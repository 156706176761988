import Modal from "@mui/material/Modal";
import React from "react";
import { Spinner } from "./Spinner";

interface Props {
  loading?: boolean;
}

export const FullScreenSpinner = ({ loading }: Props) => {
  return (
    <Modal
      open={!!loading}
      onClose={() => null}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Spinner style={style} size={50} color={"primary"} loading={loading} />
    </Modal>
  );
};

const style: React.CSSProperties = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};
