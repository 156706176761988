import React from "react";
import { Text } from "elements/text/Text";
import { palette } from "assets/palette";
import { i18n } from "constants/i18n.constants";

interface Props {
  onPress?: () => void;
  isSelected?: boolean;
  price?: number;
  savedPercentage?: number;
  header?: string;
}

export const Plan = ({
  onPress,
  isSelected,
  price,
  savedPercentage,
  header
}: Props) => {
  return (
    <div onClick={onPress} style={isSelected ? styles.selectedContainer : styles.unSelectedContainer}>
        <Text style={styles.rangeText}>{header}</Text>
        <div style={styles.rowContainer}>
            <Text style={styles.rangePrice}>{i18n.t("month", {price: `$${price?.toFixed(2)}`})}</Text>
            {isSelected && savedPercentage ? <div style={styles.save}>
                <Text style={styles.saveText}>{i18n.t("save_amount", {savedAmount: `${savedPercentage}`})}</Text>
            </div> : null}
        </div>
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  rangeText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 25,
    color: palette.gray.mediumLight,
    marginLeft: 20
  },
  rangePrice: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 25,
    color: palette.black,
    marginLeft: 20
  },
  selectedContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    backgroundColor: palette.gray.light,
    border: `1px solid ${palette.green.medium}`,
    borderRadius: 10,
    width: "100%",
    height: "auto",
    paddingTop: 20,
    paddingBottom: 20
  },
  unSelectedContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    backgroundColor: palette.gray.light,
    borderRadius: 10,
    width: "100%",
    height: "auto",
    paddingTop: 20,
    paddingBottom: 20
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  save: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: `${palette.green.medium}80`,
    borderRadius: 10,
    padding: 5,
    marginLeft: 10,
    height: 20
  },
  saveText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 23,
    color: palette.green.dark
  },
};
