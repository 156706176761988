import { useTagsHook } from "pages/private/home/components/DrawerLeft/Tags/useTagsHook";
import { setTagsModalVisible } from "redux-store/actions/home-page.actions";
import { useAppDispatch, useAppSelector } from "redux-store/store.hooks";
import { parseTags } from "helpers/utils.helper";

export default function useManageTagsHook() {
  const { modalOpened } = useAppSelector((state) => ({
    modalOpened: state.homePage.tagsModalVisible,
  }));
  const dispatch = useAppDispatch();
  const {
    tagsData,
    parsedTags,
    onPressPlus,
    onChangeTagTitle,
    onPressDelete,
    onClickHeart,
  } = useTagsHook();

  const favoriteTags = tagsData.filter((item) => item.favorite);

  const parsedFavoriteTags = parseTags(favoriteTags);

  const closeModal = () => {
    dispatch(setTagsModalVisible(false));
  };

  return {
    closeModal,
    onPressPlus,
    onChangeTagTitle,
    onPressDelete,
    modalOpened,
    parsedTags,
    parsedFavoriteTags,
    onClickHeart,
  };
}
