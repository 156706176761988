import { MeetsResource } from "communicators/resources/event-resources/meets/meets.resource";
import { ThirdPartyInformationResource } from "communicators/resources/third-party-information/third-party-information.resource";
import { sleep } from "helpers/utils.helper";
import { DateTime } from "luxon";
import { useState } from "react";
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
  useGoogleLogin,
  UseGoogleLoginProps,
} from "react-google-login";
import {
  setPermissionModalType,
  setRefreshBoardData,
} from "redux-store/actions/home-page.actions";
import { setThirdPartyProviderData } from "redux-store/actions/third-party-provider.actions";
import { useAppDispatch } from "redux-store/store.hooks";
import { useUserSettingHook } from "./useUserSettingHook";
import { CalendarAccountType } from "pages/private/onboarding-dnd/useOnboardingDndHook";
import { setCalendarData } from "redux-store/actions/user-settings.action";
import { UnprocessableAPIError } from "communicators/insumo/errors/unprocessable.error";
import { PermissionModalType } from "pages/private/home/useHomeHook";

interface Props {
  onSuccess?: () => void;
  onFail?: () => void;
}

const SCOPE = "https://www.googleapis.com/auth/calendar";

export const useGoogleSignInHook = ({ onSuccess }: Props) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const { setAndGetUserPreferedCalendar } = useUserSettingHook();

  const onSuccessGoogleAuth = async (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    if (response.code) {
      setLoading(true);
      try {
        await new ThirdPartyInformationResource().createGoogleProvider(
          response.code
        );
        for (let i = 0; i < 3; i++) {
          try {
            const response = await new MeetsResource().readMany({
              start_date: DateTime.now().toSQLDate(),
              end_date: DateTime.now().plus({ days: 30 }).toSQLDate(),
              inclusive: true,
              per: 10,
            });
            await sleep(500);
            if (response.data.length > 0) {
              break;
            }
          } catch (_) {}
        }
        await setAndGetUserPreferedCalendar(CalendarAccountType.GOOGLE);
        dispatch(setThirdPartyProviderData());
        if (onSuccess) {
          onSuccess();
        } else {
          dispatch(setRefreshBoardData());
        }
        dispatch(setCalendarData());
        setLoading(false);
      } catch (err) {
        if (err instanceof UnprocessableAPIError) {
          dispatch(setPermissionModalType(PermissionModalType.GOOGLE_MISSING));
        }

        setLoading(false);
      }
    }
  };
  const onFailGoogleAuth = (error: any) => {};
  const config: UseGoogleLoginProps = {
    onSuccess: onSuccessGoogleAuth,
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID || "",
    redirectUri: process.env.REACT_APP_GOOGLE_REDIRECT_URL,
    onFailure: onFailGoogleAuth,
    scope: SCOPE,
    responseType: "code",
    cookiePolicy: "single_host_origin",
    accessType: "offline",
    uxMode: "popup",
    isSignedIn: false,
    onRequest: () => {},
    prompt: "",
  };

  const { signIn, loaded } = useGoogleLogin(config);

  const login = () => {
    if (!loaded) return;
    signIn();
  };

  const googleAuthLoading = loading;
  return { login, googleAuthLoading };
};
