import { palette } from "assets/palette";
import { i18n } from "constants/i18n.constants";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { Text } from "elements/text/Text";
import { usePreferencesRedeemCodeHook } from "./usePreferencesRedeemCodeHook";
import { TextInput } from "elements/text-field/TextInput";
import { CustomButton } from "elements/button/Button";

export const PreferencesRedeemCode = () => {
  const {
    redeemCode,
    errorText,
    loading,
    showSuccessMessage,
    goBack,
    setRedeemCode,
    onPressSubmit,
  } = usePreferencesRedeemCodeHook();
  return (
    <div style={styles.container}>
      {!showSuccessMessage ? (
        <>
          <div style={styles.headerContainer}>
            <Icon name={IconName.BACK_ARROW} onPress={goBack} />
            <Text style={styles.headerText}>{i18n.t("redeem_code_title")}</Text>
          </div>
          <div>
            <TextInput
              onChangeText={setRedeemCode}
              value={redeemCode}
              style={styles.textInput}
              placeholder={i18n.t("redeem_code_placeholder")}
            />
          </div>
          {errorText ? <Text style={styles.errorText}>{errorText}</Text> : null}
          <CustomButton
            loading={loading}
            style={styles.buttonStyle}
            text={i18n.t("redeem")}
            onPress={onPressSubmit}
          />
        </>
      ) : (
        <>
          <Text style={styles.successHeader}>
            {i18n.t("redeem_success_title")}
          </Text>
          <Text style={styles.successSubtitle}>
            {i18n.t("redeem_success_subtitle")}
          </Text>
        </>
      )}
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flex: 1,
    alignSelf: "stretch",
    flexDirection: "column",
    paddingRight: 50,
    marginLeft: -12,
    overflowY: "scroll",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  billingCyclePlanContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "15%",
  },
  headerText: {
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "500",
    fontSize: 20,
    color: palette.black,
  },
  textInput: {
    width: 240,
    marginLeft: "10px",
  },
  errorText: {
    marginLeft: "20px",
    marginTop: "10px",
    fontSize: 16,
    color: palette.red.dark,
  },
  buttonStyle: {
    backgroundColor: palette.black3,
    width: "150px",
    marginLeft: "10px",
    marginTop: "20px",
  },
  successHeader: {
    fontSize: 30,
    fontWeight: "600",
    color: palette.green.medium,
    marginTop: "60px",
    marginLeft: "30px"
  },
  successSubtitle: {
    fontSize: 18,
    fontWeight: "600",
    color: palette.black,
    marginTop: "20px",
    marginLeft: "30px"
  }
};
