import React from "react";
import Dialog from "@mui/material/Dialog";
import { i18n } from "constants/i18n.constants";
import { IconName } from "elements/icon/Icon.type";
import { Icon } from "elements/icon/Icon";
import { palette } from "assets/palette";
import { Text } from "elements/text/Text";
import { IconButton } from "@mui/material";
import { Divider } from "elements/divider/Divider";
import { PreferencesLeftList } from "../PreferencesLeftList/PreferencesLeftList";
import { PreferencesAccount } from "../PreferencesAccount/PreferencesAccount";
import { PreferencesIntegrations } from "../PreferencesIntegrations/PreferencesIntegrations";
import { PreferenceTab } from "./PreferenceDialog.types";
import { useAppDispatch, useAppSelector } from "redux-store/store.hooks";
import {
  setPreferencesDialog,
  setPreferencesTab,
} from "redux-store/actions/home-page.actions";
import { resizeByResolutionWidth } from "helpers/utils.helper";
import { PreferencesSubscription } from "../PreferencesSubscription/PreferencesSubscription";
import { PreferencesBillingHistory } from "../PreferencesBillingHistory/PreferencesBillingHistory";
import { PreferencesChangeBillingCycle } from "../PreferencesChangeBillingCycle/PreferencesChangeBillingCycle";
import { PreferencesCompleteYourPurchase } from "../PreferencesCompleteYourPurchase/PreferencesCompleteYourPurchase";
import { PreferencesPaymentMethod } from "../PreferencesPaymentMethod/PreferencesPaymentMethod";
import { PreferencesAddPaymentMethod } from "../PreferencesAddPaymentMethod/PreferencesAddPaymentMethod";
import { PreferencesRedeemCode } from "../PreferencesRedeemCode/PreferencesRedeemCode";

interface Props {
  selectedPreference?: string;
  refreshBoards: () => void;
  handleOpenUnsubscriptionDialog: () => void;
  googleSignIn: any;
  googleAuthLoading: boolean;
}

export const PreferencesDialog = ({
  refreshBoards,
  handleOpenUnsubscriptionDialog,
  googleSignIn,
  googleAuthLoading,
}: Props) => {
  const { selectedPreference, open } = useAppSelector((state) => ({
    selectedPreference: state.homePage.selectedPreferencesTab,
    open: state.homePage.preferencesDialogOpened,
  }));
  const dispatch = useAppDispatch();

  const onSelectPreferenceTab = (preferenceKey: PreferenceTab) => {
    dispatch(setPreferencesTab(preferenceKey));
  };

  const handleClose = () => {
    dispatch(setPreferencesDialog(false));
  };

  const SelectedComponent: any = PreferencesComponents[selectedPreference];

  return (
    <Dialog onClose={handleClose} fullWidth maxWidth={"md"} open={open}>
      <div style={styles.container}>
        <div style={styles.header}>
          <Icon
            width={resizeByResolutionWidth(30)}
            height={resizeByResolutionWidth(30)}
            name={IconName.SETTINGS}
          />
          <Text style={styles.title}>{i18n.t("preferences")}</Text>
          <IconButton onClick={handleClose}>
            <Icon width={15} height={15} name={IconName.CLOSE} />
          </IconButton>
        </div>
        <Divider color={palette.gray.light} />
        <div style={styles.body}>
          <PreferencesLeftList
            onSelect={onSelectPreferenceTab}
            selected={selectedPreference}
          />
          <div style={styles.main}>
            {SelectedComponent ? (
              <SelectedComponent
                refreshBoards={refreshBoards}
                handleOpenUnsubscriptionDialog={handleOpenUnsubscriptionDialog}
                googleSignIn={googleSignIn}
                googleAuthLoading={googleAuthLoading}
              />
            ) : null}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const PreferencesComponents: Record<PreferenceTab, any> = {
  [PreferenceTab.ACCOUNT]: PreferencesAccount,
  [PreferenceTab.INTEGRATIONS]: PreferencesIntegrations,
  [PreferenceTab.SUBSCRIPTION]: PreferencesSubscription,
  [PreferenceTab.BILLING_HISTORY]: PreferencesBillingHistory,
  [PreferenceTab.PAYMENT_METHOD]: PreferencesPaymentMethod,
  [PreferenceTab.CHANGE_BILLING_CYCLE]: PreferencesChangeBillingCycle,
  [PreferenceTab.ADD_PAYMENT_METHOD]: PreferencesAddPaymentMethod,
  [PreferenceTab.COMPLETE_YOUR_PURCHASE]: PreferencesCompleteYourPurchase,
  [PreferenceTab.REDEEM_CODE]: PreferencesRedeemCode,
};

const styles: Record<string, React.CSSProperties> = {
  container: {
    overflow: "hidden",
  },
  header: {
    backgroundColor: palette.white,
    display: "flex",
    alignItems: "center",
    padding: 30,
    borderBottomWidth: 1,
  },
  title: {
    fontSize: 25,
    fontFamily: "Gilroy-Medium",
    marginLeft: 10,
    display: "flex",
    flex: 1,
  },
  body: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
  main: {
    height: 550,
    display: "flex",
    flex: 1,
    padding: 35,
  },
};
