const en = {
  app_name: "Insumo",
  sign_up_free: "Sign up to Insumo for free",
  email: "E-mail",
  enter_email: "enter your email",
  password: "Password",
  enter_password: "enter your password",
  lets_start: "Let's Start",
  or: "or",
  continue_with_google: "Continue with Google",
  continue_with_phone: "Continue with Phone",
  continue_with_apple: "Continue with Apple",
  terms_of_use_first: "By continuing, you agree to Insumo's",
  terms_of_use: "Terms of Use",
  and: "and",
  privacy_policy: "Privacy Policy",
  already_signed_up: "Already signed up?",
  login: "Sign in",
  enter_your_phone: "enter your phone number",
  phone: "Phone",
  forgot_password: "Forgot Password?",
  dont_have_an_account: "Don't have an account?",
  signup: "Sign up",
  login_to_insumo: "Sign in to Insumo",
  sign_up_with_phone: "Sign up with Phone Number",
  send_verification_code: "Send Verification Code",
  select_your_country: "Select your country",
  forgot_your_password: "Forgot Your Password?",
  send_reset_link: "Send Reset Link",
  invalid_email: "Please enter a valid email!",
  wrong_email: "Please enter a correct email!",
  wrong_password: "Password is wrong!",
  invalid_verification_code: "Please, enter a valid confirmation code!",
  verification_code: "Verification Code",
  verify_phone_text:
    "Please enter the code in the SMS sent to the phone number.",
  default_error: "There is a problem.",
  all: "All",
  tasks: "Tasks",
  preferences: "Preferences",
  account: "Account",
  integrations: "Integrations",
  help: "Help",
  fullname: "Fullname",
  profile: "Profile",
  email_header: "Email",
  change_email: "Change primary email",
  delete_account: "Delete my account",
  export_data: "Export my data",
  integrate_tools: "Integrate Your Current Tools",
  asana: "Asana",
  todoist: "Todoist",
  outlook: "Microsoft Outlook",
  jira: "Jira",
  google_calendar: "Google Calendar",
  hubspot: "Hubspot",
  notion: "Notion",
  clickup: "Clickup",
  today: "Today",
  tomorrow: "Tomorrow",
  meets: "Meets",
  daily_schedule: "Daily Schedule",
  are_you_sure_delete_integration:
    "Are you sure you want to delete the integration?",
  yes_delete: "Yes, delete",
  yes_unsubscribe: "Yes, unsubscribe",
  no_keep: "No, keep",
  user_not_found: "User Not Found",
  already_in_use: "This account already in use.",
  already_sign_up_mobile_title:
    "It looks like you created an account from our mobile app.",
  already_sign_up_mobile_subtitle:
    "We currently offer Tasks and Meeting in Insumo Web, habits are coming very soon!",
  first_two_week_free: "First two weeks is free on this web app.",
  no_credit_cart_required: "No credit card required.",
  you_can_upgrade: "or you can upgrade right now",
  upgrade_now: "Upgrade Now",
  next: "Next",
  select_task_manager_title: "Select the tools you use.",
  select_task_manager_subtitle:
    "Choose the tools you use to manage your tasks and your calendar. We'll assist with the connecting later.",
  tool_selection_subtitle: "Choose the tools you use to manage your tasks and your calendar. We'll assist with the connection later.",
  tool_selection_subtitle_1:
    "Select the tools you use to keep track of your tasks.",
  tool_selection_subtitle_2: "We’ll help you connect them later.",
  coming_soon_notify: "Coming soon!  We will notify you when it is here.",
  coming_soon: "Coming Soon",
  set_up_planning_routine: "Let's set up a planning routine!",
  plan_day_subtitle:
    "We will help you establish a daily planning routine with reminders.",
  set_reminder: "Set reminder time",
  start_planing: "Start Planning",
  create_reminder_header:
    "Establish the habit of planning your daily schedule!",
  create_reminder_subheader:
    "New habits are hard. For your first two weeks, we’ll help by sending one daily reminder to plan your day.",
  remind_me_via_notification: "Remind me via desktop notification",
  remind_me_via_email: "Remind me via email only",
  subscription_something_went_wrong:
    "Something went wrong. Please try again later or you can try free trial.",
  subscribe: "Subscribe",
  start_free_trial: "Start Free Trial",
  monthly: "Monthly",
  annually: "Annually",
  month: "{{price}}/month",
  subscription_features: [
    "Web App",
    "Process Tracking",
    "Zero Task Inbox",
    "Mobile App Apple & Android",
    "Integrations",
  ],
  premium_account: "Premium Account",
  download_mobile_app: [
    "Download our",
    "ios app",
    "and",
    "google play store app",
  ],
  start_planning_your_day: "Start planning your day",
  thanks_for_subscribing: "Thanks for subscribing!",
  integrate_your_tools: "Integrate your tools",
  integrate_subtext:
    "See everything in one place: tasks and meetings together.",
  daily_planning_process: "Daily planning process",
  plan_today: "Plan Today",
  add_your_first_task: "Add your first task",
  click_here_to_add_first_task: "Click here to add a new task",
  type_here: "Type here...",
  perfect: "Perfect!",
  drag_and_drop_tasks: "Drag & drop tasks",
  drag_and_drop_task_to_finish_today:
    "Drag & drop the tasks you want to finish today.",
  someday: "Someday",
  go_to_dashboard: "Go to Dashboard",
  example_task: "Meeting",
  what_you_get_premium: "What you get in Premium Plan",
  not_sure_yet: "Not sure yet? Enable free trial",
  free_trial_enabled: "Free trial enabled",
  per_month: "{{price}}/month",
  days_free_then_price: "{{day}} days free, then ${{price}}/month",
  no_credit_card_needed_title:
    "Free trial for two weeks, no credit card needed.",
  trial_starts_today: "Trial starts today",
  trial_ends_in: "Trial ends in {{days}} days",
  two_week: "2 week",
  logout: "Logout",
  suitcase_detail_text:
    "Integrate to manage multiple calendars and change calendar views.",
  insumo_calendar: "Insumo's Calendar",
  completed_tasks: "Completed Tasks",
  start_time: "Start Time",
  scheduled_date: "Scheduled Date",
  due_date: "Due Date",
  calendars: "CALENDARS",
  trial: "Trial",
  days_left: "{{days}} Days Left",
  reserve_time: "Reserve time on your calendar for working on the tasks today",
  inbox_tasks: "Inbox",
  organizer: "Organizer",
  we_need_your_permission_header: "We need your permission.",
  we_need_your_permission_subheader:
    "In order to access all of Insumo's features, we need your permission.",
  search_insumo: "Search Insumo...",
  completed_tasks_drawer_tooltip: "All completed tasks are here",
  settings: "Settings",
  search: "Search",
  inbox_tasks_drawer_tooltip: "All incomplete tasks are in your inbox",
  start_time_selection_tooltip: "Choose a time to start working on this task.",
  scheduled_date_selection_tooltip:
    "Choose a scheduled date to work on this task.",
  due_date_selection_tooltip: "Choose a due date to work on this task.",
  no_date: "--/--/----",
  no_time: "--:--",
  best_matches: "Best Matches / {{result}} results",
  cannot_reschedule: "You can't reschedule this event.",
  free_trial_for_two_weeks: "Free trial for two weeks, no credit card needed.",
  billing: "Billing",
  invoices: "Invoices",
  see_billing_details: "See billing details",
  subscription_features_other_order: [
    "Web App",
    "Process Tracking",
    "Zero Task Inbox",
    "Mobile App Apple & Android",
    "Integrations",
  ],
  payment_method: "Payment Method",
  card_number: "Card Number",
  change_payment_method: "Change Payment Method",
  unsubscribe: "Unsubscribe",
  reference: "Reference",
  date: "Date",
  amount: "Amount",
  product: "Product",
  download: "Download",
  days_ago: "{{day}} d ago",
  sure_unsubscribe: "Are you sure you want to unsubscribe?",
  yes: "Yes",
  subscribe_to_continue: "Please subscribe to continue using Insumo.",
  subscribe_to_continue_long_text:
    "In order to use the Insumo web app, you must subscribe to a plan. Without a subscription, you will not be able to access the features and functionality of the app.",
  someday_tasks_drawer_tooltip: "Non-started tasks without a specific date.",
  premium_plan: "Premium Plan",
  subscribe_today:
    "Subscribe today and take the first step towards a better tomorrow!",
  subscription_per_month: "{{price}} per month",
  you_are_all_set: "You are all set.",
  thanks_for_subscribing_long_text:
    "Thanks for subscribing! We couldn't have done it without you... or, well, maybe we could have, but it wouldn't have been as much fun.",
  something_went_wrong: "Something went wrong.",
  try_again_later: "Please try again later.",
  something_went_wrong_long_text:
    "Join now and transform your life with greater organization, motivation, and overall improvement.",
  trial_expired: "Your trial has expired.",
  expired: "Expired",
  started: "Started {{date}}",
  ended: "Ends {{date}}",
  trial_ended_long_text:
    "Your trial period has expired. Time to upgrade to the full version of you by subscribing. Don't worry, we promise it's worth it.",
  subscribe_after_trial_text:
    "Subscribe now and become the best version of yourself!",
  billing_details: "Billing Details",
  subscription: "Subscription",
  subscription_type: "Subscription Type",
  last_payment: "Last Payment",
  next_payment_due: "Next Payment Due",
  click_here_to_add_details: "Click on the board to add details",
  modify_later: "You can modify these dates later",
  update_payment_method: "Update Payment Method",
  change_billing_cycle: "Change Billing Cycle",
  remove: "Remove",
  save: "Save",
  cancel: "Cancel",
  billing_history: "Billing History",
  card_details: "Card Details",
  billed_now: "Billed Now",
  // eslint-disable-next-line no-template-curly-in-string
  subscription_long_text:
    "By clicking ‘’Start Insumo Premium Plan’’, you agree to be charged every renewal period.",
  start_insumo_premium_plan: "Start Insumo Premium Plan",
  plan: "Plan",
  pay_monthly: "Pay Monthly",
  pay_yearly: "Pay Yearly",
  save_amount: "Save {{savedAmount}}%",
  mm_yy: "MM / YY",
  date_invoice: "Date / Invoice",
  description: "Description",
  total: "Total",
  no_billing_history: "No billing history",
  premium: "Premium",
  yearly: "Yearly",
  please_verify_your_email: "Please verify your $#$ email",
  resend: "Resend",
  phone_number: "Phone Number",
  change_your_billing_cycle: "Change Your  Billing Cycle",
  change_your_billing_cycle_subheader:
    "Choose a billing cycle for your Unlimited Premium plan.",
  monthly_billing_cycle: "USD {{price}} Every Month",
  yearly_billing_cycle: "USD {{price}}/month x 12 Months",
  continue: "Continue",
  are_you_sure_delete_account: "Are you sure you want to delete your account?",
  no: "No",
  change_phone_number: "Change Phone Number",
  sure_to_change_phone_number: "Are you sure you want to change phone number?",
  done: "Done",
  enter_6_digit_code:
    "You need to enter the 6-digit code in the SMS sent to the Phone Number above.",
  complete_your_purchase: "Complete Your Purchase",
  submit_purchase: "Submit Purchase",
  payment_details: "Payment Details",
  complete_purchase_yearly_amount_description: "USD {{price}} x 12 Yearly",
  usd_amount: "USD {{price}}",
  applicable_tax: "+ applicable tax",
  submit_purchase_warning:
    "By clicking “Submit Purchase” you agree to be charged {{amount}} every renewal period, unless you cancel.",
  use_another_payment_method: "Use another payment method",
  card_already_exists_try_another_card: "Card already exists try another card",
  are_you_sure_you_want_to_unsubcsribe: "Are you sure you want to unsubcsribe?",
  add_payment_method: "Add Payment Method",
  actions: "Actions",
  set_as_primary: "Set as Primary",
  name: "Name",
  surname: "Surname",
  your_card_details_will_be_saved:
    "Your card details will be saved for future purchases and subscription renewals.",
  invoice_details: "Invoice Details",
  address: "Address",
  postal_code: "Postal Code",
  your_subscription_has_been_cancelled: "Your subscription has been canceled.",
  your_premium_membership_has_been_canceled:
    "Your Premium membership has been canceled.",
  you_will_have_access: "You will have access to Premium features until ",
  web_app: "Web App",
  create_tasks_and_manage_meetings:
    "Create tasks and manage meetings on the web.",
  focus: "Focus",
  focus_on_tasks: "Focus on tasks that matter and postpone others.",
  integrate_calendar_todoist_asana_others:
    "Integrate Google Calendar, Todoist, Asana and others.",
  mobile_app: "Mobile App",
  track_your_tasks: "Track your tasks, meetings and habits from mobile.",
  reminders: "Reminders",
  get_reminded: "Get reminded to plan your day.",
  zero_task: "Zero Task Inbox",
  reach_your_zero_tasks: "Reach your zero task goal and celebrate your streak.",
  apple_account: "Apple Account",
  apple_billing_description:
    "Please check your apple account for your invoice.",
  multiple: "Multiple",
  oops_something_went_wrong: "Oops, something went wrong!",
  something_went_wrong_text:
    "Please try again later. If the problem persists, please contact our support team for assistance.",
  ok: "OK",
  discard: "Discard",
  missing_permission: "Missing Permission",
  retry: "Retry",
  connect_later: "Connect Later",
  you_have_hit_zero_tasks: "You have hit zero tasks {{week}} weeks in a row",
  free_plan: "Free Plan",
  premium_expires_on: "Premium expires on {{date}}",
  achievements: "Achievements",
  current_streak: "Current Streak",
  longest_streak: "Longest Streak",
  task_zero: "Task Zero",
  weeks: "Weeks",
  days: "Days",
  current_streak_text: "You’ve hit Task Zero for {{week}} weeks in a row!",
  longest_streak_text: "Your longest Task Zero streak is {{week}} weeks!",
  task_zero_text: "You’ve hit Task Zero on {{day}} days so far!",
  existing_account_phone:
    "This phone number is associated with another account!",
  redeem_code: "Redeem a Code",
  redeem_code_title:
    "If you have a promotion code, please enter in the field below.",
  redeem_code_placeholder: "Enter your code",
  incorrect_code: "Incorrect code",
  redeem: "Redeem",
  redeem_success_title: "You’ve successfully redeemed your code.",
  redeem_success_subtitle:
    "Get ready to experience the full range of exclusive perks and privileges!",
  magic_planner: "Magic Planner",
  open: "Open",
  lets_organize_your_day: "Let’s organize your day!",
  magic_planner_yes: "Yes!",
  magic_planner_no_thanks: "No, thanks",
  magic_planner_how_can_help_text:
    "Today looks pretty busy! Do you want me to reorganize your schedule? 😍",
  magic_planner_hi: "Hi there, 🌟🥗",
  tags: "TAGS",
  select_all: "Select All",
  n_items: "{{itemCount}} Items",
  manage_tags: "Manage Tags",
  all_tags: "All Tags",
  favorites: "Favorites",
  tag_selection_tooltip: "Change tags.",
  create: "Create",
  reminder: "Reminder: ",
  moved_to_inbox: "moved to Inbox",
  we_reorganize_your_schedule: "We reorganize your schedule and prioritize tasks through your meetings.",
  magic_planner_scenario_1: "Hi there! I noticed you don't have any tasks or meetings today. Would you like to schedule some tasks? Remember, we recommend a maximum of 3 tasks per day to keep things manageable. Just let me know what you'd like to work on and I'll help arrange your day!",
  magic_planner_scenario_2: "Hello! I see you have some meetings scheduled today but no tasks yet. Please add tasks for me to help you schedule some tasks around your meetings. You can work on up to 3 tasks per day for optimal productivity.",
  magic_planner_scenario_3: "Hi there! Your calendar seems quite packed with meetings today. However, it’s important to balance meetings with focused work time. Please move some of your tasks to another day to ensure you don’t feel overwhelmed and check back in. You can always revisit the task list later.",
  magic_planner_scenario_4: "Hello! I see you have more than 3 tasks today. According to behavioural science, it's most effective to focus on up to 3 tasks a day. Could you prioritize 3 tasks and out it on your daily board? You can check back later for me to help you plan them for today.",
  magic_planner_scenario_5: "Good evening! I noticed it's past 7 PM now. It's crucial to have some time to rest and recharge. We recommend you to move the remaining tasks to tomorrow or another day. Remember, a balanced work-life schedule contributes to sustainable productivity."
};

export default en;
