import { palette } from "assets/palette";
import { EventType } from "communicators/resources/event-resources/event-resources.type";
import { TaskEntity } from "communicators/resources/event-resources/tasks/tasks.type";
import { DropResult } from "constants/dnd.constants";
import { i18n } from "constants/i18n.constants";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { Text } from "elements/text/Text";
import { DragAndDropHelper } from "helpers/dnd.helper";
import { resizeByResolutionWidth } from "helpers/utils.helper";
import { AllBoardDataEntityType, IBoard } from "interfaces/main.interfaces";
import { DateTime } from "luxon";
import { TimelineListItem } from "pages/private/home/components/TimelineListItem/TimelineListItem";
import React from "react";
import { DropableArea } from "./DropableArea";

interface Props {
  boardData: IBoard;
  onDragEnd: (result: DropResult) => void;
  onPressPlus: (date: DateTime) => void;
  onTaskCreate: (
    index: number,
    boardDate: DateTime,
    createdTask: TaskEntity
  ) => void;
  onClickCheckBox: (data: AllBoardDataEntityType) => void;
  deleteTask?: (data: AllBoardDataEntityType) => void;
  onMoveDropableArea: (boardData: IBoard) => void;
}

export const Board = ({
  boardData,
  onDragEnd,
  onPressPlus,
  onTaskCreate,
  onClickCheckBox,
  deleteTask,
  onMoveDropableArea,
}: Props) => {
    const getTitle = () => {
        const today = DateTime.now();
        if (boardData.date.toSQLDate() === today.toSQLDate()) {
            return i18n.t("today");
        } else if (
            boardData.date.toSQLDate() === today.plus({ day: 1 }).toSQLDate()
        ) {
            return i18n.t("tomorrow");
        }
        return i18n.t("someday");
    };

  const move = (dragIndex: number, hoverIndex: number) => {
    if (
      !DragAndDropHelper.draggingEvent?.data?.id ||
      DragAndDropHelper.isOverOnRightDrawer
    ) {
      return;
    }
    const { board } = DragAndDropHelper.getBoardIdByBoardItemId(
      DragAndDropHelper.draggingEvent?.data.id
    );
    const sourceBoard = board;
    const sourceIndex = dragIndex;
    const destinationBoard = boardData.date.toSQLDate();
    const destinationIndex = hoverIndex;
    if (!sourceBoard || !destinationBoard) return;
    const result: DropResult = {
      destination: { droppableId: destinationBoard, index: destinationIndex },
      source: { droppableId: sourceBoard, index: sourceIndex },
    };
    onDragEnd(result);
  };

  const onMoveDropableArea2 = () => {
    onMoveDropableArea(boardData);
  };

  return (
    <div style={styles.container}>
      <div style={styles.titleContainer}>
        <Text style={styles.title}>{getTitle()}</Text>
        <Icon
          style={styles.icon}
          fill={palette.gray.iceLight}
          name={IconName.PLUS}
          onPress={() => onPressPlus(boardData.date)}
        />
      </div>
      <div style={styles.listWrapper} className="Windows">
        {[...boardData.data].map((item, index) =>
          (item &&
            item.type === EventType.TASK &&
            item.attributes.completed_count === 0) ||
            (item && item.type !== EventType.TASK) ? (
            <TimelineListItem
              boardId={boardData?.date.toSQLDate()!}
              key={index}
              index={index}
              data={item}
              moveCard={move}
              onTaskCreate={(createdTask: TaskEntity) =>
                onTaskCreate(index, boardData.date, createdTask)
              }
              onClickCheckBox={onClickCheckBox}
              deleteTask={deleteTask}
            />
          ) : null
        )}
        <DropableArea onHover={onMoveDropableArea2} />
      </div>
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    alignSelf: "stretch",
    flexDirection: "column",
    width: resizeByResolutionWidth(390),
  },
  titleContainer: {
    display: "flex",
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontFamily: "Gilroy-Semibold",
    fontWeight: "500",
    fontSize: 25,
  },
  icon: {
    marginLeft: resizeByResolutionWidth(30),
  },
  subtitle: {
    fontFamily: "Gilroy-Semibold",
    fontWeight: "500",
    fontSize: 20,
    color: palette.gray.medium,
    marginBottom: resizeByResolutionWidth(20),
  },
  listWrapper: {
    display: "flex",
    alignSelf: "stretch",
    flexDirection: "column",
    flex: 1,
    overflowY: "scroll",
    overflow: "scroll",
  },
};
