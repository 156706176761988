import { Text } from "elements/text/Text";
import { i18n } from "constants/i18n.constants";
import { palette } from "assets/palette";
import { SubscriptionPlan } from "./SubscriptionPeriodSelection.type";

interface Props {
  selectedPlan: SubscriptionPlan;
  onSelectPlan: (plan: SubscriptionPlan) => void;
  containerStyle?: React.CSSProperties;
}

export const SubscriptionPeriodSelection = ({
  onSelectPlan,
  selectedPlan,
  containerStyle,
}: Props) => {
  return (
    <div style={{ ...styles.period, ...containerStyle }}>
      <Text
        onPress={() => onSelectPlan(SubscriptionPlan.MONTHLY)}
        style={
          selectedPlan === SubscriptionPlan.MONTHLY
            ? styles.selectedPeriodText
            : styles.unSelectedPeriodText
        }
      >
        {i18n.t("monthly")}
      </Text>
      <div style={styles.verticalSeparator} />
      <Text
        onPress={() => onSelectPlan(SubscriptionPlan.ANNUALLY)}
        style={
          selectedPlan === SubscriptionPlan.ANNUALLY
            ? styles.selectedPeriodText
            : styles.unSelectedPeriodText
        }
      >
        {i18n.t("annually")}
      </Text>
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  period: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  verticalSeparator: {
    width: 1,
    height: 46,
    marginLeft: 30,
    marginRight: 30,
    backgroundColor: palette.black,
  },
  unSelectedPeriodText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 20,
    color: palette.gray.mediumLight,
    cursor: "pointer"
  },
  selectedPeriodText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 20,
    color: palette.black,
    cursor: "pointer"
  },
};
