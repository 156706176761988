import { EventType } from "communicators/resources/event-resources/event-resources.type";
import { getMeetTimeRange, getTaskTimeRange } from "helpers/data.helper";
import {
  AllBoardDataEntityType,
  IBoard,
  ThirdPartyProvider,
} from "interfaces/main.interfaces";
import { useCallback, useEffect, useRef, useState } from "react";
import { TasksResource } from "communicators/resources/event-resources/tasks/tasks.resource";
import {
  TaskCandidate,
  TaskEntity,
} from "communicators/resources/event-resources/tasks/tasks.type";
import { debounce } from "lodash";
import { DateTime } from "luxon";
import {
  getTaskStartTime,
  todoistWriteAccessChecker,
} from "helpers/common.helper";
import { useAppDispatch, useAppSelector } from "redux-store/store.hooks";
import { setPermissionModalType } from "redux-store/actions/home-page.actions";
import { PermissionModalType } from "../../useHomeHook";
import { Scope } from "communicators/resources/third-party-information/third-party-information.type";

interface Props {
  data: AllBoardDataEntityType;
  onTaskCreate?: (newTask: TaskEntity, isNewTask: boolean) => void;
  boardData?: IBoard;
  onClickCheckBox?: (data: AllBoardDataEntityType) => void;
  newTaskCreate?: (isCreated: boolean) => void;
}

export const useTimelineListItemHook = ({
  data,
  onTaskCreate,
  boardData,
  onClickCheckBox,
  newTaskCreate
}: Props) => {
  const getInitialTitle = (newData: AllBoardDataEntityType) => {
    switch (newData?.type) {
      case EventType.HABIT_TARGET:
        return newData.attributes.title;
      case EventType.TASK:
        return newData.attributes.title;
      case EventType.MEET:
        return newData.attributes.meet_summary;
      default:
        return "";
    }
  };

  const [isMeetDetailOpen, setIsMeetDetailOpen] = useState<boolean>(false);
  const [onMouseDown, setOnMouseDown] = useState<boolean>(false);
  const [onMouseUp, setOnMouseUp] = useState<boolean>(true);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [updatedTask, setUpdatedTask] = useState(data);
  const [title, setTitle] = useState(getInitialTitle(data));
  const [currentTaskCreated, setCurrentTaskCreated] = useState(false);
  const inputRef = useRef<any>(null);

  const dispatch = useAppDispatch();

  const { thirdPartyData } = useAppSelector((state) => ({
    thirdPartyData: state.thirdPartyProviderData.thirdPartyData,
  }));

  const todoistScopes = thirdPartyData?.filter(
    (item) => item.provider === ThirdPartyProvider.TODOIST
  )[0]?.scopes;

  const onPressCheckbox = () => {
    const copyUpdatedTask = { ...updatedTask };
    const newTask = { ...updatedTask };
    if (newTask.type !== EventType.TASK) {
      return;
    }
    newTask.attributes.completed_count =
      newTask.attributes.completed_count === 0 ? 1 : 0;
    setUpdatedTask(newTask);
    setTimeout(() => {
      onClickCheckBox?.(copyUpdatedTask);
    }, 1000);
  };

  const getTime = () => {
    if (data?.type === EventType.MEET) {
      return getMeetTimeRange(data);
    }
    if (data?.type === EventType.TASK) {
      const selectedStartTimes = getTaskStartTime(data, boardData);
      const selectedEndTimes = getTaskEndTime(data, boardData);
      if (selectedStartTimes) {
        if (selectedEndTimes) {
          return `${selectedStartTimes[0].toLocaleString(
            DateTime.TIME_SIMPLE
          )} - ${selectedEndTimes[0].toLocaleString(DateTime.TIME_SIMPLE)}`;
        }
        return selectedStartTimes[0].toLocaleString(DateTime.TIME_SIMPLE);
      }
      return getTaskTimeRange(data);
    }
    return "";
  };

  const handleMeetDetail = () => {
    setIsMeetDetailOpen(!isMeetDetailOpen);
  };

  const canDrag =
    (data.type === EventType.MEET && data.attributes.is_editable) ||
    data.type === EventType.TASK;

  const onMouseDownEvent = () => {
    if (canDrag) return;
    setOnMouseUp(false);
    setOnMouseDown(true);
  };

  const onMouseUpEvent = () => {
    if (canDrag) return;
    setOnMouseUp(true);
    setOnMouseDown(false);
  };

  const onMouseMove = () => {
    if (canDrag) return;
    if (onMouseDown && !onMouseUp && !snackbarOpen) {
      setSnackbarOpen(true);
    }
  };

  const onMouseLeave = () => {
    if (canDrag) return;
    setOnMouseUp(true);
    setOnMouseDown(false);
  };

  const onCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const updateTaskDebounced = async (text: string, taskId: string) => {
    newTaskCreate && newTaskCreate(true);
    await new TasksResource().updateOnePartial(taskId, { title: text });
    newTaskCreate && newTaskCreate(false);
  };
  const createEmptyTask = async (newTaskData: TaskEntity) => {
    if (currentTaskCreated) return;
    newTaskCreate && newTaskCreate(true);
    setCurrentTaskCreated(true);
    const newTask: TaskCandidate = {
      scheduled_time: newTaskData.attributes.scheduled_time || "",
      due_date: newTaskData?.attributes.due_date,
      title: "",
      start_time: null,
      end_time: null,
      start_date: newTaskData?.attributes.start_date,
      end_date: newTaskData?.attributes.end_date,
      description: newTaskData?.attributes.description,
      completed_count: newTaskData?.attributes.completed_count,
    };
    const result = await new TasksResource().createOne(newTask);
    if (onTaskCreate && result?.data) onTaskCreate(result.data, true);
    setUpdatedTask(result.data);
    setCurrentTaskCreated(false);
    newTaskCreate && newTaskCreate(false);
  };

  const updateTaskForTitle = useCallback(
    debounce(async (newTitle: string, newTaskData: TaskEntity) => {
      if (newTaskData?.type !== EventType.TASK || !newTaskData?.id) return;
      if (
        newTaskData.attributes.provider === ThirdPartyProvider.TODOIST &&
        !todoistScopes?.includes(Scope.WRITE)
      ) {
        dispatch(setPermissionModalType(PermissionModalType.TODOIST));
        return;
      }
      const currentUpdatedTask = {
        ...newTaskData,
        attributes: { ...newTaskData?.attributes, title: newTitle },
      };
      if (onTaskCreate && currentUpdatedTask)
        onTaskCreate(currentUpdatedTask, false);
      setUpdatedTask(currentUpdatedTask);
      updateTaskDebounced(newTitle, newTaskData?.id);
    }, 500),
    [data.id]
  );

  const onChangeTitle = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    newTaskData: TaskEntity
  ) => {
    if (!todoistWriteAccessChecker(newTaskData, todoistScopes)) return;
    const text = event.target.value;
    setTitle(text);
    updateTaskForTitle(text, newTaskData);
  };

  const showCheckbox = data.type === EventType.TASK;
  const checked =
    updatedTask?.type === EventType.TASK &&
    updatedTask?.attributes.completed_count !== 0;

  const deleteTask = () => {
    if (data.type !== EventType.TASK) return;
    if (!todoistWriteAccessChecker(data, todoistScopes)) return;
    new TasksResource().removeOne(data.id);
  };

  useEffect(() => {
    if (
      data.id !== updatedTask.id ||
      getInitialTitle(data) !== getInitialTitle(updatedTask)
    ) {
      setUpdatedTask(data);
      if (data.type === EventType.TASK) {
        setTitle(data.attributes.title);
      } else if (data.type === EventType.MEET) {
        setTitle(data.attributes.meet_summary);
      }
    }
  }, [data, data.id, updatedTask.id]);

  useEffect(() => {
    if (!data.id && data.type === EventType.TASK && inputRef.current) {
      createEmptyTask(data);
      inputRef.current.focus();
    }
  }, [data.id]);

  return {
    showCheckbox,
    getTime,
    handleMeetDetail,
    onMouseDownEvent,
    onMouseUpEvent,
    onMouseMove,
    onMouseLeave,
    onCloseSnackbar,
    isMeetDetailOpen,
    snackbarOpen,
    onChangeTitle,
    title,
    updatedTask,
    checked,
    deleteTask,
    todoistScopes,
    inputRef,
    onPressCheckbox,
  };
};

const getTaskEndTime = (taskTarget: TaskEntity, boardData?: IBoard) => {
  if (boardData) {
    const times: DateTime[] = [];
    boardData?.data?.forEach((item) => {
      if (
        item?.type === EventType.MEET &&
        item?.attributes?.task_id &&
        item?.attributes?.task_id === taskTarget?.id
      ) {
        times.push(DateTime.fromISO(item?.attributes?.meet_end_time));
      }
    });
    if (times.length > 0) {
      return times;
    }
  }
  if (taskTarget?.attributes?.end_time) {
    return [
      DateTime.fromSeconds(parseInt(taskTarget?.attributes?.end_time, 10)),
    ];
  }
  return undefined;
};
