import { SubscriptionPlansResource } from "communicators/resources/subscription-plans/subscription-plans.resource";
import { SubscriptionPlanEntity } from "communicators/resources/subscription-plans/subscription-plans.type";
import { SubscriptionsResource } from "communicators/resources/subscription/subscription.resource";
import { SubscriptionPlan } from "components/subscription-period-selection/SubscriptionPeriodSelection.type";
import { useEffect, useState } from "react";
import { setPreferencesTab } from "redux-store/actions/home-page.actions";
import { setSubscriptions } from "redux-store/actions/user-settings.action";
import { useAppDispatch, useAppSelector } from "redux-store/store.hooks";
import { PreferenceTab } from "../PreferencesDialog/PreferenceDialog.types";

export enum BillingCycle {
    MONTHLY = "month",
    YEARLY = "year",
}
  
export const useChangeBillingCycleHook = () => {
  const dispatch = useAppDispatch();
  const { subscriptions } = useAppSelector((state) => ({
    subscriptions: state.userSetting.subscriptions
  }));
  const [subscriptionPlans, setSubscriptionPlans] = useState<SubscriptionPlanEntity[]>([]);

  const getSubscriptionPlans = async () => {
    const subscriptionPlansData = await new SubscriptionPlansResource().readMany();
    setSubscriptionPlans(subscriptionPlansData.data);
  };
  
  const activeSubscription = subscriptions.filter((item) => (item.attributes.status === "active"));

  const [billingCycle, setBillingCycle] = useState<"month" | "year">(activeSubscription[0].attributes.plan.data.attributes.interval);

  const onSelectBillingCycle = (billingCycle: "month" | "year") => {
      setBillingCycle(billingCycle);
  }

  const goBack = () => {
    dispatch(setPreferencesTab(PreferenceTab.SUBSCRIPTION));
  }

  const setSubscriptionInfo = async () => {
    try {
      const response = await new SubscriptionsResource().readMany();
      dispatch(setSubscriptions(response.data));
    } catch (_) {}
  };

  const onPressContinue = async () => {
    if (activeSubscription[0].attributes.plan.data.attributes.interval === BillingCycle.YEARLY && billingCycle === BillingCycle.MONTHLY) {
      await new SubscriptionsResource().updateOnePartial(activeSubscription[0].id, {
        subscription: {
          plan: SubscriptionPlan.MONTHLY
        },
      });
      dispatch(setPreferencesTab(PreferenceTab.SUBSCRIPTION));
    } else if (activeSubscription[0].attributes.plan.data.attributes.interval === BillingCycle.MONTHLY && billingCycle === BillingCycle.MONTHLY) {
      dispatch(setPreferencesTab(PreferenceTab.SUBSCRIPTION));
    } else if (activeSubscription[0].attributes.plan.data.attributes.interval === BillingCycle.MONTHLY && billingCycle === BillingCycle.YEARLY) {
      dispatch(setPreferencesTab(PreferenceTab.COMPLETE_YOUR_PURCHASE));
    }
    setSubscriptionInfo();
  }

  useEffect(() => {
    setSubscriptionInfo();
    getSubscriptionPlans();
  },[]);

  const monthlySubscription = subscriptionPlans?.filter((item) => (item.attributes.interval === BillingCycle.MONTHLY));
  const yearlySubscription = subscriptionPlans?.filter((item) => (item.attributes.interval === BillingCycle.YEARLY));

  const monthlyPrice = (monthlySubscription[0]?.attributes?.price / 100).toFixed(2);
  const yearlyPrice =( yearlySubscription[0]?.attributes?.price / 1200).toFixed(2);

  return {
    monthlyPrice,
    yearlyPrice,
    onSelectBillingCycle,
    billingCycle,
    goBack,
    onPressContinue
  };
};