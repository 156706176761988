/* eslint-disable react-hooks/exhaustive-deps */
import { CalendarsResource } from "communicators/resources/calendars/calendars.resource";
import { useEffect, useState } from "react";
import { setCalendarData } from "redux-store/actions/user-settings.action";
import { useAppDispatch, useAppSelector } from "redux-store/store.hooks";
import { HeaderButtonType } from "../../constants";

interface Props {
  onTabChanged: (boardType: HeaderButtonType) => void;
  refreshAllDataWithoutSpinner: () => void
}

export const useHomeWrapperHook = ({ onTabChanged, refreshAllDataWithoutSpinner }: Props) => {
  const [leftDrawerOpen, setLeftDrawerOpen] = useState(false);
  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false);
  const [rightDrawerOpen, setRightDrapweOpen] = useState(true);
  const [selectedHeaderButton, setSelectedHeaderButton] = useState(
    HeaderButtonType.ALL
  );
  const [isCalendarsLoading, setIsCalendarsLoading] = useState<boolean>(false);
  const { calendars, firebase_uuid } = useAppSelector((state) => ({
    calendars: state.userSetting.calendarData,
    firebase_uuid: state.userSetting.firebaseUuid,
  }));

  const dispatch = useAppDispatch();

  const onPressLeftDrawerIcon = () => {
    setLeftDrawerOpen(!leftDrawerOpen);
  };

  const onPressRightDrawerIcon = () => {
    setRightDrapweOpen(!rightDrawerOpen);
  };

  const openSearchDialog = () => {
    setIsSearchDialogOpen(true);
  };

  const closeSearchDialog = () => {
    setIsSearchDialogOpen(false);
  };

  const changeCalendarVisibility = async (
    calendar_id?: string,
    isVisible?: boolean
  ) => {
    if (!firebase_uuid || !calendar_id) return;
    try {
      setIsCalendarsLoading(true);
      // setIntegrationLoader({ calendarId: calendar_id, integrationLoader: true });
      await new CalendarsResource().updateOne(calendar_id, {
        calendar: {
          visible: !isVisible,
        },
        firebase_uuid: firebase_uuid,
      });
      await new CalendarsResource().readMany();
      dispatch(setCalendarData());
      await refreshAllDataWithoutSpinner();
      // setIntegrationLoader({ calendarId: "", integrationLoader: false });
      setIsCalendarsLoading(false);
    } catch (err) {
      // setIntegrationLoader({ calendarId: "", integrationLoader: false });
      setIsCalendarsLoading(false);
      return false;
    }
  };

  useEffect(() => {
    onTabChanged(selectedHeaderButton);
  }, [selectedHeaderButton]);

  useEffect(() => {
    dispatch(setCalendarData());
  }, []);

  return {
    selectedHeaderButton,
    leftDrawerOpen,
    rightDrawerOpen,
    onPressLeftDrawerIcon,
    onPressRightDrawerIcon,
    setSelectedHeaderButton,
    calendars,
    openSearchDialog,
    closeSearchDialog,
    isSearchDialogOpen,
    changeCalendarVisibility,
    isCalendarsLoading
  };
};
