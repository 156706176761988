import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setSubscriptions } from "redux-store/actions/user-settings.action";
import { useAppDispatch } from "redux-store/store.hooks";
import { androidAppLink, iosAppLink } from "../home/constants";

export const useSubscriptionSuccess = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onPressNext = () => {
    navigate("/onboarding/tools");
  };

  useEffect(() => {
    dispatch(setSubscriptions());
  }, [dispatch]);

  const downloadIOSApp = () => {
    window.open(iosAppLink, '_blank', 'noopener,noreferrer');
  }

  const downloadAndroidApp = () => {
    window.open(androidAppLink, '_blank', 'noopener,noreferrer');
  }

  return {
    onPressNext,
    downloadIOSApp,
    downloadAndroidApp,
  };
};
