/* eslint-disable react-hooks/exhaustive-deps */
import { TasksResource } from "communicators/resources/event-resources/tasks/tasks.resource";
import { LocalStorageParam } from "constants/local-storage.constant";
import { FullScreenSpinner } from "elements/spinner/FullScreenSpinner";
import {
  settodoistId,
  TODOIST_ONBOARDING_STATE,
} from "helpers/third-party.helper";
import { sleep } from "helpers/utils.helper";
import { usePageHook } from "hooks/usePageHook";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { setThirdPartyProviderData } from "redux-store/actions/third-party-provider.actions";
import {
  setFirebaseUuid,
  setUserBearerToken,
} from "redux-store/actions/user-settings.action";
import { useAppDispatch } from "redux-store/store.hooks";

export const TodoistCallback: React.FC = (): JSX.Element => {
  const [loading] = useState(true);
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const state = searchParams.get("state");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  usePageHook();

  const getToken = async (code: string) => {
    await sleep(1000);
    const result = await settodoistId(code);
    if (result) {
      for (let i = 0; i < 3; i++) {
        await sleep(1000);
        try {
          const tasks = await new TasksResource().readMany();
          if (tasks.data.length > 0) {
            break;
          }
        } catch (_) {}
      }
    }
    dispatch(
      setThirdPartyProviderData(() => {
        if (state === TODOIST_ONBOARDING_STATE) {
          navigate("/onboarding-integration");
          return;
        }
        navigate("/");
      })
    );
  };

  useEffect(() => {
    if (code && state) {
      const firebaseUid = localStorage.getItem(LocalStorageParam.FIREBASE_UUID);
      const storedToken = localStorage.getItem(LocalStorageParam.TOKEN);
      if (!storedToken || !firebaseUid) return;
      const parsedStored = JSON.parse(storedToken);
      dispatch(setUserBearerToken(parsedStored.access_token));
      dispatch(setFirebaseUuid(firebaseUid));
      getToken(code);
    }
  }, [code, state]);
  return <FullScreenSpinner loading={loading} />;
};
