import { useEffect, useState } from 'react';
import { deleteIntegration, makeAsanaIntegration } from 'helpers/third-party.helper';
import { useAppDispatch, useAppSelector } from "redux-store/store.hooks";
import { ThirdPartyProvider } from 'interfaces/main.interfaces';
import { setThirdPartyProviderData } from 'redux-store/actions/third-party-provider.actions';
import { setRefreshBoardData } from 'redux-store/actions/home-page.actions';

export const useAsanaHook = () => {
    const { asanaToggled, thirdPartyInformation } = useAppSelector(
        (state) => ({
            asanaToggled: !!state.thirdPartyProviderData.asanaId,
            thirdPartyInformation: state.thirdPartyProviderData.thirdPartyData
        })
    );
    const [asanaState, setAsanaState] = useState(asanaToggled);
    const dispatch = useAppDispatch();
    const toggleSwitch = () => {
        if (asanaState) {
            deleteIntegration(ThirdPartyProvider.ASANA, thirdPartyInformation);
            setAsanaState(false);
        } else {
            makeAsanaIntegration();      
            setAsanaState(true);
        }
        dispatch(setThirdPartyProviderData());
        dispatch(setRefreshBoardData())
    };
    useEffect(() => {
        dispatch(setThirdPartyProviderData());
    }, []);
    return {asanaState, toggleSwitch};
};
