import axios from "axios";
import { ThirdPartyInformationResource } from "communicators/resources/third-party-information/third-party-information.resource";
import { ThirdPartyInformationEntity } from "communicators/resources/third-party-information/third-party-information.type";

const ASANA_CLIENT_ID = process.env.REACT_APP_ASANA_CLIENT_ID;
export const ASANA_REDIRECT_URL =
  process.env.REACT_APP_ASANA_REDIRECT_URI || "";
export const ASANA_ONBOARDING_STATE = "fef364a8-9373-11ed-a1eb-0242ac120002";
export const ASANA_PREFERENCES_STATE = "762e3bc4-9374-11ed-a1eb-0242ac120002";
export const APPSUMO_REDEEM_CODE_STATE = "3a624e5e-008f-11ee-be56-0242ac120002";

export const makeAsanaIntegration = (onboarding?: boolean) => {
  const ASANA_BASE_URL = `https://app.asana.com/-/oauth_authorize?response_type=code&client_id=${ASANA_CLIENT_ID}&redirect_uri=${encodeURIComponent(
    ASANA_REDIRECT_URL
  )}&state=${!onboarding ? ASANA_PREFERENCES_STATE : ASANA_ONBOARDING_STATE}`;
  const win = window.open(ASANA_BASE_URL, "_parent");
  if (!win) return;
  win.focus();
};

const TODOIST_AUTH_URL = "https://todoist.com/oauth/access_token";
const TODOIST_LOGIN_URL = `https://todoist.com/oauth/authorize?client_id=${process.env.REACT_APP_TODOIST_CLIENT_ID}&scope=data:read_write,data:delete&state=${process.env.REACT_APP_TODOIST_CLIENT_SECRET}`;
export const TODOIST_ONBOARDING_STATE = "c163059a-9372-11ed-a1eb-0242ac120002";
const TODOIST_LOGIN_URL_ONBOARDING = `https://todoist.com/oauth/authorize?client_id=${process.env.REACT_APP_TODOIST_CLIENT_ID}&scope=data:read_write,data:delete&state=${TODOIST_ONBOARDING_STATE}`;

export const makeTodoistIntegration = (onboarding?: boolean) => {
  const win = window.open(
    onboarding ? TODOIST_LOGIN_URL_ONBOARDING : TODOIST_LOGIN_URL,
    "_parent"
  );
  if (!win) return;
  win.focus();
};

export const settodoistId = async (code: string) => {
  try {
    const result = await axios.post(TODOIST_AUTH_URL, {
      code,
      client_id: process.env.REACT_APP_TODOIST_CLIENT_ID,
      client_secret: process.env.REACT_APP_TODOIST_CLIENT_SECRET,
    });
    const { access_token } = result.data;
    if (access_token) {
      if (access_token) {
        await new ThirdPartyInformationResource().createTodoistProvider(
          access_token
        );
      }
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const deleteIntegration = async (
  provider: string,
  thirdPartyInformationData: ThirdPartyInformationEntity[] | []
) => {
  for (let i = 0; i < thirdPartyInformationData.length!; i++) {
    const integration = thirdPartyInformationData[i];
    if (integration.provider === provider) {
      await new ThirdPartyInformationResource().removeOne(integration.id);
    }
  }
};
