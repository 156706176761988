import { countries, ICountry } from "assets/countries/countries";
import { AuthV2Resource } from "communicators/resources/authV2/authV2.resource";
import { ProfileResource } from "communicators/resources/profile/profile.resource";
import { FirebaseService } from "communicators/services/firebase/Firebase.service";
import { containsOnlyNumbers } from "helpers/utils.helper";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  clearUserData,
  setUserInfo,
} from "redux-store/actions/user-settings.action";
import { useAppDispatch, useAppSelector } from "redux-store/store.hooks";
import { LocalStorageParam } from "constants/local-storage.constant";
import {
  setPreferencesDialog,
  setPreferencesTab,
} from "redux-store/actions/home-page.actions";
import { auth } from "helpers/common.helper";
import { DateTime } from "luxon";
import { PreferenceTab } from "../PreferencesDialog/PreferenceDialog.types";

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

export const usePreferencesAccountHook = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { userInfo, subscriptions } = useAppSelector((state) => ({
    userInfo: state.userSetting.userInfo,
    subscriptions: state.userSetting.subscriptions,
  }));

  const [fullname, setFullname] = useState<string>(
    userInfo?.attributes.full_name || ""
  );
  const [email, setEmail] = useState<string>(userInfo?.attributes.email || "");
  const [loading, setLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const initialCountry = countries.find(
    (country) =>
      country.callingCode ===
      userInfo?.attributes?.mobile_phone?.slice(1, -10).toString()
  );
  const [phone, setPhone] = useState<string>(
    userInfo?.attributes?.mobile_phone?.slice(-10) || ""
  );
  const [selectedCountry, setSelectedCountry] = useState<ICountry | undefined>(
    initialCountry
  );
  const ref = useRef<null | HTMLDivElement>(null);
  const [isDeleteAccountWarningShown, setIsDeleteAccountWarningShown] =
    useState<boolean>(false);

  const [isPhoneNumberChanged, setIsPhoneNumberChanged] =
    useState<boolean>(false);

  const [isChangePhoneNumberWarningShown, setIsChangePhoneNumberWarningShown] =
    useState<boolean>(false);

  const onChangePhone = (text: string) => {
    const trimmed = text.trim().replace(" ", "");
    if (trimmed === "" || containsOnlyNumbers(trimmed)) {
      setPhone(text.trim());
      setIsPhoneNumberChanged(true);
    }
  };

  useEffect(() => {
    if (phone.length === 10 && isPhoneNumberChanged) {
      const delayDebounceFn = setTimeout(() => {
        setIsChangePhoneNumberWarningShown(true);
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    } else {
      setIsChangePhoneNumberWarningShown(false);
    }
  }, [phone, isPhoneNumberChanged]);

  const onPressDontChangePhoneNumber = () => {
    setIsChangePhoneNumberWarningShown(false);
  };

  const logout = async () => {
    setLoading(true);
    await new FirebaseService().logout();
    new AuthV2Resource().logout();
    document.body.removeAttribute("style");
    dispatch(clearUserData());
    dispatch(setPreferencesDialog(false));
    navigate("/welcome");
    localStorage.clear();
    setLoading(false);
  };

  const onPressCancel = () => {
    setEmail(userInfo?.attributes.email || "");
    setFullname(userInfo?.attributes.full_name || "");
  };

  const onPressSave = async () => {
    if (!userInfo) return;
    setSaveLoading(true);
    try {
      await new ProfileResource().updateOne(userInfo.attributes.firebase_uuid, {
        user: {
          email: email,
          mobile_phone: userInfo.attributes?.mobile_phone,
          full_name: fullname,
          time_zone: timeZone,
          onboarding_completed: userInfo.attributes?.onboarding_completed,
        },
      });

      dispatch(
        setUserInfo({
          ...userInfo,
          attributes: {
            ...userInfo.attributes,
            full_name: fullname,
            email: email,
          },
        })
      );
    } catch (_) {}
    setSaveLoading(true);
  };

  const localStorageItems = [
    LocalStorageParam.TOKEN,
    LocalStorageParam.FIREBASE_UUID,
    LocalStorageParam.ONBOARDING_SIGNUP_BEFORE_SHOWN,
    LocalStorageParam.ONBOARDING_TOOLS_SHOWN,
    LocalStorageParam.ONBOARDING_CREATE_REMINDER_SHOWN,
  ];

  const onPressRedeemCode = () => {
    dispatch(setPreferencesTab(PreferenceTab.REDEEM_CODE));
  };

  const deleteAccount = () => {
    setLoading(true);
    if (!userInfo) return;
    new ProfileResource()
      .removeOne(userInfo?.attributes.firebase_uuid)
      .then(async () => {
        localStorageItems.map(async (item) => {
          await localStorage.removeItem(item);
        });
        setLoading(false);
        navigate("/");
        dispatch(clearUserData());
      });
  };

  const scrollToBottom = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (isDeleteAccountWarningShown) {
      scrollToBottom();
    }
  }, [isDeleteAccountWarningShown]);

  const onPressDeleteAccount = () => {
    setIsDeleteAccountWarningShown(!isDeleteAccountWarningShown);
  };

  const onPressDelete = () => {
    deleteAccount();
  };

  const onPressKeep = () => {
    setIsDeleteAccountWarningShown(false);
  };

  const trialingSubscriptions = subscriptions.filter(
    (item) => item?.attributes?.status === "trialing"
  );

  const activeSubscription = subscriptions.filter(
    (item) => item?.attributes?.status === "active"
  );

  const endOfPremium = DateTime.fromISO(
    activeSubscription[0]?.attributes?.current_period_end.split(" ")[0]
  );

  const isFreePlanShown =
    trialingSubscriptions.length !== 0 &&
    !userInfo?.attributes.is_premium &&
    !userInfo?.attributes.is_lifetime;

  const isPremiumWillEnd =
    activeSubscription[0]?.attributes?.cancel_at_period_end && !isFreePlanShown;

  const loginType = auth.currentUser?.providerData[0].providerId;

  const showSaveButton =
    (userInfo?.attributes.full_name &&
      userInfo?.attributes.full_name !== fullname) ||
    (!userInfo?.attributes.full_name && fullname) ||
    (!userInfo?.attributes.email && email) ||
    (userInfo?.attributes.email && userInfo?.attributes.email !== email);

  return {
    fullname,
    email,
    loading,
    showSaveButton,
    saveLoading,
    userInfo,
    setEmail,
    setFullname,
    logout,
    onPressSave,
    onPressCancel,
    setSelectedCountry,
    onChangePhone,
    phone,
    onPressDelete,
    onPressKeep,
    ref,
    isDeleteAccountWarningShown,
    onPressDeleteAccount,
    isChangePhoneNumberWarningShown,
    onPressDontChangePhoneNumber,
    loginType,
    deleteAccount,
    selectedCountry,
    setLoading,
    navigate,
    isFreePlanShown,
    isPremiumWillEnd,
    endOfPremium,
    onPressRedeemCode,
  };
};
