import React from "react";
import { Button, ButtonProps } from "@mui/material";
import { palette } from "assets/palette";
import { Text } from "elements/text/Text";
import { Icon } from "elements/icon/Icon";
import { IconName, IconProps } from "elements/icon/Icon.type";
import { Spinner } from "elements/spinner/Spinner";
interface Props extends ButtonProps {
  text?: string;
  onPress?: () => void;
  style?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  fullWidth?: boolean;
  leftIconProps?: IconProps;
  backgroundColor?: string;
  loading?: boolean;
  showCheckbox?: boolean;
  checked?: boolean;
  spinnerColor?:
  | "error"
  | "primary"
  | "inherit"
  | "success"
  | "warning"
  | "secondary"
  | "info";
}

export const CustomButton = ({
  text,
  onPress,
  style,
  fullWidth,
  variant = "contained",
  leftIconProps,
  loading,
  disabled,
  backgroundColor = palette.orange.dark,
  spinnerColor,
  textStyle,
  showCheckbox,
  checked,
  ...rest
}: Props) => {
  const determineButtonStyle = () => {
    if (disabled) return styles.buttonDisabled;
    if (variant === "outlined") {
      return styles.outlinedButton;
    }
    if (variant === "text") {
      return styles.textTypeButton;
    }

    return { ...styles.button, backgroundColor };
  };

  const determineTextStyle = () => {
    if (variant === "outlined") {
      return styles.outlinedText;
    }
    if (variant === "text") {
      return styles.textTypeText;
    }
    return styles.text;
  };

  const buttonStyle = determineButtonStyle();
  const variantedTextStyle = { ...determineTextStyle(), ...textStyle };

  return (
    <Button
      style={{
        ...buttonStyle,
        width: fullWidth ? "100%" : undefined,
        ...style,
        ...(disabled ? { backgroundColor: palette.gray.mediumLight } : {})
      }}
      variant={variant}
      onClick={onPress}
      disabled={loading || disabled}
      {...rest}
    >
      {leftIconProps && !loading ? (
        <Icon width={30} height={30} {...leftIconProps} />
      ) : null}
      {!loading ? <Text style={variantedTextStyle}>{text}</Text> : null}
      {!loading && showCheckbox ? (
        <div style={checked ? styles.filledCheckbox : styles.checkboxContainer}>
          {checked ? (
            <Icon
              name={IconName.TICK}
              width={10}
              height={10}
              fill={palette.white}
            />
          ) : null}
        </div>
      ) : null}
      <Spinner color={spinnerColor} loading={loading} />
    </Button>
  );
};

const styles: Record<string, React.CSSProperties> = {
  button: {
    backgroundColor: palette.orange.dark,
    textTransform: "none",
    borderRadius: 10,
    paddingTop: 8,
    paddingBottom: 8,
  },
  buttonDisabled: {
    backgroundColor: palette.gray.mediumLight,
    textTransform: "none",
    borderRadius: 10,
    paddingTop: 8,
    paddingBottom: 8,
  },
  outlinedButton: {
    backgroundColor: palette.white,
    textTransform: "none",
    borderRadius: 6,
    borderColor: palette.black,
    paddingTop: 8,
    paddingBottom: 8,
  },
  text: {
    fontWeight: "600",
    fontSize: 20,
    color: palette.white,
  },
  outlinedText: {
    fontWeight: "400",
    fontSize: 20,
    color: palette.black,
  },
  textTypeButton: {
    backgroundColor: palette.white,
    textTransform: "none",
    borderRadius: 6,
    paddingTop: 8,
    paddingBottom: 8,
  },
  textTypeText: {
    fontWeight: "600",
    fontSize: 20,
    color: palette.black,
  },
  checkboxContainer: {
    width: 20,
    height: 20,
    border: `1px solid ${palette.black}`,
    marginLeft: 30,
    borderRadius: 10,
    position: "absolute",
    right: 20,
  },
  filledCheckbox: {
    width: 20,
    height: 20,
    border: `1px solid ${palette.black}`,
    backgroundColor: palette.black,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 30,
    borderRadius: 10,
    position: "absolute",
    right: 20,
  },
};
