import React from "react";
import { DateTime } from "luxon";
import { Calendar } from "components/calendar/Calendar";
import { i18n } from "constants/i18n.constants";
import "./DueDateSelection.scss";

interface Props {
  onSelectDueDate: (date: DateTime) => void;
  isSomeday?: boolean;
  selectedDate?: DateTime;
}

export const DueDateSelection = ({
  onSelectDueDate,
  isSomeday = false,
  selectedDate,
}: Props) => {
  const somedaySelection = () => {
    onSelectDueDate(DateTime.utc(3000, 1, 1, 0, 0, 0, 0));
  };
 
  return (
    <div style={styles.container}>
      <Calendar
        onSelectDateCallback={onSelectDueDate}
        selectedDate={selectedDate}
      />
      <button
        className={
          isSomeday ? "selected-someday-button" : "unselected-someday-button"
        }
        onClick={somedaySelection}
      >
        {i18n.t("someday")}
      </button>
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  container: {
    marginLeft: -10,
  },
};
