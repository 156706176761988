import { LocalStorageParam } from "constants/local-storage.constant";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setFirebaseUuid,
  setSubscriptions,
  setUserBearerToken,
  setUserInfo,
} from "redux-store/actions/user-settings.action";
import { useAppDispatch } from "redux-store/store.hooks";
import { DateTime } from "luxon";
import { ProfileResource } from "communicators/resources/profile/profile.resource";
import { ProfileEntity } from "communicators/resources/profile/profile.type";
import { ResponseWithData } from "communicators/types";
import { FirebaseService } from "communicators/services/firebase/Firebase.service";
import { setThirdPartyProviderData } from "redux-store/actions/third-party-provider.actions";
import { SubscriptionsResource } from "communicators/resources/subscription/subscription.resource";
import { useUserSettingHook } from "hooks/useUserSettingHook";
import { APPSUMO_REDEEM_CODE_STATE } from "helpers/third-party.helper";
import { setRedeemOpened } from "redux-store/actions/home-page.actions";

interface TokenData {
  access_token: string;
  token_type: string;
  expires_in: number;
  created_at: number;
  scope: string;
}

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

export const useSplashHook = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const getStateFromQuery = () => {
    const search = location.search;
    if (!search) return;
    const splited = search.split("?")[1];
    if (splited.length < 2) return;
    const typeSplit = splited.split("=");
    if (
      typeSplit[0] === "state" &&
      typeSplit[1] === APPSUMO_REDEEM_CODE_STATE
    ) {
      dispatch(setRedeemOpened(true));
    }
  };

  const { getUserPreferences } = useUserSettingHook();

  const getAndSetBearerToken = async () => {
    const stored = localStorage.getItem(LocalStorageParam.TOKEN);
    if (stored) {
      const parsedTokenData: TokenData = JSON.parse(stored);
      const expireDatTimestamp =
        parsedTokenData.created_at + parsedTokenData.expires_in;
      if (DateTime.fromSeconds(expireDatTimestamp) < DateTime.now()) {
        // TODO: Get new token with expire token
      } else {
        dispatch(setUserBearerToken(parsedTokenData.access_token));
      }
      return;
    }
  };

  const setSubscriptionInfo = async () => {
    try {
      const response = await new SubscriptionsResource().readMany();
      dispatch(setSubscriptions(response.data));
      return response.data;
    } catch (_) {}
    return;
  };

  const handleAuthStateChange = async () => {
    const storedFirebaseUid = localStorage.getItem(
      LocalStorageParam.FIREBASE_UUID
    );
    if (storedFirebaseUid) {
      dispatch(setFirebaseUuid(storedFirebaseUid));
      dispatch(setThirdPartyProviderData());
    }
    await getAndSetBearerToken();
    await getUserInfo(storedFirebaseUid);
    const subscriptions = await setSubscriptionInfo();
    await getUserPreferences();
    const page = storedFirebaseUid
      ? await getOnboardingRecentPage(storedFirebaseUid, subscriptions)
      : "/welcome";
    navigate(page, page === "/home" ? { replace: true } : undefined);
  };

  const getUserInfo = async (userUid: string | null) => {
    if (!userUid) return;
    try {
      const { data }: ResponseWithData<ProfileEntity> =
        await new ProfileResource().readOne(userUid);
      if (!data) {
        new FirebaseService().logout();
      }
      await new ProfileResource().updateOne(data.attributes.firebase_uuid, {
        user: {
          email: data?.attributes?.email,
          mobile_phone: data?.attributes?.mobile_phone,
          full_name: data?.attributes?.full_name,
          time_zone: timeZone,
          onboarding_completed: data?.attributes?.onboarding_completed,
        },
      });
      dispatch(setUserInfo(data));
    } catch (error) {
      navigate("/login");
    }
  };

  const getOnboardingRecentPage = async (
    userUid: string,
    subscriptions?: any[]
  ) => {
    const { data }: ResponseWithData<ProfileEntity> =
      await new ProfileResource().readOne(userUid);
    if (data?.attributes.onboarding_completed) {
      if (
        subscriptions?.length === 0 &&
        !data?.attributes.is_lifetime &&
        !data.attributes.is_premium
      ) {
        return "/onboarding/signup-before";
      }
      return "/home";
    } else {
      const onboardingSignupBefore = localStorage.getItem(
        LocalStorageParam.ONBOARDING_SIGNUP_BEFORE_SHOWN
      );
      const onboardingCreateReminder = localStorage.getItem(
        LocalStorageParam.ONBOARDING_CREATE_REMINDER_SHOWN
      );
      const onboardingPlanTime = localStorage.getItem(
        LocalStorageParam.ONBOARDING_PLAN_TIME_SHOWN
      );
      const onboardingTools = localStorage.getItem(
        LocalStorageParam.ONBOARDING_TOOLS_SHOWN
      );
      if (onboardingCreateReminder) {
        return "/home";
      }
      if (onboardingPlanTime) {
        return "/onboarding/create-reminder";
      }
      if (onboardingTools) {
        return "/onboarding/plan-time";
      }
      if (onboardingSignupBefore) {
        return "/onboarding/tools";
      }

      return "/onboarding/tools";
    }
  };

  useEffect(() => {
    handleAuthStateChange();
    getStateFromQuery();
  }, []);

  return {};
};
