import { CircularProgress, CircularProgressProps } from "@mui/material";
import React from "react";

interface Props extends CircularProgressProps {
  loading?: boolean;
  style?: React.CSSProperties;
  size?: string | number;
}

export const Spinner = ({ loading, color = "inherit", style, size }: Props) => {
  return loading ? (
    <CircularProgress size={size ? size : 24} color={color} style={style} />
  ) : null;
};
