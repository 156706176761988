import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import { palette } from "assets/palette";

interface Props {
  value: number;
  style?: React.CSSProperties;
  progressProps?: LinearProgressProps;
}

export const ProgressBar = ({ value, style, progressProps }: Props) => {
  return (
    <div style={{ ...styles.container, ...style }}>
      <LinearProgress
        style={{
          ...styles.progress,
          backgroundColor: palette.gray.iceLight,
        }}
        variant="determinate"
        value={value}
        color={"warning"}
        {...progressProps}
      />
    </div>
  );
};

const styles = {
  container: {
    height: 10,
    width: 400,
  },
  progress: { height: 8, borderRadius: 4 },
};
