import { SearchResource } from "communicators/resources/search/search.resource";
import { AllBoardDataEntityType } from "interfaces/main.interfaces";
import { useEffect, useState } from "react";

export const useSearchDialogHook = () => {
  const [searchedText, setSearchedText] = useState("");
  const [searchItems, setSearchItems] = useState<AllBoardDataEntityType[]>([]);

  const getSearchedData = async (text: string) => {
    const response = await new SearchResource().readMany({
      count: 20,
      query: text,
    });
    if (response && response.data) {
      setSearchItems(response.data);
    }
  };

  const getInitialSearchedData = async () => {
    const response = await new SearchResource().readMany({
      count: 100,
    });
    if (response && response.data) {
      setSearchItems(response.data.slice(0, 10));
    }
  };

  const onChangeSearchInput = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchedText(event.target.value);
    getSearchedData(event.target.value);
  };

  useEffect(() => {
    getInitialSearchedData();
  }, []);

  return { searchedText, searchItems, onChangeSearchInput };
};
