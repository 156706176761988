import { DNDEvent } from "constants/dnd.constants";
import { Dispatch } from "redux";
import { DNDDispatchTypes } from "redux-store/types/dispatch.type";
import { SetDragingEventAction } from "redux-store/types/dnd.action.type";

export const setDragingEvent = (dragingEvent: DNDEvent | null) => {
  return (dispatch: Dispatch): void => {
    dispatch<SetDragingEventAction>({
      type: DNDDispatchTypes.setDragingEvent,
      payload: dragingEvent,
    });
  };
};
