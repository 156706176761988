import { i18n } from "constants/i18n.constants";
import { Text } from "elements/text/Text";
import React from "react";
import "./LoginText.scss";

interface Props {
  onPressLogin?: () => void;
}

export const LoginText = ({ onPressLogin }: Props) => {
  return (
    <Text className="first-text">
      {i18n.t("already_signed_up")}
      {"\xa0"}
      <Text onPress={onPressLogin} className="login-text">
        {i18n.t("login")}
      </Text>
    </Text>
  );
};
