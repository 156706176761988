import { ResourceManagementService } from "communicators/services/ResourceManagement.service";
import { IProfileResource } from "./profile.type";

export class ProfileResource extends ResourceManagementService<IProfileResource> {
	constructor() {
		super("USER");
	}

	updateAndReadOne = async (
		id: string,
		candidate: Partial<IProfileResource["candidate"]>,
		query?: IProfileResource["query"]
	) => {
		await this.updateOne(id, candidate);
		return await this.readOne(id, query);
	};
}
