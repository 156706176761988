import { HabitTargetEntity } from "communicators/resources/event-resources/habit-target/habit-target.type";
import { MeetEntity } from "communicators/resources/event-resources/meets/meets.type";
import { TaskEntity } from "communicators/resources/event-resources/tasks/tasks.type";
import { DateTime } from "luxon";

export type AllBoardDataEntityType =
  | MeetEntity
  | HabitTargetEntity
  | TaskEntity;

export interface IBoard {
  title: string;
  date: DateTime;
  data: AllBoardDataEntityType[];
}

export enum ThirdPartyProvider {
  ASANA = "asana",
  TODOIST = "todoist",
  GOOGLE = "google",
  JIRA = "jira",
  M_OUTLOOK = "outlook",
  NOTION = "notion",
  HUBSPOT = "hubspot",
  CLICKUP = "clickup",
}

export enum SecondDrawerType {
  INBOX_TASKS = "inbox_tasks",
  COMPLETED_TASKS = "completed_tasks",
  SOMEDAY_TASKS = "someday_tasks",
  ASANA = "asana",
  TODOIST = "todoist",
  GOOGLE = "google",
}

export enum LeftDrawerTypes {
  COMPLETED_TASKS = "Completed Tasks",
  SOMEDAY_TASKS = "Someday",
}
