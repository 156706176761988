import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { Text } from "elements/text/Text";
import { i18n } from "constants/i18n.constants";
import { resizeByResolutionWidth } from "helpers/utils.helper";
import "./MagicPlannerPopup.scss";
import { MagicPlannerDetail } from "./components/MagicPlannerDetail";
import { useMagicPlannerPopupHook } from "./useMagicPlannerPopupHook";

interface MagicPlannerPopupProps {
  magicPlannerText: string;
  onClickMagicPlan?: () => void;
}

export const MagicPlannerPopup = ({
  magicPlannerText,
  onClickMagicPlan
}: MagicPlannerPopupProps) => {
  const { isDetailShown, toggleDetail, popupRef, popupDetailRef } = useMagicPlannerPopupHook();
  return (
    <div className="magic-planner-popup" id="magic-planner-popup" ref={popupRef}>
      <div className="magic-planner-left-container">
        <Icon
          name={IconName.MAGIC_PLANNER}
          width={resizeByResolutionWidth(29)}
          height={resizeByResolutionWidth(30)}
        />
        <Text className="magic-planner-popup-text">
          {i18n.t("magic_planner")}
        </Text>
      </div>
      <button className="magic-planner-button" onClick={toggleDetail}>
        {i18n.t("open")}
      </button>
      {isDetailShown ? (
        <MagicPlannerDetail
          popupDetailRef={popupDetailRef}
          id="magic-planner-detail-container"
          text={magicPlannerText}
          onClickYes={onClickMagicPlan}
          onClickNo={toggleDetail}
        />
      ) : null}
    </div>
  );
};
