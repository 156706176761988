import { PreferenceTab } from "pages/private/home/components/PreferencesDialog/PreferenceDialog.types";
import { HomePageDispatchTypes } from "redux-store/types/dispatch.type";
import { AllHomePageActionTypes } from "redux-store/types/home-page.action.type";
import { HomePageState } from "redux-store/types/state.type";

const INITIAL_STATE: HomePageState = {
  selectedPreferencesTab: PreferenceTab.ACCOUNT,
  preferencesDialogOpened: false,
  openedMeetDetail: null,
  somethingWentWrongModalOpened: false,
  refreshCompletedTasks: false,
  refreshSomedayTasks: false,
  refreshBoardData: false,
  refreshInboxTasks: false,
  selectedPermissionModalType: null,
  taskCreatingLoading: false,
  insumoCalendarEvent: [],
  redeemOpen: false,
  tagsModalVisible: false,
  availableMagicPlannerFreeSpot: 0,
};

export const homePageReducer = (
  state = INITIAL_STATE,
  action: AllHomePageActionTypes
) => {
  switch (action.type) {
    case HomePageDispatchTypes.setPreferencesTab:
      return {
        ...state,
        selectedPreferencesTab: action.payload,
      };
    case HomePageDispatchTypes.setPreferencesDialog:
      return {
        ...state,
        preferencesDialogOpened: action.payload,
      };
    case HomePageDispatchTypes.setOpenedMeetDetail:
      return {
        ...state,
        openedMeetDetail: action.payload,
      };
    case HomePageDispatchTypes.setSomethingWentWrongModal:
      return {
        ...state,
        somethingWentWrongModalOpened: action.payload,
      };
    case HomePageDispatchTypes.refreshCompletedTasks:
      return {
        ...state,
        refreshCompletedTasks: !state.refreshCompletedTasks,
      };
    case HomePageDispatchTypes.refreshSomedayTasks:
      return {
        ...state,
        refreshSomedayTasks: !state.refreshSomedayTasks,
      };
      case HomePageDispatchTypes.refreshInboxTasks:
      return {
        ...state,
        refreshInboxTasks: !state.refreshInboxTasks,
      };
    case HomePageDispatchTypes.refreshBoardData:
      return {
        ...state,
        refreshBoardData: !state.refreshBoardData,
      };
    case HomePageDispatchTypes.setPermissionModalType:
      return {
        ...state,
        selectedPermissionModalType: action.payload,
      };
    case HomePageDispatchTypes.setTaskCreatingLoading:
      return {
        ...state,
        taskCreatingLoading: action.payload,
      };
    case HomePageDispatchTypes.setInsumoCalendarEvent:
      return {
        ...state,
        insumoCalendarEvent: action.payload,
      };
    case HomePageDispatchTypes.setRedeemOpen:
      return {
        ...state,
        redeemOpen: action.payload,
      };
    case HomePageDispatchTypes.setManageTagsModalVisible:
      return {
        ...state,
        tagsModalVisible: action.payload,
      };
      case HomePageDispatchTypes.setAvailableMagicPlannerFreeSpots:
      return {
        ...state,
        availableMagicPlannerFreeSpot: action.payload,
      };
    default:
      return state;
  }
};
