import { i18n } from "constants/i18n.constants";
import {
  createAndGetUser,
  getUserRedirectionAfterLogin,
} from "helpers/auth.helper";
import { containsOnlyNumbers } from "helpers/utils.helper";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { setThirdPartyProviderData } from "redux-store/actions/third-party-provider.actions";
import { setFirebaseUuid } from "redux-store/actions/user-settings.action";
import { useAppDispatch } from "redux-store/store.hooks";

export const useVerifyPhoneHook = () => {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onChangeCode = (text: string) => {
    const trimmed = text.trim().replace(" ", "");
    if (trimmed === "" || containsOnlyNumbers(trimmed)) {
      setCode(text.trim());
    }
  };

  const onPressSubmit = async () => {
    if (!window.confirmationResult) return;
    setLoading(true);
    try {
      const confirmationUser = await window.confirmationResult.confirm(code);
      if (confirmationUser) {
        dispatch(setFirebaseUuid(confirmationUser.user.uid));
        const idToken = await confirmationUser.user.getIdToken();
        const { isNewUser, user } = await createAndGetUser({
          firebase_uuid: confirmationUser.user.uid,
          mobile_phone: location.state.phoneNumber,
          full_name: confirmationUser.user.displayName || undefined,
          email: confirmationUser.user.email || undefined,
          idToken,
        });
        dispatch(setThirdPartyProviderData());
        navigate(await getUserRedirectionAfterLogin(isNewUser, user), await getUserRedirectionAfterLogin(isNewUser, user) === "/home" ? { replace: true } : undefined);
      } else {
        setError(i18n.t("invalid_verification_code"));
      }
    } catch (error) {
      setError(i18n.t("invalid_verification_code"));
    }
    setLoading(false);
  };

  return { code, loading, error, onChangeCode, onPressSubmit };
};
