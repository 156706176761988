import { Tooltip } from "@mui/material";
import { palette } from "assets/palette";
import { ReactElement } from "react";

interface Props {
    children: ReactElement<any, any>;
    direction: 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
    description?: string;
}

export const CustomTooltip = ({
  children,
  direction,
  description
}: Props) => {
  return (
    <Tooltip title={description} PopperProps={{sx: {
        "& .MuiTooltip-tooltip": {
          color: palette.gray.mediumLight,
          backgroundColor: palette.black,
          fontWeight: "600",
          fontSize: 10,
          fontFamily: "Gilroy-SemiBold",
        }
      },
      style: {
        zIndex: 1200,
      },
      placement: direction,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, -10]
          }
        }
      ]}}>
        {children}
    </Tooltip>
  );
};