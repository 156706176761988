import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from "redux-store/store.hooks";

export const useUnsubscriptionDialogHook = () => {
  const navigate = useNavigate();

  const { subscriptions } = useAppSelector((state) => ({
    subscriptions: state.userSetting.subscriptions
  }));

  const onPressUpgradeNow = () => {
    navigate("/checkout");
  };

  const premiumUntil = DateTime.fromSQL(subscriptions[0]?.attributes?.current_period_end.slice(0, 10)).toFormat("MMM dd, yyyy");

  return {
    premiumUntil,
    onPressUpgradeNow
  };
};
