import React from "react";
import { palette } from "assets/palette";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { Text } from "elements/text/Text";
import { AllBoardDataEntityType } from "interfaces/main.interfaces";
import { useSearcDialogItemHook } from "./useSearchDialogItemHook";

interface Props {
  data: AllBoardDataEntityType;
  onClick: (data: AllBoardDataEntityType) => void;
}

export const SearchDialogItem = (props: Props) => {
  const { leftElement, title, date, time } = useSearcDialogItemHook(props);

  return (
    <>
      <div style={styles.separator} />
      <div style={styles.container} onClick={() => props.onClick(props.data)}>
        <div style={styles.mainLeftContainer}>
          {leftElement.type === "icon" ? (
            <Icon name={leftElement.source as IconName} />
          ) : null}
          {leftElement.type === "image" ? (
            <img
              style={styles.image}
              src={leftElement.source as string}
              alt="leftIconImage"
            />
          ) : null}
          {leftElement.type === "div" ? (
            <div style={leftElement.source as React.CSSProperties} />
          ) : null}
          <div style={styles.gap} />
          <div style={styles.titleEventTypeContainer}>
            <Text style={styles.title}>{title}</Text>
            <Text style={styles.eventType}>{leftElement.text}</Text>
          </div>
        </div>
        <div style={styles.columnContainer}>
          <Text style={styles.date}>{date}</Text>
          <Text style={styles.time}>{time}</Text>
        </div>
      </div>
    </>
  );
};

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: palette.white,
    margin: "20px 45px",
    cursor: "pointer"
  },
  mainLeftContainer: {
    display: "flex",
    flexDirection: "row",
  },
  columnContainer: {
    display: "flex",
    flexDirection: "column",
  },
  image: {
    width: 18,
    height: 18,
  },
  gap: {
    width: 28,
  },
  titleEventTypeContainer: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    color: palette.gray.dark,
    fontSize: 18,
    fontFamily: "Gilroy-Semibold",
    fontWeight: "600",
  },
  eventType: {
    color: palette.gray.medium,
    fontSize: 12,
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
  },
  date: {
    color: palette.gray.medium,
    fontSize: 15,
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
  },
  time: {
    color: palette.gray.medium,
    fontSize: 12,
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
  },
  separator: {
    height: 1,
    width: "100%",
    backgroundColor: palette.gray.light,
  },
};
