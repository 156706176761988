import { Grid } from "@mui/material";
import React from "react";

interface Props {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  xs?: number;
  sm?: number;
  md?: number;
  component?: any;
  elevation?: number;
  square?: boolean;
  className?: string;
}

export const GridItem = ({ children, style, ...rest }: Props) => {
  return (
    <Grid item {...rest} style={{ ...styles.grid, ...style }}>
      {children}
    </Grid>
  );
};

const styles: Record<string, React.CSSProperties> = {
  grid: {
    padding: "0 15px !important",
  },
};
