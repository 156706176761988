import React from "react";
import { Text } from "elements/text/Text";
import { i18n } from "constants/i18n.constants";
import { Divider } from "elements/divider/Divider";
import { palette } from "assets/palette";
import { usePreferencesIntegrationsHook } from "./usePreferencesIntegrationsHook";
import { FullScreenSpinner } from "elements/spinner/FullScreenSpinner";
import { CustomButton } from "elements/button/Button";
import { IOSSwitch } from "elements/switch/Switch";

interface Props {
  refreshBoards: () => void;
  googleSignIn: any;
  googleAuthLoading: boolean;
}

export const PreferencesIntegrations = (props: Props) => {
  const {
    INTEGRATIONS_LIST,
    loading,
    deletingProvider,
    onPressNoKeep,
    onPressYesDelete
  } = usePreferencesIntegrationsHook(props);
  return (
    <div style={styles.container}>
      <Text style={styles.headerText}>{i18n.t("integrate_tools")}</Text>
      <div style={styles.integrationList}>
        {INTEGRATIONS_LIST.map((item) => {
          return (
            <div key={item.key}>
              <div style={styles.rowContainer}>
                <img
                  style={item.isComingSoon ? styles.comingSoonIntegrationImage : styles.integrationImage}
                  src={item.image}
                  alt={item.key}
                />
                <Text style={styles.rowText}>{item.text}</Text>
                {item.isComingSoon ? <Text style={styles.comingSoon}>{i18n.t("coming_soon")}</Text> :
                  <IOSSwitch
                    onChange={item.onPress}
                    checked={item.isActive}
                  />}
              </div>
              {deletingProvider === item.key ? (
                <div>
                  <Text style={styles.deleteText}>
                    {i18n.t("are_you_sure_delete_integration")}
                  </Text>
                  <div style={styles.buttonRow}>
                    <CustomButton
                      style={styles.leftButton}
                      variant="outlined"
                      text={i18n.t("no_keep")}
                      textStyle={styles.leftButtonText}
                      onPress={onPressNoKeep}
                    />
                    <CustomButton
                      style={styles.rightButton}
                      variant="outlined"
                      text={i18n.t("yes_delete")}
                      textStyle={styles.rightButtonText}
                      onPress={onPressYesDelete}
                    />
                  </div>
                </div>
              ) : null}
              <Divider color={palette.gray.light} />
            </div>
          );
        })}
      </div>
      <div style={styles.rowContainer}></div>
      <FullScreenSpinner loading={loading} />
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flex: 1,
    alignSelf: "stretch",
    flexDirection: "column",
  },
  headerText: {
    fontWeight: "600",
    fontSize: 18,
    marginBottom: 28,
  },
  nameInputWrapper: {
    width: 180,
  },
  emailHeaderText: {
    fontWeight: "bold",
    fontSize: 18,
    marginBottom: 24,
    marginTop: 24,
  },
  rowContainer: {
    display: "flex",
    alignSelf: "stretch",
    alignItems: "center",
    paddingTop: 20,
    paddingBottom: 20,
  },
  rowText: {
    fontSize: 15,
    display: "flex",
    flex: 1,
    flexDirection: "row",
    color: palette.gray.dark,
  },
  integrationImage: {
    width: 25,
    height: 25,
    marginRight: 20,
  },
  comingSoonIntegrationImage: {
    width: 25,
    height: 25,
    marginRight: 20,
    opacity: 0.5
  },
  deleteText: {
    fontSize: 15,
    fontWeight: "400",
    fontFamily: "Gilroy",
    color: palette.gray.dark,
  },
  buttonRow: {
    display: "flex",
    marginTop: 20,
    marginBottom: 10,
  },
  leftButton: {
    borderColor: palette.gray.dark,
    width: 140,
    borderRadius: 10,
    marginRight: 13,
  },
  leftButtonText: {
    fontSize: 15,
    fontWeight: "bold",
    color: palette.gray.dark,
  },
  rightButton: {
    borderColor: palette.gray.mediumLight,
    width: 140,
    borderRadius: 10,
    marginRight: 13,
  },
  rightButtonText: {
    fontSize: 15,
    fontWeight: "bold",
    color: palette.gray.mediumLight,
  },
  comingSoon: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 15,
    color: palette.gray.medium,
    marginRight: 15
  },
  integrationList: {
    display: "flex",
    alignSelf: "stretch",
    flexDirection: "column",
    overflowY: "scroll",
    paddingRight: 10,
  }
};
