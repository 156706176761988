import { CustomButton } from "elements/button/Button";
import { Text } from "elements/text/Text";
import { i18n } from "constants/i18n.constants";
import { palette } from "assets/palette";
import "./DeleteAccountWarning.scss";

interface Props {
  onPressYes?: () => void;
  onPressNo?: () => void;
}

export const DeleteAccountWarning = ({ onPressYes, onPressNo }: Props) => {
  return (
    <div className="container">
        <Text className="title">{i18n.t("are_you_sure_delete_account")}</Text>
        <div className="button-container">
            <CustomButton style={styles.noButton} text={i18n.t("no_keep")} textStyle={styles.noButtonTextStyle} onClick={onPressNo} />
            <CustomButton style={styles.yesButton} text={i18n.t("yes_delete")} textStyle={styles.yesButtonTextStyle} onClick={onPressYes} />
        </div>
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
    yesButton: {
      width: 150,
      height: 50,
      backgroundColor: palette.white,
      border: `1px solid ${palette.gray.mediumLight}`,
    },
    yesButtonTextStyle: {
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "500",
      fontSize: 15,
      color: palette.gray.mediumLight
    },
    noButton: {
      width: 150,
      height: 50,
      backgroundColor: palette.white,
      border: `1px solid ${palette.gray.dark}`,
    },
    noButtonTextStyle: {
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "500",
      fontSize: 15,
      color: palette.gray.dark
    },
  };