import { palette } from "assets/palette";
import { i18n } from "constants/i18n.constants";
import { CustomButton } from "elements/button/Button";
import React, { useState } from "react";
import { Text } from "elements/text/Text";
import { useCompleteYourPurchase } from "./useCompleteYourPurchase";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { resizeByResolutionWidth } from "helpers/utils.helper";
import { AddPaymentMethodItem } from "./components/AddPaymentMethodItem/AddPaymentMethodItem";
import { PaymentMethodItem } from "./components/PaymentMethodItem/PaymentMethodItem";
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
} from "@stripe/react-stripe-js";
import { SubscriptionsResource } from "communicators/resources/subscription/subscription.resource";
import { SubscriptionPlan } from "components/subscription-period-selection/SubscriptionPeriodSelection.type";
import {
  loadStripe,
  StripeCardNumberElement,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";
import {
  AmexImage,
  DinersImage,
  DiscoverImage,
  JCBImage,
  MasterCardImage,
  PaypalImage,
  UnionpayImage,
  VisaImage,
} from "assets/images";
import { PaymentMethodResource } from "communicators/resources/payment-method/payment-method.resource";
import { ErrorNames } from "communicators/insumo/errors/type";
import { setPreferencesTab } from "redux-store/actions/home-page.actions";
import { PreferenceTab } from "../PreferencesDialog/PreferenceDialog.types";
import { useAppDispatch } from "redux-store/store.hooks";
import { SubscriptionEntity } from "communicators/resources/subscription/subscription.type";
import { paymentMethodSelector } from "helpers/common.helper";

export const PreferencesCompleteYourPurchase = () => {
  const {
    yearlyPrice,
    goBack,
    defaultPaymentMethod,
    toggleAddPayment,
    isAddPaymentPressed,
    activeSubscription,
    onPressSubmitPurchase,
  } = useCompleteYourPurchase();

  const PK = process.env.REACT_APP_STRIPE_PK || "";

  const stripePromise = loadStripe(PK);

  return (
    <div style={styles.container}>
      <div style={styles.headerContainer}>
        <Icon name={IconName.BACK_ARROW} onPress={goBack} />
        <Text style={styles.headerText}>
          {i18n.t("complete_your_purchase")}
        </Text>
      </div>
      {isAddPaymentPressed ? (
        <Elements stripe={stripePromise}>
          <Payment
            yearlyPrice={yearlyPrice}
            activeSubscription={activeSubscription}
          />
        </Elements>
      ) : (
        <>
          <div style={styles.paymentMethodContainer}>
            <PaymentMethodItem
              name={defaultPaymentMethod[0]?.billing_details?.name}
              last4CardNumber={defaultPaymentMethod[0]?.attributes?.card?.last4}
              cardExpiryDate={`${defaultPaymentMethod[0]?.attributes?.card?.exp_month
                .toString()
                .padStart(
                  2,
                  "0"
                )}/${defaultPaymentMethod[0]?.attributes?.card?.exp_year
                .toString()
                .slice(-2)}`}
              paymentMethodCardImage={paymentMethodSelector(
                defaultPaymentMethod[0]?.attributes?.card?.brand
              )}
            />
            <AddPaymentMethodItem onClickAddPaymentMethod={toggleAddPayment} />
          </div>
          <div style={styles.bottomContainer}>
            <div style={styles.separator} />
            <Text style={styles.paymentDetails}>
              {i18n.t("payment_details")}
            </Text>
            <div style={styles.horizontalContainer}>
              <Text style={styles.yearlyAmountText}>
                {i18n.t("complete_purchase_yearly_amount_description", {
                  price: `${(yearlyPrice / 12).toFixed(2)}`,
                })}
              </Text>
              <Text style={styles.yearlyAmountText}>
                {i18n.t("usd_amount", {
                  price: `${(yearlyPrice / 12).toFixed(2)}`,
                })}
              </Text>
            </div>
            <div style={styles.separator} />
            <div style={styles.horizontalContainer}>
              <Text style={styles.totalText}>{i18n.t("total")}</Text>
              <div>
                <Text style={styles.totalText}>
                  {i18n.t("usd_amount", { price: `${yearlyPrice.toFixed(2)}` })}
                </Text>
                <Text style={styles.infoText}>{i18n.t("applicable_tax")}</Text>
              </div>
            </div>
            <CustomButton
              style={styles.submitButton}
              text={i18n.t("submit_purchase")}
              textStyle={styles.buttonText}
              onClick={onPressSubmitPurchase}
            />
            <Text style={styles.warning}>
              {i18n.t("submit_purchase_warning", { amount: `$${yearlyPrice.toFixed(2)}` })}
            </Text>
          </div>
        </>
      )}
    </div>
  );
};

export interface PaymentProps {
  yearlyPrice: number;
  activeSubscription: SubscriptionEntity[];
}

const Payment = ({ yearlyPrice, activeSubscription }: PaymentProps) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [addPaymentErrorMessage, setAddPaymentErrorMessage] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useAppDispatch();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (elements == null || !stripe) {
      return;
    }
    const cardElement = elements.getElement(CardNumberElement) || { token: "" };
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });
    if (error) {
      return;
    }
    if (paymentMethod) {
      new PaymentMethodResource()
        .createOne({
          payment_method: {
            id: paymentMethod.id,
          },
        })
        .catch((err) => {
          if (err.name === ErrorNames.CARD_ALREADY_EXISTS)
            setAddPaymentErrorMessage(
              i18n.t("card_already_exists_try_another_card")
            );
        });
    }
    const response = await new SubscriptionsResource().updateOnePartial(
      activeSubscription[0].id,
      {
        subscription: {
          plan: SubscriptionPlan.ANNUALLY,
        },
      }
    );
    const secretId = response.data.attributes.client_secret;
    const confirmResponse = await stripe.confirmCardPayment(secretId, {
      payment_method: { card: cardElement },
    });
    if (confirmResponse.paymentIntent?.status === "succeeded") {
      dispatch(setPreferencesTab(PreferenceTab.SUBSCRIPTION));
    } else {
      return;
    }
  };
  const cardNumberElement = elements?.getElement("cardNumber");
  cardNumberElement?.on("change", (event) => {
    if (event.error) {
      setErrorMessage(event.error.message);
    }
  });

  const onCardNumberChange = (data: StripeCardNumberElementChangeEvent) => {
    if (data.complete && elements) {
      const cardNumberElement = elements.getElement(CardNumberElement);
      const cardExpiryElement = elements.getElement(CardExpiryElement);
      cardNumberElement?.blur();
      cardExpiryElement?.focus();
    }
  };

  const onCardNumberReady = (element: StripeCardNumberElement) => {
    element.focus();
  };

  return (
    <div style={styles.formContainer}>
      <form style={styles.form} onSubmit={handleSubmit}>
        {errorMessage ? (
          <div style={styles.errorContainer}>
            <Text style={styles.errorCircle}>!</Text>
            <Text style={styles.error}>{errorMessage}</Text>
          </div>
        ) : null}
        {addPaymentErrorMessage ? (
          <div style={styles.errorContainer}>
            <Text style={styles.errorCircle}>!</Text>
            <Text style={styles.error}>{addPaymentErrorMessage}</Text>
          </div>
        ) : null}
        <div style={styles.rowContainer}>
          <Text style={styles.text}>{i18n.t("card_details")}</Text>
          <div style={styles.rowContainer}>
            <div style={styles.paymentsContainer}>
              <img
                width={resizeByResolutionWidth(45)}
                height={resizeByResolutionWidth(28)}
                src={MasterCardImage}
                alt="paymentMethodCardImage"
              />
            </div>
            <div style={styles.paymentsContainer}>
              <img
                width={resizeByResolutionWidth(61)}
                height={resizeByResolutionWidth(20)}
                src={VisaImage}
                alt="paymentMethodCardImage"
              />
            </div>
            <div style={styles.paymentsContainer}>
              <img
                width={resizeByResolutionWidth(61)}
                height={resizeByResolutionWidth(20)}
                src={DinersImage}
                alt="paymentMethodCardImage"
              />
            </div>
            <div style={styles.paymentsContainer}>
              <img
                width={resizeByResolutionWidth(61)}
                height={resizeByResolutionWidth(20)}
                src={DiscoverImage}
                alt="paymentMethodCardImage"
              />
            </div>
            <div style={styles.paymentsContainer}>
              <img
                width={resizeByResolutionWidth(25)}
                height={resizeByResolutionWidth(30)}
                src={PaypalImage}
                alt="paymentMethodCardImage"
              />
            </div>
            <div style={styles.paymentsContainer}>
              <img
                width={resizeByResolutionWidth(61)}
                height={resizeByResolutionWidth(20)}
                src={AmexImage}
                alt="paymentMethodCardImage"
              />
            </div>
            <div style={styles.paymentsContainer}>
              <img
                width={resizeByResolutionWidth(61)}
                height={resizeByResolutionWidth(20)}
                src={UnionpayImage}
                alt="mastercardImage"
              />
            </div>
            <div style={styles.paymentsContainer}>
              <img
                width={resizeByResolutionWidth(61)}
                height={resizeByResolutionWidth(20)}
                src={JCBImage}
                alt="mastercardImage"
              />
            </div>
          </div>
        </div>
        <div style={styles.cardInfoMainContainer}>
          <div style={styles.cardInfoContainer}>
            <div style={styles.cardNumber}>
              <CardNumberElement
                onReady={onCardNumberReady}
                onChange={onCardNumberChange}
                options={{
                  showIcon: true,
                  placeholder: `${i18n.t("card_number")}`,
                }}
              />
            </div>
            <div style={styles.dateCVCContainer}>
              <div style={styles.dateCVCInput}>
                <CardExpiryElement
                  options={{ placeholder: `${i18n.t("mm_yy")}` }}
                />
              </div>
              <div style={styles.dateCVCInput}>
                <CardCvcElement />
              </div>
            </div>
          </div>
        </div>
        <div style={styles.bottomContainer}>
          <div style={styles.separator} />
          <Text style={styles.paymentDetails}>{i18n.t("payment_details")}</Text>
          <div style={styles.horizontalContainer}>
            <Text style={styles.yearlyAmountText}>
              {i18n.t("complete_purchase_yearly_amount_description", {
                price: `${(yearlyPrice / 12).toFixed(2)}`,
              })}
            </Text>
            <Text style={styles.yearlyAmountText}>
              {i18n.t("usd_amount", { price: `${(yearlyPrice / 12).toFixed(2)}` })}
            </Text>
          </div>
          <div style={styles.separator} />
          <div style={styles.horizontalContainer}>
            <Text style={styles.totalText}>{i18n.t("total")}</Text>
            <div>
              <Text style={styles.totalText}>
                {i18n.t("usd_amount", { price: `${yearlyPrice.toFixed(2)}` })}
              </Text>
              <Text style={styles.infoText}>{i18n.t("applicable_tax")}</Text>
            </div>
          </div>
          <CustomButton
            style={styles.submitButton}
            text={i18n.t("submit_purchase")}
            textStyle={styles.buttonText}
            loading={undefined}
            type="submit"
            disabled={!stripe || !elements}
          />
          <Text style={styles.warning}>
            {i18n.t("submit_purchase_warning")}
          </Text>
        </div>
      </form>
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flex: 1,
    alignSelf: "stretch",
    flexDirection: "column",
    paddingRight: 50,
    marginLeft: -12,
    overflowY: "scroll",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  billingCyclePlanContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "15%",
  },
  headerText: {
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "500",
    fontSize: 20,
    color: palette.black,
  },
  separator: {
    height: 1,
    width: "calc(100% - 40px)",
    alignSelf: "stretch",
    backgroundColor: palette.gray.ice,
    marginLeft: 52,
  },
  submitButton: {
    width: "calc(100% - 40px)",
    backgroundColor: palette.black,
    height: 50,
    marginBottom: 20,
    marginTop: "auto",
    borderRadius: 12,
    marginLeft: 52,
  },
  buttonText: {
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "500",
    fontSize: 15,
    color: palette.white,
  },
  paymentDetails: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 18,
    color: palette.gray.medium,
    marginLeft: 52,
    marginTop: 20,
  },
  horizontalContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginLeft: 52,
    marginTop: 20,
    marginBottom: 20,
  },
  yearlyAmountText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 20,
    color: palette.black,
  },
  totalText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 25,
    color: palette.black,
    textAlign: "right",
  },
  infoText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 15,
    color: palette.gray.medium,
  },
  warning: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 15,
    textAlign: "left",
    color: palette.gray.medium,
    marginLeft: 52,
    marginBottom: 20,
  },
  bottomContainer: {
    marginTop: "auto",
  },
  paymentMethodContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: 52,
    marginBottom: 20,
  },
  form: {
    display: "flex",
    alignSelf: "stretch",
    width: "100%",
    justifyContent: "center",
    flexDirection: "column",
  },
  formContainer: {
    display: "flex",
    flex: 1,
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
  },
  errorContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginLeft: 52,
  },
  error: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 20,
    color: palette.red.dark,
  },
  errorCircle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: palette.red.light,
    width: 18,
    height: 18,
    borderRadius: 9,
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 15,
    color: palette.red.dark,
    marginRight: 10,
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: 52,
  },
  text: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 18,
    color: palette.gray.dark,
  },
  paymentsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid ${palette.gray.light}`,
    borderRadius: resizeByResolutionWidth(4.5),
    width: resizeByResolutionWidth(60),
    height: resizeByResolutionWidth(40),
    marginLeft: resizeByResolutionWidth(5),
  },
  cardInfoMainContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 10,
    backgroundColor: palette.gray.light,
    marginTop: 15,
    marginBottom: 15,
    marginLeft: 52,
  },
  cardInfoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: 45,
  },
  cardNumber: {
    width: "75%",
    marginLeft: resizeByResolutionWidth(30),
  },
  dateCVCContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "25%",
    marginRight: resizeByResolutionWidth(30),
  },
  dateCVCInput: {
    width: "100%",
  },
};
