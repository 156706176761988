export interface ICountry {
  name: string;
  alpha2Code: string;
  callingCode: string;
  flag: any;
}

export const countries: ICountry[] = [
  {
    flag: require("./flags/af.png"),
    name: "Afghanistan",
    alpha2Code: "AF",
    callingCode: "93",
  },
  {
    flag: require("./flags/ax.png"),
    name: "Åland Islands",
    alpha2Code: "AX",
    callingCode: "358",
  },
  {
    flag: require("./flags/al.png"),
    name: "Albania",
    alpha2Code: "AL",
    callingCode: "355",
  },
  {
    flag: require("./flags/dz.png"),
    name: "Algeria",
    alpha2Code: "DZ",
    callingCode: "213",
  },
  {
    flag: require("./flags/as.png"),
    name: "American Samoa",
    alpha2Code: "AS",
    callingCode: "1684",
  },
  {
    flag: require("./flags/ad.png"),
    name: "Andorra",
    alpha2Code: "AD",
    callingCode: "376",
  },
  {
    flag: require("./flags/ao.png"),
    name: "Angola",
    alpha2Code: "AO",
    callingCode: "244",
  },
  {
    flag: require("./flags/ai.png"),
    name: "Anguilla",
    alpha2Code: "AI",
    callingCode: "1264",
  },
  {
    flag: require("./flags/aq.png"),
    name: "Antarctica",
    alpha2Code: "AQ",
    callingCode: "672",
  },
  {
    flag: require("./flags/ag.png"),
    name: "Antigua and Barbuda",
    alpha2Code: "AG",
    callingCode: "1268",
  },
  {
    flag: require("./flags/ar.png"),
    name: "Argentina",
    alpha2Code: "AR",
    callingCode: "54",
  },
  {
    flag: require("./flags/am.png"),
    name: "Armenia",
    alpha2Code: "AM",
    callingCode: "374",
  },
  {
    flag: require("./flags/aw.png"),
    name: "Aruba",
    alpha2Code: "AW",
    callingCode: "297",
  },
  {
    flag: require("./flags/au.png"),
    name: "Australia",
    alpha2Code: "AU",
    callingCode: "61",
  },
  {
    flag: require("./flags/at.png"),
    name: "Austria",
    alpha2Code: "AT",
    callingCode: "43",
  },
  {
    flag: require("./flags/az.png"),
    name: "Azerbaijan",
    alpha2Code: "AZ",
    callingCode: "994",
  },
  {
    flag: require("./flags/bs.png"),
    name: "Bahamas",
    alpha2Code: "BS",
    callingCode: "1242",
  },
  {
    flag: require("./flags/bh.png"),
    name: "Bahrain",
    alpha2Code: "BH",
    callingCode: "973",
  },
  {
    flag: require("./flags/bd.png"),
    name: "Bangladesh",
    alpha2Code: "BD",
    callingCode: "880",
  },
  {
    flag: require("./flags/bb.png"),
    name: "Barbados",
    alpha2Code: "BB",
    callingCode: "1246",
  },
  {
    flag: require("./flags/by.png"),
    name: "Belarus",
    alpha2Code: "BY",
    callingCode: "375",
  },
  {
    flag: require("./flags/be.png"),
    name: "Belgium",
    alpha2Code: "BE",
    callingCode: "32",
  },
  {
    flag: require("./flags/bz.png"),
    name: "Belize",
    alpha2Code: "BZ",
    callingCode: "501",
  },
  {
    flag: require("./flags/bj.png"),
    name: "Benin",
    alpha2Code: "BJ",
    callingCode: "229",
  },
  {
    flag: require("./flags/bm.png"),
    name: "Bermuda",
    alpha2Code: "BM",
    callingCode: "1441",
  },
  {
    flag: require("./flags/bt.png"),
    name: "Bhutan",
    alpha2Code: "BT",
    callingCode: "975",
  },
  {
    flag: require("./flags/bo.png"),
    name: "Bolivia (Plurinational State of)",
    alpha2Code: "BO",
    callingCode: "591",
  },
  {
    flag: require("./flags/bq.png"),
    name: "Bonaire, Sint Eustatius and Saba",
    alpha2Code: "BQ",
    callingCode: "5997",
  },
  {
    flag: require("./flags/ba.png"),
    name: "Bosnia and Herzegovina",
    alpha2Code: "BA",
    callingCode: "387",
  },
  {
    flag: require("./flags/bw.png"),
    name: "Botswana",
    alpha2Code: "BW",
    callingCode: "267",
  },
  {
    flag: require("./flags/br.png"),
    name: "Brazil",
    alpha2Code: "BR",
    callingCode: "55",
  },
  {
    flag: require("./flags/io.png"),
    name: "British Indian Ocean Territory",
    alpha2Code: "IO",
    callingCode: "246",
  },
  {
    flag: require("./flags/vg.png"),
    name: "Virgin Islands (British)",
    alpha2Code: "VG",
    callingCode: "1284",
  },
  {
    flag: require("./flags/vi.png"),
    name: "Virgin Islands (U.S.)",
    alpha2Code: "VI",
    callingCode: "1 340",
  },
  {
    flag: require("./flags/bn.png"),
    name: "Brunei Darussalam",
    alpha2Code: "BN",
    callingCode: "673",
  },
  {
    flag: require("./flags/bg.png"),
    name: "Bulgaria",
    alpha2Code: "BG",
    callingCode: "359",
  },
  {
    flag: require("./flags/bf.png"),
    name: "Burkina Faso",
    alpha2Code: "BF",
    callingCode: "226",
  },
  {
    flag: require("./flags/bi.png"),
    name: "Burundi",
    alpha2Code: "BI",
    callingCode: "257",
  },
  {
    flag: require("./flags/kh.png"),
    name: "Cambodia",
    alpha2Code: "KH",
    callingCode: "855",
  },
  {
    flag: require("./flags/cm.png"),
    name: "Cameroon",
    alpha2Code: "CM",
    callingCode: "237",
  },
  {
    flag: require("./flags/ca.png"),
    name: "Canada",
    alpha2Code: "CA",
    callingCode: "1",
  },
  {
    flag: require("./flags/cv.png"),
    name: "Cabo Verde",
    alpha2Code: "CV",
    callingCode: "238",
  },
  {
    flag: require("./flags/ky.png"),
    name: "Cayman Islands",
    alpha2Code: "KY",
    callingCode: "1345",
  },
  {
    flag: require("./flags/cf.png"),
    name: "Central African Republic",
    alpha2Code: "CF",
    callingCode: "236",
  },
  {
    flag: require("./flags/td.png"),
    name: "Chad",
    alpha2Code: "TD",
    callingCode: "235",
  },
  {
    flag: require("./flags/cl.png"),
    name: "Chile",
    alpha2Code: "CL",
    callingCode: "56",
  },
  {
    flag: require("./flags/cn.png"),
    name: "China",
    alpha2Code: "CN",
    callingCode: "86",
  },
  {
    flag: require("./flags/cx.png"),
    name: "Christmas Island",
    alpha2Code: "CX",
    callingCode: "61",
  },
  {
    flag: require("./flags/cc.png"),
    name: "Cocos (Keeling) Islands",
    alpha2Code: "CC",
    callingCode: "61",
  },
  {
    flag: require("./flags/co.png"),
    name: "Colombia",
    alpha2Code: "CO",
    callingCode: "57",
  },
  {
    flag: require("./flags/km.png"),
    name: "Comoros",
    alpha2Code: "KM",
    callingCode: "269",
  },
  {
    flag: require("./flags/cg.png"),
    name: "Congo",
    alpha2Code: "CG",
    callingCode: "242",
  },
  {
    flag: require("./flags/cd.png"),
    name: "Congo (Democratic Republic of the)",
    alpha2Code: "CD",
    callingCode: "243",
  },
  {
    flag: require("./flags/ck.png"),
    name: "Cook Islands",
    alpha2Code: "CK",
    callingCode: "682",
  },
  {
    flag: require("./flags/cr.png"),
    name: "Costa Rica",
    alpha2Code: "CR",
    callingCode: "506",
  },
  {
    flag: require("./flags/hr.png"),
    name: "Croatia",
    alpha2Code: "HR",
    callingCode: "385",
  },
  {
    flag: require("./flags/cu.png"),
    name: "Cuba",
    alpha2Code: "CU",
    callingCode: "53",
  },
  {
    flag: require("./flags/cw.png"),
    name: "Curaçao",
    alpha2Code: "CW",
    callingCode: "599",
  },
  {
    flag: require("./flags/cy.png"),
    name: "Cyprus",
    alpha2Code: "CY",
    callingCode: "357",
  },
  {
    flag: require("./flags/cz.png"),
    name: "Czech Republic",
    alpha2Code: "CZ",
    callingCode: "420",
  },
  {
    flag: require("./flags/dk.png"),
    name: "Denmark",
    alpha2Code: "DK",
    callingCode: "45",
  },
  {
    flag: require("./flags/dj.png"),
    name: "Djibouti",
    alpha2Code: "DJ",
    callingCode: "253",
  },
  {
    flag: require("./flags/dm.png"),
    name: "Dominica",
    alpha2Code: "DM",
    callingCode: "1767",
  },
  {
    flag: require("./flags/do.png"),
    name: "Dominican Republic",
    alpha2Code: "DO",
    callingCode: "1809",
  },
  {
    flag: require("./flags/ec.png"),
    name: "Ecuador",
    alpha2Code: "EC",
    callingCode: "593",
  },
  {
    flag: require("./flags/eg.png"),
    name: "Egypt",
    alpha2Code: "EG",
    callingCode: "20",
  },
  {
    flag: require("./flags/sv.png"),
    name: "El Salvador",
    alpha2Code: "SV",
    callingCode: "503",
  },
  {
    flag: require("./flags/gq.png"),
    name: "Equatorial Guinea",
    alpha2Code: "GQ",
    callingCode: "240",
  },
  {
    flag: require("./flags/er.png"),
    name: "Eritrea",
    alpha2Code: "ER",
    callingCode: "291",
  },
  {
    flag: require("./flags/ee.png"),
    name: "Estonia",
    alpha2Code: "EE",
    callingCode: "372",
  },
  {
    flag: require("./flags/et.png"),
    name: "Ethiopia",
    alpha2Code: "ET",
    callingCode: "251",
  },
  {
    flag: require("./flags/fk.png"),
    name: "Falkland Islands (Malvinas)",
    alpha2Code: "FK",
    callingCode: "500",
  },
  {
    flag: require("./flags/fo.png"),
    name: "Faroe Islands",
    alpha2Code: "FO",
    callingCode: "298",
  },
  {
    flag: require("./flags/fj.png"),
    name: "Fiji",
    alpha2Code: "FJ",
    callingCode: "679",
  },
  {
    flag: require("./flags/fi.png"),
    name: "Finland",
    alpha2Code: "FI",
    callingCode: "358",
  },
  {
    flag: require("./flags/fr.png"),
    name: "France",
    alpha2Code: "FR",
    callingCode: "33",
  },
  {
    flag: require("./flags/gf.png"),
    name: "French Guiana",
    alpha2Code: "GF",
    callingCode: "594",
  },
  {
    flag: require("./flags/pf.png"),
    name: "French Polynesia",
    alpha2Code: "PF",
    callingCode: "689",
  },
  {
    flag: require("./flags/ga.png"),
    name: "Gabon",
    alpha2Code: "GA",
    callingCode: "241",
  },
  {
    flag: require("./flags/gm.png"),
    name: "Gambia",
    alpha2Code: "GM",
    callingCode: "220",
  },
  {
    flag: require("./flags/ge.png"),
    name: "Georgia",
    alpha2Code: "GE",
    callingCode: "995",
  },
  {
    flag: require("./flags/de.png"),
    name: "Germany",
    alpha2Code: "DE",
    callingCode: "49",
  },
  {
    flag: require("./flags/gh.png"),
    name: "Ghana",
    alpha2Code: "GH",
    callingCode: "233",
  },
  {
    flag: require("./flags/gi.png"),
    name: "Gibraltar",
    alpha2Code: "GI",
    callingCode: "350",
  },
  {
    flag: require("./flags/gr.png"),
    name: "Greece",
    alpha2Code: "GR",
    callingCode: "30",
  },
  {
    flag: require("./flags/gl.png"),
    name: "Greenland",
    alpha2Code: "GL",
    callingCode: "299",
  },
  {
    flag: require("./flags/gd.png"),
    name: "Grenada",
    alpha2Code: "GD",
    callingCode: "1473",
  },
  {
    flag: require("./flags/gp.png"),
    name: "Guadeloupe",
    alpha2Code: "GP",
    callingCode: "590",
  },
  {
    flag: require("./flags/gu.png"),
    name: "Guam",
    alpha2Code: "GU",
    callingCode: "1671",
  },
  {
    flag: require("./flags/gt.png"),
    name: "Guatemala",
    alpha2Code: "GT",
    callingCode: "502",
  },
  {
    flag: require("./flags/gg.png"),
    name: "Guernsey",
    alpha2Code: "GG",
    callingCode: "44",
  },
  {
    flag: require("./flags/gn.png"),
    name: "Guinea",
    alpha2Code: "GN",
    callingCode: "224",
  },
  {
    flag: require("./flags/gw.png"),
    name: "Guinea-Bissau",
    alpha2Code: "GW",
    callingCode: "245",
  },
  {
    flag: require("./flags/gy.png"),
    name: "Guyana",
    alpha2Code: "GY",
    callingCode: "592",
  },
  {
    flag: require("./flags/ht.png"),
    name: "Haiti",
    alpha2Code: "HT",
    callingCode: "509",
  },
  {
    flag: require("./flags/va.png"),
    name: "Holy See",
    alpha2Code: "VA",
    callingCode: "379",
  },
  {
    flag: require("./flags/hn.png"),
    name: "Honduras",
    alpha2Code: "HN",
    callingCode: "504",
  },
  {
    flag: require("./flags/hk.png"),
    name: "Hong Kong",
    alpha2Code: "HK",
    callingCode: "852",
  },
  {
    flag: require("./flags/hu.png"),
    name: "Hungary",
    alpha2Code: "HU",
    callingCode: "36",
  },
  {
    flag: require("./flags/is.png"),
    name: "Iceland",
    alpha2Code: "IS",
    callingCode: "354",
  },
  {
    flag: require("./flags/in.png"),
    name: "India",
    alpha2Code: "IN",
    callingCode: "91",
  },
  {
    flag: require("./flags/id.png"),
    name: "Indonesia",
    alpha2Code: "ID",
    callingCode: "62",
  },
  {
    flag: require("./flags/ci.png"),
    name: "Côte d'Ivoire",
    alpha2Code: "CI",
    callingCode: "225",
  },
  {
    flag: require("./flags/ir.png"),
    name: "Iran (Islamic Republic of)",
    alpha2Code: "IR",
    callingCode: "98",
  },
  {
    flag: require("./flags/iq.png"),
    name: "Iraq",
    alpha2Code: "IQ",
    callingCode: "964",
  },
  {
    flag: require("./flags/ie.png"),
    name: "Ireland",
    alpha2Code: "IE",
    callingCode: "353",
  },
  {
    flag: require("./flags/im.png"),
    name: "Isle of Man",
    alpha2Code: "IM",
    callingCode: "44",
  },
  {
    flag: require("./flags/il.png"),
    name: "Israel",
    alpha2Code: "IL",
    callingCode: "972",
  },
  {
    flag: require("./flags/it.png"),
    name: "Italy",
    alpha2Code: "IT",
    callingCode: "39",
  },
  {
    flag: require("./flags/jm.png"),
    name: "Jamaica",
    alpha2Code: "JM",
    callingCode: "1876",
  },
  {
    flag: require("./flags/jp.png"),
    name: "Japan",
    alpha2Code: "JP",
    callingCode: "81",
  },
  {
    flag: require("./flags/je.png"),
    name: "Jersey",
    alpha2Code: "JE",
    callingCode: "44",
  },
  {
    flag: require("./flags/jo.png"),
    name: "Jordan",
    alpha2Code: "JO",
    callingCode: "962",
  },
  {
    flag: require("./flags/kz.png"),
    name: "Kazakhstan",
    alpha2Code: "KZ",
    callingCode: "76",
  },
  {
    flag: require("./flags/ke.png"),
    name: "Kenya",
    alpha2Code: "KE",
    callingCode: "254",
  },
  {
    flag: require("./flags/ki.png"),
    name: "Kiribati",
    alpha2Code: "KI",
    callingCode: "686",
  },
  {
    flag: require("./flags/kw.png"),
    name: "Kuwait",
    alpha2Code: "KW",
    callingCode: "965",
  },
  {
    flag: require("./flags/kg.png"),
    name: "Kyrgyzstan",
    alpha2Code: "KG",
    callingCode: "996",
  },
  {
    flag: require("./flags/la.png"),
    name: "Lao People's Democratic Republic",
    alpha2Code: "LA",
    callingCode: "856",
  },
  {
    flag: require("./flags/lv.png"),
    name: "Latvia",
    alpha2Code: "LV",
    callingCode: "371",
  },
  {
    flag: require("./flags/lb.png"),
    name: "Lebanon",
    alpha2Code: "LB",
    callingCode: "961",
  },
  {
    flag: require("./flags/ls.png"),
    name: "Lesotho",
    alpha2Code: "LS",
    callingCode: "266",
  },
  {
    flag: require("./flags/lr.png"),
    name: "Liberia",
    alpha2Code: "LR",
    callingCode: "231",
  },
  {
    flag: require("./flags/ly.png"),
    name: "Libya",
    alpha2Code: "LY",
    callingCode: "218",
  },
  {
    flag: require("./flags/li.png"),
    name: "Liechtenstein",
    alpha2Code: "LI",
    callingCode: "423",
  },
  {
    flag: require("./flags/lt.png"),
    name: "Lithuania",
    alpha2Code: "LT",
    callingCode: "370",
  },
  {
    flag: require("./flags/lu.png"),
    name: "Luxembourg",
    alpha2Code: "LU",
    callingCode: "352",
  },
  {
    flag: require("./flags/mo.png"),
    name: "Macao",
    alpha2Code: "MO",
    callingCode: "853",
  },
  {
    flag: require("./flags/mk.png"),
    name: "Macedonia (the former Yugoslav Republic of)",
    alpha2Code: "MK",
    callingCode: "389",
  },
  {
    flag: require("./flags/mg.png"),
    name: "Madagascar",
    alpha2Code: "MG",
    callingCode: "261",
  },
  {
    flag: require("./flags/mw.png"),
    name: "Malawi",
    alpha2Code: "MW",
    callingCode: "265",
  },
  {
    flag: require("./flags/my.png"),
    name: "Malaysia",
    alpha2Code: "MY",
    callingCode: "60",
  },
  {
    flag: require("./flags/mv.png"),
    name: "Maldives",
    alpha2Code: "MV",
    callingCode: "960",
  },
  {
    flag: require("./flags/ml.png"),
    name: "Mali",
    alpha2Code: "ML",
    callingCode: "223",
  },
  {
    flag: require("./flags/mt.png"),
    name: "Malta",
    alpha2Code: "MT",
    callingCode: "356",
  },
  {
    flag: require("./flags/mh.png"),
    name: "Marshall Islands",
    alpha2Code: "MH",
    callingCode: "692",
  },
  {
    flag: require("./flags/mq.png"),
    name: "Martinique",
    alpha2Code: "MQ",
    callingCode: "596",
  },
  {
    flag: require("./flags/mr.png"),
    name: "Mauritania",
    alpha2Code: "MR",
    callingCode: "222",
  },
  {
    flag: require("./flags/mu.png"),
    name: "Mauritius",
    alpha2Code: "MU",
    callingCode: "230",
  },
  {
    flag: require("./flags/yt.png"),
    name: "Mayotte",
    alpha2Code: "YT",
    callingCode: "262",
  },
  {
    flag: require("./flags/mx.png"),
    name: "Mexico",
    alpha2Code: "MX",
    callingCode: "52",
  },
  {
    flag: require("./flags/fm.png"),
    name: "Micronesia (Federated States of)",
    alpha2Code: "FM",
    callingCode: "691",
  },
  {
    flag: require("./flags/md.png"),
    name: "Moldova (Republic of)",
    alpha2Code: "MD",
    callingCode: "373",
  },
  {
    flag: require("./flags/mc.png"),
    name: "Monaco",
    alpha2Code: "MC",
    callingCode: "377",
  },
  {
    flag: require("./flags/mn.png"),
    name: "Mongolia",
    alpha2Code: "MN",
    callingCode: "976",
  },
  {
    flag: require("./flags/me.png"),
    name: "Montenegro",
    alpha2Code: "ME",
    callingCode: "382",
  },
  {
    flag: require("./flags/ms.png"),
    name: "Montserrat",
    alpha2Code: "MS",
    callingCode: "1664",
  },
  {
    flag: require("./flags/ma.png"),
    name: "Morocco",
    alpha2Code: "MA",
    callingCode: "212",
  },
  {
    flag: require("./flags/mz.png"),
    name: "Mozambique",
    alpha2Code: "MZ",
    callingCode: "258",
  },
  {
    flag: require("./flags/mm.png"),
    name: "Myanmar",
    alpha2Code: "MM",
    callingCode: "95",
  },
  {
    flag: require("./flags/na.png"),
    name: "Namibia",
    alpha2Code: "NA",
    callingCode: "264",
  },
  {
    flag: require("./flags/nr.png"),
    name: "Nauru",
    alpha2Code: "NR",
    callingCode: "674",
  },
  {
    flag: require("./flags/np.png"),
    name: "Nepal",
    alpha2Code: "NP",
    callingCode: "977",
  },
  {
    flag: require("./flags/nl.png"),
    name: "Netherlands",
    alpha2Code: "NL",
    callingCode: "31",
  },
  {
    flag: require("./flags/nc.png"),
    name: "New Caledonia",
    alpha2Code: "NC",
    callingCode: "687",
  },
  {
    flag: require("./flags/nz.png"),
    name: "New Zealand",
    alpha2Code: "NZ",
    callingCode: "64",
  },
  {
    flag: require("./flags/ni.png"),
    name: "Nicaragua",
    alpha2Code: "NI",
    callingCode: "505",
  },
  {
    flag: require("./flags/ne.png"),
    name: "Niger",
    alpha2Code: "NE",
    callingCode: "227",
  },
  {
    flag: require("./flags/ng.png"),
    name: "Nigeria",
    alpha2Code: "NG",
    callingCode: "234",
  },
  {
    flag: require("./flags/nu.png"),
    name: "Niue",
    alpha2Code: "NU",
    callingCode: "683",
  },
  {
    flag: require("./flags/nf.png"),
    name: "Norfolk Island",
    alpha2Code: "NF",
    callingCode: "672",
  },
  {
    flag: require("./flags/kp.png"),
    name: "Korea (Democratic People's Republic of)",
    alpha2Code: "KP",
    callingCode: "850",
  },
  {
    flag: require("./flags/mp.png"),
    name: "Northern Mariana Islands",
    alpha2Code: "MP",
    callingCode: "1670",
  },
  {
    flag: require("./flags/no.png"),
    name: "Norway",
    alpha2Code: "NO",
    callingCode: "47",
  },
  {
    flag: require("./flags/om.png"),
    name: "Oman",
    alpha2Code: "OM",
    callingCode: "968",
  },
  {
    flag: require("./flags/pk.png"),
    name: "Pakistan",
    alpha2Code: "PK",
    callingCode: "92",
  },
  {
    flag: require("./flags/pw.png"),
    name: "Palau",
    alpha2Code: "PW",
    callingCode: "680",
  },
  {
    flag: require("./flags/ps.png"),
    name: "Palestine, State of",
    alpha2Code: "PS",
    callingCode: "970",
  },
  {
    flag: require("./flags/pa.png"),
    name: "Panama",
    alpha2Code: "PA",
    callingCode: "507",
  },
  {
    flag: require("./flags/pg.png"),
    name: "Papua New Guinea",
    alpha2Code: "PG",
    callingCode: "675",
  },
  {
    flag: require("./flags/py.png"),
    name: "Paraguay",
    alpha2Code: "PY",
    callingCode: "595",
  },
  {
    flag: require("./flags/pe.png"),
    name: "Peru",
    alpha2Code: "PE",
    callingCode: "51",
  },
  {
    flag: require("./flags/ph.png"),
    name: "Philippines",
    alpha2Code: "PH",
    callingCode: "63",
  },
  {
    flag: require("./flags/pn.png"),
    name: "Pitcairn",
    alpha2Code: "PN",
    callingCode: "64",
  },
  {
    flag: require("./flags/pl.png"),
    name: "Poland",
    alpha2Code: "PL",
    callingCode: "48",
  },
  {
    flag: require("./flags/pt.png"),
    name: "Portugal",
    alpha2Code: "PT",
    callingCode: "351",
  },
  {
    flag: require("./flags/pr.png"),
    name: "Puerto Rico",
    alpha2Code: "PR",
    callingCode: "1787",
  },
  {
    flag: require("./flags/qa.png"),
    name: "Qatar",
    alpha2Code: "QA",
    callingCode: "974",
  },
  {
    flag: require("./flags/xk.png"),
    name: "Republic of Kosovo",
    alpha2Code: "XK",
    callingCode: "383",
  },
  {
    flag: require("./flags/re.png"),
    name: "Réunion",
    alpha2Code: "RE",
    callingCode: "262",
  },
  {
    flag: require("./flags/ro.png"),
    name: "Romania",
    alpha2Code: "RO",
    callingCode: "40",
  },
  {
    flag: require("./flags/ru.png"),
    name: "Russian Federation",
    alpha2Code: "RU",
    callingCode: "7",
  },
  {
    flag: require("./flags/rw.png"),
    name: "Rwanda",
    alpha2Code: "RW",
    callingCode: "250",
  },
  {
    flag: require("./flags/bl.png"),
    name: "Saint Barthélemy",
    alpha2Code: "BL",
    callingCode: "590",
  },
  {
    flag: require("./flags/sh.png"),
    name: "Saint Helena, Ascension and Tristan da Cunha",
    alpha2Code: "SH",
    callingCode: "290",
  },
  {
    flag: require("./flags/kn.png"),
    name: "Saint Kitts and Nevis",
    alpha2Code: "KN",
    callingCode: "1869",
  },
  {
    flag: require("./flags/lc.png"),
    name: "Saint Lucia",
    alpha2Code: "LC",
    callingCode: "1758",
  },
  {
    flag: require("./flags/mf.png"),
    name: "Saint Martin (French part)",
    alpha2Code: "MF",
    callingCode: "590",
  },
  {
    flag: require("./flags/pm.png"),
    name: "Saint Pierre and Miquelon",
    alpha2Code: "PM",
    callingCode: "508",
  },
  {
    flag: require("./flags/vc.png"),
    name: "Saint Vincent and the Grenadines",
    alpha2Code: "VC",
    callingCode: "1784",
  },
  {
    flag: require("./flags/ws.png"),
    name: "Samoa",
    alpha2Code: "WS",
    callingCode: "685",
  },
  {
    flag: require("./flags/sm.png"),
    name: "San Marino",
    alpha2Code: "SM",
    callingCode: "378",
  },
  {
    flag: require("./flags/st.png"),
    name: "Sao Tome and Principe",
    alpha2Code: "ST",
    callingCode: "239",
  },
  {
    flag: require("./flags/sa.png"),
    name: "Saudi Arabia",
    alpha2Code: "SA",
    callingCode: "966",
  },
  {
    flag: require("./flags/sn.png"),
    name: "Senegal",
    alpha2Code: "SN",
    callingCode: "221",
  },
  {
    flag: require("./flags/rs.png"),
    name: "Serbia",
    alpha2Code: "RS",
    callingCode: "381",
  },
  {
    flag: require("./flags/sc.png"),
    name: "Seychelles",
    alpha2Code: "SC",
    callingCode: "248",
  },
  {
    flag: require("./flags/sl.png"),
    name: "Sierra Leone",
    alpha2Code: "SL",
    callingCode: "232",
  },
  {
    flag: require("./flags/sg.png"),
    name: "Singapore",
    alpha2Code: "SG",
    callingCode: "65",
  },
  {
    flag: require("./flags/sx.png"),
    name: "Sint Maarten (Dutch part)",
    alpha2Code: "SX",
    callingCode: "1721",
  },
  {
    flag: require("./flags/sk.png"),
    name: "Slovakia",
    alpha2Code: "SK",
    callingCode: "421",
  },
  {
    flag: require("./flags/si.png"),
    name: "Slovenia",
    alpha2Code: "SI",
    callingCode: "386",
  },
  {
    flag: require("./flags/sb.png"),
    name: "Solomon Islands",
    alpha2Code: "SB",
    callingCode: "677",
  },
  {
    flag: require("./flags/so.png"),
    name: "Somalia",
    alpha2Code: "SO",
    callingCode: "252",
  },
  {
    flag: require("./flags/za.png"),
    name: "South Africa",
    alpha2Code: "ZA",
    callingCode: "27",
  },
  {
    flag: require("./flags/gs.png"),
    name: "South Georgia and the South Sandwich Islands",
    alpha2Code: "GS",
    callingCode: "500",
  },
  {
    flag: require("./flags/kr.png"),
    name: "Korea (Republic of)",
    alpha2Code: "KR",
    callingCode: "82",
  },
  {
    flag: require("./flags/ss.png"),
    name: "South Sudan",
    alpha2Code: "SS",
    callingCode: "211",
  },
  {
    flag: require("./flags/es.png"),
    name: "Spain",
    alpha2Code: "ES",
    callingCode: "34",
  },
  {
    flag: require("./flags/lk.png"),
    name: "Sri Lanka",
    alpha2Code: "LK",
    callingCode: "94",
  },
  {
    flag: require("./flags/sd.png"),
    name: "Sudan",
    alpha2Code: "SD",
    callingCode: "249",
  },
  {
    flag: require("./flags/sr.png"),
    name: "Suriname",
    alpha2Code: "SR",
    callingCode: "597",
  },
  {
    flag: require("./flags/sj.png"),
    name: "Svalbard and Jan Mayen",
    alpha2Code: "SJ",
    callingCode: "4779",
  },
  {
    flag: require("./flags/sz.png"),
    name: "Swaziland",
    alpha2Code: "SZ",
    callingCode: "268",
  },
  {
    flag: require("./flags/se.png"),
    name: "Sweden",
    alpha2Code: "SE",
    callingCode: "46",
  },
  {
    flag: require("./flags/ch.png"),
    name: "Switzerland",
    alpha2Code: "CH",
    callingCode: "41",
  },
  {
    flag: require("./flags/sy.png"),
    name: "Syrian Arab Republic",
    alpha2Code: "SY",
    callingCode: "963",
  },
  {
    flag: require("./flags/tw.png"),
    name: "Taiwan",
    alpha2Code: "TW",
    callingCode: "886",
  },
  {
    flag: require("./flags/tj.png"),
    name: "Tajikistan",
    alpha2Code: "TJ",
    callingCode: "992",
  },
  {
    flag: require("./flags/tz.png"),
    name: "Tanzania, United Republic of",
    alpha2Code: "TZ",
    callingCode: "255",
  },
  {
    flag: require("./flags/th.png"),
    name: "Thailand",
    alpha2Code: "TH",
    callingCode: "66",
  },
  {
    flag: require("./flags/tl.png"),
    name: "Timor-Leste",
    alpha2Code: "TL",
    callingCode: "670",
  },
  {
    flag: require("./flags/tg.png"),
    name: "Togo",
    alpha2Code: "TG",
    callingCode: "228",
  },
  {
    flag: require("./flags/tk.png"),
    name: "Tokelau",
    alpha2Code: "TK",
    callingCode: "690",
  },
  {
    flag: require("./flags/to.png"),
    name: "Tonga",
    alpha2Code: "TO",
    callingCode: "676",
  },
  {
    flag: require("./flags/tt.png"),
    name: "Trinidad and Tobago",
    alpha2Code: "TT",
    callingCode: "1868",
  },
  {
    flag: require("./flags/tn.png"),
    name: "Tunisia",
    alpha2Code: "TN",
    callingCode: "216",
  },
  {
    flag: require("./flags/tr.png"),
    name: "Turkey",
    alpha2Code: "TR",
    callingCode: "90",
  },
  {
    flag: require("./flags/tm.png"),
    name: "Turkmenistan",
    alpha2Code: "TM",
    callingCode: "993",
  },
  {
    flag: require("./flags/tc.png"),
    name: "Turks and Caicos Islands",
    alpha2Code: "TC",
    callingCode: "1649",
  },
  {
    flag: require("./flags/tv.png"),
    name: "Tuvalu",
    alpha2Code: "TV",
    callingCode: "688",
  },
  {
    flag: require("./flags/ug.png"),
    name: "Uganda",
    alpha2Code: "UG",
    callingCode: "256",
  },
  {
    flag: require("./flags/ua.png"),
    name: "Ukraine",
    alpha2Code: "UA",
    callingCode: "380",
  },
  {
    flag: require("./flags/ae.png"),
    name: "United Arab Emirates",
    alpha2Code: "AE",
    callingCode: "971",
  },
  {
    flag: require("./flags/gb.png"),
    name: "United Kingdom of Great Britain and Northern Ireland",
    alpha2Code: "GB",
    callingCode: "44",
  },
  {
    flag: require("./flags/us.png"),
    name: "United States of America",
    alpha2Code: "US",
    callingCode: "1",
  },
  {
    flag: require("./flags/uy.png"),
    name: "Uruguay",
    alpha2Code: "UY",
    callingCode: "598",
  },
  {
    flag: require("./flags/uz.png"),
    name: "Uzbekistan",
    alpha2Code: "UZ",
    callingCode: "998",
  },
  {
    flag: require("./flags/vu.png"),
    name: "Vanuatu",
    alpha2Code: "VU",
    callingCode: "678",
  },
  {
    flag: require("./flags/ve.png"),
    name: "Venezuela (Bolivarian Republic of)",
    alpha2Code: "VE",
    callingCode: "58",
  },
  {
    flag: require("./flags/vn.png"),
    name: "Viet Nam",
    alpha2Code: "VN",
    callingCode: "84",
  },
  {
    flag: require("./flags/wf.png"),
    name: "Wallis and Futuna",
    alpha2Code: "WF",
    callingCode: "681",
  },
  {
    flag: require("./flags/eh.png"),
    name: "Western Sahara",
    alpha2Code: "EH",
    callingCode: "212",
  },
  {
    flag: require("./flags/ye.png"),
    name: "Yemen",
    alpha2Code: "YE",
    callingCode: "967",
  },
  {
    flag: require("./flags/zm.png"),
    name: "Zambia",
    alpha2Code: "ZM",
    callingCode: "260",
  },
  {
    flag: require("./flags/zw.png"),
    name: "Zimbabwe",
    alpha2Code: "ZW",
    callingCode: "263",
  },
];

const getDefaultCountry = (): ICountry => {
  let defaultCountry = null;
  const language = navigator.languages[1] || navigator.languages[0];
    defaultCountry = countries.find(
      (country) => country?.alpha2Code?.toLowerCase() === language?.toLowerCase()
    );
  if (!defaultCountry) {
    return {
      flag: require("./flags/us.png"),
      name: "United States of America",
      alpha2Code: "US",
      callingCode: "1",
    };
  }
  return defaultCountry;
};

export const defaultCountry: ICountry = getDefaultCountry();
