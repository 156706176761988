import { TaskEntity } from "communicators/resources/event-resources/tasks/tasks.type";
import { DropResult } from "constants/dnd.constants";
import { FullScreenSpinner } from "elements/spinner/FullScreenSpinner";
import { DragAndDropHelper } from "helpers/dnd.helper";
import { AllBoardDataEntityType, IBoard } from "interfaces/main.interfaces";
import { debounce } from "lodash";
import { DateTime } from "luxon";
import React from "react";
import { HeaderButtonType } from "../../constants";
import { Board } from "../Board/Board";
import { MagicPlannerPopup } from "../MagicPlannerPopup/MagicPlannerPopup";
import { MovedToInboxPopup } from "../MovedToInboxPopup/MovedToInboxPopup";
import { PlannerLoader } from "../PlannerLoader/PlannerLoader";
import { VerifyEmailPopup } from "../VerifyEmailPopup/VerifyEmailPopup";

interface Props {
  loading?: boolean;
  data: IBoard[];
  onDragEnd: (result: DropResult) => void;
  onPressBoardPlus: (date: DateTime) => void;
  onTaskCreate: (
    index: number,
    boardDate: DateTime,
    createdTask: TaskEntity,
    isNewTask: boolean
  ) => void;
  toggleVerifyEmailPopup?: () => void;
  isVerifyEmailPopupVisible?: boolean;
  onClickCheckBox: (data: AllBoardDataEntityType) => void;
  deleteTask?: (data: AllBoardDataEntityType) => void;
  onClickResend?: () => void;
  onTaskMeetAction?: () => void;
  onSelectSomeday?: (date: DateTime, data?: AllBoardDataEntityType) => void;
  refreshBoards: () => void;
  boardType?: HeaderButtonType;
  isShown?: boolean;
  checkedDataId?: string;
  onDrop?: (result: DropResult) => void;
  isStreakModalShown?: boolean;
  onCloseMovedToInboxPopup: () => void;
  isMovedToInboxPopupVisible: boolean;
  movedToInboxTaskTitles: string[];
  isPlannerLoading: boolean;
  magicPlannerText: string;
  isMagicPlannerSwitchShown: boolean;
  onClickMagicPlan: () => void;
}

export const Main = ({
  loading,
  data,
  onDragEnd,
  onPressBoardPlus,
  onTaskCreate,
  toggleVerifyEmailPopup,
  isVerifyEmailPopupVisible,
  onClickCheckBox,
  deleteTask,
  onClickResend,
  onTaskMeetAction,
  onSelectSomeday,
  refreshBoards,
  boardType,
  isShown,
  checkedDataId,
  onDrop,
  isStreakModalShown,
  onCloseMovedToInboxPopup,
  isMovedToInboxPopupVisible,
  movedToInboxTaskTitles,
  isPlannerLoading,
  magicPlannerText,
  isMagicPlannerSwitchShown,
  onClickMagicPlan,
}: Props) => {
  const onMoveDropableArea = (boardData: IBoard) => {
    if (DragAndDropHelper.isOverOnRightDrawer) {
      return;
    }
    const { board, currentIndex } = DragAndDropHelper.getBoardIdByBoardItemId(
      DragAndDropHelper.draggingEvent?.data.id
    );
    const sourceBoard = board;
    const sourceIndex = currentIndex;
    const destinationBoard = boardData?.date.toSQLDate()!;
    const destinationIndex = DragAndDropHelper.getLatestIndexOfBoard(
      destinationBoard!
    );
    if (!destinationBoard) return;
    const result: DropResult = {
      destination: { droppableId: destinationBoard, index: destinationIndex },
      source: { droppableId: sourceBoard, index: sourceIndex },
    };
    onDragEnd(result);
  };
  const onMoveDropableWithDebounce = debounce(onMoveDropableArea, 500);

  return (
    <div style={styles.container}>
      {data.map((item, index) => {
        const isLastItem = index === data.length - 1;
        return (
          <div
            style={{
              ...styles.listContainer,
              marginRight: isLastItem ? 0 : 30,
            }}
            key={index}
          >
            <Board
              onMoveDropableArea={onMoveDropableWithDebounce}
              onDragEnd={onDragEnd}
              boardData={item}
              onPressPlus={onPressBoardPlus}
              onTaskCreate={onTaskCreate}
              onClickCheckBox={onClickCheckBox}
              deleteTask={deleteTask}
              onTaskMeetAction={onTaskMeetAction}
              onSelectSomeday={onSelectSomeday}
              refreshBoards={refreshBoards}
              boardType={boardType}
              isShown={isShown}
              checkedDataId={checkedDataId}
              onDrop={onDrop}
            />
          </div>
        );
      })}
      <FullScreenSpinner loading={loading} />
      {isVerifyEmailPopupVisible ? (
        <VerifyEmailPopup
          onClickClose={toggleVerifyEmailPopup}
          onClickResend={onClickResend}
        />
      ) : null}
      {isMovedToInboxPopupVisible ? (
        <MovedToInboxPopup
          onClickClose={onCloseMovedToInboxPopup}
          movedToInboxTaskTitles={movedToInboxTaskTitles}
        />
      ) : null}
      {!isStreakModalShown &&
      !isPlannerLoading &&
      !isMagicPlannerSwitchShown ? (
        <MagicPlannerPopup
          onClickMagicPlan={onClickMagicPlan}
          magicPlannerText={magicPlannerText}
        />
      ) : null}
      {!isStreakModalShown &&
      isPlannerLoading ? (
        <PlannerLoader />
      ) : null}
    </div>
  );
};

const HEIGHT = "calc(100vh - 112px)";

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "row",
  },
  listContainer: {
    display: "flex",
    alignSelf: "stretch",
    flex: 1,
    height: HEIGHT,
  },
};
