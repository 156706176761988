import { Avatar } from "@mui/material";
import { Logo } from "assets/images";
import "./Waitlist.scss";
import { usePageHook } from "hooks/usePageHook";

export const Waitlist = () => {
  usePageHook();
  return (
    <div className="waitlist">
      <div className="waitlist-icon-container">
        <Avatar src={Logo} className="insumo-logo" />
      </div>
      <div className="waitlist-title">Thanks for joining the waitlist!</div>
      <div className="waitlist-text">
        Hang in there! We'll invite you faster than a cheetah on a caffeine
        high.
      </div>
    </div>
  );
};
