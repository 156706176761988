import { palette } from "assets/palette";
import React from "react";
import { Text } from "elements/text/Text";
import { i18n } from "constants/i18n.constants";
import { CalendarItem } from "./CalendarItem/CalendarItem";
import { GoogleCalendarImage, Logo } from "assets/images";
import { CalendarEntity } from "communicators/resources/calendars/calendars.type";
import { useUserSettingHook } from "hooks/useUserSettingHook";
import { CalendarAccountType } from "pages/private/home/useHomeHook";

export interface Props {
  onPressInsumo?: () => void;
  onPressGoogle?: () => void;
  calendarIDs?: CalendarEntity[];
  googleLoading?: boolean;
}

export const CalendarSelection = ({ onPressInsumo, onPressGoogle, calendarIDs, googleLoading }: Props) => {
  const {preferences} = useUserSettingHook();
  return (
    <div style={styles.container}>
      <Text style={styles.title}>{i18n.t("suitcase_detail_text")}</Text>
      <CalendarItem
        selected={preferences?.attributes.preferred_calendar === CalendarAccountType.INSUMO}
        title={i18n.t("insumo_calendar")}
        logo={Logo}
        onPress={onPressInsumo}
      />
      <CalendarItem
        selected={preferences?.attributes.preferred_calendar === CalendarAccountType.GOOGLE}
        title={i18n.t("google_calendar")}
        logo={GoogleCalendarImage}
        calendarIDs={calendarIDs}
        onPress={onPressGoogle}
        loading={googleLoading}
      />
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  container: {
    marginLeft: 20,
    marginRight: 20,
    padding: "40px 20px 20px 25px",
    boxShadow: "0px 4px 4px 0px #00000040",
    backgroundColor: palette.white,
    borderRadius: "0px 12px 12px 12px",
    width: "100%"
  },
  title: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 15,
    color: palette.gray.medium,
  },
};