import { IconButton } from "@mui/material";
import { palette } from "assets/palette";
import { CustomTooltip } from "components/custom-tooltip/CustomTooltip";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { SecondDrawerType } from "interfaces/main.interfaces";
import React from "react";
import { LEFT_BAR_WIDTH } from "../../constants";
import { useLeftSettingBarHook } from "./useLeftSettingsBarHook";
import { i18n } from "constants/i18n.constants";
import { resizeByResolutionWidth } from "helpers/utils.helper";
import "./LeftSettingsBar.scss";

interface Props {
  openSecondDrawer: (type: string) => void;
  secondDrawerOpenStatus?: { type: string | null; opened: boolean };
  isChildOfAFT?: boolean;
}

export const LeftSettingsBar = ({
  openSecondDrawer,
  secondDrawerOpenStatus,
  isChildOfAFT,
}: Props) => {
  const { INTEGRATIONS, onPressSettingIcon, onPressPlusIcon } =
    useLeftSettingBarHook();
  return (
    <div
      style={styles.container}
      className={
        isChildOfAFT ? "left-settings-bar child-of-aft" : "left-settings-bar"
      }
    >
      <CustomTooltip description={i18n.t("settings")} direction="right-end">
        <IconButton
          onClick={onPressSettingIcon}
          style={styles.settingIcon}
          className="settings-icon"
        >
          <Icon name={IconName.SETTING} />
        </IconButton>
      </CustomTooltip>
      <CustomTooltip description={i18n.t("integrations")} direction="right-end">
        <button onClick={onPressPlusIcon} style={styles.integrationSquare}>
          <Icon name={IconName.INTEGRATION_PLUS} />
        </button>
      </CustomTooltip>
      <CustomTooltip
        description={i18n.t("completed_tasks")}
        direction="right-end"
      >
        <button
          style={styles.integrationSquare}
          onClick={() => openSecondDrawer(SecondDrawerType.COMPLETED_TASKS)}
        >
          <Icon name={IconName.COMPLETED_TASKS} />
          {secondDrawerOpenStatus?.type === SecondDrawerType.COMPLETED_TASKS ? (
            <>
              <div style={styles.rectangle} />
              <div style={styles.triangle} />
            </>
          ) : null}
        </button>
      </CustomTooltip>
      <CustomTooltip description={i18n.t("inbox_tasks")} direction="right-end">
        <button
          style={styles.integrationSquare}
          onClick={() => openSecondDrawer(SecondDrawerType.INBOX_TASKS)}
        >
          <Icon name={IconName.INBOX_TASKS} />
          {secondDrawerOpenStatus?.type === SecondDrawerType.INBOX_TASKS ? (
            <>
              <div style={styles.rectangle} />
              <div style={styles.triangle} />
            </>
          ) : null}
        </button>
      </CustomTooltip>
      <CustomTooltip description={i18n.t("someday")} direction="right-end">
        <button
          style={styles.integrationSquare}
          onClick={() => openSecondDrawer(SecondDrawerType.SOMEDAY_TASKS)}
        >
          <Icon name={IconName.SOMEDAY_TASKS} />
          {secondDrawerOpenStatus?.type === SecondDrawerType.SOMEDAY_TASKS ? (
            <>
              <div style={styles.rectangle} />
              <div style={styles.triangle} />
            </>
          ) : null}
        </button>
      </CustomTooltip>
      {INTEGRATIONS.map((item, index) => (
        <button
          onClick={() => openSecondDrawer(item.key)}
          key={item.key}
          style={styles.integrationSquare}
        >
          <img
            style={{ ...styles.image, opacity: item.isActive ? 1 : 0.5 }}
            key={index}
            alt={index.toString()}
            src={item.image}
          />
          {secondDrawerOpenStatus?.type === item.key ? (
            <>
              <div style={styles.rectangle} />
              <div style={styles.triangle} />
            </>
          ) : null}
        </button>
      ))}
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  container: {
    width: LEFT_BAR_WIDTH - 3,
    height: "100vh",
    backgroundColor: palette.gray.iceLight,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 10,
    zIndex: 1,
    paddingLeft: 10,
    paddingRight: 10,
  },
  integrationSquare: {
    width: resizeByResolutionWidth(40),
    height: resizeByResolutionWidth(40),
    borderRadius: resizeByResolutionWidth(10),
    backgroundColor: palette.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: resizeByResolutionWidth(25),
    borderWidth: 0,
    flexDirection: "row",
    cursor: "pointer",
  },
  image: {
    width: resizeByResolutionWidth(20),
    height: resizeByResolutionWidth(20),
  },
  triangle: {
    position: "absolute",
    width: 0,
    height: 0,
    borderTop: `${resizeByResolutionWidth(36)}px solid transparent`,
    borderBottom: `${resizeByResolutionWidth(36)}px solid transparent`,
    borderRight: `${resizeByResolutionWidth(36)}px solid ${palette.white}`,
    marginLeft: "40px",
    zIndex: -1,
  },
  rectangle: {
    position: "absolute",
    width: resizeByResolutionWidth(LEFT_BAR_WIDTH) - resizeByResolutionWidth(40),
    height: resizeByResolutionWidth(40),
    marginLeft: "44px",
    backgroundColor: palette.white,
  },
};