import { ICountry } from 'assets/countries/countries';
import { AmexImage, AsanaImage, DinersImage, DiscoverImage, GoogleCalendarImage, JCBImage, MasterCardImage, TodoistImage, UnionpayImage, VisaImage } from "assets/images";
import { EventType } from "communicators/resources/event-resources/event-resources.type";
import { defaultScheduledDate } from "constants/default-scheduled-date.constant";
import { IconName } from "elements/icon/Icon.type";
import { AllBoardDataEntityType, IBoard, SecondDrawerType, ThirdPartyProvider } from "interfaces/main.interfaces";
import { DateTime } from "luxon";
import { i18n } from "constants/i18n.constants";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { ITimelineItem } from "pages/private/home/components/DrawerRight/types";
import { isValidPhoneNumber } from "libphonenumber-js";
import { LoginOptions } from 'communicators/services/firebase/type';
import { FirebaseService } from 'communicators/services/firebase/Firebase.service';
import { NavigateFunction } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import { TasksResource } from 'communicators/resources/event-resources/tasks/tasks.resource';
import { getAllBoardData } from './data.helper';
import { TaskEntity } from 'communicators/resources/event-resources/tasks/tasks.type';
import { Scope } from 'communicators/resources/third-party-information/third-party-information.type';
import { PermissionModalType } from 'pages/private/home/useHomeHook';
import { setPermissionModalType } from 'redux-store/actions/home-page.actions';
import { store } from 'redux-store/store';

export const getImageOrIcon = (data?: AllBoardDataEntityType) => {
    if (data?.type === EventType.TASK) {
      if (data?.attributes?.provider === ThirdPartyProvider.ASANA) {
        return { type: "image", source: AsanaImage, text: i18n.t("asana") };
      }
      if (data?.attributes?.provider === ThirdPartyProvider.TODOIST) {
        return { type: "image", source: TodoistImage, text: i18n.t("todoist") };
      }
      if (
        data?.attributes.scheduled_time &&
        DateTime.fromISO(data?.attributes?.scheduled_time).toFormat(
          "MM/dd/yyyy"
        ) === defaultScheduledDate
      ) {
        return {
          type: "icon",
          source: IconName.SOMEDAY_TASKS,
          text: i18n.t("someday"),
        };
      }
      if (data?.attributes?.completed_count > 0) {
        return {
          type: "icon",
          source: IconName.COMPLETED_TASKS,
          text: i18n.t("completed_tasks"),
        };
      } else {
        return {
          type: "icon",
          source: IconName.INBOX_TASKS,
          text: i18n.t("inbox_tasks"),
        };
      }
    }
    return {
      type: "image",
      source: GoogleCalendarImage,
      text: i18n.t("google_calendar"),
    };
};

export const getTitle = (data?: AllBoardDataEntityType) => {
    switch (data?.type) {
      case EventType.HABIT_TARGET:
        return data?.attributes?.title;
      case EventType.MEET:
        return data?.attributes?.meet_summary;
      default:
        return "";
    }
};

export const getTypeOfBoardData = (data: AllBoardDataEntityType) => {
  if (data?.type === EventType.TASK) {
    if (
      data?.attributes.scheduled_time &&
      DateTime.fromISO(data?.attributes?.scheduled_time).toFormat(
        "MM/dd/yyyy"
      ) === defaultScheduledDate
    ) {
      return SecondDrawerType.SOMEDAY_TASKS;
    }
    if (data?.attributes?.completed_count > 0) {
      return SecondDrawerType.COMPLETED_TASKS;
    }
    return SecondDrawerType.INBOX_TASKS;
  }
  return SecondDrawerType.INBOX_TASKS;
}

export const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIRABASE_API_KEY,
    authDomain: process.env.REACT_APP_FIRABASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASURMENT_ID,
  };
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export const determineHours = (): ITimelineItem[] => {
    let currentTime = DateTime.now().startOf("day");
    const indexNumber = 24 * 4;
    const days = [];
    for (let i = 0; i < indexNumber; i++) {
      days.push({ time: currentTime });
      currentTime = currentTime.plus({ minutes: 15 });
    }
    return days;
};

export const SubmitPhoneVerification = async (target: string, phone: string, setLoading: React.Dispatch<React.SetStateAction<boolean>>, navigate: NavigateFunction, selectedCountry?: ICountry ) => {
    const phoneIsValid = isValidPhoneNumber(
        `+${selectedCountry?.callingCode}${phone}`
    );
    if (!phoneIsValid) return;
    setLoading(true);
    try {
      let verificationId: string | null = null;
      const phoneNumber = `+${selectedCountry?.callingCode}${phone}`;
      
      if(target === "/change-phone-number"){
        verificationId = await new FirebaseService().phoneVerificationOfExistingUser(phoneNumber)
      }else{
        const confirmationResult = await new FirebaseService().login(
          LoginOptions.PHONE,
          {
            phoneNumber,
          }
        );
        window.confirmationResult = confirmationResult;
      }
      
      navigate(target, {
        state: { phoneNumber, verificationId },
      });
    } catch (err) {}
    setLoading(false);
};

export const paymentMethodSelector = (paymentMethodImage: string) => {
  if (paymentMethodImage === "visa") {
    return VisaImage;
  } else if (paymentMethodImage === "mastercard") {
    return MasterCardImage;
  } else if (paymentMethodImage === "amex") {
    return AmexImage;
  } else if (paymentMethodImage === "diners") {
    return DinersImage;
  } else if (paymentMethodImage === "discover") {
    return DiscoverImage;
  } else if (paymentMethodImage === "jcb") {
    return JCBImage;
  } else if (paymentMethodImage === "unionpay") {
    return UnionpayImage;
  } else return "";
};

export const useOutsideClick = (ref: React.MutableRefObject<any>, iconRef: React.MutableRefObject<any>, handleClose: () => void) => {
  const handleClick = useCallback((event: { target: any }) => {
    if (
      !ref?.current?.contains(event.target) &&
      !iconRef?.current?.contains(event.target)
    ) {
      handleClose();
    }
  }, [handleClose, iconRef, ref]);
  useEffect(() => {
    document.addEventListener("mouseup", handleClick);

    return () => {
      document.removeEventListener("mouseup", handleClick);
    };
  }, [handleClick]);
};

export const getTasks = async (startDate: string, endDate: string, resync?: boolean ) => {
  try {
    const response = await new TasksResource().readMany({
      start_date: startDate,
      end_date: endDate,
    }, resync);
    return response.data;
  } catch (_) {
    return [];
  }
};

export const getInitialData = async (setTodaysTasks: (value: React.SetStateAction<IBoard | undefined>) => void) => {
  const tasksResponse = await getTasks(DateTime.now().toSQLDate()!, DateTime.now().toSQLDate()!, true);
  if (!tasksResponse) return;
  const tasksData = getAllBoardData(tasksResponse, DateTime.now(), false);
  const tasksForToday = tasksData.find(
    (element: { date: DateTime }) =>
      element.date.toISODate() === DateTime.now().toISODate()
  );
  setTodaysTasks(tasksForToday);
};

export const getTaskStartTime = (taskTarget: TaskEntity, boardData?: IBoard) => {
  if (boardData) {
    const times: DateTime[] = [];
    boardData?.data?.forEach((item) => {
      if (
        item?.type === EventType.MEET &&
        item?.attributes?.task_id &&
        item?.attributes?.task_id === taskTarget?.id
      ) {
        times.push(DateTime.fromISO(item?.attributes?.meet_start_time));
      }
    });
    if (times.length > 0) {
      return times;
    }
  }
  if (taskTarget?.attributes?.start_time) {
    return [
      DateTime.fromSeconds(parseInt(taskTarget?.attributes?.start_time, 10)),
    ];
  }
  return undefined;
};

export const todoistWriteAccessChecker = (data?: TaskEntity, todoistScopes?: string[]) => {
  if (data?.attributes?.provider === ThirdPartyProvider.TODOIST && !todoistScopes?.includes(Scope.WRITE)) {
    store.dispatch(setPermissionModalType(PermissionModalType.TODOIST));
    return false;
  };
  return true;
}

export const nearestDate = (dates: DateTime[], target: DateTime) => {
  if (!target) {
    target = DateTime.now();
  }
  let nearest = Infinity;
  let result = DateTime.now();
  dates.forEach(function (date, index) {
    let distance = Math.abs(date.toMillis() - target.toMillis());
    if (distance < nearest) {
      nearest = distance;
      result = date;
    }
  });
  return result;
};
