import { palette } from "assets/palette";
import { LoginWrapper } from "components/login-wrapper/LoginWrapper";
import { i18n } from "constants/i18n.constants";
import { CustomButton } from "elements/button/Button";
import { ProgressBar } from "elements/progress-bar/ProgressBar";
import { Text } from "elements/text/Text";
import React from "react";
import { useCreateReminder } from "./useCreateReminder";
import { usePageHook } from "hooks/usePageHook";

export const CreateReminder = () => {
  const { progressValue, onPressNext, onPressNotificationButton, onPressEmailNotificationButton, loading } =
    useCreateReminder();
    usePageHook();
  return (
    <LoginWrapper
      showBottom={false}
      title={i18n.t("create_reminder_header")}
      headerRightText={i18n.t("next")}
      onPressHeaderRightText={onPressNext}
      backButton
    >
      <Text style={styles.secondarySubtitle}>
        {i18n.t("create_reminder_subheader")}
      </Text>
      <CustomButton
        loading={loading}
        spinnerColor={"primary"}
        onPress={onPressNotificationButton}
        style={styles.notificationButtonStyle}
        text={i18n.t("remind_me_via_notification")}
      />
      <div style={styles.gap} />
      <CustomButton
        loading={loading}
        spinnerColor={"primary"}
        onPress={onPressEmailNotificationButton}
        style={styles.emailButtonStyle}
        text={i18n.t("remind_me_via_email")}
      />
      <ProgressBar
        style={styles.progress}
        value={progressValue}
        progressProps={{ color: "info" }}
      />
    </LoginWrapper>
  );
};

const styles: Record<string, React.CSSProperties> = {
  secondarySubtitle: {
    fontSize: 16,
    color: palette.gray.medium,
    display: "flex",
    textAlign: "center",
    marginBottom: 60,
  },
  progress: {
    marginTop: 200,
    marginBottom: 30,
  },
  notificationButtonStyle: {
    width: "100%",
    backgroundColor: palette.black,
    height: 50,
  },
  emailButtonStyle: {
    width: "100%",
    backgroundColor: palette.gray.mediumLight,
    height: 50,
  },
  gap: {
    height: 20,
  },
};
