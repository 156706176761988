import { SubscriptionPlansResource } from "communicators/resources/subscription-plans/subscription-plans.resource";
import { SubscriptionPlanEntity } from "communicators/resources/subscription-plans/subscription-plans.type";
import { SubscriptionsResource } from "communicators/resources/subscription/subscription.resource";
import { SubscriptionPlan } from "components/subscription-period-selection/SubscriptionPeriodSelection.type";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setSubscriptions } from "redux-store/actions/user-settings.action";
import { useAppDispatch, useAppSelector } from "redux-store/store.hooks";

export const useSubscriptionSelection = () => {
  const [enableFreeTrialSelected, setEnableFreeTrialSelected] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan>(
    SubscriptionPlan.MONTHLY
  );
  const [loading, setLoading] = useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState<SubscriptionPlanEntity[]>([]);
  const { subscriptions } = useAppSelector((state) => ({
    subscriptions: state.userSetting.subscriptions
  }));

  const activeSubscriptions = subscriptions.filter((item) => (item.attributes.status === "active" || item.attributes.status === "trialing"));

  const getSubscriptionPlans = async () => {
    const {data} = await new SubscriptionPlansResource().readMany();
    setSubscriptionPlans(data);
  }

  const dispatch = useAppDispatch();

  useEffect(() => {
    getSubscriptionPlans();
  }, []);

  const monthlyPSubscriptionPlan = subscriptionPlans?.filter((item) => 
    item?.attributes?.interval === "month"
  );

  const yearlyPSubscriptionPlan = subscriptionPlans?.filter((item) => 
    item?.attributes?.interval === "year"
  )

  const monthlyPrice = (monthlyPSubscriptionPlan?.[0]?.attributes?.price / 100).toFixed(2);

  const yearlyPrice = ((yearlyPSubscriptionPlan?.[0]?.attributes?.price / 100)/12).toFixed(2);

  const navigate = useNavigate();

  const onPressNext = async () => {
    setLoading(true);
    if(activeSubscriptions.length !== 0) {
      navigate("/onboarding/tools");
    } else {
      const response = await new SubscriptionsResource().createOne({
        subscription: { plan: selectedPlan },
      });
      dispatch(setSubscriptions([response.data]));
      navigate("/onboarding/tools");
    }
    setLoading(false);
  };

  const onPressFreeTrial = async () => {
    setLoading(true);
    if(activeSubscriptions.length !== 0) {
      navigate("/onboarding/tools");
    } else {
      const response = await new SubscriptionsResource().createOne({
        subscription: { plan: selectedPlan },
      });
      dispatch(setSubscriptions([response.data]));
      navigate("/onboarding/tools");
    }
    setLoading(false);
  };

  const onPressFreeTrialCheck = () => {
    setEnableFreeTrialSelected(!enableFreeTrialSelected);
  };

  const onPressSubscribe = () => {
    navigate("/checkout");
  };
  return {
    enableFreeTrialSelected,
    selectedPlan,
    loading,
    setSelectedPlan,
    onPressNext,
    onPressFreeTrial,
    onPressSubscribe,
    onPressFreeTrialCheck,
    monthlyPrice,
    yearlyPrice
  };
};
