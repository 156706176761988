import { i18n } from "constants/i18n.constants";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { Text } from "elements/text/Text";
import "./MovedToInboxPopup.scss";

interface Props {
  onClickClose?: () => void;
  movedToInboxTaskTitles: string[];
}

export const MovedToInboxPopup = ({
  onClickClose,
  movedToInboxTaskTitles,
}: Props) => {
  return (
    <div className="moved-to-inbox-popup">
      <div className="moved-to-inbox-popup-left-color" />
      <Text className="moved-to-inbox-popup-text">{`${i18n.t(
        "reminder"
      )} "${movedToInboxTaskTitles
        .map((item) => `"${item}"`)
        .join(", ")}" ${i18n.t("moved_to_inbox")}`}</Text>
      <Icon
        name={IconName.CLOSE}
        width={12}
        height={12}
        onPress={onClickClose}
      />
    </div>
  );
};
