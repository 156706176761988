import * as React from "react";
import { styled } from "@mui/material/styles";
import { palette } from "assets/palette";
import { AllBoardDataEntityType, IBoard } from "interfaces/main.interfaces";
import { DateTime } from "luxon";
import { TaskEntity } from "communicators/resources/event-resources/tasks/tasks.type";
import { IInsumoCalendar } from "helpers/data.helper";
import { DrawerRight } from "pages/private/home/components/DrawerRight/DrawerRight";
import { PreferencesDialog } from "pages/private/home/components/PreferencesDialog/PreferencesDialog";
import { ITimelineItem } from "pages/private/home/components/DrawerRight/types";
import { DrawerLeft } from "../DrawerLeft/DrawerLeft";
import { DRAWER_WIDTH } from "pages/private/home/constants";

interface Props {
  children?: React.ReactNode;
  rightDrawerBoardData: IBoard[];
  boardData: IBoard[];
  refreshBoards: () => void;
  onPressBoardPlus: (date: DateTime) => void;
  onTaskCreate: (index: number, boardDate: DateTime, createdTask: TaskEntity) => void;
  inboxVisibleData: IBoard[];
  rightDrawerStartDate: DateTime;
  onPressNextDay: () => void;
  onPressPreviousDay: () => void;
  updateTaskWithRightDrawerDrop: (newDroppedTask: TaskEntity, timeItem: ITimelineItem) => void;
  leftDrawerType: string;
  handleOpenUnsubscriptionDialog: () => void;
  insumoCalendarEventData: IInsumoCalendar[];
  isLoading?: boolean;
  googleSignIn: any;
  googleAuthLoading: boolean;
  previousMonth: () => void;
  nextMonth: () => void;
  inboxStartDate: DateTime; 
  inboxLoading: boolean;
  onClickCheckBox: (data: AllBoardDataEntityType) => void;
  deleteTask?: (data: AllBoardDataEntityType) => void;
  somedayLoading: boolean;
  somedayData: IBoard[];
}

export const OnboardingDndWrapper = ({
  children,
  refreshBoards,
  boardData,
  onPressBoardPlus,
  onTaskCreate,
  inboxVisibleData,
  rightDrawerBoardData,
  rightDrawerStartDate,
  onPressNextDay,
  onPressPreviousDay,
  updateTaskWithRightDrawerDrop,
  leftDrawerType,
  handleOpenUnsubscriptionDialog,
  insumoCalendarEventData,
  isLoading,
  googleSignIn,
  googleAuthLoading,
  previousMonth,
  nextMonth,
  inboxStartDate,
  inboxLoading,
  onClickCheckBox,
  deleteTask,
  somedayData,
  somedayLoading,
}: Props) => {
  return (
    <div style={styles.container}>
      <DrawerLeft
        boardData={boardData}
        onPressBoardPlus={onPressBoardPlus}
        onTaskCreate={onTaskCreate}
        inboxVisibleData={inboxVisibleData}
        leftDrawerType={leftDrawerType}
        previousMonth={previousMonth}
        nextMonth={nextMonth}
        inboxStartDate={inboxStartDate}
        inboxLoading={inboxLoading}
        onClickCheckBox={onClickCheckBox}
        deleteTask={deleteTask}
        somedayData={somedayData}
        somedayLoading={somedayLoading}
      />
      <Main leftOpen={true} rightOpen={true}>
        {children}
      </Main>
      <DrawerRight refreshBoards={refreshBoards} isLoading={isLoading} rightDrawerData={rightDrawerBoardData} open={true} rightDrawerStartDate={rightDrawerStartDate} onPressNextDay={onPressNextDay} onPressPreviousDay={onPressPreviousDay} updateTaskWithRightDrawerDrop={updateTaskWithRightDrawerDrop} insumoCalendarEventData={insumoCalendarEventData} />
      <PreferencesDialog refreshBoards={refreshBoards} handleOpenUnsubscriptionDialog={handleOpenUnsubscriptionDialog} googleSignIn={googleSignIn} googleAuthLoading={googleAuthLoading} />
    </div >
  );
};

export const Main = styled("div")<{
  leftOpen?: boolean;
  rightOpen?: boolean;
}>(({ theme, leftOpen, rightOpen }) => ({
  flexGrow: 1,
  height: "100%",
  backgroundColor: palette.white,
  padding: theme.spacing(3),
  transition: theme.transitions.create(["margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `${0}px`,
  ...(leftOpen && {
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: `${0}px`,
  }),
  marginRight: `-${DRAWER_WIDTH * 2}px`,
  ...(rightOpen && {
    marginRight: `${0}px`,
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: palette.white,
    width: "100%"
  },
};