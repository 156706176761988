import { useEffect, useState } from "react";
import { SecondDrawerType, LeftDrawerTypes } from "interfaces/main.interfaces";

interface Props {
  leftDrawerType: string;
}

export const useDrawerLeftDndHook = ({ leftDrawerType }: Props) => {
  const [secondDrawerOpened, setSecondDrawerOpened] = useState<{
    type: string | null;
    opened: boolean;
  }>({
    type: null,
    opened: false,
  });

  const closeSecondDrawer = () => {
    setSecondDrawerOpened({ type: null, opened: false });
  };

  const openSecondDrawer = (type: string) => {
    if (type === secondDrawerOpened.type) {
      closeSecondDrawer();
      return;
    }
    setSecondDrawerOpened({ type, opened: true });
  };

  const openSecondDrawerAfterSearch = (type: string) => {
    setSecondDrawerOpened({ type, opened: true });
    if (type === "") {
      setSecondDrawerOpened({ type: null, opened: false });
    }
  };

  useEffect(() => {
    openSecondDrawerAfterSearch(
      leftDrawerType === LeftDrawerTypes.COMPLETED_TASKS
        ? SecondDrawerType.COMPLETED_TASKS
        : ""
    );
  }, [leftDrawerType]);

  return {
    secondDrawerOpened,
    openSecondDrawer,
    closeSecondDrawer,
    openSecondDrawerAfterSearch,
  };
};
