import React, { useEffect, useState } from "react";
import { palette } from "assets/palette";
import { DateTime } from "luxon";
import { CustomTooltip } from "components/custom-tooltip/CustomTooltip";
import { MaskedInput } from "elements/masked-input/MaskedInput";
import { i18n } from "constants/i18n.constants";
import { getDateFormat } from "helpers/utils.helper";

interface Props {
  header?: string;
  selectedTime?: DateTime | string | DateTime[];
  scheduledDate?: string | DateTime;
  dueDate?: DateTime;
  onPress?: () => void;
  tooltipDescription?: string;
  style?: React.CSSProperties;
  format?: string;
  isDisabled?: boolean;
  isInvalidDueDate?: boolean;
  onTextChange?: (text: string, displayed: string) => void;
  isDate?: boolean;
  isTime?: boolean;
  date?: DateTime | string;
  onDateComlete?: (date: DateTime) => void
}

export const TimeDateItem = ({
  header,
  selectedTime,
  onPress,
  tooltipDescription,
  style,
  format = "XX/XX/XXXX",
  isDisabled,
  isInvalidDueDate,
  isDate,
  isTime,
  date,
  onDateComlete
}: Props) => {
  const isMultiple = Array.isArray(selectedTime) && selectedTime.length > 1;
  const textSelector = () => {
    if (isTime && selectedTime && !Array.isArray(selectedTime)) {
      if (typeof selectedTime === "string") {
        return selectedTime;
      } else {
        return selectedTime.toLocaleString(DateTime.TIME_SIMPLE);
      }
    } else if (isTime && selectedTime && Array.isArray(selectedTime)) {
      if (selectedTime.length > 1) {
        return i18n.t("multiple");
      } else if(selectedTime.length === 1) {
        return selectedTime[0].toLocaleString(DateTime.TIME_SIMPLE);
      }
    } else if (isDate && date) {
      if (typeof date === "string") {
        return date;
      } else {
        if (date?.year === 3000) return i18n.t("someday");
        return date.toFormat(getDateFormat());
      }
    }
    return ""
  };
  const [text, setText] = useState(textSelector());

  const onChangeText = (_: string, displayed: string) => {
    if(isDate && onDateComlete && DateTime.fromFormat(displayed, getDateFormat()).isValid){
      onDateComlete(DateTime.fromFormat(displayed, getDateFormat()))
    }
    setText(displayed);
  };

  useEffect(() => {
    setText(textSelector());
  }, [selectedTime, date, isMultiple]);

  return (
    <CustomTooltip description={tooltipDescription} direction="top-start">
      <MaskedInput
        format={isMultiple ? "XXXXXXXX" : format}
        label={header}
        value={text}
        initialValue={text}
        style={{ ...styles.container, ...style }}
        onFocus={onPress}
        isDisabled={isMultiple || isDisabled}
        onTextChange={onChangeText}
        isInvalidDueDate={
          date && textSelector() === i18n.t("someday")
            ? false
            : isInvalidDueDate
        }
      />
    </CustomTooltip>
  );
};

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  timeText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 12,
    color: palette.gray.medium,
  },
  headerText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 10,
    color: palette.gray.medium,
  },
  buttonStyle: {
    backgroundColor: palette.gray.iceLight,
    border: `1px solid ${palette.gray.dark}`,
    borderRadius: 10,
    padding: "8px 10px 8px 10px",
  },
};
