import { UserOwnedResourceManagementService } from "communicators/services/UserOwnedResourceManagementService";
import {
  IThirdPartyInformationResource,
  Scope,
} from "./third-party-information.type";

export class GoogleThirdPartyResource extends UserOwnedResourceManagementService<IThirdPartyInformationResource> {
  constructor() {
    super("GOOGLE_THIRD_PARTY_PROVIDER");
  }

  createProvider = async (server_auth_code: string) =>
    this.createOne({
      google_calendar: {
        server_auth_code,
        redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URL,
        scopes: [Scope.READ, Scope.DELETE, Scope.WRITE],
      },
    });
}
