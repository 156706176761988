import { palette } from "assets/palette";
import { TagEntity } from "communicators/resources/tags/tags.types";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import "./TagListItem.scss";

interface Props {
  tag: TagEntity;
  onClick?: (tag_id: string) => void;
  isNotSelectedFavorite?: boolean;
}

export const TagListItem = ({ tag, onClick, isNotSelectedFavorite }: Props) => {
  return (
    <div className="tag-list-item-container">
      <Icon name={IconName.TAG} style={styles.tagIcon} />
      <div
        className={
          isNotSelectedFavorite
            ? "tag-list-item-not-selected-favorite-tag"
            : "tag-list-item-tag"
        }
        style={{
          backgroundColor: isNotSelectedFavorite
            ? palette.gray.iceLight
            : tag.attributes.color,
        }}
        onClick={() => onClick?.(tag.id)}
      >
        <div
          className="tag-list-item-favorite-tag"
          style={{ backgroundColor: tag.attributes.color }}
        />
        {tag.attributes.name}
      </div>
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  tagIcon: {
    marginLeft: -5,
  },
};
