import { palette } from "assets/palette";
import { i18n } from "constants/i18n.constants";
import { Text } from "elements/text/Text";
import './AddPaymentMethodItem.scss';
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";

interface Props {
  onClickAddPaymentMethod: () => void;
}

export const AddPaymentMethodItem = ({onClickAddPaymentMethod}: Props) => {
  return (
    <div className="add-payment-method-item-container" onClick={onClickAddPaymentMethod}>
      <Icon
        fill={palette.gray.iceLight}
        strokeColor={palette.gray.medium}
        strokeWidth="1"
        name={IconName.PLUS}
      />
      <Text className="add-payment-method-text">{i18n.t("add_payment_method")}</Text>
    </div>
  );
};
