import Dialog from "@mui/material/Dialog";
import { RocketImage } from "assets/images";
import { i18n } from "constants/i18n.constants";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { useStreakModalHook } from "./useStreakModalHook";
import "./StreakModal.scss";
import { AchievementModal } from "components/achievement-modal/AchievementModal";
import { ZeroTaskEntity } from "communicators/resources/zero-task/zero-task.type";

interface Props {
  isVisible: boolean;
  closeModal: () => void;
  streakInfo?: ZeroTaskEntity;
}

export const StreakModal = ({ isVisible, closeModal, streakInfo }: Props) => {
  const {
    ref,
    isAchievementModalVisible,
    task_zero_days,
    longest_streak,
    current_streak,
    isDetailsFirstShown,
  } = useStreakModalHook({ closeModal, isVisible, streakInfo });
  return (
    <Dialog
      fullScreen
      open={isVisible}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <div id="streak-modal-container-id" className="streak-modal-container">
        <div className="streak-modal-center-container">
          {isAchievementModalVisible ? (
            <AchievementModal
              task_zero_days={task_zero_days}
              longest_streak={longest_streak}
              current_streak={current_streak}
            />
          ) : isDetailsFirstShown ? (
            <div className="streak-modal-center-animation-container">
              <img
                src={RocketImage}
                alt="rocket"
                className="streak-modal-center-image"
              />
              <span className="streak-modal-center-text-animation-container-rolltext">
                <div className="roller">
                  <div className="streak-modal-center-prev-text-invisible">
                    {current_streak - 1}
                  </div>
                  <div className="streak-modal-center-next-text-invisible">
                    {current_streak}
                  </div>
                </div>
              </span>
              <div className="streak-modal-center-text">
                {i18n.t("you_have_hit_zero_tasks", {
                  week: `${current_streak}`,
                })}
              </div>
            </div>
          ) : null}
        </div>
        <div className="streak-modal-bottom-left-icon-container" ref={ref}>
          <div className="streak-modal-bottom-left-icon-ring">
            <Icon name={IconName.STREAK_CUP} width={33} height={40} />
          </div>
        </div>
      </div>
    </Dialog>
  );
};
