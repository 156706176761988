import { PaymentMethodResource } from "communicators/resources/payment-method/payment-method.resource";
import { PaymentMethodEntity } from "communicators/resources/payment-method/payment-method.type";
import { useEffect, useState } from "react";
import { setPreferencesTab } from "redux-store/actions/home-page.actions";
import { useAppDispatch, useAppSelector } from "redux-store/store.hooks";
import { PreferenceTab } from "../PreferencesDialog/PreferenceDialog.types";

export const usePreferencesPaymentMethodHook = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentMethodData, setPaymentMethodData] =
    useState<PaymentMethodEntity[]>();
  const { userInfo } = useAppSelector((state) => ({
    userInfo: state.userSetting.userInfo,
  }));

  const getPaymentMethodData = async () => {
    setLoading(true);
    try {
      const { data } = await new PaymentMethodResource().readMany();
      setPaymentMethodData(data);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  const defaultPaymentMethod = paymentMethodData?.filter(
    (item) => item.attributes.card.default
  );

  const nonDefaultPaymentMethods = paymentMethodData?.filter(
    (item) => item.attributes.card.default === false
  );

  const onClickAddPaymentMethod = () => {
    dispatch(setPreferencesTab(PreferenceTab.ADD_PAYMENT_METHOD));
  };

  const setPrimary = async (id: string): Promise<void> => {
    try {
      await new PaymentMethodResource().updateOne(id, {});
      setLoading(true);
      getPaymentMethodData();
    } catch {}
  };

  const removeCard = async (id: string): Promise<void> => {
    try {
      await new PaymentMethodResource().removeOne(id);
      setLoading(true);
      getPaymentMethodData();
    } catch {}
  };

  useEffect(() => {
    getPaymentMethodData();
  }, []);

  const isAppleHistory =
    !!userInfo?.attributes?.is_premium && paymentMethodData?.length === 0;

  return {
    paymentMethodData,
    defaultPaymentMethod,
    nonDefaultPaymentMethods,
    onClickAddPaymentMethod,
    loading,
    setPrimary,
    isAppleHistory,
    removeCard,
  };
};
