import { TaskTags } from './../communicators/resources/event-resources/tasks/tasks.type';
import { SubscriptionEntity } from "communicators/resources/subscription/subscription.type";
import { TagEntity } from "communicators/resources/tags/tags.types";
import { DateTime } from "luxon";
import { Tag } from "pages/private/home/components/DrawerLeft/Tags/useTagsHook";
import { AllBoardDataEntityType } from 'interfaces/main.interfaces';
import { EventType } from 'communicators/resources/event-resources/event-resources.type';
import { LocalStorageParam } from 'constants/local-storage.constant';

export const containsOnlyNumbers = (str: string) => {
  return /^\d+$/.test(str);
};

export const sleep = (time: number) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });

const BASE_WIDTH = 1920;

export const resizeByResolutionWidth = (size: number) => {
  // const result = (size * window.innerWidth) / BASE_WIDTH;
  const result = (size * 1500) / BASE_WIDTH;
  return result;
};

export const getProgressValue = (subscriptions: SubscriptionEntity[]) => {
  if (
    !subscriptions[0] ||
    !subscriptions[0].attributes.trial_started_at ||
    !subscriptions[0].attributes.trial_ends_at
  ) {
    return { error: true, remainingDay: 0, totalDay: 0 };
  }
  if (subscriptions[0].attributes.status !== "trialing") {
    return { error: true, remainingDay: 0, totalDay: 0 };
  }

  const trialStartDate = DateTime.fromISO(
    subscriptions[0].attributes.trial_started_at
  );
  const trialEndDate = DateTime.fromISO(
    subscriptions[0].attributes.trial_ends_at
  );
  const now = DateTime.now();
  const totalDay = trialEndDate.diff(trialStartDate, ["days"]).days;
  const remainingDay = trialEndDate.diff(now, ["days"]).days;
  return {
    error: false,
    remainingDay: Math.floor(remainingDay),
    totalDay: Math.floor(totalDay),
  };
};

export const getDateFormat = () => {
  return navigator.language === "en-US" ? "MM/dd/yyyy" : "dd/MM/yyyy";
};

export function fetchAndCacheImage(url: string) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = url;

    // Resolve the promise once the image is loaded and cached
    image.onload = () => resolve(image);

    // Reject the promise if there's an error loading the image
    image.onerror = (error) => reject(error);
  });
}

export const is12hTextPresent = (date: DateTime) => {
  if (
    date.toLocaleString(DateTime.TIME_SIMPLE).toLowerCase().includes("am") ||
    date.toLocaleString(DateTime.TIME_SIMPLE).toLowerCase().includes("pm")
  ) {
    return true;
  }
  return false;
};

export const parseTags = (tags: Tag[]) => {
  const ROW_COUNT = 3;
  const tagMatrix: (Tag | null)[][] = [[]];
  tags.forEach((tag) => {
    if (tagMatrix[tagMatrix.length - 1].length < ROW_COUNT) {
      tagMatrix[tagMatrix.length - 1].push(tag);
    } else {
      tagMatrix.push([tag]);
    }
  });
  const lastRowLength = tagMatrix[tagMatrix.length - 1].length;
  if (lastRowLength < ROW_COUNT) {
    for (let i = 0; i < ROW_COUNT - lastRowLength; i++) {
      tagMatrix[tagMatrix.length - 1].push(null);
    }
  }
  return tagMatrix;
};

export function findMissingObjects(arr1: TagEntity[], property: keyof TagEntity & keyof TaskTags, arr2?: TaskTags[]): TagEntity[] {
  const missingObjects: TagEntity[] = arr1.filter(obj1 => {
    return !arr2?.some(obj2 => obj2.tag[property] === obj1[property]);
  });

  return missingObjects;
}

export const COLORS = [
  "#8B99E4",
  "#E5E8FD",
  "#FDE3D8",
  "#FF9264",
  "#F6F3F8",
  "#000000",
  "#FFF2CB",
  "#FFBF43",
  "#6475CF",
  "#B6D0FF",
  "#F4F6FD",
  "#4158D7",
  "#F6F3E8",
  "#E3F8E5",
  "#336F61",
  "#DDE1E6",
  "#5A5A5A",
  "#B9FBDB",
  "#28BA74",
  "#FFA4AD",
  "#EB4354",
  "#7378B7",
  "#D7DAF4",
  "#CA94F5",
  "#F6EBFF",
  "#FFBF43",
  "#FFF2CB",
  "#FF9F74",
  "#FFF5F0",
  "#A6D485",
  "#EDFFDF",
];

export const getRandomColor = () => {
  const randomIndex = Math.floor(Math.random() * COLORS.length);
  return COLORS[randomIndex];
};

export const isItemMagicPlanner = (item: AllBoardDataEntityType) => {
  if (item.type === EventType.MEET) {
    let magicPlannerIds = [];
    try {
      const localMagicPlannerIds = localStorage.getItem(
        LocalStorageParam.MAGIC_PLANNER_IDS
      );
      if (localMagicPlannerIds) {
        magicPlannerIds = JSON.parse(localMagicPlannerIds);
      }
    } catch (_) {}
    if (
      item.attributes.moved_by_magic_planner ||
      magicPlannerIds.includes(item.id)
    ) {
      return true;
    }
  }
  return false;
};
