import { palette } from "assets/palette";
import { LoginWrapper } from "components/login-wrapper/LoginWrapper";
import { i18n } from "constants/i18n.constants";
import { CustomButton } from "elements/button/Button";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { Text } from "elements/text/Text";
import { resizeByResolutionWidth } from "helpers/utils.helper";
import React from "react";
import { useSubscriptionSuccess } from "./useSubscriptionSuccess";
import { usePageHook } from "hooks/usePageHook";

export const SubscriptionSuccess = () => {
  const { onPressNext, downloadIOSApp, downloadAndroidApp } = useSubscriptionSuccess();
  usePageHook();
  return (
    <LoginWrapper
      showBottom={false}
      title={i18n.t("thanks_for_subscribing")}
      headerRightText={i18n.t("next")}
      onPressHeaderRightText={onPressNext}
      headerIcon={IconName.SUBSCRIPTION_DIAMOND}
    >
      <div style={styles.container}>
        <div style={styles.mainContainer}>
          {Array.from(i18n.t("subscription_features")).map((item: string) => {
            return (
              <div style={styles.featuresContainer}>
                <Icon name={IconName.SUBSCRIPTION_CHECK} />
                <div style={styles.verticalGap} />
                <Text style={styles.subscriptionFeatureText}>{item}</Text>
                <div style={styles.verticalGap} />
              </div>
            );
          })}
        </div>
        <Text style={styles.premiumAccount}>{i18n.t("premium_account")}</Text>
        <div style={styles.downloadMobileAppContainer}>
          <Text style={styles.downloadMobileApp}>
            {i18n.t("download_mobile_app")[0]}
          </Text>
          &nbsp;
          <Text style={styles.underlinedDownloadMobileApp} onPress={downloadIOSApp}>
            {i18n.t("download_mobile_app")[1]}
          </Text>
          &nbsp;
          <Text style={styles.downloadMobileApp}>
            {i18n.t("download_mobile_app")[2]}
          </Text>
          &nbsp;
          <Text style={styles.underlinedDownloadMobileApp} onPress={downloadAndroidApp}>
            {i18n.t("download_mobile_app")[3]}
          </Text>
        </div>
        <div style={styles.buttonContainer}>
          <CustomButton
            style={styles.startPlanning}
            text={i18n.t("go_to_dashboard")}
            onPress={onPressNext}
          />
        </div>
      </div>
    </LoginWrapper>
  );
};

const styles: Record<string, React.CSSProperties> = {
  verticalGap: {
    width: resizeByResolutionWidth(20),
  },
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly"
  },
  subscriptionFeatureText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 18,
    color: palette.gray.dark,
  },
  premiumAccount: {
    fontFamily: "Gilroy-Semibold",
    fontWeight: "500",
    fontSize: 25,
    color: palette.black,
  },
  downloadMobileApp: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 18,
    color: palette.gray.dark,
  },
  underlinedDownloadMobileApp: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 18,
    color: palette.gray.dark,
    textDecorationLine: "underline",
    cursor: "pointer"
  },
  downloadMobileAppContainer: {
    display: "flex",
    flexDirection: "row",
  },
  startPlanning: {
    width: "50%",
    backgroundColor: palette.black,
    height: resizeByResolutionWidth(50),
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "stretch",
  },
  featuresContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: resizeByResolutionWidth(30),
  },
  mainContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
};
