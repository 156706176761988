import { EventEmitter as EvenEmitterModule } from "fbemitter";

export const EventEmitter = new EvenEmitterModule();

export enum EventEmitterType {
  ON_CREATE_NEW_MEET_FROM_TASK = "on_create_new_meet_from_task",
  CHANGE_LEFT_DRAWER_TYPE = "change_left_drawer_type",
  ON_TASK_DRAGGED = "on_task_dragged",
  ON_TASK = "on_task",
}
