import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { countries, ICountry } from "assets/countries/countries";
import { i18n } from "constants/i18n.constants";

interface Props {
  open: boolean;
  handleClose?: () => void;
  onSelect: (country: ICountry) => void;
}

export const CountrySelectDialog = ({ open, handleClose, onSelect }: Props) => {
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{i18n.t("select_your_country")}</DialogTitle>
      <List>
        {countries.map((country) => (
          <ListItem
            button
            onClick={() => onSelect(country)}
            key={country.callingCode + country.name}
          >
            <ListItemAvatar>
              <img style={styles.image} src={country.flag} alt="country-flag" />
            </ListItemAvatar>
            <ListItemText primary={country.name} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

const styles: Record<string, React.CSSProperties> = {
  image: {
    width: 30,
    height: 30,
  },
};
