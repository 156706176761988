import { AllTagsActionTypes } from "./../types/tags.action.type";
import { TagsDispatchTypes } from "./../types/dispatch.type";
import { TagsState } from "redux-store/types/state.type";

const INITIAL_STATE: TagsState = {
  tagsData: [],
  selectedTagsData: [],
};

export const tagsReducer = (
  state = INITIAL_STATE,
  action: AllTagsActionTypes
) => {
  switch (action.type) {
    case TagsDispatchTypes.setTagsData:
      return {
        ...state,
        tagsData: action.payload,
      };
    case TagsDispatchTypes.setSelectedTagsData:
      return {
        ...state,
        selectedTagsData: action.payload,
      };
    default:
      return state;
  }
};
