import { Button } from "@mui/material";
import { palette } from "assets/palette";
import { LoginWrapper } from "components/login-wrapper/LoginWrapper";
import { i18n } from "constants/i18n.constants";
import { CustomButton } from "elements/button/Button";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { ProgressBar } from "elements/progress-bar/ProgressBar";
import { Text } from "elements/text/Text";
import { DateTime } from "luxon";
import React from "react";
import { usePlaningTimeSelection } from "./usePlaningTimeSelection";
import { usePageHook } from "hooks/usePageHook";

export const PlaningTimeSelection = () => {
  const {
    progressValue,
    selectedTime,
    hours,
    accordionOpened,
    onPressAccordion,
    sendPlanningTime,
    onPressItem,
    loading,
  } = usePlaningTimeSelection();
  usePageHook();
  return (
    <LoginWrapper
      showBottom={false}
      title={i18n.t("set_up_planning_routine")}
      headerRightText={i18n.t("next")}
      onPressHeaderRightText={sendPlanningTime}
      backButton
    >
      <Text style={styles.secondarySubtitle}>
        {i18n.t("plan_day_subtitle")}
      </Text>
      <div style={styles.cardWrapper}>
        <button onClick={onPressAccordion} style={styles.row}>
          <Text style={styles.timeText}>
            {selectedTime.toLocaleString(DateTime.TIME_SIMPLE)}
          </Text>
          <Text style={styles.reminderText}>{i18n.t("set_reminder")}</Text>
        </button>
        {accordionOpened ? (
          <div style={styles.hoursWrapper}>
            {hours.map((item, index) => {
              const checked =
                item.time.hour === selectedTime.hour &&
                item.time.minute === selectedTime.minute;
              return (
                <Button
                  style={
                    checked
                      ? styles.selectedButtonStyle
                      : styles.unselectedButtonStyle
                  }
                  variant="text"
                  onClick={() => onPressItem(index, item.time)}
                  id={`${index}`}
                  key={index}
                >
                  <Text
                    style={
                      checked
                        ? styles.selectedTextStyle
                        : styles.unselectedTextStyle
                    }
                  >
                    {item.time.toLocaleString(DateTime.TIME_SIMPLE)}
                  </Text>
                  <div
                    style={
                      checked
                        ? styles.checkedContainer
                        : styles.uncheckedContainer
                    }
                  >
                    <Icon name={IconName.TICK} fill={palette.white} width={8} />
                  </div>
                </Button>
              );
            })}
          </div>
        ) : null}
      </div>
      <div style={{ height: accordionOpened ? 0 : 200 }} />
      <CustomButton
        loading={loading}
        spinnerColor="primary"
        style={styles.buttonStyle}
        text={i18n.t("start_planing")}
        onClick={sendPlanningTime}
      />
      <ProgressBar
        style={styles.progress}
        value={progressValue}
        progressProps={{ color: "info" }}
      />
    </LoginWrapper>
  );
};

const styles: Record<string, React.CSSProperties> = {
  checkbox: {
    marginBottom: 20,
    marginRight: 60,
    marginLeft: 60,
  },
  secondarySubtitle: {
    fontSize: 20,
    color: palette.gray.medium,
    display: "flex",
    textAlign: "center",
    fontWeight: "500",
    fontFamily: "Gilroy-Medium",
  },
  marginForSecondarySubtitle: {
    marginBottom: 30,
  },
  progress: {
    marginTop: 20,
    marginBottom: 30,
  },
  cardWrapper: {
    width: 300,
    borderRadius: 10,
    border: `1px solid ${palette.gray.mediumLight}`,
    padding: 20,
    marginTop: 30,
    marginBottom: 30,
  },
  row: {
    display: "flex",
    alignSelf: "stretch",
    backgroundColor: palette.white,
    width: "100%",
    borderWidth: 0,
  },
  timeText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 20,
    color: palette.gray.dark,
    flex: 1,
    textAlign: "left",
  },
  reminderText: {
    fontFamily: "Gilroy-Semibold",
    fontWeight: "500",
    fontSize: 20,
    color: palette.gray.mediumLight,
  },
  hoursWrapper: {
    height: 200,
    overflowY: "scroll",
    width: 150,
  },
  selectedButtonStyle: {
    backgroundColor: palette.gray.iceLight,
    borderRadius: 10,
    width: 110,
    textTransform: "none",
    margin: 5,
    display: "flex",
    flexDirection: "row",
  },
  unselectedButtonStyle: {
    backgroundColor: palette.white,
    borderRadius: 10,
    width: 110,
    textTransform: "none",
    margin: 5,
    display: "flex",
    flexDirection: "row",
  },
  selectedTextStyle: {
    fontSize: 16,
    fontFamily: "Gilroy-Semibold",
    fontWeight: "500",
    color: palette.blue.lighter,
    flex: 1,
  },
  unselectedTextStyle: {
    fontSize: 16,
    fontFamily: "Gilroy-Semibold",
    fontWeight: "500",
    color: palette.gray.dark,
    flex: 1,
  },
  checkedContainer: {
    width: 16,
    height: 16,
    borderRadius: 8,
    backgroundColor: palette.blue.lighter,
    border: `1px solid ${palette.blue.lighter}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  uncheckedContainer: {
    width: 16,
    height: 16,
    borderRadius: 8,
    backgroundColor: palette.white,
    border: `1px solid ${palette.white}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonStyle: {
    marginTop: 50,
    marginBottom: 20,
    width: 300,
    backgroundColor: palette.black,
    height: 50,
  },
};
