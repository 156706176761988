import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { DRAWER_WIDTH, LEFT_BAR_WIDTH } from "../../constants";
import { DrawerHeader } from "../DrawerHeader/DrawerHeader";
import { Divider } from "elements/divider/Divider";
import { Calendar } from "components/calendar/Calendar";
import { palette } from "assets/palette";
import { i18n } from "constants/i18n.constants";
import { Text } from "elements/text/Text";
import {
  AllBoardDataEntityType,
  IBoard,
  SecondDrawerType,
} from "interfaces/main.interfaces";
import { DateTime } from "luxon";
import { LeftSettingsBar } from "../LeftSettignsBar/LeftSettingsBar";
import { styled } from "@mui/material/styles";
import { CompletedTasks } from "./CompletedTasks/CompletedTasks";
import { CalendarEntity } from "communicators/resources/calendars/calendars.type";
import { ProgressBar } from "elements/progress-bar/ProgressBar";
import { CustomButton } from "elements/button/Button";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { InboxTasks } from "./InboxTasks/InboxTasks";
import { CustomTooltip } from "components/custom-tooltip/CustomTooltip";
import { SearchDialog } from "components/search-dialog/SearchDialog";
import { resizeByResolutionWidth } from "helpers/utils.helper";
import { useDrawerLeftHook } from "./useDrawerLeftHook";
import { SomedayTasks } from "./SomedayTasks/SomedayTasks";
import { Asana } from "./Asana/Asana";
import { Todoist } from "./Todoist/Todoist";
import { GoogleCalendar } from "./GoogleCalendar/GoogleCalendar";
import { TaskEntity } from "communicators/resources/event-resources/tasks/tasks.type";
import { Tags } from "./Tags/Tags";
import { Spinner } from "elements/spinner/Spinner";
import "./DrawerLeft.scss";

interface Props {
  open?: boolean;
  boardData: IBoard[];
  onSelectDate: (date: DateTime) => void;
  calendars?: CalendarEntity[];
  isSearchDialogOpen?: boolean;
  openSearchDialog?: () => void;
  closeSearchDialog?: () => void;
  onPressBoardPlus: (date: DateTime) => void;
  onTaskCreate: (
    index: number,
    boardDate: DateTime,
    createdTask: TaskEntity,
    isNewTask: boolean
  ) => void;
  inboxVisibleData: IBoard[];
  onPressSearchItem: (data: AllBoardDataEntityType) => void;
  leftDrawerSearchedDate: DateTime;
  previousMonth: () => void;
  nextMonth: () => void;
  inboxStartDate: DateTime;
  inboxLoading: boolean;
  onClickCheckBox: (data: AllBoardDataEntityType) => void;
  deleteTask?: (data: AllBoardDataEntityType) => void;
  somedayLoading: boolean;
  somedayData: IBoard[];
  onPressSomedayPlus: () => void;
  onTaskCreateSomeday?: (
    index: number,
    boardDate: DateTime,
    createdTask: TaskEntity,
    isNewTask: boolean
  ) => void;
  deleteSomedayTask: (data: AllBoardDataEntityType) => void;
  onClickCheckBoxSomeday: (data: AllBoardDataEntityType) => void;
  changeCalendarVisibility: (
    calendar_id?: string,
    isVisible?: boolean
  ) => Promise<false | undefined>;
  isCalendarsLoading?: boolean;
}

export const DrawerLeft = ({
  open,
  boardData,
  onSelectDate,
  calendars,
  isSearchDialogOpen,
  openSearchDialog,
  closeSearchDialog,
  onPressBoardPlus,
  onTaskCreate,
  inboxVisibleData,
  onPressSearchItem,
  leftDrawerSearchedDate,
  previousMonth,
  nextMonth,
  inboxStartDate,
  inboxLoading,
  onClickCheckBox,
  deleteTask,
  somedayData,
  somedayLoading,
  onPressSomedayPlus,
  onTaskCreateSomeday,
  deleteSomedayTask,
  onClickCheckBoxSomeday,
  changeCalendarVisibility,
  isCalendarsLoading,
}: Props) => {
  const {
    secondDrawerOpened,
    calendarHoveredDates,
    progressValue,
    remainingDay,
    openSecondDrawer,
    closeSecondDrawer,
    error,
    isPremium,
    onPressUpgradeNow,
    selectedIndex,
    setSelectedIndex,
  } = useDrawerLeftHook({
    boardData,
  });

  return (
    <Drawer sx={drawerSX} variant="persistent" anchor="left" open={open}>
      <div style={styles.wrapper}>
        <LeftSettingsBar
          openSecondDrawer={openSecondDrawer}
          secondDrawerOpenStatus={secondDrawerOpened}
        />
        <div style={styles.bodyWrapper}>
          <DrawerHeader style={styles.drawerHeader}>
            <div style={styles.headerButtonStyle}>
              <Text style={styles.headerTextStyle}>{i18n.t("app_name")}</Text>
              <CustomTooltip description={i18n.t("search")} direction="bottom">
                <div style={{ display: "flex" }}>
                  <Icon
                    name={IconName.SEARCH}
                    width={resizeByResolutionWidth(23)}
                    height={resizeByResolutionWidth(23)}
                    onPress={openSearchDialog}
                  />
                </div>
              </CustomTooltip>
            </div>
          </DrawerHeader>
          <Divider />
          <div
            style={
              error
                ? styles.nontrialScrollContainer
                : styles.trialScrollContainer
            }
            className="Windows"
            id="calendar"
          >
            <Calendar
              onSelectDateCallback={onSelectDate}
              hoveredDates={calendarHoveredDates}
            />
            <div className="tag-calendar-container">
              {process.env.REACT_APP_ENVIRONMENT === "staging" ? (
                <Tags />
              ) : null}
              {calendars?.length !== 0 ? (
                <div className="calendar-list-container">
                  <div style={styles.calendarHeaderContainer}>
                    <Text style={styles.calendar}>{i18n.t("calendars")}</Text>
                  </div>
                  {calendars?.map((item, index) => {
                    return (
                      <div style={styles.calendarsContainer} key={item.id}>
                        <CalendarColorItem
                          color={item.color}
                          isChecked={item.visible}
                          disabled={isCalendarsLoading}
                          onClick={() => {
                            setSelectedIndex(index);
                            changeCalendarVisibility(item.id, item.visible);
                          }}
                        >
                          {item.visible &&
                          !(isCalendarsLoading && selectedIndex === index) ? (
                            <Icon name={IconName.TICK} />
                          ) : null}
                          {isCalendarsLoading && selectedIndex === index ? (
                            <Spinner
                              size={10}
                              color={"info"}
                              loading={isCalendarsLoading}
                            />
                          ) : null}
                        </CalendarColorItem>
                        <Text style={styles.calendarItemText}>{item.name}</Text>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>
          {!error ? (
            <div
              style={
                !isPremium
                  ? styles.progressContainer
                  : styles.progressContainerWithPremium
              }
            >
              {!isPremium && (
                <div style={styles.progressBarMainContainer}>
                  <div style={styles.textContainer}>
                    <Text style={styles.trial}>{i18n.t("trial")}</Text>
                    <Text style={styles.daysLeft}>
                      {i18n.t("days_left", { days: remainingDay })}
                    </Text>
                  </div>
                  <ProgressBar
                    style={styles.progress}
                    value={progressValue}
                    progressProps={{ color: "info" }}
                  />
                </div>
              )}
              {!isPremium && (
                <CustomButton
                  style={styles.buttonStyle}
                  text={i18n.t("upgrade_now")}
                  onPress={onPressUpgradeNow}
                />
              )}
            </div>
          ) : null}
        </div>
      </div>
      <SecondDrawer
        open={secondDrawerOpened.opened && open}
        className="Windows"
      >
        {secondDrawerOpened.type === SecondDrawerType.COMPLETED_TASKS ? (
          <CompletedTasks
            closeDrawer={closeSecondDrawer}
            searchedDate={leftDrawerSearchedDate}
            onClickCheckBox={onClickCheckBox}
          />
        ) : null}
        {secondDrawerOpened.type === SecondDrawerType.INBOX_TASKS ? (
          <InboxTasks
            closeDrawer={closeSecondDrawer}
            onPressPlus={onPressBoardPlus}
            onTaskCreate={onTaskCreate}
            inboxVisibleData={inboxVisibleData}
            previousMonth={previousMonth}
            nextMonth={nextMonth}
            inboxStartDate={inboxStartDate}
            inboxLoading={inboxLoading}
            onClickCheckBox={onClickCheckBox}
            deleteTask={deleteTask}
          />
        ) : null}
        {secondDrawerOpened.type === SecondDrawerType.SOMEDAY_TASKS ? (
          <SomedayTasks
            onPressSomedayPlus={onPressSomedayPlus}
            closeDrawer={closeSecondDrawer}
            somedayLoading={somedayLoading}
            somedayData={somedayData}
            onTaskCreateSomeday={onTaskCreateSomeday}
            deleteSomedayTask={deleteSomedayTask}
            onClickCheckBoxSomeday={onClickCheckBoxSomeday}
          />
        ) : null}
        {secondDrawerOpened.type === SecondDrawerType.ASANA ? (
          <Asana closeDrawer={closeSecondDrawer} />
        ) : null}
        {secondDrawerOpened.type === SecondDrawerType.TODOIST ? (
          <Todoist closeDrawer={closeSecondDrawer} />
        ) : null}
        {secondDrawerOpened.type === SecondDrawerType.GOOGLE ? (
          <GoogleCalendar closeDrawer={closeSecondDrawer} />
        ) : null}
      </SecondDrawer>
      <SearchDialog
        open={isSearchDialogOpen}
        closeSearchDialog={closeSearchDialog}
        onPressSearchItem={onPressSearchItem}
      />
    </Drawer>
  );
};

const drawerSX = {
  width: DRAWER_WIDTH + LEFT_BAR_WIDTH,
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: DRAWER_WIDTH + LEFT_BAR_WIDTH,
    boxSizing: "border-box",
  },
};

const SecondDrawer = styled("div")<{
  open?: boolean;
}>(({ theme, open }) => ({
  height: "calc(100% - 68px)",
  flexGrow: 1,
  backgroundColor: palette.white,
  paddingRight: theme.spacing(2),
  paddingLeft: 15,
  top: 68,
  position: "absolute",
  transition: theme.transitions.create(["left"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  left: `-${DRAWER_WIDTH + 10}px`,
  ...(open && {
    transition: theme.transitions.create(["left"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    left: `${LEFT_BAR_WIDTH - 10}px`,
    zIndex: 5,
    overflowY: "scroll",
    width: "calc(100% - 61px)",
  }),
}));

const CalendarColorItem = styled("button")<{
  color?: string;
  isChecked?: boolean;
  isLoading?: boolean;
}>(({ color, isChecked, isLoading }) => ({
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: 19,
  height: 19,
  borderRadius: 3,
  border: "none",
  outline: "1px solid",
  outlineColor: color,
  backgroundColor: isChecked ? color : palette.white,
  cursor: "pointer",
  pointerEvents: isLoading ? "none" : "all",
}));

const styles: Record<string, React.CSSProperties> = {
  headerButtonStyle: {
    display: "flex",
    flex: 1,
    backgroundColor: palette.white,
    paddingLeft: resizeByResolutionWidth(20),
    alignItems: "center",
    borderWidth: 0,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headerTextStyle: {
    fontWeight: "500",
    fontSize: 22,
    marginRight: 5,
    fontFamily: "Gilroy-Medium",
    display: "flex",
    flex: 1,
  },
  drawerHeader: {},
  bodyWrapper: {},
  wrapper: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
  },
  calendarHeaderContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: resizeByResolutionWidth(40),
    marginTop: resizeByResolutionWidth(10),
  },
  calendars: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: resizeByResolutionWidth(20),
    color: palette.black,
  },
  calendarsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: resizeByResolutionWidth(40),
    marginTop: resizeByResolutionWidth(30),
  },
  calendarItemText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 15,
    color: palette.gray.gray4,
    marginLeft: resizeByResolutionWidth(20),
  },
  progressContainer: {
    bottom: resizeByResolutionWidth(10),
  },
  progressContainerWithPremium: {
    bottom: resizeByResolutionWidth(10),
  },
  trial: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: resizeByResolutionWidth(20),
    color: palette.gray.dark,
  },
  daysLeft: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: resizeByResolutionWidth(20),
    color: palette.gray.medium,
    marginRight: 5,
  },
  buttonStyle: {
    marginLeft: 40,
    width: DRAWER_WIDTH - 75,
    backgroundColor: palette.black,
    height: resizeByResolutionWidth(50),
  },
  progress: {
    marginTop: 5,
    marginBottom: resizeByResolutionWidth(30),
    marginLeft: resizeByResolutionWidth(40),
    width: DRAWER_WIDTH - resizeByResolutionWidth(75),
  },
  textContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 15,
    marginLeft: 40,
  },
  nontrialScrollContainer: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "stretch",
    height: 600,
  },
  trialScrollContainer: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "stretch",
    height: 500,
  },
  emptyCheckbox: {
    width: 26,
    height: 26,
    borderRadius: 13,
    color: palette.gray.ice,
    marginRight: 10,
    borderWidth: 0,
  },
  checkbox: {
    width: 26,
    height: 26,
    borderRadius: 13,
    color: palette.orange.light,
    marginRight: 10,
    borderWidth: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  progressBarMainContainer: {
    marginTop: 25,
  },
};
