import { TagsDispatchTypes } from './../types/dispatch.type';
import { SetSelectedTagsAction, SetTagsAction } from './../types/tags.action.type';
import { Tag } from "pages/private/home/components/DrawerLeft/Tags/useTagsHook";
import { Dispatch } from "redux";

export const setTagsData = (tags: Tag[]) => {
  return (dispatch: Dispatch): void => {
    dispatch<SetTagsAction>({
      type: TagsDispatchTypes.setTagsData,
      payload: tags,
    });
  };
};

export const setSelectedTagsData = (selectedTags: string[]) => {
  return (dispatch: Dispatch): void => {
    dispatch<SetSelectedTagsAction>({
      type: TagsDispatchTypes.setSelectedTagsData,
      payload: selectedTags,
    });
  };
};