import React from "react";
import { palette } from "assets/palette";
import { Text } from "elements/text/Text";
import { CustomButton } from "elements/button/Button";
import { i18n } from "constants/i18n.constants";
import Dialog from "@mui/material/Dialog";

export interface ButtonProps {
	buttonTitle: string;
	onPress: () => void;
	loading?: boolean;
	defaultSelection?: boolean;
  spinnerColor?: "inherit" | "error" | "primary" | "success" | "warning" | "secondary" | "info" | undefined;
}

interface Props {
  open: boolean;
  image?: string;
  buttonProps?: ButtonProps | ButtonProps[];
  title?: string;
}

export const PermissionModal = ({
  open,
  image,
  buttonProps,
  title
}: Props) => {
  const renderItem = (data: ButtonProps) => {
    return (
      <>
        <CustomButton
          onPress={data.onPress}
          loading={data.loading}
          fullWidth
          text={data.buttonTitle}
          backgroundColor={data.defaultSelection ? palette.black : palette.gray.light}
          textStyle={data.defaultSelection ? styles.defaultButton : styles.button}
          spinnerColor={data.spinnerColor}
        />
        <div style={styles.gap}/>
      </>
    );
  };
  return (
    <Dialog fullWidth maxWidth={"md"} open={open}> 
      <section style={styles.modalContainer}>
        <img
          style={styles.image}
          src={image}
          alt="logo"
        />
        <Text style={styles.title}>{title || i18n.t("we_need_your_permission_header")}</Text>
        <Text style={styles.subtitle}>{i18n.t("we_need_your_permission_subheader")}</Text>
        <div style={styles.buttonContainer}>
          {buttonProps
          ? Array.isArray(buttonProps)
            ? buttonProps.map(renderItem)
            : renderItem(buttonProps)
          : null}
        </div>
      </section>
    </Dialog>
  );
};

const styles: Record<string, React.CSSProperties> = {
  image: {
    width: 55,
    height: 58,
  },
  gap: {
    width: 15
  },
  modalContainer: {
    position: "fixed",
    width: "50%",
    height: "auto",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: palette.white,
    borderRadius: 12,
    padding: 70
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 60,
    width: "75%"
  },
  title: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 30,
    color: palette.gray.dark,
    textAlign: "left",
    marginTop: 40,
    marginBottom: 20
  },
  subtitle: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 25,
    color: palette.gray.medium,
    textAlign: "left",
    marginBottom: 60,
    width: "75%"
  },
  defaultButton: {
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "600",
    fontSize: 20,
    color: palette.white,
  },
  button: {
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "600",
    fontSize: 20,
    color: palette.black,
  }
};
