import { i18n } from "constants/i18n.constants";
import { CustomButton } from "elements/button/Button";
import { IconName } from "elements/icon/Icon.type";
import { CustomTextField } from "elements/text-field/TextField";
import { useEmailLoginHook } from "./useEmailLoginHook";

export const EmailLogin = () => {
  const {
    email,
    password,
    passwordHidden,
    loading,
    passwordErrorMessage,
    emailErrorMessage,
    setEmail,
    setPassword,
    onPressSubmit,
    onPreePasswordIcon,
  } = useEmailLoginHook();
  return (
    <>
      <CustomTextField
        label={i18n.t("email")}
        placeholder={i18n.t("enter_email")}
        value={email}
        autoComplete="email"
        onChangeText={setEmail}
        errorText={emailErrorMessage}
        classProp="custom-style"
      />
      <CustomTextField
        label={i18n.t("password")}
        placeholder={i18n.t("enter_password")}
        value={password}
        onChangeText={setPassword}
        rightIconProps={{
          name: passwordHidden
            ? IconName.PASSWORD_HIDDEN
            : IconName.PASSWORD_VISIBLE,
          onPress: onPreePasswordIcon,
          width: 34,
        }}
        type={passwordHidden ? "password" : "text"}
        errorText={passwordErrorMessage}
        classProp="custom-style"
      />
      <CustomButton
        onPress={onPressSubmit}
        loading={loading}
        fullWidth
        text={i18n.t("lets_start")}
      />
    </>
  );
};
