export const palette = {
  white: "#ffffff",
  beige: "#efe9d6",
  black: "#000000",
  black3: "#212123",
  purple: {
    lighter: "#f0f2fd",
    light: "#e3e6fc",
    medium: "#7b8cf3",
    dark: "#7378b7",
    purple50Percent: "#e3e6fc",
    darkPurple50Percent: "#bcc6ff",
    border: "#D7DBF3",
    linearDark: "#354492",
    linearLight: "#7B8EF5",
  },
  orange: {
    light: "#f5d2ae",
    medium: "#ff9264",
    dark: "#e1652c",
  },
  pink: {
    pink: "#fde3d8",
    pink50Percent: "#fde3d8",
    premium: "#f58dc6",
  },
  cream: {
    cream: "#f6f3e8",
    dark: "#f0ebd7",
  },
  vanilla: "#f6f3e8",
  gray: {
    light: "#f1f2f5",
    mediumLight: "#c4c4c4",
    medium: "#979797",
    dark: "#5a5a5a",
    ice: "#e3e6ef",
    iceLight: "#f6f7fa",
    gray4: "#BDBDBD",
    gray1: "#333333",
    400: "#d5d5d5",
    700: "#aaaaaa",
    extraLight: "#828282",
    streak: "#d9d9d9",
  },
  yellow: {
    light: "#fff2cb",
    dark: "#ffbf43",
  },
  blue: {
    light: "#b6d0ff",
    medium: "#6475cf",
    dark: "#4158d7",
    lighter: "#56CCF2",
  },
  red: {
    light: "#ffa4ad",
    normal: "#ff0000",
    dark: "#eb4354",
  },
  green: {
    green: "#a6d485",
    extraLight: "#e3f8e5",
    light: "#c0e3dc",
    neon: "#b9fbdb",
    medium: "#28ba74",
    dark: "#336f61",
    green50Percent: "#c0e3dc",
    greenDark50Percent: "#336f6180",
    border: "#b1d6ce",
  },
  violet: {
    light: "#f6ebff",
    dark: "#ca94f5",
  },
};
