import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useOnboardingDndHook } from "./useOnboardingDndHook";
import { Main } from "./components/Main/Main";
import { OnboardingDndWrapper } from "./components/OnboardingDndWrapper/OnboardingDndWrapper";
import { UnsubscriptionDialog } from "../home/components/UnsubscriptionDialog/UnsubscriptionDialog";
import { useInboxHook } from "../home/useInboxHook";
import { useSomedayHook } from "../home/useSomedayHook";
import { usePageHook } from "hooks/usePageHook";

interface Props {
  googleSignIn: any;
  googleAuthLoading: boolean;
}

export const OnboardingDnd = ({ googleSignIn, googleAuthLoading }: Props) => {
  const {
    boardData,
    loading,
    onDragEnd,
    onPressAllBoardPlus,
    onTaskCreate,
    onPressInboxBoardPlus,
    onInboxTaskCreate,
    inboxVisibleData,
    onPressNextDay,
    onPressPreviousDay,
    rightDrawerStartDate,
    updateTaskWithRightDrawerDrop,
    leftDrawerType,
    onClickCheckBox,
    deleteTask,
    allInsumoCalendarEventData,
    rightDrawerData,
    isRightDrawerLoading,
    refreshBoards,
    progressValue,
    goToDashboard,
    goBack,
    isUnsubscriptionOpened,
    handleClose,
    handleOpenUnsubscriptionDialog,
    allBoardData,
    setAllBoardData,
    startDate
  } = useOnboardingDndHook();
  usePageHook();
  const {
    inboxPreviousMonth,
    inboxNextMonth,
    inboxStartDate,
    inboxLoading,
  } = useInboxHook({startDate, allBoardData, setAllBoardData});

  const {
    sortedSomedayData,
    somedayLoading,
  } = useSomedayHook({allBoardData, setAllBoardData});
  return (
    <DndProvider backend={HTML5Backend}>
      <OnboardingDndWrapper
        boardData={boardData}
        rightDrawerBoardData={rightDrawerData}
        refreshBoards={refreshBoards}
        onPressBoardPlus={onPressInboxBoardPlus}
        onTaskCreate={onInboxTaskCreate}
        inboxVisibleData={inboxVisibleData}
        onPressNextDay={onPressNextDay}
        onPressPreviousDay={onPressPreviousDay}
        rightDrawerStartDate={rightDrawerStartDate}
        updateTaskWithRightDrawerDrop={updateTaskWithRightDrawerDrop}
        leftDrawerType={leftDrawerType}
        handleOpenUnsubscriptionDialog={handleOpenUnsubscriptionDialog}
        insumoCalendarEventData={allInsumoCalendarEventData}
        isLoading={isRightDrawerLoading}
        googleSignIn={googleSignIn}
        googleAuthLoading={googleAuthLoading}
        previousMonth={inboxPreviousMonth}
        nextMonth={inboxNextMonth}
        inboxStartDate={inboxStartDate}
        inboxLoading={inboxLoading}
        onClickCheckBox={onClickCheckBox}
        deleteTask={deleteTask}
        somedayData={sortedSomedayData}
        somedayLoading={somedayLoading}
      >
        <Main goBack={goBack} progressValue={progressValue} goToDashboard={goToDashboard} onDragEnd={onDragEnd} loading={loading} data={boardData} onPressBoardPlus={onPressAllBoardPlus} onTaskCreate={onTaskCreate} onClickCheckBox={onClickCheckBox} deleteTask={deleteTask} />
      </OnboardingDndWrapper>
      <UnsubscriptionDialog isOpened={isUnsubscriptionOpened} handleClose={handleClose} />
    </DndProvider>
  );
};
