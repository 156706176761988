import { TaskCandidate } from "communicators/resources/event-resources/tasks/tasks.type";
import { DateTime } from "luxon";
import { TasksResource } from "communicators/resources/event-resources/tasks/tasks.resource";
import { ONBOARDING_INTEGRATION_PAGE_COUNT } from "constants/onboarding.constants";
import { IBoard } from "interfaces/main.interfaces";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getInitialData } from "helpers/common.helper";

const PAGE_NUMBER = 2;

export const useAddFirstTaskDetail = () => {
  const location = useLocation();
  const [secondDrawerOpened, setSecondDrawerOpened] = useState<{
    type: string | null;
    opened: boolean;
  }>({
    type: null,
    opened: false,
  });

  const createdTaskTitle = location.state;

  const [todaysTasks, setTodaysTasks] = useState<IBoard>();
  const [isBoardPressed, setIsBoardPressed] = useState(false);

  const createdTask: TaskCandidate = {
    scheduled_time: DateTime.now().toISO({ includeOffset: true })!,
    due_date: DateTime.now().toISO({ includeOffset: true })!,
    title: createdTaskTitle,
    start_time: DateTime.now().toSeconds(),
    end_time: null,
    start_date: null,
    end_date: null,
    description: "",
    completed_count: 0,
  };

  const navigate = useNavigate();

  const closeSecondDrawer = () => {
    setSecondDrawerOpened({ type: null, opened: false });
  };

  const openSecondDrawer = (type: string) => {
    if (type === secondDrawerOpened.type) {
      closeSecondDrawer();
      return;
    }
    setSecondDrawerOpened({ type, opened: true });
  };

  const goBack = () => {
    navigate(-1);
  };

  const goToDndTutorial = () => {
    if (createdTaskTitle !== "") {
      new TasksResource().createOne(createdTask);
      navigate("/dnd-tutorial", { state: createdTask.title });
    }
  };

  const onPressBoard = () => {
    setIsBoardPressed(true);
  };

  useEffect(() => {
    getInitialData(setTodaysTasks);
  }, []);

  const progressValue = (PAGE_NUMBER / ONBOARDING_INTEGRATION_PAGE_COUNT) * 100;

  return {
    openSecondDrawer,
    secondDrawerOpened,
    progressValue,
    goBack,
    todaysTasks,
    goToDndTutorial,
    createdTask,
    isBoardPressed,
    onPressBoard,
  };
};
