import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import {
  DRAWER_WIDTH,
  HeaderButtonType,
  LEFT_BAR_WIDTH,
} from "../../constants";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { CustomButton } from "elements/button/Button";
import { Divider } from "elements/divider/Divider";
import { palette } from "assets/palette";
import { i18n } from "constants/i18n.constants";

interface AppBarProps extends MuiAppBarProps {
  leftDrawerOpen?: boolean;
  rightDrawerOpen?: boolean;
  isTransparent?: boolean;
}

interface Props {
  onPressLeftDrawerIcon: () => void;
  onPressRightDrawerIcon: () => void;
  leftDrawerOpen?: boolean;
  rightDrawerOpen?: boolean;
  selectedButton: HeaderButtonType;
  setSelectedButton: (type: HeaderButtonType) => void;
  isTransparent?: boolean;
}

export const AppBar = ({
  onPressLeftDrawerIcon,
  onPressRightDrawerIcon,
  setSelectedButton,
  leftDrawerOpen,
  rightDrawerOpen,
  selectedButton,
  isTransparent,
}: Props) => {
  const allButtonSelected = selectedButton === HeaderButtonType.ALL;
  const meetsButtonSelected = selectedButton === HeaderButtonType.MEETS;
  const tasksButtonSelected = selectedButton === HeaderButtonType.TASKS;

  return (
    <StyledAppBar
      position="fixed"
      elevation={0}
      leftDrawerOpen={leftDrawerOpen}
      rightDrawerOpen={rightDrawerOpen}
      isTransparent={isTransparent}
    >
      <Toolbar
        style={isTransparent ? styles.transparentToolbar : styles.toolbar}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={onPressLeftDrawerIcon}
          edge="start"
        >
          <Icon
            name={
              leftDrawerOpen
                ? IconName.DOUBLE_ARROW_LEFT
                : IconName.DOUBLE_ARROW_RIGHT
            }
            width={14}
            height={14}
          />
        </IconButton>
        <div style={styles.buttonContainer}>
          <CustomButton
            backgroundColor={palette.black}
            variant={allButtonSelected ? undefined : "text"}
            text={i18n.t("all")}
            style={styles.button}
            onPress={() => setSelectedButton(HeaderButtonType.ALL)}
          />
          <CustomButton
            backgroundColor={palette.black}
            variant={meetsButtonSelected ? undefined : "text"}
            text={i18n.t("meets")}
            style={styles.button}
            onPress={() => setSelectedButton(HeaderButtonType.MEETS)}
          />
          <CustomButton
            backgroundColor={palette.black}
            variant={tasksButtonSelected ? undefined : "text"}
            text={i18n.t("tasks")}
            style={styles.button}
            onPress={() => setSelectedButton(HeaderButtonType.TASKS)}
          />
        </div>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={onPressRightDrawerIcon}
          edge="end"
        >
          <Icon
            name={
              rightDrawerOpen
                ? IconName.DOUBLE_ARROW_RIGHT
                : IconName.DOUBLE_ARROW_LEFT
            }
            width={14}
            height={14}
          />
        </IconButton>
      </Toolbar>
      <Divider />
    </StyledAppBar>
  );
};

const StyledAppBar = styled(MuiAppBar)<AppBarProps>(
  ({ theme, leftDrawerOpen, rightDrawerOpen }) => ({
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(leftDrawerOpen && {
      width: `calc(100% - ${
        rightDrawerOpen ? DRAWER_WIDTH * 2 : DRAWER_WIDTH + LEFT_BAR_WIDTH
      }px)`,
      marginLeft: `${DRAWER_WIDTH}px`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
    background: palette.white ,
    ...(rightDrawerOpen && {
      width: `calc(100% - ${
        leftDrawerOpen ? DRAWER_WIDTH * 2 + LEFT_BAR_WIDTH : DRAWER_WIDTH
      }px)`,
      marginRight: `${DRAWER_WIDTH}px`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  })
);

const styles: Record<string, React.CSSProperties> = {
  buttonContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  toolbar: {
    backgroundColor: palette.white,
  },
  transparentToolbar: {
    background: "transparent",
  },
  button: {
    marginLeft: 30,
    marginRight: 30,
    width: 100,
    paddingTop: 4,
    paddingBottom: 4,
  },
};
