import { TaskTagsResource } from "./../../../../../communicators/resources/task-tags/task-tags.resource";
import { defaultScheduledDate } from "constants/default-scheduled-date.constant";
import { EventType } from "communicators/resources/event-resources/event-resources.type";
import {
  TaskEntity,
  TaskTags,
} from "communicators/resources/event-resources/tasks/tasks.type";
import {
  AllBoardDataEntityType,
  IBoard,
  ThirdPartyProvider,
} from "interfaces/main.interfaces";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { ITimelineItem } from "../DrawerRight/types";
import { i18n } from "constants/i18n.constants";
import { TasksResource } from "communicators/resources/event-resources/tasks/tasks.resource";
import { debounce } from "lodash";
import { MeetsResource } from "communicators/resources/event-resources/meets/meets.resource";
import { getTaskStartTime } from "helpers/common.helper";
import { useAppDispatch, useAppSelector } from "redux-store/store.hooks";
import {
  setPermissionModalType,
  setTagsModalVisible,
} from "redux-store/actions/home-page.actions";
import {
  CalendarAccountType,
  PermissionModalType,
} from "pages/private/home/useHomeHook";
import { Scope } from "communicators/resources/third-party-information/third-party-information.type";
import {
  findMissingObjects,
  getDateFormat,
  getRandomColor,
} from "helpers/utils.helper";
import { useUserSettingHook } from "hooks/useUserSettingHook";
import { InsumoCalendarEventsResource } from "communicators/resources/insumo-calendar-events/insumo-calendar-events.resource";
import { InsumoCalendarEvent } from "communicators/resources/insumo-calendar-events/insumo-calendar-events.type";
import {
  EventEmitter,
  EventEmitterType,
} from "constants/event-emitter.constant";
import { EventSubscription } from "fbemitter";
import { TagsResource } from "communicators/resources/tags/tags.resource";
import { TagEntity } from "communicators/resources/tags/tags.types";

interface Props {
  data: AllBoardDataEntityType;
  boardData?: IBoard;
  onTaskMeetAction?: () => void;
  refreshBoards?: () => void;
}

export const useTaskDetailHook = ({
  data,
  boardData,
  onTaskMeetAction,
  refreshBoards,
}: Props) => {
  const [snapIndex, setSnapIndex] = useState(36);
  const [updatedTask, setUpdatedTask] = useState(data);

  const { preferences, firebaseUuid } = useUserSettingHook();
  const { insumoCalendarEvent } = useAppSelector((state) => ({
    insumoCalendarEvent: state.homePage.insumoCalendarEvent,
  }));

  const getSelectedTime = () => {
    if (
      updatedTask.type === EventType.TASK &&
      preferences?.attributes.preferred_calendar === CalendarAccountType.INSUMO
    ) {
      const insumoCalendartimes: DateTime[] = [];
      insumoCalendarEvent.forEach((event) => {
        event.data.forEach((eventData) => {
          if (eventData.attributes.item_id === updatedTask.id) {
            insumoCalendartimes.push(
              DateTime.fromISO(eventData.attributes.start_date)
            );
          }
        });
      });
      return insumoCalendartimes;
    }
    if (updatedTask.type === EventType.TASK) {
      return getTaskStartTime(updatedTask, boardData);
    }
    return [];
  };
  const getScheduledDate = () => {
    if (updatedTask.type === EventType.TASK) {
      return getTaskScheduledDate(updatedTask);
    }
    return DateTime.now();
  };
  const getDueDate = () => {
    if (updatedTask.type === EventType.TASK) {
      return getTaskDueDate(updatedTask);
    }
    return DateTime.now();
  };

  const nearestDate = (dates: ITimelineItem[], target: DateTime) => {
    if (!target) {
      target = DateTime.now();
    }
    let nearest = Infinity;
    let result = -1;
    dates.forEach(function (date, index) {
      let distance = Math.abs(date.time.toMillis() - target.toMillis());
      if (distance < nearest) {
        nearest = distance;
        result = index;
      }
    });
    return result;
  };

  const determineHours = (): ITimelineItem[] => {
    let currentTime = DateTime.now().startOf("day");
    if (updatedTask.type === EventType.TASK) {
      updatedTask.attributes.scheduled_time
        ? (currentTime = DateTime.fromISO(
            updatedTask.attributes.scheduled_time
          ).startOf("day"))
        : (currentTime = DateTime.fromSQL(
            updatedTask.attributes.due_date
          ).startOf("day"));
    }
    const indexNumber = 24 * 4;
    const days = [];
    for (let i = 0; i < indexNumber; i++) {
      days.push({ time: currentTime });
      currentTime = currentTime.plus({ minutes: 15 });
    }
    return days;
  };

  const initialSelectedTimes = getSelectedTime();
  const [selectedTimes, setSelectedTimes] = useState<DateTime[]>(
    initialSelectedTimes ? initialSelectedTimes : []
  );
  const [scheduledDate, setScheduledDate] = useState<DateTime | string>(
    getScheduledDate()
  );
  const [dueDate, setDueDate] = useState<DateTime>(getDueDate());
  const [isTimeSelectionOpened, setIsTimeSelectionOpened] =
    useState<boolean>(false);
  const [isScheduledDateSelectionOpened, setIsScheduledDateSelectionOpened] =
    useState<boolean>(false);
  const [isDueDateSelectionOpened, setIsDueDateSelectionOpened] =
    useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteIconShown, setIsDeleteIconShown] = useState(false);
  const [is24h, setIs24h] = useState<boolean>(false);
  const [draggedTime, setDraggedTime] = useState("");
  const [updatedTaskId, setUpdatedTaskId] = useState("");
  const [isTagSearchShown, setIsTagSearchShown] = useState<boolean>(false);
  const [isTagSelectionOpened, setIsTagSelectionOpened] =
    useState<boolean>(false);
  const [isTagCreating, setIsTagCreating] = useState<boolean>(false);
  const [taskTags, setTaskTags] = useState<TaskTags[]>();
  const [allTags, setAllTags] = useState<TagEntity[]>([]);

  const getAllTagsData = async () => {
    const response = await new TagsResource().readMany();
    setAllTags(response.data);
  };

  const dispatch = useAppDispatch();

  const { thirdPartyData } = useAppSelector((state) => ({
    thirdPartyData: state.thirdPartyProviderData.thirdPartyData,
  }));

  const googleScopes = thirdPartyData?.filter(
    (item) => item.provider === ThirdPartyProvider.GOOGLE
  )[0]?.scopes;
  const todoistScopes = thirdPartyData?.filter(
    (item) => item.provider === ThirdPartyProvider.TODOIST
  )[0]?.scopes;

  const inputFormat = is24h ? "XX:XX" : "XX:XX XX";

  useEffect(() => {
    const timeFormat: string[] = ["am", "AM", "pm", "PM"];
    if (
      timeFormat.some((item) =>
        DateTime.now().toLocaleString(DateTime.TIME_SIMPLE).includes(item)
      )
    ) {
      setIs24h(false);
    } else {
      setIs24h(true);
    }
  }, []);

  const createNewMeet = async (time: DateTime) => {
    if (updatedTask.type !== EventType.TASK) {
      return;
    }
    if (
      preferences?.attributes.preferred_calendar === CalendarAccountType.INSUMO
    ) {
      await new InsumoCalendarEventsResource().createOne({
        insumo_calendar_event: {
          item_id: updatedTask.id,
          start_date: time.toISO(),
          end_date: time.plus({ minutes: 45 }).toISO(),
        },
        firebase_uuid: firebaseUuid,
      });
      setTimeout(() => {
        onTaskMeetAction && onTaskMeetAction();
      }, 500);
      return;
    }
    if (!googleScopes?.includes(Scope.WRITE)) {
      dispatch(setPermissionModalType(PermissionModalType.GOOGLE));
      return;
    }
    await new MeetsResource().createOne({
      meet: {
        summary: updatedTask.attributes.title,
        description: updatedTask.attributes.description,
        start_time: time.toUTC().toISO(),
        end_time: time.plus({ minutes: 45 }).toUTC().toISO(),
        task_id: updatedTask.id,
      },
    });
    setTimeout(() => {
      onTaskMeetAction && onTaskMeetAction();
    }, 500);
  };

  const deleteSelectedInsumoMeet = async (time: DateTime) => {
    let foundMeet: InsumoCalendarEvent | null = null;
    insumoCalendarEvent.forEach((event) => {
      event.data.forEach((eventData) => {
        if (
          eventData.attributes.item_id === updatedTask.id &&
          DateTime.fromISO(eventData.attributes.start_date).hour ===
            time.hour &&
          DateTime.fromISO(eventData.attributes.start_date).minute ===
            time.minute
        ) {
          foundMeet = eventData;
        }
      });
    });
    if (!foundMeet) return;
    await new InsumoCalendarEventsResource().removeOne(
      (foundMeet as InsumoCalendarEvent).id
    );
    setTimeout(() => {
      onTaskMeetAction && onTaskMeetAction();
    }, 500);
  };

  const deleteSelectedMeet = async (time: DateTime) => {
    if (
      updatedTask.type === EventType.TASK &&
      preferences?.attributes.preferred_calendar === CalendarAccountType.INSUMO
    ) {
      deleteSelectedInsumoMeet(time);
      return;
    }
    if (updatedTask.type !== EventType.TASK || !boardData) {
      return;
    }
    if (!googleScopes?.includes(Scope.WRITE)) {
      dispatch(setPermissionModalType(PermissionModalType.GOOGLE));
      return;
    }
    const foundMeet = boardData?.data.find((item) =>
      item.type === EventType.MEET && item.attributes.task_id !== null
        ? DateTime.fromISO(item.attributes.meet_start_time).hour ===
            time.hour &&
          DateTime.fromISO(item.attributes.meet_start_time).minute ===
            time.minute
        : false
    );
    if (!foundMeet) return;
    await new MeetsResource().removeOne(foundMeet.id);
    setTimeout(() => {
      onTaskMeetAction && onTaskMeetAction();
    }, 500);
  };

  const onPressItem = (index: number, time: DateTime) => {
    const newSelectedTimes = [...selectedTimes];
    const selectedTimeIndex = newSelectedTimes.findIndex(
      (item) => item.hour === time.hour && item.minute === time.minute
    );
    if (selectedTimeIndex >= 0) {
      newSelectedTimes.splice(selectedTimeIndex, 1);
      deleteSelectedMeet(time);
    } else {
      newSelectedTimes.push(time);
      createNewMeet(time);
    }
    setSelectedTimes(newSelectedTimes);
  };

  const startTimeToSeconds = (startTime?: string) => {
    if (is24h) {
      return (
        Number(startTime?.slice(0, 2)) * 3600 +
        Number(startTime?.slice(-2)) * 60
      );
    } else {
      const withoutTimeFormat = startTime?.slice(0, -2);
      if (startTime?.slice(-2).toLowerCase() === "pm") {
        return (
          (Number(withoutTimeFormat?.slice(0, 2)) + 12) * 3600 +
          Number(withoutTimeFormat?.slice(-2)) * 60
        );
      } else {
        return (
          Number(withoutTimeFormat?.slice(0, 2)) * 3600 +
          Number(withoutTimeFormat?.slice(-2)) * 60
        );
      }
    }
  };
  const setStartTimeWithDebounce = debounce((text: string) => {
    if (is24h) {
      if (text.length === 4) {
        // TODO: add am pm seperation
      }
    } else {
      const regex = /(AM|PM)$/i;
      if (text.length === 6 && regex.test(text)) {
        // TODO: add am pm seperation
      }
    }
  }, 500);

  const onStartTimeChange = (text: string) => {
    setStartTimeWithDebounce(text);
  };

  const hours = determineHours();

  useEffect(() => {
    let newSelectedTime = DateTime.now();
    if (selectedTimes && Array.isArray(selectedTimes)) {
      newSelectedTime = selectedTimes[0];
    } else if (typeof selectedTimes !== "string") {
      newSelectedTime = selectedTimes;
    }
    const selectedScrollIndex = nearestDate(hours, newSelectedTime);
    setSnapIndex(selectedScrollIndex);
  }, []);

  const onSelectScheduledDate = (date: DateTime | string) => {
    if (updatedTask.type !== EventType.TASK) return;
    const theScheduladDate = getScheduledDate();
    setScheduledDate(date);
    if (
      typeof theScheduladDate !== "string" &&
      typeof date !== "string" &&
      date.toSQLDate() === theScheduladDate.toSQLDate()
    ) {
      return;
    } else if (
      typeof theScheduladDate === "string" &&
      typeof date !== "string" &&
      theScheduladDate === date.toFormat(getDateFormat())
    ) {
      return;
    }
    updateScheduledDate(date);
  };

  const onSelectDueDate = (date: DateTime) => {
    if (
      typeof dueDate !== "string" &&
      date.toSQLDate() === dueDate.toSQLDate()
    ) {
      return;
    }
    setDueDate(date);
    updateDueDate(date);
  };

  const timeSelectionToggle = () => {
    if (!isOpen) {
      setIsTimeSelectionOpened(true);
      setIsScheduledDateSelectionOpened(false);
      setIsDueDateSelectionOpened(false);
      setIsTagSelectionOpened(false);
      setIsTagSearchShown(false);
      setIsOpen(true);
    }
    if (isOpen) {
      if (isTimeSelectionOpened) {
        setIsOpen(false);
      }
      setIsTimeSelectionOpened(!isTimeSelectionOpened);
      setIsScheduledDateSelectionOpened(false);
      setIsDueDateSelectionOpened(false);
      setIsTagSelectionOpened(false);
      setIsTagSearchShown(false);
    }
  };

  const dateSelectionToggle = () => {
    if (!isOpen) {
      setIsScheduledDateSelectionOpened(true);
      setIsTimeSelectionOpened(false);
      setIsDueDateSelectionOpened(false);
      setIsTagSelectionOpened(false);
      setIsTagSearchShown(false);
      setIsOpen(true);
    }
    if (isOpen) {
      if (isScheduledDateSelectionOpened || isDueDateSelectionOpened) {
        setIsOpen(false);
      }
      setIsScheduledDateSelectionOpened(true);
      setIsTimeSelectionOpened(false);
      setIsDueDateSelectionOpened(false);
      setIsTagSelectionOpened(false);
      setIsTagSearchShown(false);
    }
  };

  const openTimeSelector = () => {
    setIsTimeSelectionOpened(true);
    setIsDueDateSelectionOpened(false);
    setIsScheduledDateSelectionOpened(false);
    setIsTagSelectionOpened(false);
    setIsTagSearchShown(false);
  };

  const closeSelectionToggles = () => {
    setIsDueDateSelectionOpened(false);
    setIsScheduledDateSelectionOpened(false);
    setIsTimeSelectionOpened(false);
    setIsTagSelectionOpened(false);
    setIsTagSearchShown(false);
    setIsOpen(false);
    setIsDeleteIconShown(false);
  };

  const onPressDone = () => {
    refreshBoards && refreshBoards();
    closeSelectionToggles();
  };

  const openScheduledDateSelector = () => {
    setIsTimeSelectionOpened(false);
    setIsDueDateSelectionOpened(false);
    setIsScheduledDateSelectionOpened(true);
    setIsTagSelectionOpened(false);
    setIsTagSearchShown(false);
  };

  const openDueDateSelector = () => {
    setIsTimeSelectionOpened(false);
    setIsDueDateSelectionOpened(true);
    setIsScheduledDateSelectionOpened(false);
    setIsTagSelectionOpened(false);
    setIsTagSearchShown(false);
  };

  const openTagSelector = () => {
    setIsTimeSelectionOpened(false);
    setIsDueDateSelectionOpened(false);
    setIsScheduledDateSelectionOpened(false);
    setIsTagSelectionOpened(true);
  };

  const onPressTrashIcon = () => {
    setIsDeleteIconShown(!isDeleteIconShown);
  };

  const onClickTagPlus = () => {
    openTagSelector();
    toggleTagSelection();
  };

  const onClickAddTag = async (tagTitle: string) => {
    if (updatedTask.type !== EventType.TASK) return;
    setIsTagCreating(true);
    const color = getRandomColor();
    const createdTag = await new TagsResource().createOne({
      tag: { name: tagTitle, color: color },
    });
    await new TaskTagsResource().createOne({
      task_tag: {
        tag_id: createdTag.data.id,
        task_id: updatedTask.id,
      },
    });
    const task = await new TasksResource().readOne(updatedTask.id);
    setTaskTags(task.data.attributes.task_tags);
    setIsTagCreating(false);
  };

  const filteredTags = allTags.filter((item) => {
    return item?.attributes?.favorite;
  });

  const favoriteTags = findMissingObjects(filteredTags, "id", taskTags);

  const openManageTagsModal = () => {
    dispatch(setTagsModalVisible(true));
  };

  const onClickFavoriteTag = async (tag_id: string) => {
    await new TaskTagsResource().createOne({
      task_tag: {
        tag_id: tag_id,
        task_id: updatedTask.id,
      },
    });
    const task = await new TasksResource().readOne(updatedTask.id);
    setTaskTags(task.data.attributes.task_tags);
  };

  const onClickTaskTag = async (tag_id: string) => {
    await new TaskTagsResource().removeOne(tag_id);
    const task = await new TasksResource().readOne(updatedTask.id);
    setTaskTags(task.data.attributes.task_tags);
  };

  const onClickTagSearchItem = async (tag_id: string) => {
    const isTagPresent = taskTags?.some((tag) => tag.tag.id === tag_id);
    if (isTagPresent) return;
    if (!isTagPresent) {
      await new TaskTagsResource().createOne({
        task_tag: {
          tag_id: tag_id,
          task_id: updatedTask.id,
        },
      });
    }
    const task = await new TasksResource().readOne(updatedTask.id);
    setTaskTags(task.data.attributes.task_tags);
  }

  useEffect(() => {
    if (data.type === EventType.TASK) {
      setTaskTags(data.attributes?.task_tags);
    }
    getAllTagsData();
  }, []);

  useEffect(() => {
    if (isOpen === false) {
      setIsDeleteIconShown(false);
    } else if (isOpen && updatedTask.type === EventType.MEET) {
      setIsOpen(false);
    }
  }, [isOpen, updatedTask.id]);

  const updateScheduledDate = (date: DateTime | string) => {
    if (!data.id || data.type !== EventType.TASK) return;
    if (
      data?.attributes.provider === ThirdPartyProvider.TODOIST &&
      !todoistScopes?.includes(Scope.WRITE)
    ) {
      dispatch(setPermissionModalType(PermissionModalType.TODOIST));
      return;
    }
    const taskID = data.id;
    const scheduledDate = typeof date !== "string" ? date?.toSQLDate()! : date;
    new TasksResource().updateOnePartial(taskID, {
      scheduled_time: scheduledDate!,
    });
  };
  const updateDueDate = (date: DateTime | string) => {
    if (!data.id || data.type !== EventType.TASK) return;
    if (
      data?.attributes?.provider === ThirdPartyProvider.TODOIST &&
      !todoistScopes?.includes(Scope.WRITE)
    ) {
      dispatch(setPermissionModalType(PermissionModalType.TODOIST));
      return;
    }
    const taskID = data.id;
    const dueDate = typeof date !== "string" ? date.toISO()! : date;
    new TasksResource().updateOnePartial(taskID, { due_date: dueDate });
  };

  const onCreateNewMeetForTask = (emittedData: {
    taskId: string;
    time: DateTime;
  }) => {
    const { taskId, time } = emittedData;
    if (updatedTask.type === EventType.TASK && updatedTask.id === taskId) {
      const newSelectedTimes = [...selectedTimes];
      const selectedTimeIndex = newSelectedTimes.findIndex(
        (item) => item.hour === time.hour && item.minute === time.minute
      );
      if (selectedTimeIndex >= 0) {
        return;
      } else {
        newSelectedTimes.push(time);
      }
      setSelectedTimes(newSelectedTimes);
    }
  };

  const toggleTagSelection = () => {
    setIsTagSearchShown(!isTagSearchShown);
  };

  useEffect(() => {
    if (
      draggedTime !== "" &&
      updatedTask.type === EventType.TASK &&
      updatedTask.id === updatedTaskId
    ) {
      let newData: AllBoardDataEntityType;
      newData = {
        ...updatedTask,
        attributes: {
          ...updatedTask?.attributes,
          scheduled_time: draggedTime,
        },
      };
      setUpdatedTask(newData);
      setScheduledDate(DateTime.fromISO(draggedTime));
    }
  }, [draggedTime]);

  useEffect(() => {
    getScheduledDate();
    getDueDate();
    getSelectedTime();
  }, [updatedTask]);

  useEffect(() => {
    let listener: EventSubscription | null = null;
    if (updatedTask.type === EventType.TASK) {
      listener = EventEmitter.addListener(
        EventEmitterType.ON_CREATE_NEW_MEET_FROM_TASK,
        onCreateNewMeetForTask
      );
    }
    const newListener: EventSubscription = EventEmitter.addListener(
      EventEmitterType.ON_TASK_DRAGGED,
      setDraggedTime
    );
    const newListeners: EventSubscription = EventEmitter.addListener(
      EventEmitterType.ON_TASK,
      setUpdatedTaskId
    );

    return () => {
      listener?.remove();
      newListener?.remove();
      newListeners?.remove();
    };
  }, []);
  return {
    hours,
    selectedTimes,
    onPressItem,
    onSelectScheduledDate,
    scheduledDate,
    onSelectDueDate,
    dueDate,
    timeSelectionToggle,
    isTimeSelectionOpened,
    isScheduledDateSelectionOpened,
    isDueDateSelectionOpened,
    dateSelectionToggle,
    openTimeSelector,
    openDueDateSelector,
    openScheduledDateSelector,
    isOpen,
    closeSelectionToggles,
    isDeleteIconShown,
    onPressTrashIcon,
    snapIndex,
    setIsOpen,
    onStartTimeChange,
    inputFormat,
    onPressDone,
    isTagSearchShown,
    openTagSelector,
    isTagSelectionOpened,
    onClickTagPlus,
    onClickAddTag,
    isTagCreating,
    taskTags,
    favoriteTags,
    openManageTagsModal,
    onClickFavoriteTag,
    onClickTaskTag,
    onClickTagSearchItem
  };
};

const getTaskScheduledDate = (taskTarget: TaskEntity) => {
  const format = getDateFormat();
  if (
    taskTarget.attributes.scheduled_time &&
    DateTime.fromISO(taskTarget.attributes.scheduled_time).toFormat(format) !==
      defaultScheduledDate
  ) {
    return DateTime.fromISO(taskTarget.attributes.scheduled_time).toFormat(
      format
    );
  } else if (
    taskTarget.attributes.scheduled_time &&
    DateTime.fromISO(taskTarget.attributes.scheduled_time).toFormat(format) ===
      defaultScheduledDate
  ) {
    return `${i18n.t("someday")}`;
  } else {
    return DateTime.now();
  }
};

const getTaskDueDate = (taskTarget: TaskEntity) => {
  if (
    taskTarget.attributes.due_date &&
    DateTime.fromISO(taskTarget.attributes.due_date).toFormat("MM/dd/yyyy") !==
      defaultScheduledDate
  ) {
    return DateTime.fromISO(taskTarget.attributes.due_date);
  } else if (
    taskTarget.attributes.due_date &&
    DateTime.fromISO(taskTarget.attributes.due_date).toFormat("MM/dd/yyyy") ===
      defaultScheduledDate
  ) {
    return DateTime.utc(3000, 1, 1, 0, 0, 0, 0);
  } else {
    return DateTime.now();
  }
};
