import React from "react";
import { DateTime } from "luxon";
import { Calendar } from "components/calendar/Calendar";
import { i18n } from "constants/i18n.constants";
import { AllBoardDataEntityType } from "interfaces/main.interfaces";
import "./ScheduledDateSelection.scss";

interface Props {
  onSelectScheduledDate: (date: string | DateTime) => void;
  isSomeday?: boolean;
  selectedDate?: DateTime
  onSelectSomeday?: (date: DateTime, data?: AllBoardDataEntityType) => void;
}

export const ScheduledDateSelection = ({
  onSelectScheduledDate,
  isSomeday = false,
  selectedDate,
  onSelectSomeday
}: Props) => {
  const somedaySelection = () => {
    onSelectScheduledDate(DateTime.utc(3000, 1, 1, 0, 0, 0, 0));
    onSelectSomeday?.(DateTime.utc(3000, 1, 1, 0, 0, 0, 0));
  };
  const foundSelectedDate = isSomeday ? undefined : selectedDate;
  return (
    <div style={styles.container}>
      <Calendar selectedDate={foundSelectedDate} onSelectDateCallback={onSelectScheduledDate} />
      <button className={isSomeday ? "selected-someday-button" : "unselected-someday-button"} onClick={somedaySelection}>
        {i18n.t("someday")}
      </button>
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  container: {
    marginLeft: -10,
  },
};
