import { EventType } from "communicators/resources/event-resources/event-resources.type";
import { i18n } from "constants/i18n.constants";
import { getImageOrIcon } from "helpers/common.helper";
import {
  getTaskDate,
  getMeetDate,
  getMeetTimeRange,
  getTaskTimeRange,
} from "helpers/data.helper";
import {
  AllBoardDataEntityType,
} from "interfaces/main.interfaces";
import { DateTime } from "luxon";

interface Props {
  data: AllBoardDataEntityType;
}

export type SearchItemLeftElement = {
  type: string;
  source: string;
  text: string;
}

export const useSearcDialogItemHook = ({ data }: Props) => {
  const getTitle = () => {
    switch (data?.type) {
      case EventType.HABIT_TARGET:
        return data.attributes.title;
      case EventType.MEET:
        return data.attributes.meet_summary;
      case EventType.TASK:
        if (data.attributes.title.includes("http")) {
          const title = data.attributes.title;
          return title.substring(
            title.indexOf("[") + 1,
            title.lastIndexOf("]")
          );
        }
        return data.attributes.title;

      default:
        return "";
    }
  };

  const getDateToString = () => {
    let date;
    if (data.type === EventType.MEET && data.attributes.meet_start_time) {
      date = getMeetDate(data);
    } else if (
      data.type === EventType.TASK &&
      (data.attributes.scheduled_time || data.attributes.due_date)
    ) {
      date = getTaskDate(data);
    }
    if (!date) return i18n.t("no_date");
    const now = DateTime.now();
    const diffDays = date.diff(now, ["days"]);
    if (diffDays.days === 0) {
      return i18n.t("today");
    } else if (diffDays.days < 0) {
      if (now.year !== date.year) {
        date.toFormat("LLLL dd, yyyy");
      }
      return date.toFormat("LLLL dd");
    } else if (diffDays.days < 7) {
      return i18n.t("days_ago", { day: diffDays.days.toFixed(0) });
    }
    if (now.year !== date.year) {
      date.toFormat("LLLL dd, yyyy");
    }
    return date.toFormat("LLLL dd");
  };

  const getTime = () => {
    if (data?.type === EventType.MEET) {
      return getMeetTimeRange(data);
    }
    if (data?.type === EventType.TASK) {
      return getTaskTimeRange(data);
    }
    return i18n.t("no_time");
  };

  const leftElement: SearchItemLeftElement = getImageOrIcon(data);
  const title = getTitle();
  const date = getDateToString();
  const time = getTime();

  return { leftElement, title, date, time };
};
