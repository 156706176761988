import { AuthV2Resource } from "communicators/resources/authV2/authV2.resource";
import { SubscriptionsResource } from "communicators/resources/subscription/subscription.resource";
import { SubscriptionEntity } from "communicators/resources/subscription/subscription.type";
import { ProfileResource } from "communicators/resources/profile/profile.resource";
import { ProfileEntity } from "communicators/resources/profile/profile.type";
import { ResponseWithData } from "communicators/types";
import { LocalStorageParam } from "constants/local-storage.constant";
import { DateTime } from "luxon";
import { setUserInfo } from "redux-store/actions/user-settings.action";
import { store } from "redux-store/store";

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

const loginWithIdToken = async (idToken: string) => {
  await new AuthV2Resource().login(idToken);
};

export const createAndGetUser = async ({
  firebase_uuid,
  email,
  mobile_phone,
  full_name,
  idToken,
}: {
  firebase_uuid: string;
  idToken: string;
  email?: string;
  mobile_phone?: string;
  full_name?: string;
}): Promise<{ user?: ProfileEntity; isNewUser: boolean }> => {
  let user = null;
  localStorage.setItem(LocalStorageParam.FIREBASE_UUID, firebase_uuid);
  let isNewUser = true;

  try {
    const response: ResponseWithData<ProfileEntity> =
      await new ProfileResource().createOne({
        user: {
          firebase_uuid,
          email,
          mobile_phone,
          time_zone: timeZone,
          full_name,
          sign_up_token: idToken,
        },
      });
    await loginWithIdToken(idToken);
    user = response.data;
  } catch (err) {
    await loginWithIdToken(idToken);
    const dbUser = await new ProfileResource().readOne(firebase_uuid);
    isNewUser = false;
    let response: ResponseWithData<ProfileEntity> | null = null;

    if (dbUser.data) {
      const { email, mobile_phone } = dbUser.data.attributes;
      response = await new ProfileResource().updateAndReadOne(firebase_uuid, {
        user: {
          email: dbUser.data.attributes.email || email,
          mobile_phone: dbUser.data.attributes.mobile_phone || mobile_phone,
          time_zone: timeZone,
          full_name: dbUser.data.attributes.full_name || full_name,
          onboarding_completed: dbUser.data.attributes.onboarding_completed
        },
      });
    }
    user = response?.data;
  }
  if (user) {
    store.dispatch(setUserInfo(user));
  }
  return { user, isNewUser };
};


export const getUserRedirectionAfterLogin = async (
  isNewUser: boolean,
  user?: ProfileEntity
) => {
  if (!user) {
    return "/welcome";
  }
  if (isNewUser) {
    return "/subscription-selection";
  }
  if (user.attributes.onboarding_completed) {
    return "/home";
  }
  if (user.attributes.is_premium || user.attributes.is_lifetime) {
    return "/onboarding-integration";
  }
  try {
    const result = await new SubscriptionsResource().readMany();
    const subscriptions: SubscriptionEntity[] = result.data;
    if (
      subscriptions[0] &&
      subscriptions[0].attributes.status === "trialing" &&
      DateTime.fromISO(
        subscriptions[0].attributes.current_period_end.split(" ")[0]
      ) > DateTime.now()
    ) {
      return "/onboarding-integration";
    }
    return "/onboarding/signup-before";
  } catch (_) {
    return "/onboarding/signup-before";
  }
};
