import { palette } from "assets/palette";
import { i18n } from "constants/i18n.constants";
import React from "react";
import { Text } from "elements/text/Text";
import { DateTime } from "luxon";
import { AmexImage, DinersImage, DiscoverImage, JCBImage, MasterCardImage, UnionpayImage, VisaImage } from "assets/images";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { resizeByResolutionWidth } from "helpers/utils.helper";
import { SubscriptionPlan } from "components/subscription-period-selection/SubscriptionPeriodSelection.type";
import { PaymentMethods, PaymentStatus } from "constants/payment-methods.contants";

interface Props {
  dateInvoice: string;
  plan: string;
  paymentMethod: string;
  total?: string;
  paymentMethodCard: string;
  isPaid?: PaymentStatus.PAID | PaymentStatus.FAIL;
  onPressDownload?: () => void;
}

export const BillingItem = ({ dateInvoice, paymentMethod, total, onPressDownload, plan, paymentMethodCard, isPaid }: Props) => {
  const paymentMethodSelector = (paymentMethod: string) => {
    if (paymentMethod === "visa") {
      return VisaImage;
    } else if (paymentMethod === "mastercard") {
      return MasterCardImage;
    } else if (paymentMethod === "amex") {
      return AmexImage;
    } else if (paymentMethod === "diners") {
      return DinersImage;
    } else if (paymentMethod === "discover") {
      return DiscoverImage;
    } else if (paymentMethod === "jcb") {
      return JCBImage;
    } else if (paymentMethod === "unionpay") {
      return UnionpayImage;
    } else if (paymentMethod === "") {
      return undefined;
    }
  };

  const planSelector = () => {
    if (plan === SubscriptionPlan.MONTHLY) {
      return i18n.t("monthly")
    } else return i18n.t("yearly")
  };
  return (
    <div style={isPaid === PaymentStatus.PAID ? styles.paidTableRowItem : styles.unpaidTableRowItem}>
      <div style={styles.width}>
        <Text style={isPaid === PaymentStatus.PAID ? styles.paidTableRowItemText : styles.unpaidTableRowItemText}>{paymentMethod !== PaymentMethods.APPLE ? DateTime.fromISO(dateInvoice).toFormat("MMM dd, yyyy") : "-"}</Text>
      </div>
      <div style={styles.width}>
        {paymentMethod !== PaymentMethods.APPLE ? <><Text style={isPaid === PaymentStatus.PAID ? styles.paidTableRowItemText : styles.unpaidTableRowItemText}>{i18n.t("premium")}</Text>
          <Text style={isPaid === PaymentStatus.PAID ? styles.paidYearly : styles.unpaidYearly}>{planSelector()}</Text></> :
          <Text>{i18n.t("apple_billing_description")}</Text>}
      </div>
      {
        paymentMethod !== PaymentMethods.APPLE ?
          <>
            <div style={styles.cardContainer}>
              <div style={styles.cardRowContainer}>
                {paymentMethodSelector(paymentMethod) !== undefined ? <img
                  width={30}
                  height={20}
                  src={paymentMethodSelector(paymentMethod)}
                  alt="paymentMethodImage"
                /> : <div style={styles.emptyImage} />}
                <Text style={isPaid === PaymentStatus.PAID ? styles.paidCardText : styles.unpaidCardText}>{paymentMethodCard ? `***${paymentMethodCard}` : `${'\xa0'.repeat(5)}N/A`}</Text>
              </div>
            </div>
          </>
          :
          <>
            <div style={styles.appleCardContainer}>
              <div style={styles.appleCardRowContainer}>
                {paymentMethod === PaymentMethods.APPLE ? <Icon width={18} height={20} name={IconName.APPLE_CARD} /> : <div style={styles.emptyImage} />}
                <Text>{i18n.t("apple_account")}</Text>
              </div>
            </div>
          </>
      }
      <div style={styles.download}>
        {
          paymentMethod !== PaymentMethods.APPLE ?
            <>
              <div style={styles.paidContainer}>
                <Text style={isPaid === PaymentStatus.PAID ? styles.paidTableRowItemText : styles.unpaidTableRowItemText}>{total}</Text>
                <Text style={isPaid === PaymentStatus.PAID ? styles.paidText : styles.unpaidText}>{isPaid}</Text>
              </div>
              {isPaid === PaymentStatus.PAID ? <Icon
                name={IconName.DOWNLOAD}
                width={resizeByResolutionWidth(21)}
                height={resizeByResolutionWidth(19)}
                onPress={onPressDownload}
              /> : null}</>
            : <div style={{ textAlign: "center", width: "100%" }}>
              -
            </div>
        }
      </div>
    </div >
  );
};

const styles: Record<string, React.CSSProperties> = {
  unpaidTableRowItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: 45,
    marginTop: 15,
    border: `1px solid ${palette.red.light}`,
    borderRadius: 10
  },
  paidTableRowItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: 45,
    marginTop: 15,
  },
  paidTableRowItemText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 15,
    color: palette.black,
    width: "100%",
    textAlign: "center",
  },
  unpaidTableRowItemText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 15,
    color: palette.red.dark,
    width: "100%",
    textAlign: "center",
  },
  paidYearly: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 15,
    color: palette.gray.medium,
    width: "100%",
    textAlign: "center",
  },
  unpaidYearly: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 15,
    color: palette.red.dark,
    width: "100%",
    textAlign: "center",
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  cardRowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  paidCardText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 15,
    color: palette.black,
    marginLeft: 10,
    marginRight: 10
  },
  unpaidCardText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 15,
    color: palette.red.dark,
    marginLeft: 10,
    marginRight: 10
  },
  width: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    position: "relative"
  },
  appleCardContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    position: "relative"
  },
  appleCardRowContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  openedCardRowContainer: {
    height: 35,
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: palette.white,
    boxShadow: "0px 1px 3px 0px #00000040"
  },
  download: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
  },
  emptyImage: {
    width: 30,
    height: 20,
  },
  paidContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  paidText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 15,
    color: palette.blue.lighter,
    textAlign: "center",
    marginTop: 5
  },
  unpaidText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 15,
    color: palette.red.dark,
    textAlign: "center",
    marginTop: 5
  }
};
