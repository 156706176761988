import { ResourceManagementService } from "communicators/services/ResourceManagement.service";
import { IAuthV2Resource } from "./authV2.type";
import { LocalStorageParam } from "constants/local-storage.constant";
import { store } from "redux-store/store";
import { setUserBearerToken } from "redux-store/actions/user-settings.action";

export class AuthV2Resource extends ResourceManagementService<IAuthV2Resource> {
  constructor() {
    super("AUTHV2");
  }

  login = async (idToken: string): Promise<string | null> => {
    const result = await this.createOne({
      application: "insumo_web",
      firebase_access_token: idToken,
    });

    if (!result) return null;
    localStorage.setItem(LocalStorageParam.TOKEN, JSON.stringify(result));
    store.dispatch(setUserBearerToken(result.access_token));
    return result.access_token;
  };

  logout = (): void => {
    localStorage.removeItem(LocalStorageParam.TOKEN);
    localStorage.removeItem(LocalStorageParam.FIREBASE_UUID);
  };
}
