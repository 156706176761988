export enum PreferenceTab {
  ACCOUNT = "account",
  INTEGRATIONS = "integrations",
  SUBSCRIPTION = "subscription",
  BILLING_HISTORY = "billing_history",
  PAYMENT_METHOD = "payment_method",
  CHANGE_BILLING_CYCLE = "change_billing_cycle",
  COMPLETE_YOUR_PURCHASE = "complete_your_purchase",
  ADD_PAYMENT_METHOD = "add_payment_method",
  REDEEM_CODE = "redeem_code"
}
