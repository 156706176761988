import { palette } from "assets/palette";
import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { Icon } from "elements/icon/Icon";
import { i18n } from "constants/i18n.constants";
import { IconName } from "elements/icon/Icon.type";
import { Text } from "elements/text/Text";
import { PreferenceTab } from "../PreferencesDialog/PreferenceDialog.types";
import { resizeByResolutionWidth } from "helpers/utils.helper";
import { useAppSelector } from "redux-store/store.hooks";

interface Props {
  selected: PreferenceTab;
  onSelect: (preferenceKey: PreferenceTab) => void;
}

interface PreferenceListType {
  key: PreferenceTab[];
  text: string;
  icon?: IconName;
  subElements?: PreferenceListType[];
}

export const PreferencesLeftList = ({ selected, onSelect }: Props) => {
  const { user } = useAppSelector((state) => ({
    user: state.userSetting.userInfo,
  }));
  const iconWidth = (item: PreferenceListType) => {
    if (item.icon === IconName.PROFILE) {
      return resizeByResolutionWidth(18);
    } else if (item.icon === IconName.LINK) {
      return resizeByResolutionWidth(26);
    } else if (item.icon === IconName.CARD) {
      return resizeByResolutionWidth(24);
    }
  };
  const iconHeight = (item: PreferenceListType) => {
    if (item.icon === IconName.PROFILE) {
      return resizeByResolutionWidth(20);
    } else if (item.icon === IconName.LINK) {
      return resizeByResolutionWidth(15);
    } else if (item.icon === IconName.CARD) {
      return resizeByResolutionWidth(18);
    }
  };
  return (
    <div style={styles.leftList}>
      <List>
        {PREFERENCES_LIST.map((item) => {
          const isSelectedItem = item.key.includes(selected);
          if (
            item.key[0] === PreferenceTab.SUBSCRIPTION &&
            user?.attributes.is_lifetime
          ) {
            return null;
          }
          return (
            <>
              <ListItem
                key={item.key[0]}
                button
                onClick={() => onSelect(item.key[0])}
              >
                {item.icon ? (
                  <ListItemAvatar>
                    <Icon
                      width={iconWidth(item)}
                      height={iconHeight(item)}
                      fill={
                        isSelectedItem ? palette.black : palette.gray.medium
                      }
                      name={item.icon}
                    />
                  </ListItemAvatar>
                ) : null}
                <Text
                  style={
                    item.icon
                      ? isSelectedItem
                        ? styles.selectedText
                        : styles.unselectedText
                      : isSelectedItem
                      ? styles.selectedTextWithoutIcon
                      : styles.unselectedTextWithoutIcon
                  }
                >
                  {item.text}
                </Text>
              </ListItem>
              {item.subElements && isSelectedItem
                ? item.subElements?.map((subItem) => {
                    const isSubItemSelectedItem =
                      subItem.key.includes(selected);
                    return (
                      <ListItem
                        key={subItem.key[0]}
                        button
                        onClick={() => onSelect(subItem.key[0])}
                      >
                        <Text
                          style={
                            subItem.icon
                              ? isSubItemSelectedItem
                                ? styles.selectedText
                                : styles.unselectedText
                              : isSubItemSelectedItem
                              ? styles.selectedTextWithoutIcon
                              : styles.unselectedTextWithoutIcon
                          }
                        >
                          {subItem.text}
                        </Text>
                      </ListItem>
                    );
                  })
                : null}
            </>
          );
        })}
      </List>
    </div>
  );
};

const PREFERENCES_LIST: PreferenceListType[] = [
  {
    key: [PreferenceTab.ACCOUNT, PreferenceTab.REDEEM_CODE],
    text: i18n.t("account"),
    icon: IconName.PROFILE,
  },
  {
    key: [PreferenceTab.INTEGRATIONS],
    text: i18n.t("integrations"),
    icon: IconName.LINK,
  },
  {
    key: [
      PreferenceTab.SUBSCRIPTION,
      PreferenceTab.BILLING_HISTORY,
      PreferenceTab.PAYMENT_METHOD,
      PreferenceTab.CHANGE_BILLING_CYCLE,
      PreferenceTab.ADD_PAYMENT_METHOD,
      PreferenceTab.COMPLETE_YOUR_PURCHASE,
    ],
    text: i18n.t("subscription"),
    icon: IconName.CARD,
    subElements: [
      {
        key: [
          PreferenceTab.BILLING_HISTORY,
          PreferenceTab.COMPLETE_YOUR_PURCHASE,
          PreferenceTab.CHANGE_BILLING_CYCLE,
        ],
        text: i18n.t("billing_history"),
      },
      {
        key: [PreferenceTab.PAYMENT_METHOD, PreferenceTab.ADD_PAYMENT_METHOD],
        text: i18n.t("payment_method"),
      },
    ],
  },
];

const styles: Record<string, React.CSSProperties> = {
  leftList: {
    width: 220,
    backgroundColor: palette.white,
    borderRight: `1px solid ${palette.gray.light}`,
    height: "100vh",
    marginLeft: 5,
    boxShadow: "3px 4px 4px rgba(0, 0, 0, 0.1)",
  },
  selectedText: {
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "500",
    color: palette.black,
    marginLeft: -15,
    fontSize: 18,
  },
  unselectedText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    color: palette.gray.medium,
    marginLeft: -15,
    fontSize: 18,
  },
  selectedTextWithoutIcon: {
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "500",
    color: palette.black,
    marginLeft: 5,
    fontSize: 18,
  },
  unselectedTextWithoutIcon: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    color: palette.gray.medium,
    marginLeft: 5,
    fontSize: 18,
  },
};
