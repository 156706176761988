import { useHomeHook } from "./useHomeHook";
import { useCallback, useEffect, useRef, useState } from "react";

export const useStreakHook = () => {
  const { streakInfo } = useHomeHook();
  const ref = useRef<any>();
  const [isAchievementModalVisible, setIsAchievementModalVisible] =
    useState<boolean>(false);
  const useOutsideClick = () => {
    const handleClick = useCallback((event: { target: any }) => {
      if (!ref?.current?.contains(event.target)) {
        return;
      } else {
        setIsAchievementModalVisible((prev) => !prev);
      }
    }, []);

    useEffect(() => {
      document.addEventListener("mouseup", handleClick);

      return () => {
        document.removeEventListener("mouseup", handleClick);
      };
    }, [handleClick]);
  };
  useOutsideClick();
  const task_zero_days = streakInfo?.zero_day_count
    ? streakInfo?.zero_day_count
    : 0;
  const current_streak =
    streakInfo?.active_streak === null ? 1 : streakInfo?.active_streak?.length!;
  const longest_streak =
    streakInfo?.longest_streak === null
      ? 1
      : streakInfo?.longest_streak?.length!;

  return {
    task_zero_days,
    current_streak,
    longest_streak,
    ref,
    isAchievementModalVisible,
  };
};
