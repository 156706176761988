import React from "react";
import Dialog from "@mui/material/Dialog";
import { i18n } from "constants/i18n.constants";
import { IconName } from "elements/icon/Icon.type";
import { Icon } from "elements/icon/Icon";
import { palette } from "assets/palette";
import { Text } from "elements/text/Text";
import { IconButton } from "@mui/material";
import "./UnsubscriptionDialog.scss";
import { CustomButton } from "elements/button/Button";
import { useUnsubscriptionDialogHook } from "./useUnsubscriptionDialogHook";

interface Props {
  isOpened: boolean;
  handleClose: () => void;
}

export const UnsubscriptionDialog = ({ isOpened, handleClose }: Props) => {
  const {
    premiumUntil,
    onPressUpgradeNow
  } = useUnsubscriptionDialogHook();
  return (
    <Dialog onClose={handleClose} fullWidth maxWidth={"md"} open={isOpened}>
      <div style={styles.header}>
        <IconButton onClick={handleClose}>
          <Icon
            width={15}
            height={15}
            name={IconName.CLOSE}
          />
        </IconButton>
      </div>

      <div className="dialog-container">
        <div className="dialog-header">
          <Text className="dialog-title" >{i18n.t("your_subscription_has_been_cancelled")}</Text>
          <Icon style={{ cursor: "default" }} width={120} height={120} fill={palette.black} name={IconName.CARD} />
        </div>
        <div>
          <Text className="unsubscription-message" >{i18n.t("your_premium_membership_has_been_canceled")}</Text>
          <span className="unsubscription-message" >{i18n.t("you_will_have_access")}</span><span className="until-day">{premiumUntil}.</span>
        </div>
        <div className="feature-container">
          <div className="feature">
            <div><Icon style={{ cursor: "default" }} name={IconName.UNSUBSCRIPTION_WEBAPP} /></div>
            <div className="feature-description">
              <div className="feature-description-title"><p>{i18n.t("web_app")}</p></div>
              <div className="feature-description-content"><p>{i18n.t("create_tasks_and_manage_meetings")}</p></div>
            </div>
          </div>
          <div className="feature">
            <div><Icon style={{ cursor: "default" }} name={IconName.UNSUBSCRIPTION_FOCUS} /></div>
            <div className="feature-description">
              <div className="feature-description-title"><p>{i18n.t("focus")}</p></div>
              <div className="feature-description-content"><p>{i18n.t("focus_on_tasks")}</p></div>
            </div>
          </div>
          <div className="feature">
            <div><Icon style={{ cursor: "default" }} name={IconName.UNSUBSCRIPTION_INTEGRATIONS} /></div>
            <div className="feature-description">
              <div className="feature-description-title"><p>{i18n.t("integrations")}</p></div>
              <div className="feature-description-content"><p>{i18n.t("integrate_calendar_todoist_asana_others")}</p></div>
            </div>
          </div>
          <div className="feature">
            <div><Icon style={{ cursor: "default" }} name={IconName.UNSUBSCRIPTION_MOBILEAPP} /></div>
            <div className="feature-description">
              <div className="feature-description-title"><p>{i18n.t("mobile_app")}</p></div>
              <div className="feature-description-content"><p>{i18n.t("track_your_tasks")}</p></div>
            </div>
          </div>
          <div className="feature">
            <div><Icon style={{ cursor: "default" }} name={IconName.UNSUBSCRIPTION_REMINDERS} /></div>
            <div className="feature-description">
              <div className="feature-description-title"><p>{i18n.t("reminders")}</p></div>
              <div className="feature-description-content"><p>{i18n.t("get_reminded")}</p></div>
            </div>
          </div>
          <div className="feature">
            <div><Icon style={{ cursor: "default" }} name={IconName.UNSUBSCRIPTION_ZEROTASKS} /></div>
            <div className="feature-description">
              <div className="feature-description-title"><p>{i18n.t("zero_task")}</p></div>
              <div className="feature-description-content"><p>{i18n.t("reach_your_zero_tasks")}</p></div>
            </div>
          </div>
        </div>
        <div style={styles.buttonsContainer}>
          <CustomButton style={styles.nokeep} text={i18n.t("upgrade_now")} textStyle={styles.noKeepButtonText} onClick={onPressUpgradeNow} />
          <CustomButton style={styles.subscribe} text={i18n.t("go_to_dashboard")} textStyle={styles.buttonText} onClick={handleClose} />
        </div>
      </div>
    </Dialog>
  );
};


const styles: Record<string, React.CSSProperties> = {
  header: {
    position: "absolute",
    right: 20,
    top: 20,
  },
  buttonText: {
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "500",
    fontSize: 20,
    color: palette.gray.medium,
  },
  noKeepButtonText: {
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "500",
    fontSize: 20,
    color: palette.white,
  },
  nokeep: {
    width: "100%",
    backgroundColor: palette.black,
    height: 50,
    marginTop: "auto",
    color: "white",
  },
  subscribe: {
    width: "100%",
    backgroundColor: palette.gray.ice,
    height: 50,
    marginTop: "auto"
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 30,
    marginTop: 20,
  },
};
