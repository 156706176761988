import { OnboardingIntegration } from "pages/private/onboarding-integration/OnboardingIntegration";
import { CreateReminder } from "pages/private/create-reminder/CreateReminder";
import { SubscriptionFail } from "pages/private/subscription-fail/SubscriptionFail";
import { AlreadySignIn } from "pages/private/already-signin/AlreadySignIn";
import { Home } from "pages/private/home/Home";
import { PlaningTimeSelection } from "pages/private/planing-time-selection/PlaningTimeSelection";
import { ToolSelection } from "pages/private/tool-selection/ToolSelection";
import { Welcome } from "pages/public";
import { AsanaCallback } from "pages/public/asana-callback/AsanaCallback";
import { ForgotPassword } from "pages/public/forgot-password/ForgotPassword";
import { Login } from "pages/public/login/Login";
import { PhoneLogin } from "pages/public/phone-login/PhoneLogin";
import { TodoistCallback } from "pages/public/todoist_callback/TodoistCallback";
import { VerifyPhone } from "pages/public/verify-phone/VerifyPhone";
import { AddFirstTask } from "pages/private/add-first-task/AddFirstTask";
import { DndTutorial } from "pages/private/dnd-tutorial/DndTutorial";
import { SubscriptionSelection } from "pages/private/subscription-selection/SubscriptionSelection";
import { SubscriptionSuccess } from "pages/private/subscription-success/SubscriptionSuccess";
import { OnboardingDnd } from "pages/private/onboarding-dnd/OnboardingDnd";
import { Checkout } from "pages/private/checkout/Checkout";
import { AddFirstTaskDetail } from "pages/private/add-first-task-detail/AddFirstTaskDetail";
import { Waitlist } from "pages/public/waitlist/Waitlist";
import { ChangePhoneNumber } from "pages/private/change-phone-number/ChangePhoneNumber";
import { SomethingWentWrong } from "pages/private/something-went-wrong/SomethingWentWrong";

export interface IRoute {
  path: string;
  component: any;
  exact?: boolean;
  hasGoogleSignIn?: boolean;
}

export const publichRoutes: IRoute[] = [
  {
    path: "/welcome",
    component: Welcome,
  },
  {
    path: "/phone-login",
    component: PhoneLogin,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
  },
  {
    path: "/verify-phone",
    component: VerifyPhone,
  },
  {
    path: "/auth/asana/callback",
    component: AsanaCallback,
  },
  {
    path: "/auth/todoist/callback",
    component: TodoistCallback,
  },
  {
    path: "/waitlist",
    component: Waitlist,
  },
  {
    path: "/change-phone-number",
    component: ChangePhoneNumber,
  }
];

export const privateRoutes: IRoute[] = [
  {
    path: "/home",
    component: Home,
    hasGoogleSignIn: true,
  },
  {
    path: "/onboarding/signup-before",
    component: AlreadySignIn,
  },
  {
    path: "/onboarding/tools",
    component: ToolSelection,
  },
  {
    path: "/onboarding/plan-time",
    component: PlaningTimeSelection,
  },
  {
    path: "/onboarding/create-reminder",
    component: CreateReminder,
  },
  {
    path: "/subscription-fail",
    component: SubscriptionFail,
  },
  {
    path: "/onboarding-integration",
    component: OnboardingIntegration,
    hasGoogleSignIn: true,
  },
  {
    path: "/add-first-task",
    component: AddFirstTask,
  },
  {
    path: "/add-first-task-detail",
    component: AddFirstTaskDetail,
  },
  {
    path: "/dnd-tutorial",
    component: DndTutorial,
  },
  {
    path: "/onboarding-dnd",
    component: OnboardingDnd,
  },
  {
    path: "/subscription-selection",
    component: SubscriptionSelection,
  },
  {
    path: "/subscription-success",
    component: SubscriptionSuccess,
  },
  {
    path: "/checkout",
    component: Checkout,
  },
  {
    path: "/something-went-wrong",
    component: SomethingWentWrong
  }
];
