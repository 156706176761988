import React from "react";
import { i18n } from "constants/i18n.constants";
import { LoginWrapper } from "components/login-wrapper/LoginWrapper";
import { EmailLogin } from "components/email-login/EmailLogin";
import { usePageHook } from "hooks/usePageHook";

export const Welcome = () => {
  usePageHook();
  return (
    <LoginWrapper showLoginText title={i18n.t("sign_up_free")}>
      <EmailLogin />
    </LoginWrapper>
  );
};
