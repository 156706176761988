import {
  deleteIntegration,
  makeTodoistIntegration,
} from "helpers/third-party.helper";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux-store/store.hooks";
import { ThirdPartyProvider } from "interfaces/main.interfaces";
import { setThirdPartyProviderData } from "redux-store/actions/third-party-provider.actions";
import { setRefreshBoardData } from "redux-store/actions/home-page.actions";

export const useTodoistHook = () => {
  const { todoistToggled, thirdPartyInformation } = useAppSelector((state) => ({
    todoistToggled: !!state.thirdPartyProviderData.todoistId,
    thirdPartyInformation: state.thirdPartyProviderData.thirdPartyData,
  }));
  const [todoistState, setTodoistState] = useState(todoistToggled);
  const dispatch = useAppDispatch();
  const toggleSwitch = () => {
    if (todoistState) {
      deleteIntegration(ThirdPartyProvider.TODOIST, thirdPartyInformation);
      setTodoistState(false);
    } else {
      makeTodoistIntegration();
      setTodoistState(true);
    }
    dispatch(setThirdPartyProviderData());
    dispatch(setRefreshBoardData());
  };
  useEffect(() => {
    dispatch(setThirdPartyProviderData());
  }, [dispatch]);
  return { todoistState, toggleSwitch };
};
