import * as React from "react";
import { palette } from "assets/palette";
import { i18n } from "constants/i18n.constants";
import { Text } from "elements/text/Text";
import { styled } from "@mui/material/styles";
import { LeftSettingsBar } from "pages/private/home/components/LeftSettignsBar/LeftSettingsBar";
import { DrawerHeader } from "pages/private/home/components/DrawerHeader/DrawerHeader";
import { DRAWER_WIDTH, LEFT_BAR_WIDTH } from "pages/private/home/constants";
import { IOSSwitch } from "elements/switch/Switch";
import { ProgressBar } from "elements/progress-bar/ProgressBar";
import { CustomButton } from "elements/button/Button";
import { useOnboardingIntegration } from "./useOnboardingIntegration";
import { FullScreenSpinner } from "elements/spinner/FullScreenSpinner";
import { usePageHook } from "hooks/usePageHook";

interface Props {
  googleSignIn: any;
  googleAuthLoading: boolean;
}

export const OnboardingIntegration = ({ googleSignIn, googleAuthLoading }: Props) => {
  const {
    openSecondDrawer,
    secondDrawerOpened,
    progressValue,
    onPressNext,
    toggleSwitch,
    INTEGRATIONS,
    showSpinner
  } = useOnboardingIntegration({ googleSignIn, googleAuthLoading });
  usePageHook();
  return (
    <div style={styles.container}>
      <div style={styles.wrapper}>
        <LeftSettingsBar
          openSecondDrawer={openSecondDrawer}
          secondDrawerOpenStatus={secondDrawerOpened}
          isChildOfAFT={true}
        />
        <div>
          <DrawerHeader>
            <div style={styles.headerButtonStyle}>
              <Text style={styles.headerTextStyle}>{i18n.t("app_name")}</Text>
            </div>
          </DrawerHeader>
          <div style={styles.integrationContainer}>
            <Text style={styles.integrateHeader}>
              {i18n.t("integrate_your_tools")}
            </Text>
            <Text style={styles.integrateSubheader}>
              {i18n.t("integrate_subtext")}
            </Text>
          </div>
          <div style={styles.toolListContainer} className="Windows">
            {INTEGRATIONS.map((item, index) => (
              <div style={styles.integrationItem} key={index}>
                <img style={styles.image} src={item.image} alt={item.title} />
                <div style={styles.integrationItemWrapper}>
                  <Text style={styles.integrationItemText}>{item.title}</Text>
                  {item.subtitle ? (
                    <Text style={styles.integrationSubtitle}>
                      {item.subtitle}
                    </Text>
                  ) : null}
                </div>
                {item.showSwitch ? (
                  <IOSSwitch
                    onChange={() => toggleSwitch(item.provider)}
                    checked={item.toggled}
                  />
                ) : null}
              </div>
            ))}
          </div>
          <div style={styles.bottomWrapper}>
            <Text style={styles.dailyPlanningProcess}>
              {i18n.t("daily_planning_process")}
            </Text>
            <ProgressBar
              style={styles.progress}
              value={progressValue}
              progressProps={{ color: "info" }}
            />
            <CustomButton
              style={styles.buttonStyle}
              text={i18n.t("next")}
              onPress={onPressNext}
            />
          </div>
        </div>
      </div>
      <FullScreenSpinner loading={showSpinner} />
      <SecondDrawer open={secondDrawerOpened.opened} />
    </div>
  );
};

const SecondDrawer = styled("div")<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  width: DRAWER_WIDTH + 10,
  backgroundColor: palette.white,
  padding: theme.spacing(3),
  top: 68,
  position: "absolute",
  transition: theme.transitions.create(["left"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  left: `-${DRAWER_WIDTH + 10}px`,
  ...(open && {
    transition: theme.transitions.create(["left"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    left: `${LEFT_BAR_WIDTH - 10}px`,
    zIndex: 5,
  }),
}));

const styles: Record<string, React.CSSProperties> = {
  container: {
    width: 390 + LEFT_BAR_WIDTH,
    height: "100vh",
    boxShadow: "0px 4px 4px 0px #00000040",
  },
  headerButtonStyle: {
    display: "flex",
    flex: 1,
    backgroundColor: palette.white,
    paddingLeft: 20,
    alignItems: "center",
    borderWidth: 0,
  },
  headerTextStyle: {
    fontWeight: "500",
    fontSize: 22,
    marginRight: 5,
  },
  iconContainer: {
    paddingTop: 5,
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    height: "100%",
  },
  integrationContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginLeft: 40,
    marginRight: 40,
    marginTop: 100,
    marginBottom: 20,
  },
  integrateHeader: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 30,
    color: palette.black,
  },
  integrateSubheader: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 22,
    color: palette.gray.dark,
  },
  integrationItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: palette.white,
    borderRadius: 12,
    boxShadow: "0px 4px 4px 0px #0000001A",
    padding: 20,
    marginLeft: 40,
    marginRight: 40,
    marginTop: 25,
  },
  image: {
    width: 24,
    height: 24,
  },
  integrationItemText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 18,
    color: palette.gray.dark,
    textAlign: "center",
  },
  integrationSubtitle: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 15,
    color: palette.gray.mediumLight,
    textAlign: "center",
  },
  integrationItemWrapper: {
    display: "flex",
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    marginLeft: 20,
    alignItems: "flex-start",
  },
  progress: {
    marginTop: 5,
    marginBottom: 50,
    marginLeft: 40,
    width: DRAWER_WIDTH - 40,
  },
  dailyPlanningProcess: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 10,
    color: palette.gray.medium,
    marginLeft: 40,
  },
  buttonStyle: {
    marginLeft: 40,
    width: 200,
    backgroundColor: palette.black,
    height: 50,
  },
  bottomWrapper: {
    position: "absolute",
    bottom: 70
  },
  toolListContainer: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "stretch",
    height: 200,
    overflow: "hidden",
    overflowY: "scroll",
    paddingBottom: 10
  }
};
