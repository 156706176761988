import Axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { IAPIResource } from "communicators/resources/type";

export abstract class BaseCommunicator<Resource extends IAPIResource> {
  axios!: AxiosInstance;

  get;
  post;
  put;
  patch;
  delete;

  constructor(public baseUrl: string, public headers?: Record<string, string>) {
    this.axios = Axios.create({ baseURL: this.baseUrl, headers: this.headers });

    this.get = <T extends Resource["entity"] | Resource["entities"]>(
      ...args: [url: string, config?: AxiosRequestConfig<Resource["candidate"]>]
    ) =>
      this.axios
        .get<
          AxiosRequestConfig<Resource["candidate"]> | undefined,
          AxiosResponse<T>
        >(...args)
        .then(this.handleSuccess)
        .catch(this.handleError);

    this.post = (
      ...args: [
        url: string,
        data?: Resource["candidate"],
        config?: AxiosRequestConfig<Resource["candidate"]>
      ]
    ) =>
      this.axios
        .post<
          AxiosRequestConfig<Resource["candidate"]> | undefined,
          AxiosResponse<Resource["entity"]>
        >(...args)
        .then(this.handleSuccess)
        .catch(this.handleError);

    this.put = (
      ...args: [url: string, config?: AxiosRequestConfig<Resource["candidate"]>]
    ) =>
      this.axios
        .put<
          AxiosRequestConfig<Resource["candidate"]> | undefined,
          AxiosResponse<Resource["entity"]>
        >(...args)
        .then(this.handleSuccess)
        .catch(this.handleError);

    this.patch = (
      ...args: [
        url: string,
        data?: Partial<Resource["candidate"]>,
        config?: AxiosRequestConfig<Resource["candidate"]>
      ]
    ) =>
      this.axios
        .patch<
          AxiosRequestConfig<Resource["candidate"]> | undefined,
          AxiosResponse<Resource["entity"]>
        >(...args)
        .then(this.handleSuccess)
        .catch(this.handleError);

    this.delete = (
      ...args: [url: string, config?: AxiosRequestConfig<Resource>]
    ) =>
      this.axios
        .delete<
          AxiosRequestConfig<Resource["candidate"]> | undefined,
          AxiosResponse<null>
        >(...args)
        .then(this.handleSuccess)
        .catch(this.handleError);

    return this;
  }

  handleSuccess: <T>(axiosResponse: AxiosResponse<T>) => T = (axiosResponse) =>
    axiosResponse.data;

  handleError(error: AxiosError): never | Promise<never> {
    throw error;
  }
}
