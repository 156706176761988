import { Typography } from "@mui/material";
import { resizeByResolutionWidth } from "helpers/utils.helper";
import React from "react";

interface Props {
  style?: React.CSSProperties;
  children?: React.ReactNode | string;
  className?: string;
  onPress?: () => void;
}

const styles = {
  text: {
    fontFamily: "Gilroy",
  },
};

export const Text = ({ children, style, onPress, ...rest }: Props) => {
  return (
    <Typography
      onClick={onPress}
      style={{
        ...styles.text,
        ...style,
        ...{
          fontSize:
            style?.fontSize && typeof style.fontSize === "number"
              ? resizeByResolutionWidth(style.fontSize)
              : 12,
        },
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
};
