import { AsanaImage, GoogleCalendarImage, TodoistImage } from "assets/images";
import { UserPreferencesResource } from "communicators/resources/user-preferences/user-preferences.resource";
import { UserPreferencesEntity } from "communicators/resources/user-preferences/user-preferences.type";
import { ResponseWithData } from "communicators/types";
import { ThirdPartyProvider } from "interfaces/main.interfaces";
import { useEffect, useState } from "react";
import {
  setPreferencesDialog,
  setPreferencesTab,
} from "redux-store/actions/home-page.actions";
import { useAppDispatch, useAppSelector } from "redux-store/store.hooks";
import { PreferenceTab } from "../PreferencesDialog/PreferenceDialog.types";

type Integration = {
  image: string;
  isActive: boolean;
  key: string;
}[];

export const useLeftSettingBarHook = () => {
  const { googleId, asanaId, todoistId } = useAppSelector((state) => ({
    googleId: state.thirdPartyProviderData.googleId,
    asanaId: state.thirdPartyProviderData.asanaId,
    todoistId: state.thirdPartyProviderData.todoistId,
  }));
  const [preference, setPreference] =
    useState<ResponseWithData<UserPreferencesEntity[]>>();
  const dispatch = useAppDispatch();

  const getUserPreference = async () => {
    const response = await new UserPreferencesResource().readMany();
    setPreference(response);
  };
  const INTEGRATIONS: Integration = [];

  preference?.data[0].attributes.preferred_tools?.forEach((item) => {
    if (item === ThirdPartyProvider.TODOIST) {
      INTEGRATIONS.push({
        image: TodoistImage,
        isActive: !!todoistId,
        key: ThirdPartyProvider.TODOIST,
      });
    } else if (item === ThirdPartyProvider.ASANA) {
      INTEGRATIONS.push({
        image: AsanaImage,
        isActive: !!asanaId,
        key: ThirdPartyProvider.ASANA,
      });
    } else if (item === ThirdPartyProvider.GOOGLE) {
      INTEGRATIONS.push({
        image: GoogleCalendarImage,
        isActive: !!googleId,
        key: ThirdPartyProvider.GOOGLE,
      });
    }
  });

  const onPressSettingIcon = () => {
    dispatch(setPreferencesTab(PreferenceTab.ACCOUNT));
    dispatch(setPreferencesDialog(true));
  };

  const onPressPlusIcon = () => {
    dispatch(setPreferencesTab(PreferenceTab.INTEGRATIONS));
    dispatch(setPreferencesDialog(true));
  };

  useEffect(() => {
    getUserPreference();
  }, []);

  return { INTEGRATIONS, onPressSettingIcon, onPressPlusIcon };
};
