import { LocalStorageParam } from "constants/local-storage.constant";
import { ONBOARDING_PAGE_COUNT } from "constants/onboarding.constants";
import { OnesignalHelper } from "helpers/onesignal.helper";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const PAGE_NUMBER = 3;

export const useCreateReminder = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onPressNext = () => {
    localStorage.setItem(LocalStorageParam.ONBOARDING_CREATE_REMINDER_SHOWN, JSON.stringify(true));
    navigate("/onboarding-integration");
  };

  const onPressNotificationButton = () => {
    setLoading(true);
    OnesignalHelper.askPermission();
    onPressNext();
    setLoading(false);
  };

  const onPressEmailNotificationButton = () => {
    setLoading(true);
    onPressNext();
    setLoading(false);
  }

  const progressValue = (PAGE_NUMBER / ONBOARDING_PAGE_COUNT) * 100;

  return {
    progressValue,
    onPressNext,
    onPressNotificationButton,
    onPressEmailNotificationButton,
    loading
  };
};
