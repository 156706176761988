import OneSignal from "react-onesignal";
import { store } from "redux-store/store";

class OnesignalController {
  userId = "";
  private isInit = false;

  initSubscriptionListener = () => {
    OneSignal.on("subscriptionChange", function (isSubscribed) {
      const userId = store.getState().userSetting.firebaseUuid;
      if (isSubscribed && userId) {
        OneSignal.setExternalUserId(userId);
      }
    });
  };

  askPermission = async () => {
    if (!this.isInit) return null;
    await OneSignal.showSlidedownPrompt({
      force: true,
    });
  };

  init = async () => {
    try {
      await OneSignal.init({
        appId: process.env.REACT_APP_ONESIGNAL_APP_ID || "",
        allowLocalhostAsSecureOrigin: true,
      });
      this.initSubscriptionListener();
      this.isInit = true;
    } catch (_) {}
  };
}

export const OnesignalHelper = new OnesignalController();
