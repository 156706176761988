import React, { useEffect, useState } from "react";
import { palette } from "assets/palette";
import { Text } from "elements/text/Text";
import { i18n } from "constants/i18n.constants";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { CustomButton } from "elements/button/Button";
import { SubscriptionPlan } from "components/subscription-period-selection/SubscriptionPeriodSelection.type";
import { resizeByResolutionWidth } from "helpers/utils.helper";
import Dialog from "@mui/material/Dialog";
import { SubscriptionPlansResource } from "communicators/resources/subscription-plans/subscription-plans.resource";
import { SubscriptionPlanEntity } from "communicators/resources/subscription-plans/subscription-plans.type";
import { useNavigate } from "react-router-dom";
import { SubscriptionsResource } from "communicators/resources/subscription/subscription.resource";
import { SubscriptionEntity } from "communicators/resources/subscription/subscription.type";
import { DateTime } from "luxon";
import { useAppSelector } from "redux-store/store.hooks";

interface Props {
  onPress?: () => void;
  open?: boolean;
}

export const SubscribeToContinueModal = ({ open }: Props) => {
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan>(
    SubscriptionPlan.MONTHLY
  );
  const [data, setData] = useState<SubscriptionPlanEntity[]>();
  const [dialogOpened, setDialogOpened] = useState(false);

  const { userInfo } = useAppSelector((state) => ({
    userInfo: state.userSetting.userInfo,
  }));
  const navigate = useNavigate();

  const getData = async () => {
    const subscriptionPlansData =
      await new SubscriptionPlansResource().readMany();
    setData(subscriptionPlansData.data);
  };
  const getSubscriptions = async () => {
    const result = await new SubscriptionsResource().readMany();
    const subscriptions: SubscriptionEntity[] = result.data;
    let dialogVisibility = true;
    subscriptions.forEach((subscription) => {
      if (
        subscription &&
        (subscription.attributes.status === "active" ||
          subscription.attributes.status === "trialing") &&
        DateTime.fromSQL(subscription.attributes.current_period_end) >
          DateTime.now()
      ) {
        dialogVisibility = false;
      }
    }); 
    if (dialogVisibility && !userInfo?.attributes.is_premium && !userInfo?.attributes.is_lifetime) {
      setDialogOpened(true);
    }
  };

  const onPressSubscribe = () => {
    navigate("/checkout", { state: { selectedPlan } });
  };

  useEffect(() => {
    getData();
    getSubscriptions();
  }, []);

  const getCurrencySymbol = (currency: string): string => {
    if (currency === "usd") return "$";
    return "";
  };

  const getPrice = (): string => {
    const foundData = data?.find(
      (d) => d?.attributes?.interval === selectedPlan
    );
    if (foundData) {
      return (
        getCurrencySymbol(foundData.attributes.currency) +
        (selectedPlan === "month"
          ? foundData?.attributes?.price / 100
          : (foundData?.attributes?.price / 100 / 12).toFixed(2))
      );
    }
    return "";
  };

  return (
    <Dialog onClose={() => {}} fullWidth maxWidth={"md"} open={!!dialogOpened}>
      <div style={styles.container}>
        <div style={styles.rowContainer}>
          <div style={styles.headerContainer}>
            <Text style={styles.title}>{i18n.t("subscribe_to_continue")}</Text>
            <Text style={styles.subtitle}>
              {i18n.t("subscribe_to_continue_long_text")}
            </Text>
          </div>
          <Icon
            name={IconName.BILLING}
            width={resizeByResolutionWidth(79)}
            height={resizeByResolutionWidth(55)}
            fill={palette.black}
            style={styles.icon}
          />
        </div>
        <div style={styles.rowContainer}>
          <div style={styles.featureContainer}>
            <div style={styles.subscriptionFeaturesContainer}>
              {Array.from(i18n.t("subscription_features")).map(
                (item: string, index) => {
                  return (
                    <div style={styles.featuresContainer} key={index}>
                      <Icon name={IconName.SUBSCRIPTION_CHECK} />
                      <div style={styles.verticalGap} />
                      <Text style={styles.subscriptionFeatureText}>{item}</Text>
                      <div style={styles.verticalGap} />
                    </div>
                  );
                }
              )}
            </div>
            <CustomButton
              onPress={onPressSubscribe}
              style={styles.subscribe}
              text={i18n.t("subscribe")}
            />
          </div>
          <div style={styles.center}>
            <div style={styles.planContainer}>
              <Text
                onPress={() => setSelectedPlan(SubscriptionPlan.MONTHLY)}
                style={
                  selectedPlan === SubscriptionPlan.MONTHLY
                    ? styles.selectedPeriodText
                    : styles.unSelectedPeriodText
                }
              >
                {i18n.t("monthly")}
              </Text>
              <div style={styles.verticalPlanSeparator} />
              <Text
                onPress={() => setSelectedPlan(SubscriptionPlan.ANNUALLY)}
                style={
                  selectedPlan === SubscriptionPlan.ANNUALLY
                    ? styles.selectedPeriodText
                    : styles.unSelectedPeriodText
                }
              >
                {i18n.t("annually")}
              </Text>
            </div>
            <Text style={styles.plan}>
              {i18n.t("per_month", { price: getPrice() })}
            </Text>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const styles: Record<string, React.CSSProperties> = {
  featureContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: 20,
    width: "50%",
  },
  headerContainer: {
    width: "65%",
  },
  container: {
    padding: "80px 80px 65px 80px",
  },
  icon: {
    marginLeft: "auto",
    marginBottom: "auto",
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    alignSelf: "stretch",
  },
  planContainer: {
    display: "flex",
    flexDirection: "row",
    alignSelf: "stretch",
    alignItems: "center",
  },
  title: {
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "500",
    fontSize: 30,
    color: palette.black,
    textAlign: "left",
  },
  subtitle: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 18,
    color: palette.gray.medium,
    textAlign: "left",
    marginTop: 25,
  },
  subscriptionFeaturesContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  featuresContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 20,
  },
  subscribe: {
    width: 250,
    backgroundColor: palette.black,
    height: 60,
    marginTop: 30,
  },
  unSelectedPeriodText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 18,
    color: palette.gray.mediumLight,
    cursor: "pointer",
  },
  selectedPeriodText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 18,
    color: palette.black,
    cursor: "pointer",
  },
  verticalPlanSeparator: {
    width: 1,
    height: 46,
    marginLeft: 30,
    marginRight: 30,
    backgroundColor: palette.black,
  },
  plan: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 20,
    color: palette.black,
    textAlign: "left",
    marginTop: 25,
  },
  center: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "auto",
    marginBottom: 20,
    marginLeft: "auto",
  },
};
