import { palette } from "assets/palette";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { Text } from "elements/text/Text";
import React, { useEffect, useState } from "react";
import { TagListItem } from "./TagListItem";
import { i18n } from "constants/i18n.constants";
import "./TagSearchItem.scss";
import { TagEntity } from "communicators/resources/tags/tags.types";
import { TagsResource } from "communicators/resources/tags/tags.resource";
import { TaskTags } from "communicators/resources/event-resources/tasks/tasks.type";

interface Props {
  style?: React.CSSProperties;
  label?: string;
  onClickPlus?: () => void;
  isTagSearchShown?: boolean;
  onPress?: () => void;
  tagCircleColor?: string;
  isTagPresent?: boolean;
  onClickAddTag: (tagTitle: string) => void;
  taskTags?: TaskTags[];
  isTagCreating?: boolean;
  onClickTagSearchItem?: (tag_id: string) => void;
}

export const TagSearchItem = ({
  style,
  label,
  onClickPlus,
  isTagSearchShown,
  onPress,
  tagCircleColor,
  isTagPresent,
  onClickAddTag,
  taskTags,
  isTagCreating,
  onClickTagSearchItem
}: Props) => {
  const [tag, setTag] = useState<string>("");
  const [tags, setTags] = useState<TagEntity[]>([]);
  const getData = async () => {
    const response = await new TagsResource().readMany();
    setTags(response.data);
  };
  useEffect(() => {
    getData();
  }, [onClickAddTag]);
  const filteredTags = tags.filter((item) => {
    return tag !== ""
      ? item.attributes.name.toLowerCase().includes(tag.toLowerCase())
      : [];
  });
  const isTagCreatable = () => {
    const typedTag = tags.filter(
      (item) => item.attributes.name.toLowerCase() === tag.toLowerCase()
    );
    if (filteredTags.includes(typedTag[0])) {
      return false;
    }
    return true;
  };
  const isNotSelectedFavorite = (id: string, taskTags?: TaskTags[]) => {
    const filteredTaskTags = taskTags?.filter((item) => {
      return item.id === id;
    });
    if (filteredTaskTags?.length !== 0) {
      return true;
    }
    return false;
  };
  return (
    <div style={style}>
      {label ? (
        <div className="tag-item-label-container">
          <Text style={styles.label}>{label}</Text>
          <Icon
            name={IconName.PLUS}
            onPress={onClickPlus}
            width={8}
            height={8}
            strokeColor={palette.gray.streak}
            strokeWidth="3"
            fill={palette.white}
            style={styles.plusIcon}
          />
          {isTagSearchShown ? (
            <div className="tag-item-tag-search-container">
              <input
                className="tag-item-input"
                placeholder="Type a label"
                value={tag}
                onChange={(event) => setTag(event.target.value)}
              />
              {tag !== "" ? (
                <div className="tag-item-tag-list">
                  {filteredTags.map((item) => {
                    return (
                      <TagListItem
                        isNotSelectedFavorite={
                          item.attributes.favorite && !isNotSelectedFavorite(item.id, taskTags)
                        }
                        key={item.id}
                        tag={item}
                        onClick={onClickTagSearchItem}
                      />
                    );
                  })}
                  {isTagCreatable() ? (
                    <div
                      className="tag-item-tag-add-list-item-container"
                      style={
                        isTagCreating ? { pointerEvents: "none" } : undefined
                      }
                      onClick={() => onClickAddTag(tag)}
                    >
                      <Icon name={IconName.TAG_PLUS} style={styles.tagPlus} />
                      <div className="tag-item-tag-add-list-item-text">
                        {i18n.t("create")} {tag}
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      ) : null}
      <div className="tag-item-container" onClick={onPress}>
        <div
          className={
            tagCircleColor
              ? "tag-item-tag-circle"
              : isTagPresent
              ? "tag-item-tag-circle-multiple-color"
              : ""
          }
          style={{ backgroundColor: !isTagPresent ? tagCircleColor : undefined }}
        />
      </div>
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  label: {
    fontFamily: "Gilroy-Semibold",
    fontWeight: "500",
    color: palette.gray.mediumLight,
    marginBottom: 5,
    fontSize: 10,
  },
  plusIcon: {
    marginTop: -7,
  },
  tagPlus: {
    marginLeft: -7,
  },
};
