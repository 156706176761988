import { i18n } from "constants/i18n.constants";
import { Text } from "elements/text/Text";
import React from "react";
import "./SignUpText.scss";

interface Props {
  onPress?: () => void;
}

export const SignUpText = ({ onPress }: Props) => {
  return (
    <Text className="first-text">
      {i18n.t("dont_have_an_account")}
      {"\xa0"}
      <Text onPress={onPress} className="login-text">
        {i18n.t("signup")}
      </Text>
    </Text>
  );
};
