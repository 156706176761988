import { ResourceName } from "communicators/types";

import { IAPIResource } from "communicators/resources/type";
import { ResourceManagementService } from "./ResourceManagement.service";
import { IPaginationOptions } from "./ResourceManagement.service";
import { store } from "redux-store/store";

export class UserOwnedResourceManagementService<
  T extends IAPIResource
> extends ResourceManagementService<T> {
  firebaseUuid: string = "";

  constructor(
    resourceName: ResourceName,
    externalPaginationParameters?: Partial<IPaginationOptions>
  ) {
    super(resourceName, externalPaginationParameters);
    const storedId = store.getState().userSetting.firebaseUuid;
    if (this.areWeTestingWithJest()) {
      this.firebaseUuid = process.env.REACT_APP_USER_ID || "";
    }
    if (storedId) {
      this.firebaseUuid = storedId;
    }
  }

  readOne = async (id: string, query: T["query"] = {}) =>
    this.communicator.readOne(id, query);

  readMany = async (query: T["query"] = {}) => {
    const modifiedQuery = this.injectPaginationOptionsToParams(query);

      const response = await this.communicator.readMany(modifiedQuery);
  
      if (response?.meta && response?.meta?.pagination)
        this.canLoadMore = response.meta.pagination.next_page != null;
  
      return response;
  };

  createOne = async (candidate: T["candidate"], query: T["query"] = {}) =>
    this.communicator.createOne(candidate, query);

  updateOne = async (id: string, body: Partial<T["candidate"]>) =>
    this.communicator.updateOne(id, body);

  updateOnePartial = async (id: string, body: Partial<T["candidate"]>) =>
    this.communicator.updateOnePartial(id, body);

  removeOne = async (id: string, query: T["query"] = {}) =>
    this.communicator.removeOne(id, query);

  resync = async () => {
    return await this.communicator.resync({});
  };

  private areWeTestingWithJest = () => {
    return process.env.JEST_WORKER_ID !== undefined;
  };
}
