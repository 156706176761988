import { i18n } from "constants/i18n.constants";
import { TextInput } from "elements/text-field/TextInput";
import { Text } from "elements/text/Text";
import { palette } from "assets/palette";
import React from "react";
import { GoogleCalendarImage } from "assets/images";
import { Divider } from "elements/divider/Divider";
import { IconButton, InputBase } from "@mui/material";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { FullScreenSpinner } from "elements/spinner/FullScreenSpinner";
import { CustomButton } from "elements/button/Button";
import { usePreferencesAccountHook } from "./usePreferencesAccountHook";
import { resizeByResolutionWidth } from "helpers/utils.helper";
import { CountryCodePicker } from "components/country-code-picker/CountryCodePicker";
import { DeleteAccountWarning } from "./components/DeleteAccountWarning";
import ProfileDiamondIcon from "assets/images/profile_diamond.svg";
import { ChangePhoneNumberWarning } from "./components/ChnagePhoneNumberWarning";
import { SubmitPhoneVerification } from "helpers/common.helper";
import "./PreferencesAccount.scss";

export const PreferencesAccount = () => {
  const {
    fullname,
    email,
    loading,
    showSaveButton,
    saveLoading,
    setEmail,
    setFullname,
    logout,
    onPressSave,
    onPressCancel,
    setSelectedCountry,
    phone,
    onChangePhone,
    onPressDelete,
    onPressKeep,
    ref,
    isDeleteAccountWarningShown,
    isChangePhoneNumberWarningShown,
    onPressDontChangePhoneNumber,
    loginType,
    deleteAccount,
    setLoading,
    selectedCountry,
    navigate,
    isFreePlanShown,
    isPremiumWillEnd,
    endOfPremium,
    userInfo,
    onPressRedeemCode,
  } = usePreferencesAccountHook();
  const renderLeftItem = (
    <CountryCodePicker
      initialCountry={selectedCountry}
      onSelect={setSelectedCountry}
    />
  );
  return (
    <div style={styles.container} ref={ref} className="Windows">
      <div style={styles.profileRow}>
        <div style={styles.profileBody}>
          <div style={styles.profileTextContainer}>
            <Text style={styles.headerText}>{i18n.t("profile")}</Text>
            {isFreePlanShown ? (
              <div className="free-plan-text">{i18n.t("free_plan")}</div>
            ) : (
              <div style={styles.imgContainer}>
                <img src={ProfileDiamondIcon} alt="" />
              </div>
            )}
            {isPremiumWillEnd ? (
              <div className="premium-expires-on">
                {i18n.t("premium_expires_on", {
                  date: endOfPremium.toFormat("LLL dd"),
                })}
              </div>
            ) : null}
          </div>
          <TextInput
            onChangeText={setFullname}
            value={fullname}
            label={i18n.t("fullname")}
            style={styles.nameInputWrapper}
          />
        </div>
        <div style={styles.phoneNumberContainer}>
          {loginType === "google.com" ? null : loginType === "phone" ? (
            <Text style={styles.label}>{i18n.t("phone_number")}</Text>
          ) : null}
          {loginType === "google.com" ? (
            <TextInput
              onChangeText={setEmail}
              value={email}
              label={i18n.t("email_header")}
              style={styles.emailTextInput}
              disabled
              leftImage={GoogleCalendarImage}
            />
          ) : loginType === "phone" ? (
            <div style={styles.phoneNumberInput}>
              {renderLeftItem}
              <InputBase
                value={phone}
                style={styles.input}
                onChange={(event) => onChangePhone(event.target.value)}
              />
            </div>
          ) : (
            <TextInput
              onChangeText={setEmail}
              value={email}
              label={i18n.t("email_header")}
              style={styles.emailTextInput}
              disabled
            />
          )}
        </div>
        <div
          className={
            fullname || email
              ? "profile-circle-container"
              : "profile-circle-container-invisible"
          }
        >
          <p className="profile-circle-container-letter">
            {fullname
              ? fullname.charAt(0).toLocaleUpperCase()
              : email.charAt(0).toLocaleUpperCase()}
          </p>
        </div>
      </div>
      {isChangePhoneNumberWarningShown ? (
        <ChangePhoneNumberWarning
          onPressNo={onPressDontChangePhoneNumber}
          onPressYes={() =>
            SubmitPhoneVerification(
              "/change-phone-number",
              phone,
              setLoading,
              navigate,
              selectedCountry
            )
          }
        />
      ) : (
        <>
          <Divider color={palette.gray.light} />
          <div style={styles.rowContainer}>
            {loginType === "google.com" ? (
              <Text style={styles.rowText}>{i18n.t("phone_number")}</Text>
            ) : loginType === "phone" ? (
              <Text style={styles.rowText}>{i18n.t("email_header")}</Text>
            ) : (
              <Text style={styles.rowText}>{i18n.t("phone_number")}</Text>
            )}
            {loginType === "google.com" ? (
              <div style={styles.infoContainer}>
                <div style={styles.phoneNumberContainer}>
                  <Text style={styles.label}>{i18n.t("phone_number")}</Text>
                  <div style={styles.phoneNumberInput}>
                    {renderLeftItem}
                    <InputBase
                      value={phone}
                      style={styles.input}
                      onChange={(event) => onChangePhone(event.target.value)}
                    />
                  </div>
                </div>
              </div>
            ) : loginType === "phone" ? (
              <div style={styles.infoContainer}>
                <TextInput
                  onChangeText={setEmail}
                  value={email}
                  label={i18n.t("email_header")}
                  style={styles.emailTextInput}
                  disabled
                />
              </div>
            ) : (
              <div style={styles.infoContainer}>
                <div style={styles.phoneNumberInput}>
                  {renderLeftItem}
                  <InputBase
                    value={phone}
                    style={styles.input}
                    onChange={(event) => onChangePhone(event.target.value)}
                  />
                </div>
              </div>
            )}
          </div>
          {!userInfo?.attributes.is_lifetime ? (
            <>
              <Divider color={palette.gray.light} />
              <div style={styles.rowContainer}>
                <Text style={styles.rowText}>{i18n.t("redeem_code")}</Text>
                <IconButton onClick={onPressRedeemCode}>
                  <Icon width={20} height={20} name={IconName.REDEEM_CODE} />
                </IconButton>
              </div>
            </>
          ) : null}
          <Divider color={palette.gray.light} />
          <div style={styles.rowContainer}>
            <Text style={styles.rowText}>{i18n.t("logout")}</Text>
            <IconButton onClick={logout}>
              <Icon
                width={20}
                height={20}
                name={IconName.LOGOUT}
                onPress={logout}
              />
            </IconButton>
          </div>
          <Divider color={palette.gray.light} />
          <div style={styles.rowContainer}>
            <Text style={styles.rowText}>{i18n.t("delete_account")}</Text>
            <IconButton onClick={deleteAccount}>
              <Icon width={20} height={20} name={IconName.TRASH} />
            </IconButton>
          </div>
        </>
      )}
      {isDeleteAccountWarningShown ? (
        <DeleteAccountWarning
          onPressNo={onPressKeep}
          onPressYes={onPressDelete}
        />
      ) : null}
      {showSaveButton && !isChangePhoneNumberWarningShown ? (
        <div style={styles.rowContainer}>
          <CustomButton
            backgroundColor={palette.gray.ice}
            fullWidth
            text={i18n.t("cancel")}
            style={styles.cancelButton}
            textStyle={styles.cancelButtonText}
            onPress={onPressCancel}
          />
          <CustomButton
            backgroundColor={palette.black}
            fullWidth
            text={i18n.t("save")}
            loading={saveLoading}
            onPress={onPressSave}
          />
        </div>
      ) : null}
      <FullScreenSpinner loading={loading} />
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flex: 1,
    alignSelf: "stretch",
    flexDirection: "column",
    overflow: "scroll",
  },
  phoneNumberContainer: {
    flexDirection: "column",
    marginTop: "auto",
  },
  profileRow: {
    display: "flex",
    alignSelf: "stretch",
    justifyContent: "center",
    marginBottom: 30,
  },
  profileBody: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginTop: "auto",
  },
  profileImageContainer: {
    width: 76,
    height: 76,
    borderRadius: 38,
    backgroundColor: palette.violet.dark,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    marginLeft: 30,
    marginRight: 30,
  },
  profileImageAvatar: {
    width: 49,
    height: 68,
  },
  headerText: {
    fontWeight: "bold",
    fontSize: 18,
  },
  nameInputWrapper: {
    width: 180,
  },
  emailHeaderText: {
    fontWeight: "bold",
    fontSize: 18,
    marginBottom: 24,
    marginTop: 24,
  },
  rowContainer: {
    display: "flex",
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 20,
    paddingBottom: 20,
    minHeight: 76,
  },
  rowText: {
    fontSize: 15,
    flexDirection: "row",
    color: palette.gray.dark,
    width: 180,
  },
  emailTextInput: {
    width: 225,
  },
  cancelButtonText: {
    color: palette.gray.medium,
  },
  cancelButton: {
    marginRight: resizeByResolutionWidth(20),
  },
  label: {
    fontFamily: "Gilroy-Semibold",
    color: palette.gray.medium,
    fontSize: 10,
  },
  phoneNumberInput: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 34,
    borderRadius: 10,
    border: `1px solid ${palette.gray.light}`,
    backgroundColor: palette.gray.iceLight,
    marginTop: 4,
    paddingLeft: 10,
    paddingRight: 10,
    width: 225,
  },
  imgContainer: {
    width: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  profileTextContainer: {
    display: "flex",
    gap: 15,
    alignItems: "center",
    marginBottom: 10,
  },
  infoContainer: {
    marginLeft: 65,
    marginRight: "auto",
  },
};
