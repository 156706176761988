import { palette } from "assets/palette";
import { Text } from "elements/text/Text";
import { FC, useEffect } from "react";
import { DropTargetMonitor, useDrop } from "react-dnd";
import { TimelineListItem } from "../TimelineListItem/TimelineListItem";
import { IEvent, ITimelineItem } from "../../types";
import { DateTime } from "luxon";
import { DRAWER_WIDTH } from "pages/private/home/constants";
import { DragAndDropHelper } from "helpers/dnd.helper";
import { is12hTextPresent } from "helpers/utils.helper";

export interface BoardSquareProps {
  exact?: boolean;
  item: ITimelineItem;
  showBoardItem?: boolean;
  onDrop: (item: ITimelineItem) => void;
  handleOnDraging: (event: IEvent) => void;
  events: IEvent[];
  onHoverTimeBox: () => void;
}

const TIME_HEIGHT = 15;

export const TimeBox: FC<BoardSquareProps> = ({
  item,
  exact,
  onDrop,
  handleOnDraging,
  events,
  onHoverTimeBox,
}: BoardSquareProps) => {
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: "board_item",
      drop: () => {
        onDrop(item);
      },
      collect: (monitor: DropTargetMonitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    []
  );

  const getNowLinePosition = () => {
    const now = DateTime.now();
    const currentTime = item.time;
    const itemMinuteAdded = currentTime.plus({ minutes: 15 });
    if (
      now.toSeconds() >= currentTime.toSeconds() &&
      now.toSeconds() < itemMinuteAdded.toSeconds()
    ) {
      const diff = now.diff(currentTime, ["minutes"]);
      const marginTop = (diff.minutes * TIME_HEIGHT) / 15;
      return marginTop;
    }
    return -1;
  };

  const determinetimeEvents = () => {
    const timeEvents: IEvent[] = [];
    events.forEach((eventItem) => {
      const itemMinuteAdded = item.time.plus({ minutes: 15 });
      if (
        eventItem.start.toMillis() >= item.time.toMillis() &&
        eventItem.start.toMillis() < itemMinuteAdded.toMillis()
      ) {
        timeEvents.push(eventItem);
      }
    });
    return timeEvents;
  };

  useEffect(() => {
    DragAndDropHelper.setIsOverOnOnRightDrawer(isOver);
  }, [isOver]);

  const thisTimeEvents = determinetimeEvents();

  const isActive = isOver && canDrop;
  let backgroundColor = undefined;
  if (isActive) {
    backgroundColor = palette.orange.light;
  }

  const marginForNowLine = getNowLinePosition();

  const eventContainerStyle = {
    display: "inline-block",
    width: `calc(100% / ${thisTimeEvents.length})`,
    zIndex: 1
  };

  useEffect(() => {
    if (isActive) {
      onHoverTimeBox();
    }
  }, [isActive]);

  return (
    <div ref={drop}>
      <div style={{ ...styles.time, backgroundColor }}>
        {exact ? (
          <div style={styles.timeDividerWrapper}>
            <Text style={styles.timeText}>
              {item.time.toLocaleString(DateTime.TIME_SIMPLE)}
            </Text>
            <div style={styles.divider} />
          </div>
        ) : null}
        <div
          style={{
            ...styles.boxWrapper,
            marginTop: exact ? -10 : 0,
            marginLeft: is12hTextPresent(item.time) ? 15 : undefined,
          }}
        >
          {thisTimeEvents.map((event, index) => (
            <div style={eventContainerStyle} key={index}>
              <TimelineListItem
                index={index}
                onDraging={handleOnDraging}
                event={event}
              />
            </div>
          ))}
        </div>
        {marginForNowLine >= 0 ? (
          <div style={{ ...styles.nowLineContainer, top: marginForNowLine }}>
            <Text style={styles.nowTimeText}>
              {DateTime.now().toLocaleString(DateTime.TIME_SIMPLE)}
            </Text>
            <div style={styles.ball} />
            <div style={styles.line} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "stretch",
    paddingTop: 20,
  },
  time: {
    height: TIME_HEIGHT,
    display: "flex",
    alignSelf: "stretch",
    paddingRight: 20,
    flexDirection: "column",
    position: "relative",
  },
  timeText: {
    color: palette.gray.medium,
    fontSize: 12,
    fontFamily: "Gilroy-Semibold",
    fontWeight: "500",
    marginTop: -8,
  },
  timeDividerWrapper: {
    display: "flex",
    alignSelf: "stretch",
    flexDirection: "row",
    flex: 1,
    paddingLeft: 20,
  },
  divider: {
    display: "flex",
    flex: 1,
    alignSelf: "stretch",
    height: 1,
    backgroundColor: palette.gray.light,
    marginLeft: 10,
  },
  boxWrapper: {
    flex: 1,
    display: "flex",
  },
  nowLineContainer: {
    position: "absolute",
    width: DRAWER_WIDTH - 25,
    display: "flex",
    flexDirection: "row",
    marginLeft: 25,
  },
  nowTimeText: {
    fontSize: 12,
    color: palette.red.light,
    marginTop: -6,
    marginRight: 10,
    fontWeight: "600",
  },
  line: {
    display: "flex",
    flex: 1,
    height: 1,
    backgroundColor: palette.red.light,
  },
  ball: {
    width: 6,
    height: 6,
    borderRadius: 3,
    backgroundColor: palette.red.light,
    marginTop: -2,
  },
};
