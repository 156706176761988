import { AchievementMountainImage } from "assets/images";
import { resizeByResolutionWidth } from "helpers/utils.helper";
import { i18n } from "constants/i18n.constants";
import "./AchievementModal.scss";

interface Props {
  task_zero_days?: string | number;
  current_streak?: string | number;
  longest_streak?: number;
  ref?: any;
}

export const AchievementModal = ({
  task_zero_days,
  current_streak,
  longest_streak,
  ref
}: Props) => {
  return (
      <div className="achievement-modal-container" ref={ref}>
        <div className="first-elem">
          <div className="first-elem-top">
            <div className="mys-sen-title-container">
              <p className="achievement-title">{i18n.t("achievements")}</p>
              <img src={AchievementMountainImage} alt="achievement-mountain" width={resizeByResolutionWidth(85)} height={resizeByResolutionWidth(77)} />
            </div>
          </div>
          <div className="first-elem-bottom-container">
            <div className="first-elem-bottom">
              <div className="left-container">
                <div className="week-number">{current_streak ? current_streak : 0}</div>
                <div className="week-text">{i18n.t("weeks").toLocaleUpperCase()}</div>
              </div>
              <div className="right-container">
                <div className="streak-text">{i18n.t("current_streak")}</div>
                <div className="streak-details">{i18n.t("current_streak_text", { week: `${current_streak ? current_streak : 0}` })}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="second-elem">
          <div className="left-container">
            <div className="week-number">{longest_streak ? longest_streak : 0}</div>
            <div className="week-text">{i18n.t("weeks").toLocaleUpperCase()}</div>
          </div>
          <div className="right-container">
            <div className="streak-text">{i18n.t("longest_streak")}</div>
            <div className="streak-details">{i18n.t("longest_streak_text", { week: `${longest_streak ? longest_streak : 0}` })}</div>
          </div>
        </div>
        <div className="third-elem">
          <div className="left-container">
            <div className="week-number">{task_zero_days ? task_zero_days : 0}</div>
            <div className="week-text">{i18n.t("days").toLocaleUpperCase()}</div>
          </div>
          <div className="right-container">
            <div className="streak-text">{i18n.t("task_zero")}</div>
            <div className="streak-details">{i18n.t("task_zero_text", { day: `${task_zero_days ? task_zero_days : 0}` })}</div>
          </div>
        </div>
      </div>
  );
};
