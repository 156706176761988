import * as React from "react";
import { palette } from "assets/palette";
import { i18n } from "constants/i18n.constants";
import { Text } from "elements/text/Text";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { CustomTooltip } from "components/custom-tooltip/CustomTooltip";
import { Spinner } from "elements/spinner/Spinner";
import { resizeByResolutionWidth } from "helpers/utils.helper";
import { TimelineListItem } from "../../TimelineListItem/TimelineListItem";
import { DateTime } from "luxon";
import { TaskEntity } from "communicators/resources/event-resources/tasks/tasks.type";
import { AllBoardDataEntityType, IBoard } from "interfaces/main.interfaces";
import { EventType } from "communicators/resources/event-resources/event-resources.type";

interface Props {
  closeDrawer: () => void;
  onPressPlus: (date: DateTime) => void;
  onTaskCreate: (
    index: number,
    boardDate: DateTime,
    createdTask: TaskEntity,
    isNewTask: boolean
  ) => void;
  inboxVisibleData: IBoard[];
  previousMonth: () => void;
  nextMonth: () => void;
  inboxStartDate: DateTime;
  inboxLoading: boolean;
  onClickCheckBox: (data: AllBoardDataEntityType) => void;
  deleteTask?: (data: AllBoardDataEntityType) => void;
}

export const InboxTasks = ({
  closeDrawer,
  onPressPlus,
  onTaskCreate,
  inboxVisibleData,
  previousMonth,
  nextMonth,
  inboxStartDate,
  inboxLoading,
  onClickCheckBox,
  deleteTask,
}: Props) => {
  return (
    <>
      <div style={styles.secondDrawerHeaderContainer}>
        <CustomTooltip
          description={i18n.t("inbox_tasks_drawer_tooltip")}
          direction="top-start"
        >
          <div>
            <Text style={styles.secondDrawerHeader}>
              {i18n.t("inbox_tasks")}
            </Text>
          </div>
        </CustomTooltip>
        <Icon
          width={resizeByResolutionWidth(30)}
          height={resizeByResolutionWidth(30)}
          name={IconName.PLUS}
          fill={palette.white}
          onPress={() => onPressPlus(inboxStartDate)}
        />
        <Icon
          width={resizeByResolutionWidth(15)}
          height={resizeByResolutionWidth(30)}
          name={IconName.LEFT_DRAWER_BIG_BACK}
          onPress={closeDrawer}
        />
      </div>
      <div style={styles.monthContainer}>
        <Text style={styles.dateText}>
          {inboxStartDate.toFormat("LLLL yyyy")}
        </Text>
        <div style={styles.secondDrawerHeaderContainer}>
          <Icon
            name={IconName.CALENDAR_BACK}
            width={resizeByResolutionWidth(8)}
            height={resizeByResolutionWidth(13)}
            onPress={previousMonth}
          />
          <Icon
            name={IconName.CALENDAR_FORWARD}
            width={resizeByResolutionWidth(8)}
            height={resizeByResolutionWidth(13)}
            style={styles.forwardIcon}
            onPress={nextMonth}
          />
        </div>
      </div>
      <div style={styles.scrollContainer}>
        {inboxLoading ? (
          <Spinner
            style={styles.spinner}
            size={50}
            color={"primary"}
            loading={inboxLoading}
          />
        ) : (
          inboxVisibleData.map((item, index) => {
            return (
              <div
                style={styles.itemContainer}
                key={`${item}${index}`}
                id={item?.date?.toSQLDate()!}
              >
                <Text style={styles.dateText}>
                  {item.date.toFormat("EEEE, d")}
                </Text>
                {item.data.map((card, index) => {
                  if (card.type !== EventType.TASK) return null;
                  return (
                    <TimelineListItem
                      boardId={card.id}
                      key={card.id}
                      index={index}
                      data={card}
                      onTaskCreate={(
                        createdTask: TaskEntity,
                        isNewTask: boolean
                      ) =>
                        onTaskCreate(
                          index,
                          inboxVisibleData[index].date,
                          createdTask,
                          isNewTask
                        )
                      }
                      onClickCheckBox={onClickCheckBox}
                      deleteTask={deleteTask}
                    />
                  );
                })}
              </div>
            );
          })
        )}
      </div>
    </>
  );
};

const styles: Record<string, React.CSSProperties> = {
  secondDrawerHeaderContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  secondDrawerHeader: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 20,
    color: palette.black,
    textAlign: "left",
  },
  dateText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 18,
    color: palette.gray.dark,
    textAlign: "left",
    marginBottom: 5,
  },
  monthContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 35,
    marginBottom: 15,
  },
  forwardIcon: {
    marginLeft: 35,
  },
  spinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  scrollContainer: {
    overflowY: "scroll",
    overflow: "visible",
  },
  headerIconContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    flex: 1,
    justifyContent: "flex-end",
  },
  itemContainer: {
    paddingLeft: 2,
    paddingRight: 2,
  },
};
