import { palette } from "assets/palette";
import { i18n } from "constants/i18n.constants";
import { CustomButton } from "elements/button/Button";
import React from "react";
import { Text } from "elements/text/Text";
import { BillingCycle, useChangeBillingCycleHook } from "./useChangeBillingCycleHook";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { resizeByResolutionWidth } from "helpers/utils.helper";

export const PreferencesChangeBillingCycle = () => {
  const {
    monthlyPrice,
    yearlyPrice,
    onSelectBillingCycle,
    billingCycle,
    goBack,
    onPressContinue
  } = useChangeBillingCycleHook();

  return (
    <div style={styles.container}>
      <div style={styles.headerContainer}>
        <Icon name={IconName.BACK_ARROW} onPress={goBack} />
        <Text style={styles.headerText}>{i18n.t("change_your_billing_cycle")}</Text>
      </div>
      <Text style={styles.subHeaderText}>{i18n.t("change_your_billing_cycle_subheader")}</Text>
      <div style={billingCycle === BillingCycle.YEARLY ? styles.selectedBillingCycleContainer : styles.unselectedBillingCycleContainer} onClick={() => onSelectBillingCycle(BillingCycle.YEARLY)}>
        <div style={styles.billingCyclePlanContainer}>
          <div
            style={billingCycle === BillingCycle.YEARLY ? styles.checkedContainer : styles.unCheckedContainer}
          >
            {billingCycle === BillingCycle.YEARLY ? (
              <Icon name={IconName.TICK} width={10} fill={palette.white} />
            ) : null}
          </div>
          <Text style={styles.billingCycleText}>{i18n.t("yearly")}</Text>
        </div>
        <Text style={styles.description}>{i18n.t("yearly_billing_cycle", {price: `$${yearlyPrice}`})}</Text>
        {billingCycle === BillingCycle.YEARLY ? <div style={styles.save}>
                <Text style={styles.saveText}>{i18n.t("save_amount", {savedAmount: `${58}`})}</Text>
            </div> : <div style={styles.empty}/>}
      </div>
      <div style={billingCycle === BillingCycle.MONTHLY ? styles.selectedBillingCycleContainer : styles.unselectedBillingCycleContainer} onClick={() => onSelectBillingCycle(BillingCycle.MONTHLY)}>
        <div style={styles.billingCyclePlanContainer}>
          <div
            style={billingCycle === BillingCycle.MONTHLY ? styles.checkedContainer : styles.unCheckedContainer}
          >
            {billingCycle === BillingCycle.MONTHLY ? (
              <Icon name={IconName.TICK} width={10} fill={palette.white} />
            ) : null}
          </div>
          <Text style={styles.billingCycleText}>{i18n.t("monthly")}</Text>
        </div>
        <Text style={styles.description}>{i18n.t("monthly_billing_cycle", {price: `$${monthlyPrice}`})}</Text>
        <div style={styles.empty}/>
      </div>
      <CustomButton style={styles.continue} text={i18n.t("continue")} textStyle={styles.buttonText} onClick={onPressContinue} loading={undefined} />
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flex: 1,
    alignSelf: "stretch",
    flexDirection: "column",
    paddingRight: 50,
    marginLeft: -12,
    overflowY: "scroll"
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  billingCyclePlanContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "15%",
  },
  headerText: {
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "500",
    fontSize: 20,
    color: palette.black,
  },
  subHeaderText: {
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "400",
    fontSize: 15,
    color: palette.gray.extraLight,
    marginLeft: 52,
    marginBottom: 25
  },
  continue: {
    width: "100%",
    backgroundColor: palette.black,
    height: 50,
    marginBottom: 50,
    marginTop: "auto"
  },
  buttonText: {
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "500",
    fontSize: 15,
    color: palette.white,
  },
  selectedBillingCycleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: 50,
    backgroundColor: palette.gray.iceLight,
    border: `1px solid ${palette.blue.lighter}`,
    borderRadius: 7,
    marginBottom: 15,
    marginLeft: 15,
    paddingLeft: 15,
    paddingRight: 15
  },
  unselectedBillingCycleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: 50,
    backgroundColor: palette.gray.iceLight,
    borderRadius: 7,
    marginBottom: 15,
    marginLeft: 15,
    paddingLeft: 15,
    paddingRight: 15
  },
  billingCycleText: {
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "500",
    fontSize: 18,
    color: palette.gray.dark,
  },
  description: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 18,
    color: palette.gray.dark,
    textAlign: "left",
    width: "60%",
  },
  unCheckedContainer: {
    width: resizeByResolutionWidth(20),
    height: resizeByResolutionWidth(20),
    borderRadius: resizeByResolutionWidth(15),
    border: `1px solid ${palette.gray.dark}`,
    marginRight: 15
  },
  checkedContainer: {
    width: resizeByResolutionWidth(20),
    height: resizeByResolutionWidth(20),
    borderRadius: resizeByResolutionWidth(15),
    backgroundColor: palette.blue.lighter,
    border: `1px solid ${palette.blue.lighter}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 15
  },
  save: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: `${palette.green.medium}80`,
    borderRadius: 5,
    padding: 5,
  },
  saveText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 15,
    color: palette.green.dark
  },
  empty: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: palette.gray.iceLight,
    borderRadius: 5,
    padding: 2,
    width: "10%",
  }
};
