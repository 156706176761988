import { useEffect } from "react";
import { setCalendarData } from "redux-store/actions/user-settings.action";
import { useAppDispatch, useAppSelector } from "redux-store/store.hooks";

export enum AccessRole {
  OWNER = "owner"
}

export const useCalendarSelectionHook = () => {
  const { isGoogleIntegrationOpened, calendars } = useAppSelector((state) => ({
    isGoogleIntegrationOpened: !!state.thirdPartyProviderData.googleId,
    calendars: state.userSetting.calendarData
  }));
  const dispatch = useAppDispatch();
  const calendarIDs = calendars.filter(item => item.access_role === AccessRole.OWNER);
  useEffect(() => {
    dispatch(setCalendarData());
  });
  return { isGoogleIntegrationOpened, calendarIDs };
};