import { InvoicesResource } from "communicators/resources/invoices/invoices.resource";
import { InvoiceEntity } from "communicators/resources/invoices/invoices.types";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { i18n } from "constants/i18n.constants";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux-store/store.hooks";

export const usePreferencesBillingHistoryHook = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [invoices, setInvoices] = useState<InvoiceEntity[]>([]);
  const [isAppleHistory, setIsAppleHistory] = useState<boolean>(false);
  const { userInfo, subscriptions } = useAppSelector((state) => ({
    userInfo: state.userSetting.userInfo,
    subscriptions: state.userSetting.subscriptions
  }));

  const activeSubscriptions = subscriptions.filter((item) => (item.attributes.status === "active"));

  const getInvoices = async () => {
    setLoading(true);
    try {
      const result = await new InvoicesResource().readMany();
      if (result && result.data?.length > 0) {
        setInvoices(result.data);
        setIsAppleHistory(false);
      }
      else if (result && result.data?.length === 0 && userInfo?.attributes?.is_premium) {
        setIsAppleHistory(true);
      }
    } catch (_) {
    }
    setLoading(false);
  };

  const onPressItemDownload = (item: string) => {
    window.open(item);
  };

  useEffect(() => {
    getInvoices();
  }, []);

  const visibleInvoices = invoices.filter((item) => item?.attributes?.paid).map((item) => ({
    id: item?.attributes?.id,
    reference: item?.attributes?.id,
    date: DateTime.fromISO(item?.attributes?.created_at).toSQLDate(),
    description: item?.attributes?.plan,
    paymentMethodCardType: item?.attributes?.charge !== null ? item?.attributes?.charge?.payment_method_details?.card?.brand : "",
    paymentMethodCardNumber: item?.attributes?.charge !== null ? item?.attributes?.charge?.payment_method_details?.card?.last4 : "",
    amount: `USD ${(item?.attributes?.amount_due / 100).toFixed(2)}`,
    amountInt: item?.attributes?.amount_due,
    data: item,
  }));

  const tableHeaderTexts = [i18n.t("date_invoice"), i18n.t("description"), i18n.t("payment_method"), i18n.t("total")];

  const upgradeNow = () => {
    navigate("/checkout", {
      state: "inside_app",
    });
  }

  return { loading, visibleInvoices, onPressItemDownload, tableHeaderTexts, invoices, upgradeNow, isAppleHistory, activeSubscriptions, userInfo };
};
