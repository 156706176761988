import {
  AsanaImage,
  ClickupImage,
  GoogleCalendarImage,
  HubspotImage,
  JiraImage,
  NotionImage,
  OutlookImage,
  TodoistImage,
} from "assets/images";
import { ThirdPartyInformationResource } from "communicators/resources/third-party-information/third-party-information.resource";
import { i18n } from "constants/i18n.constants";
import {
  makeAsanaIntegration,
  makeTodoistIntegration,
} from "helpers/third-party.helper";
import { useUserSettingHook } from "hooks/useUserSettingHook";
import { ThirdPartyProvider } from "interfaces/main.interfaces";
import { useState } from "react";
import { setThirdPartyProviderData } from "redux-store/actions/third-party-provider.actions";
import { useAppDispatch, useAppSelector } from "redux-store/store.hooks";
import { CalendarAccountType } from "../../useHomeHook";
import { setCalendarData } from "redux-store/actions/user-settings.action";

interface Props {
  refreshBoards: () => void;
  googleSignIn: any;
  googleAuthLoading: boolean;
}

export const usePreferencesIntegrationsHook = ({
  refreshBoards,
  googleSignIn,
  googleAuthLoading,
}: Props) => {
  const [removeLoading, setRemoveLoading] = useState(false);
  const [deletingProvider, setDeletingProvider] =
    useState<ThirdPartyProvider | null>(null);

  const dispatch = useAppDispatch();

  const { setAndGetUserPreferedCalendar } = useUserSettingHook();

  const { googleId, asanaId, todoistId } = useAppSelector((state) => ({
    googleId: state.thirdPartyProviderData.googleId,
    asanaId: state.thirdPartyProviderData.asanaId,
    todoistId: state.thirdPartyProviderData.todoistId,
  }));

  const getProviderId = (provider: ThirdPartyProvider) => {
    switch (provider) {
      case ThirdPartyProvider.ASANA:
        return asanaId;
      case ThirdPartyProvider.GOOGLE:
        return googleId;
      case ThirdPartyProvider.TODOIST:
        return todoistId;
      default:
        return null;
    }
  };

  const onPressYesDelete = async () => {
    if (!deletingProvider) return;
    const providerId = getProviderId(deletingProvider);
    if (!providerId) return;
    setDeletingProvider(null);
    setRemoveLoading(true);
    await new ThirdPartyInformationResource().removeOne(providerId);
    if (deletingProvider === ThirdPartyProvider.GOOGLE) {
      await setAndGetUserPreferedCalendar(CalendarAccountType.INSUMO);
      dispatch(setCalendarData());
    }
    refreshBoards();
    dispatch(setThirdPartyProviderData());
    setRemoveLoading(false);
  };

  const onPressNoKeep = () => {
    setDeletingProvider(null);
  };

  const onPressGoogleIntegration = async () => {
    if (!googleId || googleId === "") {
      googleSignIn();
      return;
    }
    setDeletingProvider(ThirdPartyProvider.GOOGLE);
  };

  const onPressTodoistIntegration = () => {
    if (!todoistId) {
      makeTodoistIntegration();
      return;
    }
    setDeletingProvider(ThirdPartyProvider.TODOIST);
  };

  const onPressAsanaIntegration = () => {
    if (!asanaId) {
      makeAsanaIntegration();
      return;
    }
    setDeletingProvider(ThirdPartyProvider.ASANA);
  };

  const INTEGRATIONS_LIST = [
    {
      key: "google",
      text: i18n.t("google_calendar"),
      image: GoogleCalendarImage,
      onPress: onPressGoogleIntegration,
      isActive: !!googleId,
    },
    {
      key: "asana",
      text: i18n.t("asana"),
      image: AsanaImage,
      onPress: onPressAsanaIntegration,
      isActive: !!asanaId,
    },
    {
      key: "todoist",
      text: i18n.t("todoist"),
      image: TodoistImage,
      onPress: onPressTodoistIntegration,
      isActive: !!todoistId,
    },
    {
      key: "jira",
      text: i18n.t("jira"),
      image: JiraImage,
      onPress: undefined,
      isActive: undefined,
      isComingSoon: true,
    },
    {
      key: "notion",
      text: i18n.t("notion"),
      image: NotionImage,
      onPress: undefined,
      isActive: undefined,
      isComingSoon: true,
    },
    {
      key: "outlook",
      text: i18n.t("outlook"),
      image: OutlookImage,
      onPress: undefined,
      isActive: undefined,
      isComingSoon: true,
    },
    {
      key: "hubspot",
      text: i18n.t("hubspot"),
      image: HubspotImage,
      onPress: undefined,
      isActive: undefined,
      isComingSoon: true,
    },
    {
      key: "clickup",
      text: i18n.t("clickup"),
      image: ClickupImage,
      onPress: undefined,
      isActive: undefined,
      isComingSoon: true,
    },
  ];

  const loading = googleAuthLoading || removeLoading;
  return {
    INTEGRATIONS_LIST,
    loading,
    deletingProvider,
    onPressNoKeep,
    onPressYesDelete,
  };
};
