import { DefaultAPIError } from "./default.error";
import { ErrorCodes } from "./type";
import { CardAlreadyExistsError, UnauthorizedError } from "./unauthorized.error";
import { ResourceName } from "communicators/types";
import { InsumoAPIError } from "./insumo.error";
import { UnprocessableAPIError } from "./unprocessable.error";

export interface ErrorDictionary {
	[code: number]: typeof InsumoAPIError;
	DEFAULT: typeof DefaultAPIError;
}

export const INSUMO_ERRORS: ErrorDictionary = {
	[ErrorCodes.UNAUTHORIZED]: UnauthorizedError,
	[ErrorCodes.CARD_ALREADY_EXISTS]: CardAlreadyExistsError,
	[ErrorCodes.UNPROCESSABLE]: UnprocessableAPIError,
	DEFAULT: DefaultAPIError
};

export const INSUMO_RESOURCE_ERRORS: Partial<{
	[T in ResourceName]: Omit<ErrorDictionary, "DEFAULT">;
}> = {};
