import { palette } from "assets/palette";
import { LoginWrapper } from "components/login-wrapper/LoginWrapper";
import { SubscriptionPeriodSelection } from "components/subscription-period-selection/SubscriptionPeriodSelection";
import { SubscriptionPlan } from "components/subscription-period-selection/SubscriptionPeriodSelection.type";
import { i18n } from "constants/i18n.constants";
import { CustomButton } from "elements/button/Button";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { ProgressBar } from "elements/progress-bar/ProgressBar";
import { Text } from "elements/text/Text";
import React from "react";
import { useSubscriptionSelection } from "./useSubscriptionSelection";
import { usePageHook } from "hooks/usePageHook";

export const SubscriptionSelection = () => {
  const {
    enableFreeTrialSelected,
    selectedPlan,
    loading,
    setSelectedPlan,
    onPressNext,
    onPressFreeTrial,
    onPressSubscribe,
    onPressFreeTrialCheck,
    monthlyPrice,
    yearlyPrice,
  } = useSubscriptionSelection();
  usePageHook();
  return (
    <LoginWrapper
      showBottom={false}
      title={i18n.t(
        enableFreeTrialSelected
          ? "no_credit_card_needed_title"
          : "what_you_get_premium"
      )}
      headerRightText={i18n.t("next")}
      onPressHeaderRightText={onPressNext}
    >
      {!enableFreeTrialSelected ? (
        <div style={styles.propertiesWrapper}>
          {Array.from(i18n.t("subscription_features")).map(
            (item: string, index) => {
              return (
                <div style={styles.featuresWrapper} key={index}>
                  <Icon name={IconName.SUBSCRIPTION_CHECK} />
                  <div style={styles.verticalGap} />
                  <Text style={styles.subscriptionFeatureText}>{item}</Text>
                  <div style={styles.verticalGap} />
                </div>
              );
            }
          )}
        </div>
      ) : null}

      {enableFreeTrialSelected ? (
        <>
          <div style={styles.progressTitleWrapper}>
            <Text
              style={{
                ...styles.progressUpperText,
                ...styles.trialStartsDayText,
              }}
            >
              {i18n.t("trial_starts_today")}
            </Text>
            <Text style={styles.progressUpperText}>
              {i18n.t("trial_ends_in", { days: "14" })}
            </Text>
          </div>
          <ProgressBar
            style={styles.progress}
            value={2}
            progressProps={{ color: "info" }}
          />
        </>
      ) : null}
      <SubscriptionPeriodSelection
        selectedPlan={selectedPlan}
        onSelectPlan={setSelectedPlan}
      />
      <Text style={styles.secondarySubtitle}>
        {enableFreeTrialSelected
          ? i18n.t("days_free_then_price", {
              price:
                selectedPlan === SubscriptionPlan.MONTHLY
                  ? monthlyPrice
                  : yearlyPrice,
              day: 14,
            })
          : i18n.t("per_month", {
              price:
                selectedPlan === SubscriptionPlan.MONTHLY
                  ? monthlyPrice
                  : yearlyPrice,
            })}
      </Text>
      <CustomButton
        style={{
          ...styles.subscribe,
          backgroundColor: enableFreeTrialSelected
            ? palette.orange.dark
            : palette.black,
        }}
        text={i18n.t(
          enableFreeTrialSelected ? "start_free_trial" : "subscribe"
        )}
        onPress={enableFreeTrialSelected ? onPressFreeTrial : onPressSubscribe}
        loading={loading}
      />
      <div style={styles.gap} />
      <CustomButton
        style={styles.startFreeTrial}
        textStyle={styles.startFreeTrialText}
        onPress={onPressFreeTrialCheck}
        text={i18n.t(
          enableFreeTrialSelected ? "free_trial_enabled" : "not_sure_yet"
        )}
        showCheckbox
        checked={enableFreeTrialSelected}
        disabled={loading}
      />
    </LoginWrapper>
  );
};

const styles: Record<string, React.CSSProperties> = {
  secondarySubtitle: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 25,
    color: palette.black,
    marginBottom: 35,
    marginTop: 15,
  },
  progress: {
    marginTop: 20,
    marginBottom: 30,
    width: "100%",
  },
  subscribe: {
    width: 500,
    backgroundColor: palette.black,
    height: 50,
  },
  startFreeTrial: {
    width: 500,
    backgroundColor: palette.gray.iceLight,
    height: 50,
    marginBottom: 60,
  },
  startFreeTrialText: {
    color: palette.gray.medium,
  },
  gap: {
    height: 20,
  },
  verticalGap: {
    width: 20,
  },
  subscriptionFeatureText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 18,
    color: palette.gray.dark,
  },
  featuresWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 30,
  },
  progressTitleWrapper: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "row",
    marginTop: 25,
  },
  trialStartsDayText: {
    display: "flex",
    flex: 1,
  },
  progressUpperText: {
    fontWeight: "500",
    fontFamily: "Gilroy-Semibold",
    color: palette.gray.medium,
  },
  propertiesWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    marginTop: 15,
    marginBottom: 30,
  },
};
