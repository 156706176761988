import { ZeroTaskEntity } from "communicators/resources/zero-task/zero-task.type";
import { DateTime } from "luxon";
import { useCallback, useEffect, useRef, useState } from "react";

interface Props {
  isVisible?: boolean;
  closeModal?: () => void;
  streakInfo?: ZeroTaskEntity;
}

export const useStreakModalHook = ({
  closeModal,
  isVisible,
  streakInfo,
}: Props) => {
  const [isAchievementModalVisible, setIsAchievementModalVisible] =
    useState<boolean>(false);
  const [isDetailsFirstShown, setIsDetailsFirstShown] = useState<boolean>(true);

  const task_zero_days = streakInfo?.zero_day_count
    ? streakInfo?.zero_day_count
    : 0;
  const current_streak =
    streakInfo?.active_streak === null ? 1 : streakInfo?.active_streak?.length!;
  const longest_streak =
    streakInfo?.longest_streak === null
      ? 1
      : streakInfo?.longest_streak?.length!;

  const streak_modal_center_image = document.querySelector<HTMLElement>(
    ".streak-modal-center-image"
  )!;
  const streak_modal_center_prev_text = document.querySelector<HTMLElement>(
    ".streak-modal-center-prev-text-invisible"
  )!;
  const streak_modal_center_next_text = document.querySelector<HTMLElement>(
    ".streak-modal-center-next-text-invisible"
  )!;
  const rolltext = document.querySelector<HTMLElement>(
    ".streak-modal-center-text-animation-container-rolltext"
  )!;
  const image_key_frames = [{ top: 0 }, { top: -196 }];
  const prev_text_key_frames = [{ opacity: "0" }, { opacity: "1" }];
  const timing_options = {
    duration: 3000,
    iterations: 1,
  };
  const prev_text_timing_options = {
    duration: 3000,
    iterations: 1,
  };

  setTimeout(() => {
    rolltext?.classList?.remove(
      "streak-modal-center-text-animation-container-rolltext"
    );
    streak_modal_center_image
      ?.animate(image_key_frames, timing_options)
      .finished.then(() => {
        streak_modal_center_image.style.display = "none";
      });
    streak_modal_center_prev_text
      ?.animate(prev_text_key_frames, prev_text_timing_options)
      .finished.then(() => {
        streak_modal_center_prev_text?.classList?.remove(
          "streak-modal-center-prev-text-invisible"
        );
        streak_modal_center_prev_text?.classList?.add(
          "streak-modal-center-prev-text"
        );
        streak_modal_center_next_text?.classList?.remove(
          "streak-modal-center-next-text-invisible"
        );
        streak_modal_center_next_text?.classList?.add(
          "streak-modal-center-next-text"
        );
        rolltext?.classList?.add(
          "streak-modal-center-text-animation-container-rolltext"
        );
      });
  }, 0);

  const ref = useRef<any>();

  const useOutsideClick = () => {
    const handleClick = useCallback((event: { target: any }) => {
      if (!ref?.current?.contains(event.target)) {
        setIsDetailsFirstShown(true);
        setIsAchievementModalVisible(false);
        closeModal?.();
      } else {
        setIsDetailsFirstShown(false);
        setIsAchievementModalVisible((prev) => !prev);
      }
    }, []);

    useEffect(() => {
      document.addEventListener("mouseup", handleClick);

      return () => {
        document.removeEventListener("mouseup", handleClick);
      };
    }, [handleClick]);
  };
  useOutsideClick();

  useEffect(() => {
    if (!isVisible) {
      setIsDetailsFirstShown(true);
      setIsAchievementModalVisible(false);
    }
  }, [isVisible]);

  return {
    ref,
    isAchievementModalVisible,
    task_zero_days,
    longest_streak,
    current_streak,
    isDetailsFirstShown,
  };
};
