/* eslint-disable react-hooks/exhaustive-deps */
import { TasksResource } from "communicators/resources/event-resources/tasks/tasks.resource";
import { ThirdPartyInformationResource } from "communicators/resources/third-party-information/third-party-information.resource";
import { LocalStorageParam } from "constants/local-storage.constant";
import { FullScreenSpinner } from "elements/spinner/FullScreenSpinner";
import {
  ASANA_ONBOARDING_STATE,
  ASANA_REDIRECT_URL,
} from "helpers/third-party.helper";
import { sleep } from "helpers/utils.helper";
import { usePageHook } from "hooks/usePageHook";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setThirdPartyProviderData } from "redux-store/actions/third-party-provider.actions";
import {
  setFirebaseUuid,
  setUserBearerToken,
} from "redux-store/actions/user-settings.action";
import { useAppDispatch } from "redux-store/store.hooks";

export const AsanaCallback: React.FC = (): JSX.Element => {
  const [loading] = useState(true);
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const state = searchParams.get("state");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const registerCode = async (code: string) => {
    await sleep(1000);
    const result =
      await new ThirdPartyInformationResource().createAsanaProvider(
        code,
        ASANA_REDIRECT_URL
      );
    if (result) {
      for (let i = 0; i < 3; i++) {
        await sleep(1000);
        try {
          const tasks = await new TasksResource().readMany();
          if (tasks.data.length > 0) {
            break;
          }
        } catch (_) {}
      }
    }
    dispatch(setThirdPartyProviderData());
    if (state === ASANA_ONBOARDING_STATE) {
      setTimeout(() => {
        navigate("/onboarding-integration");
      }, 500);
      return;
    }
    navigate("/");
  };

  useEffect(() => {
    if (code && state) {
      const firebaseUid = localStorage.getItem(LocalStorageParam.FIREBASE_UUID);
      const storedToken = localStorage.getItem(LocalStorageParam.TOKEN);
      if (!storedToken || !firebaseUid) return;
      const parsedStored = JSON.parse(storedToken);
      dispatch(setUserBearerToken(parsedStored.access_token));
      dispatch(setFirebaseUuid(firebaseUid));
      registerCode(code);
    }
  }, [code, state]);
  usePageHook();

  return <FullScreenSpinner loading={loading} />;
};
