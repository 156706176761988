import { palette } from "assets/palette";
import { LoginWrapper } from "components/login-wrapper/LoginWrapper";
import { SubscriptionPeriodSelection } from "components/subscription-period-selection/SubscriptionPeriodSelection";
import { SubscriptionPlan } from "components/subscription-period-selection/SubscriptionPeriodSelection.type";
import { i18n } from "constants/i18n.constants";
import { CustomButton } from "elements/button/Button";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { FullScreenSpinner } from "elements/spinner/FullScreenSpinner";
import { Text } from "elements/text/Text";
import React from "react";
import { useSubscriptionFail } from "./useSubscriptionFail";
import { usePageHook } from "hooks/usePageHook";

export const SubscriptionFail = () => {
  const { onPressNext, setSelectedPlan, selectedPlan, loading, subscribe, monthlyPrice, yearlyPrice } =
    useSubscriptionFail();
    usePageHook();
  return (
    <LoginWrapper
      showBottom={false}
      title={i18n.t("subscription_something_went_wrong")}
      headerRightText={i18n.t("next")}
      onPressHeaderRightText={onPressNext}
      headerIcon={IconName.SOMETHING_WENT_WRONG}
    >
      <div style={styles.mainContainer}>
        {Array.from(i18n.t("subscription_features")).map((item: string, index) => {
          return (
            <div style={styles.featuresContainer} key={index}>
              <Icon name={IconName.SUBSCRIPTION_CHECK} />
              <div style={styles.verticalGap} />
              <Text style={styles.subscriptionFeatureText}>{item}</Text>
              <div style={styles.verticalGap} />
            </div>
          );
        })}
      </div>
      <SubscriptionPeriodSelection
        selectedPlan={selectedPlan}
        onSelectPlan={setSelectedPlan}
      />
      <Text style={styles.secondarySubtitle}>
        {i18n.t("month", {
          price: selectedPlan === SubscriptionPlan.MONTHLY ? monthlyPrice : yearlyPrice,
        })}
      </Text>
      <CustomButton style={styles.subscribe} text={i18n.t("subscribe")} onClick={subscribe} />
      <div style={styles.gap} />
      <CustomButton
        style={styles.startFreeTrial}
        text={i18n.t("start_free_trial")}
        onPress={onPressNext}
      />
      <FullScreenSpinner loading={loading} />
    </LoginWrapper>
  );
};

const styles: Record<string, React.CSSProperties> = {
  period: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 80,
  },
  verticalSeparator: {
    width: 1,
    height: 46,
    marginLeft: 30,
    marginRight: 30,
    backgroundColor: palette.black,
  },
  secondarySubtitle: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 25,
    color: palette.black,
    marginTop: 15,
    marginBottom: 35
  },
  subscribe: {
    width: 500,
    backgroundColor: palette.black,
    height: 50,
  },
  startFreeTrial: {
    width: 500,
    backgroundColor: palette.gray.mediumLight,
    height: 50,
    marginBottom: 60,
  },
  gap: {
    height: 20,
  },
  verticalGap: {
    width: 20,
  },
  unSelectedPeriodText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 18,
    color: palette.gray.mediumLight,
  },
  selectedPeriodText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 18,
    color: palette.black,
  },
  subscriptionFeatureText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 18,
    color: palette.gray.dark,
  },
  featuresContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  mainContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    marginBottom: 35
  },
};
