import { palette } from "assets/palette";
import { TaskEntity } from "communicators/resources/event-resources/tasks/tasks.type";
import { DropResult } from "constants/dnd.constants";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { Text } from "elements/text/Text";
import { resizeByResolutionWidth } from "helpers/utils.helper";
import { AllBoardDataEntityType, IBoard } from "interfaces/main.interfaces";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { HeaderButtonType } from "../../constants";
import { TimelineListItem } from "../TimelineListItem/TimelineListItem";
import { DropableArea } from "./DropableArea";
import { useBoardHook } from "./useBoardHook";

interface Props {
  boardData: IBoard;
  onDragEnd: (result: DropResult) => void;
  onPressPlus: (date: DateTime) => void;
  onTaskCreate: (
    index: number,
    boardDate: DateTime,
    createdTask: TaskEntity,
    isNewTask: boolean
  ) => void;
  onClickCheckBox: (data: AllBoardDataEntityType) => void;
  deleteTask?: (data: AllBoardDataEntityType) => void;
  onMoveDropableArea: (boardData: IBoard) => void;
  onTaskMeetAction?: () => void;
  onSelectSomeday?: (date: DateTime, data?: AllBoardDataEntityType) => void;
  refreshBoards: () => void;
  boardType?: HeaderButtonType;
  isShown?: boolean;
  checkedDataId?: string;
  onDrop?: (result: DropResult) => void;
}

export const Board = ({
  boardData,
  onDragEnd,
  onPressPlus,
  onTaskCreate,
  onClickCheckBox,
  deleteTask,
  onMoveDropableArea,
  onTaskMeetAction,
  onSelectSomeday,
  refreshBoards,
  boardType,
  isShown,
  checkedDataId,
  onDrop,
}: Props) => {
  const { move, getTitle, onPressPlusToAddTask, isItemShown } = useBoardHook({
    boardData,
    onDragEnd,
    onPressPlus,
    isShown,
    checkedDataId,
  });

  const onMoveDropableArea2 = () => {
    onMoveDropableArea(boardData);
  };

  const [isPlusActive, setIsPlusActive] = useState(true);

  const handleNewTaskCreate = (isCreated: boolean) => {
    setIsPlusActive(!isCreated);
  };

  return (
    <div style={styles.container}>
      <div style={styles.titleContainer}>
        <Text style={styles.title}>{getTitle()}</Text>
        <Icon
          style={
            boardType !== HeaderButtonType.MEETS
              ? styles.icon
              : styles.hiddenIcon
          }
          fill={palette.gray.iceLight}
          name={IconName.PLUS}
          onPress={onPressPlusToAddTask}
          disabled={!isPlusActive}
        />
      </div>
      <Text style={styles.subtitle}>{boardData.date.toFormat("LLLL d")}</Text>
      <div
        style={styles.listWrapper}
        className="Windows"
        id={boardData.date.toString()}
      >
        {[...boardData.data].map((item, index) =>
          isItemShown(item) ? (
            <TimelineListItem
              boardId={boardData?.date.toSQLDate()!}
              key={item.id || index}
              index={index}
              data={item}
              moveCard={move}
              onTaskCreate={(createdTask: TaskEntity, isNewTask: boolean) =>
                onTaskCreate(index, boardData.date, createdTask, isNewTask)
              }
              onClickCheckBox={onClickCheckBox}
              deleteTask={deleteTask}
              boardData={boardData}
              onTaskMeetAction={onTaskMeetAction}
              onSelectSomeday={onSelectSomeday}
              refreshBoards={refreshBoards}
              newTaskCreate={handleNewTaskCreate}
            />
          ) : null
        )}
        <DropableArea onHover={onMoveDropableArea2} onDrop={onDrop} />
      </div>
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    alignSelf: "stretch",
    flexDirection: "column",
    width: resizeByResolutionWidth(390),
  },
  titleContainer: {
    display: "flex",
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontFamily: "Gilroy-Semibold",
    fontWeight: "500",
    fontSize: 25,
  },
  icon: {
    marginLeft: resizeByResolutionWidth(30),
  },
  hiddenIcon: {
    marginLeft: resizeByResolutionWidth(30),
    visibility: "hidden",
  },
  subtitle: {
    fontFamily: "Gilroy-Semibold",
    fontWeight: "500",
    fontSize: 20,
    color: palette.gray.extraLight,
    marginBottom: resizeByResolutionWidth(20),
  },
  listWrapper: {
    display: "flex",
    alignSelf: "stretch",
    flexDirection: "column",
    flex: 1,
    paddingRight: 8,
    paddingTop: 8,
    overflow: "scroll",
  },
};
