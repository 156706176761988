import React from "react";
import { CustomButton } from "elements/button/Button";
import { i18n } from "constants/i18n.constants";
import { CustomTextField } from "elements/text-field/TextField";
import { LoginWrapper } from "components/login-wrapper/LoginWrapper";
import { usePhoneLoginHook } from "./usePhoneLoginHook";
import { palette } from "assets/palette";
import { CountryCodePicker } from "components/country-code-picker/CountryCodePicker";
import { SubmitPhoneVerification } from "helpers/common.helper";
import { usePageHook } from "hooks/usePageHook";

export const PhoneLogin = () => {
  const {
    phone,
    loading,
    phoneIsValid,
    onChangePhone,
    setSelectedCountry,
    selectedCountry,
    setLoading,
    navigate
  } = usePhoneLoginHook();
  usePageHook();
  const renderLeftItem = <CountryCodePicker onSelect={setSelectedCountry} />;
  return (
    <LoginWrapper
      showContinueWithPhone={false}
      showLoginText
      title={i18n.t("sign_up_with_phone")}
    >
      <CustomTextField
        label={i18n.t("phone")}
        placeholder={i18n.t("enter_your_phone")}
        value={phone}
        autoComplete="phone"
        onChangeText={onChangePhone}
        leftItem={renderLeftItem}
        classProp="custom-style"
      />

      <CustomButton
        id="recaptcha-container"
        loading={loading}
        backgroundColor={phoneIsValid ? palette.black : palette.gray.mediumLight}
        disabled={!phoneIsValid}
        spinnerColor={"secondary"}
        fullWidth
        text={i18n.t("send_verification_code")}
        onPress={() => SubmitPhoneVerification("/verify-phone", phone, setLoading, navigate, selectedCountry)}
      />
    </LoginWrapper>
  );
};
