import React from "react";
import { Button } from "@mui/material";
import { Text } from "elements/text/Text";
import { palette } from "assets/palette";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { resizeByResolutionWidth } from "helpers/utils.helper";

interface Props {
  onPress: () => void;
  title: string;
  image: string;
  subtitle?: string;
  checked?: boolean;
  style?: React.CSSProperties;
}

export const ListCheckBox = ({
  image,
  title,
  subtitle,
  checked,
  style,
  onPress,
}: Props) => {
  return (
    <Button
      onClick={onPress}
      variant="outlined"
      style={{ ...styles.button, ...style }}
    >
      <div style={styles.container}>
        <img style={styles.image} src={image} alt={title} />
        <Text style={styles.titleText}>{title}</Text>
        <div
          style={checked ? styles.checkedContainer : styles.unCheckedContainer}
        >
          {checked ? (
            <Icon name={IconName.TICK} width={10} fill={palette.white} />
          ) : null}
        </div>
      </div>
      {subtitle && checked ? (
        <Text style={styles.secondarySubtitle}>{subtitle}</Text>
      ) : null}
    </Button>
  );
};

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "row",
    alignSelf: "stretch",
    alignItems: "center",
  },
  button: {
    display: "flex",
    alignSelf: "stretch",
    flexDirection: "column",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    borderColor: palette.gray.mediumLight,
    textTransform: "none",
  },
  image: {
    width: resizeByResolutionWidth(30),
    height: resizeByResolutionWidth(30),
    marginRight: 15,
  },
  titleText: {
    fontSize: 20,
    color: palette.gray.dark,
    flex: 1,
    textAlign: "left",
    fontWeight: "500",
    fontFamily: "Gilroy-Medium",
  },
  unCheckedContainer: {
    width: resizeByResolutionWidth(30),
    height: resizeByResolutionWidth(30),
    borderRadius: resizeByResolutionWidth(15),
    border: `1px solid ${palette.gray.dark}`,
  },
  checkedContainer: {
    width: resizeByResolutionWidth(30),
    height: resizeByResolutionWidth(30),
    borderRadius: resizeByResolutionWidth(15),
    backgroundColor: palette.blue.lighter,
    border: `1px solid ${palette.blue.lighter}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  secondarySubtitle: {
    fontSize: 16,
    color: palette.gray.medium,
    marginTop: 10,
    display: "flex",
    textAlign: "left",
    alignSelf: "stretch",
  },
};
