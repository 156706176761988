import { ONBOARDING_INTEGRATION_PAGE_COUNT } from "constants/onboarding.constants";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  makeAsanaIntegration,
  makeTodoistIntegration,
} from "helpers/third-party.helper";
import { ThirdPartyProvider } from "interfaces/main.interfaces";
import { i18n } from "constants/i18n.constants";
import {
  AsanaImage,
  GoogleCalendarImage,
  TodoistImage,
} from "assets/images";
import { useAppDispatch, useAppSelector } from "redux-store/store.hooks";
import { ResponseWithData } from "communicators/types";
import { UserPreferencesEntity } from "communicators/resources/user-preferences/user-preferences.type";
import { UserPreferencesResource } from "communicators/resources/user-preferences/user-preferences.resource";
import { ThirdPartyInformationResource } from "communicators/resources/third-party-information/third-party-information.resource";
import { setThirdPartyProviderData } from "redux-store/actions/third-party-provider.actions";

const PAGE_NUMBER = 1;

interface Props {
  googleSignIn: any;
  googleAuthLoading: boolean;
}

export const useOnboardingIntegration = ({ googleSignIn, googleAuthLoading }: Props) => {
  const [secondDrawerOpened, setSecondDrawerOpened] = React.useState<{
    type: string | null;
    opened: boolean;
  }>({
    type: null,
    opened: false,
  });
  const dispatch = useAppDispatch();
  const [preference, setPreference] =
    useState<ResponseWithData<UserPreferencesEntity[]>>();

  const { googleId, asanaToggled, todoistToggled } = useAppSelector(
    (state) => ({
      googleId: state.thirdPartyProviderData.googleId,
      asanaToggled: !!state.thirdPartyProviderData.asanaId,
      todoistToggled: !!state.thirdPartyProviderData.todoistId,
    })
  );

  const getUserPreference = async () => {
    const response = await new UserPreferencesResource().readMany();
    setPreference(response);
  };

  const navigate = useNavigate();

  const closeSecondDrawer = () => {
    setSecondDrawerOpened({ type: null, opened: false });
  };

  const openSecondDrawer = (type: string) => {
    if (type === secondDrawerOpened.type) {
      closeSecondDrawer();
      return;
    }
    setSecondDrawerOpened({ type, opened: true });
  };

  const onPressNext = () => {
    navigate("/add-first-task");
  };


  const onPressGoogleIntegration = async () => {
    if (!googleId || googleId === "") {
      try {
        googleSignIn();
      }
      catch {
      }
      finally {
        return;
      }
    }

    await new ThirdPartyInformationResource().removeOne(googleId);
    dispatch(setThirdPartyProviderData());
  };

  const toggleSwitch = (provider: ThirdPartyProvider) => {
    if (provider === ThirdPartyProvider.TODOIST) {
      makeTodoistIntegration(true);
    } else if (provider === ThirdPartyProvider.ASANA) {
      makeAsanaIntegration(true);
    } else if (provider === ThirdPartyProvider.GOOGLE) {
      onPressGoogleIntegration();
    }
  };

  useEffect(() => {
    getUserPreference();
  }, []);

  const progressValue = (PAGE_NUMBER / ONBOARDING_INTEGRATION_PAGE_COUNT) * 100;

  const getGoogleToggled = (): boolean => { if (googleId && googleId !== "") return true; else return false; }

  const INTEGRATIONS: {
    provider: ThirdPartyProvider;
    title: string;
    image: string;
    subtitle?: string;
    onToggleSwitch?: (provider: ThirdPartyProvider) => void;
    showSwitch?: boolean;
    toggled?: boolean;
  }[] = [];

  preference?.data[0].attributes.preferred_tools?.forEach((item) => {
    if (item === ThirdPartyProvider.GOOGLE) {
      INTEGRATIONS.push({
        provider: ThirdPartyProvider.GOOGLE,
        title: i18n.t("google_calendar"),
        image: GoogleCalendarImage,
        onToggleSwitch: () => toggleSwitch(ThirdPartyProvider.GOOGLE),
        showSwitch: true,
        toggled: getGoogleToggled(),
      });
    } else if (item === ThirdPartyProvider.ASANA) {
      INTEGRATIONS.push({
        provider: ThirdPartyProvider.ASANA,
        title: i18n.t("asana"),
        image: AsanaImage,
        onToggleSwitch: () => toggleSwitch(ThirdPartyProvider.ASANA),
        showSwitch: true,
        toggled: asanaToggled,
      });
    } else if (item === ThirdPartyProvider.TODOIST) {
      INTEGRATIONS.push({
        provider: ThirdPartyProvider.TODOIST,
        title: i18n.t("todoist"),
        image: TodoistImage,
        onToggleSwitch: () => toggleSwitch(ThirdPartyProvider.TODOIST),
        showSwitch: true,
        toggled: todoistToggled,
      });
    }
  });

  const showSpinner = googleAuthLoading;
  return {
    openSecondDrawer,
    secondDrawerOpened,
    progressValue,
    onPressNext,
    toggleSwitch,
    INTEGRATIONS,
    showSpinner
  };
};
