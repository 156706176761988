import { palette } from "assets/palette";
import { LoginWrapper } from "components/login-wrapper/LoginWrapper";
import { i18n } from "constants/i18n.constants";
import { CustomButton } from "elements/button/Button";
import React from "react";
import { useChangePhoneNumberHook } from "./useChangePhoneNumberHook";
import { Text } from "elements/text/Text";
import { VerificationCodeInputs } from "./components/VerificationCodeInputs";
import { usePageHook } from "hooks/usePageHook";

export const ChangePhoneNumber = () => {
  const { loading, error, onPressSubmit, code, setCode, phoneNumberModifier } =
    useChangePhoneNumberHook();
    usePageHook();
  return (
    <LoginWrapper
        showBottom={false}
        title={i18n.t("change_phone_number")}
    >
        <div style={styles.phoneNumberContainer}>
            <Text style={styles.phoneNumber}>
                {phoneNumberModifier()}
            </Text>
        </div>
        <Text style={styles.secondarySubtitle}>
            {i18n.t("enter_6_digit_code")}
        </Text>
        <VerificationCodeInputs codeLength={6} code={code} setCode={setCode} />
        <Text style={styles.errorText}>{error}</Text>
        <CustomButton
        loading={loading}
        spinnerColor="primary"
        style={styles.buttonStyle}
        text={i18n.t("done")}
        onPress={onPressSubmit}
        />
    </LoginWrapper>
  );
};

const styles: Record<string, React.CSSProperties> = {
    secondarySubtitle: {
      fontSize: 25,
      color: palette.black,
      display: "flex",
      textAlign: "center",
      fontWeight: "400",
      fontFamily: "Gilroy",
      marginTop: 50,
      marginBottom: 50,
      padding: "10px 40px 10px 40px"
    },
    buttonStyle: {
      marginTop: "auto",
      marginBottom: 50,
      width: "100%",
      backgroundColor: palette.black,
      height: 50,
    },
    phoneNumberContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        border: `2px dashed ${palette.gray.mediumLight}`,
        borderRadius: 12,
        width: "50%",
        padding: "10px 40px 10px 40px",
        marginTop: 20
    },
    phoneNumber: {
        fontSize: 25,
        color: palette.black,
        display: "flex",
        textAlign: "center",
        fontWeight: "400",
        fontFamily: "Gilroy",
    },
    errorText: {
        fontSize: 14,
        color: palette.red.dark,
        textAlign: "left",
        display: "flex",
        alignSelf: "flex-start",
        margin: "0 0 8px 8px",
    }
  };