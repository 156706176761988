import { TaskTags } from "communicators/resources/event-resources/tasks/tasks.type";
import { TagEntity } from "communicators/resources/tags/tags.types";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import "./TagSelection.scss";

interface Props {
  tags?: TaskTags[];
  favoriteTags?: TagEntity[];
  onClickTripleCircle?: () => void;
  onClickFavoriteTag?: (id: string) => void;
  onClickTaskTag?: (id: string) => void;
}

export const TagSelection = ({
  tags,
  favoriteTags,
  onClickTripleCircle,
  onClickFavoriteTag,
  onClickTaskTag,
}: Props) => {
  return (
    <div className="tag-selection-container">
      <div className="tag-selection-tag-list-container">
        {tags?.map((item) => {
          return (
            <div
              key={item.id}
              className="tag-selection-tag-list-item"
              style={{ backgroundColor: item?.tag.color }}
              onClick={() => onClickTaskTag?.(item.id)}
            >
              {item?.tag.name}
            </div>
          );
        })}
        {favoriteTags?.map((item) => {
          return (
            <div
              className="tag-list-item-not-selected-favorite-tag"
              onClick={() => onClickFavoriteTag?.(item.id)}
            >
              <div
                className="tag-list-item-favorite-tag"
                style={{ backgroundColor: item?.attributes?.color }}
              />
              &nbsp;{item?.attributes?.name}
            </div>
          );
        })}
      </div>
      <Icon
        name={IconName.TAG_SELECTION_TRIPLE_CIRCLE}
        onPress={onClickTripleCircle}
      />
    </div>
  );
};
