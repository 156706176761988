import * as React from "react";
import { HomeWrapper } from "./components/HomeWrapper/HomeWrapper";
import { Main } from "./components/Main/Main";
import { useHomeHook } from "./useHomeHook";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { UnsubscriptionDialog } from "./components/UnsubscriptionDialog/UnsubscriptionDialog";
import { SubscribeToContinueModal } from "components/subscribe-to-continue/SubscribeToContinueModal";
import { useSomedayHook } from "./useSomedayHook";
import { PermissionModal } from "components/permission-modal/PermissionModal";
import { StreakModal } from "components/streak-modal/StreakModal";
import { AchievementModal } from "components/achievement-modal/AchievementModal";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { palette } from "assets/palette";
import { useStreakHook } from "./useStreakHook";
import { ManageTagsModal } from "components/manage-tags-modal/ManageTagsModal";
import { usePageHook } from "hooks/usePageHook";

interface Props {
  googleSignIn: any;
  googleAuthLoading: boolean;
}

export const Home = ({ googleSignIn, googleAuthLoading }: Props) => {
  const {
    boardData,
    loading,
    onDragEnd,
    setActiveBoard,
    onSelectDate,
    onPressAllBoardPlus,
    onTaskCreate,
    isVerifyEmailPopupVisible,
    toggleVerifyEmailPopup,
    onPressNextDay,
    onPressPreviousDay,
    rightDrawerStartDate,
    updateTaskWithRightDrawerDrop,
    onPressSearchItem,
    leftDrawerSearchedDate,
    onClickCheckBox,
    deleteTask,
    allInsumoCalendarEventData,
    rightDrawerData,
    isRightDrawerLoading,
    onClickResendEmailVerification,
    refreshBoards,
    onTaskMeetAction,
    subscribeModal,
    subscribe,
    allBoardData,
    setAllBoardData,
    inboxPreviousMonth,
    inboxNextMonth,
    inboxStartDate,
    inboxLoading,
    onPressInboxBoardPlus,
    onInboxTaskCreate,
    sortedInboxVisibleData,
    onHoverRightDrawer,
    permissionModalProps,
    isStreakModalVisible,
    closeStreakModal,
    activeBoard,
    isDoneTaskShown,
    checkedDataId,
    streakInfo,
    isBackgroundImageShown,
    isMovedToInboxPopupVisible,
    onCloseMovedToInboxPopup,
    movedToInboxTaskTitles,
    isPlannerLoading,
    magicPlannerText,
    onClickMagicPlan,
    toggleMagicPlanner,
    isMagicPlannerOn,
    isMagicPlannerSwitchShown,
    onClickToday,
    refreshAllDataWithoutSpinner
  } = useHomeHook();

  const {
    sortedSomedayData,
    somedayLoading,
    onSelectSomeday,
    onPressSomedayPlus,
    onTaskCreateSomeday,
    deleteSomedayTask,
    onClickCheckBoxSomeday,
  } = useSomedayHook({ allBoardData, setAllBoardData });

  const [isUnsubscriptionOpened, setIsUnsubscriptionOpened] =
    React.useState<boolean>(false);
  const handleClose = () => {
    setIsUnsubscriptionOpened(false);
  };
  const handleOpenUnsubscriptionDialog = () => {
    setIsUnsubscriptionOpened(true);
  };

  const {
    task_zero_days,
    current_streak,
    longest_streak,
    ref,
    isAchievementModalVisible,
  } = useStreakHook();
  usePageHook();

  return (
    <DndProvider backend={HTML5Backend}>
      <HomeWrapper
        onSelectDate={onSelectDate}
        boardData={boardData}
        rightDrawerBoardData={rightDrawerData}
        onTabChanged={setActiveBoard}
        refreshBoards={refreshBoards}
        onPressBoardPlus={onPressInboxBoardPlus}
        onTaskCreate={onInboxTaskCreate}
        inboxVisibleData={sortedInboxVisibleData}
        onPressNextDay={onPressNextDay}
        onPressPreviousDay={onPressPreviousDay}
        rightDrawerStartDate={rightDrawerStartDate}
        updateTaskWithRightDrawerDrop={updateTaskWithRightDrawerDrop}
        onPressSearchItem={onPressSearchItem}
        leftDrawerSearchedDate={leftDrawerSearchedDate}
        handleOpenUnsubscriptionDialog={handleOpenUnsubscriptionDialog}
        insumoCalendarEventData={allInsumoCalendarEventData}
        isLoading={isRightDrawerLoading}
        googleSignIn={googleSignIn}
        googleAuthLoading={googleAuthLoading}
        previousMonth={inboxPreviousMonth}
        nextMonth={inboxNextMonth}
        inboxStartDate={inboxStartDate}
        inboxLoading={inboxLoading}
        onClickCheckBox={onClickCheckBox}
        deleteTask={deleteTask}
        somedayData={sortedSomedayData}
        onHoverRightDrawer={onHoverRightDrawer}
        somedayLoading={somedayLoading}
        onPressSomedayPlus={onPressSomedayPlus}
        onTaskCreateSomeday={onTaskCreateSomeday}
        deleteSomedayTask={deleteSomedayTask}
        onClickCheckBoxSomeday={onClickCheckBoxSomeday}
        isTransparent={isBackgroundImageShown}
        toggleMagicPlanner={toggleMagicPlanner}
        magicPlannerState={isMagicPlannerOn}
        isMagicPlannerSwitchShown={isMagicPlannerSwitchShown}
        onClickToday={onClickToday}
        refreshAllDataWithoutSpinner={refreshAllDataWithoutSpinner}
      >
        <Main
          onSelectSomeday={onSelectSomeday}
          onTaskMeetAction={onTaskMeetAction}
          onDragEnd={onDragEnd}
          loading={loading}
          data={boardData}
          onPressBoardPlus={onPressAllBoardPlus}
          onTaskCreate={onTaskCreate}
          toggleVerifyEmailPopup={toggleVerifyEmailPopup}
          isVerifyEmailPopupVisible={isVerifyEmailPopupVisible}
          onClickCheckBox={onClickCheckBox}
          deleteTask={deleteTask}
          onClickResend={onClickResendEmailVerification}
          refreshBoards={refreshBoards}
          boardType={activeBoard}
          isShown={isDoneTaskShown}
          checkedDataId={checkedDataId}
          isStreakModalShown={isStreakModalVisible}
          onCloseMovedToInboxPopup={onCloseMovedToInboxPopup}
          isMovedToInboxPopupVisible={isMovedToInboxPopupVisible}
          movedToInboxTaskTitles={movedToInboxTaskTitles}
          isPlannerLoading={isPlannerLoading}
          magicPlannerText={magicPlannerText}
          isMagicPlannerSwitchShown={isMagicPlannerSwitchShown}
          onClickMagicPlan={onClickMagicPlan}
        />
      </HomeWrapper>
      <UnsubscriptionDialog
        isOpened={isUnsubscriptionOpened}
        handleClose={handleClose}
      />
      <SubscribeToContinueModal open={subscribeModal} onPress={subscribe} />
      <ManageTagsModal />
      <PermissionModal {...permissionModalProps} />
      <StreakModal
        isVisible={isStreakModalVisible}
        closeModal={closeStreakModal}
        streakInfo={streakInfo}
      />
      {isAchievementModalVisible &&
      isBackgroundImageShown &&
      !isMovedToInboxPopupVisible ? (
        <div style={styles.achievementModalStyle}>
          <AchievementModal
            task_zero_days={task_zero_days}
            longest_streak={longest_streak}
            current_streak={current_streak}
          />
        </div>
      ) : null}
      {isBackgroundImageShown && !isMovedToInboxPopupVisible ? (
        <div style={styles.toggleContainer} ref={ref}>
          <div style={styles.toggleItem}>
            <Icon name={IconName.STREAK_CUP} width={33} height={40} />
          </div>
        </div>
      ) : null}
    </DndProvider>
  );
};

const styles: Record<string, React.CSSProperties> = {
  achievementModalStyle: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  toggleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#5a5a5acc",
    borderRadius: "50%",
    width: 76,
    height: 76,
    position: "absolute",
    bottom: "6.481%",
    left: "4.687%",
  },
  toggleItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: 68,
    height: 68,
    borderRadius: "50%",
    border: `4px solid ${palette.gray.iceLight}`,
  },
};
