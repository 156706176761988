import { UserPreferencesResource } from "communicators/resources/user-preferences/user-preferences.resource";
import { LocalStorageParam } from "constants/local-storage.constant";
import { ONBOARDING_PAGE_COUNT } from "constants/onboarding.constants";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux-store/store.hooks";
import { UserPreferencesEntity } from "communicators/resources/user-preferences/user-preferences.type";
import { ResponseWithData } from "communicators/types";
import { determineHours } from "helpers/common.helper";

const PAGE_NUMBER = 2;
const DEFAULT_TIME_INDEX = 37;

export const usePlaningTimeSelection = () => {
  const [accordionOpened, setAcordionOpened] = useState(false);
  const [preference, setPreference] =
    useState<ResponseWithData<UserPreferencesEntity[]>>();

  const [selectedTime, setSelectedTime] = useState(
    DateTime.now().set({ hour: 9, minute: 0 })
  );
  const [scrollIndex, setScrollIndex] = useState(DEFAULT_TIME_INDEX);

  const [loading, setLoading] = useState(false);

  const { userInfo } = useAppSelector((state) => ({
    userInfo: state.userSetting.userInfo,
  }));

  const onPressAccordion = () => {
    setAcordionOpened(!accordionOpened);
  };

  const navigate = useNavigate();

  const onPressNext = () => {
    localStorage.setItem(
      LocalStorageParam.ONBOARDING_PLAN_TIME_SHOWN,
      JSON.stringify(true)
    );
    navigate("/onboarding/create-reminder");
  };

  const progressValue = (PAGE_NUMBER / ONBOARDING_PAGE_COUNT) * 100;

  const hours = determineHours();
  const formattedTime = selectedTime.toLocaleString(DateTime.TIME_SIMPLE);
  const getUserPreference = async () => {
    const response = await new UserPreferencesResource().readMany();
    setPreference(response);
  };

  const onPressItem = (index: number, time: DateTime) => {
    setScrollIndex(index);
    setSelectedTime(time);
  };

  useEffect(() => {
    getUserPreference();
  }, []);

  useEffect(() => {
    const selectedIndex = document.getElementById((scrollIndex - 3).toString());
    if (selectedIndex) {
      selectedIndex.scrollIntoView(true);
    }
  });

  const sendPlanningTime = async () => {
    if (!userInfo || !preference) return;
    setLoading(true);
    await new UserPreferencesResource().updateOnePartial(
      preference.data[0].id,
      {
        user_preference: {
          planning_time: formattedTime,
        },
      }
    );
    localStorage.setItem(
      LocalStorageParam.ONBOARDING_PLAN_TIME_SHOWN,
      JSON.stringify(true)
    );
    onPressNext();
    setLoading(false);
  };

  return {
    progressValue,
    hours,
    selectedTime,
    accordionOpened,
    onPressNext,
    onPressAccordion,
    sendPlanningTime,
    onPressItem,
    loading,
  };
};
