export enum PaymentMethods {
    APPLE = "apple",
}

export enum PaymentPlans {
    PREMIUM = "premium"
}

export enum PaymentStatus {
    PAID = "PAID",
    UNPAID = "UNPAID",
    FAIL = "FAIL"
}