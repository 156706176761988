import { combineReducers } from "redux";
import { userSettingsReducer } from "./user-settings.reducer";
import { thirdPartyProviderReducer } from "./third-party-provider.reducer";
import { dndReducer } from "./dnd.reducer";
import { homePageReducer } from "./home.reducer";
import { tagsReducer } from "./tags.reducer";

export const rootReducer = combineReducers({
  userSetting: userSettingsReducer,
  thirdPartyProviderData: thirdPartyProviderReducer,
  dnd: dndReducer,
  homePage: homePageReducer,
  tags: tagsReducer
});
