import { TextareaAutosize } from "@mui/material";
import { palette } from "assets/palette";
import { resizeByResolutionWidth } from "helpers/utils.helper";
import { Tag } from "./useTagsHook";
import { i18n } from "constants/i18n.constants";
import { Text } from "elements/text/Text";
import { useState } from "react";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";

interface Props {
  tag: Tag;
  loading?: boolean;
  onChangeTitle: (text: string, tag: Tag) => void;
  onPressDelete?: (tag: Tag) => void;
  containerStyle?: React.CSSProperties;
  onPressHeart?: (tag_id: string, isFavorite: boolean) => void;
  isFav?: boolean;
  onClick?: (tag_id: string, event?: any) => void;
}

export const TagItem = ({
  tag,
  loading,
  containerStyle,
  onChangeTitle,
  onPressDelete,
  onPressHeart,
  isFav,
  onClick,
}: Props) => {
  const [text, setText] = useState(tag.title);
  const [trashPressed, setTrashPressed] = useState<boolean>(false);
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const onChangeText = (newTitle: string) => {
    if (loading) return;
    setText(newTitle);
    onChangeTitle(newTitle, tag);
  };

  return (
    <div
      onClick={(event) => {
        var target = event.target as Element;
        if (target.classList.contains("input")) {
          event.preventDefault();
          event.stopPropagation();
        } else {
          setIsSelected(!isSelected);
          onClick?.(tag.id);
        }
      }}
      key={tag.id.toString()}
      style={
        isSelected
          ? { ...styles.itemContainer, backgroundColor: tag.color }
          : { ...styles.itemContainer, ...containerStyle }
      }
    >
      <div style={{ ...styles.itemColorBorder, backgroundColor: tag.color }} />
      <div style={styles.itemBody}>
        <TextareaAutosize
          className="input"
          value={text}
          placeholder={i18n.t("type_here")}
          onChange={(e) => onChangeText(e.target.value)}
        />
        <Text style={styles.itemSubtitle}>
          {i18n.t("n_items", { itemCount: tag.item_count })}
        </Text>
      </div>
      <div>
        <Icon
          width={isFav ? 25 : 17}
          height={isFav ? 25 : 17}
          style={isFav ? styles.filledHeart : undefined}
          name={isFav ? IconName.HEART_FILLED : IconName.HEARTH_OUTLINE}
          onPress={() => onPressHeart?.(tag.id, tag.favorite)}
        />
        <Icon
          width={17}
          height={17}
          style={styles.trash}
          name={IconName.TRASH}
          onPress={() => setTrashPressed(!trashPressed)}
        />
      </div>
      {trashPressed ? (
        <Icon
          width={6.5}
          height={6.5}
          name={IconName.CLOSE}
          onPress={() => onPressDelete?.(tag)}
          style={styles.closeIcon}
        />
      ) : null}
    </div>
  );
};
const styles: Record<string, React.CSSProperties> = {
  itemContainer: {
    display: "flex",
    alignSelf: "stretch",
    flexDirection: "row",
    borderRadius: 12,
    marginBottom: "10px",
    backgroundColor: palette.gray.ice,
    marginLeft: resizeByResolutionWidth(20),
    marginRight: resizeByResolutionWidth(20),
    position: "relative",
  },
  itemBody: {
    marginLeft: "20px",
    flex: 1,
    height: "100%",
    paddingTop: resizeByResolutionWidth(10),
    paddingBottom: resizeByResolutionWidth(10),
  },
  itemColorBorder: {
    width: "9px",
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
  },
  itemTitle: {
    color: palette.black,
    fontSize: resizeByResolutionWidth(20),
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
  },
  itemSubtitle: {
    color: palette.gray.medium,
    fontSize: resizeByResolutionWidth(18),
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    marginTop: resizeByResolutionWidth(10),
  },
  iconContainer: {
    display: "flex",
    flexDirection: "column",
  },
  closeIcon: {
    width: 19.5,
    height: 19.5,
    borderRadius: "50%",
    backgroundColor: palette.gray.ice,
    position: "absolute",
    top: -8,
    right: -8,
  },
  filledHeart: {
    marginRight: -4,
  },
  trash: {
    marginLeft: "auto",
  },
};
