import { UserOwnedResourceManagementService } from "communicators/services/UserOwnedResourceManagementService";
import { ITasksResource } from "./tasks.type";

export class TasksResource extends UserOwnedResourceManagementService<ITasksResource> {
  constructor() {
    super("TASKS");
  }

  readMany = async (query: ITasksResource["query"] = {}, resync?: boolean) => {
    if (resync) {
      await this.resync();
    }

    return await this.communicator.readMany(query);
  };
}
