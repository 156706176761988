import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux-store/store.hooks";
import { useGoogleSignInHook } from "hooks/useGoogleSignInHook";
import { ThirdPartyProvider } from "interfaces/main.interfaces";
import { deleteIntegration } from "helpers/third-party.helper";
import { setThirdPartyProviderData } from "redux-store/actions/third-party-provider.actions";
import { setRefreshBoardData } from "redux-store/actions/home-page.actions";
import { useUserSettingHook } from "hooks/useUserSettingHook";
import { CalendarAccountType } from "pages/private/home/useHomeHook";
import { setCalendarData } from "redux-store/actions/user-settings.action";

export const useGoogleCalendarHook = () => {
  const [thisrdpartyLoading, setThirdPartyLoading] = useState(false);
  const [deleteOpened, setDeleteOpened] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const { setAndGetUserPreferedCalendar } = useUserSettingHook();

  const { googleToggled, thirdPartyInformation } = useAppSelector((state) => ({
    googleToggled: !!state.thirdPartyProviderData.googleId,
    thirdPartyInformation: state.thirdPartyProviderData.thirdPartyData,
  }));

  const dispatch = useAppDispatch();

  const closeDeleteOpen = () => {
    setDeleteOpened(false);
  };

  const thirdPartyDataOnLoad = async (calendarType: CalendarAccountType) => {
    await setAndGetUserPreferedCalendar(calendarType);
    dispatch(setRefreshBoardData());
    setThirdPartyLoading(false);
    setDeleting(false);
    closeDeleteOpen();
    if (calendarType === CalendarAccountType.INSUMO) {
      dispatch(setCalendarData());
    }
  };

  const onSuccessGoogleLogin = () => {
    setThirdPartyLoading(true);
    dispatch(
      setThirdPartyProviderData(() =>
        thirdPartyDataOnLoad(CalendarAccountType.GOOGLE)
      )
    );
  };

  const { login, googleAuthLoading } = useGoogleSignInHook({
    onSuccess: onSuccessGoogleLogin,
  });

  const onSwitchGoogleLogin = () => {
    if (googleAuthLoading) return;
    login();
  };

  const onPressYesDelete = async () => {
    setDeleting(true);
    await deleteIntegration(ThirdPartyProvider.GOOGLE, thirdPartyInformation);
    dispatch(
      setThirdPartyProviderData(() =>
        thirdPartyDataOnLoad(CalendarAccountType.INSUMO)
      )
    );
  };

  const toggleSwitch = () => {
    if (googleToggled) {
      setDeleteOpened(true);
    } else {
      onSwitchGoogleLogin();
      dispatch(setThirdPartyProviderData());
    }
  };
  useEffect(() => {
    dispatch(setThirdPartyProviderData());
  }, []);

  const loading = thisrdpartyLoading || googleAuthLoading;
  return {
    googleToggled,
    loading,
    deleteOpened,
    deleting,
    toggleSwitch,
    closeDeleteOpen,
    onPressYesDelete,
  };
};
