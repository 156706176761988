import { SubscriptionPlansResource } from "communicators/resources/subscription-plans/subscription-plans.resource";
import { SubscriptionPlanEntity } from "communicators/resources/subscription-plans/subscription-plans.type";
import { SubscriptionsResource } from "communicators/resources/subscription/subscription.resource";
import { SubscriptionPlan } from "components/subscription-period-selection/SubscriptionPeriodSelection.type";
import { LocalStorageParam } from "constants/local-storage.constant";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setSubscriptions } from "redux-store/actions/user-settings.action";
import { useAppDispatch, useAppSelector } from "redux-store/store.hooks";

export const useAlreadySignInHook = () => {
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan>(
    SubscriptionPlan.MONTHLY
  );
  const [startFreeTrialLoading, setStartFreeTrialLoading] = useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState<
    SubscriptionPlanEntity[]
  >([]);
  const { subscriptions } = useAppSelector((state) => ({
    subscriptions: state.userSetting.subscriptions,
  }));

  const activeSubscriptions = subscriptions.filter(
    (item) =>
      item.attributes.status === "active" ||
      item.attributes.status === "trialing"
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const getSubscriptionPlans = async () => {
    const { data } = await new SubscriptionPlansResource().readMany();
    setSubscriptionPlans(data);
  };

  useEffect(() => {
    getSubscriptionPlans();
  }, []);

  const monthlyPSubscriptionPlan = subscriptionPlans?.filter(
    (item) => item?.attributes?.interval === "month"
  );

  const yearlyPSubscriptionPlan = subscriptionPlans?.filter(
    (item) => item?.attributes?.interval === "year"
  );

  const monthlyPrice = subscriptionPlans ? (monthlyPSubscriptionPlan?.[0]?.attributes?.price / 100).toFixed(2) : "";

  const yearlyPrice =subscriptionPlans ?  (yearlyPSubscriptionPlan?.[0]?.attributes?.price / 1200).toFixed(2) : "";

  const onPressUpgradeNow = () => {
    navigate("/checkout");
  };

  const onPressStartFreeTrial = async () => {
    localStorage.setItem(
      LocalStorageParam.ONBOARDING_SIGNUP_BEFORE_SHOWN,
      JSON.stringify(true)
    );
    setStartFreeTrialLoading(true);
    if (activeSubscriptions.length !== 0) {
      navigate("/onboarding/tools");
    } else {
      const response = await new SubscriptionsResource().createOne({
        subscription: { plan: selectedPlan },
      });
      dispatch(setSubscriptions([response.data]));
      navigate("/onboarding/tools");
    }
    setStartFreeTrialLoading(false);
  };

  return {
    selectedPlan,
    startFreeTrialLoading,
    onPressUpgradeNow,
    setSelectedPlan,
    onPressStartFreeTrial,
    monthlyPrice,
    yearlyPrice,
  };
};
