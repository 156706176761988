import { TasksResource } from "communicators/resources/event-resources/tasks/tasks.resource";
import { ONBOARDING_INTEGRATION_PAGE_COUNT } from "constants/onboarding.constants";
import { getAllBoardData } from "helpers/data.helper";
import { IBoard } from "interfaces/main.interfaces";
import { DateTime } from "luxon";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const PAGE_NUMBER = 3;

export const useDndTutorial = () => {
  const [secondDrawerOpened, setSecondDrawerOpened] = React.useState<{
    type: string | null;
    opened: boolean;
  }>({
    type: null,
    opened: false,
  });
  const [newTaskCardShown, setNewTaskCardShown] = React.useState(false);
  const [createdTaskShownOnCalendar, setCreatedTaskShownOnCalendar] =
    React.useState(false);
  const [todaysTasks, setTodaysTasks] = React.useState<IBoard>();
  const [tomorrowsTasks, setTomorrowsTasks] = React.useState<IBoard>();
  const [otherTasks, setOtherTasks] = React.useState<IBoard>();

  const getTasks = async () => {
    try {
      const response = await new TasksResource().readMany({
        start_date: DateTime.now().toSQLDate(),
        end_date: DateTime.now().plus({ days: 30 }).toISODate(),
      });
      return response.data;
    } catch (_) {
      return [];
    }
  };

  const getInitialData = async () => {
    const tasksResponse = await getTasks();
    if (!tasksResponse) return;
    const tasksData = getAllBoardData(tasksResponse, DateTime.now(), false);
    const tasksForToday = tasksData.find(
      (element: { date: DateTime }) =>
        element.date.toISODate() === DateTime.now().toISODate()
    );
    const tasksForTomorrow = tasksData.find(
      (element: { date: DateTime }) =>
        element.date.toISODate() ===
        DateTime.now().plus({ days: 1 }).toISODate()
    );
    const otherTasks = tasksData.find(
      (element: { date: DateTime }) =>
        element.date.toISODate() !==
          DateTime.now().plus({ days: 1 }).toISODate() ||
        DateTime.now().toISODate()
    );
    setTodaysTasks(tasksForToday);
    setTomorrowsTasks(tasksForTomorrow);
    setOtherTasks(otherTasks);
  };

  const navigate = useNavigate();
  const location = useLocation();

  const closeSecondDrawer = () => {
    setSecondDrawerOpened({ type: null, opened: false });
  };

  const openSecondDrawer = (type: string) => {
    if (type === secondDrawerOpened.type) {
      closeSecondDrawer();
      return;
    }
    setSecondDrawerOpened({ type, opened: true });
  };

  const goBack = () => {
    navigate(-1);
  };

  const progressValue = (PAGE_NUMBER / ONBOARDING_INTEGRATION_PAGE_COUNT) * 100;

  const dailyTimes: string[] = [];
  for (let i = 9; i < 23; i++) {
    dailyTimes.push((i < 10 ? "0" : "") + i + ":00");
  }

  const exampleTaskItem = document.getElementById("1");
  const newTaskCard = document.getElementById("3");

  document.documentElement.style.setProperty(
    "--xx",
    `${window.innerWidth - 390}px`
  );

  const createdTask = location.state;

  useEffect(() => {
    setTimeout(() => {
      navigate("/onboarding-dnd", { replace: true });
    }, 9000);
  }, []);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setCreatedTaskShownOnCalendar(true);
      setNewTaskCardShown(true);
    }, 7000);
    return () => clearTimeout(timer);
  }, []);

  React.useEffect(() => {
    getInitialData();
  }, []);

  return {
    openSecondDrawer,
    secondDrawerOpened,
    progressValue,
    goBack,
    dailyTimes,
    exampleTaskItem,
    newTaskCard,
    newTaskCardShown,
    createdTaskShownOnCalendar,
    todaysTasks,
    createdTask,
    tomorrowsTasks,
    otherTasks,
  };
};
