import React from "react";
import { palette } from "assets/palette";
import Dialog from "@mui/material/Dialog";
import { Icon } from "elements/icon/Icon";
import { resizeByResolutionWidth } from "helpers/utils.helper";
import { Text } from "elements/text/Text";
import { IconButton } from "@mui/material";
import { IconName } from "elements/icon/Icon.type";
import { Divider } from "elements/divider/Divider";
import { i18n } from "constants/i18n.constants";
import useManageTagsHook from "./useManageTagsHook";
import { TagItem } from "pages/private/home/components/DrawerLeft/Tags/TagItem";
import { debounce } from "lodash";

export const ManageTagsModal = () => {
  const {
    modalOpened,
    parsedTags,
    closeModal,
    onPressPlus,
    onPressDelete,
    onChangeTagTitle,
    parsedFavoriteTags,
    onClickHeart,
  } = useManageTagsHook();
  return (
    <Dialog onClose={closeModal} fullWidth maxWidth={"md"} open={modalOpened}>
      <div style={styles.container}>
        <div style={styles.header}>
          <Icon
            width={resizeByResolutionWidth(30)}
            height={resizeByResolutionWidth(30)}
            name={IconName.TAG_ICON}
          />
          <Text style={styles.title}>{i18n.t("manage_tags")}</Text>
          <IconButton onClick={closeModal}>
            <Icon width={15} height={15} name={IconName.CLOSE} />
          </IconButton>
        </div>
        <Divider color={palette.gray.light} />
        <div style={styles.subbody}>
          <div style={styles.row}>
            <Text style={styles.titleFotList}>{i18n.t("favorites")}</Text>
          </div>
          {parsedFavoriteTags.map((tags, index) => (
            <div key={index} style={styles.row}>
              {tags.map((item) =>
                item ? (
                  <TagItem
                    containerStyle={styles.tagItemContainerStyle}
                    key={item.id}
                    tag={item}
                    onChangeTitle={debounce(onChangeTagTitle, 1000)}
                    onPressDelete={onPressDelete}
                    onPressHeart={onClickHeart}
                    isFav={item.favorite}
                  />
                ) : (
                  <div
                    style={{
                      ...styles.tagItemContainerStyle,
                      marginRight: resizeByResolutionWidth(30),
                    }}
                  />
                )
              )}
            </div>
          ))}
          <div style={styles.row}>
            <Text style={styles.titleFotList}>{i18n.t("all_tags")}</Text>
            <Icon
              width={24}
              height={24}
              name={IconName.PLUS}
              fill={palette.white}
              strokeColor={palette.gray.streak}
              onPress={onPressPlus}
            />
          </div>
          {parsedTags.map((tags, index) => (
            <div key={index} style={styles.row}>
              {tags.map((item) =>
                item ? (
                  <TagItem
                    containerStyle={styles.tagItemContainerStyle}
                    key={item.id}
                    tag={item}
                    onChangeTitle={debounce(onChangeTagTitle, 1000)}
                    onPressDelete={onPressDelete}
                    onPressHeart={onClickHeart}
                    isFav={item.favorite}
                  />
                ) : (
                  <div
                    style={{
                      ...styles.tagItemContainerStyle,
                      marginRight: resizeByResolutionWidth(30),
                    }}
                  />
                )
              )}
            </div>
          ))}
        </div>
      </div>
    </Dialog>
  );
};

const styles: Record<string, React.CSSProperties> = {
  container: {
    overflow: "hidden",
    minHeight: "500px",
    overflowY: "scroll",
  },
  header: {
    backgroundColor: palette.white,
    display: "flex",
    alignItems: "center",
    padding: 30,
    borderBottomWidth: 1,
  },
  title: {
    fontSize: 25,
    fontFamily: "Gilroy-Medium",
    marginLeft: 10,
    display: "flex",
    flex: 1,
  },
  body: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
  main: {
    height: 550,
    display: "flex",
    flex: 1,
    padding: 35,
  },
  subbody: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: resizeByResolutionWidth(40),
    paddingRight: resizeByResolutionWidth(40),
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignSelf: "stretch",
    marginTop: 10,
    alignItems: "center",
  },
  tagItemContainerStyle: {
    marginRight: resizeByResolutionWidth(10),
    display: "flex",
    flex: 1,
  },
  titleFotList: {
    fontSize: 18,
    fontWeight: "600",
    color: palette.gray.dark,
    marginLeft: 20,
  },
};
