import { MagicPlannerHelper } from "helpers/magic-planner.helper";
import { TodoistImage } from "assets/images";
import { GoogleCalendarImage } from "assets/images";
/* eslint-disable react-hooks/exhaustive-deps */
import { MeetsResource } from "communicators/resources/event-resources/meets/meets.resource";
import { MeetEntity } from "communicators/resources/event-resources/meets/meets.type";
import { TasksResource } from "communicators/resources/event-resources/tasks/tasks.resource";
import { TaskEntity } from "communicators/resources/event-resources/tasks/tasks.type";
import { FirebaseService } from "communicators/services/firebase/Firebase.service";
import { DropResult } from "constants/dnd.constants";
import { DragAndDropHelper } from "helpers/dnd.helper";
import {
  AllBoardDataEntityType,
  IBoard,
  ThirdPartyProvider,
} from "interfaces/main.interfaces";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store/store.hooks";
import { HeaderButtonType, streaksJokerImage } from "./constants";
import {
  getAllBoardData,
  getAllInsumoCalendarEventData,
  IInsumoCalendar,
  removeCompletedDataFromAllBoard,
  reorder,
} from "helpers/data.helper";
import { debounce } from "lodash";
import { EventType } from "communicators/resources/event-resources/event-resources.type";
import { IEvent, ITimelineItem } from "./components/DrawerRight/types";
import { TaskGoalResource } from "communicators/resources/task-goal/task-goal.resource";
import { InsumoCalendarEventsResource } from "communicators/resources/insumo-calendar-events/insumo-calendar-events.resource";
import {
  setInsumoCalendarEvent,
  setSomethingWentWrongModal,
} from "redux-store/actions/home-page.actions";
import { useInboxHook } from "./useInboxHook";
import {
  setRefreshCompletedTasks,
  setPreferencesDialog,
  setPreferencesTab,
  setRefreshBoardData,
  setRefreshInboxTasks,
  setRefreshSomedayTasks,
} from "redux-store/actions/home-page.actions";
import {
  auth,
  getTypeOfBoardData,
  todoistWriteAccessChecker,
} from "helpers/common.helper";
import { useUserSettingHook } from "hooks/useUserSettingHook";
import { useGoogleSignInHook } from "hooks/useGoogleSignInHook";
import { i18n } from "constants/i18n.constants";
import { makeTodoistIntegration } from "helpers/third-party.helper";
import { setPermissionModalType } from "redux-store/actions/home-page.actions";
import { Scope } from "communicators/resources/third-party-information/third-party-information.type";
import { CalendarRevokeResource } from "communicators/resources/calendar-revoke/calendar-revoke.resource";
import { setThirdPartyProviderData } from "redux-store/actions/third-party-provider.actions";
import { ButtonProps } from "components/permission-modal/PermissionModal";
import {
  EventEmitter,
  EventEmitterType,
} from "constants/event-emitter.constant";
import { defaultScheduledDate } from "constants/default-scheduled-date.constant";
import { ZeroTaskEntity } from "communicators/resources/zero-task/zero-task.type";
import { ZeroTaskResource } from "communicators/resources/zero-task/zero-task.resource";
import { ResponseWithData } from "communicators/types";
import { PreferenceTab } from "./components/PreferencesDialog/PreferenceDialog.types";
import { useStreakModalHook } from "components/streak-modal/useStreakModalHook";
import { fetchAndCacheImage } from "helpers/utils.helper";
import { MagicPlannerEntity } from "communicators/resources/magic-planner/magic-planner.types";
import { LocalStorageParam } from "constants/local-storage.constant";

export enum PermissionModalType {
  GOOGLE = "Google",
  GOOGLE_MISSING = "Google Missing Permission",
  TODOIST = "Todoist",
}

export enum CalendarAccountType {
  GOOGLE = "google",
  INSUMO = "insumo",
}

export interface AllBoardDataType {
  all: IBoard[];
  meets: IBoard[];
  tasks: IBoard[];
}

export const useHomeHook = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [subscribeModal, setSubscribeModal] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<DateTime>(DateTime.now());
  const [endDate, setEndDate] = useState<DateTime>(
    DateTime.now().plus({ day: 7 })
  );
  const [rightDrawerStartDate, setRightDrawerStartDate] = useState<DateTime>(
    DateTime.now()
  );
  const [activeBoard, setActiveBoard] = useState<HeaderButtonType>(
    HeaderButtonType.ALL
  );
  const [meets, setMeets] = useState<MeetEntity[]>([]);
  const [tasks, setTasks] = useState<TaskEntity[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [allBoardData, setAllBoardData] = useState<AllBoardDataType>({
    all: [],
    meets: [],
    tasks: [],
  });
  const [newDroppedTask, setNewDroppedTask] = useState<TaskEntity>();
  const [timeItem, setTimeItem] = useState<ITimelineItem>();
  const [leftDrawerSearchedDate, setLeftDrawerSearchedDate] =
    useState<DateTime>(DateTime.now());
  const [allInsumoCalendarEventData, setAllInsumoCalendarEventData] = useState<
    IInsumoCalendar[]
  >([]);
  const [rightDrawerData, setRightDrawerData] = useState<IBoard[]>([]);
  const [isRightDrawerLoading, setIsRightDrawerLoading] =
    useState<boolean>(false);
  const [googlePermissionLoading, setGooglePermissionLoading] =
    useState<boolean>(false);
  const [todoistPermissionLoading, setTodoistPermissionLoading] =
    useState<boolean>(false);
  const [init, setInit] = useState<boolean>(false);
  const [isStreakModalVisible, setIsStreakModalVisible] =
    useState<boolean>(false);
  const [isDoneTaskShown, setIsDoneTaskShown] = useState<boolean>(false);
  const [checkedDataId, setCheckedDataId] = useState<string>("");
  const [isBackgroundImageShown, setIsBackgroundImageShown] =
    useState<boolean>(false);
  const [isPlannerLoading, setIsPlannerLoading] = useState<boolean>(false);
  const [magicPlannerRequestResponse, setMagicPlannerRequestResponse] =
    useState<MagicPlannerEntity>({
      status: "pending",
    });
  const [isMagicPlannerOn, setIsMagicPlannerOn] = useState<boolean>(false);
  const [isSendingRequest, setIsSendingRequest] = useState<boolean>(false);
  const [isMagicPlannerSwitchShown, setIsMagicPlannerSwitchShown] =
    useState<boolean>(false);

  let customType: AllBoardDataEntityType;
  type I = typeof customType;
  const isAllBoardDataEntityType = (
    itemType: AllBoardDataEntityType | IEvent | undefined
  ): itemType is I => {
    return [itemType].indexOf(itemType) !== -1;
  };
  const [streakInfo, setStreakInfo] = useState<ZeroTaskEntity>();

  const closeStreakModal = () => {
    setIsStreakModalVisible(false);
  };

  const { setAndGetUserPreferedCalendar } = useUserSettingHook();

  const refreshAllDataWithoutSpinner = () => {
    getInitialData({ showSpinner: false });
    getInitialInsumoCalendarEventData(true);
    getRightDrawerData();
  };

  const {
    inboxData,
    setInboxData,
    inboxPreviousMonth,
    inboxNextMonth,
    inboxStartDate,
    inboxLoading,
    onPressInboxBoardPlus,
    onInboxTaskCreate,
    sortedInboxVisibleData,
    refreshInboxWithoutSpinner,
    deleteInboxTask,
  } = useInboxHook({
    startDate,
    allBoardData,
    setAllBoardData,
    refreshAllDataWithoutSpinner,
    leftDrawerSearchedDate,
  });

  const {
    userInfo,
    preferences,
    refreshBoardData,
    permissionModalType,
    thirdPartyData,
    redeemOpen,
  } = useAppSelector((state) => ({
    userInfo: state.userSetting.userInfo,
    preferences: state.userSetting.preferences,
    refreshBoardData: state.homePage.refreshBoardData,
    permissionModalType: state.homePage.selectedPermissionModalType,
    thirdPartyData: state.thirdPartyProviderData.thirdPartyData,
    redeemOpen: state.homePage.redeemOpen,
  }));

  const { subscriptions, user, somethingWentWrongModalVisible } =
    useAppSelector((state) => ({
      subscriptions: state.userSetting.subscriptions,
      user: state.userSetting.userInfo,
      somethingWentWrongModalVisible:
        state.homePage.somethingWentWrongModalOpened,
    }));

  const todoistScopes = thirdPartyData?.filter(
    (item) => item.provider === ThirdPartyProvider.TODOIST
  )[0]?.scopes;

  const activeSubscriptions = subscriptions.filter(
    (item) =>
      item.attributes.status === "active" ||
      item.attributes.status === "trialing"
  );

  const updateTask = (removed: AllBoardDataEntityType, result: DropResult) => {
    if (
      removed.type === EventType.TASK &&
      removed.attributes.provider === ThirdPartyProvider.TODOIST &&
      !todoistScopes?.includes(Scope.WRITE)
    ) {
      dispatch(setPermissionModalType(PermissionModalType.TODOIST));
      return;
    }
    new TasksResource().updateOnePartial(removed.id, {
      task: {
        completed_count: 0,
        scheduled_time: result.destination.droppableId,
      },
    });
    dispatch(setRefreshInboxTasks());
    refreshAllDataWithoutSpinner();
    if (
      removed?.type === EventType.TASK &&
      removed?.attributes.scheduled_time &&
      DateTime.fromISO(removed?.attributes?.scheduled_time).toFormat(
        "MM/dd/yyyy"
      ) === defaultScheduledDate
    ) {
      dispatch(setRefreshSomedayTasks());
    }
    dispatch(setRefreshCompletedTasks());
    let currentUpdatedTask: AllBoardDataEntityType;
    const copyAllBoard: AllBoardDataType = { ...allBoardData };
    copyAllBoard.all.forEach((currentBoard, index) => {
      currentBoard?.data?.forEach((item, indexOfBdd) => {
        if (item?.id === removed?.id && item.type === EventType.TASK) {
          currentUpdatedTask = {
            ...item,
            attributes: {
              ...item?.attributes,
              scheduled_time: `${result.destination.droppableId}T00:00:00.000Z`,
            },
          };
          copyAllBoard.all[index].data.splice(
            indexOfBdd,
            1,
            currentUpdatedTask
          );
        }
      });
    });
    setAllBoardData(removeCompletedDataFromAllBoard(copyAllBoard));
    EventEmitter.emit(
      EventEmitterType.ON_TASK_DRAGGED,
      `${result.destination.droppableId}T00:00:00.000Z`
    );
    EventEmitter.emit(EventEmitterType.ON_TASK, removed.id);
  };

  const updateWithDebounce = debounce(updateTask, 1000);

  const onSuccessGoogleAuth = async () => {
    dispatch(setThirdPartyProviderData());
    await setAndGetUserPreferedCalendar(CalendarAccountType.GOOGLE);
    refreshBoards(CalendarAccountType.GOOGLE);
    setGooglePermissionLoading(false);
    dispatch(setPermissionModalType(null));
  };

  const onFailGoogleAuth = () => {
    setGooglePermissionLoading(false);
    dispatch(setPermissionModalType(null));
  };

  const { login } = useGoogleSignInHook({
    onSuccess: onSuccessGoogleAuth,
    onFail: onFailGoogleAuth,
  });

  const onPressGooglePermissionGive = async () => {
    setGooglePermissionLoading(true);
    try {
      await new CalendarRevokeResource().readMany();
    } catch (_) {}
    login();
  };

  const onPressGooglePermissionDecline = () => {
    setGooglePermissionLoading(false);
    dispatch(setPermissionModalType(null));
  };

  const googleCalendarPermissionButtonProps: ButtonProps[] = [
    {
      buttonTitle: i18n.t("ok"),
      onPress: onPressGooglePermissionGive,
      loading: googlePermissionLoading,
      defaultSelection: true,
      spinnerColor: "primary",
    },
    {
      buttonTitle: i18n.t("discard"),
      onPress: onPressGooglePermissionDecline,
      loading: false,
      defaultSelection: false,
    },
  ];

  const googleCalendarMissingPermissionButtonProps: ButtonProps[] = [
    {
      buttonTitle: i18n.t("retry"),
      onPress: onPressGooglePermissionGive,
      loading: googlePermissionLoading,
      defaultSelection: true,
      spinnerColor: "primary",
    },
    {
      buttonTitle: i18n.t("connect_later"),
      onPress: onPressGooglePermissionDecline,
      loading: false,
      defaultSelection: false,
    },
  ];

  const onPressTodoistPermissionGive = () => {
    makeTodoistIntegration();
    setTodoistPermissionLoading(false);
    dispatch(setPermissionModalType(null));
  };

  const onPressTodoistPermissionDecline = () => {
    setTodoistPermissionLoading(false);
    dispatch(setPermissionModalType(null));
  };

  const todoistPermissionButtonProps: ButtonProps[] = [
    {
      buttonTitle: i18n.t("ok"),
      onPress: onPressTodoistPermissionGive,
      loading: todoistPermissionLoading,
      defaultSelection: true,
    },
    {
      buttonTitle: i18n.t("discard"),
      onPress: onPressTodoistPermissionDecline,
      loading: todoistPermissionLoading,
      defaultSelection: false,
    },
  ];

  const getPermissionModalProps = () => {
    if (permissionModalType === PermissionModalType.GOOGLE) {
      return {
        open: true,
        image: GoogleCalendarImage,
        buttonProps: googleCalendarPermissionButtonProps,
      };
    } else if (permissionModalType === PermissionModalType.TODOIST) {
      return {
        open: true,
        image: TodoistImage,
        buttonProps: googleCalendarPermissionButtonProps,
      };
    } else if (permissionModalType === PermissionModalType.GOOGLE_MISSING) {
      return {
        open: true,
        image: GoogleCalendarImage,
        buttonProps: googleCalendarMissingPermissionButtonProps,
        title: i18n.t("missing_permission"),
      };
    } else {
      return {
        open: false,
        image: TodoistImage,
        buttonProps: todoistPermissionButtonProps,
      };
    }
  };

  const permissionModalProps = getPermissionModalProps();

  const handleDateChange = () => {
    if (
      rightDrawerStartDate.startOf("day").toSQLDate() ===
      endDate.startOf("day").toSQLDate()
    ) {
      setStartDate(endDate);
      setEndDate(endDate.plus({ day: 7 }));
    } else if (
      rightDrawerStartDate.startOf("day").toSQLDate()! <
      startDate.startOf("day").toSQLDate()!
    ) {
      setStartDate(startDate.minus({ day: 7 }));
      setEndDate(startDate);
    }
  };

  const loginType = auth.currentUser?.providerData[0].providerId;
  const verifyEmailPopupVisibilityState = () => {
    if (
      userInfo?.attributes?.email_verified ||
      auth?.currentUser?.emailVerified ||
      loginType === "google.com"
    ) {
      return false;
    } else return true;
  };

  const [isVerifyEmailPopupVisible, setIsVerifyEmailPopupVisible] =
    useState<boolean>(verifyEmailPopupVisibilityState());

  const toggleVerifyEmailPopup = () => {
    setIsVerifyEmailPopupVisible(false);
  };

  let movedToInboxTaskTitles: string[] = [];

  if (activeBoard === HeaderButtonType.ALL) {
    allBoardData?.all.forEach((data) => {
      data?.data?.forEach((item) => {
        if (item?.type === EventType.TASK && item?.attributes?.moved_to_inbox) {
          movedToInboxTaskTitles.push(item.attributes.title);
        }
      });
    });
  } else if (activeBoard === HeaderButtonType.TASKS) {
    allBoardData.tasks.forEach((data) => {
      data?.data?.forEach((item) => {
        if (item?.type === EventType.TASK && item?.attributes?.moved_to_inbox) {
          movedToInboxTaskTitles.push(item?.attributes?.title);
        }
      });
    });
  } else {
    movedToInboxTaskTitles = [];
  }

  const movedToInboxPopupVisibilityState = () => {
    if (movedToInboxTaskTitles.length !== 0) {
      return true;
    } else return false;
  };

  const [isMovedToInboxPopupVisible, setIsMovedToInboxPopupVisible] =
    useState<boolean>(movedToInboxPopupVisibilityState());

  const onCloseMovedToInboxPopup = () => {
    setIsMovedToInboxPopupVisible(false);
  };

  const getMeets = async (resync?: boolean) => {
    try {
      const response = await new MeetsResource().readMany(
        {
          start_date: startDate.minus({ day: 1 }).toSQLDate(),
          end_date: endDate.toSQLDate(),
          inclusive: true,
          per: 1000,
        },
        resync
      );
      setMeets(response.data);
      return response.data;
    } catch (_) {
      return [];
    }
  };

  const getTasks = async (resync?: boolean) => {
    try {
      const response = await new TasksResource().readMany({
        start_date: startDate.toSQLDate(),
        end_date: endDate.toSQLDate(),
        per: 1000,
      });
      setTasks(response.data);
      return response.data;
    } catch (_) {
      return [];
    }
  };

  const refreshTasksWithoutLoader = async () => {
    const tasksResponse = await getTasks(false);
    const tasksData = getAllBoardData(tasksResponse, startDate, false);
    setAllBoardData({ ...allBoardData, tasks: tasksData });
  };

  const getInitialData = async ({
    prefferedCalendar,
    showSpinner = true,
  }: {
    prefferedCalendar?: CalendarAccountType;
    showSpinner?: boolean;
  }) => {
    setLoading(showSpinner && true);
    setInit(true);
    const meetsResponse =
      (prefferedCalendar || preferences?.attributes.preferred_calendar) ===
      CalendarAccountType.GOOGLE
        ? await getMeets(!init)
        : [];
    const tasksResponse = await getTasks(!init);
    if (!meetsResponse && !tasksResponse) return;
    const boardData = getAllBoardData(
      [...meetsResponse, ...tasksResponse],
      startDate,
      false
    );
    const meetsData = getAllBoardData(meetsResponse, startDate, false);
    const tasksData = getAllBoardData(tasksResponse, startDate, false);
    setAllBoardData(
      removeCompletedDataFromAllBoard({
        all: boardData,
        meets: meetsData,
        tasks: tasksData,
      })
    );
    setLoading(false);
  };

  const getRightDrawerData = async () => {
    setIsRightDrawerLoading(true);
    const meetsResponse = await getMeets(false);
    if (!meetsResponse) return;
    const rightDrawerInitialData = getAllBoardData(
      [...meetsResponse],
      rightDrawerStartDate,
      false
    );
    setRightDrawerData(rightDrawerInitialData);
    setIsRightDrawerLoading(false);
  };

  const onTaskMeetAction = async () => {
    if (
      preferences?.attributes.preferred_calendar === CalendarAccountType.INSUMO
    ) {
      getInitialInsumoCalendarEventData();
    } else {
      getRightDrawerData();
    }
  };

  const refreshBoards = (prefferedCalendar?: CalendarAccountType) => {
    getInitialData({ prefferedCalendar });
    getRightDrawerData();
    getInitialInsumoCalendarEventData();
  };

  const onDragEnd = (result: DropResult) => {
    if (
      !result.destination ||
      result.destination.droppableId === "right_drawer"
    ) {
      return;
    }
    let board = allBoardData.all;
    if (activeBoard === HeaderButtonType.MEETS) {
      board = allBoardData.meets;
    } else if (activeBoard === HeaderButtonType.TASKS) {
      board = allBoardData.tasks;
    }
    let sourceItem = isAllBoardDataEntityType(
      DragAndDropHelper?.draggingEvent?.data
    )
      ? DragAndDropHelper?.draggingEvent?.data
      : customType;
    if (sourceItem?.type === EventType.TASK) {
      sourceItem = {
        ...sourceItem,
        attributes: {
          ...sourceItem.attributes,
          completed_count: 0,
        },
      };
    }
    const { copyBoard, removed } = reorder(result, board, sourceItem);
    if (!removed || !copyBoard) return;
    updateWithDebounce(removed, result);
    if (activeBoard === HeaderButtonType.MEETS) {
      boardData = allBoardData.meets;
      setAllBoardData(
        removeCompletedDataFromAllBoard({ ...allBoardData, meets: copyBoard })
      );
    } else if (activeBoard === HeaderButtonType.TASKS) {
      boardData = allBoardData.tasks;
      setAllBoardData(
        removeCompletedDataFromAllBoard({ ...allBoardData, tasks: copyBoard })
      );
    } else {
      setAllBoardData(
        removeCompletedDataFromAllBoard({ ...allBoardData, all: copyBoard })
      );
    }
  };

  const onHoverRightDrawer = (
    data: AllBoardDataEntityType,
    rightDrawerDate: DateTime
  ) => {
    const { board, currentIndex } = DragAndDropHelper.getBoardIdByBoardItemId(
      data.id
    );
    const sourceBoard = board;
    const sourceIndex = currentIndex;
    const destinationBoard = rightDrawerDate.toSQLDate()!;
    const destinationIndex = DragAndDropHelper.getLatestIndexOfBoard(
      destinationBoard!
    );
    if (!sourceBoard || !destinationBoard || sourceBoard === destinationBoard)
      return;
    const result: DropResult = {
      destination: { droppableId: destinationBoard, index: destinationIndex },
      source: { droppableId: sourceBoard, index: sourceIndex },
    };
    onDragEnd(result);
  };

  const onSelectDate = (date: DateTime) => {
    setStartDate(date);
    setEndDate(date.plus({ day: 7 }));
  };
  let boardData = allBoardData.all;
  if (activeBoard === HeaderButtonType.MEETS) {
    boardData = allBoardData.meets;
  } else if (activeBoard === HeaderButtonType.TASKS) {
    boardData = allBoardData.tasks;
  }

  const onPressAllBoardPlus = (date: DateTime) => {
    if (activeBoard === HeaderButtonType.MEETS) return;
    const copyAllBoard: AllBoardDataType = { ...allBoardData };
    copyAllBoard.all.forEach((currentBoard, index) => {
      if (currentBoard.date.toSQL() === date.toSQL()) {
        const newTaskEntity: TaskEntity = {
          id: "",
          type: EventType.TASK,
          attributes: {
            title: "",
            description: "",
            due_date: date.toSQL()!,
            start_date: "",
            end_date: "",
            start_time: null,
            end_time: null,
            permalink_url: null,
            provider: "",
            task_gid: "",
            created_at: date.toSQL()!,
            updated_at: date.toSQL()!,
            completed_count: 0,
            scheduled_time: date.toSQLDate(),
          },
        };
        copyAllBoard.all[index].data.unshift(newTaskEntity);
        copyAllBoard.tasks[index].data.unshift(newTaskEntity);
      }
    });
    setAllBoardData(removeCompletedDataFromAllBoard(copyAllBoard));
  };

  const onTaskCreate = (
    index: number,
    boardDate: DateTime,
    createdTask: TaskEntity,
    isNewTask: boolean
  ) => {
    const copyAllBoard: AllBoardDataType = { ...allBoardData };
    copyAllBoard.all.forEach((currentBoard, boardIndex) => {
      if (currentBoard.date.toSQL() === boardDate.toSQL()) {
        const boardItemId = copyAllBoard.all[boardIndex].data[index].id;
        if (isNewTask && boardItemId) {
          copyAllBoard.all[boardIndex].data.splice(index, 0, createdTask);
        } else {
          copyAllBoard.all[boardIndex].data[index] = createdTask;
        }
      }
    });
    copyAllBoard.tasks.forEach((currentBoard, boardIndex) => {
      if (currentBoard.date.toSQL() === boardDate.toSQL()) {
        copyAllBoard.tasks[boardIndex].data[index] = createdTask;
      }
    });
    refreshInboxWithoutSpinner();
    setAllBoardData(removeCompletedDataFromAllBoard(copyAllBoard));
  };

  const onPressNextDay = () => {
    setRightDrawerStartDate(rightDrawerStartDate.plus({ day: 1 }));
  };

  const onPressPreviousDay = () => {
    setRightDrawerStartDate(rightDrawerStartDate.minus({ day: 1 }));
  };

  const onClickToday = () => {
    setRightDrawerStartDate(DateTime.now());
  };

  const geteTaskDate = (dataObject: TaskEntity) => {
    return dataObject.attributes.scheduled_time
      ? DateTime.fromISO(dataObject.attributes.scheduled_time)
      : DateTime.fromSQL(dataObject.attributes.due_date);
  };

  const getMeetDate = (dataObject: MeetEntity) => {
    return DateTime.fromISO(dataObject.attributes.meet_start_time);
  };

  const getStreakInfo = async () => {
    try {
      const result: ResponseWithData<any> =
        await new ZeroTaskResource().readMany();
      if (result && result.data) {
        setStreakInfo(result.data);
      }
    } catch (_) {}
  };

  useEffect(() => {
    getStreakInfo();
  }, []);

  const updateTaskGoal = async (data: TaskEntity) => {
    try {
      const response = await new TaskGoalResource().createOne({
        task_goal: {
          task_id: data.id,
          completed_date: DateTime.now().toSQLDate(),
        },
      });
      if (
        streakInfo &&
        (streakInfo?.active_streak === null
          ? response?.streak?.active_streak.length === 1
          : response.streak.active_streak.length ===
            streakInfo?.active_streak!?.length + 1)
      ) {
        getStreakInfo();
        setIsStreakModalVisible(true);
      }
      dispatch(setRefreshCompletedTasks());
    } catch (_) {}
  };

  const { isAchievementModalVisible } = useStreakModalHook({
    closeModal: closeStreakModal,
    isVisible: isStreakModalVisible,
    streakInfo,
  });

  const [shownBackgroundImage, setShownBackgroundImage] =
    useState<string>(streaksJokerImage);

  const getTodaysStreakImage = (date: DateTime) => {
    const todaysDate = date.toFormat("dd.MM.yyyy");
    return `https://insumo-achievement-background-assets.s3.amazonaws.com/${todaysDate}.jpg`;
  };

  const todaysImage = getTodaysStreakImage(DateTime.now());

  useEffect(() => {
    setShownBackgroundImage(getTodaysStreakImage(DateTime.now()));
  }, [todaysImage]);

  useEffect(() => {
    if (
      (isBackgroundImageShown || isAchievementModalVisible) &&
      shownBackgroundImage
    ) {
      fetchAndCacheImage(shownBackgroundImage)
        .then((image) => {
          // Image is loaded and cached
          if (location.pathname === "/home") {
            document.body.style.background = `linear-gradient(to top, #ffffff 0.1%, rgba(229, 227, 248, 0) 0.1%, rgba(237, 248, 255, 0.01) 32.57%, #ffffff 98.37%),
          url('${shownBackgroundImage}') no-repeat center center fixed`;
            document.body.style.backgroundSize = "cover";
          } else {
            document.body.style.backgroundImage = "null";
            document.body.style.backgroundColor = "null";
          }
        })
        .catch((error) => {
          console.error("Error fetching image:", error);
        });
    }
  }, [
    isBackgroundImageShown,
    isAchievementModalVisible,
    shownBackgroundImage,
    location.pathname,
  ]);

  const onClickCheckBox = async (data: AllBoardDataEntityType) => {
    if (data.type !== EventType.TASK) return;
    if (!todoistWriteAccessChecker(data, todoistScopes)) return;
    try {
      await updateTaskGoal(data);
      const inbox: IBoard[] = [...inboxData];
      let inboxIndex = -1;
      let inboxBoardIndex = -1;
      for (let i = 0; i < inbox.length; i++) {
        const foundIndexForInbox = inbox[i].data.findIndex(
          (item) => item.id === data.id
        );
        if (foundIndexForInbox > 0) {
          inboxIndex = foundIndexForInbox;
          inboxBoardIndex = i;
        }
      }
      if (inboxBoardIndex >= 0 && inboxIndex >= 0) {
        inbox[inboxBoardIndex].data[inboxIndex] = data;
      }
      getTasks();
      setInboxData(inbox);
      const all = [...allBoardData.all];
      let allIndex = -1;
      let allBoardIndex = -1;
      let tasksIndex = -1;
      let tasksBoardIndex = -1;
      for (let i = 0; i < all.length; i++) {
        const foundIndexForAll = all[i].data.findIndex(
          (item) => item.id === data.id
        );
        if (foundIndexForAll >= 0) {
          allIndex = foundIndexForAll;
          allBoardIndex = i;
        }
      }
      let push = false;
      if (
        allIndex === -1 &&
        allBoardIndex === -1 &&
        data.attributes.completed_count === 0 &&
        data.attributes.scheduled_time
      ) {
        const dataBoardIndex = all.findIndex(
          (item) =>
            item.date.toSQLDate() ===
            DateTime.fromISO(data.attributes.scheduled_time || "").toSQLDate()
        );
        if (dataBoardIndex >= 0) {
          allIndex = dataBoardIndex;
          allBoardIndex = all[dataBoardIndex].data.length;
          tasksIndex = dataBoardIndex;
          tasksBoardIndex = allBoardIndex;
          push = true;
        }
      }
      const tasks = [...allBoardData.tasks];
      for (let i = 0; i < all.length; i++) {
        const foundIndexForAll = all[i].data.findIndex(
          (item) => item.id === data.id
        );
        if (foundIndexForAll >= 0) {
          tasksIndex = foundIndexForAll;
          tasksBoardIndex = i;
        }
      }
      if (tasksBoardIndex >= 0 && tasksIndex >= 0) {
        if (push) {
          tasks[tasksBoardIndex].data.push(data);
        } else {
          tasks[tasksBoardIndex].data[tasksIndex] = data;
        }
      }
      if (allBoardIndex >= 0 && allIndex >= 0) {
        if (push) {
          all[allIndex].data.push(data);
        } else {
          all[allIndex].data[allBoardIndex] = data;
        }
      }

      setAllBoardData(
        removeCompletedDataFromAllBoard({
          ...allBoardData,
          all: all,
          tasks: tasks,
        })
      );
      refreshTasksWithoutLoader();
    } catch {}
  };

  const updateTaskWithRightDrawerDrop = (
    newestDroppedTask: TaskEntity,
    newTimeItem: ITimelineItem
  ) => {
    setNewDroppedTask(newestDroppedTask);
    setTimeItem(newTimeItem);
  };

  const updateNewTask = (id: string, time: string) => {
    if (!todoistWriteAccessChecker(newDroppedTask, todoistScopes)) return;
    new TasksResource().updateOnePartial(id, { scheduled_time: time });
  };

  const updateWithDebounceNewTask = debounce(updateNewTask, 1001);

  const showNotification = (message: string) => {
    new Notification(message);
  };

  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("Browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
    if (redeemOpen) {
      dispatch(setPreferencesTab(PreferenceTab.REDEEM_CODE));
      dispatch(setPreferencesDialog(true));
    }
  }, []);

  useEffect(() => {
    if (user?.attributes?.email_verified) {
      setIsVerifyEmailPopupVisible(false);
    }
  }, [user]);

  const getRelocatedDate = (data: AllBoardDataEntityType) => {
    let date: DateTime;
    if (data.type === EventType.MEET && data.attributes.meet_start_time) {
      date = getMeetDate(data);
    } else if (
      data.type === EventType.TASK &&
      (data.attributes.scheduled_time || data.attributes.due_date)
    ) {
      date = geteTaskDate(data);
    } else date = DateTime.now();
    return date;
  };

  const onPressSearchItem = (data: AllBoardDataEntityType) => {
    EventEmitter.emit(EventEmitterType.CHANGE_LEFT_DRAWER_TYPE, {
      leftDrawerType: getTypeOfBoardData(data),
    });
    if (
      data.type === EventType.TASK &&
      data?.attributes.scheduled_time &&
      DateTime.fromISO(data?.attributes?.scheduled_time).toFormat(
        "MM/dd/yyyy"
      ) !== defaultScheduledDate
    ) {
      setLeftDrawerSearchedDate(getRelocatedDate(data));
    }
    if (
      data.type === EventType.TASK &&
      data?.attributes.completed_count === 0 &&
      data?.attributes.scheduled_time &&
      DateTime.fromISO(data?.attributes?.scheduled_time).toFormat(
        "MM/dd/yyyy"
      ) !== defaultScheduledDate
    ) {
      setStartDate(getRelocatedDate(data));
    }
  };

  const onClickResendEmailVerification = async () => {
    await auth?.currentUser?.reload();
    if (!auth.currentUser?.emailVerified) {
      await new FirebaseService().sendVerifyEmail();
    }
  };

  useEffect(() => {
    if (
      newDroppedTask?.attributes.scheduled_time &&
      DateTime.fromISO(
        newDroppedTask?.attributes.scheduled_time
      ).toSQLDate() === timeItem?.time.toSQLDate()
    ) {
      return;
    }
    if (newDroppedTask && timeItem) {
      let removedId: number = -1;
      let removedDataId: number = -1;
      let toId: number = -1;
      const copyAllBoard = { ...allBoardData };
      copyAllBoard?.all?.forEach((bd, index) => {
        bd?.data?.forEach((bdd, indexOfBdd) => {
          if (bdd?.id === newDroppedTask?.id) {
            removedId = index;
            removedDataId = indexOfBdd;
          }
        });

        if (
          bd.date.startOf("day").toSQL() ===
          timeItem?.time.startOf("day").toSQL()
        ) {
          toId = index;
        }
      });
      let tasksRemovedId: number = -1;
      let tasksRemovedDataId: number = -1;
      let tasksToId: number = -1;
      copyAllBoard?.tasks?.forEach((bd, index) => {
        bd?.data?.forEach((bdd, indexOfBdd) => {
          if (bdd?.id === newDroppedTask?.id) {
            tasksRemovedId = index;
            tasksRemovedDataId = indexOfBdd;
          }
        });

        if (
          bd.date.startOf("day").toSQL() ===
          timeItem?.time.startOf("day").toSQL()
        ) {
          tasksToId = index;
        }
      });

      if (
        removedDataId !== -1 &&
        removedId !== -1 &&
        toId !== -1 &&
        tasksRemovedDataId !== -1 &&
        tasksRemovedId !== -1 &&
        tasksToId !== -1
      ) {
        copyAllBoard?.all?.[toId].data.push(newDroppedTask);
        copyAllBoard?.all?.[removedId].data.splice(removedDataId, 1);

        copyAllBoard?.tasks?.[tasksToId].data.push(newDroppedTask);
        copyAllBoard?.tasks?.[tasksRemovedId].data.splice(
          tasksRemovedDataId,
          1
        );
        setAllBoardData(
          removeCompletedDataFromAllBoard({
            ...copyAllBoard,
            all: copyAllBoard.all,
            tasks: copyAllBoard.tasks,
          })
        );
        updateWithDebounceNewTask(
          newDroppedTask.id,
          timeItem?.time.toSQLDate()!
        );
      }
    }
  }, [newDroppedTask, timeItem]);

  const getInsumoCalendarEventData = async () => {
    const { data } = await new InsumoCalendarEventsResource().readMany({
      start_date: startDate.minus({ day: 1 }).toSQLDate(),
      end_date: endDate.plus({ day: 1 }).toSQLDate(),
    });
    return data;
  };

  const getInitialInsumoCalendarEventData = async (
    dontShowSpinner?: boolean
  ) => {
    setLoading(!dontShowSpinner && true);
    const insumoCalendarEventData = await getInsumoCalendarEventData();
    if (!insumoCalendarEventData) return;
    const allInsumoCalendarEventData = getAllInsumoCalendarEventData(
      insumoCalendarEventData,
      rightDrawerStartDate
    );
    setAllInsumoCalendarEventData(allInsumoCalendarEventData);
    dispatch(setInsumoCalendarEvent(allInsumoCalendarEventData));
    setLoading(false);
  };

  useEffect(() => {
    handleDateChange();
    getRightDrawerData();
    getInitialInsumoCalendarEventData();
  }, [rightDrawerStartDate]);

  useEffect(() => {
    getInitialData({});
    getRightDrawerData();
    getInitialInsumoCalendarEventData();
  }, [startDate]);

  useEffect(() => {
    let savedBoardData = allBoardData.all;
    let savedRightDrawerBoardData = allBoardData.all;
    if (activeBoard === HeaderButtonType.MEETS) {
      savedBoardData = allBoardData.meets;
      savedRightDrawerBoardData = allBoardData.meets;
    } else if (activeBoard === HeaderButtonType.TASKS) {
      savedBoardData = allBoardData.tasks;
      savedRightDrawerBoardData = allBoardData.tasks;
    }
    DragAndDropHelper.setBoardData(savedBoardData);
    DragAndDropHelper.setBoardData(savedRightDrawerBoardData);
  }, [activeBoard, allBoardData]);

  useEffect(() => {
    if (user && (user.attributes.is_premium || user.attributes.is_lifetime)) {
      return;
    }
    if (activeSubscriptions.length === 0) {
      setSubscribeModal(true);
      return;
    }
    let isSubscriptionEnd = true;
    subscriptions.forEach((subscription) => {
      if (
        DateTime.fromISO(
          subscription.attributes.current_period_end.split(" ")[0]
        ) > DateTime.now()
      ) {
        isSubscriptionEnd = false;
      }
    });
    setSubscribeModal(isSubscriptionEnd);
  }, [subscriptions, user]);

  const subscribe = () => {
    navigate("/checkout");
  };

  const deleteTask = async (data: AllBoardDataEntityType) => {
    try {
      const all = allBoardData.all;
      all.forEach((item) => {
        item.data = item.data.filter((subitem) => subitem.id !== data.id);
      });
      const tasks = allBoardData.tasks;
      tasks.forEach((item) => {
        item.data = item.data.filter((subitem) => subitem.id !== data.id);
      });
      setAllBoardData(
        removeCompletedDataFromAllBoard({
          ...allBoardData,
          all: all,
          tasks: tasks,
        })
      );
      deleteInboxTask(data);
      await new TasksResource().removeOne(data.id);
    } catch {}
  };

  const handleCloseSomethingWentWrongModal = () => {
    dispatch(setSomethingWentWrongModal(false));
  };

  useEffect(() => {
    refreshAllDataWithoutSpinner();
  }, [refreshBoardData]);

  let todaysTasks: IBoard[];

  useEffect(() => {
    todaysTasks = [...allBoardData?.all].filter(
      (item) => item.date.toSQLDate() === DateTime.now().toSQLDate()
    );

    const todayTasksAll = [...tasks].filter(
      (task) =>
        DateTime.fromISO(
          task.attributes.scheduled_time || task.attributes.due_date
        ).toSQLDate() === DateTime.now().toSQLDate()
    );
    const todayUncompletedTasks = todaysTasks[0]?.data.filter(
      (item) =>
        item.type === EventType.TASK && item.attributes.completed_count === 0
    );
    if (todayUncompletedTasks?.length === 0 && todayTasksAll.length > 0) {
      setIsBackgroundImageShown(true);
    } else {
      setIsBackgroundImageShown(false);
    }
  }, [allBoardData, tasks]);

  const targetTime = DateTime.now()
    .toLocal()
    .set({ hour: 19, minute: 0, second: 0 });

  let magicPlannerText: string = "";

  const todaysEvents = [...allBoardData?.all].filter(
    (item) => item.date.toSQLDate() === DateTime.now().toSQLDate()
  );

  let taskCount: number = 0;
  const replacedTask: TaskEntity[] = [];
  todaysEvents[0]?.data?.forEach((item) => {
    const isMeet = todaysEvents[0]?.data?.find(
      (meet) =>
        meet.type === EventType.MEET && meet.attributes.task_id === item.id
    );
    if (item?.type === EventType.TASK && !isMeet) {
      replacedTask.push(item);
      taskCount += 1;
    }
  });

  if (todaysEvents.length === 0 || todaysEvents[0]?.data?.length === 0) {
    magicPlannerText = i18n.t("magic_planner_scenario_1");
  }
  if (
    todaysEvents[0]?.data.every((item) => item.type === EventType.MEET) &&
    !(todaysEvents.length === 0 || todaysEvents[0]?.data?.length === 0)
  ) {
    magicPlannerText = i18n.t("magic_planner_scenario_2");
  }
  if (
    MagicPlannerHelper.intervals.length <= replacedTask.length &&
    replacedTask.length <= 3
  ) {
    magicPlannerText = i18n.t("magic_planner_scenario_3");
  }
  if (replacedTask.length > 3) {
    magicPlannerText = i18n.t("magic_planner_scenario_4");
  }
  if (DateTime.now().toLocal() > targetTime) {
    magicPlannerText = i18n.t("magic_planner_scenario_5");
  }

  const now = DateTime.now().toFormat("dd/MM/yyyy");

  function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const getRandomIntervals = (replacedTasks: TaskEntity[]) => {
    const availableIntervals = [...MagicPlannerHelper.intervals];
    return availableIntervals
      .sort(() => Math.random() - Math.random())
      .slice(0, replacedTasks.length);
  };

  const moveToMagicPlanner = async () => {
    const availableIntervals = [...MagicPlannerHelper.intervals];
    if (replacedTask.length <= availableIntervals.length) {
      const randomIntervals = getRandomIntervals(replacedTask);
      const ids = [];
      for (let i = 0; i < replacedTask.length; i++) {
        const meetResponse = await new MeetsResource().createOne({
          meet: {
            summary: replacedTask[i].attributes.title,
            description: replacedTask[i].attributes.description,
            start_time: randomIntervals[i].start.toUTC().toISO(),
            end_time: randomIntervals[i].end.toUTC().toISO(),
            task_id: replacedTask[i].id,
            moved_by_magic_planner: true,
          },
        });
        ids.push(meetResponse?.data?.id);
        await sleep(1500);
      }
      localStorage.setItem(
        LocalStorageParam.MAGIC_PLANNER_IDS,
        JSON.stringify(ids)
      );
    }
  };

  const onClickMagicPlan = async () => {
    setIsPlannerLoading(true);
    try {
      setIsSendingRequest(true);
      await moveToMagicPlanner();
      dispatch(setRefreshBoardData());
      setIsMagicPlannerOn(true);
      localStorage.setItem(LocalStorageParam.MAGIC_PLANNER_DATE, now);
      setIsSendingRequest(false);
      setIsPlannerLoading(false);
      setIsMagicPlannerSwitchShown(true);
    } catch (_) {}
  };

  useEffect(() => {
    if (window.localStorage !== undefined) {
      const magicPlannerDate = localStorage.getItem(
        LocalStorageParam.MAGIC_PLANNER_DATE
      );
      if (now === magicPlannerDate) {
        setIsMagicPlannerSwitchShown(true);
      }
    }
  }, []);

  const toggleMagicPlanner = () => {
    setIsMagicPlannerOn(!isMagicPlannerOn);
  };

  return {
    loading,
    boardData,
    onDragEnd,
    setActiveBoard,
    onSelectDate,
    onPressAllBoardPlus,
    onTaskCreate,
    isVerifyEmailPopupVisible,
    toggleVerifyEmailPopup,
    onPressNextDay,
    onPressPreviousDay,
    startDate,
    rightDrawerStartDate,
    updateTaskWithRightDrawerDrop,
    showNotification,
    onPressSearchItem,
    leftDrawerSearchedDate,
    onClickCheckBox,
    deleteTask,
    allInsumoCalendarEventData,
    rightDrawerData,
    isRightDrawerLoading,
    onClickResendEmailVerification,
    refreshBoards,
    onTaskMeetAction,
    subscribeModal,
    subscribe,
    allBoardData,
    setAllBoardData,
    handleCloseSomethingWentWrongModal,
    somethingWentWrongModalVisible,
    inboxPreviousMonth,
    inboxNextMonth,
    inboxStartDate,
    inboxLoading,
    onPressInboxBoardPlus,
    onInboxTaskCreate,
    sortedInboxVisibleData,
    onHoverRightDrawer,
    permissionModalProps,
    isStreakModalVisible,
    closeStreakModal,
    activeBoard,
    isDoneTaskShown,
    checkedDataId,
    streakInfo,
    isBackgroundImageShown,
    isMovedToInboxPopupVisible,
    onCloseMovedToInboxPopup,
    movedToInboxTaskTitles,
    isPlannerLoading,
    magicPlannerText,
    onClickMagicPlan,
    toggleMagicPlanner,
    isMagicPlannerOn,
    isMagicPlannerSwitchShown,
    onClickToday,
    refreshAllDataWithoutSpinner
  };
};
