import { UserPreferencesResource } from "communicators/resources/user-preferences/user-preferences.resource";
import { CalendarAccountType } from "pages/private/home/useHomeHook";
import { useEffect, useState } from "react";
import { setUserPreferences } from "redux-store/actions/user-settings.action";
import { useAppDispatch, useAppSelector } from "redux-store/store.hooks";

export const useUserSettingHook = () => {
  const [preferencesLoading, setPreferencesLoading] = useState(false);
  const dispatch = useAppDispatch();

  const { preferences, firebaseUuid } = useAppSelector((state) => ({
    preferences: state.userSetting.preferences,
    firebaseUuid: state.userSetting.firebaseUuid,
  }));

  const getUserPreferences = async () => {
    setPreferencesLoading(true);
    try {
      const response = await new UserPreferencesResource().readMany();
      if (response.data && response.data[0]) {
        dispatch(setUserPreferences(response.data[0]));
      }
    } catch (_) {}
    setPreferencesLoading(false);
  };

  const setAndGetUserPreferedCalendar = async (
    calendar: CalendarAccountType
  ) => {
    if (!preferences) return;
    try {
      dispatch(
        setUserPreferences({
          ...preferences,
          attributes: {
            ...preferences.attributes,
            preferred_calendar: calendar,
          },
        })
      );
      await new UserPreferencesResource().updateOnePartial(preferences.id, {
        user_preference: {
          preferred_calendar: calendar,
        },
      });
      await getUserPreferences();
    } catch (_) {}
  };

  useEffect(() => {
    if(!preferences){
      getUserPreferences();
    }
  }, [preferences])

  return {
    getUserPreferences,
    setAndGetUserPreferedCalendar,
    preferencesLoading,
    preferences,
    firebaseUuid,
  };
};
