import React from "react";
import Drawer from "@mui/material/Drawer";
import { DRAWER_WIDTH } from "../../constants";
import { Timeline } from "./components/Timeline/Timeline";
import { AllBoardDataEntityType, IBoard } from "interfaces/main.interfaces";
import { Text } from "elements/text/Text";
import { i18n } from "constants/i18n.constants";
import { palette } from "assets/palette";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { useDrawerRightHook } from "./useDrawerRightHook";
import { CalendarSelection } from "./components/CalendarSelection/CalendarSelection";
import { DateTime } from "luxon";
import { TaskEntity } from "communicators/resources/event-resources/tasks/tasks.type";
import { ITimelineItem } from "./types";
import { IInsumoCalendar } from "helpers/data.helper";
import { Spinner } from "elements/spinner/Spinner";
import { CalendarAccountType } from "../../useHomeHook";
import { IOSSwitch } from "elements/switch/Switch";

interface Props {
  open?: boolean;
  rightDrawerData: IBoard[];
  onPressPreviousDay: () => void;
  onPressNextDay: () => void;
  rightDrawerStartDate: DateTime;
  updateTaskWithRightDrawerDrop: (
    newDroppedTask: TaskEntity,
    timeItem: ITimelineItem
  ) => void;
  insumoCalendarEventData: IInsumoCalendar[];
  refreshBoards: (prefferedCalendar?: CalendarAccountType) => void;
  isLoading?: boolean;
  onHoverRightDrawer?: (data: AllBoardDataEntityType, time: DateTime) => void;
  toggleMagicPlanner?: () => void;
  magicPlannerState?: boolean;
  isMagicPlannerSwitchShown?: boolean;
  onClickToday?: () => void;
}

export const DrawerRight = ({
  open,
  rightDrawerData,
  onPressPreviousDay,
  onPressNextDay,
  rightDrawerStartDate,
  updateTaskWithRightDrawerDrop,
  isLoading,
  insumoCalendarEventData,
  refreshBoards,
  onHoverRightDrawer,
  toggleMagicPlanner,
  magicPlannerState,
  isMagicPlannerSwitchShown,
  onClickToday,
}: Props) => {
  const {
    toggleCalendarSelection,
    isCalendarSelectionOpen,
    ref,
    iconRef,
    onPressInsumo,
    onPressGoogle,
    calendarIDs,
    dateDisplay,
    googleLoading,
  } = useDrawerRightHook({
    refreshBoards,
    rightDrawerStartDate,
    rightDrawerData,
    magicPlannerState,
  });
  return (
    <Drawer
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: DRAWER_WIDTH,
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="right"
      open={open}
    >
      <div style={styles.scheduleContainer}>
        <div style={styles.scheduleTopContainer}>
          <div
            style={
              isCalendarSelectionOpen
                ? styles.openedSuitcaseContainer
                : styles.closedSuitcaseContainer
            }
            onClick={toggleCalendarSelection}
            ref={iconRef}
          >
            <Icon name={IconName.SCHEDULE_SUITCASE} style={styles.cursor} />
          </div>
          <Text style={styles.title}>{i18n.t("daily_schedule")}</Text>
        </div>
        {isCalendarSelectionOpen ? (
          <div style={styles.modal} ref={ref}>
            <CalendarSelection
              onPressGoogle={onPressGoogle}
              onPressInsumo={onPressInsumo}
              calendarIDs={calendarIDs}
              googleLoading={googleLoading}
            />
          </div>
        ) : null}
      </div>
      <div style={styles.rowContainer}>
        <Text style={styles.today}>{dateDisplay()}</Text>
        {isMagicPlannerSwitchShown ? (
          <IOSSwitch
            onChange={toggleMagicPlanner}
            checked={magicPlannerState}
            sx={{
              "&.MuiSwitch-root .Mui-checked": {
                "& + .MuiSwitch-track": {
                  background:
                    "linear-gradient(246deg, rgba(252, 167, 141, 0.90) 0%, rgba(246, 140, 212, 0.90) 100%, rgba(246, 140, 212, 0.90) 100%)",
                  opacity: 1,
                  border: 0,
                },
              },
            }}
          />
        ) : null}
      </div>
      <div style={styles.dayContainer}>
        <Text style={styles.date}>
          {rightDrawerStartDate.toFormat("MMMM dd")}
        </Text>
        <div style={styles.iconContainer}>
          <Icon name={IconName.CALENDAR_BACK} onPress={onPressPreviousDay} style={styles.calendarBackIcon}/>
          <button style={styles.todayButton} onClick={onClickToday}>{i18n.t("today")}</button>
          <Icon
            name={IconName.CALENDAR_FORWARD}
            style={styles.calendarForwardIcon}
            onPress={onPressNextDay}
          />
        </div>
      </div>
      {!isLoading ? (
        <Timeline
          boardData={rightDrawerData}
          startDate={rightDrawerStartDate}
          updateTaskWithRightDrawerDrop={updateTaskWithRightDrawerDrop}
          insumoCalendarEventData={insumoCalendarEventData}
          isLoading={isLoading}
          onHoverRightDrawer={onHoverRightDrawer}
          magicPlannerState={magicPlannerState}
        />
      ) : (
        <div style={styles.spinnerContainer}>
          <Spinner loading={isLoading} />
        </div>
      )}
    </Drawer>
  );
};

const styles: Record<string, React.CSSProperties> = {
  dayContainer: {
    display: "flex",
    alignSelf: "stretch",
    justifyContent: "space-between",
    alignItems: 'center',
    marginLeft: 20,
    marginRight: 20,
    marginTop: 15,
    marginBottom: 35,
  },
  openedSuitcaseContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 41,
    height: 41,
    borderRadius: "10px 10px 0px 0px",
    boxShadow: "0px 4px 3px 0px #0000001A",
    cursor: "pointer",
  },
  closedSuitcaseContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 41,
    height: 41,
    borderRadius: 10,
    boxShadow: "0px 4px 3px 0px #0000001A",
    cursor: "pointer",
  },
  scheduleContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: palette.white,
    borderWidth: 0,
    marginTop: 35,
    marginLeft: 20,
    marginRight: 20,
    position: "relative",
    zIndex: 10,
  },
  title: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 22,
    color: palette.black,
    marginLeft: 15,
  },
  today: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 22,
    color: palette.black,
    width: "max-content",
  },
  date: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 18,
    color: palette.gray.extraLight,
  },
  calendarForwardIcon: {
    marginLeft: 10,
  },
  calendarBackIcon: {
    marginLeft: 10,
    marginRight: 10,
  },
  scheduleTopContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  modal: {
    position: "absolute",
    top: 41,
    left: -20,
    zIndex: 1,
  },
  cursor: {
    cursor: "pointer",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  spinnerContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rowContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    alignSelf: "stretch",
    marginRight: 20,
    marginLeft: 20,
    marginTop: 15,
  },
  todayButton: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 15,
    color: palette.gray.medium,
    backgroundColor: palette.gray.iceLight,
    textAlign: 'center',
    padding: '2px 15px',
    border: 'none',
    borderRadius: 7,
    outline: 'solid #D7DBF3',
    cursor: 'pointer'
  }
};
