import {
  AsanaImage,
  ClickupImage,
  GoogleCalendarImage,
  HubspotImage,
  JiraImage,
  NotionImage,
  OutlookImage,
  TodoistImage,
} from "assets/images";
import { UserPreferencesResource } from "communicators/resources/user-preferences/user-preferences.resource";
import { UserPreferencesEntity } from "communicators/resources/user-preferences/user-preferences.type";
import { ResponseWithData } from "communicators/types";
import { i18n } from "constants/i18n.constants";
import { LocalStorageParam } from "constants/local-storage.constant";
import { ONBOARDING_PAGE_COUNT } from "constants/onboarding.constants";
import { ThirdPartyProvider } from "interfaces/main.interfaces";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux-store/store.hooks";

const PAGE_NUMBER = 1;

export const useToolSelectionHook = () => {
  const [selectedProviders, setSelectedProviders] = useState<
    ThirdPartyProvider[]
  >([]);
  const [preference, setPreference] = useState<ResponseWithData<UserPreferencesEntity[]>>();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { userInfo } = useAppSelector((state) => ({
    userInfo: state.userSetting.userInfo,
  }));

  const getUserPreference = async () => {
    const response = await new UserPreferencesResource().readMany();
    setPreference(response);
  }

  useEffect(() => {
    getUserPreference();
  }, []);

  const sendIntegrationTools = async () => {
    if (!userInfo || !preference) return;
    setLoading(true);
   try{
    await new UserPreferencesResource().updateOnePartial(preference.data[0].id, {
      user_preference: {
        preferred_tools: selectedProviders.map(String),
      },
    });
    }catch(_){
      await new UserPreferencesResource().updateOnePartial(preference.data[0].id, {
        user_preference: {
          preferred_tools: selectedProviders.map(String),
        },
      });
   }
    localStorage.setItem(LocalStorageParam.ONBOARDING_TOOLS_SHOWN, JSON.stringify(true));
    navigate("/onboarding/plan-time");
    setLoading(false);
  }

  const onSelect = (provider: ThirdPartyProvider) => {
    const indexOfProvider = selectedProviders.findIndex(
      (item) => item === provider
    );

    let newProviders = [...selectedProviders];
    if (indexOfProvider >= 0) {
      newProviders.splice(indexOfProvider, 1);
    } else {
      newProviders.push(provider);
    }
    setSelectedProviders(newProviders);
  };

  const isProviderSelected = (provider: ThirdPartyProvider) => {
    return selectedProviders.includes(provider);
  };

  const progressValue = (PAGE_NUMBER / ONBOARDING_PAGE_COUNT) * 100;

  return { onSelect, isProviderSelected, progressValue, sendIntegrationTools, loading };
};

export const TOOLS: {
  provider: ThirdPartyProvider;
  title: string;
  image: string;
  subtitle?: string;
}[] = [
  {
    provider: ThirdPartyProvider.ASANA,
    title: i18n.t("asana"),
    image: AsanaImage,
  },
  {
    provider: ThirdPartyProvider.TODOIST,
    title: i18n.t("todoist"),
    image: TodoistImage,
  },
  {
    provider: ThirdPartyProvider.GOOGLE,
    title: i18n.t("google_calendar"),
    image: GoogleCalendarImage,
  },
  {
    provider: ThirdPartyProvider.JIRA,
    title: i18n.t("jira"),
    image: JiraImage,
    subtitle: i18n.t("coming_soon_notify"),
  },
  {
    provider: ThirdPartyProvider.NOTION,
    title: i18n.t("notion"),
    image: NotionImage,
    subtitle: i18n.t("coming_soon_notify"),
  },
  {
    provider: ThirdPartyProvider.M_OUTLOOK,
    title: i18n.t("outlook"),
    image: OutlookImage,
    subtitle: i18n.t("coming_soon_notify"),
  },
  {
    provider: ThirdPartyProvider.HUBSPOT,
    title: i18n.t("hubspot"),
    image: HubspotImage,
    subtitle: i18n.t("coming_soon_notify"),
  },
  {
    provider: ThirdPartyProvider.CLICKUP,
    title: i18n.t("clickup"),
    image: ClickupImage,
    subtitle: i18n.t("coming_soon_notify"),
  },
];
