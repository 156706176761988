import { i18n } from "constants/i18n.constants";
import { Text } from "elements/text/Text";
import React from "react";
import "./TermsOfUseText.scss";

interface Props {
  onPressTemsOfUse?: () => void;
  onPressPrivacyPolicy?: () => void;
}

export const TermsOfUseText = ({
  onPressTemsOfUse,
  onPressPrivacyPolicy,
}: Props) => {
  return (
    <Text className="terms-of-use-text">
      {i18n.t("terms_of_use_first")}
      {"\xa0"}
      <Text onPress={onPressTemsOfUse} className="terms-of-use">
        {i18n.t("terms_of_use")}
      </Text>
      {"\xa0"}
      {i18n.t("and")}
      {"\xa0"}
      <Text onPress={onPressPrivacyPolicy} className="privacy-policy">
        {i18n.t("privacy_policy")}
      </Text>
    </Text>
  );
};
