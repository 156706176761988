import { defaultCountry, ICountry } from "assets/countries/countries";
import { Icon } from "elements/icon/Icon";
import { Text } from "elements/text/Text";
import { IconName } from "elements/icon/Icon.type";
import React, { useEffect, useState } from "react";
import { CountrySelectDialog } from "./CountrySelectDialog";

interface Props {
  onSelect?: (country: ICountry) => void;
  initialCountry?: ICountry;
}

export const CountryCodePicker = ({ onSelect, initialCountry }: Props) => {
  const [selectedCountry, setSelectedCountry] = useState(initialCountry || defaultCountry);
  const [opened, setOpened] = useState(false);

  const openPicker = () => {
    setOpened(true);
  };

  const closePicker = () => {
    setOpened(false);
  };

  const onSelectCountry = (country: ICountry) => {
    setSelectedCountry(country);
    onSelect && onSelect(country);
    closePicker();
  };

  useEffect(() => {
    if (onSelect) {
      onSelect(selectedCountry);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <button onClick={openPicker} style={styles.container}>
        <img style={styles.image} src={selectedCountry.flag} alt="country-flag" />
        <Text style={styles.codeText}>{`+${selectedCountry.callingCode}`}</Text>
        <Icon name={IconName.ARROW_DOWN} style={styles.cursorStyle} />
      </button>
      <CountrySelectDialog
        handleClose={closePicker}
        open={opened}
        onSelect={onSelectCountry}
      />
    </>
  );
};

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "transparent",
    borderWidth: 0,
    alignItems: "center",
    cursor: "pointer",
  },
  image: {
    width: 30,
    height: 30,
  },
  codeText: {
    marginLeft: 6,
  },
  cursorStyle: {
    cursor: "pointer"
  }
};
