/* eslint-disable react-hooks/exhaustive-deps */
import { palette } from "assets/palette";
import { EventType } from "communicators/resources/event-resources/event-resources.type";
import { useOutsideClick } from "helpers/common.helper";
import { CalendarAccountType } from "pages/private/home/useHomeHook";
import { useEffect, useRef, useState } from "react";
import { useDrag } from "react-dnd";
import { setOpenedMeetDetail } from "redux-store/actions/home-page.actions";
import { useAppDispatch, useAppSelector } from "redux-store/store.hooks";
import { IEvent } from "../../types";
import { isItemMagicPlanner } from "helpers/utils.helper";

interface Props {
  event: IEvent;
  onDraging: (event: IEvent) => void;
}

const TIME_BOX_HEIGHT = 15;

export const useTimelineListItemHook = ({ event, onDraging }: Props) => {
  const [onMouseDown, setOnMouseDown] = useState(false);
  const [onMouseUp, setOnMouseUp] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isMeetDetailShown, setIsMeetDetailShown] = useState(false);

  const { calendars, preferences } = useAppSelector((state) => ({
    calendars: state.userSetting.calendarData,
    preferences: state.userSetting.preferences,
  }));
  const ref = useRef<any>();
  const mainRef = useRef<any>();

  const dispatch = useAppDispatch();

  const canDrag = event.data.attributes.is_editable;

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "board_item",
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      canDrag: canDrag,
    }),
    []
  );
  const determineHeight = () => {
    const diff = event.end.diff(event.start, ["minutes"]);
    return diff.minutes < 15
      ? 45
      : Math.ceil(diff.minutes / 15) * TIME_BOX_HEIGHT;
  };

  const getTitle = () => {
    if (event.data.type === EventType.MEET) {
      return event.data.attributes.meet_summary;
    }
  };

  const determineBackground = () => {
    if (isItemMagicPlanner(event.data)) {
      return "linear-gradient(246deg, rgba(252, 167, 141, 0.90) 0%, rgba(246, 140, 212, 0.90) 100%, rgba(246, 140, 212, 0.90) 100%)";
    }
    if (event.data.type === EventType.MEET) {
      if (event.data.attributes.task_id) {
        return palette.orange.medium;
      }
    }
    return palette.green.light;
  };

  const determineBackgroundColor = () => {
    if (event.data.type === EventType.MEET) {
      if (event.data.attributes.task_id) {
        return palette.orange.medium;
      }
      return palette.green.light;
    }
  };

  const determineTextColor = () => {
    if (event.data.type === EventType.MEET) {
      if (
        event.data.attributes.task_id ||
        event.data.attributes.moved_by_magic_planner
      ) {
        return palette.white;
      }
      return palette.green.dark;
    }
  };

  const determineTextFontSize = () => {
    const height = determineHeight();
    if (height > TIME_BOX_HEIGHT) {
      return 16;
    }
    return 12;
  };

  const onMouseDownEvent = () => {
    if (canDrag) return;
    setOnMouseUp(false);
    setOnMouseDown(true);
  };

  const onMouseUpEvent = () => {
    if (canDrag) return;
    setOnMouseUp(true);
    setOnMouseDown(false);
  };

  const onMouseMove = () => {
    if (canDrag) return;
    if (onMouseDown && !onMouseUp && !snackbarOpen) {
      setSnackbarOpen(true);
    }
  };

  const onMouseLeave = () => {
    if (canDrag) return;
    setOnMouseUp(true);
    setOnMouseDown(false);
  };

  const onCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const desiredCalendar = calendars.filter(
    (item) => item.remote_id === event?.data?.attributes?.calendar_id
  );

  useEffect(() => {
    if (isDragging) {
      onDraging(event);
    }
  }, [isDragging]);

  const backgroundColor = determineBackgroundColor();
  const title = getTitle();
  const textColor = determineTextColor();
  const background = determineBackground();

  const toggleDetail = () => {
    setIsMeetDetailShown(!isMeetDetailShown);
  };

  const handleMeetDetailClose = () => {
    setIsMeetDetailShown(false);
  };

  useEffect(() => {
    document.addEventListener("keydown", (event) => {
      if (event.key === "Escape") {
        dispatch(setOpenedMeetDetail(null));
        handleMeetDetailClose();
      }
    });
    return () => {
      document.removeEventListener("keydown", () => {});
    };
  }, [dispatch]);

  useOutsideClick(ref, mainRef, handleMeetDetailClose);

  const isInsumo =
    preferences?.attributes?.preferred_calendar === CalendarAccountType.INSUMO;

  return {
    drag,
    determineHeight,
    determineTextFontSize,
    isDragging,
    backgroundColor,
    title,
    textColor,
    onMouseDownEvent,
    onMouseUpEvent,
    onMouseMove,
    snackbarOpen,
    onCloseSnackbar,
    onMouseLeave,
    desiredCalendar,
    ref,
    mainRef,
    isMeetDetailShown,
    toggleDetail,
    isInsumo,
    background,
  };
};
