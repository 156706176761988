import { setSomethingWentWrongModal } from './../../redux-store/actions/home-page.actions';
import { AxiosError, AxiosRequestConfig } from "axios";
import { BaseCommunicator } from "../base.communicator";
import { Endpoints, HasQueryMethods } from "../types";
import { IAPIResource } from "communicators/resources/type";
import { ErrorDictionary, INSUMO_ERRORS } from "./errors/error.dictionary";
import { ErrorCodes } from "./errors/type";
import { store } from "redux-store/store";
import { LocalStorageParam } from "constants/local-storage.constant";

export class InsumoCommunicator<T extends IAPIResource>
  extends BaseCommunicator<T>
  implements HasQueryMethods<T>
{
  constructor() {
    super(process.env.REACT_APP_INSUMO_URL || "");

    this.axios.interceptors.request.use(
      async (request) => await this.handleRequest(request),
      async (error) => await this.handleError(error)
    );
  }

  errorLookup: ErrorDictionary = INSUMO_ERRORS;

  endpoint = Endpoints.DEFAULT;

  handleRequest = async (request: AxiosRequestConfig<any>) => {
    let token = store.getState().userSetting.bearerToken;
    if (!token) {
      const storedToken = localStorage.getItem(LocalStorageParam.TOKEN);
      if (storedToken) {
        token = JSON.parse(storedToken).access_token;
      }
    }

    if (token)
      request.headers = {
        ...request.headers,
        Authorization: `Bearer ${token}`,
        "Device-Type": "Web",
      };
    return request;
  };

  handleError = (error: AxiosError) => {
    if (error?.response?.status === ErrorCodes.NOT_BETA_USER) {
      window.location.replace('/waitlist');
    }
    if (error?.response?.status === ErrorCodes.NOT_FOUND) {
      store.dispatch(setSomethingWentWrongModal(true));
    }
    const status = error.response?.status ?? ErrorCodes.SERVER_ERROR;
    throw this.errorLookup[status]
      ? new this.errorLookup[status](error.message)
      : new this.errorLookup.DEFAULT(status);
  };

  readOne = (id: string, query?: T["query"]) =>
    this.get<T["entity"]>(`${this.endpoint}/${id}`, { params: query });
  readMany = (query?: T["query"]) =>
    this.get<T["entities"]>(this.endpoint, { params: query });
  createOne = (body: T["candidate"], query?: T["query"]) =>
    query
      ? this.post(this.endpoint, body, query)
      : this.post(this.endpoint, body);
  updateOne = (id: string, body: Partial<T["candidate"]>) =>
    this.put(`${this.endpoint}/${id}`, body);
  updateOnePartial = (id: string, body: Partial<T["candidate"]>) =>
    this.patch(`${this.endpoint}/${id}`, body);
  removeOne = (id: string, query?: T["query"]) =>
    this.delete(`${this.endpoint}/${id}`, { params: query });

  resync = (query: T["query"]) =>
    this.get<any>(`${this.endpoint}/resync`, { params: query });

  private areWeTestingWithJest = () => {
    return process.env.JEST_WORKER_ID !== undefined;
  };
}