export enum LocalStorageParam {
  TOKEN = "token",
  FIREBASE_UUID = "fb_id",
  ONBOARDING_SIGNUP_BEFORE_SHOWN = "onboarding_signup_before_shown",
  ONBOARDING_TOOLS_SHOWN = "onboarding_tools_shown",
  ONBOARDING_PLAN_TIME_SHOWN = "onboarding_plan_time_shown",
  ONBOARDING_CREATE_REMINDER_SHOWN = "onboarding_create_reminder_shown",
  MAGIC_PLANNER_DATE = "magic_planner_date",
  MAGIC_PLANNER_IDS = "magic_planner_ids",
}
