import { VerificationCodeInput } from './VerificationCodeInput';
import './VerificationCodeInputs.scss'

interface OtpProps {
    codeLength: number;
    code: string[];
    setCode: React.Dispatch<React.SetStateAction<string[]>>;
}

export const VerificationCodeInputs = ({codeLength, code, setCode}: OtpProps) => {
    const handleCode = (ev: React.ChangeEvent<HTMLInputElement>, value: string, index: number) => {
        const newCode = [...code];
        const remainingFields = codeLength - index;
        const newValue = value.length ? value.split('', remainingFields) : [''];
        const newValueSize = value.length ? value.length : 1; 
        const target = ev.currentTarget as HTMLInputElement;

        newCode.splice(index, newValueSize , ...newValue);
        setCode(newCode);

        if(value.length && value.length < codeLength  && index !== codeLength - 1) {
            (target.nextElementSibling as HTMLInputElement || null).focus();
        }
        if(value.length === codeLength) {
            target.blur();
        }
        
    }

    const handleKey = (ev: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        const target = ev.currentTarget as HTMLInputElement;
        if(ev.key === 'Backspace' && target.value === '' && index) {
            (target.previousElementSibling as HTMLInputElement || null).focus();
        }
        if(ev.key === 'ArrowLeft') {
            const prevElement = target.previousElementSibling as HTMLInputElement || null;
            if(prevElement) prevElement.focus();
        }
        if(ev.key === 'ArrowRight') {
            const nextElement = target.nextElementSibling as HTMLInputElement || null;
            if(nextElement) nextElement.focus();
        }
    }

    return (
        <div className='verification-container'>
            {
                code.map((char, index) => ( 
                    <VerificationCodeInput 
                        key={index} 
                        handleCode={handleCode} 
                        handleKey={handleKey}
                        char={char} 
                        index={index} 
                        maxLength={codeLength}
                    />
                ))
            }
        </div>
    );
}