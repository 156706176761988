import { palette } from "assets/palette";
import React from "react";
import { Text } from "elements/text/Text";
import { i18n } from "constants/i18n.constants";

interface Props {
  onChangeBillingCycle?: () => void;
  onRemove?: () => void;
  showUnsubscribe?: boolean;
}

export const PaymentCardModal = ({
  onChangeBillingCycle,
  onRemove,
  showUnsubscribe = true,
}: Props) => {
  return (
    <div style={styles.container}>
      {/* <Text style={styles.text}>{i18n.t("update_payment_method")}</Text>
      <div style={styles.separator} /> */}
      <Text style={styles.text} onPress={onChangeBillingCycle}>
        {i18n.t("change_billing_cycle")}
      </Text>
      {/* <div style={styles.separator} /> */}
      {showUnsubscribe ? (
        <Text style={styles.text} onPress={onRemove}>
          {i18n.t("unsubscribe")}
        </Text>
      ) : null}
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "center",
    padding: 15,
    backgroundColor: palette.white,
    borderRadius: "10px 0px 10px 10px",
    boxShadow: "0px 2px 2px 0px #00000040",
    width: "110%",
    marginRight: "auto",
  },
  text: {
    fontWeight: "400",
    fontSize: 13,
    color: palette.gray.dark,
    marginTop: 10,
    marginBottom: 10,
    cursor: "pointer",
  },
  separator: {
    width: "100%",
    height: 1,
    backgroundColor: palette.gray.mediumLight,
  },
};
