import React from "react";
import { InputBase } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { palette } from "assets/palette";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { i18n } from "constants/i18n.constants";
import { SearchDialogItem } from "components/search-dialog-item/SearchDialogItem";
import { Text } from "elements/text/Text";
import { resizeByResolutionWidth } from "helpers/utils.helper";
import { useSearchDialogHook } from "./useSearchDialogHook";
import { AllBoardDataEntityType } from "interfaces/main.interfaces";

interface Props {
  closeSearchDialog?: () => void;
  open?: boolean;
  onPressSearchItem: (data: AllBoardDataEntityType) => void;
}

export const SearchDialog = ({ closeSearchDialog, open, onPressSearchItem }: Props) => {
  const { searchedText, searchItems, onChangeSearchInput } =
    useSearchDialogHook();
  return (
    <Dialog onClose={closeSearchDialog} fullWidth maxWidth={"md"} open={!!open}>
      <section style={styles.modalContainer}>
        <div style={styles.searchDialogContainer}>
          <div style={styles.searchDialogLeftContainer}>
            <Icon name={IconName.SEARCH} />
            <InputBase
              value={searchedText}
              placeholder={i18n.t("search_insumo")}
              style={styles.input}
              onChange={onChangeSearchInput}
            />
          </div>
          <Icon name={IconName.CLOSE} onPress={closeSearchDialog} />
        </div>
        <div style={styles.separator} />
        <Text style={styles.bestMatchText}>
          {i18n.t("best_matches", { result: "20" })}
        </Text>
        {searchItems.map((item) => {
          return <SearchDialogItem data={item} key={item.id} onClick={() => onPressSearchItem(item)}/>;
        })}
      </section>
    </Dialog>
  );
};

const styles: Record<string, React.CSSProperties> = {
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#00000080",
  },
  modalContainer: {
    position: "fixed",
    width: "50%",
    height: "75%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: palette.white,
    borderRadius: resizeByResolutionWidth(12),
    overflow: "hidden",
    overflowY: "scroll",
  },
  searchDialogContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: resizeByResolutionWidth(30),
    paddingLeft: resizeByResolutionWidth(30),
    paddingRight: resizeByResolutionWidth(30),
    paddingBottom: resizeByResolutionWidth(10),
  },
  searchDialogLeftContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  input: {
    display: "flex",
    alignSelf: "stretch",
    flex: 1,
    borderWidth: 0,
    backgroundColor: "transparent",
    border: "none",
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 25,
    color: palette.gray.dark,
    marginLeft: resizeByResolutionWidth(20),
  },
  separator: {
    height: 1,
    width: "100%",
    backgroundColor: palette.gray.mediumLight,
  },
  bestMatchText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 15,
    color: palette.gray.medium,
    marginTop: resizeByResolutionWidth(25),
    marginBottom: resizeByResolutionWidth(25),
    marginLeft: resizeByResolutionWidth(45),
    marginRight: resizeByResolutionWidth(45),
  },
};
