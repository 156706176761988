/* eslint-disable react-hooks/exhaustive-deps */
import { EventType } from "communicators/resources/event-resources/event-resources.type";
import { DNDItemType } from "constants/dnd.constants";
import type { Identifier, XYCoord } from "dnd-core";
import { DragAndDropHelper } from "helpers/dnd.helper";
import { AllBoardDataEntityType } from "interfaces/main.interfaces";
import { useEffect, useRef } from "react";
import { DragSourceMonitor, useDrag, useDrop } from "react-dnd";
import { useAppSelector } from "redux-store/store.hooks";

export interface DargHookProps {
  id: string;
  index: number;
  moveCard?: (dragIndex: number, hoverIndex: number) => void;
  data: AllBoardDataEntityType;
}

interface DragItem {
  index: number;
  id: string;
  boardId: string;
}

export const useDragHook = ({ id, index, data, moveCard }: DargHookProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const dragingEvent = useAppSelector((state) => state.dnd.dragingEvent);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: "board_item",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard && moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const canDrag = data.type === EventType.TASK && !!data.attributes.title;

  const [{ isDragging, didDrop }, drag] = useDrag({
    type: "board_item",
    item: () => {
      return { id, index };
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
      didDrop: monitor.didDrop(),
    }),
    canDrag: canDrag,
  });

  const opacity =  dragingEvent?.data.id === data.id ? 0.2 : 1;

  drag(drop(ref));

  useEffect(() => {
    if (isDragging) {
      DragAndDropHelper.setDragingEvent({ type: DNDItemType.BOARD_ITEM, data });
    }
  }, [isDragging]);

  useEffect(() => {
    if (didDrop) {
      DragAndDropHelper.setDragingEvent(null);
    }
  }, [didDrop]);

  return { ref, opacity, handlerId };
};
