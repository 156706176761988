export enum BootsplashDispatchTypes {
  setBootsplashVisibility = "SET_BOOTSPLASH_VISIBILITY",
  setBootSplashIsHide = "SET_BOOTSPLASH_IS_HIDE",
}

export enum UserSettingshDispatchTypes {
  setUserBearerToken = "SET_USER_BEARER_TOKEN",
  setUserInfo = "SET_USER_INFO",
  setOnboardingUserFullname = "SET_ONBOARDING_USER_FULLNAME",
  setFirebaseUuid = "SET_FIREBASE_UUID",
  clearUserData = "CLEAR_USER_DATA",
  setUserSubscriptions = "SET_USER_SUBSCRIPTIONS",
  setCalendarData = "SET_CALENDAR_DATA",
  setUserPreferences = "SET_USER_PREFERENCES",
}

export enum DeviceSettingshDispatchTypes {
  setIs24HourFormat = "SET_IS_24_HOUR_FORMAT",
}

export enum ThirdPartyProviderDispatchTypes {
  setThirdPartyProviderData = "SET_THIRD_PARTY_PROVIDER_DATA",
  setThirdPartyData = "SET_THIRD_PARTY_DATA",
}

export enum TagsDispatchTypes {
  setTagsData = "SET_TAGS_DATA",
  setSelectedTagsData = "SET_SELECTED_TAGS_DATA",
}

export enum DNDDispatchTypes {
  setDragingEvent = "SET_DRAGING_EVENT",
}

export enum HomePageDispatchTypes {
  setPreferencesTab = "SET_PREFERENCES_TAB",
  setPreferencesDialog = "SET_PREFERENCES_DIALOG",
  setOpenedMeetDetail = "SET_OPENED_MEET_DETAIL",
  setSomethingWentWrongModal = "SET_SOMETHING_WENT_WRONG_MODAL",
  refreshCompletedTasks = "REFRESH_COMPLETED_TASKS",
  refreshSomedayTasks = "REFRESH_SOMEDAY_TASKS",
  refreshInboxTasks = "REFRESH_INBOX_TASKS",
  refreshBoardData = "REFRESH_BOARD_DATA",
  setPermissionModalType = "SET_PERMISSION_MODAL_TYPE",
  setTaskCreatingLoading = "SET_TASK_CREATING_LOADING",
  setInsumoCalendarEvent = "SET_INSUMO_CALENDAR_EVENT",
  setRedeemOpen = "SET_REDEEM_OPEN",
  setManageTagsModalVisible = "SET_MANAGE_TAGS_MODAL_VISIBLE",
  setAvailableMagicPlannerFreeSpots = "SET_AVAILABLE_MAGIC_PLANNER_FREE_SPOTS",
}
