import thunk from "redux-thunk";
import { rootReducer } from "redux-store/reducers";
import { configureStore } from "@reduxjs/toolkit";

const middlewares = [thunk];

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({serializableCheck: false}).concat(...middlewares),
});
