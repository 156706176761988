import React from "react";

export interface IconProps {
  name?: IconName;
  width?: number;
  height?: number;
  fill?: string;
  strokeColor?: string;
  strokeWidth?: string;
  fillOpacity?: number;
  style?: React.CSSProperties;
  testID?: string;
  onPress?: () => void;
  disabled?: boolean;
  ref?: any;
}

export enum IconName {
  PASSWORD_VISIBLE = "password_visible",
  PASSWORD_HIDDEN = "password_hidden",
  GOOGLE = "google_icon",
  PHONE = "phone",
  ARROW_DOWN = "arrow_down",
  DOUBLE_ARROW_LEFT = "double_arrow_left",
  DOUBLE_ARROW_RIGHT = "double_arrow_right",
  ARROW_DOWN_IOS = "arrow_down_ios",
  SETTINGS = "settings",
  CLOSE = "close",
  PROFILE = "profile",
  LINK = "link",
  HELP = "help",
  TRASH = "trash",
  PLUS = "plus",
  TICK = "tick",
  SETTING = "setting",
  BACK_ARROW = "back_arrow",
  SOMETHING_WENT_WRONG = "something_went_wrong",
  SUBSCRIPTION_CHECK = "subscription_check",
  SUBSCRIPTION_DIAMOND = "subscription_diamond",
  ADD_FIRST_TASK_ARROW = "add_first_task_arrow",
  TIME = "time",
  CALENDAR = "calendar",
  SCHEDULE_SUITCASE = "schedule_suitcase",
  CALENDAR_FORWARD = "calendar_forward",
  CALENDAR_BACK = "calendar_back",
  INTEGRATION_PLUS = "integration_plus",
  LOGOUT = "logout",
  COMPLETED_TASKS = "completed_tasks",
  INBOX_TASKS = "inbox_tasks",
  LEFT_DRAWER_BIG_BACK = "left_drawer_big_back",
  PEOPLE = "people",
  SEARCH = "search",
  SOMEDAY_TASKS = "someday_tasks",
  CARD = "card",
  DOWNLOAD = "download",
  APPLE = "apple",
  BILLING = "billing",
  UNSUBSCRIPTION_WEBAPP = "unsubscription_webapp",
  UNSUBSCRIPTION_FOCUS = "unsubscription_focus",
  UNSUBSCRIPTION_INTEGRATIONS = "unsubscription_integrations",
  UNSUBSCRIPTION_MOBILEAPP = "unsubscription_mobileapp",
  UNSUBSCRIPTION_REMINDERS = "unsubscription_reminders",
  UNSUBSCRIPTION_ZEROTASKS = "unsubscription_zerotasks",
  APPLE_CARD = "apple_card",
  STREAK_CUP = "streak_cup",
  REDEEM_CODE = "redeem_code",
  MAGIC_PLANNER = "magic_planner",
  MAGIC_PLANNER_TRIANGLE = "magic_planner_triangle",
  TAG = "tag",
  TAG_ICON = "tag_icon",
  HEARTH_OUTLINE = "heart_otline",
  HEART_FILLED = "heart_filled",
  TAG_SELECTION_TRIPLE_CIRCLE = "tag_selection_triple_circle",
  TAG_PLUS = "tag_plus"
}
