import { MeetEntity } from "communicators/resources/event-resources/meets/meets.type";
import { IInsumoCalendar } from "helpers/data.helper";
import { PreferenceTab } from "pages/private/home/components/PreferencesDialog/PreferenceDialog.types";
import { PermissionModalType } from "pages/private/home/useHomeHook";
import { Dispatch } from "redux";
import { HomePageDispatchTypes } from "redux-store/types/dispatch.type";
import {
  SetOpenedMeetDetailAction,
  SetPreferencesDialogAction,
  SetPreferencesTabAction,
  SetSomethingWentWrongModalAction,
  SetRefreshCompletedTasksAction,
  SetRefreshBoardDataAction,
  SetPermissionModalTypeAction,
  SetTaskCreatingLoadingAction,
  SetInsumoCalendarEventAction,
  SetRefreshSomedayTasksAction,
  SetRedeemOpenAction,
  SetTagsModalVisibleAction,
  SetAvailableMagicPlannerFreeSpotsAction,
  SetRefreshInboxTasksAction,
} from "redux-store/types/home-page.action.type";

export const setPreferencesTab = (tab: PreferenceTab) => {
  return (dispatch: Dispatch): void => {
    dispatch<SetPreferencesTabAction>({
      type: HomePageDispatchTypes.setPreferencesTab,
      payload: tab,
    });
  };
};

export const setPreferencesDialog = (status: boolean) => {
  return (dispatch: Dispatch): void => {
    dispatch<SetPreferencesDialogAction>({
      type: HomePageDispatchTypes.setPreferencesDialog,
      payload: status,
    });
  };
};

export const setOpenedMeetDetail = (meet: MeetEntity | null) => {
  return (dispatch: Dispatch): void => {
    dispatch<SetOpenedMeetDetailAction>({
      type: HomePageDispatchTypes.setOpenedMeetDetail,
      payload: meet,
    });
  };
};

export const setRefreshCompletedTasks = () => {
  return (dispatch: Dispatch): void => {
    dispatch<SetRefreshCompletedTasksAction>({
      type: HomePageDispatchTypes.refreshCompletedTasks,
    });
  };
};

export const setRefreshSomedayTasks = () => {
  return (dispatch: Dispatch): void => {
    dispatch<SetRefreshSomedayTasksAction>({
      type: HomePageDispatchTypes.refreshSomedayTasks,
    });
  };
};

export const setRefreshInboxTasks = () => {
  return (dispatch: Dispatch): void => {
    dispatch<SetRefreshInboxTasksAction>({
      type: HomePageDispatchTypes.refreshInboxTasks,
    });
  };
};

export const setRefreshBoardData = () => {
  return (dispatch: Dispatch): void => {
    dispatch<SetRefreshBoardDataAction>({
      type: HomePageDispatchTypes.refreshBoardData,
    });
  };
};

export const setSomethingWentWrongModal = (status: boolean) => {
  return (dispatch: Dispatch): void => {
    dispatch<SetSomethingWentWrongModalAction>({
      type: HomePageDispatchTypes.setSomethingWentWrongModal,
      payload: status,
    });
  };
};

export const setPermissionModalType = (type: PermissionModalType | null) => {
  return (dispatch: Dispatch): void => {
    dispatch<SetPermissionModalTypeAction>({
      type: HomePageDispatchTypes.setPermissionModalType,
      payload: type,
    });
  };
};

export const setTaskCreatingLoading = (status: boolean) => {
  return (dispatch: Dispatch): void => {
    dispatch<SetTaskCreatingLoadingAction>({
      type: HomePageDispatchTypes.setTaskCreatingLoading,
      payload: status,
    });
  };
};

export const setInsumoCalendarEvent = (data: IInsumoCalendar[]) => {
  return (dispatch: Dispatch): void => {
    dispatch<SetInsumoCalendarEventAction>({
      type: HomePageDispatchTypes.setInsumoCalendarEvent,
      payload: data,
    });
  };
};

export const setRedeemOpened = (status: boolean) => {
  return (dispatch: Dispatch): void => {
    dispatch<SetRedeemOpenAction>({
      type: HomePageDispatchTypes.setRedeemOpen,
      payload: status,
    });
  };
};

export const setTagsModalVisible = (status: boolean) => {
  return (dispatch: Dispatch): void => {
    dispatch<SetTagsModalVisibleAction>({
      type: HomePageDispatchTypes.setManageTagsModalVisible,
      payload: status,
    });
  };
};

export const setAvailableMagicPlannerFreeSpots = (freeSpot: number) => {
  return (dispatch: Dispatch): void => {
    dispatch<SetAvailableMagicPlannerFreeSpotsAction>({
      type: HomePageDispatchTypes.setAvailableMagicPlannerFreeSpots,
      payload: freeSpot,
    });
  };
};
