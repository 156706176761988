import { UserOwnedResourceManagementService } from "communicators/services/UserOwnedResourceManagementService";
import { IMeetsResource } from "./meets.type";

export class MeetsResource extends UserOwnedResourceManagementService<IMeetsResource> {
  constructor() {
    super("MEETS");
  }

  readMany = async (query: IMeetsResource["query"] = {}, resync?: boolean) => {
    if (resync) {
      await this.resync();
    }

    return await this.communicator.readMany(query);
  };
}
