import { useCallback, useEffect, useState } from "react";
import { TagsResource } from "communicators/resources/tags/tags.resource";
import { useAppDispatch, useAppSelector } from "redux-store/store.hooks";
import { setTagsModalVisible } from "redux-store/actions/home-page.actions";
import { parseTags, getRandomColor } from "helpers/utils.helper";
import {
  setSelectedTagsData,
  setTagsData,
} from "redux-store/actions/tags.actions";

export interface Tag {
  id: string;
  title: string;
  item_count: number;
  color: string;
  sessionId?: string;
  favorite: boolean;
}

export const useTagsHook = () => {
  const dispatch = useAppDispatch();
  const [tagCreating, setTagCreating] = useState<Record<string, boolean>>({});
  const { tagsData, selectedTagsData } = useAppSelector((state) => ({
    tagsData: state.tags.tagsData,
    selectedTagsData: state.tags.selectedTagsData,
  }));

  const selectTag = (id: string) => {
    const updatedIds = [...selectedTagsData];
    updatedIds.push(id);
    dispatch(setSelectedTagsData(updatedIds));
    if (selectedTagsData.includes(id)) {
      const updatedIds = [...selectedTagsData];
      const index = updatedIds.findIndex((element) => element === id);
      updatedIds.splice(index, 1);
      dispatch(setSelectedTagsData(updatedIds));
    }
  };

  const openManageTagsModal = () => {
    dispatch(setTagsModalVisible(true));
  };

  const getData = async () => {
    if (tagsData.length !== 0) return;
    const response = await new TagsResource().readMany();
    const newTags: Tag[] = [];
    response.data.forEach((tagData) => {
      newTags.push({
        id: tagData.id,
        color: tagData.attributes.color,
        title: tagData.attributes.name,
        item_count: tagData.attributes.item_count || 0,
        favorite: tagData.attributes.favorite,
      });
    });
    dispatch(setTagsData(newTags.reverse()));
  };

  const onPressPlus = async () => {
    const color = getRandomColor();
    const sessionId = generateString(10);
    const newTags: Tag[] = [
      {
        id: "",
        color,
        item_count: 0,
        title: "",
        sessionId: sessionId,
        favorite: false,
      },
      ...tagsData,
    ];
    dispatch(setTagsData(newTags));
  };

  const onPressDelete = (tag: Tag) => {
    const indexOfTag = tagsData.findIndex((item) =>
      item.id ? item.id === tag.id : item.sessionId === tag.sessionId
    );
    if (indexOfTag < -1) return;
    const newTags = tagsData.map((item) => ({
      ...item,
    }));
    const removed = tagsData[indexOfTag];
    newTags.splice(indexOfTag, 1);
    dispatch(setTagsData(newTags));
    new TagsResource().removeOne(removed.id);
  };

  const visibleTags = tagsData.slice(0, 3);

  const setTagCreatingWithData = (status: boolean, tagSessionId: string) => {
    const newTagCreating = { ...tagCreating };
    newTagCreating[tagSessionId] = status;
    setTagCreating(newTagCreating);
  };

  const createTag = async (text: string, tag: Tag) => {
    if (!tag.sessionId) return;
    setTagCreatingWithData(true, tag.sessionId);
    const response = await new TagsResource().createOne({
      tag: { name: text, color: tag.color },
    });
    const newTags = tagsData.map((item) => ({
      ...item,
    }));
    newTags.forEach((item) => {
      if (item.sessionId === tag.sessionId) {
        item.id = response.data.id;
        item.title = response.data.attributes.name;
      }
    });
    dispatch(setTagsData(newTags));
    setTagCreatingWithData(false, tag.sessionId);
  };

  const updateTagTitle = (text: string, tag: Tag) => {
    if (!tag.id) return;
    new TagsResource().updateOnePartial(tag.id, { tag: { name: text } });
  };

  const onChangeTagTitle = (text: string, tag: Tag) => {
    const newTags = tagsData.map((item) => ({
      ...item,
    }));
    newTags.forEach((item) => {
      if (
        item.id === tag.id ||
        (tag.sessionId && item.sessionId && tag.sessionId === item.sessionId)
      ) {
        if (!item.title) {
          createTag(text, tag);
        } else {
          updateTagTitle(text, tag);
        }
        item.title = text;
      }
    });
    dispatch(setTagsData(newTags));
  };

  const isTagLoading = (tag: Tag) => {
    return !!(
      tagCreating[tag.id] ||
      (tag.sessionId && tagCreating[tag.sessionId])
    );
  };

  const onClickHeart = useCallback(
    async (tag_id: string, isFavorite: boolean) => {
      if (!tag_id) return;
      await new TagsResource().updateOnePartial(tag_id, {
        tag: { favorite: !isFavorite },
      });
      getData();
    },
    []
  );

  useEffect(() => {
    getData();
  }, []);

  const parsedTags = parseTags(tagsData);

  return {
    tagsData,
    parsedTags,
    visibleTags,
    onPressPlus,
    onChangeTagTitle,
    isTagLoading,
    openManageTagsModal,
    onPressDelete,
    onClickHeart,
    selectTag,
  };
};

const chrs = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

function generateString(length: number) {
  let result = " ";
  const charactersLength = chrs.length;
  for (let i = 0; i < length; i++) {
    result += chrs.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
