import { countries, ICountry } from "assets/countries/countries";
import { containsOnlyNumbers } from "helpers/utils.helper";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { isValidPhoneNumber } from "libphonenumber-js";

export const usePhoneLoginHook = () => {
  const initialCountry = countries.find(
    (country) => country.callingCode === "90"
  );
  const [phone, setPhone] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<ICountry | undefined>(
    initialCountry
  );

  const navigate = useNavigate();

  const onChangePhone = (text: string) => {
    const trimmed = text.trim().replace(" ", "");
    if (trimmed === "" || containsOnlyNumbers(trimmed)) {
      setPhone(text.trim());
    }
  };

  const phoneIsValid = isValidPhoneNumber(
    `+${selectedCountry?.callingCode}${phone}`
  );

  return {
    phone,
    loading,
    phoneIsValid,
    onChangePhone,
    setSelectedCountry,
    setLoading,
    selectedCountry,
    navigate
  };
};
