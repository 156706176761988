import { palette } from "assets/palette";
import { i18n } from "constants/i18n.constants";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { Text } from "elements/text/Text";
import { resizeByResolutionWidth } from "helpers/utils.helper";
import { useTagsHook } from "./useTagsHook";
import { TagItem } from "./TagItem";
import { debounce } from "lodash";
import { ManageTagsModal } from "components/manage-tags-modal/ManageTagsModal";

export const Tags = () => {
  const {
    visibleTags,
    onPressPlus,
    onChangeTagTitle,
    isTagLoading,
    openManageTagsModal,
    onClickHeart,
    selectTag,
    onPressDelete
  } = useTagsHook();
  return (
    <div style={styles.container}>
      <div style={styles.headerContainer}>
        <Text style={styles.title}>{i18n.t("tags")}</Text>
        <Icon
          onPress={onPressPlus}
          style={styles.icon}
          name={IconName.PLUS}
          fill={palette.white}
        />
        <div style={styles.divider} />
        <Text onPress={openManageTagsModal} style={styles.selectAllText}>
          {i18n.t("select_all")}
        </Text>
      </div>
      {visibleTags.map((tag) => (
        <TagItem
          key={tag.id.toString()}
          tag={tag}
          onChangeTitle={debounce(onChangeTagTitle, 1000)}
          loading={isTagLoading(tag)}
          onPressHeart={onClickHeart}
          isFav={tag.favorite}
          onClick={selectTag}
          onPressDelete={onPressDelete}
        />
      ))}
      <ManageTagsModal />
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: resizeByResolutionWidth(40),
    width: "100%",
  },
  title: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: resizeByResolutionWidth(20),
    color: palette.black,
  },
  icon: {
    marginLeft: "20px",
  },
  selectAllText: {
    color: palette.gray.medium,
    fontSize: 15,
    fontWeight: "500",
    fontFamily: "Gilroy-Semibold",
    marginRight: 30,
    cursor: "pointer",
  },
  divider: {
    display: "flex",
    flex: 1,
  },
};
