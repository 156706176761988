import React from "react";
import { Text } from "elements/text/Text";
import './PaymentMethodItem.scss';
import { palette } from "assets/palette";
import { resizeByResolutionWidth } from "helpers/utils.helper";
import { IconName } from "elements/icon/Icon.type";
import { Icon } from "elements/icon/Icon";

interface Props {
  paymentMethodCardImage: string;
  last4CardNumber: string;
  cardExpiryDate: string;
  name?: string | null;
}

export const PaymentMethodItem = ({paymentMethodCardImage, last4CardNumber, cardExpiryDate, name}: Props) => {
  return (
    <div className={"complete-payment-method-item-container"}>
        <div style={styles.checkedContainer}>
            <Icon name={IconName.TICK} width={10} fill={palette.white} />
        </div>
        <img
            width={50}
            height={30}
            src={paymentMethodCardImage}
            alt="paymentMethodImage"
        />
        <div style={styles.modalParent}>
        <div className="payment-method-right-container">
            <Text className="payment-method-gray-text">{`****${last4CardNumber} | Expires ${cardExpiryDate}`}</Text>
            {name && name !== null ? <Text className="payment-method-black-text">{name}</Text> : null}
        </div>
        </div>
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  downIcon: {
    rotate: "90deg"
  },
  cardRowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
  },
  openedCardRowContainer: {
    height: 35,
    width: "75%",
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: palette.white,
    boxShadow: "0px 1px 3px 0px #00000040"
  },
  modalParent: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "flex-end",
    justifyContent: "center",
    position: "relative"
  },
  checkedContainer: {
    width: resizeByResolutionWidth(20),
    height: resizeByResolutionWidth(20),
    borderRadius: resizeByResolutionWidth(15),
    backgroundColor: palette.blue.lighter,
    border: `1px solid ${palette.blue.lighter}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 15
  },
};