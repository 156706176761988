export enum ErrorCodes {
  INVALID_EMAIL = "auth/invalid-email",
  WRONG_EMAIL = "auth/wrong-email",
  WRONG_PASSWORD = "auth/wrong-password",
  ALREADY_IN_USE = "auth/email-already-in-use",
  USER_NOT_FOUND = "auth/user-not-found",
  INVALID_RECIPENT = "auth/invalid-recipient-email"
}

export enum ErrorNames {
  INVALID_EMAIL = "InvalidEmail",
  WRONG_EMAIL = "WrongEmail",
  WRONG_PASSWORD = "WrongPassword",
  DEFAULT = "UnrecognizedFirebaseError",
  ALREADY_IN_USE = "AlreadyInUse",
  USER_NOT_FOUND = "UserNotFound",
  INVALID_RECIPENT = "InvalidRecipent"
}
