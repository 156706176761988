import { setPreferencesTab } from "redux-store/actions/home-page.actions";
import { PreferenceTab } from "../PreferencesDialog/PreferenceDialog.types";
import { useAppDispatch, useAppSelector } from "redux-store/store.hooks";
import { useState } from "react";
import { i18n } from "constants/i18n.constants";
import { CouponsResource } from "communicators/resources/coupons/coupons.resource";
import { ProfileResource } from "communicators/resources/profile/profile.resource";
import { setUserInfo } from "redux-store/actions/user-settings.action";

export const usePreferencesRedeemCodeHook = () => {
  const [redeemCode, setRedeemCode] = useState("");
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const { firebaseUuid } = useAppSelector((state) => ({
    firebaseUuid: state.userSetting.firebaseUuid,
  }));

  const dispatch = useAppDispatch();

  const goBack = () => {
    dispatch(setPreferencesTab(PreferenceTab.ACCOUNT));
  };

  const onPressSubmit = async () => {
    if (!firebaseUuid) return;
    setLoading(true);
    try {
      await new CouponsResource().readMany({
        coupon_code: redeemCode,
      });
      const userResponse = await new ProfileResource().readOne(firebaseUuid);
      dispatch(setUserInfo(userResponse.data));
      setShowSuccessMessage(true);
      setTimeout(() => {
        goBack();
        setShowSuccessMessage(false);
      }, 5000);
    } catch (_) {
      setErrorText(i18n.t("incorrect_code"));
    }
    setLoading(false);
  };
  return {
    errorText,
    loading,
    redeemCode,
    showSuccessMessage,
    goBack,
    setRedeemCode,
    onPressSubmit,
  };
};
