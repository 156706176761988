import {
  ClearUserDataAction,
  SetCalendarDataAction,
  SetUserPreferencesAction,
  SetUserSubscriptionAction,
} from "../types/user-setting.action.type";
import { Dispatch } from "redux";
import { UserSettingshDispatchTypes } from "redux-store/types/dispatch.type";
import {
  SetFirebaseUuidAction,
  SetOnboardingUserFullnameAction,
  SetUserBearerTokenAction,
  SetUserInfoAction,
} from "redux-store/types/user-setting.action.type";
import { ProfileEntity } from "communicators/resources/profile/profile.type";
import { SubscriptionEntity } from "communicators/resources/subscription/subscription.type";
import { CalendarsResource } from "communicators/resources/calendars/calendars.resource";
import { UserPreferencesEntity } from "communicators/resources/user-preferences/user-preferences.type";
import { CalendarEntity } from "communicators/resources/calendars/calendars.type";
import { SubscriptionsResource } from "communicators/resources/subscription/subscription.resource";

export const setUserBearerToken = (token: string) => {
  return (dispatch: Dispatch): void => {
    dispatch<SetUserBearerTokenAction>({
      type: UserSettingshDispatchTypes.setUserBearerToken,
      payload: token,
    });
  };
};

export const setUserInfo = (userInfo: ProfileEntity) => {
  return (dispatch: Dispatch): void => {
    dispatch<SetUserInfoAction>({
      type: UserSettingshDispatchTypes.setUserInfo,
      payload: userInfo,
    });
  };
};

export const setOnboardingUserFullname = (fullname: string) => {
  return (dispatch: Dispatch): void => {
    dispatch<SetOnboardingUserFullnameAction>({
      type: UserSettingshDispatchTypes.setOnboardingUserFullname,
      payload: fullname,
    });
  };
};

export const setFirebaseUuid = (firebaseUuid: string) => {
  return (dispatch: Dispatch): void => {
    dispatch<SetFirebaseUuidAction>({
      type: UserSettingshDispatchTypes.setFirebaseUuid,
      payload: firebaseUuid,
    });
  };
};

export const clearUserData = () => {
  return (dispatch: Dispatch): void => {
    dispatch<ClearUserDataAction>({
      type: UserSettingshDispatchTypes.clearUserData,
    });
  };
};

export const setSubscriptions = (subscriptions?: SubscriptionEntity[]) => {
  return async (dispatch: Dispatch) => {
    if (!subscriptions) {
      try {
        const response = await new SubscriptionsResource().readMany();
        if (response.data) {
          dispatch<SetUserSubscriptionAction>({
            type: UserSettingshDispatchTypes.setUserSubscriptions,
            payload: response.data,
          });
        }
      } catch (_) {}
    }
    if (subscriptions) {
      dispatch<SetUserSubscriptionAction>({
        type: UserSettingshDispatchTypes.setUserSubscriptions,
        payload: subscriptions,
      });
    }
  };
};

export const setCalendarData = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    let payload: CalendarEntity[] = [];
    try {
      const { data } = await new CalendarsResource().readMany();
      payload = data;
    } catch (_) {}

    dispatch<SetCalendarDataAction>({
      type: UserSettingshDispatchTypes.setCalendarData,
      payload: payload,
    });
  };
};

export const setUserPreferences = (preferences: UserPreferencesEntity) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch<SetUserPreferencesAction>({
      type: UserSettingshDispatchTypes.setUserPreferences,
      payload: preferences,
    });
  };
};
