import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPhoneNumber,
  onAuthStateChanged,
  RecaptchaVerifier,
  signOut,
  UserCredential,
  ConfirmationResult,
  signInWithPopup,
  sendEmailVerification,
  sendPasswordResetEmail,
  OAuthProvider,
  PhoneAuthProvider,
  linkWithCredential,
} from "firebase/auth";
import { FIREBASE_ERRORS } from "./errors/error.dictionary";
import {
  LoginOptions,
  EmailLoginCredentials,
  PhoneLoginCredentials,
  LoginCredentials,
} from "./type";
import { i18n } from "constants/i18n.constants";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIRABASE_API_KEY,
  authDomain: process.env.REACT_APP_FIRABASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASURMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const phoneProvider = new PhoneAuthProvider(auth);

export class FirebaseService {
  async login(
    method: LoginOptions.EMAIL,
    credentials: EmailLoginCredentials
  ): Promise<UserCredential>;

  async login(
    method: LoginOptions.PHONE,
    credentials: PhoneLoginCredentials
  ): Promise<ConfirmationResult>;

  async login(method: LoginOptions.GOOGLE): Promise<UserCredential>;

  async login(method: LoginOptions.APPLE): Promise<UserCredential>;

  async login(
    method: LoginOptions,
    credentials?: LoginCredentials
  ): Promise<ConfirmationResult | UserCredential> {
    try {
      if (method === LoginOptions.PHONE)
        return await this.loginWithPhone(credentials as PhoneLoginCredentials);
      if (method === LoginOptions.GOOGLE) return await this.loginWithGoogle();
      if (method === LoginOptions.APPLE) return await this.loginWithApple();
      return await this.loginWithEmail(credentials as EmailLoginCredentials);
    } catch (error: any) {
      return this.handleError(error);
    }
  }

  signup = async (credentials: EmailLoginCredentials) => {
    try {
      const response = await createUserWithEmailAndPassword(
        auth,
        credentials.email,
        credentials.password
      );

      await sendEmailVerification(response.user);
      return response.user;
    } catch (error) {
      return this.handleError(error);
    }
  };

  sendForgotPasswordEmail = async (email: string) => {
    return await sendPasswordResetEmail(auth, email);
  };

  sendVerifyEmail = async () => {
    return await sendEmailVerification(auth.currentUser!);
  };

  logout = async () => signOut(auth);

  onAuthStateChangedListener = (listener: (...args: any) => any) =>
    onAuthStateChanged(auth, listener);

  handleError = (error: any) => {
    if (FIREBASE_ERRORS[error.code]) throw new FIREBASE_ERRORS[error.code]();
    throw new FIREBASE_ERRORS.DEFAULT();
  };

  private loginWithEmail = async (
    credentials: EmailLoginCredentials
  ): Promise<UserCredential> =>
    await signInWithEmailAndPassword(
      auth,
      credentials.email,
      credentials.password
    );

  private loginWithPhone = async (
    credentials: PhoneLoginCredentials
  ): Promise<ConfirmationResult> => {
    const applicationVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
      },
      auth
    );
    return await signInWithPhoneNumber(
      auth,
      credentials.phoneNumber,
      applicationVerifier
    );
  };

  private loginWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    return await signInWithPopup(auth, provider);
  };

  private loginWithApple = async () => {
    const provider = new OAuthProvider("apple.com");
    return await signInWithPopup(auth, provider);
  };

  phoneVerificationOfExistingUser = async (phoneNumber: string) => {
    const applicationVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
      },
      auth
    );
    const confirmationResult = await phoneProvider.verifyPhoneNumber(
      phoneNumber,
      applicationVerifier
    );
    return confirmationResult;
  };

  linkPhoneNumberToUser = async (
    verificationId: string,
    verificationCode: string
  ) => {
    const user = auth.currentUser;
    if (!user) return;
    const credantial = PhoneAuthProvider.credential(
      verificationId,
      verificationCode
    );
    try {
      const response = await linkWithCredential(auth.currentUser, credantial);
      return { error: false, message: "", result: response };
    } catch (error: any) {
      if (error.code === "auth/account-exists-with-different-credential") {
        return {
          error: true,
          message: i18n.t("existing_account_phone"),
          result: undefined,
        };
      }
      return { error: true, message: "", result: undefined };
    }
  };

  logScreenView = (screenName: string) => {
    logEvent(analytics, "screen_view" as never, {
      firebase_screen: screenName,
    });
  };
}
