import { palette } from "assets/palette";
import React from "react";
import { Text } from "elements/text/Text";
import { i18n } from "constants/i18n.constants";
import './PaymentMethodModal.scss';

interface Props {
  isDefaultCard: boolean;
  onClickSetAsPrimary?: () => void;
  onClickUpdatePaymentMethod: () => void;
  onClickRemove?: () => void;
}

export const PaymentMethodModal = ({ isDefaultCard, onClickUpdatePaymentMethod, onClickRemove, onClickSetAsPrimary }: Props) => {

  return (
    <div className="payment-method-modal-container">
      {isDefaultCard ? <Text style={styles.text} onPress={onClickUpdatePaymentMethod}>{i18n.t("update_payment_method")}</Text> :
        <>
          <Text style={styles.text} onPress={onClickSetAsPrimary}>{i18n.t("set_as_primary")}</Text>
          {/* <div style={styles.separator} />
          <Text style={styles.text} onPress={onClickUpdatePaymentMethod}>{i18n.t("update_payment_method")}</Text> */}
          <div style={styles.separator} />
          <Text style={styles.text} onPress={onClickRemove}>{i18n.t("remove")}</Text>
        </>}
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "center",
    padding: 15,
    backgroundColor: palette.white,
    borderRadius: "10px 0px 10px 10px",
    boxShadow: "0px 2px 2px 0px #00000040",
    width: "110%",
    marginRight: "auto"
  },
  text: {
    fontWeight: "400",
    fontSize: 13,
    color: palette.gray.dark,
    marginTop: 10,
    marginBottom: 10,
    cursor: "pointer"
  },
  separator: {
    width: "100%",
    height: 0.4,
    backgroundColor: palette.gray.mediumLight
  }
};