import { i18n } from "constants/i18n.constants";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import "./MagicPlannerDetail.scss";

interface Props {
  onClickYes?: () => void;
  onClickNo?: () => void;
  text?: string;
  id?: string;
  popupDetailRef?: React.MutableRefObject<any>;
}

export const MagicPlannerDetail = ({ onClickNo, onClickYes, text, id, popupDetailRef }: Props) => {
  return (
    <div className="magic-planner-detail-container" id={id} ref={popupDetailRef}>
      <div className="magic-planner-detail-top-container">
        {i18n.t("lets_organize_your_day")}
      </div>
      <div className="magic-planner-detail-center-container">
        <div className="magic-planner-detail-prompt-container">
          {!text ? (
            <>
              <span className="magic-planner-detail-prompt-name-text">
                {i18n.t("magic_planner_hi")}
              </span>
              <div>&nbsp;</div>
            </>
          ) : null}
          <span className="magic-planner-detail-prompt-question-text">
            {text ? text : i18n.t("magic_planner_how_can_help_text")}
          </span>
        </div>
        {!text ? (
          <div className="magic-planner-detail-button-container">
            <button
              className="magic-planner-detail-yes-button"
              onClick={onClickYes}
            >
              {i18n.t("magic_planner_yes")}
            </button>
            <button
              className="magic-planner-detail-no-button"
              onClick={onClickNo}
            >
              {i18n.t("magic_planner_no_thanks")}
            </button>
          </div>
        ) : null}
      </div>
      <Icon
        name={IconName.MAGIC_PLANNER_TRIANGLE}
        style={{
          position: "absolute",
          bottom: -45,
          right: -12,
          zIndex: -1,
          cursor: "default",
        }}
      />
    </div>
  );
};
