import { i18n } from "constants/i18n.constants";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Text } from "elements/text/Text";
import "./PaymentMethodItem.scss";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { PaymentMethodModal } from "../PaymentMethodModal/PaymentMethodModal";
import { palette } from "assets/palette";
import { PaymentMethods } from "constants/payment-methods.contants";

interface Props {
  paymentMethodCardImage: string;
  last4CardNumber: string;
  cardExpiryDate: string;
  name: string;
  isDefaultCard: boolean;
  setPrimary?: () => void;
  removeCard?: () => void;
}

export const PaymentMethodItem = ({
  paymentMethodCardImage,
  last4CardNumber,
  cardExpiryDate,
  name,
  isDefaultCard,
  setPrimary,
  removeCard,
}: Props) => {
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const ref = useRef<any>();
  const iconRef = useRef<any>();
  const toggleActions = () => {
    setIsDetailOpen(!isDetailOpen);
  };
  const handleClose = () => {
    setIsDetailOpen(false);
  };

  const useOutsideClick = () => {
    const handleClick = useCallback((event: { target: any }) => {
      if (
        !ref?.current?.contains(event.target) &&
        !iconRef?.current?.contains(event.target)
      ) {
        handleClose();
      }
    }, []);
    useEffect(() => {
      document.addEventListener("mouseup", handleClick);

      return () => {
        document.removeEventListener("mouseup", handleClick);
      };
    }, [handleClick]);
  };
  useOutsideClick();
  return name !== PaymentMethods.APPLE ? (
    <div
      className={
        isDefaultCard
          ? "default-payment-method-item-container"
          : "payment-method-item-container"
      }
    >
      <img
        width={50}
        height={30}
        src={paymentMethodCardImage}
        alt="paymentMethodImage"
      />
      <div style={styles.modalParent}>
        <div className="payment-method-right-container">
          <div
            className="payment-method-action-container"
            onClick={!isDefaultCard ? toggleActions : undefined}
            style={
              isDetailOpen && !isDefaultCard
                ? {
                    ...styles.cardRowContainer,
                    ...styles.openedCardRowContainer,
                  }
                : {
                    ...styles.cardRowContainer,
                    cursor: isDefaultCard ? undefined : "pointer",
                  }
            }
            ref={iconRef}
          >
            {!isDefaultCard ? (
              <>
                <Text className="payment-method-gray-text">
                  {i18n.t("actions")}
                </Text>
                <Icon
                  name={IconName.CALENDAR_FORWARD}
                  style={styles.downIcon}
                />
              </>
            ) : null}
          </div>
          <Text className="payment-method-gray-text">{`****${last4CardNumber} | Expires ${cardExpiryDate}`}</Text>
          {name ? (
            <Text className="payment-method-black-text">{name}</Text>
          ) : null}
        </div>
        {isDetailOpen && !isDefaultCard ? (
          <div style={styles.modalContainer} ref={ref}>
            <PaymentMethodModal
              isDefaultCard={isDefaultCard}
              onClickUpdatePaymentMethod={() => {}}
              onClickSetAsPrimary={setPrimary}
              onClickRemove={removeCard}
            />
          </div>
        ) : null}
      </div>
    </div>
  ) : (
    <div className="apple-account-card-container">
      <Icon width={50} height={50} name={IconName.APPLE_CARD} />
      <Text>{i18n.t("apple_account")}</Text>
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  downIcon: {
    rotate: "90deg",
  },
  cardRowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: "100%",
    height: "30px",
  },
  openedCardRowContainer: {
    height: 35,
    width: "75%",
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: palette.white,
    boxShadow: "0px 1px 3px 0px #00000040",
  },
  modalParent: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "flex-end",
    justifyContent: "center",
    position: "relative",
  },
  modalContainer: {
    position: "absolute",
    top: 35,
    left: "-10%",
    zIndex: 1,
    width: "100%",
  },
};
