import React from "react";
import { Grid } from "@mui/material";

interface Props {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

export const GridContainer = ({ children, ...rest }: Props) => {
  return (
    <Grid container {...rest}>
      {children}
    </Grid>
  );
};
