import * as React from "react";
import { palette } from "assets/palette";
import Drawer from "@mui/material/Drawer";
import {
  AllBoardDataEntityType,
  IBoard,
  SecondDrawerType,
} from "interfaces/main.interfaces";
import { DateTime } from "luxon";
import { styled } from "@mui/material/styles";
import { TaskEntity } from "communicators/resources/event-resources/tasks/tasks.type";
import { useDrawerLeftDndHook } from "./useDrawerLeftDndHook";
import { CompletedTasks } from "pages/private/home/components/DrawerLeft/CompletedTasks/CompletedTasks";
import { InboxTasks } from "pages/private/home/components/DrawerLeft/InboxTasks/InboxTasks";
import { SomedayTasks } from "pages/private/home/components/DrawerLeft/SomedayTasks/SomedayTasks";
import { Asana } from "pages/private/home/components/DrawerLeft/Asana/Asana";
import { Todoist } from "pages/private/home/components/DrawerLeft/Todoist/Todoist";
import { GoogleCalendar } from "pages/private/home/components/DrawerLeft/GoogleCalendar/GoogleCalendar";
import { LeftSettingsBar } from "pages/private/home/components/LeftSettignsBar/LeftSettingsBar";
import { DRAWER_WIDTH, LEFT_BAR_WIDTH } from "pages/private/home/constants";

interface Props {
  boardData: IBoard[];
  onPressBoardPlus: (date: DateTime) => void;
  onTaskCreate: (
    index: number,
    boardDate: DateTime,
    createdTask: TaskEntity
  ) => void;
  inboxVisibleData: IBoard[];
  leftDrawerType: string;
  previousMonth: () => void;
  nextMonth: () => void;
  inboxStartDate: DateTime;
  inboxLoading: boolean;
  onClickCheckBox: (data: AllBoardDataEntityType) => void;
  deleteTask?: (data: AllBoardDataEntityType) => void;
  somedayLoading: boolean;
  somedayData: IBoard[];
}

export const DrawerLeft = ({
  boardData,
  onPressBoardPlus,
  onTaskCreate,
  inboxVisibleData,
  leftDrawerType,
  previousMonth,
  nextMonth,
  inboxStartDate,
  inboxLoading,
  onClickCheckBox,
  deleteTask,
  somedayData,
  somedayLoading,
}: Props) => {
  const {
    secondDrawerOpened,
    openSecondDrawer,
    closeSecondDrawer,
  } = useDrawerLeftDndHook({
    leftDrawerType,
  });

  const drawerSX = {
    width: secondDrawerOpened.opened ? DRAWER_WIDTH + LEFT_BAR_WIDTH - 60 : LEFT_BAR_WIDTH,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: secondDrawerOpened.opened ? DRAWER_WIDTH + LEFT_BAR_WIDTH : LEFT_BAR_WIDTH,
      boxSizing: "border-box",
    },
  };

  return (
    <div>
    <Drawer sx={drawerSX} variant="persistent" anchor="left" open>
      <LeftSettingsBar
          openSecondDrawer={openSecondDrawer}
          secondDrawerOpenStatus={secondDrawerOpened}
          isChildOfAFT
      />
      <SecondDrawer
        open={secondDrawerOpened.opened}
        className="Windows"
      >
        {(() => {
          switch(secondDrawerOpened.type) {
            case SecondDrawerType.COMPLETED_TASKS:
              return (
                <CompletedTasks
                  closeDrawer={closeSecondDrawer}
                  searchedDate={DateTime.now()}
                  onClickCheckBox={onClickCheckBox}
                />
              )
            case SecondDrawerType.INBOX_TASKS:
              return (
                <InboxTasks
                closeDrawer={closeSecondDrawer}
                onPressPlus={onPressBoardPlus}
                onTaskCreate={onTaskCreate}
                inboxVisibleData={inboxVisibleData}
                previousMonth={previousMonth}
                nextMonth={nextMonth}
                inboxStartDate={inboxStartDate}
                inboxLoading={inboxLoading}
                onClickCheckBox={onClickCheckBox}
                deleteTask={deleteTask}
                />
              )
            case SecondDrawerType.SOMEDAY_TASKS:
              return (
                <SomedayTasks closeDrawer={closeSecondDrawer} somedayLoading={somedayLoading} somedayData={somedayData} />
              )
            case SecondDrawerType.ASANA:
              return (
                <Asana closeDrawer={closeSecondDrawer} />
              )
            case SecondDrawerType.TODOIST:
              return (
                <Todoist closeDrawer={closeSecondDrawer} />
              )
            case SecondDrawerType.GOOGLE:
              return (
                <GoogleCalendar closeDrawer={closeSecondDrawer} />
              )
            default:
              return null
          }
        })()}
      </SecondDrawer>
    </Drawer>
    {secondDrawerOpened.opened ? <div style={{width: DRAWER_WIDTH + LEFT_BAR_WIDTH}} /> : null}
    </div>
  );
};

const SecondDrawer = styled("div")<{
  open?: boolean;
}>(({ theme, open }) => ({
  height: "calc(100% - 68px)",
  width: "calc(100% - 85px)",
  flexGrow: 1,
  backgroundColor: palette.white,
  paddingRight: theme.spacing(2),
  paddingLeft: 15,
  top: 68,
  position: "absolute",
  transition: theme.transitions.create(["left"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  left: `-${DRAWER_WIDTH + 10}px`,
  ...(open && {
    transition: theme.transitions.create(["left"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    left: `${LEFT_BAR_WIDTH - 10}px`,
    zIndex: 5,
    overflowY: "scroll",
  }),
}));
