import { palette } from "assets/palette";
import { i18n } from "constants/i18n.constants";
import { CustomButton } from "elements/button/Button";
import React from "react";
import { Text } from "elements/text/Text";
import { usePreferencesBillingHistoryHook } from "./usePreferencesBillingHistory";
import { BillingItem } from "./components/BillingItem";
import { Spinner } from "elements/spinner/Spinner";
import {
  PaymentMethods,
  PaymentPlans,
  PaymentStatus,
} from "constants/payment-methods.contants";

export const PreferencesBillingHistory = () => {
  const {
    visibleInvoices,
    loading,
    onPressItemDownload,
    tableHeaderTexts,
    invoices,
    upgradeNow,
    isAppleHistory,
    activeSubscriptions,
    userInfo,
  } = usePreferencesBillingHistoryHook();

  return (
    <div style={styles.container}>
      <Text style={styles.headerText}>{i18n.t("billing_history")}</Text>
      <div style={styles.tableHeader}>
        {tableHeaderTexts.map((item, index) => (
          <Text style={styles.tableHeaderText} key={index}>
            {item}
          </Text>
        ))}
      </div>
      {loading ? (
        <div style={styles.spinner}>
          <Spinner loading={loading} />
        </div>
      ) : visibleInvoices?.length > 0 ? (
        visibleInvoices.map((item) => (
          <BillingItem
            dateInvoice={item?.date!}
            total={item?.amount}
            onPressDownload={() =>
              onPressItemDownload(item?.data?.attributes?.invoice_pdf)
            }
            paymentMethod={item?.paymentMethodCardType}
            plan={item?.data?.attributes?.plan}
            paymentMethodCard={item?.paymentMethodCardNumber}
            isPaid={
              item?.data?.attributes?.paid
                ? PaymentStatus.PAID
                : PaymentStatus.FAIL
            }
          />
        ))
      ) : isAppleHistory ? (
        <>
          <BillingItem
            dateInvoice={""}
            total={""}
            paymentMethod={PaymentMethods.APPLE}
            plan={PaymentPlans.PREMIUM}
            paymentMethodCard={PaymentMethods.APPLE}
            isPaid={PaymentStatus.PAID}
          />
        </>
      ) : (
        ""
      )}
      {invoices?.length === 0 &&
      !loading &&
      !isAppleHistory &&
      visibleInvoices?.length === 0 ? (
        <div style={styles.noInvoiceContainer}>
          <Text style={styles.noHistory}>{i18n.t("no_billing_history")}</Text>
        </div>
      ) : null}
      {!loading &&
      activeSubscriptions.length === 0 &&
      !userInfo?.attributes?.is_premium &&
      !userInfo?.attributes.is_lifetime ? (
        <CustomButton
          style={styles.upgrade}
          text={i18n.t("upgrade_now")}
          textStyle={styles.buttonText}
          onClick={upgradeNow}
        />
      ) : null}
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flex: 1,
    alignSelf: "stretch",
    flexDirection: "column",
    overflowY: "scroll",
  },
  headerText: {
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "500",
    fontSize: 18,
    color: palette.black,
  },
  upgrade: {
    width: "100%",
    backgroundColor: palette.black,
    height: 50,
    marginBottom: 50,
    marginTop: "auto",
  },
  buttonText: {
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "500",
    fontSize: 15,
    color: palette.white,
  },
  tableHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: 45,
    backgroundColor: palette.gray.iceLight,
    borderRadius: 7,
    marginTop: 20,
  },
  tableHeaderText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 15,
    color: palette.gray.medium,
    width: "100%",
    textAlign: "center",
  },
  noHistory: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 20,
    color: palette.gray.medium,
    width: "100%",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  noInvoiceContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
  },
  spinner: {
    display: "flex",
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 40,
  },
};
