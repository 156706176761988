import { palette } from "assets/palette";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import React, { FC } from "react";
import { Text } from "elements/text/Text";
import { styled } from "@mui/material/styles";
import { CalendarEntity } from "communicators/resources/calendars/calendars.type";
import { Spinner } from "elements/spinner/Spinner";

export interface Props {
  title: string;
  logo: string;
  selected?: boolean;
  calendarIDs?: CalendarEntity[];
  onPress?: () => void;
  loading?: boolean;
}

export const CalendarItem: FC<Props> = ({
  title,
  logo,
  selected,
  calendarIDs,
  loading,
  onPress,
}: Props) => {
  return (
    <div style={styles.mainContainer}>
      <div style={styles.container}>
        <img style={styles.calendarImage} alt="logo" src={logo} />
        <Text style={styles.title}>{title}</Text>
        {!loading || selected ? (
          <div
            style={
              selected ? styles.checkedContainer : styles.unCheckedContainer
            }
            onClick={onPress}
          >
            {selected ? (
              <Icon name={IconName.TICK} width={10} fill={palette.white} />
            ) : null}
          </div>
        ) : null}
        <Spinner
          style={styles.spinnerStyle}
          loading={loading && !selected}
          color="primary"
          size={"small"}
        />
      </div>
      {calendarIDs
        ? calendarIDs.map((item) => (
            <div style={styles.meetDetailContainer} key={item.id}>
              <EmailLetterContainer color={palette.yellow.dark}>
                <Text style={styles.letter}>
                  {item.remote_id.charAt(0).toUpperCase()}
                </Text>
              </EmailLetterContainer>
              <Text style={styles.calendarId}>{item.remote_id}</Text>
            </div>
          ))
        : null}
    </div>
  );
};

const EmailLetterContainer = styled("div")<{
  color?: string;
}>(({ color }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 16,
  height: 16,
  borderRadius: "50%",
  backgroundColor: color,
  marginTop: 3,
}));

const styles: Record<string, React.CSSProperties> = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: 20,
    marginBottom: 15,
    borderRadius: 10,
    border: `1px solid ${palette.gray.light}`,
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  unCheckedContainer: {
    width: 20,
    height: 20,
    borderRadius: 10,
    border: `1px solid ${palette.gray.dark}`,
    marginLeft: "auto",
    marginRight: 15,
    cursor: "pointer",
  },
  checkedContainer: {
    width: 20,
    height: 20,
    borderRadius: 10,
    backgroundColor: palette.blue.lighter,
    border: `1px solid ${palette.blue.lighter}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: 15,
  },
  title: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 10,
    color: palette.black,
    margin: "13px 0px 13px 0px",
  },
  calendarImage: {
    width: 16,
    height: 16,
    margin: 13,
  },
  meetDetailContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: 13,
  },
  letter: {
    fontFamily: "Gilroy-Medium",
    fontSize: 10,
    color: palette.white,
  },
  calendarId: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 12,
    color: palette.gray.dark,
    marginLeft: 13,
    display: "inline",
    flex: 1,
    width: "100%",
    overflowWrap: "anywhere",
  },
  spinnerStyle: {
    marginLeft: "auto",
  },
};
