import React, { useState } from "react";
import { StandardTextFieldProps, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { palette } from "assets/palette";
import { IconProps } from "elements/icon/Icon.type";
import { Icon } from "elements/icon/Icon";
import { Text } from "elements/text/Text";
import "./TextField.scss";

interface Props extends StandardTextFieldProps {
  placeholder?: string;
  label?: string;
  value?: string;
  onChangeText?: (text: string) => void;
  autoComplete?: string;
  rightIconProps?: IconProps;
  leftItem?: React.ReactNode;
  errorText?: string;
  classProp?: string;
}

export const CustomTextField = ({
  placeholder,
  label,
  value,
  autoComplete,
  rightIconProps,
  leftItem,
  classProp,
  errorText,
  onChangeText,
  ...rest
}: Props) => {
  const [focused, setFocused] = useState(false);
  const classes = useStyles();

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    setFocused(false);
  };

  const focusedOrHaveValue = focused || value;

  return (
    <>
      <div
        className={
          focusedOrHaveValue ? classes.focusedDiv + " " + classProp : classes.unfocusedDiv + " " + classProp
        }
      >
        {leftItem || null}
        <TextField
          {...rest}
          fullWidth
          label={
            focusedOrHaveValue ? label || placeholder : placeholder || label
          }
          variant="standard"
          autoComplete={autoComplete}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
          onChange={(event) => onChangeText && onChangeText(event.target.value)}
          InputProps={{
            className: focusedOrHaveValue
              ? classes.inputFocused
              : classes.inputUnfocused,
            disableUnderline: true,
          }}
          InputLabelProps={{
            style: {
              color: focusedOrHaveValue ? palette.black : palette.gray.medium,
            },
          }}
        />
        {rightIconProps ? <Icon {...rightIconProps} /> : null}
      </div>
      <Text style={errorTextStyle}>{errorText || ""}</Text>
    </>
  );
};

const useStyles = makeStyles({
  inputFocused: {
    height: 30,
    fontFamily: "Gilroy",
  },
  inputUnfocused: {
    height: 50,
    fontFamily: "Gilroy",
  },
  focusedDiv: {
    alignSelf: "stretch",
    backgroundColor: palette.gray.iceLight,
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 30,
    height: 60,
    borderRadius: 16,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    border: `1px solid ${palette.black}`,
  },
  unfocusedDiv: {
    border: `1px solid ${palette.gray.iceLight}`,
    alignSelf: "stretch",
    backgroundColor: palette.gray.iceLight,
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 30,
    height: 60,
    borderRadius: 16,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
});

const errorTextStyle: React.CSSProperties = {
  fontSize: 14,
  color: palette.red.dark,
  textAlign: "left",
  display: "flex",
  alignSelf: "flex-start",
  margin: "0 0 8px 8px",
};
