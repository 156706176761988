import * as React from "react";
import { palette } from "assets/palette";
import { i18n } from "constants/i18n.constants";
import { Text } from "elements/text/Text";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { CustomTooltip } from "components/custom-tooltip/CustomTooltip";
import { Spinner } from "elements/spinner/Spinner";
import { resizeByResolutionWidth } from "helpers/utils.helper";
import { TimelineListItem } from "../../TimelineListItem/TimelineListItem";
import { AllBoardDataEntityType, IBoard } from "interfaces/main.interfaces";
import { DateTime } from "luxon";
import { TaskEntity } from "communicators/resources/event-resources/tasks/tasks.type";

interface Props {
  closeDrawer: () => void;
  somedayLoading: boolean;
  somedayData: IBoard[];
  onPressSomedayPlus?: () => void;
  onTaskCreateSomeday?: (
    index: number,
    boardDate: DateTime,
    createdTask: TaskEntity,
    isNewTask: boolean
  ) => void;
  deleteSomedayTask?: (data: AllBoardDataEntityType) => void;
  onClickCheckBoxSomeday?: (data: AllBoardDataEntityType) => void;
}

export const SomedayTasks = ({
  closeDrawer,
  onPressSomedayPlus,
  onTaskCreateSomeday,
  somedayLoading,
  somedayData,
  deleteSomedayTask,
  onClickCheckBoxSomeday
}: Props) => {
  return (
    <>
      <CustomTooltip
        description={i18n.t("someday_tasks_drawer_tooltip")}
        direction="top-start"
      >
        <div style={styles.secondDrawerHeaderContainer}>
          <Text style={styles.secondDrawerHeader}>{i18n.t("someday")}</Text>
          <Icon
            width={resizeByResolutionWidth(30)}
            height={resizeByResolutionWidth(30)}
            name={IconName.PLUS}
            fill={palette.white}
            onPress={onPressSomedayPlus}
          />
          <Icon
            width={resizeByResolutionWidth(15)}
            height={resizeByResolutionWidth(30)}
            name={IconName.LEFT_DRAWER_BIG_BACK}
            onPress={closeDrawer}
          />
        </div>
      </CustomTooltip>
      <div style={styles.scrollContainer}>
        {somedayLoading ? (
          <Spinner
            style={styles.spinner}
            size={50}
            color={"primary"}
            loading={somedayLoading}
          />
        ) : (
          somedayData.map((item, index) => {
            return (
              <div style={styles.taskItemContainer} key={`${item}${index}`}>
                {item.data.map((card, index) => {
                  return (
                    <TimelineListItem
                      boardId={card.id}
                      key={card.id}
                      index={index}
                      data={card}
                      onTaskCreate={(newTask, isNewTask) =>
                        onTaskCreateSomeday &&
                        onTaskCreateSomeday(
                          index,
                          DateTime.utc(3000, 1, 2, 0, 0, 0, 0),
                          newTask,
                          isNewTask
                        )
                      }
                      deleteTask={deleteSomedayTask}
                      onClickCheckBox={onClickCheckBoxSomeday}
                    />
                  );
                })}
              </div>
            );
          })
        )}
      </div>
    </>
  );
};

const grid = 8;

const styles: Record<string, React.CSSProperties> = {
  iconContainer: {
    paddingTop: 5,
  },
  secondDrawerHeaderContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  secondDrawerHeader: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 20,
    color: palette.black,
    textAlign: "left",
  },
  itemContainer: {
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    borderRadius: 12,
    background: palette.white,
    boxShadow: "0px 4px 4px 0px #0000001A",
    height: 79,
  },
  title: {
    display: "flex",
    marginRight: 14,
    color: palette.gray.dark,
    fontSize: 15,
    fontFamily: "Gilroy-Semibold",
    fontWeight: "500",
    flex: 1,
  },
  row: {
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    flexDirection: "row",
    flex: 1,
  },
  iconGap: {
    width: 20,
  },
  itemIconContainer: {
    display: "flex",
    flexDirection: "row",
  },
  completedIcon: {
    marginLeft: -7,
  },
  spinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  scrollContainer: {
    overflowY: "scroll",
    overflow: "visible",
  },
  headerIconContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    flex: 1,
    justifyContent: "flex-end",
  },
  taskItemContainer: {
    paddingLeft: 2,
    paddingRight: 2,
    marginTop: 10,
  },
};
