import { palette } from "assets/palette";
import { CustomButton } from "elements/button/Button";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { Text } from "elements/text/Text";
import { i18n } from "constants/i18n.constants";
import "./VerifyEmailPopup.scss";

interface Props {
  onClickClose?: () => void;
  onClickResend?: () => void;
}

export const VerifyEmailPopup = ({ onClickClose, onClickResend}: Props) => {
  const [mainText, hightlightedText] = i18n.t("please_verify_your_email").split('$#$');
  return (
    <div className="verify-email-popup">
      <Text className="verify-email-popup-text">{mainText}&nbsp;<Text className="verify-email-popup-highlighted-text">{hightlightedText}</Text></Text>
      <CustomButton
        style={styles.button}
        textStyle={styles.buttonText}
        text={i18n.t("resend")}
        onClick={onClickResend}
      />
      <Icon name={IconName.CLOSE} width={7} height={7} style={styles.closeIcon} onPress={onClickClose} />
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  button: {
    backgroundColor: palette.gray.iceLight,
    padding: "8.5px 15px",
    marginLeft: 15
  },
  buttonText: {
    fontSize: 12.75,
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "500",
    color: palette.gray.medium,
  },
  closeIcon: {
    position: "absolute",
    top: 7,
    right: 7
  }
};
  