import { InsumoCommunicator } from "../insumo/insumo.communicator";
import { ResourceName, Endpoints, HasQueryMethods } from "../types";
import { IAPIResource } from "communicators/resources/type";
import { InsumoAPIError } from "communicators/insumo/errors/insumo.error";
import { ErrorNames } from "communicators/insumo/errors/type";
import { LocalStorageParam } from "constants/local-storage.constant";
import {
  INSUMO_ERRORS,
  INSUMO_RESOURCE_ERRORS,
} from "communicators/insumo/errors/error.dictionary";

export interface IPaginationOptions {
  total: number;
  limitValue: number;
  totalPages: number;
  current: number;
  nextPage: number | null;
  prevPage: number | null;
}
export class ResourceManagementService<T extends IAPIResource>
  implements HasQueryMethods<T>
{
  communicator: InsumoCommunicator<T>;

  pageSize: number;
  currentPage: number;
  canLoadMore = true;

  readOne;
  readMany;
  createOne;
  updateOne;
  removeOne;

  constructor(
    public resourceName: ResourceName,
    public paginationOptions?: Partial<IPaginationOptions>
  ) {
    this.pageSize = this.paginationOptions?.limitValue ?? 1;
    this.currentPage = this.paginationOptions?.current ?? 1;

    const FabricatedCommunicator = this.fabricateResourceCommunicator();
    this.communicator = new FabricatedCommunicator();

    this.readOne = (id: string, query?: T["query"]) =>
      query
        ? this.communicator.readOne(id, query)
        : this.communicator.readOne(id);

    this.readMany = async (query: T["query"] = {}) => {
      const modifiedQuery = this.injectPaginationOptionsToParams(query);

      const response = await this.communicator.readMany(modifiedQuery);

      if (response?.meta && response?.meta?.pagination)
        this.canLoadMore = response?.meta.pagination.next_page != null;

      return response;
    };

    this.createOne = (candidate: T["candidate"], query?: T["query"]) =>
      query
        ? this.communicator.createOne(candidate, query)
        : this.communicator.createOne(candidate);

    this.updateOne = (...args: [string, Partial<T["candidate"]>]) =>
      this.communicator.updateOne(...args);

    this.removeOne = (id: string) => this.communicator.removeOne(id);
  }

  private fabricateResourceCommunicator = () => {
    const resourceName = this.resourceName;

    return class ResourceCommunicator extends InsumoCommunicator<T> {
      endpoint = Endpoints[resourceName];
      errorLookup = {
        ...INSUMO_ERRORS,
        ...INSUMO_RESOURCE_ERRORS[resourceName],
      };
    };
  };

  handleCommunicationErrors = async (error: InsumoAPIError): Promise<void> => {
    switch (error.name) {
      case ErrorNames.UNAUTHORIZED:
        localStorage.removeItem(LocalStorageParam.TOKEN);
        break;
      default:
        throw error;
    }
  };

  injectPaginationOptionsToParams = (query: T["query"]) => {
    return this.paginationOptions
      ? {
        ...query,
        page: this.currentPage++,
        per: this.pageSize,
      }
      : query;
  };
}
