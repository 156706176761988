import { getProgressValue } from "helpers/utils.helper";
import { IBoard } from "interfaces/main.interfaces";
import { useEffect, useState } from "react";
import { useAppSelector } from "redux-store/store.hooks";
import { SecondDrawerType } from "interfaces/main.interfaces";
import { useNavigate } from "react-router-dom";
import { EventSubscription } from "fbemitter";
import {
  EventEmitter,
  EventEmitterType,
} from "constants/event-emitter.constant";

interface Props {
  boardData: IBoard[];
}

export const useDrawerLeftHook = ({ boardData }: Props) => {
  const [secondDrawerOpened, setSecondDrawerOpened] = useState<{
    type: string | null;
    opened: boolean;
  }>({
    type: null,
    opened: false,
  });

  const [isPremium, setIsPremium] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>();

  const navigate = useNavigate();

  const { subscriptions, userInfo } = useAppSelector((state) => ({
    subscriptions: state.userSetting.subscriptions,
    userInfo: state.userSetting.userInfo,
  }));

  const getIsPremium = async () => {
    if (userInfo) {
      setIsPremium(
        !!userInfo?.attributes.is_premium || !!userInfo.attributes.is_lifetime
      );
    }
  };

  useEffect(() => {
    getIsPremium();
  }, [userInfo]);

  const closeSecondDrawer = () => {
    setSecondDrawerOpened({ type: null, opened: false });
  };

  const openSecondDrawer = (type: string) => {
    if (type === secondDrawerOpened.type) {
      closeSecondDrawer();
      return;
    }
    setSecondDrawerOpened({ type, opened: true });
  };

  const openSecondDrawerAfterSearch = ({
    leftDrawerType,
  }: {
    leftDrawerType: SecondDrawerType;
  }) => {
    openSecondDrawer(leftDrawerType);
  };

  const onPressUpgradeNow = () => {
    navigate("/checkout", {
      state: "inside_app",
    });
  };

  useEffect(() => {
    const listener: EventSubscription = EventEmitter.addListener(
      EventEmitterType.CHANGE_LEFT_DRAWER_TYPE,
      openSecondDrawerAfterSearch
    );

    return () => {
      listener.remove();
    };
  }, []);

  const calendarHoveredDates = boardData.map((item) => item.date);
  const { error, totalDay, remainingDay } = getProgressValue(subscriptions);
  const progressValue = error
    ? 0
    : ((totalDay - remainingDay) / totalDay) * 100;
  return {
    calendarHoveredDates,
    secondDrawerOpened,
    progressValue,
    remainingDay,
    openSecondDrawer,
    closeSecondDrawer,
    openSecondDrawerAfterSearch,
    error,
    isPremium,
    onPressUpgradeNow,
    selectedIndex,
    setSelectedIndex
  };
};
