export enum LoginOptions {
  EMAIL = "email",
  PHONE = "phone",
  GOOGLE = "google",
  APPLE = "apple",
}

export interface EmailLoginCredentials {
  email: string;
  password: string;
}

export interface PhoneLoginCredentials {
  phoneNumber: string;
}

export type LoginCredentials = EmailLoginCredentials | PhoneLoginCredentials;
