import { AllBoardDataEntityType } from "interfaces/main.interfaces";
import { IEvent } from "pages/private/home/components/DrawerRight/types";

export enum DNDItemType {
  BOARD_ITEM = "board_item",
  TIMELINE_ITEM = "timeline_item",
}

export interface DNDTimelineItem {
  type: DNDItemType.TIMELINE_ITEM;
  data: IEvent;
}

export interface DNDBoardItem {
  type: DNDItemType.BOARD_ITEM;
  data: AllBoardDataEntityType;
}

export type DNDEvent = DNDTimelineItem | DNDBoardItem;

export interface DropResult {
    destination: { droppableId: string; index: number };
    source: { droppableId: string | null; index: number };
  }