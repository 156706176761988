import { EventType } from "communicators/resources/event-resources/event-resources.type";
import { TasksResource } from "communicators/resources/event-resources/tasks/tasks.resource";
import { getAllBoardData } from "helpers/data.helper";
import { isDefaultScheduledTime } from "helpers/isDefaultScheduledTime";
import { AllBoardDataEntityType, IBoard } from "interfaces/main.interfaces";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { AllBoardDataType } from "./useHomeHook";
import { TaskEntity } from "communicators/resources/event-resources/tasks/tasks.type";
import { TaskGoalResource } from "communicators/resources/task-goal/task-goal.resource";
import { useAppSelector } from "redux-store/store.hooks";

type Props = {
  allBoardData: AllBoardDataType;
  setAllBoardData: React.Dispatch<React.SetStateAction<AllBoardDataType>>;
};

export const useSomedayHook = ({ allBoardData, setAllBoardData }: Props) => {
  const [somedayData, setSomedayData] = useState<{
    all: IBoard[];
  }>({
    all: [],
  });
  const [somedayLoading, setSomedayLoading] = useState<boolean>(false);
  const [someday, setSomeday] = useState<DateTime>(DateTime.now());

  const { refreshSomedayTasks } = useAppSelector((state) => ({
    refreshSomedayTasks: state.homePage.refreshSomedayTasks,
  }));

  const getSomedayTasks = async (resync?: boolean) => {
    try {
      const response = await new TasksResource().readMany(
        {
          start_date: DateTime.utc(2999, 12, 30, 0, 0, 0, 0).toSQLDate(),
          end_date: DateTime.utc(3000, 1, 2, 0, 0, 0, 0).toSQLDate(),
          per: 100000,
          filter: "someday",
        },
        resync
      );
      return response.data;
    } catch (_) {
      return [];
    }
  };

  const onTaskCreateSomeday = (
    index: number,
    _: DateTime,
    createdTask: TaskEntity,
    isNewTask: boolean
  ) => {
    const copyAllBoard: { all: IBoard[] } = { ...somedayData };
    copyAllBoard.all.forEach((currentBoard, boardIndex) => {
      if (
        currentBoard.date.toSQLDate() ===
        DateTime.utc(3000, 1, 1, 0, 0, 0, 0).toSQLDate()
      ) {
        const boardItemId = copyAllBoard.all[boardIndex].data[index].id;
        if (isNewTask && boardItemId) {
          copyAllBoard.all[boardIndex].data.splice(index, 0, createdTask);
        } else {
          copyAllBoard.all[boardIndex].data[index] = createdTask;
        }
      }
    });

    setSomedayData(copyAllBoard);
  };

  const getInitialSomedayData = async (showLoading = true) => {
    setSomedayLoading(showLoading && true);
    const tasksResponse = await getSomedayTasks(true);
    if (!tasksResponse) return;
    const data = getAllBoardData(
      [...tasksResponse],
      DateTime.utc(2999, 12, 31, 0, 0, 0, 0),
      false
    );
    setSomedayData({ all: data });
    setSomedayLoading(false);
  };

  const onSelectSomeday = (date: DateTime, data?: AllBoardDataEntityType) => {
    setSomeday(date);
    const all = [...allBoardData.all];
    const tasks = [...allBoardData.tasks];
    all.forEach(
      (item) =>
        (item.data = item.data.filter((subitem) => subitem.id !== data?.id))
    );
    tasks.forEach(
      (item) =>
        (item.data = item.data.filter((subitem) => subitem.id !== data?.id))
    );
    setAllBoardData({ ...allBoardData, all: all, tasks: tasks });
  };

  const onPressSomedayPlus = () => {
    const now = DateTime.now();
    const copyAll = { ...somedayData };
    const newTaskItem: TaskEntity = {
      id: "",
      type: EventType.TASK,
      attributes: {
        title: "",
        description: "",
        due_date: "",
        start_date: "",
        end_date: "",
        start_time: null,
        end_time: null,
        permalink_url: null,
        provider: "",
        task_gid: "",
        created_at: now.toSQL()!,
        updated_at: now.toSQL()!,
        completed_count: 0,
        scheduled_time: DateTime.utc(3000, 1, 1, 0, 0, 0, 0).toString(),
      },
    };
    let exist = false;
    copyAll.all.forEach((item, index) => {
      if (
        item.date.toSQLDate() ===
        DateTime.utc(3000, 1, 1, 0, 0, 0, 0).toSQLDate()
      ) {
        copyAll.all[index].data.unshift(newTaskItem);
        exist = true;
      }
    });
    if (!exist) {
      copyAll.all.unshift({
        data: [newTaskItem],
        date: now,
        title: DateTime.utc(3000, 1, 1, 0, 0, 0, 0).toFormat("LLLL dd"),
      });
    }
    setSomedayData(copyAll);
  };

  const filterSomedayDataVisible = () => {
    const copySomedayData = { all: [...somedayData.all] };
    const somedayDatas = copySomedayData.all.filter(
      (item) => item.data.length !== 0
    );
    somedayDatas.forEach((item) => {
      item.data = item.data.filter((subitem) =>
        subitem.type === EventType.TASK
          ? subitem.attributes.completed_count === 0 &&
            isDefaultScheduledTime(subitem) &&
            DateTime.fromISO(
              subitem.attributes.scheduled_time
                ? subitem.attributes.scheduled_time
                : ""
            ).year === DateTime.utc(3000, 1, 1, 0, 0, 0, 0).year
          : null
      );
    });

    const somedayVisibleData = somedayDatas.filter((item) =>
      item.data.some((item) =>
        item.type === EventType.TASK
          ? item.attributes.completed_count === 0 &&
            isDefaultScheduledTime(item) &&
            DateTime.fromISO(
              item.attributes.scheduled_time
                ? item.attributes.scheduled_time
                : ""
            ).year === DateTime.utc(3000, 1, 1, 0, 0, 0, 0).year
          : null
      )
    );
    const sortedSomedayData = somedayVisibleData.filter((item) =>
      item.data.sort((a, b) =>
        a.type === EventType.TASK && b.type === EventType.TASK
          ? b.attributes.created_at.localeCompare(a.attributes.created_at)
          : 1
      )
    );
    return sortedSomedayData;
  };

  const deleteSomedayTask = async (data: AllBoardDataEntityType) => {
    try {
      const all = somedayData.all;
      all.forEach((item) => {
        item.data = item.data.filter((subitem) => subitem.id !== data.id);
      });
      setSomedayData({ ...allBoardData, all: all });
      await new TasksResource().removeOne(data.id);
    } catch {}
  };

  const updateTaskGoal = async (data: TaskEntity) => {
    try {
      await new TaskGoalResource().createOne({
        task_goal: {
          task_id: data.id,
        },
      });
    } catch (_) {}
  };

  const onClickCheckBoxSomeday = async (data: AllBoardDataEntityType) => {
    if (data.type !== EventType.TASK) return;
    try {
      await updateTaskGoal(data);
      const all = [...somedayData.all];
      let allIndex = -1;
      let allBoardIndex = -1;
      for (let i = 0; i < all.length; i++) {
        const foundIndexForAll = all[i].data.findIndex(
          (item) => item.id === data.id
        );
        if (foundIndexForAll >= 0) {
          allIndex = foundIndexForAll;
          allBoardIndex = i;
        }
      }

      if (allBoardIndex >= 0 && allIndex >= 0) {
        all[allBoardIndex].data[allIndex] = data;
      }
      setSomedayData({ ...allBoardData, all: all });
    } catch {}
  };

  useEffect(() => {
    getInitialSomedayData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [someday]);

  useEffect(() => {
    getInitialSomedayData(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshSomedayTasks]);

  const sortedSomedayData: IBoard[] = filterSomedayDataVisible();
  return {
    sortedSomedayData,
    somedayLoading,
    onSelectSomeday,
    onPressSomedayPlus,
    onTaskCreateSomeday,
    deleteSomedayTask,
    onClickCheckBoxSomeday,
  };
};
