import * as React from "react";
import { palette } from "assets/palette";
import { i18n } from "constants/i18n.constants";
import { Text } from "elements/text/Text";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { useCompletedTasksHook } from "./useCompletedTasksHook";
import { Spinner } from "elements/spinner/Spinner";
import { CustomTooltip } from "components/custom-tooltip/CustomTooltip";
import { resizeByResolutionWidth } from "helpers/utils.helper";
import { TimelineListItem } from "../../TimelineListItem/TimelineListItem";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { AllBoardDataEntityType } from "interfaces/main.interfaces";

interface Props {
  closeDrawer: () => void;
  searchedDate: DateTime;
  onClickCheckBox: (task: AllBoardDataEntityType) => void;
}

export const CompletedTasks = ({ closeDrawer, searchedDate, onClickCheckBox }: Props) => {
  const {
    completedVisibleData,
    nextMonth,
    previousMonth,
    startDate,
    loading,
    setStartDate,
    onPressCompletedTaskCheckbox,
    deleteTask
  } = useCompletedTasksHook({onClickCheckBox});

  useEffect(() => {
    setStartDate(searchedDate);
  }, [searchedDate]);

  return (
    <>
      <CustomTooltip
        description={i18n.t("completed_tasks_drawer_tooltip")}
        direction="top-end"
      >
        <div style={styles.secondDrawerHeaderContainer}>
          <Text style={styles.secondDrawerHeader}>
            {i18n.t("completed_tasks")}
          </Text>
          <div style={styles.headerIconContainer}>
            <Icon
              width={resizeByResolutionWidth(15)}
              height={resizeByResolutionWidth(30)}
              name={IconName.LEFT_DRAWER_BIG_BACK}
              onPress={closeDrawer}
            />
          </div>
        </div>
      </CustomTooltip>
      <div style={styles.monthContainer}>
        <Text style={styles.dateText}>{startDate.toFormat("LLLL yyyy")}</Text>
        <div style={styles.secondDrawerHeaderContainer}>
          <Icon
            name={IconName.CALENDAR_BACK}
            width={resizeByResolutionWidth(8)}
            height={resizeByResolutionWidth(13)}
            onPress={previousMonth}
          />
          <Icon
            name={IconName.CALENDAR_FORWARD}
            width={resizeByResolutionWidth(8)}
            height={resizeByResolutionWidth(13)}
            style={styles.forwardIcon}
            onPress={nextMonth}
          />
        </div>
      </div>
      <div style={styles.scrollContainer} className="Windows">
        {loading ? (
          <Spinner
            style={styles.spinner}
            size={50}
            color={"primary"}
            loading={loading}
          />
        ) : (
          completedVisibleData.map((item, index) => {
            return (
              <div style={styles.itemContainer} key={`${item}${index}`} id={item?.date?.toSQLDate()!}>
                <Text style={styles.dateText}>
                  {item.date.toFormat("EEEE, d")}
                </Text>
                {item.data.map((card, index) => {
                  return (
                    <TimelineListItem
                      boardId={card.id}
                      key={card.id}
                      index={index}
                      data={card}
                      isCompleted
                      isCard
                      onClickCheckBox={onPressCompletedTaskCheckbox}
                      deleteTask={deleteTask}
                    />
                  );
                })}
              </div>
            );
          })
        )}
      </div>
    </>
  );
};

const styles: Record<string, React.CSSProperties> = {
  secondDrawerHeaderContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  secondDrawerHeader: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 20,
    color: palette.black,
    textAlign: "left",
  },
  dateText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 18,
    color: palette.gray.dark,
    textAlign: "left",
    marginBottom: 5,
  },
  monthContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 35,
    marginBottom: 15,
  },
  forwardIcon: {
    marginLeft: 35,
  },
  spinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  scrollContainer: {
    overflowY: "scroll",
    overflow: "visible"
  },
  headerIconContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    flex: 1,
    justifyContent: "flex-end",
  },
  itemContainer: {
    paddingLeft: 2,
    paddingRight: 2,
  },
};
