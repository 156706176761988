import { FirebaseAPIError } from "communicators/services/firebase/errors/firebase.error";
import { FirebaseService } from "communicators/services/firebase/Firebase.service";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const useForgotPasswordHook = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const sendVerification = async () => {
    setLoading(true);
    setError("");
    try {
      await new FirebaseService().sendForgotPasswordEmail(email);
      navigate("/login");
    } catch (err) {
      if (err instanceof FirebaseAPIError) {
        setError(err.message);
      }
    }
    setLoading(false);
  };
  return { email, loading, error, setEmail, sendVerification };
};
