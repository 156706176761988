import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { Text } from "elements/text/Text";
import { palette } from "assets/palette";
import { IconButton } from "@mui/material";
import { resizeByResolutionWidth } from "helpers/utils.helper";

interface Props {
  hoveredDates?: DateTime[];
  onSelectDateCallback: (date: DateTime) => void;
  selectedDate?: DateTime;
}

export const Calendar = ({
  hoveredDates,
  selectedDate,
  onSelectDateCallback,
}: Props) => {
  const [value, setValue] = React.useState<DateTime | null>(
    selectedDate || DateTime.now()
  );
  const [currentMonth, setCurrentMonth] = useState<DateTime | null>(
    DateTime.now()
  );

  useEffect(() => {
    if (selectedDate) {
      setValue(selectedDate);
    }
  }, [selectedDate]);

  const isInThisMonth = (date: DateTime) => {
    if (!currentMonth || !date) {
      return false;
    }
    return date.month === currentMonth.month;
  };

  const isToday = (date: DateTime) => {
    const now = DateTime.now();
    return (
      now.day === date.day && date.month === now.month && now.year === date.year
    );
  };

  const onSelectDate = (date: DateTime | null) => {
    if (!date) return;
    const isDateInThisMonth = isInThisMonth(date);
    if (!isDateInThisMonth) return;
    setValue(date);
    onSelectDateCallback(date);
  };

  const isDayInHoveredDay = (day: DateTime) => {
    if (!hoveredDates) return -1;

    return hoveredDates.findIndex(
      (item) =>
        item.day === day.day &&
        item.month === day.month &&
        item.year === day.year
    );
  };

  const getDayWrapperStyle = (day: DateTime) => {
    const isHoveredDayIndex = isDayInHoveredDay(day);
    let dayWrapperStyle = styles.dayWrapper;
    if (
      hoveredDates &&
      isHoveredDayIndex > 0 &&
      isHoveredDayIndex < hoveredDates.length - 1
    ) {
      dayWrapperStyle = {
        ...styles.dayWrapper,
        backgroundColor: palette.blue.light,
      };
    } else if (hoveredDates && isHoveredDayIndex === 0) {
      dayWrapperStyle = {
        ...styles.dayWrapper,
        backgroundColor: palette.blue.light,
        borderTopLeftRadius: resizeByResolutionWidth(20),
        borderBottomLeftRadius: resizeByResolutionWidth(20),
      };
    } else if (hoveredDates && isHoveredDayIndex === hoveredDates.length - 1) {
      dayWrapperStyle = {
        ...styles.dayWrapper,
        backgroundColor: palette.blue.light,
        borderTopRightRadius: resizeByResolutionWidth(20),
        borderBottomRightRadius: resizeByResolutionWidth(20),
      };
    }
    return dayWrapperStyle;
  };

  const renderDay = (params: DateTime) => {
    const isDateInThisMonth = isInThisMonth(params);
    const isDateToday = isToday(params) && isDateInThisMonth;
    const isSelected = !value || params.equals(value);
    const isHoveredDayIndex = isDayInHoveredDay(params);
    const isHovered = isHoveredDayIndex !== -1;
    const dayWrapperStyle = getDayWrapperStyle(params);

    let textStyle = styles.notSelectedText;
    if (isDateToday) {
      textStyle = styles.todayText;
    }
    if (isSelected && !isHovered) {
      textStyle = styles.selectedText;
    }
    if (!isDateInThisMonth)
      return (
        <div style={dayWrapperStyle}>
          <div style={styles.iconButtonStyle} />
        </div>
      );

    return (
      <div style={dayWrapperStyle}>
        <IconButton
          style={
            isSelected && !isHovered
              ? styles.selectedDateStyle
              : styles.iconButtonStyle
          }
          onClick={() => onSelectDate(params)}
          id="calendar-day"
        >
          <Text style={textStyle}>{params.day}</Text>
        </IconButton>
      </div>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        openTo="day"
        value={value}
        onChange={onSelectDate}
        renderInput={(params) => <TextField size="small" {...params} />}
        onMonthChange={(date) => setCurrentMonth(date)}
        renderDay={renderDay}
      />
    </LocalizationProvider>
  );
};

const styles: Record<string, React.CSSProperties> = {
  todayText: {
    color: palette.blue.lighter,
    fontWeight: "600",
    fontSize: 20,
  },
  iconButtonStyle: {
    width: resizeByResolutionWidth(50),
    height: resizeByResolutionWidth(40),
    margin: resizeByResolutionWidth(4),
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  selectedDateStyle: {
    width: resizeByResolutionWidth(50),
    height: resizeByResolutionWidth(40),
    marginRight: resizeByResolutionWidth(4),
    marginLeft: resizeByResolutionWidth(4),
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    backgroundColor: palette.orange.dark,
  },
  dayWrapper: {
    width: resizeByResolutionWidth(50),
    height: resizeByResolutionWidth(40),
    marginBottom: resizeByResolutionWidth(4),
  },
  notSelectedText: {
    fontWeight: "600",
    color: palette.black,
    fontSize: 20,
  },
  selectedText: {
    fontWeight: "600",
    color: palette.white,
    fontSize: 20,
  },
};
