import * as React from "react";
import { palette } from "assets/palette";
import { i18n } from "constants/i18n.constants";
import { Text } from "elements/text/Text";
import { styled } from "@mui/material/styles";
import { LeftSettingsBar } from "pages/private/home/components/LeftSettignsBar/LeftSettingsBar";
import { DRAWER_WIDTH, LEFT_BAR_WIDTH } from "pages/private/home/constants";
import { ProgressBar } from "elements/progress-bar/ProgressBar";
import { CustomButton } from "elements/button/Button";
import { useDndTutorial } from "./useDndTutorial";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { DateTime } from "luxon";
import "./animation.css";
import { EventType } from "communicators/resources/event-resources/event-resources.type";
import { usePageHook } from "hooks/usePageHook";

export const DndTutorial = () => {
  const {
    openSecondDrawer,
    secondDrawerOpened,
    progressValue,
    goBack,
    dailyTimes,
    exampleTaskItem,
    newTaskCard,
    newTaskCardShown,
    createdTaskShownOnCalendar,
    todaysTasks,
    createdTask,
    tomorrowsTasks,
    otherTasks,
  } = useDndTutorial();
  usePageHook();
  return (
    <div style={styles.mainContainer}>
      <div style={styles.backdrop} />
      <div style={styles.container}>
        <div style={styles.wrapper}>
          <LeftSettingsBar
            openSecondDrawer={openSecondDrawer}
            secondDrawerOpenStatus={secondDrawerOpened}
          />
          <div>
            <div style={styles.headerButtonStyle}>
              <Text style={styles.headerTextStyle}>{i18n.t("app_name")}</Text>
            </div>
            <div style={styles.planTodayContainer}>
              <Icon
                name={IconName.BACK_ARROW}
                onPress={goBack}
                style={styles.backButton}
              />
              <Text style={styles.header}>{i18n.t("plan_today")}</Text>
              <Text style={styles.subheader}>{i18n.t("drag_and_drop_task_to_finish_today")}</Text>
              <div style={styles.todayContainer}>
                <Text style={styles.today}>{i18n.t("today")}</Text>
                <Icon
                  name={IconName.PLUS}
                  fill={palette.white}
                  style={styles.plusIcon}
                />
              </div>
            </div>
            {!newTaskCardShown ? (
              <div id="new-task-card" className="task">
                <button style={styles.emptyCheckbox} />
                <Text style={styles.task}>{createdTask}</Text>
                <Icon name={IconName.TIME} />
                <div style={styles.iconGap} />
                <Icon name={IconName.CALENDAR} />
              </div>
            ) : null}
            <div
              style={
                newTaskCardShown
                  ? styles.itemsContainer
                  : styles.itemsContainerSmaller
              }
              className="Windows"
            >
              {todaysTasks?.data.map((item) => {
                if (item.type !== EventType.TASK) return <></>;
                return (
                  <div
                    style={styles.todaysTaskCard}
                    className="itemContainer"
                    key={item.id}
                  >
                    <button style={styles.emptyCheckbox}></button>
                    <Text style={styles.task}>{item.attributes.title}</Text>
                    <div style={styles.iconContainer}>
                      <Icon name={IconName.TIME} />
                      <div style={styles.iconGap} />
                      <Icon name={IconName.CALENDAR} />
                    </div>
                  </div>
                );
              })}
            </div>
            <div style={styles.progressContainer}>
              <Text style={styles.dailyPlanningProcess}>
                {i18n.t("daily_planning_process")}
              </Text>
              <ProgressBar
                style={styles.progress}
                value={progressValue}
                progressProps={{ color: "info" }}
              />
              <CustomButton
                style={styles.buttonStyle}
                text={i18n.t("go_to_dashboard")}
              />
            </div>
          </div>
        </div>
      </div>
      <MiddleContainer marginTop={179}>
        <Text style={styles.dragDropTasks}>
          {i18n.t("drag_and_drop_tasks")}
        </Text>
        <div style={styles.rowContainer}>
          <div style={styles.topMargin}>
            <div style={styles.rowContainer}>
              <Text style={styles.tomorrow}>{i18n.t("tomorrow")}</Text>
              <Icon
                name={IconName.PLUS}
                fill={palette.white}
                style={styles.plusIcon}
              />
            </div>
            {tomorrowsTasks?.data.map((item) => {
              if (item.type !== EventType.TASK) return <></>;
              return (
                <div
                  style={styles.tomorrowsTaskCard}
                  className="itemContainer"
                  key={item.id}
                >
                  <button style={styles.emptyCheckbox}></button>
                  <Text style={styles.task}>{item.attributes.title}</Text>
                  <div style={styles.iconContainer}>
                    <Icon name={IconName.TIME} />
                    <div style={styles.iconGap} />
                    <Icon name={IconName.CALENDAR} />
                  </div>
                </div>
              );
            })}
          </div>
          <div style={styles.gap} />
          <div style={styles.topMargin}>
            <div style={styles.rowContainer}>
              <Text style={styles.someday}>{i18n.t("someday")}</Text>
              <Icon
                name={IconName.PLUS}
                fill={palette.white}
                style={styles.plusIcon}
              />
            </div>
            {otherTasks?.data.map((item) => {
              if (item.type !== EventType.TASK) return <></>;
              return (
                <div style={styles.tomorrowsTaskCard} key={item.id}>
                  <button style={styles.emptyCheckbox}></button>
                  <Text style={styles.task}>{item.attributes.title}</Text>
                  <div style={styles.iconContainer}>
                    <Icon name={IconName.TIME} />
                    <div style={styles.iconGap} />
                    <Icon name={IconName.CALENDAR} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </MiddleContainer>
      <div style={styles.schedule} className="Windows">
        <div style={styles.wrapper}>
          <div>
            <div style={styles.rightTextContainer}>
              <div style={styles.scheduleContainer}>
                <div style={styles.suitcaseContainer}>
                  <Icon name={IconName.SCHEDULE_SUITCASE} />
                </div>
                <Text style={styles.dailySchedule}>
                  {i18n.t("daily_schedule")}
                </Text>
              </div>
              <Text style={styles.scheduleToday}>{i18n.t("today")}</Text>
              <div style={styles.todaysDateContainer}>
                <Text style={styles.todaysDate}>
                  {DateTime.now().toFormat("MMMM dd")}
                </Text>
                <Icon
                  name={IconName.CALENDAR_BACK}
                  style={styles.calendarBackIcon}
                />
                <Icon
                  name={IconName.CALENDAR_FORWARD}
                  style={styles.calendarForwardIcon}
                />
              </div>
              <ExampleTask top={240} left={74}>
                <Text style={styles.exampleTaskText}>
                  {i18n.t("example_task")}
                </Text>
              </ExampleTask>
              {createdTaskShownOnCalendar ? (
                <CreatedTask
                  top={newTaskCard?.offsetTop}
                  left={exampleTaskItem?.offsetLeft}
                >
                  <Text style={styles.createdTaskText}>{createdTask}</Text>
                </CreatedTask>
              ) : null}
              {dailyTimes.map((item, index) => {
                return (
                  <div style={styles.todaysDateContainer} key={index}>
                    <Text style={styles.scheduleTime}>{item}</Text>
                    <div style={styles.line} id={index.toString()} />
                    {index === 3 && !createdTaskShownOnCalendar ? (
                      <CalendarTimeBackground
                        top={newTaskCard?.offsetTop}
                        left={74}
                        className="taskBackground"
                      />
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MiddleContainer = styled("div")<{
  marginTop?: number;
}>(({ marginTop }) => ({
  marginTop: marginTop,
  marginLeft: 50,
}));

const CalendarTimeBackground = styled("div")<{
  left?: number;
  top?: number;
}>(({ left, top }) => ({
  left: left,
  height: 30,
  width: "100%",
  borderRadius: 3,
  backgroundColor: palette.orange.light,
  position: "absolute",
  top: top,
}));

const ExampleTask = styled("div")<{
  top?: number;
  left?: number;
}>(({ top, left }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  height: 25,
  width: 150,
  borderRadius: 3,
  paddingLeft: 10,
  backgroundColor: palette.green.light,
  position: "absolute",
  top: top,
  left: left,
}));

const CreatedTask = styled("div")<{
  top?: number;
  left?: number;
}>(({ top, left }) => ({
  height: 25,
  width: 150,
  borderRadius: 3,
  paddingLeft: 10,
  backgroundColor: palette.orange.light,
  position: "absolute",
  top: top,
  left: left,
}));

const styles: Record<string, React.CSSProperties> = {
  container: {
    width: 390 + LEFT_BAR_WIDTH,
    height: "100vh",
    boxShadow: "0px 4px 4px 0px #00000040",
    position: "relative",
  },
  backdrop: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#00000080",
    zIndex: 11,
  },
  mainContainer: {
    display: "flex",
    flexDirection: "row",
  },
  progressContainer: {
    position: "absolute",
    bottom: 70,
  },
  headerButtonStyle: {
    display: "flex",
    flex: 1,
    paddingLeft: 20,
    alignItems: "center",
    borderWidth: 0,
    marginTop: 15,
  },
  headerTextStyle: {
    fontWeight: "500",
    fontSize: 22,
    marginRight: 5,
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    height: "100%",
  },
  planTodayContainer: {
    marginLeft: 40,
    marginRight: 40,
    marginTop: 60,
  },
  header: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 30,
    color: palette.black,
    textAlign: "left",
    marginTop: 25,
  },
  subheader: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 22,
    color: palette.gray.dark,
    textAlign: "left",
  },
  backButton: {
    marginLeft: -12,
    cursor: "pointer",
  },
  todayContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 50,
  },
  today: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 25,
    color: palette.black,
    marginRight: 20,
  },
  plusIcon: {
    marginTop: 4,
  },
  progress: {
    marginTop: 5,
    marginBottom: 50,
    marginLeft: 40,
    width: DRAWER_WIDTH - 80,
  },
  dailyPlanningProcess: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 10,
    color: palette.gray.medium,
    marginLeft: 40,
  },
  buttonStyle: {
    marginLeft: 40,
    width: 200,
    backgroundColor: palette.black,
    height: 50,
  },
  checkbox: {
    width: 26,
    height: 26,
    borderRadius: 13,
    backgroundColor: palette.gray.ice,
    marginRight: 10,
    borderWidth: 0,
  },
  task: {
    marginLeft: 15,
  },
  iconGap: {
    width: 20,
  },
  dragDropTasks: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 30,
    color: palette.black,
  },
  dragDropTasksSubheader: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 22,
    color: palette.gray.dark,
  },
  tomorrow: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 25,
    color: palette.black,
    marginRight: 20,
  },
  someday: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 25,
    color: palette.black,
  },
  schedule: {
    width: 390,
    marginLeft: "auto",
    height: "100vh",
    backgroundColor: palette.white,
    boxShadow: "0px 4px 4px 0px #00000040",
    zIndex: 12,
    overflow: "scroll",
    position: "absolute",
    right: 0,
  },
  suitcaseContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 41,
    height: 41,
    borderRadius: 10,
    boxShadow: "0px 4px 3px 0px #0000001A",
  },
  dailySchedule: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 22,
    color: palette.black,
    marginLeft: 15,
  },
  scheduleContainer: {
    display: "flex",
    flex: 1,
    backgroundColor: palette.white,
    alignItems: "center",
    borderWidth: 0,
    marginTop: 50,
  },
  rightTextContainer: {
    paddingLeft: 30,
    position: "relative",
  },
  scheduleToday: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 25,
    color: palette.black,
    marginTop: 15,
  },
  todaysDate: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 20,
    color: palette.gray.dark,
  },
  todaysDateContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 30,
  },
  calendarForwardIcon: {
    marginLeft: 35,
  },
  calendarBackIcon: {
    marginLeft: 20,
  },
  scheduleTime: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 12,
    color: palette.gray.medium,
  },
  line: {
    height: 1,
    width: "100vh",
    marginLeft: 17,
    backgroundColor: `${palette.purple.light}70`,
  },
  exampleTask: {
    height: 25,
    width: 150,
    borderRadius: 3,
    display: "flex",
    alignItems: "center",
    paddingLeft: 10,
    backgroundColor: palette.green.light,
  },
  exampleTaskText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 15,
    color: palette.green.dark,
  },
  createdTaskText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 15,
    color: palette.white,
  },
  todaysTaskCard: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: palette.white,
    borderRadius: 12,
    marginLeft: 40,
    marginRight: 40,
    marginTop: 25,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 15,
    paddingBottom: 35,
  },
  tomorrowsTaskCard: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: palette.white,
    borderRadius: 12,
    marginTop: 25,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 15,
    paddingBottom: 35,
  },
  emptyCheckbox: {
    width: 26,
    height: 26,
    borderRadius: 13,
    color: palette.gray.ice,
    marginRight: 10,
    borderWidth: 0,
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
  },
  gap: {
    width: 50,
  },
  topMargin: {
    marginTop: 50,
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: 'center',
    marginTop: 26
  },
  itemsContainer: {
    maxHeight: "calc(40% - 110px)",
    overflow: "scroll",
  },
  itemsContainerSmaller: {
    maxHeight: "calc(40% - 210px)",
    overflow: "scroll",
  },
};
