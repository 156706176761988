import { createTheme, ThemeProvider } from "@mui/material";
import { palette } from "assets/palette";
import React from "react";
import type {} from "@mui/x-date-pickers/themeAugmentation";

interface Props {
  children?: React.ReactNode;
}

const theme = createTheme({
  palette: {
    primary: {
      main: palette.orange.medium,
      dark: palette.orange.dark,
      light: palette.orange.light,
    },
    secondary: {
      main: palette.cream.cream,
      light: palette.cream.cream,
      dark: palette.cream.dark,
    },
    background: {
      default: palette.white,
      paper: palette.white,
    },
    warning: {
      light: palette.gray.iceLight,
      main: palette.gray.medium,
      dark: palette.gray.dark,
    },
    info: {
      light: palette.blue.lighter,
      main: palette.blue.lighter,
      dark: palette.blue.dark,
    },
  },
  typography: {
    fontFamily: "Gilroy",
  },
  components: {
    MuiDatePicker: {
      styleOverrides: {
        root: {
          backgroundColor: "red",
          fontFamily: "Gilroy",
          fontSize: "bold",
        },
      },
    },
  },
});

export const CustomThemeProvider = ({ children }: Props) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
