import axios from "axios";
import { UserOwnedResourceManagementService } from "communicators/services/UserOwnedResourceManagementService";
import { store } from "redux-store/store";
import { GoogleThirdPartyResource } from "./google-third-party.resource";
import {
  IThirdPartyInformationResource,
  Scope,
} from "./third-party-information.type";

export class ThirdPartyInformationResource extends UserOwnedResourceManagementService<IThirdPartyInformationResource> {
  constructor() {
    super("THIRD_PARTY_INFORMATION");
  }

  createGoogleProvider = async (server_auth_code: string) =>
    await new GoogleThirdPartyResource().createProvider(server_auth_code);

  createTodoistProvider = async (access_token: string) =>
    this.createOne({
      third_party_information: {
        provider: "todoist",
        access_token,
        scopes: [Scope.READ, Scope.WRITE, Scope.DELETE],
      },
    });

  createAsanaProvider = async (code: string, redirectUri: string) => {
    const firebaseUuid = store.getState().userSetting.firebaseUuid;
    if (!firebaseUuid) return;
    await axios.get(
      `${process.env.REACT_APP_INSUMO_URL}api/v1/public/integrations/asana/register?code=${code}&state=${firebaseUuid}&redirect_uri=${redirectUri}`
    );
    return true;
  };
}
