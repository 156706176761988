import { useGoogleSignInHook } from "hooks/useGoogleSignInHook";
import { DateTime } from "luxon";
import { useEffect, useRef, useState } from "react";
import { setOpenedMeetDetail } from "redux-store/actions/home-page.actions";
import { setCalendarData } from "redux-store/actions/user-settings.action";
import { useAppDispatch, useAppSelector } from "redux-store/store.hooks";
import { CalendarAccountType } from "../../useHomeHook";
import { AccessRole } from "./components/CalendarSelection/useCalendarSelectionHook";
import { i18n } from "constants/i18n.constants";
import { useUserSettingHook } from "hooks/useUserSettingHook";
import { useOutsideClick } from "helpers/common.helper";
import { IBoard } from "interfaces/main.interfaces";
import { isItemMagicPlanner } from "helpers/utils.helper";

type Props = {
  refreshBoards: (prefferedCalendar: CalendarAccountType) => void;
  rightDrawerStartDate: DateTime;
  rightDrawerData: IBoard[];
  magicPlannerState?: boolean;
  isMagicPlannerSwitchShown?: boolean;
};

export const useDrawerRightHook = ({
  refreshBoards,
  rightDrawerStartDate,
  rightDrawerData,
  magicPlannerState,
  isMagicPlannerSwitchShown,
}: Props) => {
  const { preferences } = useUserSettingHook();
  const { googleId, calendars } = useAppSelector((state) => ({
    googleId: state.thirdPartyProviderData.googleId,
    calendars: state.userSetting.calendarData,
  }));
  const [isCalendarSelectionOpen, setIsCalendarSelectionOpen] = useState(false);
  const [loadingCalendarPicking, setLoadingCalendarPicking] = useState(false);

  const dispatch = useAppDispatch();
  const ref = useRef<any>();
  const iconRef = useRef<any>();
  const { setAndGetUserPreferedCalendar } = useUserSettingHook();

  const toggleCalendarSelection = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setIsCalendarSelectionOpen(!isCalendarSelectionOpen);
  };
  const handleClose = () => {
    setIsCalendarSelectionOpen(false);
  };

  const onPressInsumo = async () => {
    await setAndGetUserPreferedCalendar(CalendarAccountType.INSUMO);
    refreshBoards(CalendarAccountType.INSUMO);
  };

  const onSuccessGoogleAuth = async () => {
    setLoadingCalendarPicking(true);
    await setAndGetUserPreferedCalendar(CalendarAccountType.GOOGLE);
    setLoadingCalendarPicking(false);
    refreshBoards(CalendarAccountType.GOOGLE);
  };

  const { login, googleAuthLoading } = useGoogleSignInHook({
    onSuccess: onSuccessGoogleAuth,
  });

  const onPressGoogle = async () => {
    if (googleId || googleId !== "") {
      await setAndGetUserPreferedCalendar(CalendarAccountType.GOOGLE);
      refreshBoards(CalendarAccountType.GOOGLE);
    } else {
      login();
    }
  };

  const initialiseSelectedCalendar = () => {
    if (
      preferences &&
      (preferences.attributes.preferred_calendar ===
        CalendarAccountType.GOOGLE ||
        preferences.attributes.preferred_calendar ===
          CalendarAccountType.INSUMO)
    ) {
      if (
        preferences.attributes.preferred_calendar ===
          CalendarAccountType.GOOGLE &&
        (!googleId || googleId === "")
      ) {
        onPressInsumo();
      }
      return;
    }
    if (googleId) {
      onPressGoogle();
    } else {
      onPressInsumo();
    }
  };

  const dateDisplay = () => {
    if (
      rightDrawerStartDate.toFormat("MMMM dd") ===
      DateTime.now().toFormat("MMMM dd")
    ) {
      return i18n.t("today");
    } else if (
      rightDrawerStartDate.toFormat("MMMM dd") ===
      DateTime.now().plus({ day: 1 }).toFormat("MMMM dd")
    ) {
      return i18n.t("tomorrow");
    } else return rightDrawerStartDate.toFormat("MMMM dd");
  };

  const calendarIDs = calendars.filter(
    (item) => item.access_role === AccessRole.OWNER
  );

  useEffect(() => {
    dispatch(setCalendarData());
    initialiseSelectedCalendar();
  }, [dispatch]);

  useEffect(() => {
    document.addEventListener("keydown", (event) => {
      if (event.key === "Escape") {
        dispatch(setOpenedMeetDetail(null));
        handleClose();
      }
    });
    return () => {
      document.removeEventListener("keydown", () => {});
    };
  }, [dispatch]);

  useOutsideClick(ref, iconRef, handleClose);

  const googleLoading = googleAuthLoading || loadingCalendarPicking;
  return {
    toggleCalendarSelection,
    isCalendarSelectionOpen,
    ref,
    iconRef,
    onPressInsumo,
    onPressGoogle,
    calendarIDs,
    dateDisplay,
    googleLoading,
  };
};
