import * as React from "react";
import { i18n } from "constants/i18n.constants";
import { Text } from "elements/text/Text";
import { useAsanaHook } from "./useAsanaHook";
import { AsanaImage } from "assets/images";
import { IOSSwitch } from "elements/switch/Switch";
import { palette } from "assets/palette";
import { Icon } from "elements/icon/Icon";
import { resizeByResolutionWidth } from "helpers/utils.helper";
import { IconName } from "elements/icon/Icon.type";

interface Props {
  closeDrawer: () => void;
}

export const Asana = ({
  closeDrawer
}: Props) => {
  const {asanaState, toggleSwitch} = useAsanaHook();
  return (
    <>
        <div style={styles.secondDrawerHeaderContainer}>
            <Text style={styles.secondDrawerHeader}>{i18n.t("asana")}</Text>
            <Icon
                width={resizeByResolutionWidth(15)}
                height={resizeByResolutionWidth(30)}
                name={IconName.LEFT_DRAWER_BIG_BACK}
                onPress={closeDrawer}
            />
        </div>
        <div style={styles.integrationItem} className="item">
            <img style={styles.image} src={AsanaImage} alt={i18n.t("asana")} />
            <div style={styles.integrationItemWrapper}>
                <Text style={styles.integrationItemText}>{i18n.t("asana")}</Text>
            </div>
            <IOSSwitch
                onChange={toggleSwitch}
                checked={asanaState}
            />
        </div>
    </>
  );
};

const styles: Record<string, React.CSSProperties> = {
    secondDrawerHeaderContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
    },
    secondDrawerHeader: {
        fontFamily: "Gilroy-Medium",
        fontWeight: "500",
        fontSize: 20,
        color: palette.black,
        textAlign: "left",
    },
    integrationItem: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: palette.white,
        borderRadius: 12,
        padding: 20,
        marginTop: 35
    },
    image: {
        width: 18,
        height: 16.5,
    },
    integrationItemText: {
        fontFamily: "Gilroy-Medium",
        fontWeight: "500",
        fontSize: 18,
        color: palette.gray.dark,
    },
    integrationItemWrapper: {
        display: "flex",
        alignSelf: "stretch",
        flex: 1,
        flexDirection: "column",
        marginLeft: 20,
        alignItems: "flex-start",
    },
};
