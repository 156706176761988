import { resizeByResolutionWidth } from "helpers/utils.helper";

export const DRAWER_WIDTH = resizeByResolutionWidth(385);

export enum HeaderButtonType {
  ALL = "all",
  TASKS = "tasks",
  MEETS = "meets",
}

export const LEFT_BAR_WIDTH = resizeByResolutionWidth(90);

export const streaksJokerImage = `https://insumo-achievement-background-assets.s3.amazonaws.com/Joker.png`;

export const iosAppLink = "https://apps.apple.com/us/app/insumo-habit-tracker-remind/id1559902544";
export const androidAppLink = "https://play.google.com/store/apps/details?id=com.insumoandroid&gl=TR";