import { DNDDispatchTypes } from "redux-store/types/dispatch.type";
import { AllDNDActionTypes } from "redux-store/types/dnd.action.type";
import { DNDState } from "redux-store/types/state.type";

const INITIAL_STATE: DNDState = {
  dragingEvent: null,
};

export const dndReducer = (
  state = INITIAL_STATE,
  action: AllDNDActionTypes
) => {
  switch (action.type) {
    case DNDDispatchTypes.setDragingEvent:
      return {
        ...state,
        dragingEvent: action.payload,
      };
    default:
      return state;
  }
};
