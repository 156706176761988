import { AxiosRequestConfig } from "axios";
import { IAPIResource } from "communicators/resources/type";

export enum Endpoints {
  DEFAULT = "",
  LOGIN = "oauth/token",
  AUTH = "oauth/token",
  USER = "api/v1/public/users",
  LIBRARY = "api/v1/public/libraries",
  HABITS = "api/v1/public/events/habits",
  HABIT_TARGETS = "api/v1/public/events/habit_targets",
  HABIT_GOAL = "api/v1/public/events/habit_goals/create_or_destroy",
  ONBOARDING_QUESTIONS = "api/v1/public/onboarding_questions",
  MEETS = "api/v1/public/events/meets",
  TASKS = "api/v1/public/events/tasks",
  ONBOARDING_ANSWER = "api/v1/public/onboarding_answers",
  THIRD_PARTY_INFORMATION = "api/v1/public/third_party_informations",
  GOOGLE_THIRD_PARTY_PROVIDER = "api/v1/public/integrations/google_calendar/register",
  CALENDAR = "api/v1/public/calendars",
  CHALLENGE = "api/v1/public/challenges",
  TASK_GOAL = "api/v1/public/events/task_goals/create_or_destroy",
  CHALLENGE_SUBSCRIPTION = "api/v1/public/challenge_subscriptions",
  AUTHV2 = "api/v1/auth/login_with_firebase",
  USER_PREFERENCES = "api/v1/public/user_preferences",
  SUBSCRIPTIONS = "api/v1/public/subscriptions",
  SEARCH = "api/v1/public/events/search",
  INVOICES = "api/v1/public/invoices",
  PAYMENT_METHOD = "api/v1/public/payment_methods",
  INSUMO_CALENDAR_EVENTS = "api/v1/public/insumo_calendar_events",
  SUBSCRIPTION_PLANS = "api/v1/public/subscription_plans",
  CALENDAR_REVOKE = "api/v1/public/integrations/google_calendar/revoke",
  ZERO_TASK = "api/v1/public/zero_day_streaks",
  COUPONS = "api/v1/public/coupons/use",
  TAGS = "api/v1/public/tags",
  TASK_TAGS = "api/v1/public/task_tags",
  MAGIC_PLANNER = "api/v1/public/assistant",
}

export type ResourceName = keyof typeof Endpoints;

export enum ApiMethods {
  GET = "get",
  POST = "post",
  PUT = "put",
  PATCH = "patch",
  DELETE = "delete",
}

export enum CommunicatorMethods {
  READ_ONE = "readOne",
  READ_MANY = "readMany",
  CREATE_ONE = "createOne",
  UPDATE_ONE = "updateOne",
  REMOVE_ONE = "removeOne",
}

export type CommunicatorMethod<R, D> = (
  ...args: [url: string, data?: D, config?: AxiosRequestConfig<D>]
) => Promise<R>;

export interface ResponseWithData<T> {
  meta?: Record<string, any>;
  data: T;
}

export interface HasQueryMethods<T extends IAPIResource> {
  readOne: (id: string, query?: T["query"]) => Promise<T["entity"]>;
  readMany: (query?: T["query"]) => Promise<T["entities"]>;
  createOne: (body: T["candidate"], query: T["query"]) => Promise<T["entity"]>;
  updateOne: (
    id: string,
    body: Partial<T["candidate"]>
  ) => Promise<T["entity"]>;
  removeOne: (id: string) => Promise<null>;
}
