import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { AppBar } from "../AppBar/AppBar";
import { DrawerLeft } from "../DrawerLeft/DrawerLeft";
import {
  DRAWER_WIDTH,
  HeaderButtonType,
  LEFT_BAR_WIDTH,
} from "../../constants";
import { DrawerHeader } from "../DrawerHeader/DrawerHeader";
import { DrawerRight } from "../DrawerRight/DrawerRight";
import { palette } from "assets/palette";
import { useHomeWrapperHook } from "./useHomeWrapperHook";
import { PreferencesDialog } from "../PreferencesDialog/PreferencesDialog";
import { AllBoardDataEntityType, IBoard } from "interfaces/main.interfaces";
import { DateTime } from "luxon";
import { TaskEntity } from "communicators/resources/event-resources/tasks/tasks.type";
import { IEvent, ITimelineItem } from "../DrawerRight/types";
import { useEffect } from "react";
import { IInsumoCalendar } from "helpers/data.helper";
import { CalendarAccountType } from "../../useHomeHook";

interface Props {
  children?: React.ReactNode;
  onTabChanged: (boardType: HeaderButtonType) => void;
  rightDrawerBoardData: IBoard[];
  boardData: IBoard[];
  onSelectDate: (date: DateTime) => void;
  refreshBoards: (prefferedCalendar?: CalendarAccountType) => void;
  onPressBoardPlus: (date: DateTime) => void;
  onTaskCreate: (
    index: number,
    boardDate: DateTime,
    createdTask: TaskEntity,
    isNewTask: boolean
  ) => void;
  onTaskCreateSomeday: (
    index: number,
    boardDate: DateTime,
    createdTask: TaskEntity,
    isNewTask: boolean
  ) => void;
  inboxVisibleData: IBoard[];
  rightDrawerStartDate: DateTime;
  onPressNextDay: () => void;
  onPressPreviousDay: () => void;
  updateTaskWithRightDrawerDrop: (
    newDroppedTask: TaskEntity,
    timeItem: ITimelineItem
  ) => void;
  onPressSearchItem: (data: AllBoardDataEntityType) => void;
  leftDrawerSearchedDate: DateTime;
  handleOpenUnsubscriptionDialog: () => void;
  insumoCalendarEventData: IInsumoCalendar[];
  isLoading?: boolean;
  googleSignIn: any;
  googleAuthLoading: boolean;
  previousMonth: () => void;
  nextMonth: () => void;
  inboxStartDate: DateTime;
  inboxLoading: boolean;
  onClickCheckBox: (data: AllBoardDataEntityType) => void;
  deleteTask?: (data: AllBoardDataEntityType) => void;
  somedayLoading: boolean;
  somedayData: IBoard[];
  onHoverRightDrawer: (data: AllBoardDataEntityType, time: DateTime) => void;
  onPressSomedayPlus: () => void;
  deleteSomedayTask: (data: AllBoardDataEntityType) => void;
  onClickCheckBoxSomeday: (data: AllBoardDataEntityType) => void;
  isTransparent?: boolean;
  toggleMagicPlanner: () => void;
  magicPlannerState: boolean;
  isMagicPlannerSwitchShown?: boolean;
  onClickToday?: () => void;
  refreshAllDataWithoutSpinner: () => void
}

export const HomeWrapper = ({
  children,
  onTabChanged,
  onSelectDate,
  refreshBoards,
  boardData,
  onPressBoardPlus,
  onTaskCreate,
  inboxVisibleData,
  rightDrawerBoardData,
  rightDrawerStartDate,
  onPressNextDay,
  onPressPreviousDay,
  updateTaskWithRightDrawerDrop,
  onPressSearchItem,
  leftDrawerSearchedDate,
  handleOpenUnsubscriptionDialog,
  insumoCalendarEventData,
  isLoading,
  googleSignIn,
  googleAuthLoading,
  previousMonth,
  nextMonth,
  inboxStartDate,
  inboxLoading,
  onClickCheckBox,
  deleteTask,
  somedayData,
  somedayLoading,
  onHoverRightDrawer,
  onPressSomedayPlus,
  onTaskCreateSomeday,
  deleteSomedayTask,
  onClickCheckBoxSomeday,
  isTransparent,
  toggleMagicPlanner,
  magicPlannerState,
  isMagicPlannerSwitchShown,
  onClickToday,
  refreshAllDataWithoutSpinner
}: Props) => {
  const {
    selectedHeaderButton,
    leftDrawerOpen,
    rightDrawerOpen,
    onPressLeftDrawerIcon,
    onPressRightDrawerIcon,
    setSelectedHeaderButton,
    calendars,
    openSearchDialog,
    closeSearchDialog,
    isSearchDialogOpen,
    changeCalendarVisibility,
    isCalendarsLoading
  } = useHomeWrapperHook({ onTabChanged, refreshAllDataWithoutSpinner });
  useEffect(() => {
    closeSearchDialog();
  }, [onPressSearchItem]);

  return (
    <div id="transparent-background-image" style={mainStyles.container}>
      <Box sx={styles.sxBox}>
        <CssBaseline />
        <AppBar
          onPressRightDrawerIcon={onPressRightDrawerIcon}
          onPressLeftDrawerIcon={onPressLeftDrawerIcon}
          leftDrawerOpen={leftDrawerOpen}
          rightDrawerOpen={rightDrawerOpen}
          selectedButton={selectedHeaderButton}
          setSelectedButton={setSelectedHeaderButton}
          isTransparent={isTransparent}
        />
        <DrawerLeft
          onSelectDate={onSelectDate}
          boardData={boardData}
          open={leftDrawerOpen}
          calendars={calendars}
          isSearchDialogOpen={isSearchDialogOpen}
          openSearchDialog={openSearchDialog}
          closeSearchDialog={closeSearchDialog}
          onPressBoardPlus={onPressBoardPlus}
          onTaskCreate={onTaskCreate}
          inboxVisibleData={inboxVisibleData}
          onPressSearchItem={onPressSearchItem}
          leftDrawerSearchedDate={leftDrawerSearchedDate}
          previousMonth={previousMonth}
          nextMonth={nextMonth}
          inboxStartDate={inboxStartDate}
          inboxLoading={inboxLoading}
          onClickCheckBox={onClickCheckBox}
          deleteTask={deleteTask}
          somedayData={somedayData}
          somedayLoading={somedayLoading}
          onPressSomedayPlus={onPressSomedayPlus}
          onTaskCreateSomeday={onTaskCreateSomeday}
          deleteSomedayTask={deleteSomedayTask}
          onClickCheckBoxSomeday={onClickCheckBoxSomeday}
          changeCalendarVisibility={changeCalendarVisibility}
          isCalendarsLoading={isCalendarsLoading}
        />
        <Main
          leftOpen={leftDrawerOpen}
          rightOpen={rightDrawerOpen}
          isHome
          isTransparent={isTransparent}
        >
          <DrawerHeader />
          {children}
        </Main>
        <DrawerRight
          refreshBoards={refreshBoards}
          isLoading={isLoading}
          rightDrawerData={rightDrawerBoardData}
          open={rightDrawerOpen}
          rightDrawerStartDate={rightDrawerStartDate}
          onPressNextDay={onPressNextDay}
          onPressPreviousDay={onPressPreviousDay}
          updateTaskWithRightDrawerDrop={updateTaskWithRightDrawerDrop}
          insumoCalendarEventData={insumoCalendarEventData}
          onHoverRightDrawer={onHoverRightDrawer}
          toggleMagicPlanner={toggleMagicPlanner}
          magicPlannerState={magicPlannerState}
          isMagicPlannerSwitchShown={isMagicPlannerSwitchShown}
          onClickToday={onClickToday}
        />
        <PreferencesDialog
          refreshBoards={refreshBoards}
          handleOpenUnsubscriptionDialog={handleOpenUnsubscriptionDialog}
          googleSignIn={googleSignIn}
          googleAuthLoading={googleAuthLoading}
        />
      </Box>
    </div>
  );
};

export const Main = styled("div")<{
  leftOpen?: boolean;
  rightOpen?: boolean;
  isHome?: boolean;
  isTransparent?: boolean;
}>(({ theme, leftOpen, rightOpen, isHome, isTransparent }) => ({
  flexGrow: 1,
  height: "100%",
  backgroundColor: isHome ? palette.gray.light : palette.white,
  background: isTransparent ? "transparent" : undefined,
  padding: theme.spacing(3),
  transition: theme.transitions.create(["margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${DRAWER_WIDTH + LEFT_BAR_WIDTH}px`,
  ...(leftOpen && {
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: `${0}px`,
  }),
  marginRight: `-${DRAWER_WIDTH * 2}px`,
  ...(rightOpen && {
    marginRight: `${0}px`,
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const styles = {
  sxBox: { display: "flex" },
};

const mainStyles: Record<string, React.CSSProperties> = {
  container: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
  },
};
