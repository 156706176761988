import { DRAWER_WIDTH } from "../../constants";
import { CircularProgress } from "@mui/material";
import "./PlannerLoader.scss";
import { palette } from "assets/palette";
import { i18n } from "constants/i18n.constants";

export const PlannerLoader = () => {
  return (
    <div className="planner-loader-container" style={{ width: DRAWER_WIDTH }}>
      <div className="planner-loader-top-container">
        <div className="planner-loader-top-title">
          {i18n.t("magic_planner")}
        </div>
        <div>&nbsp;</div>
        {i18n.t("we_reorganize_your_schedule")}
      </div>
      <div className="planner-loader-inside-circle">
        <CircularProgress
          size={188}
          thickness={1.5}
          sx={{ color: palette.white }}
        />
      </div>
      <div />
    </div>
  );
};
