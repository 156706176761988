import { PaymentMethodResource } from 'communicators/resources/payment-method/payment-method.resource';
import { PaymentMethodEntity } from "communicators/resources/payment-method/payment-method.type";
import { SubscriptionsResource } from "communicators/resources/subscription/subscription.resource";
import { i18n } from "constants/i18n.constants";
import { DateTime } from 'luxon';
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { setPreferencesDialog, setPreferencesTab } from 'redux-store/actions/home-page.actions';
import { setSubscriptions } from "redux-store/actions/user-settings.action";
import { useAppDispatch, useAppSelector } from "redux-store/store.hooks";
import { PreferenceTab } from '../PreferencesDialog/PreferenceDialog.types';
import { PreferenceSubscriptionProps } from './PreferencesSubscription';

export const useSubscriptionHook = ({ handleOpenUnsubscriptionDialog }: PreferenceSubscriptionProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [deleteSubsLoading, setDeleteSubsLoading] = useState(false);
  const [isUnsubscribeOpen, setIsUnsubscribeOpen] = useState(false);
  const { subscriptions } = useAppSelector((state) => ({
    subscriptions: state.userSetting.subscriptions
  }));

  const [paymentMethodData, setPaymentMethodData] = useState<PaymentMethodEntity>();

  const tableHeaderTexts = [i18n.t("subscription_type"), i18n.t("last_payment"), i18n.t("next_payment_due"), i18n.t("payment_method")];

  const getPaymentMethodData = async () => {
    const { data } = await new PaymentMethodResource().readMany();
    const defaultPaymentMethod = data.filter((item) => (item.attributes.card.default === true));
    setPaymentMethodData(defaultPaymentMethod[0]);
  }

  const setSubscriptionInfo = async () => {
    try {
      const response = await new SubscriptionsResource().readMany();
      dispatch(setSubscriptions(response.data));
    } catch (_) { }
  };

  const deleteSubscription = async () => {
    if (subscriptions.length === 0) {
      return;
    }
    const subscription = subscriptions[0];
    setDeleteSubsLoading(true);
    try {
      await new SubscriptionsResource().removeOne(subscription.attributes.id);
      await setSubscriptionInfo();
    } catch (_) { }
    setDeleteSubsLoading(false);
    dispatch(setPreferencesDialog(false));
    handleOpenUnsubscriptionDialog();
  };

  useEffect(() => {
    getPaymentMethodData();
    setSubscriptionInfo();
  }, []);

  const trialingSubscription = subscriptions.filter((item) => (item.attributes.status === "trialing"));
  const isTrialing = trialingSubscription.length !== 0;

  const activeSubscription = subscriptions.filter((item) => (item.attributes.status === "active"));
  const isSubscriptionPresent = activeSubscription.length !== 0;
  const lastPayment = activeSubscription[0]?.attributes.current_period_start.slice(0, 10);
  const nextPaymentDue = activeSubscription[0]?.attributes.current_period_end.slice(0, 10);
  const paymentMethod = paymentMethodData?.attributes.card.brand;
  const isCanceled = activeSubscription[0]?.attributes.cancel_at_period_end;

  const onChangeBillingCycle = () => {
    dispatch(setPreferencesTab(PreferenceTab.CHANGE_BILLING_CYCLE));
  }

  const onRemove = () => {
    setIsUnsubscribeOpen(true);
  }

  const noKeep = () => {
    setIsUnsubscribeOpen(false);
  }

  const onPressUpgradeNow = () => {
    navigate("/checkout", {
      state: "inside_app",
    });
  };

  const startDate = DateTime.fromISO(
    trialingSubscription[0]?.attributes.current_period_start.split(" ")[0]
  ).toFormat('LLL dd');

  const endDate = DateTime.fromISO(
    trialingSubscription[0]?.attributes.current_period_end.split(" ")[0]
  ).toFormat('LLL dd');

  const differenceBetweenTrialStartAndEndDates = DateTime.fromISO(
    trialingSubscription[0]?.attributes.current_period_end.split(" ")[0]
  ).diff(DateTime.fromISO(
    trialingSubscription[0]?.attributes.current_period_start.split(" ")[0]
  ), ["days"]).toObject().days;

  const differenceBetweenTodayAndTrialStartDate = DateTime.now().diff(DateTime.fromISO(
    trialingSubscription[0]?.attributes.current_period_start.split(" ")[0]
  ), ["days"]).toObject().days;

  const progressValue = differenceBetweenTrialStartAndEndDates && differenceBetweenTodayAndTrialStartDate ? (differenceBetweenTodayAndTrialStartDate / differenceBetweenTrialStartAndEndDates) * 100 : 0;

  return {
    tableHeaderTexts,
    deleteSubscription,
    deleteSubsLoading,
    lastPayment,
    nextPaymentDue,
    isSubscriptionPresent,
    paymentMethodData,
    paymentMethod,
    onChangeBillingCycle,
    isUnsubscribeOpen,
    onRemove,
    noKeep,
    isCanceled,
    onPressUpgradeNow,
    isTrialing,
    startDate,
    endDate,
    progressValue
  };
};
