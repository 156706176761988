import { AsanaImage, GoogleCalendarImage, TodoistImage } from "assets/images";
import { palette } from "assets/palette";
import { EventType } from "communicators/resources/event-resources/event-resources.type";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import {
  AllBoardDataEntityType,
  IBoard,
  ThirdPartyProvider,
} from "interfaces/main.interfaces";
import React, { useCallback, useEffect } from "react";
import { TimeDateItem } from "./components/TimeDateItem";
import { useDragHook } from "./useDragHook";
import { useTimelineListItemHook } from "./useTimelineListItemHook";
import { i18n } from "constants/i18n.constants";
import { DueDateSelection } from "./components/DueDateSelection";
import { ScheduledDateSelection } from "./components/ScheduledDateSelection";
import { StartTimeSelection } from "./components/StartTimeSelection";
import { MeetDetail } from "./components/MeetDetail";
import { CustomTooltip } from "components/custom-tooltip/CustomTooltip";
import Snackbar from "@mui/material/Snackbar";
import { IconButton, TextareaAutosize } from "@mui/material";
import "./style.scss";
import { TaskEntity } from "communicators/resources/event-resources/tasks/tasks.type";
import { DateTime } from "luxon";
import { isDefaultScheduledTimeForDateTime } from "helpers/isDefaultScheduledTime";
import { getTitle } from "helpers/common.helper";
import { useTaskDetailHook } from "./useTaskDetailHook";
import { DragAndDropHelper } from "helpers/dnd.helper";
import { resizeByResolutionWidth } from "helpers/utils.helper";
import { TagSearchItem } from "./components/TagItem/TagSearchItem";
import { TagSelection } from "./components/TagSelection";

interface Props {
  data: AllBoardDataEntityType;
  index: number;
  moveCard?: (dragIndex: number, hoverIndex: number) => void;
  boardId: string;
  isCard?: boolean;
  isCompleted?: boolean;
  onTaskCreate?: (newTask: TaskEntity, isNewTask: boolean) => void;
  onClickCheckBox?: (data: AllBoardDataEntityType) => void;
  deleteTask?: (data: AllBoardDataEntityType) => void;
  boardData?: IBoard;
  onTaskMeetAction?: () => void;
  onSelectSomeday?: (date: DateTime, data?: AllBoardDataEntityType) => void;
  refreshBoards?: () => void;
  newTaskCreate?: (isCreated: boolean) => void;
}

export const TimelineListItem = ({
  data,
  index,
  moveCard,
  isCard,
  boardData,
  isCompleted,
  onTaskCreate,
  onClickCheckBox,
  deleteTask,
  onTaskMeetAction,
  onSelectSomeday,
  refreshBoards,
  newTaskCreate,
}: Props) => {
  const {
    showCheckbox,
    getTime,
    handleMeetDetail,
    onMouseDownEvent,
    onMouseUpEvent,
    onMouseMove,
    onMouseLeave,
    onCloseSnackbar,
    isMeetDetailOpen,
    snackbarOpen,
    onChangeTitle,
    updatedTask,
    checked,
    title,
    inputRef,
    onPressCheckbox,
  } = useTimelineListItemHook({
    data,
    onTaskCreate,
    boardData,
    onClickCheckBox,
    newTaskCreate,
  });
  const {
    hours,
    selectedTimes,
    onPressItem,
    onSelectScheduledDate,
    scheduledDate,
    onSelectDueDate,
    dueDate,
    timeSelectionToggle,
    isTimeSelectionOpened,
    isScheduledDateSelectionOpened,
    isDueDateSelectionOpened,
    dateSelectionToggle,
    openTimeSelector,
    openDueDateSelector,
    openScheduledDateSelector,
    closeSelectionToggles,
    isOpen,
    isDeleteIconShown,
    onPressTrashIcon,
    snapIndex,
    setIsOpen,
    onPressDone,
    isTagSearchShown,
    openTagSelector,
    isTagSelectionOpened,
    onClickTagPlus,
    onClickAddTag,
    isTagCreating,
    taskTags,
    favoriteTags,
    openManageTagsModal,
    onClickFavoriteTag,
    onClickTaskTag,
    onClickTagSearchItem,
  } = useTaskDetailHook({
    data,
    boardData,
    onTaskMeetAction,
    refreshBoards,
  });
  const { ref, opacity } = useDragHook({
    moveCard,
    index,
    id: data?.id,
    data,
  });

  const renderLeftBottom = () => {
    if (data?.type === EventType.MEET) {
      return (
        <img
          style={styles.image}
          src={GoogleCalendarImage}
          alt="google_calendar"
        />
      );
    }
    if (
      data?.type === EventType.TASK &&
      data?.attributes.provider === ThirdPartyProvider.ASANA
    ) {
      return <img style={styles.image} src={AsanaImage} alt="asana" />;
    }
    if (
      data?.type === EventType.TASK &&
      data?.attributes.provider === ThirdPartyProvider.TODOIST
    ) {
      return <img style={styles.image} src={TodoistImage} alt="todoist" />;
    }
    return <></>;
  };

  const renderCheckBox = () => {
    if (!showCheckbox) return null;
    return isCard && isCompleted ? (
      <button
        onClick={() => onClickCheckBox?.(updatedTask)}
        style={styles.uncursor}
      >
        <Icon style={{ cursor: "default" }} name={IconName.TICK} />
      </button>
    ) : (
      <button
        style={checked ? styles.checkbox : styles.emptyCheckbox}
        onClick={onPressCheckbox}
      >
        {checked ? <Icon name={IconName.TICK} /> : null}
      </button>
    );
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={onCloseSnackbar}
      >
        <Icon name={IconName.CLOSE} fill={palette.white} />
      </IconButton>
    </>
  );

  useEffect(() => {
    document.addEventListener(
      "keydown",
      (event) => {
        var name = event.key;
        var code = event.code;
        // Alert the key name and key code on keydown
        if (name === "Escape" || (code === "KeyS" && event.ctrlKey)) {
          event.preventDefault();
          closeSelectionToggles();
        }
      },
      false
    );
  }, []);

  const getIsSomedayScheduled = (): boolean => {
    if (typeof scheduledDate === "string") {
      if (scheduledDate.split("/")[2] === "3000") {
        return true;
      }
    }
    if (typeof scheduledDate !== "string" && scheduledDate) {
      return scheduledDate.year === 3000;
    }
    return false;
  };

  const isDueDateSmallerThanToday = (): boolean => {
    if (data?.type === EventType.TASK && data?.attributes?.completed_count > 0)
      return false;
    const today = DateTime.now();
    if (typeof dueDate !== "string") {
      if (dueDate?.year < today?.year) {
        return true;
      } else if (
        dueDate?.year === today?.year &&
        dueDate?.month < today?.month
      ) {
        return true;
      } else if (
        dueDate?.year === today?.year &&
        dueDate?.month === today?.month &&
        dueDate?.day < today?.day
      ) {
        return true;
      }
    }
    return false;
  };
  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if ((e.keyCode === 13 && e.shiftKey === false) || e.code === "enter") {
      e.preventDefault();
      onPressDone();
      inputRef.current?.blur();
    }
  };

  const useOutsideClick = () => {
    const handleClick = useCallback((event: { target: any }) => {
      if (!ref?.current?.contains(event.target)) {
        setIsOpen(false);
      }
    }, []);
    useEffect(() => {
      document.addEventListener("mouseup", handleClick);

      return () => {
        document.removeEventListener("mouseup", handleClick);
      };
    }, [handleClick]);
  };

  useOutsideClick();
  const isSomeday =
    getIsSomedayScheduled() || isDefaultScheduledTimeForDateTime(dueDate);
  return (
    <div
      onMouseDown={onMouseDownEvent}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUpEvent}
      onMouseLeave={onMouseLeave}
      onDragEnd={() => DragAndDropHelper.setDragingEvent(null)}
      style={{ ...styles.itemContainer, opacity }}
      ref={ref}
    >
      <div style={styles.row}>
        <div style={styles.topContainer}>
          <div style={styles.titleContainer}>
            {renderCheckBox()}
            {data?.type === EventType.TASK ? (
              <TextareaAutosize
                className="input"
                value={title}
                placeholder={i18n.t("type_here")}
                onChange={(e) => onChangeTitle(e, data)}
                ref={inputRef}
                onKeyDown={onEnterPress}
              />
            ) : (
              <div className="title" style={styles.title}>
                {getTitle(data)}
              </div>
            )}
            {showCheckbox ? (
              <div style={styles.iconContainer}>
                <CustomTooltip
                  description={i18n.t("start_time")}
                  direction="top-start"
                >
                  <Icon name={IconName.TIME} onPress={timeSelectionToggle} />
                </CustomTooltip>
                <CustomTooltip
                  description={i18n.t("due_date")}
                  direction="top-start"
                >
                  <Icon
                    name={IconName.CALENDAR}
                    onPress={dateSelectionToggle}
                    style={styles.iconAligner}
                  />
                </CustomTooltip>
              </div>
            ) : null}
          </div>
          <div style={styles.bottomContainer}>
            {!isOpen ? (
              <div
                className={
                  data?.type === EventType.MEET ? "meet-time" : "task-time"
                }
              >
                {getTime()}
              </div>
            ) : null}
            <div style={styles.imageWrapper} onClick={handleMeetDetail}>
              {renderLeftBottom()}
            </div>
          </div>
          {isOpen ? (
            <>
              <div style={styles.selectionContainer}>
                <TimeDateItem
                  tooltipDescription={i18n.t("start_time_selection_tooltip")}
                  selectedTime={selectedTimes}
                  header={i18n.t("start_time")}
                  onPress={openTimeSelector}
                  style={styles.timeInput}
                  format="XX:XX"
                  isTime
                />
                <TimeDateItem
                  tooltipDescription={i18n.t(
                    "scheduled_date_selection_tooltip"
                  )}
                  date={scheduledDate}
                  header={i18n.t("scheduled_date")}
                  onPress={openScheduledDateSelector}
                  style={styles.dateInput}
                  isDate
                  onDateComlete={onSelectScheduledDate}
                />
                <TimeDateItem
                  tooltipDescription={i18n.t("due_date_selection_tooltip")}
                  date={dueDate}
                  header={i18n.t("due_date")}
                  onPress={openDueDateSelector}
                  style={styles.dateInput}
                  isInvalidDueDate={isDueDateSmallerThanToday()}
                  isDate
                  onDateComlete={onSelectDueDate}
                />
                {process.env.REACT_APP_ENVIRONMENT === "staging" ? (
                  <CustomTooltip
                    description={i18n.t("tag_selection_tooltip")}
                    direction="top-start"
                  >
                    <TagSearchItem
                      label="Tag"
                      style={styles.tagItem}
                      onClickPlus={onClickTagPlus}
                      isTagSearchShown={isTagSearchShown}
                      onPress={openTagSelector}
                      onClickAddTag={onClickAddTag}
                      isTagCreating={isTagCreating}
                      tagCircleColor={
                        data.type === EventType.TASK
                          ? taskTags?.length === 1
                            ? taskTags[0].tag.color
                            : undefined
                          : undefined
                      }
                      isTagPresent={
                        data.type === EventType.TASK
                          ? data?.attributes?.task_tags?.length! > 1
                          : undefined
                      }
                      taskTags={taskTags}
                      onClickTagSearchItem={onClickTagSearchItem}
                    />
                  </CustomTooltip>
                ) : null}
              </div>
              {isTimeSelectionOpened ? (
                <StartTimeSelection
                  snapIndex={snapIndex}
                  hours={hours}
                  selectedTime={selectedTimes}
                  onPressItem={onPressItem}
                />
              ) : null}
              {isScheduledDateSelectionOpened ? (
                <ScheduledDateSelection
                  onSelectScheduledDate={onSelectScheduledDate}
                  isSomeday={isSomeday}
                  selectedDate={
                    typeof scheduledDate !== "string"
                      ? scheduledDate
                      : undefined
                  }
                  onSelectSomeday={(date) => onSelectSomeday?.(date, data)}
                />
              ) : null}
              {isDueDateSelectionOpened ? (
                <DueDateSelection
                  onSelectDueDate={onSelectDueDate}
                  isSomeday={isSomeday}
                  selectedDate={dueDate}
                />
              ) : null}
              {isTagSelectionOpened ? (
                <TagSelection
                  tags={taskTags}
                  favoriteTags={favoriteTags}
                  onClickTripleCircle={openManageTagsModal}
                  onClickFavoriteTag={onClickFavoriteTag}
                  onClickTaskTag={onClickTaskTag}
                />
              ) : null}
              <div className="delete-container">
                <button className="done-button" onClick={onPressDone}>
                  {i18n.t("done")}
                </button>
                <Icon
                  width={20}
                  height={20}
                  name={IconName.TRASH}
                  fill={palette.gray.mediumLight}
                  onPress={onPressTrashIcon}
                />
              </div>
              {isOpen && isDeleteIconShown ? (
                <Icon
                  width={6.5}
                  height={6.5}
                  name={IconName.CLOSE}
                  onPress={() => deleteTask?.(data)}
                  style={styles.closeIcon}
                />
              ) : null}
            </>
          ) : null}
        </div>
      </div>
      {!showCheckbox && data?.type === EventType.MEET && isMeetDetailOpen ? (
        <MeetDetail data={data} />
      ) : null}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={onCloseSnackbar}
        message={i18n.t("cannot_reschedule")}
        action={action}
      />
    </div>
  );
};

const grid = 8;

const styles: Record<string, React.CSSProperties> = {
  itemContainer: {
    display: "flex",
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    borderRadius: 12,
    backgroundColor: palette.white,
    boxShadow:
      "-1px 3px 4px rgba(0, 0, 0, 0.14), 1px 3px 4px rgba(0, 0, 0, 0.14)",
    position: "relative",
    flexDirection: "column",
  },
  title: {
    width: resizeByResolutionWidth(390) - 40,
  },
  taskTime: {
    fontSize: 12,
    fontFamily: "Gilroy-Medium",
    color: palette.gray.medium,
    marginLeft: 38,
    marginTop: 5,
  },
  meetTime: {
    fontSize: 12,
    fontFamily: "Gilroy-Medium",
    color: palette.gray.medium,
  },
  row: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    flexDirection: "row",
  },
  imageWrapper: {
    marginTop: 2,
    display: "flex",
    marginLeft: "auto",
  },
  image: {
    width: 16,
    height: 16,
    opacity: 0.6,
  },
  emptyCheckbox: {
    width: 26,
    height: 26,
    borderRadius: 13,
    backgroundColor: palette.gray.ice,
    marginRight: 10,
    borderWidth: 0,
    cursor: "pointer",
  },
  checkbox: {
    width: 26,
    height: 26,
    borderRadius: 13,
    backgroundColor: palette.orange.dark,
    marginRight: 10,
    borderWidth: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  uncursor: {
    width: 26,
    height: 26,
    borderRadius: 13,
    backgroundColor: palette.orange.dark,
    marginRight: 10,
    borderWidth: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  selectionContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: resizeByResolutionWidth(20),
  },
  topContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignSelf: "stretch",
  },
  titleContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "stretch",
    justifyContent: "space-between",
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  bottomContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    alignSelf: "stretch",
  },
  dateInput: {
    width: 75,
  },
  timeInput: {
    width: 60,
  },
  tagItem: {
    width: 40,
  },
  completedIcon: {
    marginBottom: "auto",
  },
  closeIcon: {
    width: 19.5,
    height: 19.5,
    borderRadius: "50%",
    backgroundColor: palette.gray.ice,
    position: "absolute",
    top: -8,
    right: -8,
  },
  iconAligner: {
    marginRight: -6,
  },
};
