import React from "react";
import { i18n } from "constants/i18n.constants";
import { LoginWrapper } from "components/login-wrapper/LoginWrapper";
import { SubscriptionPeriodSelection } from "components/subscription-period-selection/SubscriptionPeriodSelection";
import { Text } from "elements/text/Text";
import { palette } from "assets/palette";
import { CustomButton } from "elements/button/Button";
import { useAlreadySignInHook } from "./useAlreadySignInHook";
import { SubscriptionPlan } from "components/subscription-period-selection/SubscriptionPeriodSelection.type";
import "./AlreadySignIn.scss";
import { usePageHook } from "hooks/usePageHook";

export const AlreadySignIn = () => {
  const {
    selectedPlan,
    startFreeTrialLoading,
    setSelectedPlan,
    onPressUpgradeNow,
    onPressStartFreeTrial,
    monthlyPrice,
    yearlyPrice
  } = useAlreadySignInHook();
  usePageHook();
  return (
    <LoginWrapper
      showBottom={false}
      title={`${i18n.t("first_two_week_free")} ${i18n.t(
        "no_credit_cart_required"
      )}`}
      headerRightText={i18n.t("next")}
      onPressHeaderRightText={onPressStartFreeTrial}
    >
      <div className="already-sign-in-body-container">
      <Text style={{ ...styles.subtitle, ...styles.marginForSubtitle }}>
        {i18n.t("already_sign_up_mobile_title")}
      </Text>
      <Text
        style={{
          ...styles.subtitle,
          ...styles.marginForSubtitle,
          color: palette.gray.dark,
        }}
      >
        {i18n.t("already_sign_up_mobile_subtitle")}
      </Text>
      <SubscriptionPeriodSelection
        selectedPlan={selectedPlan}
        onSelectPlan={setSelectedPlan}
      />
      <Text style={styles.priceText}>
        {i18n.t("days_free_then_price", {
          day: 14,
          price: selectedPlan === SubscriptionPlan.MONTHLY ? monthlyPrice : yearlyPrice,
        })}
      </Text>
      <CustomButton
        onPress={onPressStartFreeTrial}
        style={styles.buttonStyle}
        text={i18n.t("start_free_trial")}
        loading={startFreeTrialLoading}
      />
      <Text style={styles.secondarySubtitle}>{i18n.t("you_can_upgrade")}</Text>
      <CustomButton
        onPress={onPressUpgradeNow}
        style={{ ...styles.buttonStyle, ...styles.upgradeNowButton }}
        text={i18n.t("upgrade_now")}
      />
      </div>
    </LoginWrapper>
  );
};

const styles: Record<string, React.CSSProperties> = {
  subtitle: {
    fontSize: 20,
    color: palette.black,
    fontFamily: "Gilroy-Semibold",
    fontWeight: "500",
    display: "flex",
    textAlign: "center",
    marginLeft: 50,
    marginRight: 50,
  },
  secondarySubtitle: {
    fontSize: 20,
    color: palette.gray.medium,
  },
  marginForSubtitle: {
  },
  marginForNoCreditCard: {
    margin: 0
  },
  buttonStyle: {
    height: 50,
    width: 400,
  },
  priceText: {
    fontSize: 18,
    color: palette.black,
    fontFamily: "Gilroy-Semibold",
    fontWeight: "500",
    display: "flex",
    textAlign: "center",
    marginLeft: 50,
    marginRight: 50,
  },
  upgradeNowButton: {
    backgroundColor: palette.gray.mediumLight,
  },
};
