import { DateTime } from "luxon";

class MagicPlannerController {
  intervals: { start: DateTime; end: DateTime }[] = [];

  setInterval(newIntervals: { start: DateTime; end: DateTime }[]) {
    this.intervals = newIntervals;
  }
}
export const MagicPlannerHelper = new MagicPlannerController();
