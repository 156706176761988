import {
  IAPIResource,
  Meta,
  ResponseWithData,
} from "communicators/resources/type";

export interface IThirdPartyInformationResource extends IAPIResource {
  query: Record<string, any>;
  candidate: Record<string, any>;
  entity: ResponseWithData<AllThirdPartyInformationEntity>;
  entities: ResponseWithData<ThirdPartyInformationEntity[]>;
}
export interface AllThirdPartyInformationEntity {
  meta: Meta;
  data: ThirdPartyInformationEntity[];
}
export interface ThirdPartyInformationEntity {
  id: string;
  access_token: string;
  refresh_token?: string;
  resync_token: string | null;
  provider: string;
  expire_at?: string;
  metadata?: string[];
  user_id: string;
  created_at: string;
  updated_at: string;
  scopes?: string[];
}

export enum Scope {
  READ = "data:read",
  WRITE = "data:write",
  DELETE = "data:delete"
}