import { DNDEvent, DropResult } from "constants/dnd.constants";
import { IBoard } from "interfaces/main.interfaces";
import { IEvent } from "pages/private/home/components/DrawerRight/types";
import { setDragingEvent } from "redux-store/actions/dnd.actions";
import { store } from "redux-store/store";

class DragAndDropHelperSingelton {
  draggingEvent: DNDEvent | null = null;
  events: IEvent[] = [];
  currentBoardData: IBoard[] = [];
  isOverOnRightDrawer: boolean = false;



  setDragingEvent = (newDragingEvent: DNDEvent | null) => {
    this.draggingEvent = newDragingEvent;
    store.dispatch(setDragingEvent(newDragingEvent));
  };

  setEvents = (newEvents: IEvent[]) => {
    this.events = newEvents;
  };

  setBoardData = (boardData: IBoard[]) => {
    this.currentBoardData = boardData;
  };

  setIsOverOnOnRightDrawer = (status: boolean) => {
    this.isOverOnRightDrawer = status;
  }

  getBoardIdByBoardItemId = (boardItemId?: string) => {
    if (!boardItemId) return { board: "", currentIndex: -1 };
    for (let i = 0; i < this.currentBoardData.length; i++) {
      const currentBoard = this.currentBoardData[i];
      if (
        !currentBoard ||
        !currentBoard.data ||
        currentBoard.data.length === 0
      ) {
        continue;
      }
      for (let j = 0; j < currentBoard.data.length; j++) {
        const currentItem = currentBoard.data[j];
        if (!currentItem) {
          continue;
        }
        if (boardItemId === currentItem.id) {
          return { board: currentBoard.date.toSQLDate(), currentIndex: j };
        }
      }
    }
    return { board: "", currentIndex: -1 };
  };

  getLatestIndexOfBoard = (boardId: string) => {
    for (let i = 0; i < this.currentBoardData.length; i++) {
      const currentBoard = this.currentBoardData[i];
      if (currentBoard.date.toSQLDate() === boardId) {
        return currentBoard.data.length;
      }
    }
    return 0;
  };
}
export const DragAndDropHelper = new DragAndDropHelperSingelton();

export const onMoveDropableArea = (boardData: IBoard, onDragEnd: (result: DropResult) => void) => {
  const { board, currentIndex } = DragAndDropHelper.getBoardIdByBoardItemId(
    DragAndDropHelper.draggingEvent?.data.id
  );
  const sourceBoard = board;
  const sourceIndex = currentIndex;
  const destinationBoard = boardData?.date.toSQLDate()!;
  const destinationIndex =
    DragAndDropHelper.getLatestIndexOfBoard(destinationBoard!);
  if (!sourceBoard || !destinationBoard) return;
  const result: DropResult = {
    destination: { droppableId: destinationBoard, index: destinationIndex },
    source: { droppableId: sourceBoard, index: sourceIndex },
  };
  onDragEnd(result);
};