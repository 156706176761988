import React, { Fragment, useEffect } from "react";
import { palette } from "assets/palette";
import { ITimelineItem } from "pages/private/home/components/DrawerRight/types";
import { Text } from "elements/text/Text";
import { Button } from "@mui/material";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { DateTime } from "luxon";

interface Props {
  hours: ITimelineItem[];
  selectedTime: DateTime | string | DateTime[];
  onPressItem: (index: number, time: DateTime) => void;
  snapIndex?: number;
  isSomeday?: boolean;
}

export const StartTimeSelection = ({
  hours,
  selectedTime,
  onPressItem,
  snapIndex,
  isSomeday,
}: Props) => {
  useEffect(() => {
    if (snapIndex) {
      const selectedIndex = document.getElementById(snapIndex.toString());
      if (selectedIndex) {
        selectedIndex.scrollIntoView(true);
      }
    }
  }, [snapIndex]);
  const isSelected = (item: ITimelineItem) => {
    if (!selectedTime || typeof selectedTime === "string") {
      return false;
    }
    if (!Array.isArray(selectedTime)) {
      return (
        item.time.hour === selectedTime.hour &&
        item.time.minute === selectedTime.minute
      );
    } else {
      const foundTime = selectedTime.find(
        (data) =>
          item.time.hour === data.hour && item.time.minute === data.minute
      );
      return !!foundTime;
    }
  };
  if (isSomeday) return <></>;
  return (
    <div style={styles.hoursWrapper} className="Windows">
      {hours.map((item, index) => {
        const checked = isSelected(item);
        return (
          <Fragment key={`${item}${index}`}>
            <Button
              style={
                checked
                  ? styles.selectedButtonStyle
                  : styles.unselectedButtonStyle
              }
              variant="text"
              onClick={() => onPressItem(index, item.time)}
              id={`${index}`}
              key={index}
            >
              <Text
                style={
                  checked
                    ? styles.selectedTextStyle
                    : styles.unselectedTextStyle
                }
              >
                {item.time.toLocaleString(DateTime.TIME_SIMPLE)}
              </Text>
              <div
                style={
                  checked ? styles.checkedContainer : styles.uncheckedContainer
                }
              >
                <Icon name={IconName.TICK} fill={palette.white} width={8} />
              </div>
            </Button>
            <div style={styles.divider} />
          </Fragment>
        );
      })}
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  hoursWrapper: {
    height: 200,
    overflowY: "scroll",
    marginTop: 12,
  },
  selectedButtonStyle: {
    backgroundColor: palette.white,
    borderRadius: 10,
    width: "100%",
    textTransform: "none",
    margin: 5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  unselectedButtonStyle: {
    backgroundColor: palette.white,
    borderRadius: 10,
    textTransform: "none",
    margin: 5,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  selectedTextStyle: {
    fontSize: 14,
    fontFamily: "Gilroy-Semibold",
    fontWeight: "500",
    color: palette.blue.lighter,
  },
  unselectedTextStyle: {
    fontSize: 14,
    fontFamily: "Gilroy-Semibold",
    fontWeight: "500",
    color: palette.gray.dark,
  },
  divider: {
    height: 1,
    width: "100%",
    backgroundColor: palette.gray.light,
  },
  checkedContainer: {
    width: 16,
    height: 16,
    borderRadius: 8,
    backgroundColor: palette.blue.lighter,
    border: `1px solid ${palette.blue.lighter}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  uncheckedContainer: {
    width: 16,
    height: 16,
    borderRadius: 8,
    backgroundColor: palette.white,
    border: `1px solid ${palette.white}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};
