import React from "react";
import { palette } from "assets/palette";
import { Text } from "elements/text/Text";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { styled } from "@mui/material/styles";
import { MeetEntity } from "communicators/resources/event-resources/meets/meets.type";
import { DateTime } from "luxon";
import { i18n } from "constants/i18n.constants";
import { GoogleCalendarImage, Logo } from "assets/images";
import { getMeetTimeRange } from "helpers/data.helper";

interface Props {
  data: MeetEntity;
  isDrawer?: boolean;
  ref?: any;
  isInsumo?: boolean;
}

export const MeetDetail = ({ data, isDrawer, ref, isInsumo }: Props) => {
  return (
    <div style={isDrawer ? styles.drawerContainer : styles.container} ref={ref}>
      {isDrawer ? (
        <>
          <div style={styles.drawerImageContainer}>
            <img
              style={styles.image}
              src={isInsumo ? Logo : GoogleCalendarImage}
              alt="google_calendar"
            />
            <Text style={styles.title}>{data.attributes.meet_summary}</Text>
          </div>
          <div style={styles.separator} />
        </>
      ) : null}
      <div style={styles.meetDetailContainer}>
        <Icon
          name={IconName.TIME}
          strokeColor={palette.gray.dark}
          style={styles.iconAligner}
        />
        <div style={styles.textAligner}>
          <Text style={styles.detailText}>
            {DateTime.fromISO(data.attributes.meet_end_time).toFormat(
              "cccc, MMMM dd"
            )}
          </Text>
          <Text style={styles.detailTextSubheader}>
            {getMeetTimeRange(data)}
          </Text>
        </div>
      </div>
      <div style={styles.separator} />
      {!isInsumo && !data.attributes.moved_by_magic_planner ? (
        <>
          <div style={styles.meetDetailContainer}>
            <Icon
              name={IconName.PEOPLE}
              fill={palette.gray.dark}
              strokeColor={palette.white}
              style={styles.iconAligner}
            />
            <Text style={styles.attendees}>{`${
              data.attributes.meet_attendees?.length || 0
            } guests`}</Text>
          </div>
          <div style={styles.meetDetailContainer}>
            <EmailLetterContainer color={palette.yellow.dark}>
              <Text style={styles.letter}>
                {data.attributes.meet_organizer?.charAt(0).toUpperCase()}
              </Text>
            </EmailLetterContainer>
            <div style={styles.mailAligner}>
              <Text style={styles.detailText}>
                {data.attributes.meet_organizer}
              </Text>
              <Text style={styles.detailTextSubheader}>
                {i18n.t("organizer")}
              </Text>
            </div>
          </div>
          <div style={styles.meetDetailContainer}>
            <EmailLetterContainer color={palette.orange.dark}>
              <Text style={styles.letter}>
                {data.attributes.calendar_id.charAt(0).toUpperCase()}
              </Text>
            </EmailLetterContainer>
            <Text style={styles.calendarId}>{data.attributes.calendar_id}</Text>
          </div>
        </>
      ) : null}
    </div>
  );
};

const EmailLetterContainer = styled("div")<{
  color?: string;
}>(({ color }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 16,
  height: 16,
  borderRadius: 8,
  backgroundColor: color,
  marginTop: 3,
}));

const styles: Record<string, React.CSSProperties> = {
  container: {
    backgroundColor: palette.white,
  },
  drawerContainer: {
    backgroundColor: palette.white,
    borderRadius: 10,
    boxShadow: "0px 4px 4px 0px #0000001A",
    padding: 15,
  },
  meetDetailContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 10,
  },
  iconAligner: {
    marginLeft: -8,
  },
  attendees: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 12,
    color: palette.gray.dark,
    marginLeft: 15,
  },
  textAligner: {
    marginLeft: 15,
  },
  mailAligner: {
    marginLeft: 21,
  },
  detailText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 12,
    color: palette.gray.dark,
  },
  detailTextSubheader: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 10,
    color: palette.gray.medium,
  },
  letter: {
    fontFamily: "Gilroy-Medium",
    fontSize: 10,
    color: palette.white,
  },
  separator: {
    height: 1,
    backgroundColor: palette.gray.light,
    width: "100%",
    marginTop: 10,
  },
  calendarId: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 12,
    color: palette.gray.dark,
    marginLeft: 21,
  },
  image: {
    width: 16,
    height: 16,
    marginLeft: -3,
  },
  title: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 15,
    color: palette.gray.dark,
    marginLeft: 21,
  },
  drawerImageContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 15,
  },
};
