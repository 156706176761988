import { i18n } from "constants/i18n.constants";
import { Text } from "elements/text/Text";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { ProgressBar } from "elements/progress-bar/ProgressBar";
import "./TrialView.scss";
import { palette } from "assets/palette";

interface Props {
  upgradeNow?: () => void;
  startDate?: string;
  endDate?: string;
  progressValue: number;
}

export const TrialView = ({
  upgradeNow,
  startDate,
  endDate,
  progressValue
}: Props) => {
  return (
    <div style={{flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between"}}>
      <Text style={styles.headerText}>{i18n.t("trial")}</Text>
      <>
        <ProgressBar
          style={styles.progress}
          value={progressValue}
          progressProps={{ color: "info" }}
        />
        <div className="date-text-container">
          <div className="date-text">{i18n.t("started", { date: startDate })}</div>
          <div className="date-text">{i18n.t("ended", { date: endDate })}</div>
        </div>
      </>
      <div className="subscribe-today">{i18n.t("subscribe_today")}</div>
      <div className="feature-container">
        <div className="feature">
          <div><Icon style={{ cursor: "default" }} name={IconName.UNSUBSCRIPTION_WEBAPP} /></div>
          <div className="feature-description">
            <div className="feature-description-title"><p>{i18n.t("web_app")}</p></div>
            <div className="feature-description-content"><p>{i18n.t("create_tasks_and_manage_meetings")}</p></div>
          </div>
        </div>
        <div className="feature">
          <div><Icon style={{ cursor: "default" }} name={IconName.UNSUBSCRIPTION_FOCUS} /></div>
          <div className="feature-description">
            <div className="feature-description-title"><p>{i18n.t("focus")}</p></div>
            <div className="feature-description-content"><p>{i18n.t("focus_on_tasks")}</p></div>
          </div>
        </div>
        <div className="feature">
          <div><Icon style={{ cursor: "default" }} name={IconName.UNSUBSCRIPTION_MOBILEAPP} /></div>
          <div className="feature-description">
            <div className="feature-description-title"><p>{i18n.t("mobile_app")}</p></div>
            <div className="feature-description-content"><p>{i18n.t("track_your_tasks")}</p></div>
          </div>
        </div>
        <div className="feature">
          <div><Icon style={{ cursor: "default" }} name={IconName.UNSUBSCRIPTION_REMINDERS} /></div>
          <div className="feature-description">
            <div className="feature-description-title"><p>{i18n.t("reminders")}</p></div>
            <div className="feature-description-content"><p>{i18n.t("get_reminded")}</p></div>
          </div>
        </div>
        <div className="feature">
          <div><Icon style={{ cursor: "default" }} name={IconName.UNSUBSCRIPTION_INTEGRATIONS} /></div>
          <div className="feature-description">
            <div className="feature-description-title"><p>{i18n.t("integrations")}</p></div>
            <div className="feature-description-content"><p>{i18n.t("integrate_calendar_todoist_asana_others")}</p></div>
          </div>
        </div>
        <div className="feature">
          <div><Icon style={{ cursor: "default" }} name={IconName.UNSUBSCRIPTION_ZEROTASKS} /></div>
          <div className="feature-description">
            <div className="feature-description-title"><p>{i18n.t("zero_task")}</p></div>
            <div className="feature-description-content"><p>{i18n.t("reach_your_zero_tasks")}</p></div>
          </div>
        </div>
      </div>
      <button className="upgrade-now" onClick={upgradeNow}>{i18n.t("upgrade_now")}</button>
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  progress: {
    width: "100%",
    height: 10,
  },
  headerText: {
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "500",
    fontSize: 18,
    color: palette.black,
  },
};
