import * as React from "react";
import { i18n } from "constants/i18n.constants";
import { Text } from "elements/text/Text";
import { useGoogleCalendarHook } from "./useGoogleCalendarHook";
import { GoogleCalendarImage } from "assets/images";
import { IOSSwitch } from "elements/switch/Switch";
import { palette } from "assets/palette";
import { Icon } from "elements/icon/Icon";
import { resizeByResolutionWidth } from "helpers/utils.helper";
import { IconName } from "elements/icon/Icon.type";
import { Spinner } from "elements/spinner/Spinner";
import { CustomButton } from "elements/button/Button";

interface Props {
  closeDrawer: () => void;
}

export const GoogleCalendar = ({ closeDrawer }: Props) => {
  const {
    googleToggled,
    loading,
    deleteOpened,
    deleting,
    toggleSwitch,
    closeDeleteOpen,
    onPressYesDelete,
  } = useGoogleCalendarHook();
  return (
    <>
      <div style={styles.secondDrawerHeaderContainer}>
        <Text style={styles.secondDrawerHeader}>
          {i18n.t("google_calendar")}
        </Text>
        <Icon
          width={resizeByResolutionWidth(15)}
          height={resizeByResolutionWidth(30)}
          name={IconName.LEFT_DRAWER_BIG_BACK}
          onPress={closeDrawer}
        />
      </div>
      <div style={styles.integrationItem} className="item">
        <img
          style={styles.image}
          src={GoogleCalendarImage}
          alt={i18n.t("google_calendar")}
        />
        <div style={styles.integrationItemWrapper}>
          <Text style={styles.integrationItemText}>
            {i18n.t("google_calendar")}
          </Text>
        </div>
        {!loading ? (
          <IOSSwitch onChange={toggleSwitch} checked={googleToggled} />
        ) : (
          <Spinner size="small" loading />
        )}
      </div>
      {deleteOpened ? (
        <div>
          <Text style={styles.deleteText}>
            {i18n.t("are_you_sure_delete_integration")}
          </Text>
          <div style={styles.buttonRow}>
            <CustomButton
              style={styles.leftButton}
              variant="outlined"
              text={i18n.t("no_keep")}
              textStyle={styles.leftButtonText}
              onPress={closeDeleteOpen}
            />
            <CustomButton
              style={styles.rightButton}
              variant="outlined"
              text={i18n.t("yes_delete")}
              textStyle={styles.rightButtonText}
              loading={deleting}
              onPress={onPressYesDelete}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

const styles: Record<string, React.CSSProperties> = {
  secondDrawerHeaderContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  secondDrawerHeader: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 20,
    color: palette.black,
    textAlign: "left",
  },
  integrationItem: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: palette.white,
    borderRadius: 12,
    padding: 20,
    marginTop: 35,
  },
  image: {
    width: 18,
    height: 16.5,
  },
  integrationItemText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 18,
    color: palette.gray.dark,
  },
  integrationItemWrapper: {
    display: "flex",
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    marginLeft: 20,
    alignItems: "flex-start",
  },
  deleteText: {
    fontSize: 15,
    fontWeight: "400",
    fontFamily: "Gilroy",
    color: palette.gray.dark,
    marginTop: "30px",
  },
  buttonRow: {
    display: "flex",
    marginTop: 20,
    marginBottom: 10,
  },
  leftButton: {
    borderColor: palette.gray.dark,
    width: 140,
    borderRadius: 10,
    marginRight: 13,
  },
  leftButtonText: {
    fontSize: 15,
    fontWeight: "bold",
    color: palette.gray.dark,
  },
  rightButton: {
    borderColor: palette.gray.mediumLight,
    width: 140,
    borderRadius: 10,
    marginRight: 13,
  },
  rightButtonText: {
    fontSize: 15,
    fontWeight: "bold",
    color: palette.gray.mediumLight,
  },
};
