import MuiDivider from "@mui/material/Divider";
import { palette } from "assets/palette";

interface Props {
  color?: string;
}

export const Divider = ({ color }: Props) => {
  let style = dividerStyle;
  if (color) {
    style = { ...dividerStyle, borderColor: color };
  }
  return <MuiDivider style={style} />;
};

const dividerStyle = { borderColor: palette.gray.mediumLight };
