import { InsumoAPIError } from "./insumo.error";
import { ErrorNames } from "./type";

export class UnprocessableAPIError extends InsumoAPIError {
  name = ErrorNames.UNPROCESSABLE;
  message: string | ErrorNames = ErrorNames.UNPROCESSABLE;

  constructor(message: string) {
    super(message);
    this.message = message;
  }
}
