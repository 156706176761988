import React from "react";
import { Text } from "elements/text/Text";
import { palette } from "assets/palette";
import InputBase from "@mui/material/InputBase";
import { resizeByResolutionWidth } from "helpers/utils.helper";

interface Props {
  label?: string;
  value: string;
  onChangeText?: (text: string) => void;
  style?: React.CSSProperties;
  leftImage?: string;
  disabled?: boolean;
  placeholder?: string;
}

export const TextInput = ({
  label,
  value,
  style,
  leftImage,
  disabled = false,
  placeholder,
  onChangeText,
}: Props) => {
  return (
    <div style={{ ...styles.container, ...style }}>
      <Text style={styles.label}>{label}</Text>
      <div style={styles.inputContainer}>
        {leftImage ? (
          <img
            src={leftImage}
            style={styles.leftImageStyle}
            alt="left_image_of_input"
          />
        ) : null}
        <InputBase
          value={value}
          style={styles.input}
          disabled={disabled}
          placeholder={placeholder}
          onChange={(event) => onChangeText && onChangeText(event.target.value)}
        />
      </div>
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  inputContainer: {
    display: "flex",
    alignSelf: "stretch",
    height: 34,
    borderRadius: 10,
    border: `1px solid ${palette.gray.light}`,
    backgroundColor: palette.gray.iceLight,
    marginTop: 4,
    paddingLeft: 10,
    paddingRight: 10,
    alignItems: "center",
  },
  label: {
    fontFamily: "Gilroy-Semibold",
    color: palette.gray.medium,
    fontSize: 10,
  },
  input: {
    display: "flex",
    alignSelf: "stretch",
    flex: 1,
    borderWidth: 0,
    backgroundColor: "transparent",
    border: "none",
  },
  leftImageStyle: {
    width: resizeByResolutionWidth(24),
    height: resizeByResolutionWidth(24),
    marginRight: resizeByResolutionWidth(26),
    objectFit: "contain",
  },
};
