import { EventType } from "communicators/resources/event-resources/event-resources.type";
import { TasksResource } from "communicators/resources/event-resources/tasks/tasks.resource";
import { TaskEntity } from "communicators/resources/event-resources/tasks/tasks.type";
import { isDefaultScheduledTime } from "helpers/isDefaultScheduledTime";
import { AllBoardDataEntityType, IBoard } from "interfaces/main.interfaces";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { AllBoardDataType } from "./useHomeHook";
import { useAppSelector } from "redux-store/store.hooks";
import { getAllBoardData } from "helpers/data.helper";
import { nearestDate } from "helpers/common.helper";

type Props = {
  startDate: DateTime;
  allBoardData: AllBoardDataType;
  setAllBoardData: React.Dispatch<React.SetStateAction<AllBoardDataType>>;
  refreshAllDataWithoutSpinner?: () => void;
  leftDrawerSearchedDate?: DateTime;
};

export const useInboxHook = ({
  startDate,
  refreshAllDataWithoutSpinner,
  leftDrawerSearchedDate
}: Props) => {
  const [inboxStartDate, setInboxStartDate] = useState<DateTime>(
    DateTime.now()
  );
  const [inboxData, setInboxData] = useState<IBoard[]>([]);
  const [inboxLoading, setInboxLoading] = useState<boolean>(false);

  const { refreshCompletedTasks, refreshInboxTasks } = useAppSelector((state) => ({
    refreshCompletedTasks: state.homePage.refreshCompletedTasks,
    refreshInboxTasks: state.homePage.refreshInboxTasks,
  }));

  const getInboxTasks = async (resync?: boolean) => {
    try {
      const response = await new TasksResource().readMany(
        {
          start_date: inboxStartDate.startOf("month").toSQLDate(),
          end_date: inboxStartDate.endOf("month").toSQLDate(),
          per: 1000000,
        },
        resync
      );
      return response.data;
    } catch (_) {
      return [];
    }
  };

  const getInitialInboxData = async () => {
    setInboxLoading(true);
    const tasksResponse = await getInboxTasks(false);
    if (!tasksResponse) return;
    const tasksData = getAllBoardData(tasksResponse, inboxStartDate.startOf("month"), false, inboxStartDate.daysInMonth);
    setInboxData(tasksData);
    setInboxLoading(false);
  };

  const onInboxTaskCreate = (
    index: number,
    boardDate: DateTime,
    createdTask: TaskEntity
  ) => {
    const copyAllBoard: IBoard[] = [...inboxData];
    copyAllBoard?.forEach((currentBoard, boardIndex) => {
      if (currentBoard.date.toSQL() === boardDate.toSQL()) {
        copyAllBoard[boardIndex].data[index] = createdTask;
      }
    });
    setInboxData(copyAllBoard);
    refreshAllDataWithoutSpinner && refreshAllDataWithoutSpinner();
  };

  const deleteInboxTask = (data: AllBoardDataEntityType) => {
    if (data.type !== EventType.TASK) return;
    const all = [...inboxData];
    all.forEach((item) => {
      item.data = item.data.filter((subitem) => subitem.id !== data.id);
    });
    setInboxData(all);
  };

  const onPressInboxBoardPlus = (date: DateTime) => {
    const copyInbox: IBoard[] = [...inboxData];
    let additionSuccess = false;
    const newTaskEntity: TaskEntity = {
      id: "",
      type: EventType.TASK,
      attributes: {
        title: "",
        description: "",
        due_date: date.toSQL()!,
        start_date: "",
        end_date: "",
        start_time: null,
        end_time: null,
        permalink_url: null,
        provider: "",
        task_gid: "",
        created_at: date.toSQL()!,
        updated_at: date.toSQL()!,
        completed_count: 0,
        scheduled_time: date.toSQLDate(),
      },
    };
    copyInbox.forEach((currentBoard, index) => {
      if (currentBoard.date.toSQL() === date.toSQL()) {
        additionSuccess = true;
        copyInbox[index].data.unshift(newTaskEntity);
      }
    });
    if (!additionSuccess) {
      const board: IBoard = {
        title: date.toFormat("LLLL dd ccc"),
        date: date,
        data: [newTaskEntity],
      };
      copyInbox.push(board);
    }
    setInboxData(copyInbox);
  };

  let inboxTaskData = inboxData.filter((item) => item?.data?.length !== 0);
  inboxTaskData.forEach((item) => {
    item.data = item.data.filter((subitem) =>
      subitem.type === EventType.TASK
        ? subitem.attributes.completed_count === 0 &&
          !isDefaultScheduledTime(subitem) &&
          DateTime.fromISO(
            subitem.attributes.scheduled_time
              ? subitem.attributes.scheduled_time
              : subitem?.attributes?.due_date
          ).year === inboxStartDate.year &&
          DateTime.fromISO(
            subitem.attributes.scheduled_time
              ? subitem.attributes.scheduled_time
              : subitem?.attributes?.due_date
          ).month === inboxStartDate.month
        : null
    );
  });
  let inboxVisibleData = inboxTaskData?.filter((item) =>
    item?.data?.some((item) =>
      item?.type === EventType.TASK
        ? item?.attributes?.completed_count === 0 &&
          !isDefaultScheduledTime(item) &&
          DateTime.fromISO(
            item?.attributes?.scheduled_time
              ? item?.attributes?.scheduled_time
              : item?.attributes?.due_date
          ).year === inboxStartDate?.year &&
          DateTime.fromISO(
            item?.attributes?.scheduled_time
              ? item?.attributes?.scheduled_time
              : item?.attributes?.due_date
          ).month === inboxStartDate?.month
        : null
    )
  );

  const inboxPreviousMonth = () => {
    setInboxStartDate(inboxStartDate.minus({ month: 1 }));
  };

  const inboxNextMonth = () => {
    setInboxStartDate(inboxStartDate.plus({ month: 1 }));
  };

  const refreshInboxWithoutSpinner = async () => {
    const tasksResponse = await getInboxTasks(false);
    if (!tasksResponse) return;
    const tasksData = getAllBoardData(tasksResponse, inboxStartDate.startOf("month"), false, inboxStartDate.daysInMonth);
    setInboxData(tasksData);
  };

  useEffect(() => {
    getInitialInboxData();
  }, [inboxStartDate, refreshInboxTasks]);

  useEffect(() => {
    refreshInboxWithoutSpinner();
  }, [refreshCompletedTasks]);

  let inboxDays: DateTime[] = [];

  const sortedInboxVisibleData = inboxVisibleData.sort(
    (a, b) => b.date.valueOf() - a.date.valueOf()
  );

  inboxDays = sortedInboxVisibleData.map((item) => item.date);

  const scrollDate: DateTime = nearestDate(inboxDays, DateTime.now());

  const scrolledId: string = DateTime.utc(
    scrollDate.year,
    scrollDate.month,
    scrollDate.day
  ).toSQLDate()!;

  const element = document.getElementById(scrolledId);

  useEffect(() => {
    if (element && inboxStartDate.month === DateTime.now().month) {
      element.scrollIntoView({ behavior: "auto" });
    }
  }, [inboxStartDate, element]);

  useEffect(() => {
    if (leftDrawerSearchedDate) {
      setInboxStartDate(leftDrawerSearchedDate);
    }
  }, [leftDrawerSearchedDate]);

  return {
    inboxData,
    setInboxData,
    inboxPreviousMonth,
    inboxNextMonth,
    inboxStartDate,
    inboxLoading,
    onPressInboxBoardPlus,
    onInboxTaskCreate,
    refreshInboxWithoutSpinner,
    deleteInboxTask,
    sortedInboxVisibleData,
  };
};
