import React from "react";
import { CustomButton } from "elements/button/Button";
import { GridContainer } from "elements/gird/GridContainer";
import { GridItem } from "elements/gird/GridItem";
import { Avatar, IconButton, Paper } from "@mui/material";
import { Logo } from "assets/images";
import { Text } from "elements/text/Text";
import { i18n } from "constants/i18n.constants";
import { palette } from "assets/palette";
import { IconName } from "elements/icon/Icon.type";
import { useLoginWrapperHook } from "./useLoginWrapperHook";
import { TermsOfUseText } from "components/login-wrapper/concatinated-texts/TermsOfUseText";
import { LoginText } from "components/login-wrapper/concatinated-texts/LoginText";
import { SignUpText } from "components/login-wrapper/concatinated-texts/SignUpText";
import { FullScreenSpinner } from "elements/spinner/FullScreenSpinner";
import { Icon } from "elements/icon/Icon";
import "./LoginWrapper.scss";

interface Props {
  children?: React.ReactNode;
  title?: string;
  showLoginText?: boolean;
  showForgotPassword?: boolean;
  showSignUpText?: boolean;
  showContinueWithPhone?: boolean;
  showBottom?: boolean;
  headerRightText?: string;
  onPressHeaderRightText?: () => void;
  backButton?: boolean;
  headerIcon?: IconName;
  isStripePayment?: boolean;
}

export const LoginWrapper = ({
  children,
  title,
  showLoginText,
  showForgotPassword,
  showSignUpText,
  showContinueWithPhone = true,
  showBottom = true,
  headerRightText,
  onPressHeaderRightText,
  backButton,
  headerIcon,
  isStripePayment
}: Props) => {
  const {
    loading,
    onPressGoogleSignIn,
    onPressTermsOfUse,
    onPressPrivacyPolicy,
    onPressPhoneLogin,
    onPressAppleLogin,
    onPressSignUp,
    onPressLogin,
    onPressForgotPassword,
    goBack,
  } = useLoginWrapperHook();
  return (
    <div>
      <GridContainer className="general-login-container">
        <GridItem
          className="sub-container"
          xs={11}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
        >
          <div
            style={
              backButton && headerRightText
                ? styles.headerContainer
                : styles.headerRightText
            }
            className={backButton && headerRightText ? 'header header-container' : 'header header-rigth-text'}
          >
            {backButton ? (
              <IconButton onClick={goBack} style={!isStripePayment ? styles.backButton : styles.styledBackButton}>
                <Icon name={IconName.BACK_ARROW} />
              </IconButton>
            ) : null}
            {headerRightText ? (
              <button
                style={styles.rightTextButton}
                onClick={onPressHeaderRightText}
              >
                <Text style={styles.headerText}>{headerRightText}</Text>
              </button>
            ) : null}
          </div>
          {!isStripePayment ? <GridItem className="icon-container">
            {headerIcon ? (
              <Icon name={headerIcon} />
            ) : (
              <Avatar src={Logo} className="insumo-logo" />
            )}
            <Text className="title">{title}</Text>
          </GridItem> : null}
          {children}
          {showBottom ? (
            <>
              <div className="or-seperator">
                <div className="or-line" />
                <Text className="or-text">{i18n.t("or")}</Text>
                <div className="or-line" />
              </div>
              <CustomButton
                variant="outlined"
                fullWidth
                text={i18n.t("continue_with_google")}
                leftIconProps={{ name: IconName.GOOGLE }}
                style={styles.googleButton}
                onPress={onPressGoogleSignIn}
                className="google-button"
              />
              <div style={styles.row} className="another-logins-container">
                {showContinueWithPhone ? (
                  <>
                    <CustomButton
                      variant="outlined"
                      fullWidth
                      text={i18n.t("continue_with_phone")}
                      leftIconProps={{
                        name: IconName.PHONE,
                        width: 17,
                        height: 24,
                      }}
                      onPress={onPressPhoneLogin}
                    />
                  </>
                ) : null}
                <CustomButton
                  variant="outlined"
                  fullWidth
                  text={i18n.t("continue_with_apple")}
                  leftIconProps={{
                    name: IconName.APPLE,
                    width: 17,
                    height: 24,
                  }}
                  onPress={onPressAppleLogin}
                />
              </div>
              <div className={showForgotPassword ? "login-footer-with-forgotpw" : "login-footer"}>
                {showLoginText ? <LoginText onPressLogin={onPressLogin} /> : null}
                {showForgotPassword ? (
                  <Text
                    onPress={onPressForgotPassword}
                    style={styles.forgotPassword}
                  >
                    {i18n.t("forgot_password")}
                  </Text>
                ) : null}
                {showSignUpText ? <SignUpText onPress={onPressSignUp} /> : null}
                <TermsOfUseText
                  onPressPrivacyPolicy={onPressPrivacyPolicy}
                  onPressTemsOfUse={onPressTermsOfUse}
                />
              </div>
            </>
          ) : null}
        </GridItem>
      </GridContainer>
      <FullScreenSpinner loading={loading} />
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  forgotPassword: {
    fontSize: 14,
    textDecoration: "underline",
    color: palette.gray.mediumLight,
    display: "flex",
    alignSelf: "flex-start",
    marginTop: 12,
  },
  headerText: {
    fontWeight: "500",
    fontSize: 18,
    color: palette.gray.dark,
    fontFamily: "Gilroy-Medium",
    textDecoration: "underline",
  },
  rightTextButton: {
    borderWidth: 0,
    backgroundColor: palette.white,
  },
  backButton: {
    borderWidth: 0,
    backgroundColor: "transparent",
    cursor: "pointer",
  },
  styledBackButton: {
    borderWidth: 0,
    backgroundColor: "transparent",
    left: -20
  },
  headerContainer: {
    display: "flex",
    alignSelf: "stretch",
    height: 50,
    alignItems: "center",
    justifyContent: "space-between",
  },
  headerRightText: {
    display: "flex",
    alignSelf: "stretch",
    height: 50,
    alignItems: "center",
    justifyContent: "flex-end",
  },
};
