import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { RouteProvider } from "containers/router/RouteProvider";
import { CustomThemeProvider } from "containers/theme/ThemeProvider";
import { store } from "redux-store/store";
import { OnesignalHelper } from "helpers/onesignal.helper";
import { isMobile } from "react-device-detect";
import { androidAppLink, iosAppLink } from "pages/private/home/constants";
import "./App.scss";

function App() {
  const [isMobileDevice, setIsMobileDevice] = useState<boolean>(false);

  useEffect(() => {
    if (isMobile) {
      setIsMobileDevice(true);
    }
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      window.location.href = iosAppLink;
    } else if (/Android/.test(userAgent)) {
      window.location.href = androidAppLink;
    }

    OnesignalHelper.init();
  }, []);

  useEffect(() => {
    const os = navigator.userAgent;
    if (os.search("Windows") !== -1) {
      const element = document.querySelector("*");
      element?.setAttribute("class", "Windows");
    }
  });
  return (
    <div>
      {isMobileDevice ? (
        <></>
      ) : (
        <Provider store={store}>
          <CustomThemeProvider>
            <RouteProvider />
          </CustomThemeProvider>
        </Provider>
      )}
    </div>
  );
}

export default App;
