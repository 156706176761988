import { FirebaseAPIError } from "communicators/services/firebase/errors/firebase.error";
import { ErrorNames } from "communicators/services/firebase/errors/type";
import { FirebaseService } from "communicators/services/firebase/Firebase.service";
import { User } from "firebase/auth";
import {
  createAndGetUser,
  getUserRedirectionAfterLogin,
} from "helpers/auth.helper";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { setThirdPartyProviderData } from "redux-store/actions/third-party-provider.actions";
import { setFirebaseUuid } from "redux-store/actions/user-settings.action";
import { useAppDispatch } from "redux-store/store.hooks";
import { LoginOptions } from "communicators/services/firebase/type";
import { i18n } from "constants/i18n.constants";

export const useEmailLoginHook = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [emailErrorMessage, setemailErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onPreePasswordIcon = () => {
    setPasswordHidden(!passwordHidden);
  };

  const resetErrors = () => {
    setPasswordErrorMessage("");
    setemailErrorMessage("");
  };

  const makeUserProcessAfterFirebase = async (
    userFirebase: User,
    isNew: boolean
  ) => {
    const idToken = await userFirebase.getIdToken();
    const { isNewUser, user } = await createAndGetUser({
      idToken,
      firebase_uuid: userFirebase.uid,
      email: email,
      full_name: userFirebase.displayName || undefined,
    });
    dispatch(setFirebaseUuid(userFirebase.uid));
    dispatch(setThirdPartyProviderData());
    setLoading(false);
    navigate(await getUserRedirectionAfterLogin(isNewUser, user), await getUserRedirectionAfterLogin(isNewUser, user) === "/home" ? { replace: true } : undefined);
  };

  function validateEmail(validateMail: string) {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(validateMail);
  }

  const createUser = async () => {
    try {
      resetErrors();
      if (!validateEmail(email)) {
        setPasswordErrorMessage(i18n.t("invalid_email"));
        return;
      }
      setLoading(true);
      const user = await new FirebaseService().login(LoginOptions.EMAIL, {
        email,
        password,
      });
      makeUserProcessAfterFirebase(user.user, false);
    } catch (err) {
      if (err instanceof FirebaseAPIError) {
        if (err.name === ErrorNames.USER_NOT_FOUND) {
          try {
            const user = await new FirebaseService().signup({
              email,
              password,
            });
            makeUserProcessAfterFirebase(user, true);
          } catch (error) {
            if (err instanceof FirebaseAPIError) {
              setPasswordErrorMessage(err.message);
            } else {
              setPasswordErrorMessage(i18n.t("invalid_email"));
            }
            setLoading(false);
          }
        } else if (err.name === ErrorNames.WRONG_PASSWORD) {
          setPasswordErrorMessage(err.message);
          setLoading(false);
        } else {
          setemailErrorMessage(err.message);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    }
  };

  const onPressSubmit = () => {
    createUser();
  };

  return {
    email,
    password,
    passwordHidden,
    passwordErrorMessage,
    emailErrorMessage,
    loading,
    setEmail,
    onPressSubmit,
    setPassword,
    onPreePasswordIcon,
  };
};
