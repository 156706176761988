import { InsumoAPIError } from "./insumo.error";
import { ErrorCodes, ErrorMessages, ErrorNames } from "./type";

export class UnauthorizedError extends InsumoAPIError {
	name = ErrorNames.UNAUTHORIZED;
	message = ErrorMessages.UNAUTHORIZED;
	status = ErrorCodes.UNAUTHORIZED;
}

export class CardAlreadyExistsError extends InsumoAPIError {
	name = ErrorNames.CARD_ALREADY_EXISTS;
	message = ErrorMessages.CARD_ALREADY_EXISTS;
	status = ErrorCodes.CARD_ALREADY_EXISTS;
}
