import { palette } from "assets/palette";
import { ListCheckBox } from "components/ListCheckbox/ListCheckBox";
import { LoginWrapper } from "components/login-wrapper/LoginWrapper";
import { i18n } from "constants/i18n.constants";
import { ProgressBar } from "elements/progress-bar/ProgressBar";
import { Spinner } from "elements/spinner/Spinner";
import { Text } from "elements/text/Text";
import React from "react";
import { TOOLS, useToolSelectionHook } from "./useToolSelectionHook";
import { usePageHook } from "hooks/usePageHook";

export const ToolSelection = () => {
  const { onSelect, isProviderSelected, progressValue, sendIntegrationTools, loading } =
    useToolSelectionHook();
    usePageHook();
  return (
    <LoginWrapper
      showBottom={false}
      title={i18n.t("select_task_manager_title")}
      headerRightText={i18n.t("next")}
      onPressHeaderRightText={sendIntegrationTools}
      backButton
    >
      <Text style={styles.secondarySubtitle}>
        {i18n.t("tool_selection_subtitle")}
      </Text>
      {loading ? <Spinner style={styles.spinner} size={50} color={"primary"} loading={loading} /> :
        <div style={styles.toolListContainer}>
          {TOOLS.map((item, index) => {
            return (
              <ListCheckBox
                key={index}
                style={styles.checkbox}
                title={item.title}
                checked={isProviderSelected(item.provider)}
                image={item.image}
                onPress={() => onSelect(item.provider)}
                subtitle={item.subtitle}
              />
            );
          })}
        </div>
      }
      <ProgressBar style={styles.progress} value={progressValue} progressProps={{ color: "info" }} />
    </LoginWrapper>
  );
};

const styles: Record<string, React.CSSProperties> = {
  checkbox: {
    marginBottom: 20,
    marginRight: 60,
    marginLeft: 60,
  },
  secondarySubtitle: {
    fontSize: 16,
    color: palette.gray.medium,
    display: "flex",
    textAlign: "center",
    marginBottom: 30,
    marginRight: 60,
    marginLeft: 60,
  },
  progress: {
    marginTop: 20,
    marginBottom: 30,
  },
  spinner: {
    display: "flex",
    alignSelf: "center"
  },
  toolListContainer: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "stretch",
    height: 350,
    overflow: "hidden",
    overflowY: "scroll",
  }
};
