import { ThirdPartyProviderDispatchTypes } from "redux-store/types/dispatch.type";
import { ThirdPartyProviderState } from "redux-store/types/state.type";
import { AllProviderActionTypes } from "redux-store/types/third-party-provider.action.type";

const INITIAL_STATE: ThirdPartyProviderState = {
  asanaId: "",
  todoistId: "",
  googleId: "",
  thirdPartyData: []
};

export const thirdPartyProviderReducer = (
  state = INITIAL_STATE,
  action: AllProviderActionTypes
) => {
  switch (action.type) {
    case ThirdPartyProviderDispatchTypes.setThirdPartyProviderData:
      return {
        ...state,
        asanaId: action.payload.asanaPayload,
        todoistId: action.payload.todoistPayload,
        googleId: action.payload.googlePayload,
      };
    case ThirdPartyProviderDispatchTypes.setThirdPartyData:
      return {
        ...state,
        thirdPartyData: action.payload,
      };
    default:
      return state;
  }
};
