import { EventType } from "communicators/resources/event-resources/event-resources.type";
import { TasksResource } from "communicators/resources/event-resources/tasks/tasks.resource";
import { todoistWriteAccessChecker } from "helpers/common.helper";
import { nearestDate } from "helpers/common.helper";
import { getAllBoardData } from "helpers/data.helper";
import {
  AllBoardDataEntityType,
  IBoard,
  ThirdPartyProvider,
} from "interfaces/main.interfaces";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useAppSelector } from "redux-store/store.hooks";
interface Props {
  onClickCheckBox: (task: AllBoardDataEntityType) => void;
}

export const useCompletedTasksHook = ({ onClickCheckBox }: Props) => {
  const [startDate, setStartDate] = useState(DateTime.now());
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState<{
    all: IBoard[];
    tasks: IBoard[];
  }>({
    all: [],
    tasks: [],
  });

  const { refreshCompletedTasks, thirdPartyData } = useAppSelector((state) => ({
    refreshCompletedTasks: state.homePage.refreshCompletedTasks,
    thirdPartyData: state.thirdPartyProviderData.thirdPartyData,
  }));

  const todoistScopes = thirdPartyData?.filter(
    (item) => item.provider === ThirdPartyProvider.TODOIST
  )[0]?.scopes;

  const getTasks = async (resync?: boolean) => {
    try {
      const response = await new TasksResource().readMany(
        {
          start_date: startDate.startOf("month").toSQLDate(),
          end_date: startDate.endOf("month").toSQLDate(),
          per: 1000000,
        },
        resync
      );
      return response.data;
    } catch (_) {
      return [];
    }
  };

  const getInitialData = async (showLoading = true) => {
    setLoading(showLoading && true);
    const tasksResponse = await getTasks(true);
    if (!tasksResponse) return;
    const data = getAllBoardData(
      [...tasksResponse],
      startDate.startOf("month"),
      false,
      startDate.daysInMonth
    );
    const tasksData = getAllBoardData(
      tasksResponse,
      startDate,
      false,
      startDate.daysInMonth
    );
    setAllData({ all: data, tasks: tasksData });
    setLoading(false);
  };

  const getCompletedVisibleData = () => {
    let completedData = allData.all.filter((item) => item.data.length !== 0);
    for (let i = 0; i < completedData.length; i++) {
      completedData[i].data = completedData[i].data.filter(
        (item) =>
          item.type === EventType.TASK && item.attributes.completed_count !== 0
      );
    }
    completedData = completedData.filter((item) => item.data.length !== 0);
    return completedData;
  };

  const onPressCompletedTaskCheckbox = (task: AllBoardDataEntityType) => {
    if (task.type !== EventType.TASK) return;
    if (!todoistWriteAccessChecker(task, todoistScopes)) return;
    const newAllData = [...allData.all];
    let dateIndex = -1;
    let dayIndex = -1;
    for (let i = 0; i < newAllData.length; i++) {
      const index = newAllData[i].data.findIndex(
        (item) => item.type === EventType.TASK && item.id === task.id
      );
      if (index >= 0) {
        dateIndex = i;
        dayIndex = index;
      }
    }
    if (dateIndex >= 0 && dayIndex >= 0) {
      newAllData[dateIndex].data[dayIndex] = {
        ...task,
        attributes: { ...task.attributes, completed_count: 0 },
      };
    }
    setAllData({ all: newAllData, tasks: allData.tasks });
    onClickCheckBox({
      ...task,
      attributes: { ...task.attributes, completed_count: 0 },
    });
  };

  const completedVisibleData = getCompletedVisibleData();

  let completedDays: DateTime[] = [];

  completedDays = completedVisibleData.map((item) => item.date);

  const previousMonth = () => {
    setStartDate(startDate.minus({ month: 1 }));
  };

  const nextMonth = () => {
    setStartDate(startDate.plus({ month: 1 }));
  };

  const deleteTask = async (data: AllBoardDataEntityType) => {
    if (data.type !== EventType.TASK) {
      return;
    }
    try {
      const all = allData.all;
      all.forEach((item) => {
        item.data = item.data.filter((subitem) => subitem.id !== data.id);
      });
      const tasks = allData.tasks;
      tasks.forEach((item) => {
        item.data = item.data.filter((subitem) => subitem.id !== data.id);
      });
      setAllData({
        all: all,
        tasks: tasks,
      });
      await new TasksResource().removeOne(data.id);
    } catch {}
  };

  useEffect(() => {
    getInitialData();
  }, [startDate]);

  useEffect(() => {
    getInitialData(false);
  }, [refreshCompletedTasks]);

  const scrollDate: DateTime = nearestDate(completedDays, DateTime.now());

  const scrolledId: string = DateTime.utc(
    scrollDate.year,
    scrollDate.month,
    scrollDate.day
  ).toSQLDate()!;

  const element = document.getElementById(scrolledId);

  useEffect(() => {
    if (element && startDate.month === DateTime.now().month) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [startDate, element]);

  return {
    completedVisibleData,
    nextMonth,
    previousMonth,
    startDate,
    loading,
    setStartDate,
    onPressCompletedTaskCheckbox,
    deleteTask,
  };
};
