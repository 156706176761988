import { palette } from "assets/palette";
import { i18n } from "constants/i18n.constants";
import React, { useEffect, useState } from "react";
import { Text } from "elements/text/Text";
import { useSubscriptionHook } from "./useSubscriptionHook";
import { SubscriptionItem } from "./components/SubscriptionItem";
import { useAppSelector } from "redux-store/store.hooks";
import { PaymentMethods } from "constants/payment-methods.contants";
import { Spinner } from "elements/spinner/Spinner";
import "./PreferencesSubscription.scss";
import { TrialView } from "./components/TrialView";

export type PreferenceSubscriptionProps = {
  handleOpenUnsubscriptionDialog: () => void;
}

export const PreferencesSubscription = ({ handleOpenUnsubscriptionDialog }: PreferenceSubscriptionProps) => {
  const {
    tableHeaderTexts,
    deleteSubscription,
    deleteSubsLoading,
    lastPayment,
    nextPaymentDue,
    isSubscriptionPresent,
    paymentMethodData,
    paymentMethod,
    onChangeBillingCycle,
    isUnsubscribeOpen,
    onRemove,
    noKeep,
    isCanceled,
    onPressUpgradeNow,
    isTrialing,
    startDate,
    endDate,
    progressValue
  } = useSubscriptionHook({ handleOpenUnsubscriptionDialog });

  const { userInfo } = useAppSelector((state) => ({
    userInfo: state.userSetting.userInfo,
  }));

  const [isApple, setIsApple] = useState(false);

  useEffect(() => {
    if (userInfo?.attributes?.is_premium && !lastPayment && !nextPaymentDue) {
      setIsApple(true);
    }
  }, [userInfo]);

  return (
    <div style={styles.container}>
      {!isSubscriptionPresent && isTrialing ? <TrialView upgradeNow={onPressUpgradeNow} startDate={startDate} endDate={endDate} progressValue={progressValue} /> : 
      <>
        <Text style={styles.headerText}>{i18n.t("subscription")}</Text>
        <div style={styles.tableHeader}>
          {tableHeaderTexts.map((item, index) => (<Text style={styles.tableHeaderText} key={index}>{item}</Text>))}
        </div>
        {isSubscriptionPresent ? <SubscriptionItem showUnsubscribe={!isCanceled} lastPayment={lastPayment} nextPayment={nextPaymentDue} paymentMethod={paymentMethodData?.attributes.card.last4} paymentMethodImage={paymentMethod} onChangeBillingCycle={onChangeBillingCycle} onRemove={onRemove} /> :
          isApple ? <SubscriptionItem lastPayment="-" nextPayment="-" paymentMethod={""} paymentMethodImage={PaymentMethods.APPLE} /> : null
        }
        {isUnsubscribeOpen ? <div style={styles.unsubscribeButtonsContainer}>
          <Text style={styles.unsubscribeText}>{i18n.t("are_you_sure_you_want_to_unsubcsribe")}</Text>
          <div style={styles.buttonsContainer}>
            <button className="keep-button" onClick={noKeep}>{deleteSubsLoading ? <Spinner loading={deleteSubsLoading} /> : i18n.t("no_keep")}</button>
            <button className="unsubscribe-button" onClick={deleteSubscription}>{deleteSubsLoading ? <Spinner loading={deleteSubsLoading} /> : i18n.t("yes_unsubscribe")}</button>
          </div>
        </div> : null}
      </>}
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flex: 1,
    alignSelf: "stretch",
    flexDirection: "column",
    overflowY: "scroll",
  },
  unsubscribeButtonsContainer: {
    marginTop: "auto",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 30,
    marginTop: 20,
  },
  headerText: {
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "500",
    fontSize: 18,
    color: palette.black,
  },
  unsubscribeText: {
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "500",
    fontSize: 20,
    color: palette.gray.dark,
    userSelect: "none",
  },
  tableHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: 45,
    backgroundColor: palette.gray.iceLight,
    borderRadius: 7,
    marginTop: 20,
  },
  tableHeaderText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 15,
    color: palette.gray.medium,
    width: "100%",
    textAlign: "center",
  },
};
