import { TaskEntity } from "communicators/resources/event-resources/tasks/tasks.type";
import { DropResult } from "constants/dnd.constants";
import { CustomButton } from "elements/button/Button";
import { ProgressBar } from "elements/progress-bar/ProgressBar";
import { FullScreenSpinner } from "elements/spinner/FullScreenSpinner";
import { DragAndDropHelper } from "helpers/dnd.helper";
import { AllBoardDataEntityType, IBoard } from "interfaces/main.interfaces";
import { debounce } from "lodash";
import { DateTime } from "luxon";
import React from "react";
import { Board } from "../Board/Board";
import { Text } from "elements/text/Text";
import { i18n } from "constants/i18n.constants";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { palette } from "assets/palette";
import { DRAWER_WIDTH } from "pages/private/home/constants";

interface Props {
  loading?: boolean;
  data: IBoard[];
  onDragEnd: (result: DropResult) => void;
  onPressBoardPlus: (date: DateTime) => void;
  onTaskCreate: (
    index: number,
    boardDate: DateTime,
    createdTask: TaskEntity
  ) => void;
  onClickCheckBox: (data: AllBoardDataEntityType) => void;
  deleteTask?: (data: AllBoardDataEntityType) => void;
  progressValue: number;
  goToDashboard: () => void;
  goBack: () => void;
}

export const Main = ({
  loading,
  data,
  onDragEnd,
  onPressBoardPlus,
  onTaskCreate,
  onClickCheckBox,
  deleteTask,
  progressValue,
  goToDashboard,
  goBack,
}: Props) => {
  const onMoveDropableArea = (boardData: IBoard) => {
    if (DragAndDropHelper.isOverOnRightDrawer) {
      return;
    }
    const { board, currentIndex } = DragAndDropHelper.getBoardIdByBoardItemId(
      DragAndDropHelper.draggingEvent?.data.id
    );
    const sourceBoard = board;
    const sourceIndex = currentIndex;
    const destinationBoard = boardData?.date.toSQLDate()!;
    const destinationIndex =
      DragAndDropHelper.getLatestIndexOfBoard(destinationBoard);
    if (!sourceBoard || !destinationBoard) return;
    const result: DropResult = {
      destination: { droppableId: destinationBoard, index: destinationIndex },
      source: { droppableId: sourceBoard, index: sourceIndex },
    };
    onDragEnd(result);
  };
  const onMoveDropableWithDebounce = debounce(onMoveDropableArea, 500);

  return (
    <div style={styles.mainContainer}>
        <div>
            <div style={styles.headerButtonStyle}>
            <Text style={styles.headerTextStyle}>{i18n.t("app_name")}</Text>
            </div>
            <Icon name={IconName.BACK_ARROW} onPress={goBack} style={styles.backButton} />
            <Text style={styles.dragDropTasks}>{i18n.t("drag_and_drop_tasks")}</Text>
            <Text style={styles.dragDropTasksSubheader}>{i18n.t("reserve_time")}</Text>
        </div>
        <div style={styles.container}>
            {data?.map((item, index) => {
                const isLastItem = index === data.length - 1;
                return (
                    <div
                      style={{
                          ...styles.listContainer,
                          marginRight: isLastItem ? 0 : 30,
                      }}
                      key={index}
                    >
                      <Board
                          onMoveDropableArea={onMoveDropableWithDebounce}
                          onDragEnd={onDragEnd}
                          boardData={item}
                          onPressPlus={onPressBoardPlus}
                          onTaskCreate={onTaskCreate}
                          onClickCheckBox={onClickCheckBox}
                          deleteTask={deleteTask} />
                    </div>
                );
            })}
            <FullScreenSpinner loading={loading} />
        </div>
        <div style={styles.progressContainer}>
            <Text style={styles.dailyPlanningProcess}>{i18n.t("daily_planning_process")}</Text>
            <ProgressBar
                style={styles.progress}
                value={progressValue}
                progressProps={{ color: "info" }} />
            <CustomButton style={styles.buttonStyle} text={i18n.t("go_to_dashboard")} onClick={goToDashboard} />
        </div>
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
    mainContainer: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: palette.white,
      },
      container: {
        display: "flex",
        alignSelf: "stretch",
        flex: 1,
        flexDirection: "row",
        marginLeft: 25,
        marginTop: 60,
      },
      listContainer: {
        display: "flex",
        alignSelf: "stretch",
        flex: 1,
        overflow: "hidden",
        overflowY: "scroll",
      },
      headerButtonStyle: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        borderWidth: 0,
      },
      headerTextStyle: {
        fontWeight: "500",
        fontSize: 22,
        marginRight: 5,
      },
      backButton: {
        marginTop: 20,
        marginLeft: 12,
        cursor: "pointer",
      },
      dragDropTasks: {
        fontFamily: "Gilroy-Medium",
        fontWeight: "500",
        fontSize: 30,
        color: palette.black,
        marginTop: 25,
        marginLeft: 25
      },
      dragDropTasksSubheader: {
        fontFamily: "Gilroy-Medium",
        fontWeight: "500",
        fontSize: 22,
        color: palette.gray.dark,
        marginLeft: 25
      },
      today: {
        fontFamily: "Gilroy-Medium",
        fontWeight: "500",
        fontSize: 25,
        color: palette.black,
        marginRight: 20,
      },
      plusIcon: {
        marginTop: 4,
      },
      verticalGap: {
        width: 75
      },
      task: {
        marginLeft: 15
      },
      iconGap: {
        width: 20
      },
      iconContainer: {
        display: "flex",
        flexDirection: "row",
      },
      progressContainer: {
        position: "fixed",
        bottom: 40,
        marginLeft: 20,
      },
      dailyPlanningProcess: {
        fontFamily: "Gilroy-Medium",
        fontWeight: "500",
        fontSize: 10,
        color: palette.gray.medium,
      },
      progress: {
        marginTop: 5,
        marginBottom: 20,
        width: DRAWER_WIDTH - 80,
      },
      buttonStyle: {
        width: 200,
        backgroundColor: palette.black,
        height: 50,
      },
};
