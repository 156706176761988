import { DefaultFirebaseError } from "./default-firebase.error";
import { ExistingAccountError } from "./existing-account.error";
import { FirebaseAPIError } from "./firebase.error";
import { InvalidEmailError } from "./invalid-email.error";
import { ErrorCodes } from "./type";
import { UserNotFoundError } from "./user-not-found.error";
import { WrongEmailError } from "./wrong-email.error";
import { WrongPasswordError } from "./wrong-password.error";

export const FIREBASE_ERRORS: Record<string, typeof FirebaseAPIError> = {
  [ErrorCodes.INVALID_EMAIL]: InvalidEmailError,
  [ErrorCodes.WRONG_EMAIL]: WrongEmailError,
  [ErrorCodes.WRONG_PASSWORD]: WrongPasswordError,
  [ErrorCodes.ALREADY_IN_USE]: ExistingAccountError,
  [ErrorCodes.USER_NOT_FOUND]: UserNotFoundError,
  [ErrorCodes.INVALID_RECIPENT]: InvalidEmailError,
  DEFAULT: DefaultFirebaseError,
};
