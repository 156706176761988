import { ONBOARDING_INTEGRATION_PAGE_COUNT } from "constants/onboarding.constants";
import { IBoard } from "interfaces/main.interfaces";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getInitialData } from "helpers/common.helper";
import { usePageHook } from "hooks/usePageHook";

const PAGE_NUMBER = 2;

export const useAddFirstTask = () => {
  const [secondDrawerOpened, setSecondDrawerOpened] = React.useState<{
    type: string | null;
    opened: boolean;
  }>({
    type: null,
    opened: false,
  });

  const [newTaskTitle, setNewTaskTitle] = React.useState("");
  const [isPlusIconClicked, setIsPlusIconClicked] = React.useState(false);
  const [todaysTasks, setTodaysTasks] = React.useState<IBoard>();

  const navigate = useNavigate();

  const closeSecondDrawer = () => {
    setSecondDrawerOpened({ type: null, opened: false });
  };

  const onClickPlusIcon = () => {
    setIsPlusIconClicked(true);
  };

  const openSecondDrawer = (type: string) => {
    if (type === secondDrawerOpened.type) {
      closeSecondDrawer();
      return;
    }
    setSecondDrawerOpened({ type, opened: true });
  };

  const goBack = () => {
    navigate(-1);
  };

  const goToAddFirstTaskDetail = () => {
      navigate("/add-first-task-detail", { state: newTaskTitle });
  };

  useEffect(() => {
    getInitialData(setTodaysTasks);
  }, []);

  const progressValue = (PAGE_NUMBER / ONBOARDING_INTEGRATION_PAGE_COUNT) * 100;

  return {
    openSecondDrawer,
    secondDrawerOpened,
    progressValue,
    newTaskTitle,
    setNewTaskTitle,
    isPlusIconClicked,
    onClickPlusIcon,
    goBack,
    todaysTasks,
    goToAddFirstTaskDetail,
  };
};
