import { palette } from "assets/palette";
import { i18n } from "constants/i18n.constants";
import { CustomButton } from "elements/button/Button";
import React from "react";
import { Text } from "elements/text/Text";
import { usePreferencesPaymentMethodHook } from "./usePreferencesPaymentMethodHook";
import "./PreferencesPaymentMethod.scss";
import { AddPaymentMethodItem } from "./components/AddPaymentMethodItem/AddPaymentMethodItem";
import { PaymentMethodItem } from "./components/PaymentMethodItem/PaymentMethodItem";
import { Spinner } from "elements/spinner/Spinner";
import { PaymentMethods } from "constants/payment-methods.contants";
import { paymentMethodSelector } from "helpers/common.helper";

export const PreferencesPaymentMethod = () => {
  const {
    defaultPaymentMethod,
    nonDefaultPaymentMethods,
    onClickAddPaymentMethod,
    loading,
    setPrimary,
    isAppleHistory,
    removeCard,
  } = usePreferencesPaymentMethodHook();
  return (
    <div className="preferences-payment-method-container">
      <Text style={styles.headerText}>{i18n.t("payment_method")}</Text>
      {loading ? (
        <div style={styles.spinner}>
          <Spinner loading={loading} />
        </div>
      ) : (
        <div className="payment-methods-container">
          {defaultPaymentMethod !== undefined &&
          defaultPaymentMethod.length !== 0 ? (
            <PaymentMethodItem
              name={defaultPaymentMethod[0]?.attributes.billing_details?.name!}
              last4CardNumber={defaultPaymentMethod[0]?.attributes?.card?.last4}
              cardExpiryDate={`${defaultPaymentMethod[0]?.attributes?.card?.exp_month
                .toString()
                .padStart(
                  2,
                  "0"
                )}/${defaultPaymentMethod[0]?.attributes?.card?.exp_year
                .toString()
                .slice(-2)}`}
              paymentMethodCardImage={paymentMethodSelector(
                defaultPaymentMethod[0]?.attributes?.card?.brand
              )}
              isDefaultCard
              setPrimary={() => {}}
            />
          ) : null}
          {nonDefaultPaymentMethods?.map((item) => {
            return (
              <PaymentMethodItem
                name={item?.attributes?.billing_details?.name!}
                last4CardNumber={item?.attributes?.card?.last4}
                cardExpiryDate={`${item?.attributes?.card?.exp_month
                  .toString()
                  .padStart(2, "0")}/${item?.attributes?.card?.exp_year
                  .toString()
                  .slice(-2)}`}
                paymentMethodCardImage={paymentMethodSelector(
                  item?.attributes?.card?.brand
                )}
                isDefaultCard={false}
                setPrimary={() => setPrimary(item?.attributes?.id)}
                removeCard={() => removeCard(item?.attributes?.id)}
              />
            );
          })}
          {isAppleHistory && (
            <PaymentMethodItem
              name={PaymentMethods.APPLE}
              last4CardNumber=""
              cardExpiryDate=""
              paymentMethodCardImage={PaymentMethods.APPLE}
              isDefaultCard={false}
            />
          )}
          {!isAppleHistory ? (
            <AddPaymentMethodItem
              onClickAddPaymentMethod={onClickAddPaymentMethod}
            />
          ) : null}
        </div>
      )}
      {!loading && !isAppleHistory ? (
        <CustomButton
          style={styles.addPaymentMethodButton}
          text={i18n.t("add_payment_method")}
          textStyle={styles.addPaymentMethodButtonText}
          onClick={onClickAddPaymentMethod}
          loading={loading}
          spinnerColor="primary"
        />
      ) : null}
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  headerText: {
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "500",
    fontSize: 18,
    color: palette.black,
  },
  addPaymentMethodButton: {
    width: "100%",
    backgroundColor: palette.black,
    height: 50,
    marginBottom: 50,
    marginTop: "30px",
  },
  addPaymentMethodButtonText: {
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "500",
    fontSize: 15,
    color: palette.white,
  },
  spinner: {
    display: "flex",
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 40,
  },
};
