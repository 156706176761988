import { ErrorMessages, ErrorNames } from "./type";

export class InsumoAPIError extends Error {
  name: ErrorNames | string = "";
  message: ErrorMessages | string = "";
  status!: number;

  constructor(message?: string) {
    super();
    if(message){
      this.message = message;
    }
  }
}
