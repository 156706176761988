import { ErrorMessages, ErrorNames } from "./type";

export class DefaultAPIError extends Error {
	name = ErrorNames.DEFAULT;
	message = ErrorMessages.DEFAULT;

	constructor(public status: number) {
		super();
	}
}
