import { EventType } from "communicators/resources/event-resources/event-resources.type";
import { DropResult } from "constants/dnd.constants";
import { i18n } from "constants/i18n.constants";
import { DragAndDropHelper } from "helpers/dnd.helper";
import {
  AllBoardDataEntityType,
  IBoard,
  ThirdPartyProvider,
} from "interfaces/main.interfaces";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useAppSelector } from "redux-store/store.hooks";

interface Props {
  boardData: IBoard;
  onDragEnd: (result: DropResult) => void;
  onPressPlus: (date: DateTime) => void;
  isShown?: boolean;
  checkedDataId?: string;
}

export const useBoardHook = ({
  boardData,
  checkedDataId,
  isShown,
  onDragEnd,
  onPressPlus,
}: Props) => {
  const [selectedDate, setSelectedDate] = useState<DateTime>(DateTime.now());

  const scrolledList = document.getElementById(selectedDate.toString());

  const { asanaExist, todoistExist } = useAppSelector((state) => ({
    todoistExist: !!state.thirdPartyProviderData.todoistId,
    asanaExist: !!state.thirdPartyProviderData.asanaId,
  }));

  const { selectedTagsData } = useAppSelector((state) => ({
    selectedTagsData: state.tags.selectedTagsData,
  }));

  useEffect(() => {
    scrolledList?.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, [onPressPlus, scrolledList]);

  const onPressPlusToAddTask = () => {
    onPressPlus(boardData.date);
    setSelectedDate(boardData.date);
  };

  const getTitle = () => {
    const today = DateTime.now();
    if (boardData.date.toSQLDate() === today.toSQLDate()) {
      return i18n.t("today");
    } else if (
      boardData.date.toSQLDate() === today.plus({ day: 1 }).toSQLDate()
    ) {
      return i18n.t("tomorrow");
    }
    return boardData.date.toFormat("cccc");
  };

  const move = (dragIndex: number, hoverIndex: number) => {
    if (
      !DragAndDropHelper.draggingEvent?.data?.id ||
      DragAndDropHelper.isOverOnRightDrawer
    ) {
      return;
    }
    const { board } = DragAndDropHelper.getBoardIdByBoardItemId(
      DragAndDropHelper.draggingEvent?.data.id
    );
    const sourceBoard = board;
    const sourceIndex = dragIndex;
    const destinationBoard = boardData.date.toSQLDate();
    const destinationIndex = hoverIndex;
    if (!destinationBoard) return;
    const result: DropResult = {
      destination: { droppableId: destinationBoard, index: destinationIndex },
      source: { droppableId: sourceBoard, index: sourceIndex },
    };
    onDragEnd(result);
  };

  const isItemShown = (item: AllBoardDataEntityType) => {
    if (
      item?.type === EventType.TASK &&
      item.attributes.provider === ThirdPartyProvider.TODOIST &&
      !todoistExist
    ) {
      return false;
    }
    if (
      item?.type === EventType.TASK &&
      item.attributes.provider === ThirdPartyProvider.ASANA &&
      !asanaExist
    ) {
      return false;
    }

    if (selectedTagsData.length !== 0 && item.type === EventType.TASK) {
      return item.attributes.task_tags?.some((item) => selectedTagsData.includes(item.tag.id));
    }

    if (
      item &&
      item.type === EventType.TASK &&
      item.attributes.completed_count === 0
    )
      return true;

    if (
      isShown &&
      checkedDataId === item.id &&
      item &&
      item.type === EventType.TASK &&
      item.attributes.completed_count !== 0
    ) {
      return true;
    }

    if (item && item.type === EventType.MEET && !item.attributes.task_id) {
      return true;
    }

    return false;
  };

  return { getTitle, move, onPressPlusToAddTask, isItemShown };
};
