import {
  setThirdPartyDataAction,
  setThirdPartyProviderDataAction,
} from "../types/third-party-provider.action.type";
import { Dispatch } from "redux";
import { ThirdPartyProviderDispatchTypes } from "redux-store/types/dispatch.type";
import { ThirdPartyInformationResource } from "communicators/resources/third-party-information/third-party-information.resource";

export const setThirdPartyProviderData = (onLoad?: () => void) => {
  return async (dispatch: Dispatch): Promise<void> => {
    const { data } = await new ThirdPartyInformationResource().readMany();
    const payload = {
      asanaPayload: "",
      todoistPayload: "",
      googlePayload: "",
    };
    data.forEach(({ provider, id }) => {
      if (!data || data.length === 0) return;
      if (provider === "asana") {
        payload.asanaPayload = id;
      } else if (provider === "todoist") {
        payload.todoistPayload = id;
      } else if (provider === "google") {
        payload.googlePayload = id;
      }
    });
    if (!payload.asanaPayload) payload.asanaPayload = "";
    if (!payload.googlePayload) payload.googlePayload = "";
    if (!payload.todoistPayload) payload.todoistPayload = "";
    onLoad && onLoad();
    dispatch<setThirdPartyDataAction>({
      type: ThirdPartyProviderDispatchTypes.setThirdPartyData,
      payload: data,
    });
    dispatch<setThirdPartyProviderDataAction>({
      type: ThirdPartyProviderDispatchTypes.setThirdPartyProviderData,
      payload: payload,
    });
  };
};
