interface CodeInputProps {
    handleCode: (ev: React.ChangeEvent<HTMLInputElement>, value: string, index: number) => void;
    handleKey: (ev: React.KeyboardEvent<HTMLInputElement>, index: number) => void;
    char: string;
    index: number;
    maxLength: number
}

export const VerificationCodeInput = ({handleCode, handleKey, char, index, maxLength}: CodeInputProps) => {
    const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const pattern = /^\d*$/;
        const target = ev.currentTarget as HTMLInputElement;
        const isValidChar = pattern.test(target.value);

        if(!isValidChar) return;

        handleCode(ev, target.value, index);
    }
    const handleFocus = (ev: React.FocusEvent<HTMLInputElement>) => {
        (ev.currentTarget as HTMLInputElement).select();
    }

    return (
        <input 
            type="text" 
            inputMode="numeric" 
            autoComplete="one-time-code"
            onChange={handleChange}
            onKeyDown={(ev) => handleKey(ev, index)}
            value={char}
            onFocus={handleFocus}
            maxLength={maxLength}
         />
    )
}