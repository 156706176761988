import { SomethingWentWrongImage } from "assets/images";
import { i18n } from "constants/i18n.constants";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import "./SomethingWentWrong.scss";

interface Props {
    closeModal?: () => void;
}

export const SomethingWentWrong = ({ closeModal }: Props) => {
    return (
        <div className="something-went-wrong-backdrop">
            <section className="something-went-wrong">
                <div className="something-went-wrong-close-icon">
                    <Icon name={IconName.CLOSE} onPress={closeModal} />
                </div>
                <div className="something-went-wrong-icon-container">
                    <img className="something-went-wrong-image" src={SomethingWentWrongImage} alt="sth-went-wrong" />
                </div>
                <div className="something-went-wrong-title">{i18n.t("oops_something_went_wrong")}</div>
                <div className="something-went-wrong-text">{i18n.t("something_went_wrong_text")}</div>
            </section>
        </div>
    );
};
