import { TimeBox } from "../TimeBox/TimeBox";
import { IEvent, TimelineProps } from "../../types";
import { useTimelineHook } from "./useTimelineHook";
import "./Timeline.scss";
import { useEffect, useState } from "react";
import { isItemMagicPlanner } from "helpers/utils.helper";

export const Timeline = ({
  boardData,
  startDate,
  updateTaskWithRightDrawerDrop,
  insumoCalendarEventData,
  isLoading,
  onHoverRightDrawer,
  magicPlannerState,
}: TimelineProps) => {
  const { timeline, handleDraging, onDrop, onHoverTimeBox, events } =
    useTimelineHook({
      boardData,
      startDate,
      updateTaskWithRightDrawerDrop,
      insumoCalendarEventData,
      isLoading,
      onHoverRightDrawer,
    });
  const [data, setData] = useState<IEvent[]>([]);
  useEffect(() => {
    const filtered = events.filter((item) => !isItemMagicPlanner(item.data));
    setData(filtered);
  }, [events, magicPlannerState]);
  const filteredEvents = !magicPlannerState ? data : events;

  return (
    <div className="timeline-container Windows">
      {timeline.map((item, index) => {
        const exact = item.time.minute === 0;
        const showItem = index === 30;
        return (
          <div id={`${index}`} key={index}>
            <TimeBox
              onDrop={(item) => onDrop(item)}
              key={item.time.toISO()}
              exact={exact}
              item={item}
              showBoardItem={showItem}
              events={filteredEvents}
              handleOnDraging={handleDraging}
              onHoverTimeBox={() => onHoverTimeBox(item)}
            />
          </div>
        );
      })}
    </div>
  );
};
