import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import { Splash } from "pages/public";
import { privateRoutes, publichRoutes } from "./routes";
import { useAppSelector } from "redux-store/store.hooks";
import { useGoogleSignInHook } from "hooks/useGoogleSignInHook";

const PrivateRoute = ({ children }: any) => {
  const isLoggedIn = useAppSelector(
    (state) => !!state.userSetting.firebaseUuid
  );
  return isLoggedIn ? children : <Navigate to="/" />;
};

export const RouteProvider = () => {
  const { login, googleAuthLoading } = useGoogleSignInHook({});
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Splash />} />
          {publichRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={<route.component />}
            />
          ))}
          {privateRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <PrivateRoute>
                  {route.hasGoogleSignIn ? (
                    <route.component
                      googleSignIn={login}
                      googleAuthLoading={googleAuthLoading}
                    />
                  ) : (
                    <route.component />
                  )}
                </PrivateRoute>
              }
            />
          ))}
        </Routes>
      </Router>
    </div>
  );
};
