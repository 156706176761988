import { PaymentMethodResource } from "communicators/resources/payment-method/payment-method.resource";
import { PaymentMethodEntity } from "communicators/resources/payment-method/payment-method.type";
import { SubscriptionPlansResource } from "communicators/resources/subscription-plans/subscription-plans.resource";
import { SubscriptionPlanEntity } from "communicators/resources/subscription-plans/subscription-plans.type";
import { SubscriptionsResource } from "communicators/resources/subscription/subscription.resource";
import { SubscriptionPlan } from "components/subscription-period-selection/SubscriptionPeriodSelection.type";
import { useEffect, useState } from "react";
import { setPreferencesTab } from "redux-store/actions/home-page.actions";
import { setSubscriptions } from "redux-store/actions/user-settings.action";
import { useAppDispatch, useAppSelector } from "redux-store/store.hooks";
import { BillingCycle } from "../PreferencesChangeBillingCycle/useChangeBillingCycleHook";
import { PreferenceTab } from "../PreferencesDialog/PreferenceDialog.types";
  
export const useCompleteYourPurchase = () => {
  const dispatch = useAppDispatch();
  const [paymentMethodData, setPaymentMethodData] = useState<PaymentMethodEntity[]>([]);
  const [isAddPaymentPressed, setIsAddPaymentPressed] = useState<boolean>(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState<SubscriptionPlanEntity[]>([]);

  const getSubscriptionPlans = async () => {
    const subscriptionPlansData = await new SubscriptionPlansResource().readMany();
    setSubscriptionPlans(subscriptionPlansData.data);
  };
  const getPaymentMethodData = async () => {
    const { data } = await new PaymentMethodResource().readMany();
    setPaymentMethodData(data);
  }
  const defaultPaymentMethod = paymentMethodData?.filter((item) => (item.attributes.card.default));

  const toggleAddPayment = () => {
    setIsAddPaymentPressed(true);
  }
  const { subscriptions } = useAppSelector((state) => ({
    subscriptions: state.userSetting.subscriptions
  }));
  const activeSubscription = subscriptions.filter((item) => (item.attributes.status === "active"));

  const goBack = () => {
    if (isAddPaymentPressed) {
      setIsAddPaymentPressed(false);
    } else {
      dispatch(setPreferencesTab(PreferenceTab.CHANGE_BILLING_CYCLE));
    }
  }

  const setSubscriptionInfo = async () => {
    try {
      const response = await new SubscriptionsResource().readMany();
      dispatch(setSubscriptions(response.data));
    } catch (_) {}
  };

  const onPressSubmitPurchase = async () => {
    await new SubscriptionsResource().updateOnePartial(activeSubscription[0].id, {
      subscription: {
        plan: SubscriptionPlan.ANNUALLY,
      },
    });
    setSubscriptionInfo();
    dispatch(setPreferencesTab(PreferenceTab.SUBSCRIPTION));
  }

  useEffect(() => {
    getPaymentMethodData();
    setSubscriptionInfo();
    getSubscriptionPlans();
  },[]);

  const yearlySubscription = subscriptionPlans?.filter((item) => (item.attributes.interval === BillingCycle.YEARLY));

  const yearlyPrice = yearlySubscription[0]?.attributes?.price / 100;

  return {
    yearlyPrice,
    goBack,
    defaultPaymentMethod,
    toggleAddPayment,
    isAddPaymentPressed,
    activeSubscription,
    onPressSubmitPurchase
  };
};