import { EventType } from "communicators/resources/event-resources/event-resources.type";
import { MeetEntity } from "communicators/resources/event-resources/meets/meets.type";
import { TaskEntity } from "communicators/resources/event-resources/tasks/tasks.type";
import { InsumoCalendarEvent } from "communicators/resources/insumo-calendar-events/insumo-calendar-events.type";
import { DropResult } from "constants/dnd.constants";
import { AllBoardDataEntityType, IBoard } from "interfaces/main.interfaces";
import { DateTime } from "luxon";
import { AllBoardDataType } from "pages/private/onboarding-dnd/useOnboardingDndHook";

export interface IInsumoCalendar {
  title: string;
  date: DateTime;
  data: InsumoCalendarEvent[];
}

export const convertBoardDataToUsableFormat = (
  data: Record<string, AllBoardDataEntityType[]>
) => {
  const result: IBoard[] = [];
  Object.keys(data).forEach((date) => {
    result.push({
      date: DateTime.fromSQL(date),
      data: data[date],
      title: DateTime.fromSQL(date).toFormat("LLLL dd ccc"),
    });
  });
  return result;
};

export const separateBoardEventsByDate = (
  data: AllBoardDataEntityType[],
  initialData: Record<string, AllBoardDataEntityType[]>
): Record<string, AllBoardDataEntityType[]> => {
  const result: Record<string, AllBoardDataEntityType[]> = { ...initialData };
  data.forEach((dataObject) => {
    if (dataObject.type === EventType.MEET) {
      const meetDate = getMeetDate(dataObject).toSQLDate();
      if (!meetDate) {
        return;
      }
      const meetsOfObjectByDate = result[meetDate];
      if (!result[meetDate]) return;
      result[meetDate] = !meetsOfObjectByDate
        ? [dataObject]
        : [...meetsOfObjectByDate, dataObject];
    } else if (dataObject.type === EventType.HABIT_TARGET) {
      const habitTargetsOfObjectByDate = result[dataObject.attributes.date];
      if (!result[dataObject.attributes.date]) return;
      if (!dataObject.attributes.date) {
        return;
      }
      result[dataObject.attributes.date] = !habitTargetsOfObjectByDate
        ? [dataObject]
        : [...habitTargetsOfObjectByDate, dataObject];
    } else if (dataObject.type === EventType.TASK) {
      const taskDate = getTaskDate(dataObject).toSQLDate();
      if (!taskDate) {
        return;
      }
      const tasksOfObjectByDate = result[taskDate];
      if (!result[taskDate]) return;
      result[taskDate] = !tasksOfObjectByDate
        ? [dataObject]
        : [...tasksOfObjectByDate, dataObject];
    }
  });

  return result;
};

export const getInitialDateAndBoardItems = (
  startDate: DateTime,
  additionalDay: number
): Record<string, AllBoardDataEntityType[]> => {
  const result: Record<string, AllBoardDataEntityType[]> = {};
  let currentDate = startDate;
  for (let i = 0; i < additionalDay; i++) {
    if (currentDate?.toSQLDate()) {
      result[String(currentDate.toSQLDate())] = [];
      currentDate = currentDate.plus({ day: 1 });
    }
  }
  return result;
};

export const getTaskDate = (dataObject: TaskEntity) => {
  return dataObject.attributes.scheduled_time
    ? DateTime.fromISO(dataObject.attributes.scheduled_time)
    : DateTime.fromSQL(dataObject.attributes.due_date);
};

export const getMeetDate = (dataObject: MeetEntity) => {
  return DateTime.fromISO(dataObject.attributes.meet_start_time);
};

export const getAllBoardData = (
  data: AllBoardDataEntityType[],
  startDate: DateTime,
  isDnd: boolean,
  dayCount?: number
): IBoard[] => {
  const theDayCount = isDnd ? 2 : dayCount || 7;
  const y = getInitialDateAndBoardItems(startDate, isDnd ? 2 : theDayCount);
  const x = separateBoardEventsByDate([...data], y);
  return convertBoardDataToUsableFormat(x);
};

export const getSomedayData = (data: AllBoardDataEntityType[]): IBoard[] => {
  let y = getInitialDateAndBoardItems(DateTime.utc(3000, 1, 1, 0, 0, 0, 0), 1);
  let x = separateBoardEventsByDate([...data], y);
  return convertBoardDataToUsableFormat(x);
};

export const changeBoardDataByDate = (
  newList: AllBoardDataEntityType[],
  boardData: IBoard[],
  boardDate: string
) => {
  for (let i = 0; i < boardData.length; i++) {
    if (boardData[i].date.toSQLDate() === boardDate) {
      boardData[i].data = newList;
      break;
    }
  }
};

export const reorder = (
  result: DropResult,
  boardData: IBoard[],
  item?: AllBoardDataEntityType
):
  | {
      copyBoard: IBoard[];
      removed?: undefined;
    }
  | {
      copyBoard: IBoard[];
      removed: AllBoardDataEntityType | null;
    } => {
  const copyBoard = [...boardData];
  if (!result.destination) return { copyBoard };
  let removed: AllBoardDataEntityType | null = null;
  if (result.destination.droppableId === result.source.droppableId) {
    const droppableBoard = copyBoard.find(
      (board) => board.date.toSQLDate() === result.destination?.droppableId
    );
    if (!droppableBoard) return { copyBoard };
    const list = droppableBoard.data;
    [removed] = list.splice(result.source.index, 1);
    list.splice(result.destination.index, 0, removed);
    changeBoardDataByDate(list, copyBoard, result.destination.droppableId);
  } else {
    const sourceBoard = copyBoard.find(
      (board) => board.date.toSQLDate() === result.source?.droppableId
    );
    const destinationBoard = copyBoard.find(
      (board) => board.date.toSQLDate() === result.destination?.droppableId
    );
    if (!destinationBoard) return { copyBoard };
    if (!sourceBoard) {
      if (!item) return { copyBoard };
      const destinationList = destinationBoard.data;
      destinationList.splice(result.destination.index, 0, item);
      changeBoardDataByDate(
        destinationList,
        copyBoard,
        result.destination.droppableId
      );
    } else {
      const sourceList = sourceBoard.data;
      const destinationList = destinationBoard.data;
      [removed] = sourceList.splice(result.source.index, 1);
      destinationList.splice(result.destination.index, 0, removed);
      changeBoardDataByDate(sourceList, copyBoard, result.source.droppableId!);
      changeBoardDataByDate(
        destinationList,
        copyBoard,
        result.destination.droppableId
      );
    }
  }

  return { copyBoard, removed };
};

export const getMeetTimeRange = (meet: MeetEntity) => {
  return `${DateTime.fromISO(meet.attributes.meet_start_time).toLocaleString(
    DateTime.TIME_SIMPLE
  )} - ${DateTime.fromISO(meet.attributes.meet_end_time).toLocaleString(
    DateTime.TIME_SIMPLE
  )}`;
};

export const getTaskTimeRange = (taskTarget: TaskEntity) => {
  if (taskTarget.attributes.start_time && taskTarget.attributes.end_time) {
    return `${DateTime.fromSeconds(Number(taskTarget.attributes.start_time))
      .toUTC()
      .toLocaleString(DateTime.TIME_SIMPLE)} - ${DateTime.fromSeconds(
      Number(taskTarget.attributes.end_time)
    )
      .toUTC()
      .toLocaleString(DateTime.TIME_SIMPLE)}`;
  } else if (taskTarget.attributes.start_time) {
    return `${DateTime.fromSeconds(Number(taskTarget.attributes.start_time))
      .toUTC()
      .toLocaleString(DateTime.TIME_SIMPLE)}`;
  } else if (taskTarget.attributes.end_time) {
    return `${DateTime.fromSeconds(Number(taskTarget.attributes.end_time))
      .toUTC()
      .toLocaleString(DateTime.TIME_SIMPLE)}`;
  }
  return "";
};

export const convertInsumoCalendarEventDataToUsableFormat = (
  data: Record<string, InsumoCalendarEvent[]>
) => {
  const result: IInsumoCalendar[] = [];
  Object.keys(data).forEach((date) => {
    result.push({
      date: DateTime.fromSQL(date),
      data: data[date],
      title: DateTime.fromSQL(date).toFormat("LLLL dd ccc"),
    });
  });
  return result;
};

export const getInsumoCalendarEventDate = (dataObject: InsumoCalendarEvent) => {
  return DateTime.fromISO(dataObject.attributes.start_date);
};

export const separateInsumoCalendarEventsByDate = (
  data: InsumoCalendarEvent[],
  initialData?: Record<string, InsumoCalendarEvent[]>
): Record<string, InsumoCalendarEvent[]> => {
  const result: Record<string, InsumoCalendarEvent[]> = initialData || {};
  data.forEach((dataObject) => {
    const taskDate = getInsumoCalendarEventDate(dataObject).toSQLDate();
    if (!taskDate) {
      return;
    }
    const tasksOfObjectByDate = result[taskDate];
    result[taskDate] = !tasksOfObjectByDate
      ? [dataObject]
      : [...tasksOfObjectByDate, dataObject];
  });
  return result;
};

export const getInitialDateAndInsumoCalendarEventItems = (
  startDate: DateTime,
  additionalDay: number
): Record<string, InsumoCalendarEvent[]> => {
  const result: Record<string, InsumoCalendarEvent[]> = {};
  let currentDate = startDate;
  for (let i = 0; i < additionalDay; i++) {
    if (!currentDate || !currentDate.toSQLDate()) {
      continue;
    }
    result[String(currentDate.toSQLDate())] = [];
    currentDate = currentDate.plus({ day: 1 });
  }
  return result;
};

export const getAllInsumoCalendarEventData = (
  data: InsumoCalendarEvent[],
  startDate: DateTime
): IInsumoCalendar[] => {
  let y = getInitialDateAndInsumoCalendarEventItems(startDate, 7);
  let x = separateInsumoCalendarEventsByDate([...data], y);
  return convertInsumoCalendarEventDataToUsableFormat(x);
};

export const removeCompletedDataFromAllBoard = (data: AllBoardDataType) => {
  const all = [...data?.all];
  const tasks = [...data?.tasks];
  const meets = data?.meets;
  all.forEach(
    (boardData) =>
      (boardData.data = boardData?.data.filter(
        (item) =>
          (item?.type === EventType.TASK &&
            item?.attributes?.completed_count === 0) ||
          item?.type === EventType.MEET
      ))
  );
  tasks.forEach(
    (boardData) =>
      (boardData.data = boardData?.data.filter(
        (item) =>
          (item?.type === EventType.TASK &&
            item?.attributes?.completed_count === 0) ||
          item?.type === EventType.MEET
      ))
  );
  return { all, meets, tasks };
};
