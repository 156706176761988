import { LoginWrapper } from "components/login-wrapper/LoginWrapper";
import { i18n } from "constants/i18n.constants";
import { CustomButton } from "elements/button/Button";
import React from "react";
import { CustomTextField } from "elements/text-field/TextField";
import { palette } from "assets/palette";
import { useForgotPasswordHook } from "./useForgotPasswordHook";
import { usePageHook } from "hooks/usePageHook";

export const ForgotPassword = () => {
  const { email, loading, error, setEmail, sendVerification } =
    useForgotPasswordHook();

  const validateEmail = (email: string): boolean => {
    const validation: any = email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return validation;
  };
  usePageHook();

  return (
    <LoginWrapper showSignUpText title={i18n.t("forgot_your_password")}>
      <CustomTextField
        label={i18n.t("email")}
        placeholder={i18n.t("enter_email")}
        value={email}
        autoComplete="email"
        onChangeText={setEmail}
        errorText={error}
        classProp="custom-style"
      />

      <CustomButton
        backgroundColor={palette.black}
        spinnerColor="secondary"
        fullWidth
        text={i18n.t("send_reset_link")}
        onPress={sendVerification}
        loading={loading}
        disabled={!validateEmail(email)}
      />
    </LoginWrapper>
  );
};
