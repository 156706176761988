import { setPreferencesTab } from "redux-store/actions/home-page.actions";
import { useAppDispatch } from "redux-store/store.hooks";
import { PreferenceTab } from "../PreferencesDialog/PreferenceDialog.types"; 

export const useAddPaymentMethodHook = () => {
  const dispatch = useAppDispatch();

  const goBack = () => {
    dispatch(setPreferencesTab(PreferenceTab.PAYMENT_METHOD));
  }

  return {
    goBack,
  };
};