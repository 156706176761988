import { ProfileResource } from "communicators/resources/profile/profile.resource";
import { FirebaseService } from "communicators/services/firebase/Firebase.service";
import { i18n } from "constants/i18n.constants";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setFirebaseUuid,
  setUserInfo,
} from "redux-store/actions/user-settings.action";
import { useAppDispatch, useAppSelector } from "redux-store/store.hooks";

export const useChangePhoneNumberHook = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const emptyCode = Array(6).fill("");
  const [code, setCode] = useState(emptyCode);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector((state) => ({
    userInfo: state.userSetting.userInfo,
  }));

  const phoneNumber: string = location.state.phoneNumber;
  const verificationId: string | null = location.state.verificationId;

  const onPressSubmit = async () => {
    if (!verificationId) return;
    setLoading(true);
    try {
      const joinedCode = code.join("");
      const confirmationResult =
        await new FirebaseService().linkPhoneNumberToUser(
          verificationId,
          joinedCode
        );
      if (confirmationResult?.error || !confirmationResult?.result) {
        setError(
          confirmationResult?.message || i18n.t("invalid_verification_code")
        );
        setLoading(false);
        return;
      }

      dispatch(setFirebaseUuid(confirmationResult.result.user.uid));
      if (userInfo) {
        const response = await new ProfileResource().updateAndReadOne(
          userInfo?.attributes.firebase_uuid,
          {
            user: {
              email: userInfo.attributes.email,
              mobile_phone: location.state.phoneNumber,
              full_name: userInfo.attributes.full_name,
              onboarding_completed: userInfo.attributes?.onboarding_completed,
            },
          }
        );
        const user = response?.data;
        if (user) {
          dispatch(setUserInfo(user));
        }
        navigate("/home", { replace: true });
      } else {
        setError(i18n.t("invalid_verification_code"));
      }
    } catch (error) {
      setError(i18n.t("invalid_verification_code"));
    }
    setLoading(false);
  };

  const phoneNumberModifier = () => {
    const last10digits = phoneNumber.slice(-10).replace(/[^\d]/g, "");
    const countryCode = phoneNumber.slice(0, -10);
    const yy = last10digits.replace(
      /(\d{3})(\d{3})(\d{2})(\d{2})/,
      "$1 $2 $3 $4"
    );
    return countryCode + " " + yy;
  };

  useEffect(() => {
    phoneNumberModifier();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loading, error, onPressSubmit, code, setCode, phoneNumberModifier };
};
