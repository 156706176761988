import { FirebaseService } from "communicators/services/firebase/Firebase.service";
import { LoginOptions } from "communicators/services/firebase/type";
import {
  createAndGetUser,
  getUserRedirectionAfterLogin,
} from "helpers/auth.helper";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { setThirdPartyProviderData } from "redux-store/actions/third-party-provider.actions";
import { setFirebaseUuid } from "redux-store/actions/user-settings.action";
import { useAppDispatch } from "redux-store/store.hooks";

const URLS = {
  TERMS_OF_USE: "https://www.insumo.io/termsofuse",
  PRIVACY_POLICY: "https://www.insumo.io/privacypolicy",
};

export const useLoginWrapperHook = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onPressGoogleSignIn = async () => {
    const firebaseUser = await new FirebaseService().login(LoginOptions.GOOGLE);
    if (!firebaseUser) return;
    setLoading(true);
    const idToken = await firebaseUser.user.getIdToken();
    const { isNewUser, user } = await createAndGetUser({
      firebase_uuid: firebaseUser.user.uid,
      email: firebaseUser.user.email || undefined,
      full_name: firebaseUser.user.displayName || undefined,
      mobile_phone: firebaseUser.user.phoneNumber || undefined,
      idToken,
    });
    dispatch(setFirebaseUuid(firebaseUser.user.uid));
    dispatch(setThirdPartyProviderData());
    navigate(await getUserRedirectionAfterLogin(isNewUser, user), await getUserRedirectionAfterLogin(isNewUser, user) === "/home" ? { replace: true } : undefined);
    setLoading(false);
  };

  const onPressAppleLogin = async () => {
    const firebaseUser = await new FirebaseService().login(LoginOptions.APPLE);
    if (!firebaseUser) return;
    setLoading(true);
    const idToken = await firebaseUser.user.getIdToken();
    const { isNewUser, user } = await createAndGetUser({
      firebase_uuid: firebaseUser.user.uid,
      email: firebaseUser.user.email || undefined,
      full_name: undefined,
      mobile_phone: undefined,
      idToken,
    });
    dispatch(setFirebaseUuid(firebaseUser.user.uid));
    dispatch(setThirdPartyProviderData());
    navigate(await getUserRedirectionAfterLogin(isNewUser, user), await getUserRedirectionAfterLogin(isNewUser, user) === "/home" ? { replace: true } : undefined);
    setLoading(false);
  };

  const onPressPhoneLogin = () => {
    navigate("/phone-login");
  };

  const onPressSignUp = () => {
    navigate("/welcome");
  };

  const onPressLogin = () => {
    navigate("/login");
  };

  const onPressForgotPassword = () => {
    navigate("/forgot-password");
  };

  const onPressTermsOfUse = () => {
    window.open(URLS.TERMS_OF_USE);
  };

  const onPressPrivacyPolicy = () => {
    window.open(URLS.PRIVACY_POLICY);
  };

  const goBack = () => {
    navigate(-1);
  };

  return {
    onPressGoogleSignIn,
    onPressAppleLogin,
    onPressTermsOfUse,
    onPressPrivacyPolicy,
    onPressPhoneLogin,
    onPressSignUp,
    onPressLogin,
    onPressForgotPassword,
    loading,
    goBack,
  };
};
