import { useOutsideClick } from "helpers/common.helper";
import { useRef, useState } from "react";

export const useMagicPlannerPopupHook = () => {
  const [isDetailShown, setIsDetailShown] = useState<boolean>(false);
  const popupRef = useRef<any>();
  const popupDetailRef = useRef<any>();

  const toggleDetail = () => {
    setIsDetailShown(!isDetailShown);
  };

  const closeDetail = () => {
    setIsDetailShown(false);
    popup.style.right = '-225px';
  };

  const popup = document.getElementById('magic-planner-popup')!;

  popup?.addEventListener('mouseover', () => {
    popup.style.right = '10px';
  });

  useOutsideClick(popupRef, popupDetailRef, closeDetail);

  return {
    isDetailShown,
    toggleDetail,
    popupRef,
    popupDetailRef
  };
};
