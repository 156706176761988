import { UserSettingshDispatchTypes } from "redux-store/types/dispatch.type";
import { UserSettingsState } from "redux-store/types/state.type";
import { AllUserSettingsActionTypes } from "redux-store/types/user-setting.action.type";

const INITIAL_STATE: UserSettingsState = {
  bearerToken: "",
  onboardingUserFullname: "",
  subscriptions: [],
  calendarData: [],
};

export const userSettingsReducer = (
  state = INITIAL_STATE,
  action: AllUserSettingsActionTypes
) => {
  switch (action.type) {
    case UserSettingshDispatchTypes.setUserBearerToken:
      return {
        ...state,
        bearerToken: action.payload,
      };
    case UserSettingshDispatchTypes.setUserInfo:
      return {
        ...state,
        userInfo: action.payload,
      };
    case UserSettingshDispatchTypes.setOnboardingUserFullname:
      return {
        ...state,
        onboardingUserFullname: action.payload,
      };

    case UserSettingshDispatchTypes.setFirebaseUuid:
      return {
        ...state,
        firebaseUuid: action.payload,
      };

    case UserSettingshDispatchTypes.setUserSubscriptions:
      return {
        ...state,
        subscriptions: action.payload,
      };

    case UserSettingshDispatchTypes.setCalendarData:
      return {
        ...state,
        calendarData: action.payload,
      };

    case UserSettingshDispatchTypes.clearUserData:
      return {
        ...INITIAL_STATE,
        bearerToken: state.bearerToken,
      };
    case UserSettingshDispatchTypes.setUserPreferences:
      return {
        ...state,
        preferences: action.payload,
      };

    default:
      return state;
  }
};
