import { palette } from "assets/palette";
import { i18n } from "constants/i18n.constants";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Text } from "elements/text/Text";
import { DateTime } from "luxon";
import {
  AmexImage,
  DinersImage,
  DiscoverImage,
  JCBImage,
  MasterCardImage,
  UnionpayImage,
  VisaImage,
} from "assets/images";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { PaymentCardModal } from "./PaymentCardModal";
import { Spinner } from "elements/spinner/Spinner";
import { PaymentMethods } from "constants/payment-methods.contants";

interface Props {
  lastPayment: string;
  nextPayment: string;
  paymentMethod?: string;
  paymentMethodImage?: string;
  onChangeBillingCycle?: () => void;
  onRemove?: () => void;
  showUnsubscribe?: boolean;
}

export const SubscriptionItem = ({
  lastPayment,
  nextPayment,
  paymentMethod,
  paymentMethodImage,
  showUnsubscribe,
  onChangeBillingCycle,
  onRemove,
}: Props) => {
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const ref = useRef<any>();
  const iconRef = useRef<any>();
  const togglePaymentMethod = () => {
    setIsDetailOpen(!isDetailOpen);
  };
  const paymentMethodSelector = () => {
    if (paymentMethodImage === "visa") {
      return VisaImage;
    } else if (paymentMethodImage === "mastercard") {
      return MasterCardImage;
    } else if (paymentMethodImage === "amex") {
      return AmexImage;
    } else if (paymentMethodImage === "diners") {
      return DinersImage;
    } else if (paymentMethodImage === "discover") {
      return DiscoverImage;
    } else if (paymentMethodImage === "jcb") {
      return JCBImage;
    } else if (paymentMethodImage === "unionpay") {
      return UnionpayImage;
    }
  };

  const isMastercard = () => {
    if (paymentMethodSelector() === MasterCardImage) {
      return true;
    }
    return false;
  };

  const useOutsideClick = () => {
    const handleClick = useCallback((event: { target: any }) => {
      if (
        !ref?.current?.contains(event.target) &&
        !iconRef?.current?.contains(event.target)
      ) {
        handleClose();
      }
    }, []);
    useEffect(() => {
      document.addEventListener("mouseup", handleClick);

      return () => {
        document.removeEventListener("mouseup", handleClick);
      };
    }, [handleClick]);
  };

  const handleClose = () => {
    setIsDetailOpen(false);
  };

  useOutsideClick();

  return (
    <div style={styles.tableRowItem}>
      <div style={styles.width}>
        <Text style={styles.tableRowItemText}>{i18n.t("app_name")}</Text>
      </div>
      <div style={styles.width}>
        <Text style={styles.tableRowItemText}>
          {lastPayment === "-"
            ? lastPayment
            : DateTime.fromISO(lastPayment).toFormat("MMM dd, yyyy")}
        </Text>
      </div>
      <div style={styles.width}>
        <Text style={styles.tableRowItemText}>
          {nextPayment === "-"
            ? nextPayment
            : DateTime.fromISO(nextPayment).toFormat("MMM dd, yyyy")}
        </Text>
      </div>
      <div style={styles.cardContainer}>
        <div
          style={
            isDetailOpen
              ? { ...styles.cardRowContainer, ...styles.openedCardRowContainer }
              : styles.cardRowContainer
          }
          ref={iconRef}
        >
          {paymentMethod ? (
            <>
              <div style={styles.cardImageContainer}>
                <img
                  width={24}
                  height={isMastercard() ? 20 : 8}
                  src={paymentMethodSelector()}
                  alt="paymentMethodImage"
                />
              </div>
              <Text style={styles.cardText}>
                {paymentMethodImage === PaymentMethods.APPLE
                  ? i18n.t("apple_account")
                  : `***${paymentMethod}`}
              </Text>
              <Icon
                name={IconName.CALENDAR_FORWARD}
                style={styles.downIcon}
                onPress={togglePaymentMethod}
              />
            </>
          ) : paymentMethodImage === PaymentMethods.APPLE ? (
            <>
              <div style={styles.appleCardImageContainer}>
                <Icon width={18} height={20} name={IconName.APPLE_CARD} />
              </div>
              <Text style={styles.cardText}>{i18n.t("apple_account")}</Text>
            </>
          ) : (
            <Spinner
              style={styles.spinner}
              size={50}
              color={"primary"}
              loading={!paymentMethod}
            />
          )}
        </div>
        {isDetailOpen ? (
          <div style={styles.modalContainer} ref={ref}>
            <PaymentCardModal
              showUnsubscribe={showUnsubscribe}
              onChangeBillingCycle={onChangeBillingCycle}
              onRemove={onRemove}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  tableRowItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: 45,
  },
  spinner: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  tableRowItemText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 15,
    color: palette.black,
    width: "100%",
    textAlign: "center",
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  cardRowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  downIcon: {
    rotate: "90deg",
  },
  cardText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 15,
    color: palette.black,
    marginLeft: 10,
    marginRight: 10,
  },
  width: {
    width: "100%",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    paddingRight: 2,
  },
  modalContainer: {
    position: "absolute",
    top: 35,
    paddingRight: 2,
    left: "-10%",
    zIndex: 1,
    width: "100%",
  },
  openedCardRowContainer: {
    height: 35,
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: palette.white,
    boxShadow: "0px 1px 3px 0px #00000040",
  },
  cardImageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 30,
    height: 20,
    border: `1px solid ${palette.gray.mediumLight}`,
    borderRadius: 3,
  },
  appleCardImageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 20,
    height: 20,
    borderRadius: 3,
  },
};
