import { LoginWrapper } from "components/login-wrapper/LoginWrapper";
import { i18n } from "constants/i18n.constants";
import { CustomButton } from "elements/button/Button";
import { CustomTextField } from "elements/text-field/TextField";
import React from "react";
import { useVerifyPhoneHook } from "./useVerifyPhone";
import { usePageHook } from "hooks/usePageHook";

export const VerifyPhone = () => {
  const { code, loading, error, onChangeCode, onPressSubmit } =
    useVerifyPhoneHook();
  usePageHook();
  return (
    <LoginWrapper>
      <CustomTextField
        label={i18n.t("verification_code")}
        placeholder={i18n.t("verify_phone_text")}
        value={code}
        onChangeText={onChangeCode}
        errorText={error}
        classProp="custom-style"
      />

      <CustomButton
        loading={loading}
        fullWidth
        text={i18n.t("send_verification_code")}
        onPress={onPressSubmit}
      />
    </LoginWrapper>
  );
};
