import { ICON_DICT } from "./Icon.library";
import { IconProps } from "./Icon.type";

export const Icon: React.FC<IconProps> = (props: IconProps): JSX.Element => {
  const { style, onPress, testID, disabled, ref, ...rest } = props;
  return (
    <button
      style={{ ...buttonStyle, ...style }}
      onClick={onPress}
      disabled={disabled}
      ref={ref}
    >
      <GeneratedIcon {...rest} />
    </button>
  );
};

const GeneratedIcon: React.FC<IconProps> = (props: IconProps): JSX.Element => {
  const { name } = props;

  if (!name || !ICON_DICT[name]) return <></>;
  const SelectedIcon = ICON_DICT[name];
  return <SelectedIcon {...props} />;
};

const buttonStyle: React.CSSProperties = {
  borderWidth: 0,
  backgroundColor: "transparent",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 6,
  cursor: "pointer"
};
