import { SubscriptionPlansResource } from "communicators/resources/subscription-plans/subscription-plans.resource";
import { SubscriptionPlanEntity } from "communicators/resources/subscription-plans/subscription-plans.type";
import { SubscriptionsResource } from "communicators/resources/subscription/subscription.resource";
import { SubscriptionPlan } from "components/subscription-period-selection/SubscriptionPeriodSelection.type";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const useSubscriptionFail = () => {
  const navigate = useNavigate();
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan>(
    SubscriptionPlan.MONTHLY
  );
  const [subscriptionPlans, setSubscriptionPlans] = useState<SubscriptionPlanEntity[]>([]);
  const [loading, setLoading] = useState(false);

  const getSubscriptionPlans = async () => {
    const {data} = await new SubscriptionPlansResource().readMany();
    setSubscriptionPlans(data);
  }

  useEffect(() => {
    getSubscriptionPlans();
  }, []);

  const monthlyPSubscriptionPlan = subscriptionPlans?.filter((item) => 
    item?.attributes?.interval === "month"
  );

  const yearlyPSubscriptionPlan = subscriptionPlans?.filter((item) => 
    item?.attributes?.interval === "year"
  )

  const monthlyPrice = monthlyPSubscriptionPlan?.[0]?.attributes?.price;

  const yearlyPrice = yearlyPSubscriptionPlan?.[0]?.attributes?.price;

  const onPressNext = async () => {
    setLoading(true);
    await new SubscriptionsResource().createOne({
      subscription: { plan: selectedPlan },
    });
    navigate("/onboarding/tools");
    setLoading(false);
  };

  const subscribe = () => {
    navigate("/checkout");
  };

  return {
    onPressNext,
    setSelectedPlan,
    selectedPlan,
    loading,
    subscribe,
    monthlyPrice,
    yearlyPrice
  };
};
