export enum ErrorNames {
	UNAUTHORIZED = "InsumoUnauthorized",
	NOT_BETA_USER = "Betausererror",
	CARD_ALREADY_EXISTS = "CardAlreadyExists",
	NOT_FOUND = "NotFound",
	DEFAULT = "UnrecognizedInsumoError",
	UNPROCESSABLE = "UnprocessableError"
}

export enum ErrorMessages {
	UNAUTHORIZED = "Unauthorized!",
	NOT_BETA_USER = "User is not beta user",
	CARD_ALREADY_EXISTS = "Card already exists",
	NOT_FOUND = "Not found!",
	DEFAULT = "Oops! Something went wrong.",
	UNPROCESSABLE = "Unprocessable request."
}

export enum ErrorCodes {
	UNAUTHORIZED = 401,
	NOT_BETA_USER = 403,
	SERVER_ERROR = 500,
	CARD_ALREADY_EXISTS = 400,
	NOT_FOUND = 404,
	UNPROCESSABLE = 422
}
