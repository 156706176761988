import { palette } from "assets/palette";
import { Text } from "elements/text/Text";
import React, { useEffect, useState } from "react";

interface Props {
  style?: React.CSSProperties;
  label?: string;
  placeholder?: string;
  initialValue?: string;
  format: string;
  onTextChange?: (text: string, displayed: string) => void;
  onFocus?: () => void;
  isDisabled?: boolean;
  value?: string;
  isInvalidDueDate?: boolean;
}

const transformToDisplayed = (text: string, format: string) => {
  let displayed = "";
  let j = 0;
  for (let i = 0; i < format.length; i++) {
    const originalChar = text[j];
    if (!originalChar) break;
    const formatChar = format[i];
    if (formatChar === "X" || formatChar === originalChar) {
      displayed += text[j];
      j++;
    } else {
      displayed += formatChar;
    }
  }
  return displayed;
};

const transformToOriginal = (text: string, format: string) => {
  let original = "";
  for (let i = 0; i < text.length; i++) {
    if (format[i] === "X") {
      original += text[i];
    }
  }
  return original;
};

export const MaskedInput = ({
  style,
  placeholder,
  label,
  format,
  initialValue = "",
  onTextChange,
  onFocus,
  isDisabled,
  value,
  isInvalidDueDate,
  ...other
}: Props) => {
  const [text, setText] = useState({
    originalText: transformToOriginal(initialValue, format),
    displayedText: initialValue,
  });

  const onChangeText = (text: string) => {
    const displayed = transformToDisplayed(text, format);
    const original = transformToOriginal(text, format);
    onTextChange && onTextChange(original, displayed);
    setText({
      originalText: original,
      displayedText: displayed,
    });
  };
  useEffect(() => {
    if (value) {
      onChangeText(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const getStyleOfInput = () => {
    if (isDisabled) return styles.disabledContainer;
    else if (isInvalidDueDate)
      return {
        ...styles.container,
        border: `2px solid ${palette.red.normal}`,
        outlineColor: palette.red.normal,
      };
    else return styles.container;
  };

  return (
    <div style={style} {...other}>
      {label ? <Text style={styles.label}>{label}</Text> : null}
      <input
        style={getStyleOfInput()}
        value={value === "Someday" ? value : text.displayedText}
        placeholder={placeholder}
        onChange={(event) => onChangeText(event.target.value.toUpperCase())}
        onFocus={onFocus}
        disabled={isDisabled}
      />
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    alignSelf: "stretch",
    backgroundColor: palette.gray.iceLight,
    border: "none",
    borderRadius: 8,
    paddingLeft: 5,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 9,
    height: 34,
    textAlign: "center",
    color: palette.gray.medium,
    outlineOffset: -1,
    outlineColor: palette.gray.medium,
  },
  disabledContainer: {
    display: "flex",
    alignSelf: "stretch",
    backgroundColor: palette.gray.iceLight,
    border: "none",
    borderRadius: 8,
    padding: 5,
    color: palette.gray.mediumLight,
    paddingLeft: 5,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 9,
    height: 34,
    textAlign: "center",
  },
  label: {
    fontFamily: "Gilroy-Semibold",
    fontWeight: "500",
    color: palette.gray.mediumLight,
    marginBottom: 5,
    fontSize: 10,
  },
};
