/* eslint-disable react-hooks/exhaustive-deps */
import { MeetsResource } from "communicators/resources/event-resources/meets/meets.resource";
import { MeetEntity } from "communicators/resources/event-resources/meets/meets.type";
import { TasksResource } from "communicators/resources/event-resources/tasks/tasks.resource";
import { TaskEntity } from "communicators/resources/event-resources/tasks/tasks.type";
import { DropResult } from "constants/dnd.constants";
import { DragAndDropHelper } from "helpers/dnd.helper";
import {
  AllBoardDataEntityType,
  IBoard,
  ThirdPartyProvider,
} from "interfaces/main.interfaces";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store/store.hooks";
import {
  getAllBoardData,
  getAllInsumoCalendarEventData,
  getSomedayData,
  IInsumoCalendar,
  reorder,
} from "helpers/data.helper";
import { debounce } from "lodash";
import { EventType } from "communicators/resources/event-resources/event-resources.type";
import { isDefaultScheduledTime } from "helpers/isDefaultScheduledTime";
import { TaskGoalResource } from "communicators/resources/task-goal/task-goal.resource";
import { InsumoCalendarEventsResource } from "communicators/resources/insumo-calendar-events/insumo-calendar-events.resource";
import { ITimelineItem } from "../home/components/DrawerRight/types";
import { ONBOARDING_INTEGRATION_PAGE_COUNT } from "constants/onboarding.constants";
import { ProfileResource } from "communicators/resources/profile/profile.resource";
import { PermissionModalType } from "../home/useHomeHook";
import { setPermissionModalType } from "redux-store/actions/home-page.actions";
import { Scope } from "communicators/resources/third-party-information/third-party-information.type";
import { HeaderButtonType } from "../home/constants";
import { todoistWriteAccessChecker } from "helpers/common.helper";

export enum CalendarAccountType {
  GOOGLE = "google",
  INSUMO = "insumo",
}

export interface AllBoardDataType {
  all: IBoard[];
  meets: IBoard[];
  tasks: IBoard[];
}

const PAGE_NUMBER = 3;

export const useOnboardingDndHook = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState<DateTime>(DateTime.now());
  const [endDate, setEndDate] = useState<DateTime>(
    DateTime.now().plus({ day: 7 })
  );
  const [rightDrawerStartDate, setRightDrawerStartDate] = useState<DateTime>(
    DateTime.now()
  );
  const [activeBoard, setActiveBoard] = useState<HeaderButtonType>(
    HeaderButtonType.ALL
  );
  const [meets, setMeets] = useState<MeetEntity[]>([]);
  const [tasks, setTasks] = useState<TaskEntity[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [allBoardData, setAllBoardData] = useState<AllBoardDataType>({
    all: [],
    meets: [],
    tasks: [],
  });
  const [newDroppedTask, setNewDroppedTask] = useState<TaskEntity>();
  const [timeItem, setTimeItem] = useState<ITimelineItem>();
  const [leftDrawerType, setLeftDrawerType] = useState<string>("");
  const [allInsumoCalendarEventData, setAllInsumoCalendarEventData] = useState<
    IInsumoCalendar[]
  >([]);
  const [rightDrawerData, setRightDrawerData] = useState<IBoard[]>([]);
  const [isRightDrawerLoading, setIsRightDrawerLoading] =
    useState<boolean>(false);
  const [inboxStartDate, setInboxStartDate] = useState<DateTime>(
    DateTime.now()
  );
  const [inboxTasks, setInboxTasks] = useState<IBoard[]>([]);

  const { userInfo, preferences, thirdPartyData } = useAppSelector((state) => ({
    userInfo: state.userSetting.userInfo,
    preferences: state.userSetting.preferences,
    thirdPartyData: state.thirdPartyProviderData.thirdPartyData,
  }));

  const todoistScopes = thirdPartyData?.filter(
    (item) => item.provider === ThirdPartyProvider.TODOIST
  )[0]?.scopes;

  const updateTask = (removed: AllBoardDataEntityType, result: DropResult) => {
    if (
      removed.type === EventType.TASK &&
      removed.attributes.provider === ThirdPartyProvider.TODOIST &&
      !todoistScopes?.includes(Scope.WRITE)
    ) {
      dispatch(setPermissionModalType(PermissionModalType.TODOIST));
      return;
    }
    new TasksResource().updateOnePartial(removed.id, {
      scheduled_time: result.destination.droppableId,
    });
  };

  const updateWithDebounce = debounce(updateTask, 1000);

  const handleDateChange = () => {
    if (
      rightDrawerStartDate.startOf("day").toSQLDate() ===
      endDate.startOf("day").toSQLDate()
    ) {
      setStartDate(endDate);
      setEndDate(endDate.plus({ day: 7 }));
    } else if (
      rightDrawerStartDate.startOf("day").toSQLDate()! <
      startDate.startOf("day").toSQLDate()!
    ) {
      setStartDate(startDate.minus({ day: 7 }));
      setEndDate(startDate);
    }
  };

  const getMeets = async (resync?: boolean) => {
    try {
      const response = await new MeetsResource().readMany(
        {
          start_date: startDate.toSQLDate(),
          end_date: endDate.toSQLDate(),
          inclusive: true,
          per: 1000,
        },
        resync
      );
      setMeets(response.data);
      return response.data;
    } catch (_) {
      return [];
    }
  };

  const getTasks = async (resync?: boolean) => {
    try {
      const response = await new TasksResource().readMany({
        start_date: startDate.toSQLDate(),
        end_date: startDate.plus({ day: 1 }).toSQLDate(),
      });
      setTasks(response.data);
      return response.data;
    } catch (_) {
      return [];
    }
  };

  const getInboxTasks = async (resync?: boolean) => {
    try {
      const response = await new TasksResource().readMany({
        start_date: inboxStartDate.startOf("month").toSQLDate(),
        end_date: inboxStartDate.endOf("month").toSQLDate(),
      });
      setTasks(response.data);
      return response.data;
    } catch (_) {
      return [];
    }
  };

  const getSomedayTasks = async () => {
    try {
      const response = await new TasksResource().readMany(
        {
          start_date: DateTime.utc(3000, 1, 1, 0, 0, 0, 0).toSQLDate(),
          end_date: DateTime.utc(3000, 1, 1, 0, 0, 0, 0).toSQLDate(),
        },
        true
      );
      return response.data;
    } catch (_) {
      return [];
    }
  };

  const getInitialData = async (prefferedCalendar?: CalendarAccountType) => {
    setLoading(true);
    const meetsResponse =
      (prefferedCalendar || preferences?.attributes.preferred_calendar) ===
      CalendarAccountType.GOOGLE
        ? await getMeets(true)
        : [];
    const tasksResponse = await getTasks(false);
    if (!meetsResponse && !tasksResponse) return;
    const boardData = getAllBoardData(
      [...meetsResponse, ...tasksResponse],
      startDate,
      true
    );
    const somedayTasks = await getSomedayTasks();
    const parsedSomedayTasks = getSomedayData(somedayTasks);
    const tasksData = getAllBoardData(tasksResponse, startDate, true);
    const allTasks = tasksData.concat(parsedSomedayTasks[0]);
    const meetsData = getAllBoardData(meetsResponse, startDate, false);
    setAllBoardData({ all: allTasks, meets: meetsData, tasks: allTasks });
    setLoading(false);
  };

  const getInitialInboxData = async () => {
    setLoading(true);
    const tasksResponse = await getInboxTasks(true);
    if (!tasksResponse) return;
    const tasksData = getAllBoardData(tasksResponse, startDate, false);
    setInboxTasks(tasksData);
    setLoading(false);
  };

  const getRightDrawerData = async () => {
    setIsRightDrawerLoading(true);
    const meetsResponse = await getMeets(true);
    if (!meetsResponse) return;
    const rightDrawerInitialData = getAllBoardData(
      [...meetsResponse],
      startDate,
      false
    );
    setRightDrawerData(rightDrawerInitialData);
    setIsRightDrawerLoading(false);
  };

  const refreshBoards = (prefferedCalendar?: CalendarAccountType) => {
    getInitialData(prefferedCalendar);
    getRightDrawerData();
    getInitialInsumoCalendarEventData();
    getInitialInboxData();
  };

  const onDragEnd = (result: DropResult) => {
    if (
      !result.destination ||
      result.destination.droppableId === "right_drawer"
    ) {
      return;
    }
    let board = allBoardData.all;
    if (activeBoard === HeaderButtonType.MEETS) {
      board = allBoardData.meets;
    } else if (activeBoard === HeaderButtonType.TASKS) {
      board = allBoardData.tasks;
    }
    const { copyBoard, removed } = reorder(result, board);
    if (!removed || !copyBoard) return;
    updateWithDebounce(removed, result);
    if (activeBoard === HeaderButtonType.MEETS) {
      boardData = allBoardData.meets;
      setAllBoardData({ ...allBoardData, meets: copyBoard });
    } else if (activeBoard === HeaderButtonType.TASKS) {
      boardData = allBoardData.tasks;
      setAllBoardData({ ...allBoardData, tasks: copyBoard });
    } else {
      setAllBoardData({ ...allBoardData, all: copyBoard });
    }
  };

  const onSelectDate = (date: DateTime) => {
    setStartDate(date);
    setEndDate(date.plus({ day: 7 }));
  };
  let boardData = allBoardData.all;
  if (activeBoard === HeaderButtonType.MEETS) {
    boardData = allBoardData.meets;
  } else if (activeBoard === HeaderButtonType.TASKS) {
    boardData = allBoardData.tasks;
  }

  const onPressAllBoardPlus = (date: DateTime) => {
    if (activeBoard === HeaderButtonType.MEETS) return;
    const copyAllBoard: AllBoardDataType = { ...allBoardData };
    copyAllBoard.all.forEach((currentBoard, index) => {
      if (currentBoard.date.toSQL() === date.toSQL()) {
        const newTaskEntity: TaskEntity = {
          id: "",
          type: EventType.TASK,
          attributes: {
            title: "",
            description: "",
            due_date: date.toSQL()!,
            start_date: "",
            end_date: "",
            start_time: null,
            end_time: null,
            permalink_url: null,
            provider: "",
            task_gid: "",
            created_at: date.toSQL()!,
            updated_at: date.toSQL()!,
            completed_count: 0,
            scheduled_time: date.toUTC().toISO(),
          },
        };
        copyAllBoard.all[index].data.unshift(newTaskEntity);
      }
    });
    setAllBoardData(copyAllBoard);
  };

  const onTaskCreate = (
    index: number,
    boardDate: DateTime,
    createdTask: TaskEntity
  ) => {
    const copyAllBoard: AllBoardDataType = { ...allBoardData };
    copyAllBoard.all.forEach((currentBoard, boardIndex) => {
      if (currentBoard.date.toSQL() === boardDate.toSQL()) {
        copyAllBoard.all[boardIndex].data[index] = createdTask;
      }
    });
    copyAllBoard.tasks.forEach((currentBoard, boardIndex) => {
      if (currentBoard.date.toSQL() === boardDate.toSQL()) {
        copyAllBoard.tasks[boardIndex].data[index] = createdTask;
      }
    });
    setAllBoardData(copyAllBoard);
  };

  const onPressInboxBoardPlus = (date: DateTime) => {
    const copyAllBoard: AllBoardDataType = { ...allBoardData };
    copyAllBoard.all.forEach((currentBoard, index) => {
      if (currentBoard.date.toSQL() === date.toSQL()) {
        const newTaskEntity: TaskEntity = {
          id: "",
          type: EventType.TASK,
          attributes: {
            title: "",
            description: "",
            due_date: date.toSQL()!,
            start_date: "",
            end_date: "",
            start_time: null,
            end_time: null,
            permalink_url: null,
            provider: "",
            task_gid: "",
            created_at: date.toSQL()!,
            updated_at: date.toSQL()!,
            completed_count: 0,
            scheduled_time: date.toUTC().toISO(),
          },
        };
        copyAllBoard.all[index].data.unshift(newTaskEntity);
        copyAllBoard.tasks[index].data.unshift(newTaskEntity);
      }
    });
    setAllBoardData(copyAllBoard);
  };

  const onPressNextDay = () => {
    setRightDrawerStartDate(rightDrawerStartDate.plus({ day: 1 }));
  };

  const onPressPreviousDay = () => {
    setRightDrawerStartDate(rightDrawerStartDate.minus({ day: 1 }));
  };

  const onInboxTaskCreate = (
    index: number,
    boardDate: DateTime,
    createdTask: TaskEntity
  ) => {
    const copyAllBoard = { ...inboxVisibleData };
    copyAllBoard.forEach((currentBoard, boardIndex) => {
      if (currentBoard.date.toSQL() === boardDate.toSQL()) {
        copyAllBoard[boardIndex].data[index] = createdTask;
      }
    });
    setAllBoardData({ ...allBoardData, all: copyAllBoard });
  };

  const onClickCheckBox = (data: AllBoardDataEntityType) => {
    if (data?.type !== EventType.TASK) return;
    if (!todoistWriteAccessChecker(data, todoistScopes)) return;
    const newTaskdata = { ...data };
    newTaskdata.attributes.completed_count =
      data.attributes.completed_count > 0 ? 0 : 1;
    try {
      new TaskGoalResource().createOne({
        task_goal: {
          task_id: data.id,
          completed_date: DateTime.now().toSQLDate(),
        },
      });

      const all = [...allBoardData.all];
      let allIndex = -1;
      let allBoardIndex = -1;
      let tasksIndex = -1;
      let tasksBoardIndex = -1;
      for (let i = 0; i < all.length; i++) {
        const foundIndexForAll = all[i].data.findIndex(
          (item) => item.id === data.id
        );
        if (foundIndexForAll > 0) {
          allIndex = foundIndexForAll;
          allBoardIndex = i;
        }
      }
      const tasks = [...allBoardData.tasks];
      for (let i = 0; i < all.length; i++) {
        const foundIndexForAll = all[i].data.findIndex(
          (item) => item.id === data.id
        );
        if (foundIndexForAll > 0) {
          tasksIndex = foundIndexForAll;
          tasksBoardIndex = i;
        }
      }
      if (tasksBoardIndex >= 0 && tasksIndex >= 0) {
        tasks[tasksBoardIndex].data[tasksIndex] = newTaskdata;
      }
      if (allBoardIndex >= 0 && allIndex >= 0) {
        all[tasksBoardIndex].data[tasksIndex] = newTaskdata;
      }
      setAllBoardData({ ...allBoardData, all: all, tasks: tasks });
    } catch {}
  };

  const updateTaskWithRightDrawerDrop = (
    newestDroppedTask: TaskEntity,
    newTimeItem: ITimelineItem
  ) => {
    setNewDroppedTask(newestDroppedTask);
    setTimeItem(newTimeItem);
  };

  const updateNewTask = (id: string, time: string) => {
    if (!todoistWriteAccessChecker(newDroppedTask, todoistScopes)) return;
    new TasksResource().updateOnePartial(id, { scheduled_time: time });
  };

  const updateWithDebounceNewTask = debounce(updateNewTask, 1001);

  const showNotification = (message: string) => {
    new Notification(message);
  };

  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("Browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
  }, []);

  useEffect(() => {
    if (newDroppedTask && timeItem) {
      let removedId: number = -1;
      let removedDataId: number = -1;
      let toId: number = -1;
      const copyAllBoard = { ...allBoardData };
      copyAllBoard?.all?.forEach((bd, index) => {
        if (
          bd.date.startOf("day").toSQL() ===
          timeItem?.time.startOf("day").toSQL()
        ) {
          toId = index;
        }
        bd?.data?.forEach((bdd, indexOfBdd) => {
          if (bdd?.id === newDroppedTask?.id) {
            removedId = index;
            removedDataId = indexOfBdd;
          }
        });
      });
      if (removedId === toId) {
        return;
      }
      if (removedDataId !== -1 && removedId !== -1 && toId !== -1) {
        copyAllBoard?.all?.[toId].data.unshift(newDroppedTask);
        copyAllBoard?.all?.[removedId].data.splice(removedDataId, 1);

        setAllBoardData({
          ...copyAllBoard,
          all: copyAllBoard.all,
          tasks: copyAllBoard.tasks,
        });
        updateWithDebounceNewTask(
          newDroppedTask.id,
          timeItem?.time?.toUTC().toISO()!
        );
      }
    }
  }, [newDroppedTask, timeItem]);

  const getInsumoCalendarEventData = async () => {
    const { data } = await new InsumoCalendarEventsResource().readMany({
      start_date: startDate.minus({ day: 1 }).toSQLDate(),
      end_date: endDate.plus({ day: 1 }).toSQLDate(),
    });
    return data;
  };

  const getInitialInsumoCalendarEventData = async () => {
    setLoading(true);
    const insumoCalendarEventData = await getInsumoCalendarEventData();
    if (!insumoCalendarEventData) return;
    const allInsumoCalendarEventData = getAllInsumoCalendarEventData(
      insumoCalendarEventData,
      rightDrawerStartDate
    );
    setAllInsumoCalendarEventData(allInsumoCalendarEventData);
    setLoading(false);
  };

  useEffect(() => {
    handleDateChange();
    getRightDrawerData();
    getInitialInsumoCalendarEventData();
  }, [rightDrawerStartDate]);

  useEffect(() => {
    getInitialData();
    getRightDrawerData();
    getInitialInsumoCalendarEventData();
    getInitialInboxData();
  }, [startDate]);

  useEffect(() => {
    getInitialInboxData();
  }, [inboxStartDate]);

  let inboxData = inboxTasks.filter((item) => item?.data?.length !== 0);
  inboxData.forEach((item) => {
    item.data = item.data.filter((subitem) =>
      subitem.type === EventType.TASK
        ? subitem.attributes.completed_count === 0 &&
          !isDefaultScheduledTime(subitem) &&
          DateTime.fromISO(
            subitem?.attributes?.scheduled_time
              ? subitem?.attributes?.scheduled_time
              : ""
          ).year === startDate?.year
        : null
    );
  });
  let inboxVisibleData = inboxData?.filter((item) =>
    item?.data?.some((item) =>
      item?.type === EventType.TASK
        ? item?.attributes?.completed_count === 0 &&
          !isDefaultScheduledTime(item) &&
          DateTime.fromISO(
            item?.attributes?.scheduled_time
              ? item?.attributes?.scheduled_time
              : ""
          ).year === startDate?.year
        : null
    )
  );

  useEffect(() => {
    inboxData = allBoardData?.all?.filter((item) => item?.data?.length !== 0);
    inboxVisibleData = inboxData?.filter((item) =>
      item?.data?.some((item) =>
        item?.type === EventType.TASK
          ? item?.attributes?.completed_count === 0 &&
            !isDefaultScheduledTime(item) &&
            DateTime.fromISO(
              item?.attributes?.scheduled_time
                ? item?.attributes?.scheduled_time
                : ""
            ).year === startDate?.year
          : null
      )
    );
  }, [onPressInboxBoardPlus]);

  useEffect(() => {
    let savedBoardData = allBoardData.all;
    let savedRightDrawerBoardData = allBoardData.all;
    if (activeBoard === HeaderButtonType.MEETS) {
      savedBoardData = allBoardData.meets;
      savedRightDrawerBoardData = allBoardData.meets;
    } else if (activeBoard === HeaderButtonType.TASKS) {
      savedBoardData = allBoardData.tasks;
      savedRightDrawerBoardData = allBoardData.tasks;
    }
    DragAndDropHelper.setBoardData(savedBoardData);
    DragAndDropHelper.setBoardData(savedRightDrawerBoardData);
  }, [activeBoard, allBoardData]);

  const deleteTask = (data: AllBoardDataEntityType) => {
    try {
      new TasksResource().removeOne(data.id);
      const all = allBoardData.all;
      all.forEach((item) => {
        item.data = item.data.filter((subitem) => subitem.id !== data.id);
      });
      const tasks = allBoardData.tasks;
      tasks.forEach((item) => {
        item.data = item.data.filter((subitem) => subitem.id !== data.id);
      });
      setAllBoardData({ ...allBoardData, all: all, tasks: tasks });
    } catch {}
  };

  const progressValue = (PAGE_NUMBER / ONBOARDING_INTEGRATION_PAGE_COUNT) * 100;

  const goToDashboard = async () => {
    try {
      if (userInfo) {
        await new ProfileResource().updateAndReadOne(
          userInfo?.attributes.firebase_uuid,
          {
            user: {
              onboarding_completed: true,
              email: userInfo.attributes.email,
            },
          }
        );
      }
    } catch (error) {}
    navigate("/home", { replace: true });
  };

  const goBack = () => {
    navigate(-1);
  };

  const [isUnsubscriptionOpened, setIsUnsubscriptionOpened] =
    useState<boolean>(false);
  const handleOpenUnsubscriptionDialog = () => {
    setIsUnsubscriptionOpened(true);
  };
  const handleClose = () => {
    setIsUnsubscriptionOpened(false);
  };

  return {
    loading,
    boardData,
    onDragEnd,
    setActiveBoard,
    onSelectDate,
    onPressAllBoardPlus,
    onTaskCreate,
    onPressInboxBoardPlus,
    onInboxTaskCreate,
    inboxVisibleData,
    onPressNextDay,
    onPressPreviousDay,
    startDate,
    rightDrawerStartDate,
    updateTaskWithRightDrawerDrop,
    showNotification,
    leftDrawerType,
    onClickCheckBox,
    deleteTask,
    allInsumoCalendarEventData,
    rightDrawerData,
    isRightDrawerLoading,
    refreshBoards,
    progressValue,
    goToDashboard,
    goBack,
    isUnsubscriptionOpened,
    handleClose,
    handleOpenUnsubscriptionDialog,
    allBoardData,
    setAllBoardData,
  };
};
