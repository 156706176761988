import { palette } from "assets/palette";
import { i18n } from "constants/i18n.constants";
import { CustomButton } from "elements/button/Button";
import React, { useState } from "react";
import { Text } from "elements/text/Text";
import { useAddPaymentMethodHook } from "./useAddPaymentMethodHook";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { resizeByResolutionWidth } from "helpers/utils.helper";
import {
  AmexImage,
  DinersImage,
  DiscoverImage,
  JCBImage,
  MasterCardImage,
  PaypalImage,
  UnionpayImage,
  VisaImage,
} from "assets/images";
import {
  AddressElement,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  loadStripe,
  StripeAddressElementChangeEvent,
  StripeCardNumberElement,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";
import { PaymentMethodResource } from "communicators/resources/payment-method/payment-method.resource";
import { ErrorNames } from "communicators/insumo/errors/type";

const PK = process.env.REACT_APP_STRIPE_PK || "";

const stripePromise = loadStripe(PK);

export const PreferencesAddPaymentMethod = () => {
  const { goBack } = useAddPaymentMethodHook();
  return (
    <div style={styles.container}>
      <div style={styles.headerContainer}>
        <Icon name={IconName.BACK_ARROW} onPress={goBack} />
        <Text style={styles.headerText}>{i18n.t("add_payment_method")}</Text>
      </div>
      <Elements stripe={stripePromise}>
        <Payment goBack={goBack} />
      </Elements>
    </div>
  );
};

type PaymentProps = {
  goBack: () => void;
};

const Payment = ({ goBack }: PaymentProps) => {
  type StripeAddressType = {
    line1?: string;
    line2: string | null;
    city?: string;
    state?: string;
    postal_code?: string;
    country?: string;
  };
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [addPaymentErrorMessage, setAddPaymentErrorMessage] =
    useState<string>("");
  const [address, setAddress] = useState<StripeAddressType>();
  const [isInvoiceDetailsShown, setIsInvoiceDetailsShown] =
    useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState<string>("");
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (elements == null || !stripe) {
      return;
    }
    setLoading(true);
    const cardElement = elements.getElement(CardNumberElement) || { token: "" };
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: name,
        address: {
          city: address?.city,
          country: address?.country,
          line1: address?.line1,
          line2: address?.line2!,
          postal_code: address?.postal_code,
          state: address?.state,
        },
      },
    });
    if (error) {
      return;
    }
    if (paymentMethod) {
      new PaymentMethodResource()
        .createOne({
          payment_method: {
            id: paymentMethod.id,
          },
        })
        .then(() => {
          setLoading(false);
          goBack();
        })
        .catch((err) => {
          if (err.name === ErrorNames.CARD_ALREADY_EXISTS)
            setAddPaymentErrorMessage(
              i18n.t("card_already_exists_try_another_card")
            );
          setLoading(false);
        });
    }
  };
  const cardNumberElement = elements?.getElement("cardNumber");
  cardNumberElement?.on("change", (event) => {
    if (event.error) {
      setErrorMessage(event.error.message);
    }
  });

  const onCardNumberChange = (data: StripeCardNumberElementChangeEvent) => {
    if (data.complete && elements) {
      const cardNumberElement = elements.getElement(CardNumberElement);
      const cardExpiryElement = elements.getElement(CardExpiryElement);
      cardNumberElement?.blur();
      cardExpiryElement?.focus();
    }
  };

  const onChangeAddress = (event: StripeAddressElementChangeEvent) => {
    if (event) {
      const address = event.value.address;
      const name = event?.value?.name;
      setAddress(address);
      setName(name);
    }
  };

  const onCardNumberReady = (element: StripeCardNumberElement) => {
    element.focus();
  };

  const onClickInvoiceDetails = () => {
    setIsInvoiceDetailsShown(!isInvoiceDetailsShown);
  };

  return (
    <div style={styles.formContainer}>
      <form style={styles.form} onSubmit={handleSubmit}>
        {errorMessage ? (
          <div style={styles.errorContainer}>
            <Text style={styles.errorCircle}>!</Text>
            <Text style={styles.error}>{errorMessage}</Text>
          </div>
        ) : null}
        {addPaymentErrorMessage ? (
          <div style={styles.errorContainer}>
            <Text style={styles.errorCircle}>!</Text>
            <Text style={styles.error}>{addPaymentErrorMessage}</Text>
          </div>
        ) : null}
        <div style={styles.rowContainer}>
          <Text style={styles.text}>{i18n.t("card_details")}</Text>
          <div style={styles.rowContainer}>
            <div style={styles.paymentsContainer}>
              <img
                width={resizeByResolutionWidth(45)}
                height={resizeByResolutionWidth(28)}
                src={MasterCardImage}
                style={styles.iconImage}
                alt="paymentMethodCardImage"
              />
            </div>
            <div style={styles.paymentsContainer}>
              <img
                width={resizeByResolutionWidth(61)}
                height={resizeByResolutionWidth(20)}
                src={VisaImage}
                style={styles.iconImage}
                alt="paymentMethodCardImage"
              />
            </div>
            <div style={styles.paymentsContainer}>
              <img
                width={resizeByResolutionWidth(61)}
                height={resizeByResolutionWidth(20)}
                src={DinersImage}
                style={styles.iconImage}
                alt="paymentMethodCardImage"
              />
            </div>
            <div style={styles.paymentsContainer}>
              <img
                width={resizeByResolutionWidth(61)}
                height={resizeByResolutionWidth(20)}
                src={DiscoverImage}
                style={styles.iconImage}
                alt="paymentMethodCardImage"
              />
            </div>
            <div style={styles.paymentsContainer}>
              <img
                width={resizeByResolutionWidth(25)}
                height={resizeByResolutionWidth(30)}
                src={PaypalImage}
                style={styles.iconImage}
                alt="paymentMethodCardImage"
              />
            </div>
            <div style={styles.paymentsContainer}>
              <img
                width={resizeByResolutionWidth(61)}
                height={resizeByResolutionWidth(20)}
                src={AmexImage}
                style={styles.iconImage}
                alt="paymentMethodCardImage"
              />
            </div>
            <div style={styles.paymentsContainer}>
              <img
                width={resizeByResolutionWidth(61)}
                height={resizeByResolutionWidth(20)}
                src={UnionpayImage}
                style={styles.iconImage}
                alt="mastercardImage"
              />
            </div>
            <div style={styles.paymentsContainer}>
              <img
                width={resizeByResolutionWidth(61)}
                height={resizeByResolutionWidth(20)}
                src={JCBImage}
                style={styles.iconImage}
                alt="mastercardImage"
              />
            </div>
          </div>
        </div>
        <div style={styles.cardInfoMainContainer}>
          <div style={styles.cardInfoContainer}>
            <div style={styles.cardNumber}>
              <CardNumberElement
                onReady={onCardNumberReady}
                onChange={onCardNumberChange}
                options={{
                  showIcon: true,
                  placeholder: `${i18n.t("card_number")}`,
                }}
              />
            </div>
            <div style={styles.dateCVCContainer}>
              <div style={styles.dateCVCInput}>
                <CardExpiryElement
                  options={{ placeholder: `${i18n.t("mm_yy")}` }}
                />
              </div>
              <div style={styles.dateCVCInput}>
                <CardCvcElement />
              </div>
            </div>
          </div>
        </div>
        <div
          style={styles.invoiceDetailsContainer}
          onClick={onClickInvoiceDetails}
        >
          <Text style={styles.invoiceDetails}>{i18n.t("invoice_details")}</Text>
          <Icon name={IconName.CALENDAR_FORWARD} style={styles.forwardIcon} />
        </div>
        {isInvoiceDetailsShown ? (
          <AddressElement
            options={{ mode: "billing" }}
            onChange={onChangeAddress}
          />
        ) : null}
        <Text style={styles.info}>
          {i18n.t("your_card_details_will_be_saved")}
        </Text>
        <div style={styles.buttonContainer}>
          <CustomButton
            style={styles.cancel}
            text={i18n.t("cancel")}
            textStyle={styles.cancelText}
            onClick={goBack}
          />
          <CustomButton
            style={styles.save}
            text={i18n.t("save")}
            type="submit"
            disabled={!stripe || !elements}
            loading={loading}
            spinnerColor={"primary"}
          />
        </div>
      </form>
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flex: 1,
    alignSelf: "stretch",
    flexDirection: "column",
    paddingRight: 50,
    marginLeft: -12,
    overflowY: "scroll",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  headerText: {
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "500",
    fontSize: 20,
    color: palette.black,
  },
  continue: {
    width: "100%",
    backgroundColor: palette.black,
    height: 50,
    marginBottom: 50,
    marginTop: "auto",
  },
  buttonText: {
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "500",
    fontSize: 15,
    color: palette.white,
  },
  errorContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  error: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 20,
    color: palette.red.dark,
  },
  errorCircle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: palette.red.light,
    width: 18,
    height: 18,
    borderRadius: 9,
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 15,
    color: palette.red.dark,
    marginRight: 10,
  },
  form: {
    display: "flex",
    alignSelf: "stretch",
    width: "100%",
    justifyContent: "center",
    flexDirection: "column",
    marginLeft: 50,
  },
  formContainer: {
    display: "flex",
    flex: 1,
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  text: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 18,
    color: palette.gray.dark,
    width: "100px"
  },
  paymentsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid ${palette.gray.light}`,
    borderRadius: resizeByResolutionWidth(4.5),
    width: resizeByResolutionWidth(67.5),
    height: resizeByResolutionWidth(45),
    marginLeft: resizeByResolutionWidth(5),
  },
  cardInfoMainContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 10,
    backgroundColor: palette.gray.light,
    marginTop: 15,
    marginBottom: 15,
  },
  cardInfoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: 45,
  },
  cardNumber: {
    width: "75%",
    marginLeft: resizeByResolutionWidth(30),
  },
  dateCVCContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "25%",
    marginRight: resizeByResolutionWidth(30),
  },
  dateCVCInput: {
    width: "100%",
  },
  save: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: palette.black,
    borderRadius: 12,
    height: 50,
    marginBottom: 30,
    marginTop: 15,
  },
  cancel: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: palette.gray.ice,
    borderRadius: 12,
    height: 50,
    marginBottom: 30,
    marginTop: 15,
  },
  input: {
    display: "flex",
    alignSelf: "stretch",
    width: "100%",
    height: 45,
    borderRadius: 7,
    border: `1px solid ${palette.gray.light}`,
    backgroundColor: palette.gray.iceLight,
    marginTop: 4,
    paddingLeft: 10,
    paddingRight: 10,
    alignItems: "center",
  },
  label: {
    fontFamily: "Gilroy-Medium",
    color: palette.gray.gray4,
    fontSize: 15,
  },
  mainInputContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 15,
  },
  inputContainer: {
    width: "100%",
  },
  info: {
    fontFamily: "Gilroy",
    color: palette.gray.dark,
    fontSize: 13,
    marginTop: "auto",
    paddingTop: 20,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 20,
  },
  cancelText: {
    fontFamily: "Gilroy-SemiBold",
    color: palette.gray.medium,
    fontSize: 15,
  },
  invoiceDetails: {
    fontFamily: "Gilroy-Medium",
    color: palette.gray.dark,
    fontSize: 18,
    alignSelf: "center",
  },
  invoiceDetailsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "max-content",
    marginTop: 20,
    marginBottom: 20,
    cursor: "pointer",
  },
  forwardIcon: {
    marginLeft: 10,
  },
  phonePostalCodeContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 20,
  },
  iconImage: {
    objectFit: "contain",
  },
};
