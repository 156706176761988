import { LoginWrapper } from "components/login-wrapper/LoginWrapper";
import {
  Elements,
  useStripe,
  useElements,
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import {
  loadStripe,
  StripeCardNumberElement,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";
import { i18n } from "constants/i18n.constants";
import { SubscriptionsResource } from "communicators/resources/subscription/subscription.resource";
import { useLocation, useNavigate } from "react-router-dom";
import { SubscriptionPlan } from "components/subscription-period-selection/SubscriptionPeriodSelection.type";
import { Text } from "elements/text/Text";
import { palette } from "assets/palette";
import {
  MasterCardImage,
  PaypalImage,
  DinersImage,
  DiscoverImage,
  AmexImage,
  UnionpayImage,
  JCBImage,
  VisaImage,
} from "assets/images";
import { resizeByResolutionWidth } from "helpers/utils.helper";
import { CustomButton } from "elements/button/Button";
import { useEffect, useState } from "react";
import { Plan } from "./components/Plan";
import { SubscriptionPlansResource } from "communicators/resources/subscription-plans/subscription-plans.resource";
import { SubscriptionPlanEntity } from "communicators/resources/subscription-plans/subscription-plans.type";
import { useAppDispatch, useAppSelector } from "redux-store/store.hooks";
import { setSubscriptions } from "redux-store/actions/user-settings.action";
import { usePageHook } from "hooks/usePageHook";

const PK = process.env.REACT_APP_STRIPE_PK || "";

const stripePromise = loadStripe(PK);

export const Checkout = () => {
  const params = useLocation();
  const selectedPlan: SubscriptionPlan | undefined =
    params?.state?.selectedPlan;
  usePageHook();
  return (
    <LoginWrapper
      headerRightText={i18n.t("next")}
      title={i18n.t("free_trial_for_two_weeks")}
      showBottom={false}
      backButton
      isStripePayment
    >
      <div style={styles.mainContainer}>
        <Elements stripe={stripePromise}>
          <CheckoutForm beforeSelectedPlan={selectedPlan} />
        </Elements>
      </div>
    </LoginWrapper>
  );
};

const CheckoutForm = ({
  beforeSelectedPlan,
}: {
  beforeSelectedPlan?: SubscriptionPlan;
}) => {
  const [selectedPlan, setSelectedPlan] = useState(
    beforeSelectedPlan || SubscriptionPlan.MONTHLY
  );
  const [subscriptionPlans, setSubscriptionPlans] = useState<
    SubscriptionPlanEntity[]
  >([]);
  const [errorMessage, setErrorMessage] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const location = useLocation();
  const [cardNumberCompleted, setCardNumberCompleted] =
    useState<boolean>(false);
  const [cardCvcCompleted, setCardCvcCompleted] = useState<boolean>(false);
  const [cardExpiryCompleted, setCardExpiryCompleted] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { subscriptions } = useAppSelector((state) => ({
    subscriptions: state.userSetting.subscriptions,
  }));

  const dispatch = useAppDispatch();

  const activeSubscriptions = subscriptions.filter(
    (item) =>
      item.attributes.status === "active" ||
      item.attributes.status === "trialing"
  );

  const getSubscriptionPlans = async () => {
    const { data } = await new SubscriptionPlansResource().readMany();
    setSubscriptionPlans(data);
  };

  useEffect(() => {
    getSubscriptionPlans();
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    setLoading(true);

    if (elements == null || !stripe) {
      setLoading(false);
      return;
    }
    const cardElement = elements.getElement(CardNumberElement) || { token: "" };
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });
    if (error) {
      navigate("/subscription-fail");
      setLoading(false);
      return;
    }

    if (activeSubscriptions.length !== 0) {
      navigate("/home", { replace: true });
      setLoading(false);
      return;
    }
    const response = await new SubscriptionsResource().createOne({
      subscription: {
        plan: SubscriptionPlan.MONTHLY,
        payment_method: paymentMethod.id,
      },
    });
    dispatch(setSubscriptions([...subscriptions, response.data]));
    const secretId = response.data.attributes.client_secret;
    const confirmResponse = await stripe.confirmCardPayment(secretId, {
      payment_method: { card: cardElement },
    });
    if (confirmResponse.paymentIntent?.status === "succeeded") {
      if (location.state === "inside_app") {
        navigate("/home", { replace: true });
        setLoading(false);
      } else {
        navigate("/subscription-success");
        setLoading(false);
      }
    } else {
      navigate("/subscription-fail");
      setLoading(false);
    }
  };

  const selectMonthlyPlan = () => {
    setSelectedPlan(SubscriptionPlan.MONTHLY);
  };

  const selectAnnualPlan = () => {
    setSelectedPlan(SubscriptionPlan.ANNUALLY);
  };
  const cardNumberElement = elements?.getElement("cardNumber");
  cardNumberElement?.on("change", (event) => {
    if (event.error) {
      setErrorMessage(event.error.message);
    }
  });

  const onCardNumberChange = (data: StripeCardNumberElementChangeEvent) => {
    setCardNumberCompleted(data?.complete);
    if (data.complete && elements) {
      const cardNumberElement = elements.getElement(CardNumberElement);
      const cardExpiryElement = elements.getElement(CardExpiryElement);
      cardNumberElement?.blur();
      cardExpiryElement?.focus();
    }
  };

  const onCardNumberReady = (element: StripeCardNumberElement) => {
    element.focus();
  };

  const monthlyPSubscriptionPlan = subscriptionPlans?.filter(
    (item) => item?.attributes?.interval === "month"
  );

  const yearlyPSubscriptionPlan = subscriptionPlans?.filter(
    (item) => item?.attributes?.interval === "year"
  );

  const monthlyPrice = monthlyPSubscriptionPlan?.[0]?.attributes?.price;

  const yearlyPrice = yearlyPSubscriptionPlan?.[0]?.attributes?.price;

  const savedPercentage = Number(
    (((monthlyPrice * 12 - yearlyPrice) / (monthlyPrice * 12)) * 100).toFixed(0)
  );

  const monthlyFixed = monthlyPrice / 100;
  const yearlyFixed = yearlyPrice / 100;

  return (
    <div style={styles.formContainer}>
      <form style={styles.form} onSubmit={handleSubmit}>
        <Text style={styles.plan}>{i18n.t("plan")}</Text>
        <div style={styles.planContainer}>
          <Plan
            header={i18n.t("pay_monthly")}
            price={monthlyFixed}
            onPress={selectMonthlyPlan}
            isSelected={selectedPlan === SubscriptionPlan.MONTHLY}
          />
          <div style={styles.gap} />
          <Plan
            header={i18n.t("pay_yearly")}
            price={yearlyFixed / 12}
            onPress={selectAnnualPlan}
            isSelected={selectedPlan === SubscriptionPlan.ANNUALLY}
            savedPercentage={savedPercentage}
          />
        </div>
        {errorMessage ? (
          <div style={styles.errorContainer}>
            <Text style={styles.errorCircle}>!</Text>
            <Text style={styles.error}>{errorMessage}</Text>
          </div>
        ) : null}
        <div style={styles.rowContainer}>
          <Text style={styles.text}>{i18n.t("card_details")}</Text>
          <div style={styles.rowContainer}>
            <div style={styles.paymentsContainer}>
              <img
                width={60}
                height={45}
                src={MasterCardImage}
                alt="mastercardImage"
              />
            </div>
            <div style={styles.paymentContainerWithBorder}>
              <img
                width={resizeByResolutionWidth(50)}
                height={resizeByResolutionWidth(19)}
                src={VisaImage}
                alt="mastercardImage"
              />
            </div>
            <div style={styles.paymentContainerWithBorder}>
              <img
                width={resizeByResolutionWidth(50)}
                height={resizeByResolutionWidth(30)}
                src={DinersImage}
                alt="mastercardImage"
              />
            </div>
            <div style={styles.paymentContainerWithBorder}>
              <img
                width={resizeByResolutionWidth(61)}
                height={resizeByResolutionWidth(30)}
                src={DiscoverImage}
                alt="mastercardImage"
              />
            </div>
            <div style={styles.paymentContainerWithBorder}>
              <img
                width={resizeByResolutionWidth(25)}
                height={resizeByResolutionWidth(30)}
                src={PaypalImage}
                alt="mastercardImage"
              />
            </div>
            <div style={styles.paymentsContainer}>
              <img
                width={resizeByResolutionWidth(61)}
                height={30}
                src={AmexImage}
                alt="mastercardImage"
              />
            </div>
            <div style={styles.stripeContainer}>
              <img
                width={resizeByResolutionWidth(67.5)}
                height={resizeByResolutionWidth(45)}
                style={styles.stripeBase}
                src={UnionpayImage}
                alt="mastercardImage"
              />
              <img
                width={55}
                height={35}
                style={styles.stripeText}
                src={JCBImage}
                alt="mastercardImage"
              />
            </div>
          </div>
        </div>
        <div style={styles.cardInfoMainContainer}>
          <div style={styles.cardInfoContainer}>
            <div style={styles.cardNumber}>
              <CardNumberElement
                onReady={onCardNumberReady}
                onChange={onCardNumberChange}
                options={{
                  showIcon: true,
                  placeholder: `${i18n.t("card_number")}`,
                }}
              />
            </div>
            <div style={styles.dateCVCContainer}>
              <div style={styles.dateCVCInput}>
                <CardExpiryElement
                  options={{ placeholder: `${i18n.t("mm_yy")}` }}
                  onChange={(e) => setCardExpiryCompleted(e.complete)}
                />
              </div>
              <div style={styles.dateCVCInput}>
                <CardCvcElement
                  onChange={(e) => setCardCvcCompleted(e.complete)}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={styles.billedAmountContainer}>
          <Text style={styles.billed}>{i18n.t("billed_now")}</Text>
          <div style={styles.separator} />
          <Text style={styles.billed}>{`USD ${
            selectedPlan === SubscriptionPlan.MONTHLY
              ? monthlyFixed.toFixed(2)
              : yearlyFixed.toFixed(2)
          }`}</Text>
        </div>
        <Text style={styles.longText}>
          {i18n.t("subscription_long_text", {
            price: `${
              selectedPlan === SubscriptionPlan.MONTHLY
                ? monthlyFixed.toFixed(2)
                : yearlyFixed.toFixed(2)
            }`,
            period: `${selectedPlan}`,
          })}
        </Text>
        <CustomButton
          style={styles.subscribe}
          text={i18n.t("start_insumo_premium_plan")}
          type="submit"
          disabled={
            !cardNumberCompleted || !cardExpiryCompleted || !cardCvcCompleted
          }
          loading={loading}
          spinnerColor="primary"
        />
      </form>
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  text: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 25,
    color: palette.gray.medium,
  },
  error: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 20,
    color: palette.red.dark,
  },
  errorCircle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: palette.red.light,
    width: 18,
    height: 18,
    borderRadius: 9,
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 15,
    color: palette.red.dark,
    marginRight: 10,
  },
  errorContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  mainContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    alignSelf: "stretch",
    justifyContent: "space-evenly",
  },
  formContainer: {
    display: "flex",
    alignSelf: "stretch",
    flex: 1,
    justifyContent: "center",
  },
  form: {
    display: "flex",
    alignSelf: "stretch",
    flex: 1,
    justifyContent: "center",
    flexDirection: "column",
  },
  planContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 10,
    marginBottom: 20,
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  paymentsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: resizeByResolutionWidth(4.5),
    width: 45,
    height: 30,
    marginRight: 10,
  },
  paymentContainerWithBorder: {
    border: `1px solid ${palette.gray.light}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: resizeByResolutionWidth(4.5),
    width: 45,
    height: 30,
    marginRight: 10,
  },
  stripeContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    top: 0,
    left: 0,
  },
  stripeText: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
  },
  stripeBase: {
    position: "relative",
    top: 0,
    left: 0,
  },
  cardInfoMainContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 10,
    backgroundColor: palette.gray.light,
    width: "100%",
    padding: `${resizeByResolutionWidth(30)}px ${resizeByResolutionWidth(0)}px`,
    marginTop: 15,
    marginBottom: 15,
  },
  cardInfoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  cardNumber: {
    width: "75%",
    marginLeft: resizeByResolutionWidth(30),
  },
  dateCVCContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "25%",
    marginRight: resizeByResolutionWidth(30),
  },
  dateCVCInput: {
    width: "100%",
  },
  billedAmountContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 30,
  },
  billed: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 30,
    color: palette.black,
  },
  separator: {
    width: 1,
    height: 25,
    backgroundColor: palette.black,
    marginLeft: 10,
    marginRight: 10,
  },
  longText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 20,
    color: palette.gray.medium,
  },
  subscribe: {
    width: "100%",
    backgroundColor: palette.black,
    height: 60,
    marginTop: 30,
    marginBottom: 30,
  },
  plan: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 25,
    color: palette.black,
  },
  gap: {
    width: 30,
    height: "auto",
  },
};
