/* eslint-disable react-hooks/exhaustive-deps */
import { palette } from "assets/palette";
import { EventType } from "communicators/resources/event-resources/event-resources.type";
import { Text } from "elements/text/Text";
import { CSSProperties } from "react";
import Snackbar from "@mui/material/Snackbar";
import { MeetDetail } from "../../../TimelineListItem/components/MeetDetail";
import { IEvent } from "../../types";
import { useTimelineListItemHook } from "./useTimelineListItemHook";
import { IconButton } from "@mui/material";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { i18n } from "constants/i18n.constants";
import { styled } from "@mui/material/styles";

interface Props {
  event: IEvent;
  onDraging: (event: IEvent) => void;
  index: number;
}

export const TimelineListItem = ({ event, index, onDraging }: Props) => {
  const {
    determineHeight,
    drag,
    determineTextFontSize,
    isDragging,
    backgroundColor,
    title,
    textColor,
    onMouseDownEvent,
    onMouseUpEvent,
    onMouseMove,
    onCloseSnackbar,
    snackbarOpen,
    onMouseLeave,
    desiredCalendar,
    ref,
    mainRef,
    isMeetDetailShown,
    toggleDetail,
    isInsumo,
    background,
  } = useTimelineListItemHook({
    event,
    onDraging,
  });
  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={onCloseSnackbar}
      >
        <Icon name={IconName.CLOSE} fill={palette.white} />
      </IconButton>
    </>
  );

  return (
    <div style={styles.wrapper} ref={mainRef}>
      <div
        ref={drag}
        style={{
          ...styles.container,
          opacity: isDragging ? 0.5 : 1,
          backgroundColor,
          background: background,
          height: determineHeight(),
          marginLeft: index === 0 ? 50 : 0,
        }}
        onClick={toggleDetail}
        onMouseDown={onMouseDownEvent}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUpEvent}
        onMouseLeave={onMouseLeave}
      >
        {event.data.type === EventType.MEET ? (
          <EmailColor color={desiredCalendar[0]?.color} />
        ) : null}
        <Text
          style={{
            ...styles.text,
            color: textColor,
            fontSize: determineTextFontSize(),
          }}
        >
          {title}
        </Text>
      </div>
      {event?.data?.type === EventType.MEET && isMeetDetailShown ? (
        <div style={styles.detailContainer}>
          <MeetDetail
            data={event?.data}
            isDrawer={true}
            ref={ref}
            isInsumo={isInsumo}
          />
        </div>
      ) : null}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={onCloseSnackbar}
        message={i18n.t("cannot_reschedule")}
        action={action}
      />
    </div>
  );
};

const EmailColor = styled("div")<{
  color?: string;
}>(({ color }) => ({
  width: 7,
  height: 7,
  borderRadius: 1,
  marginRight: 5,
  backgroundColor: color,
}));

const styles: Record<string, CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "move",
    borderRadius: 4,
    paddingLeft: 10,
    paddingRight: 10,
    border: `1px solid ${palette.white}`,
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  text: {
    fontFamily: "Gilroy-Semibold",
    fontWeight: "500",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  detailContainer: {
    marginLeft: 50,
    zIndex: 2,
    position: "absolute",
    top: 45,
  },
};
