import * as React from "react";
import { palette } from "assets/palette";
import { i18n } from "constants/i18n.constants";
import { Text } from "elements/text/Text";
import { styled } from "@mui/material/styles";
import { LeftSettingsBar } from "pages/private/home/components/LeftSettignsBar/LeftSettingsBar";
import { DrawerHeader } from "pages/private/home/components/DrawerHeader/DrawerHeader";
import { DRAWER_WIDTH, LEFT_BAR_WIDTH } from "pages/private/home/constants";
import { ProgressBar } from "elements/progress-bar/ProgressBar";
import { CustomButton } from "elements/button/Button";
import { useAddFirstTask } from "./useAddFirstTask";
import { InputBase } from "@mui/material";
import { Icon } from "elements/icon/Icon";
import { IconName } from "elements/icon/Icon.type";
import { EventType } from "communicators/resources/event-resources/event-resources.type";
import "./style.css";
import { usePageHook } from "hooks/usePageHook";

export const AddFirstTask = () => {
  const {
    openSecondDrawer,
    secondDrawerOpened,
    progressValue,
    newTaskTitle,
    setNewTaskTitle,
    onClickPlusIcon,
    isPlusIconClicked,
    goBack,
    todaysTasks,
    goToAddFirstTaskDetail
  } = useAddFirstTask();
  usePageHook();
  return (
    <div style={styles.container}>
      <div style={styles.wrapper}>
        <LeftSettingsBar
          openSecondDrawer={openSecondDrawer}
          secondDrawerOpenStatus={secondDrawerOpened}
          isChildOfAFT={true}
        />
        <div>
          <DrawerHeader>
            <div style={styles.headerButtonStyle}>
              <Text style={styles.headerTextStyle}>{i18n.t("app_name")}</Text>
            </div>
          </DrawerHeader>
          <div style={styles.planTodayContainer}>
            <Icon name={IconName.BACK_ARROW} onPress={goBack} style={styles.backButton} />
            <Text style={styles.header}>{i18n.t("plan_today")}</Text>
            <Text style={styles.subheader}>{i18n.t("add_your_first_task")}</Text>
            <div style={styles.todayContainer} id="hello">
              <Text style={styles.today}>{i18n.t("today")}</Text>
              <Icon name={IconName.PLUS} onPress={onClickPlusIcon} fill={palette.white} style={styles.plusIcon} />
              {!isPlusIconClicked ? (
                <>
                  <Icon name={IconName.ADD_FIRST_TASK_ARROW} />
                  <div style={styles.addNewTaskBox}>
                    <Text style={styles.clickHereText}>{i18n.t("click_here_to_add_first_task")}</Text>
                  </div>
                </>) : null}
            </div>
          </div>
          <div style={styles.taskContainer} className="Windows">
            {isPlusIconClicked ? <div style={styles.newTaskCard}>
              <button style={false ? styles.checkbox : styles.emptyCheckbox}>
                {false ? <Icon name={IconName.TICK} /> : null}
              </button>
              <InputBase
                value={newTaskTitle}
                placeholder={i18n.t("type_here")}
                style={styles.input}
                onChange={(event) => setNewTaskTitle(event.target.value)}
              />
              <div style={styles.iconContainer}>
                <Icon name={IconName.TIME} />
                <div style={styles.iconGap} />
                <Icon name={IconName.CALENDAR} />
              </div>
            </div> : null}
            {todaysTasks?.data.map((item) => {
              if (item.type !== EventType.TASK) return <></>;
              return (
                <div style={styles.newTaskCard} className="itemContainer" key={item.id}>
                  <button style={false ? styles.checkbox : styles.emptyCheckbox}>
                    {false ? <Icon name={IconName.TICK} /> : null}
                  </button>
                  <Text>{item.attributes.title}</Text>
                  <div style={styles.iconContainer}>
                    <Icon name={IconName.TIME} />
                    <div style={styles.iconGap} />
                    <Icon name={IconName.CALENDAR} />
                  </div>
                </div>
              )
            })}
          </div>
          <div style={styles.progressBarTextContainer}>
            <Text style={styles.dailyPlanningProcess}>{i18n.t("daily_planning_process")}</Text>
            <ProgressBar
              style={styles.progress}
              value={progressValue}
              progressProps={{ color: "info" }}
            />
            <CustomButton style={styles.buttonStyle} text={i18n.t("next")} onClick={goToAddFirstTaskDetail} />
          </div>
        </div>
      </div>
      <SecondDrawer open={secondDrawerOpened.opened} />
    </div>
  );
};

const SecondDrawer = styled("div")<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  width: DRAWER_WIDTH + 10,
  backgroundColor: palette.white,
  padding: theme.spacing(3),
  top: 68,
  position: "absolute",
  transition: theme.transitions.create(["left"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  left: `-${DRAWER_WIDTH + 10}px`,
  ...(open && {
    transition: theme.transitions.create(["left"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    left: `${LEFT_BAR_WIDTH - 10}px`,
    zIndex: 5,
  }),
}));

const styles: Record<string, React.CSSProperties> = {
  container: {
    width: 390 + LEFT_BAR_WIDTH,
    height: "100vh",
    boxShadow: "0px 4px 4px 0px #00000040",
  },
  headerButtonStyle: {
    display: "flex",
    flex: 1,
    backgroundColor: palette.white,
    paddingLeft: 20,
    alignItems: "center",
    borderWidth: 0,
  },
  headerTextStyle: {
    fontWeight: "500",
    fontSize: 22,
    marginRight: 5,
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    height: "100%",
  },
  planTodayContainer: {
    marginLeft: 40,
    marginRight: 40,
    marginTop: "6vh",
  },
  header: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 30,
    color: palette.black,
    textAlign: "left",
    marginTop: 25,
  },
  subheader: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 22,
    color: palette.gray.dark,
    textAlign: "left",
  },
  backButton: {
    marginLeft: -12,
    cursor: "pointer",
  },
  todayContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 50,
  },
  today: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 25,
    color: palette.black,
    marginRight: 20,
  },
  plusIcon: {
    marginTop: 4,
    cursor: "pointer",
  },
  addNewTaskBox: {
    width: 140,
    height: 50,
    background: 'linear-gradient(to bottom, #84dcf9, #4ec9fe, #00b4ff, #009dff)',
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: -6
  },
  clickHereText: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 15,
    color: palette.white,
    textAlign: "center",
    padding: 10,
  },
  newTaskCard: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: palette.white,
    borderRadius: 12,
    marginLeft: 40,
    marginRight: 40,
    marginTop: 25,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 15,
    paddingBottom: 35,
    boxShadow: "0px 6px 5px rgba(0, 0, 0, 0.14)",
  },
  progress: {
    marginTop: 5,
    marginBottom: 50,
    marginLeft: 40,
    width: DRAWER_WIDTH - 80,
  },
  dailyPlanningProcess: {
    fontFamily: "Gilroy-Medium",
    fontWeight: "500",
    fontSize: 10,
    color: palette.gray.medium,
    marginLeft: 40,
  },
  buttonStyle: {
    marginLeft: 40,
    width: 200,
    backgroundColor: palette.black,
    height: 50,
  },
  emptyCheckbox: {
    width: 26,
    height: 26,
    borderRadius: 13,
    color: palette.gray.ice,
    marginRight: 10,
    borderWidth: 0,
  },
  checkbox: {
    width: 26,
    height: 26,
    borderRadius: 13,
    color: palette.orange.light,
    marginRight: 10,
    borderWidth: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  input: {
    display: "flex",
    alignSelf: "stretch",
    flex: 1,
    borderWidth: 0,
    backgroundColor: "transparent",
    border: "none",
  },
  iconGap: {
    width: 20
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
  },
  progressBarTextContainer: {
    position: "absolute",
    bottom: 70
  },
  taskContainer: {
    overflowY: "scroll",
    maxHeight: "calc(40% - 136px)",
    padding: "0px 0px 20px 0",
  }
};