/* eslint-disable react-hooks/exhaustive-deps */
import { Scope } from "communicators/resources/third-party-information/third-party-information.type";
import {
  setAvailableMagicPlannerFreeSpots,
  setPermissionModalType,
} from "redux-store/actions/home-page.actions";
import { InsumoCalendarEventsResource } from "communicators/resources/insumo-calendar-events/insumo-calendar-events.resource";
import {
  CalendarAccountType,
  PermissionModalType,
} from "pages/private/home/useHomeHook";
import { EventType } from "communicators/resources/event-resources/event-resources.type";
import { MeetsResource } from "communicators/resources/event-resources/meets/meets.resource";
import { MeetEntity } from "communicators/resources/event-resources/meets/meets.type";
import { TaskEntity } from "communicators/resources/event-resources/tasks/tasks.type";
import { DNDItemType } from "constants/dnd.constants";
import { DragAndDropHelper } from "helpers/dnd.helper";
import {
  AllBoardDataEntityType,
  ThirdPartyProvider,
} from "interfaces/main.interfaces";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux-store/store.hooks";
import { IEvent, ITimelineItem, TimelineProps } from "../../types";
import { useUserSettingHook } from "hooks/useUserSettingHook";
import {
  EventEmitter,
  EventEmitterType,
} from "constants/event-emitter.constant";
import { MagicPlannerHelper } from "helpers/magic-planner.helper";

const DEFAULT_TIME_INDEX = 36;
const MEET_DURATION = 45;
const MS_TO_MINUTES = 60000;

export const useTimelineHook = ({
  startDate,
  updateTaskWithRightDrawerDrop,
  boardData,
  insumoCalendarEventData,
  onHoverRightDrawer,
}: TimelineProps) => {
  const [events, setEvents] = useState<IEvent[]>([]);
  const [scrollIndex] = useState(DEFAULT_TIME_INDEX);

  const dispatch = useAppDispatch();

  const { firebaseUuid, thirdPartyData } = useAppSelector((state) => ({
    firebaseUuid: state.userSetting.firebaseUuid,
    thirdPartyData: state.thirdPartyProviderData.thirdPartyData,
  }));

  const googleScopes = thirdPartyData?.filter(
    (item) => item.provider === ThirdPartyProvider.GOOGLE
  )[0]?.scopes;

  const handleDraging = (dragingEvent: IEvent) => {
    DragAndDropHelper.setDragingEvent({
      type: DNDItemType.TIMELINE_ITEM,
      data: dragingEvent,
    });
  };

  const { preferences } = useUserSettingHook();

  const onDropTimelineItem = (dragingEvent: IEvent, item: ITimelineItem) => {
    if (!googleScopes?.includes(Scope.WRITE)) {
      dispatch(setPermissionModalType(PermissionModalType.GOOGLE));
      return;
    }
    const newEvent = { ...dragingEvent };
    const eventCurrentIndex = DragAndDropHelper.events.findIndex(
      (data) => data.id === newEvent.id
    );
    const diff = newEvent.end.diff(newEvent.start, ["minutes"]);
    newEvent.start = item.time;
    newEvent.end = item.time.plus({ minutes: diff.minutes });
    const newEvents = [...DragAndDropHelper.events];
    newEvents.splice(eventCurrentIndex, 1);
    newEvents.push(newEvent);
    setEvents(newEvents);
    DragAndDropHelper.setEvents(newEvents);
    new MeetsResource().updateOnePartial(dragingEvent.id.split("_")[0], {
      meet: {
        start_time: newEvent.start.toISO(),
        end_time: newEvent.end.toISO(),
      },
    });
  };

  const onDropTimelineItemInsumoCalendar = (
    dragingEvent: IEvent,
    item: ITimelineItem
  ) => {
    const newEvent = { ...dragingEvent };
    const eventCurrentIndex = DragAndDropHelper.events.findIndex(
      (data) => data.id === newEvent.id
    );
    const diff = newEvent.end.diff(newEvent.start, ["minutes"]);
    newEvent.start = item.time;
    newEvent.end = item.time.plus({ minutes: diff.minutes });
    const newEvents = [...DragAndDropHelper.events];
    newEvents.splice(eventCurrentIndex, 1);
    newEvents.push(newEvent);
    setEvents(newEvents);
    DragAndDropHelper.setEvents(newEvents);
    new InsumoCalendarEventsResource().updateOnePartial(
      dragingEvent.id.split("_")[0],
      {
        insumo_calendar_event: {
          start_date: newEvent.start.toISO(),
          end_date: newEvent.end.toISO(),
        },
        firebase_uuid: firebaseUuid,
      }
    );
  };

  const onDropTaskItem = async (
    dragingEvent: TaskEntity,
    item: ITimelineItem
  ) => {
    if (!googleScopes?.includes(Scope.WRITE)) {
      dispatch(setPermissionModalType(PermissionModalType.GOOGLE));
      return;
    }
    let diff = 45;
    if (
      dragingEvent.attributes.start_time &&
      dragingEvent.attributes.end_time
    ) {
      const startTimeInSecond = parseInt(dragingEvent.attributes.start_time);
      const endTimeInSecond = parseInt(dragingEvent.attributes.end_time);
      diff = (endTimeInSecond - startTimeInSecond) / 60;
    }
    const startTime = item.time;
    const endTime = item.time.plus({ minutes: diff });
    const newEvents = [...DragAndDropHelper.events];
    const eventId = crypto.randomUUID();
    const meetData: MeetEntity = {
      id: `${eventId}_tl`,
      type: EventType.MEET,
      attributes: {
        meet_summary: dragingEvent.attributes.title,
        meet_descripion: dragingEvent.attributes.description,
        meet_start_time: startTime.toISO()!,
        meet_end_time: endTime.toISO()!,
        calendar_id: "",
        is_completed: false,
        meet_location: "",
        meet_provider: "",
        task_id: dragingEvent.id,
      },
    };
    EventEmitter.emit(EventEmitterType.ON_CREATE_NEW_MEET_FROM_TASK, {
      taskId: meetData.attributes.task_id,
      time: startTime,
    });
    newEvents.push({
      start: startTime,
      end: endTime,
      id: dragingEvent.id,
      data: meetData,
    });
    setEvents(newEvents);
    DragAndDropHelper.setEvents(newEvents);
    try {
      const response = await new MeetsResource().createOne({
        meet: {
          summary: meetData.attributes.meet_summary,
          description: meetData.attributes.meet_descripion,
          start_time: meetData.attributes.meet_start_time,
          end_time: meetData.attributes.meet_end_time,
          task_id: dragingEvent.id,
          moved_by_magic_planner: false,
        },
        firebase_uuid: firebaseUuid,
      });
      if (!response.data) return;
      const eventsForId = [...newEvents];
      for (let i = 0; i < eventsForId.length; i++) {
        if (eventsForId[i].id === dragingEvent.id) {
          eventsForId[i].id = response.data.id;
          eventsForId[i].data = {
            ...response.data,
            id: `${response.data.id}_tl`,
          };
          break;
        }
      }
      setEvents(eventsForId);

      DragAndDropHelper.setEvents(eventsForId);
    } catch (_) {}
  };

  const onHoverTimeBox = (item: ITimelineItem) => {
    const draggingData = DragAndDropHelper.draggingEvent;
    if (
      !draggingData ||
      !draggingData.data ||
      draggingData.type !== DNDItemType.BOARD_ITEM ||
      !onHoverRightDrawer
    ) {
      return;
    }
    onHoverRightDrawer(draggingData.data, item.time);
  };

  const determineDays = (): ITimelineItem[] => {
    let currentTime = startDate.startOf("day");
    const indexNumber = 24 * 4;
    const days = [];
    for (let i = 0; i < indexNumber; i++) {
      days.push({ time: currentTime });
      currentTime = currentTime.plus({ minutes: 15 });
    }
    return days;
  };

  const onDropBoardItem = (
    dragingEvent: AllBoardDataEntityType,
    item: ITimelineItem
  ) => {
    if (dragingEvent.type === EventType.TASK) {
      onDropTaskItem(dragingEvent, item);
    }
  };

  const onDropBoardItemToInsumoCalendar = (
    dragingEvent: AllBoardDataEntityType,
    item: ITimelineItem
  ) => {
    if (dragingEvent.type === EventType.TASK) {
      onDropInsumoCalendarEventItem(dragingEvent, item);
    }
  };

  const onDrop = (item: ITimelineItem) => {
    if (
      !DragAndDropHelper.draggingEvent ||
      !DragAndDropHelper.draggingEvent.data
    ) {
      return;
    }
    if (DragAndDropHelper.draggingEvent.type === DNDItemType.TIMELINE_ITEM) {
      if (
        preferences?.attributes.preferred_calendar ===
        CalendarAccountType.INSUMO
      ) {
        onDropTimelineItemInsumoCalendar(
          DragAndDropHelper.draggingEvent.data,
          item
        );
      } else {
        onDropTimelineItem(DragAndDropHelper.draggingEvent.data, item);
      }
    } else if (
      DragAndDropHelper.draggingEvent.type === DNDItemType.BOARD_ITEM
    ) {
      const data = DragAndDropHelper.draggingEvent.data;
      if (data.type === EventType.TASK) {
        updateTaskWithRightDrawerDrop(data, item);
      }
      if (
        preferences?.attributes.preferred_calendar ===
        CalendarAccountType.GOOGLE
      ) {
        onDropBoardItem(data, item);
      } else if (
        preferences?.attributes.preferred_calendar ===
        CalendarAccountType.INSUMO
      ) {
        onDropBoardItemToInsumoCalendar(data, item);
      }
    }
  };

  const parseBoardDataIntoScheduleEvents = (): IEvent[] => {
    const desiredData = boardData.filter(
      (item) =>
        item.date.toFormat("yyyy LLL dd") === startDate.toFormat("yyyy LLL dd")
    );
    return desiredData[0]?.data?.map(
      (item) =>
        ({
          start:
            item.type === EventType.MEET
              ? DateTime.fromISO(item.attributes.meet_start_time)
              : DateTime.now(),
          end:
            item.type === EventType.MEET
              ? DateTime.fromISO(item.attributes.meet_start_time).plus({
                  minutes: 45,
                })
              : DateTime.now().plus({ minutes: 45 }),
          id: item.id,
          data: item,
        } as IEvent)
    );
  };

  const parseInsumoCalendarEventDataIntoScheduleEvents = (): IEvent[] => {
    const desiredData = insumoCalendarEventData?.filter(
      (item) =>
        item.date.toFormat("yyyy LLL dd") === startDate.toFormat("yyyy LLL dd")
    );
    return desiredData?.[0]?.data?.map(
      (item) =>
        ({
          start: DateTime.fromISO(item.attributes.start_date),
          end: DateTime.fromISO(item.attributes.end_date),
          id: item.id,
          data: {
            id: `${item.id}_tl`,
            type: EventType.MEET,
            attributes: {
              meet_summary: item.attributes.item.attributes.title,
              meet_descripion: item.attributes.item.attributes.description,
              meet_start_time: item.attributes.start_date,
              meet_end_time: item.attributes.end_date,
              calendar_id: "",
              is_completed: false,
              meet_location: "",
              meet_provider: "",
              task_id: item.id,
            },
          },
        } as IEvent)
    );
  };

  const onDropInsumoCalendarEventItem = async (
    dragingEvent: TaskEntity,
    item: ITimelineItem
  ) => {
    let diff = 45;
    if (
      dragingEvent.attributes.start_date &&
      dragingEvent.attributes.end_date
    ) {
      const startTimeInSecond = parseInt(dragingEvent.attributes.start_date);
      const endTimeInSecond = parseInt(dragingEvent.attributes.end_date);
      diff = (endTimeInSecond - startTimeInSecond) / 60;
    }
    const startTime = item.time;
    const endTime = item.time.plus({ minutes: diff });
    const newEvents = [...DragAndDropHelper.events];
    const eventId = crypto.randomUUID();
    const meetData: MeetEntity = {
      id: `${eventId}_tl`,
      type: EventType.MEET,
      attributes: {
        meet_summary: dragingEvent.attributes.title,
        meet_descripion: dragingEvent.attributes.description,
        meet_start_time: startTime.toISO()!,
        meet_end_time: endTime.toISO()!,
        calendar_id: "",
        is_completed: false,
        meet_location: "",
        meet_provider: "",
        task_id: dragingEvent.id,
      },
    };
    newEvents.push({
      start: startTime,
      end: endTime,
      id: dragingEvent.id,
      data: meetData,
    });
    setEvents(newEvents);
    DragAndDropHelper.setEvents(newEvents);
    try {
      await new InsumoCalendarEventsResource().createOne({
        insumo_calendar_event: {
          item_id: dragingEvent.id,
          start_date: startTime.toISO(),
          end_date: startTime.plus({ minutes: 45 }).toISO(),
        },
        firebase_uuid: firebaseUuid,
      });
    } catch (_) {}
  };

  useEffect(() => {
    if (
      preferences?.attributes.preferred_calendar === CalendarAccountType.GOOGLE
    ) {
      const desiredEvents = parseBoardDataIntoScheduleEvents();
      const newEvents: IEvent[] = [];
      desiredEvents?.forEach((item) => {
        if (!item) return;
        const { startTime, endTime } = getStartEndTimeOfMeet(item.data);
        if (endTime && startTime) {
          newEvents.push({
            id: `${item.id}`,
            data: item.data,
            start: startTime,
            end: endTime,
          });
        }
      });
      DragAndDropHelper.setEvents(newEvents);
      setEvents(newEvents);
    } else if (
      preferences?.attributes.preferred_calendar === CalendarAccountType.INSUMO
    ) {
      const desiredEvents = parseInsumoCalendarEventDataIntoScheduleEvents();
      const newEvents: IEvent[] = [];
      desiredEvents?.forEach((item) => {
        if (!item) return;
        const { startTime, endTime } =
          getStartEndTimeOfInsumoCalendarEvent(item);
        if (endTime && startTime) {
          newEvents.push({
            id: `${item.id}_tl`,
            data: item.data,
            start: startTime,
            end: endTime,
          });
        }
      });
      DragAndDropHelper.setEvents(newEvents);
      setEvents(newEvents);
    }
  }, [
    preferences?.attributes.preferred_calendar,
    startDate,
    insumoCalendarEventData,
    boardData,
  ]);

  const timeline = determineDays();

  useEffect(() => {
    const selectedIndex = document.getElementById(scrollIndex.toString());
    if (selectedIndex) {
      selectedIndex.scrollIntoView(true);
    }
  }, []);

  const isEventExistOn = (
    startTime: DateTime,
    endTime: DateTime,
    localEvents: IEvent[]
  ) => {
    for (let i = 0; i < localEvents.length; i++) {
      const currentEvent = localEvents[i];
      if (
        (currentEvent.start >= startTime && currentEvent.start < endTime) ||
        (currentEvent.end <= endTime && currentEvent.end > startTime)
      ) {
        return true;
      }
    }
    return false;
  };

  const determineIntervals = (
    startTime: DateTime,
    endTime: DateTime,
    events: IEvent[]
  ): { start: DateTime; end: DateTime }[] => {
    let currentTime = startTime;
    const indexNumber = 24 * 4;
    const intervals = [];
    for (let i = 0; i < indexNumber; i++) {
      const isEventExist = isEventExistOn(
        currentTime,
        currentTime.plus({ minutes: 45 }),
        events
      );
      if (!isEventExist) {
        intervals.push({
          start: currentTime,
          end: currentTime.plus({ minutes: 45 }),
        });
        currentTime = currentTime.plus({ minutes: 45 });
      } else {
        currentTime = currentTime.plus({ minutes: 15 });
      }

      if (currentTime > endTime) {
        break;
      }
    }
    return intervals;
  };

  const hasAvailableInterval = (array: IEvent[]) => {
    const targetTime = DateTime.now()
      .toLocal()
      .set({ hour: 19, minute: 0, second: 0 });
    const startTime = DateTime.now()
      .toLocal()
      .set({ hour: 9, minute: 0, second: 0 });
    const meetData: MeetEntity = {
      id: `meetEntity_tl`,
      type: EventType.MEET,
      attributes: {
        meet_summary: "",
        meet_descripion: "",
        meet_start_time: startTime.toISO()!,
        meet_end_time: startTime.toISO()!,
        calendar_id: "",
        is_completed: false,
        meet_location: "",
        meet_provider: "",
        task_id: undefined,
      },
    };
    const dummyStartMeet: IEvent = {
      start: startTime,
      end: startTime,
      id: "startIndex",
      data: meetData,
    };

    const dummyEndMeet: IEvent = {
      start: targetTime,
      end: targetTime,
      id: "endIndex",
      data: meetData,
    };
    let count = 0;
    const sortedEvents = array
      .slice()
      .sort((a, b) => a.start.valueOf() - b.start.valueOf());
    const filteredSortedEvents = sortedEvents.filter(
      (event) =>
        event.end > startTime &&
        event.start < targetTime &&
        event.end > DateTime.now()
    );
    if (DateTime.now() < startTime) {
      filteredSortedEvents.unshift(dummyStartMeet);
    }
    if (DateTime.now() < targetTime) {
      filteredSortedEvents.push(dummyEndMeet);
    }
    if (filteredSortedEvents.length === 1) {
      count += Math.floor(
        Number(filteredSortedEvents[0].start.diff(DateTime.now(), "minutes")) /
          MS_TO_MINUTES /
          MEET_DURATION
      );
    }
    for (let i = 1; i < filteredSortedEvents.length; i++) {
      const prevEvent = filteredSortedEvents[i - 1];
      const currentEvent = filteredSortedEvents[i];

      const timeGapInMinutes =
        Number(currentEvent.start.diff(prevEvent.end, "minutes")) /
        MS_TO_MINUTES;

      if (timeGapInMinutes >= MEET_DURATION) {
        count += Math.floor(timeGapInMinutes / MEET_DURATION);
      }
    }
    if (
      filteredSortedEvents &&
      filteredSortedEvents.length !== 0 &&
      Number(DateTime.now().diff(filteredSortedEvents[0].start, "minutes")) < 0
    ) {
      count += Math.abs(
        Number(DateTime.now().diff(filteredSortedEvents[0].start, "minutes")) /
          MS_TO_MINUTES /
          MEET_DURATION
      );
    }
    dispatch(setAvailableMagicPlannerFreeSpots(count));
  };

  const todayAtNine = DateTime.now()
    .toLocal()
    .set({ hour: 9, minute: 0, second: 0, millisecond: 0 });
  const todayAtSeven = DateTime.now()
    .toLocal()
    .set({ hour: 18, minute: 15, second: 0, millisecond: 0 });

  const getCurrentMagicTime = () => {
    const now = DateTime.now().toLocal();
    if (now.minute > 45) {
      return DateTime.now()
        .plus({ hour: 1 })
        .toLocal()
        .set({ minute: 0, second: 0, millisecond: 0 });
    } else {
      let currentMinute = now.minute;
      while (currentMinute % 15 !== 0) {
        currentMinute++;
      }
      return DateTime.now().toLocal().set({
        hour: now.hour,
        minute: currentMinute,
        second: 0,
        millisecond: 0,
      });
    }
  };

  const magicPlannerStartTime =
    DateTime.now().toLocal() > todayAtNine
      ? getCurrentMagicTime()
      : todayAtNine;

  useEffect(() => {
    hasAvailableInterval?.(events);
    const intervals = determineIntervals(
      magicPlannerStartTime,
      todayAtSeven,
      events
    );
    MagicPlannerHelper.setInterval(intervals);
  }, [events.length]);

  return {
    timeline,
    events,
    handleDraging,
    onDrop,
    onHoverTimeBox,
  };
};

const getStartEndTimeOfMeet = (meet: MeetEntity) => {
  if (!meet.attributes.meet_start_time || !meet.attributes.meet_end_time) {
    return { startTime: null, endTime: null };
  }
  const taskStartTime = DateTime.fromISO(meet.attributes.meet_start_time);
  const taskEndtime = DateTime.fromISO(meet.attributes.meet_end_time);
  return { startTime: taskStartTime, endTime: taskEndtime };
};

const getStartEndTimeOfInsumoCalendarEvent = (event: IEvent) => {
  if (!event.start || !event.end) {
    return { startTime: null, endTime: null };
  }
  const eventStartTime = event.start;
  const eventEndtime = event.end;
  return { startTime: eventStartTime, endTime: eventEndtime };
};
