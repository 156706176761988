/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { DropTargetMonitor, useDrop } from "react-dnd";

interface Props {
  onHover: () => void;
}

export const DropableArea = ({ onHover }: Props) => {
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: "board_item",
      drop: () => {},
      collect: (monitor: DropTargetMonitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    []
  );

  useEffect(() => {
    if (isOver && canDrop) {
      onHover();
    }
  }, [isOver, canDrop]);
  return <div ref={drop} style={styles.container}></div>;
};

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
};
